import { ApiResponse } from '../api/api-request-helpers';
import { GenericNotOkResponse } from '../api/types';
import { extractResponseNotOkMessage } from '../api/requests';

export enum DataState {
    Loading = 'Loading',
    Loaded = 'Loaded',
    Error = 'Error',
}

export type Data<T = unknown> =
    | {
          state: DataState.Loading;
      }
    | {
          state: DataState.Loaded;
          value: T;
      }
    | {
          state: DataState.Error;
          errorMessage: string;
      };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtractDataType<T extends Data<any>> = Extract<T, { state: DataState.Loaded }>['value'];

export const isDataLoaded = <T>(data: Data<T> | undefined): data is Extract<Data<T>, { state: DataState.Loaded }> =>
    Boolean(data && data.state === DataState.Loaded);

export const isDataLoading = <T>(data: Data<T> | undefined): data is Extract<Data<T>, { state: DataState.Loading }> =>
    data === undefined || data.state === DataState.Loading;

export const isDataError = <T>(data: Data<T> | undefined): data is Extract<Data<T>, { state: DataState.Error }> =>
    Boolean(data && data.state === DataState.Error);

export const getDataFromApiResponse = <TOkResponse, TDataValue>(
    result: ApiResponse<TOkResponse, GenericNotOkResponse>,
    mapper: (response: TOkResponse) => TDataValue
): Data<TDataValue> =>
    result.ok
        ? { state: DataState.Loaded, value: mapper(result.response) }
        : {
              state: DataState.Error,
              errorMessage: extractResponseNotOkMessage(result.response),
          };

export const makeLoadedData = <T>(value: T): Data<T> => ({ state: DataState.Loaded, value });
export const makeLoadingData = <T>(): Data<T> => ({ state: DataState.Loading });
export const makeErrorData = <T>(errorMessage: string): Data<T> => ({ state: DataState.Error, errorMessage });
