// eslint-disable-next-line
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Settings from './Settings';
import { ModifyUserDetails } from '../../components/UserDetails/ModifyUserDetails';
import { AppRoutes } from '../../routes';

export const userDetailsManagement: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => (
    <Switch>
        <Route exact path={AppRoutes.Settings} component={Settings} />
        <Route exact path={AppRoutes.ModifyUserDetails} component={ModifyUserDetails} />
    </Switch>
);
