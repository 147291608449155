import _ from 'lodash';
import { AuthHelper } from '../types/auth-helper-types';
import * as ApiRequests from '../api/requests';
import { DomainResponse, makeOkDomainResponse } from '../data/domain/domain-response';
import { Data, isDataLoaded, makeErrorData, makeLoadedData } from '../types/data-providers';
import { ProjectInfo } from '../types';
import { SEPERATOR_COMMA, SEPERATOR_SPACE, SYMBOL_PLUS } from '../constants';
import { CustomerInfo, SMCustomerData } from '../types/customer-detail';
import { SMOrganization, ServiceManagerOrgInfo } from '../types/organization';
import { USER_ROLE_TYPE } from '../app/enum/ENUM';

type Organization = {
    id: string;
    name: string;
    address: string;
};

export type AppData = {
    organizations: DomainResponse<{ [k: string]: DomainResponse<Organization> }>;
    projects: DomainResponse<{ [k: string]: DomainResponse<Organization> }>;
    customers: DomainResponse<{ [k: string]: DomainResponse<SMCustomerData> }>;
};

type GetProjectsResult = DomainResponse<ProjectInfo[]>;
type GetCustomersResult = DomainResponse<CustomerInfo[]>;
type GetServiceOrganizationsResult = DomainResponse<ServiceManagerOrgInfo[]>;

const getProjects = async (authHelper: AuthHelper, projectId: string, status: []): Promise<GetProjectsResult> => {
    const results = await ApiRequests.getProjects({ projectId, status }, { authHelper });
    if (results.ok) {
        return {
            ok: true,
            value: results.response.data
        };
    }

    return { ok: false, message: ApiRequests.extractErrorCodeWithMessage(results.response) };
};

const getCustomers = async (authHelper: AuthHelper): Promise<GetCustomersResult> => {
    const results = await ApiRequests.getCustomerList('', { authHelper });
    if (results.ok) {
        return {
            ok: true,
            value: results.response.data.customerDetailList
        };
    }

    return { ok: false, message: ApiRequests.extractErrorCodeWithMessage(results.response) };
};


const loadDataForUser = async (authHelper: AuthHelper, userId: string, userType: number | undefined): Promise<DomainResponse<AppData>> => {

    const emptyCustomers: { [k: string]: DomainResponse<SMCustomerData> } = {};
    const emptyServiceOrganizations: { [k: string]: DomainResponse<SMOrganization> } = {};
    const emptyOrganizations: { [k: string]: DomainResponse<Organization> } = {};

    var customers = emptyCustomers;
    var serviceOrganizations = emptyServiceOrganizations;
    var organizations = emptyOrganizations;

    
    const organizationsResult = await getProjects(authHelper, '', []);//await getOrganizations(authHelper, userId);

    if (!organizationsResult.ok) {
        return organizationsResult;
    }

    organizations = organizationsResult.value.reduce((memo, proj) => {
        memo[proj.projectId] = makeOkDomainResponse({
            id: proj.projectId,
            name: proj.name,
            address: proj.city? (proj.city + SEPERATOR_COMMA + proj.country): proj.country,
        });
        return memo;
    }, emptyOrganizations);
  
    if (userType === USER_ROLE_TYPE.SERVICE_MANAGER) {
        //Service customer
        const customersResult = await getCustomers(authHelper);
        if (!customersResult.ok) {
            return customersResult;
        }

        customers = customersResult.value.reduce((memo, cust) => {
            memo[cust.id] = makeOkDomainResponse({
                id: cust.id,
                name: cust.companyName,
                address: cust.city ? (cust.city + SEPERATOR_COMMA + cust.country) : cust.country,
                primaryName: (cust.primaryFirstName ? cust.primaryFirstName : "") + (cust.primaryLastName ? SEPERATOR_SPACE + cust.primaryLastName : ""),
                primaryPhone: SYMBOL_PLUS + cust.primaryCountryCode + SEPERATOR_SPACE + cust.primaryPhone,
                primaryEmail: cust.primaryEmail ? cust.primaryEmail : "",
                adminCount: cust.adminCount,
                viewerCount: cust.viewerCount,
                customerNumber: cust.customerNumber,
            });
            return memo;
        }, emptyCustomers);

       
    }

    return makeOkDomainResponse({
        organizations: makeOkDomainResponse(organizations),
        projects: makeOkDomainResponse(organizations),
        customers: makeOkDomainResponse(customers),
    });
};

const getOrganization = (appData: Data<AppData>, organizationId: string | undefined): Data<Organization> => {
    if (!isDataLoaded(appData)) {
        return appData;
    }

    if (!appData.value.organizations.ok) {
        return makeErrorData(appData.value.organizations.message || 'Organization not found');
    }

    const organizations = appData.value.organizations.value;

    const organization = organizationId ? organizations[organizationId] : _.values(organizations)[0];

    if (!organization) return makeErrorData('Organization not found');

    if (!organization.ok) {
        return makeErrorData(organization.message || 'Organization not found');
    }
    return makeLoadedData(organization.value);
};

const getCustomer = (appData: Data<AppData>, customerId: string | number | undefined = -2): Data<SMCustomerData> => {
    if (!isDataLoaded(appData)) {
        return appData;
    }

    if (!appData.value.customers.ok) {
        return makeErrorData(appData.value.customers.message || 'Customer not found');
    }

    const customers = appData.value.customers.value;

    const customer = customerId ? customers[customerId] : _.values(customers)[0];

    if (!customer) return makeErrorData('Customer not found');

    if (!customer.ok) {
        return makeErrorData(customer.message || 'Customer not found');
    }
    return makeLoadedData(customer.value);
};



export const AppDataLoader = {
    loadDataForUser,
    getOrganization,
    getCustomer,
};
