// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Checkbox, CircularProgress, IconButton, InputAdornment, Table, TableBody, TablePagination, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ArrowDropDown, Close, GetApp, Search } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { useDispatch, useSelector } from 'react-redux';
import { HeadCell, Order } from '../../types/table';
import { Selectors } from '../../selectors';
import { GatewayAssetData, GatewayAssetList, GatewayDetailsData, GatewayDetailsList } from '../../types/gateway';
import { Thunks } from '../../actions';
import { getComparator, stableSort } from '../../lib/table-helpers';
import { EnhancedTableHead } from '../../components/Table/EnhancedTableHead';
import { AssetGatewayListItem } from '../../components/AssetListItem/AssetGatewayListItem';
import { DeviceGatewayDetails } from './DeviceGatewayDetails';
import { DropDownListComponent } from '../../components/DropDownList/DropDownListComponent';
import { isValidCharWithoutSpace } from '../../lib/site-helper';
import { ItemList } from '../../types/logbook-type';
import { BuildingList } from '../../types/building';
import { EmptyState } from '@brightlayer-ui/react-components';
import SyncButtonImage from '../../assets/images/sync_button.svg';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { customerListV2Data } from '../../types/customer-detail';
import { CURRENT_PAGE_SESSION, DEFAULT_PAGE_ONE, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { AssetActions } from '../../actions/actions/asset-actions';
import { USER_ROLE_TYPE } from '../enum/ENUM';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider';
import { OrganizationActions } from '../../actions/actions/organization-actions';
import { useToasts } from '../../data/DataProviders/ToastProvider';

type Props = {
    onClick: Function;
    orgList: ItemList;
    defaultProject: string;
    defaultBuilding: string;
    defaultGateway: string;
    systemCountClick: Function;
    showAllClick?: Function;
};

export const AssetsGateways: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [buildingId, setBuildingId] = React.useState(props.defaultBuilding);
    const asset = useSelector(Selectors.Dashboard.asset);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('gateWayName');
    const [emptyGatewayList, setEmptyGatewayList] = React.useState(false);
    const [selectedGateway, setSelectedGateway] = React.useState('');
    const [gatewayList, setGatewayList] = useState<GatewayAssetList>([]);
    const [gatewayAssetList, setGatewayAssetList] = useState<GatewayAssetList>([]);
    const [isAssetGatewayOpen, setAssetGatewayOpenState] = React.useState(false);
    const [gatewayDataLoaded, setGatewayDataLoaded] = React.useState(false);
    const [searchText, setSearchText] = useState('');
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const useOrganizationDataList = useOrganizationList();
    const organization = useSelector(Selectors.Organization.organization);
    const [buildings, setBuildings] = useState<ItemList>([]);
    const [filterBuilding, setFilteredBuilding] = React.useState(props.defaultBuilding);
    const [filterGateway, setFilterGateway] = React.useState(props.defaultGateway);
    const [firstCall, setFirstCall] = React.useState(true);
    const [isFav, setIsFav] = React.useState(false);


    const [projects] = useState<ItemList>(props.orgList);
    const [filterProject, setFilteredProject] = React.useState(props.defaultProject);
    const [filterCustomer, setFilteredCustomer] = React.useState('');
    const [customerList, setCustomerList] = React.useState<ItemList>([]);
    const [customerId, setCustomerId] = React.useState('');
    const [offset, setOffset] = React.useState(1);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);

    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const DEFAULT_ID: string = '';
    const customer = useSelector(Selectors.Customer.customer);
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';

    const headCells: HeadCell[] = [
        { id: 'gateWayName', label: t('DEVICE_DETAILS.NAME'), hideSorting: false },
        { id: 'buildingName', label: t('DEVICE_DETAILS.BUILDING'), hideSorting: false },
        { id: 'systemCount', label: t('DEVICE_DETAILS.SYSTEMS'), hideSorting: false },
        { id: 'connectionType', label: t('DEVICE_DETAILS.CONNECTION_TYPE'), hideSorting: false },
        { id: 'gatewayStatus', label: t('DEVICE_DETAILS.STATUS'), hideSorting: false },
    ];

    const getGatewayAssetList = (list: GatewayDetailsList): GatewayAssetList => {
        return list.map((data, gateway) => {
            return (
                {
                    gateWayId: data.gateWayId,
                    gateWayName: data.gateWayName,
                    projectId: data.projectId,
                    projectName: data.projectName,
                    buildingName: data.buildingName,
                    buildingId: data.buildingId,
                    isFavourite: data.isFavourite ? 1 : 0,
                    connectionType: data.connectionType,
                    gatewayStatus: data.gatewayStatus,
                    systemCount: data.systemCount,
                }
            );
        })
    };

    useEffect(() => {
        if (firstCall) {
            setFirstCall(false);
            if (dashboard.buildingListSuccess)
                dispatch(Thunks.Dashboard.getBuildingList(filterProject, DEFAULT_ID));

            dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, filterBuilding, DEFAULT_ID, filterCustomer, searchText, offset.toString(), rowsPerPage.toString(), isFav));
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            dispatch(AssetActions.gatewayDetailsListUnmounted());
            dispatch(Thunks.Dashboard.getBuildingList(filterProject, DEFAULT_ID));
            usertype === USER_ROLE_TYPE.SERVICE_MANAGER ? dispatch(Thunks.Customer.getCustomerListV2(filterProject)) : '';
            dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, DEFAULT_ID, DEFAULT_ID, DEFAULT_ID, searchText, offset.toString(), rowsPerPage.toString(), isFav));
            setBuildings([]);
            setCustomerList([]);
            setBuildingId(filterProject);
            setCustomerId(filterProject);
            setFilteredBuilding('');
            setFilteredCustomer('');
        }
    }, [filterProject]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            usertype === USER_ROLE_TYPE.SERVICE_MANAGER ? dispatch(Thunks.Customer.getCustomerListV2(filterProject, filterBuilding)) : '';
            dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, filterBuilding, DEFAULT_ID, filterCustomer, searchText, offset.toString(), rowsPerPage.toString(), isFav));
        }
    }, [filterBuilding, filterCustomer]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            setBuildings(buildingListData(dashboard.buildingList.detail));
        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            // addToast('Building list loading failed');
            setBuildings([]);
            setBuildingId(DEFAULT_ID);
        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!customer.customerListLoading && customer.customerListSuccess) {
            setCustomerList(customerListData(customer.customerList.customerDetailList));
        }
        else if (!customer.customerListLoading && customer.customerListFail) {
            setCustomerList([]);
            setCustomerId(DEFAULT_ID);
        }
    }, [customer.customerListLoading, customer.customerListSuccess, customer.customerListFail]); // eslint-disable-line react-hooks/exhaustive-deps


    const customerListData = (list: customerListV2Data): ItemList => {
        return list?.map((data, customer) => {
            return {
                id: data.id,
                name: data.companyName
            };
        });
    };

    useEffect(() => {
        if (!asset.gatewayDetailsListLoading && asset.gatewayDetailsListSuccess) {
            setEmptyGatewayList(asset.gatewayDetailsList.detail.length <= 0)
            if (asset.gatewayDetailsList.detail.length > 0) {
                const gatewayList = getGatewayAssetList(asset.gatewayDetailsList.detail);
                setGatewayList(gatewayList);
                setTotalRecords(asset.gatewayDetailsList.totalRecordCount);
                setGatewayAssetList(gatewayList);
            } else {
                setGatewayList([]);
                setGatewayAssetList([]);
            }
            setGatewayDataLoaded(true);
        } else if (!asset.gatewayDetailsListLoading && asset.gatewayDetailsListFail) {
            // addToast('device list loading failed');
            setGatewayList([]);
            setEmptyGatewayList(true);
            setGatewayAssetList([]);
        }
    }, [asset.gatewayDetailsListLoading, asset.gatewayDetailsListSuccess, asset.gatewayDetailsListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            if (filterProject !== '' || filterBuilding !== '' || filterCustomer !== '') {
                dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, filterBuilding, '', filterCustomer, '', offset.toString(), rowsPerPage.toString(), isFav));
                setCurrentPage(0);
            } else {
                dispatch(Thunks.Asset.getGatewayDetailsList('', '', '', '', '', offset.toString(), rowsPerPage.toString(), isFav));
                const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
                setCurrentPage(parseInt(currentPageAfterSearchClear));
            }
        }
        // eslint-disable-next-line
    }, [offset, rowsPerPage]);

    useEffect(() => {
        if (!firstCall) {
            if (!organization.markasFavLoading && organization.markasFavSuccess) {
                addToast(t('TOAST_MSG.MARK_AS_FAV_API_SUCCESS'));
                dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, filterBuilding, '', filterCustomer, '', offset.toString(), rowsPerPage.toString(), isFav));
            }
            else if (!organization.markasFavLoading && organization.markasFavFail) {
                if (organization.errorCode === '9183') {
                    addToast(t('ORGANIZATION_MANAGE.MARK_AS_FAV_API_FAILURE_MESSAGE'));
                }
                else if (organization.errorCode === '9001') {
                    addToast(t('TOAST_MSG.REMOVE_ACCESS_ERROR_CODE_9001'));
                }
                else {
                    addToast(t('TOAST_MSG.MARK_AS_FAV_API_FAILED'));
                }
                dispatch(OrganizationActions.markasFavUnmounted());
            }
        }
    }, [organization.markasFavLoading, organization.markasFavSuccess, organization.markasFavFail]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            if (!organization.removeMarkAsFavLoading && organization.removeMarkAsFavSuccess) {
                addToast(t('TOAST_MSG.REMOVE_MARK_AS_FAV_API_SUCCESS'));
                dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, filterBuilding, '', filterCustomer, '', offset.toString(), rowsPerPage.toString(), isFav));
            }
            else if (!organization.removeMarkAsFavLoading && organization.removeMarkAsFavFail) {
                if (organization.errorCode === '9001')
                    addToast(t('TOAST_MSG.REMOVE_ACCESS_ERROR_CODE_9001'));
                else
                    addToast(t('TOAST_MSG.REMOVE_MARK_AS_FAV_API_FAILED'));
                dispatch(OrganizationActions.removeMarkAsFavUnmounted());
            }
        }
    }, [organization.removeMarkAsFavLoading, organization.removeMarkAsFavSuccess, organization.removeMarkAsFavFail]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!firstCall) {
            if (gatewayDataLoaded) {
                /* Debouncing to override user typing */
                const delayDebounceFn = setTimeout(() => {
                    if (searchText !== '') {
                        dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, filterBuilding, DEFAULT_ID, filterCustomer, searchText, offset.toString(), rowsPerPage.toString(), isFav));
                        setCurrentPage(0);
                    } else {
                        dispatch(Thunks.Asset.getGatewayDetailsList('', '', '', '', '', offset.toString(), rowsPerPage.toString(), isFav));
                        const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
                        setCurrentPage(parseInt(currentPageAfterSearchClear));
                    }
                }, 3000)
                return () => clearTimeout(delayDebounceFn)
            }
        }
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps
    const buildingListData = (list: BuildingList): ItemList => {
        return list?.map((data, building) => {
            return {
                id: data.buildingId,
                name: data.name,
            };
        });
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const sortedValue = stableSort(gatewayAssetList, getComparator(order, orderBy));
        setGatewayAssetList(sortedValue);
    };
    const systemCountClick = (value: {
        buildingId: string;
        projectId: string;
        gatewayId: string;
    }): void => {
        props.systemCountClick({ buildingId: value.buildingId, projectId: value.projectId, gatewayId: value.gatewayId });
    };

    const gatewayDetailClose = (): void => {
        setAssetGatewayOpenState(false);
        setSelectedGateway('');
    };

    const handleDisplay = (value: {
        gatewayData: GatewayAssetData;
    }): void => {
        setSelectedGateway(value.gatewayData.gateWayId);
        props.onClick({ gatewayData: value.gatewayData });
        setAssetGatewayOpenState(true);
    };
    const handleShowAll = (value: {
        projectId: string, buildingId: string, gatewayId: string
    }): void => {
        if (props.showAllClick)
            props.showAllClick({ projectId: value.projectId, buildingId: value.buildingId, gatewayId: value.gatewayId });
    };

    function getDetails(): void {
        throw new Error('Function not implemented.');
    }

    const handleFavoriteChange = (
        event: React.ChangeEvent<HTMLElement>,
        checked: boolean,
    ) => {
        if (checked) {
            setIsFav(true);
            dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, filterBuilding, '', filterCustomer, '', DEFAULT_PAGE_ONE.toString(), rowsPerPage.toString(), true));
            setCurrentPage(0);

        } else {
            setIsFav(false);
            dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, filterBuilding, '', filterCustomer, '', offset.toString(), rowsPerPage.toString(), false));
        }
    };

    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (isValidCharWithoutSpace(e.target.value)) {
            setSearchText(e.target.value);
        }
    };
    const resetSearch = (): void => {
        setSearchText('');
    };
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, newPage.toString());
        setOffset(newPage + 1);
        setSelectedGateway(DEFAULT_ID);
    };


    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        setCurrentPage(0);
        setOffset(1);
        setSelectedGateway(DEFAULT_ID);
    };


    return (
        <div id="asset-gateway-container"
            data-testid="asset-gateway-container">
            <div style={{ display: 'flex' }}>

                <Button
                    disabled={true}
                    id="gateway-download-as-button"
                    data-testid="gateway-download"
                    style={{
                        opacity: 0.5,
                        flexDirection: 'row',
                        backgroundColor: PXBColors.blue[700],
                        color: PXBColors.white[50],
                        marginRight: 10,
                        textTransform: 'none'
                    }}
                    onClick={(): void => getDetails()}
                >
                    <GetApp style={{ padding: 3 }} />
                    {md ? t('ASSETS_DETAILS.DOWNLOAD_AS') : ''}
                    <ArrowDropDown style={{ padding: 3 }} />
                </Button>
                <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto' }}>
                    <Typography
                        id="gateway-favorite-filter-checkbox-label"
                        data-testid="gateway-favorite-filter-checkbox-label"
                        style={{ alignSelf: 'center' }} variant="body2">
                        {t('ASSETS_DETAILS.SHOW_FAVORITE_GATEWAYS')}
                    </Typography>

                    <Checkbox id="gateway-favorite-filter-checkbox"
                        onChange={handleFavoriteChange} />

                    <Button
                        style={{
                            width: '20px',
                            backgroundImage: `url(${SyncButtonImage})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'filled',
                            backgroundRepeat: 'no-repeat',
                        }}
                        color="primary"
                        data-testid="cancel-location"
                        onClick={() => {
                            dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, filterBuilding, DEFAULT_ID, searchText, '', offset.toString(), rowsPerPage.toString(), isFav));
                        }}
                    >
                        {''}
                    </Button>
                </div>
            </div>


            <div data-testid="asset-gateways-search-panel" style={{ display: 'flex', }}>

                <DropDownListComponent disabled={!useOrganizationDataList.orgListLoaded || asset.gatewayDetailsListLoading} data-testid="asset-gateways-project-filter" list={projects} value={t('SEARCH.ALL_ORGANIZATIONS')} handleSelection={setFilteredProject} selected={''} defaultSelection={props.defaultProject} ></DropDownListComponent>
                <DropDownListComponent disabled={dashboard.buildingListLoading || asset.gatewayDetailsListLoading} data-testid="asset-gateways-building-filter" list={buildings} value={t('SEARCH.ALL_BUILDINGS')} handleSelection={setFilteredBuilding} selected={buildingId} defaultSelection={props.defaultBuilding}></DropDownListComponent>
                {usertype === USER_ROLE_TYPE.SERVICE_MANAGER && <DropDownListComponent disabled={customer.customerListLoading} data-testid="asset-buildings-customer-filter" list={customerList} value={t('SEARCH.ALL_CUSTOMER')} handleSelection={setFilteredCustomer} selected={customerId} defaultSelection={''} ></DropDownListComponent>}

                <TextField
                    disabled={asset.gatewayDetailsListLoading}
                    inputProps={{ 'data-testid': 'search', maxLength: 100 }}
                    value={searchText}
                    style={{
                        width: 'auto',
                        textAlign: 'right',
                        float: 'right',
                        border: '0px solid #727E84',
                        borderRadius: 5,
                        backgroundColor: 'white',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        overflow: 'hidden',
                        marginTop: 28,
                        fontFamily: 'Open sans',
                        fontSize: 14,
                        color: '#727E84',
                        fontStyle: 'normal',
                        fontWeight: 400,
                    }}
                    placeholder={'Search'}
                    onChange={(e): void => onChangeSearchText(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search data-testid="search-icon" style={{ color: PXBColors.gray[500] }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchText !== '' && (
                                    <IconButton onClick={resetSearch}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>

            <Card style={{ flex: '1 1 0px', overflow: 'auto', marginTop: '2%', width: (selectedGateway.length > 0) ? '77.5%' : '100%', transitionDelay: '0.2s', transitionDuration: '0.5s' }} id="gateway-list-container" data-testid="gateway-list-container">

                <Table stickyHeader size="small"
                    id="gateway-list-table"
                    data-testid="gateway-list-table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />

                    {!asset.gatewayDetailsListLoading && !emptyGatewayList && useOrganizationDataList.orgListLoaded && <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable'}
                        id="gateway-list-table-body"
                        data-testid="gateway-list-table-body">
                        {stableSort(gatewayAssetList, getComparator(order, orderBy)).map((row: GatewayAssetData, index: number) => (
                            <AssetGatewayListItem
                                key={row.gateWayId}
                                row={row}
                                selectedGateway={selectedGateway}
                                displayClick={handleDisplay}
                                systemCountClick={systemCountClick}
                            ></AssetGatewayListItem>
                        ))}
                    </TableBody>}
                </Table>
                {asset.gatewayDetailsListLoading && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!asset.gatewayDetailsListLoading && asset.gatewayDetailsListSuccess && !useOrganizationDataList.orgListLoaded && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!asset.gatewayDetailsListLoading && emptyGatewayList && <EmptyState style={{ flex: 1, height: 200 }}
                    title={t('ORGANIZATION_MANAGE.ERROR_NO_GATEWAY_FOUND')} icon={undefined} placeholder={undefined} />}
                <Box style={{ flex: '1 1 0px', backgroundColor: PXBColors.white[50] }}>
                    {!emptyGatewayList && <TablePagination
                        component="div"
                        data-testid="pagination-users-service-partner"
                        SelectProps={{
                            MenuProps: {
                                sx: {
                                    '.MuiTablePagination-menuItem': {
                                        display: 'flex',
                                        flexDirection: 'column'
                                    },
                                },
                            },
                        }}
                        count={totalRecords}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={t('LABELS.ITEMS_PER_PAGE')}
                        showFirstButton showLastButton
                    />}
                </Box>
            </Card >
            {isAssetGatewayOpen && <Box
                display="flex"
                style={{
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    top: 112,
                    right: 0,
                    zIndex: 100,
                    minHeight: '100%',
                    backgroundColor: PXBColors.white[500],
                    position: 'absolute',
                    visibility: isAssetGatewayOpen ? 'visible' : 'hidden',
                }}
                data-testid="gateway-details"
                sx={{
                    maxWidth: '30%'
                }}
            >
                <DeviceGatewayDetails
                    closeClick={gatewayDetailClose} gatewayId={selectedGateway} isAssetGateway={true} displayClick={handleShowAll} />
            </Box>}
        </div>
    );
};


