import * as ApiRequests from '../../api/requests';
import { AuthHelper } from '../../types/auth-helper-types';
import { AppThunk } from '../actions';
import { SignalRConnectionActions } from '../actions/signalr-connection-actions';

export const SignalRConnectionThunks = {
    getSignalRConnection: (authHelper: AuthHelper): AppThunk => async (dispatch): Promise<void> => {
        dispatch(SignalRConnectionActions.SignalRConnectionStarted());

        const payload = await ApiRequests.getSignalRConnection({}, { authHelper });
        if (payload.ok) {
            dispatch(
                SignalRConnectionActions.SignalRConnectionSucceeded(
                    payload.httpStatusCode,
                    payload.response.data.url,
                    payload.response.data.accessToken
                )
            );
        } else {
            dispatch(SignalRConnectionActions.SignalRConnectionFailed());
        }
    },
};
