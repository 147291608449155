// eslint-disable-next-line
import React from 'react';
import {
    TableRow,
    IconButton,
    Typography,

} from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ChevronRight } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { LuminaryData } from '../../types/luminary';
import { DeviceState, getLuminaryStatusState } from '../../app/enum/ENUM';
import { TableCell } from '@mui/material';

type Props = {
    row: LuminaryData;
    selectedLuminary: string;
    displayClick: Function;
};

export const LuminaryListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const row: LuminaryData = props.row;

    const handleDisplay = (): void => {
        props.displayClick({ luminaryId: row.luminaryId });
    };

    const systemState: DeviceState = getLuminaryStatusState(row.lmStatus);

    return (
        <TableRow
            id={`row${row.luminaryId}`}
            data-testid={`row${row.luminaryId}`}
            style={{ cursor:'pointer', width: '100%', backgroundColor: props.selectedLuminary === row.luminaryId ? PXBColors.blue[50] : PXBColors.white[100] }}
            onClick={(): void => handleDisplay()}
        >
            <TableCell style={{ width: '0%', padding: 0 }}
                id="luminaries-list-item-avtar"
                data-testid="luminaries-list-item-avtar">
            </TableCell>
            <TableCell
                id="luminaries-list-item-name"
                data-testid="luminaries-list-item-name"
                //id={labelId} // ToDo - for selection view handling
                align="left"
                padding="checkbox"
                style={{ width: 'auto', wordWrap: 'break-word',wordBreak:'break-all' }}
            >
                {row.luminaryName}
            </TableCell>

            <TableCell align="left" padding="checkbox" style={{ width: '15%' }}
                id="luminaries-list-item-type"
                data-testid="luminaries-list-item-type">
                {row.shortAddress}
            </TableCell>

            <TableCell align="left" padding="checkbox" style={{ width: '17%', padding: 0, paddingLeft: 16 }}
                id="luminaries-list-item-atsd"
                data-testid="luminaries-list-item-atsd">
                {row.ATSD}
            </TableCell>

            <TableCell
                align="left"
                padding="checkbox"
                style={{ width: '15%', padding: 0, paddingLeft: 12, wordWrap: 'break-word', wordBreak: 'break-all' }}
                id="luminaries-list-item-circuit"
                data-testid="luminaries-list-item-circuit">
                {row.circuit}
            </TableCell>

            <TableCell align="left" style={{ width: '15%', paddingLeft: 10 }}
                id="luminaries-list-item-status"
                data-testid="luminaries-list-item-status">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <div style={{ minWidth: 25 }}>
                        <img
                            src={systemState.statusImage}
                            alt="logo"
                            style={{ display: 'flex', alignItems: 'right' }}
                            id="device-status-icon"
                            data-testid="device-status-icon"
                        /></div>
                    <Typography
                        id="device-status-button"
                        data-testid="device-status-button"

                        style={{ textTransform: 'none', marginLeft: 10 }} variant="body2">
                        {t(systemState.statusTitle)}
                    </Typography>
                </div>
            </TableCell>

            <TableCell style={{ width: '0%', padding: 0 }}>
            </TableCell>

            <TableCell style={{ width: '5%' }}
                id="luminaries-list-item-next"
                data-testid="luminaries-list-item-next">
                {/* May need in future */}
                {/* <IconButton edge="end" id="list-item-next-icon" style={{ marginLeft: "auto" }} >
                    <ChevronRight />
                </IconButton> */}
            </TableCell>

        </TableRow>
    );
};
