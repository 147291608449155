import React from 'react';
import { useAuthState, useAuthHelper, getUserId } from '../../components/AuthContextProvider';

import { useDispatch } from 'react-redux';
import { Actions, Thunks } from '../../actions';

/**
 * This provider stuffs the retrieved data into redux state. The settings
 * page was already implemented based on teh global Redux store (to hold the data)
 * before this was added, so this keeps it working (this is just a way to trigger the
 * data to load).
 *
 * No determination has been made on whether we should continue to use
 * the global Redux store for this kind of data or not.
 */
export const UserSettingsDataProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
   const userId = getUserId(useAuthState());
    const authHelper = useAuthHelper();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (userId) {
            dispatch(Thunks.UserSettings.getUserData(userId, authHelper));
        }

        return (): void => {
            dispatch(Actions.UserSettings.userSettingsProviderUnmounted());
        };
    }, [userId, authHelper, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};
