// eslint-disable-next-line
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ConfirmationPopUp } from '../ConfirmationPopUp/ConfirmationPopUp';
import { useToasts } from '../../data/DataProviders/ToastProvider';

const CustomizedSnackbar: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const { open, popupTitle, message, appearance, removeToast } = useToasts();

    const handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string): void => {
        if (reason === 'clickaway') {
            return;
        }
        removeToast();
    };

    return (
        <>
            {appearance === 'success' && (
                <Snackbar
                    data-testid="customSnackbar"
                    open={open}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={5000}
                    onClose={()=>handleClose}
                    action={
                        <Button color="primary" size="small" onClick={handleClose}>
                            OK
                        </Button>
                    }
                    message={message}
                />
            )}
            {appearance === 'default' && (
                <Snackbar
                    data-testid="customSnackbar"
                    open={open}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={5000}
                    onClose={()=>handleClose}
                    message={message}
                />
            )}
            {appearance === 'error' && (
                <ConfirmationPopUp
                    title={popupTitle}
                    body={
                        <Typography variant="subtitle2" style={{ marginBottom: 30 }}>
                            {message}
                        </Typography>
                    }
                    okButtonText={'OK'}
                    show={open}
                    handleOk={(): void => {
                        removeToast();
                    }}
                    width="xs"
                    okButtonShow={true}
                ></ConfirmationPopUp>
            )}
        </>
    );
};

export default CustomizedSnackbar;
