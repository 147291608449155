import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { UPDATE_INVITATION_REQUEST } from './url-constants';

type UpdateInvitationRequestEndpointSpecification = ApiEndpointSpecification;
export const UpdateInvitationRequestEndpointSpecifications: UpdateInvitationRequestEndpointSpecification = {
    url: UPDATE_INVITATION_REQUEST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'UpdateInvitationRequestRequestParams',
    requestBodySchemaName: 'UpdateInvitationRequestRequestBody',
    okResponseSchemaName: 'UpdateInvitationRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeUpdateInvitationRequest = Helpers.makeApiRequestFn<
    Types.UpdateInvitationRequestRequestParams,
    Types.UpdateInvitationRequestRequestBody,
    Types.UpdateInvitationRequestOkResponse
>(UpdateInvitationRequestEndpointSpecifications);
