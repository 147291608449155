// eslint-disable-next-line
import React from 'react';
import * as DataProviders from '../../types/data-providers';
import { HttpRequest } from '../../api/http-request';

type ContextState = DataProviders.Data<{
    content: string | undefined;
}>;

const Context = React.createContext<ContextState | null>(null);

function useLegalContentData(): ContextState {
    const contextState = React.useContext(Context);
    if (contextState === null) {
        throw new Error('useLegalContentData must be used within a LegalContentDataProvider');
    }
    return contextState;
}

const LegalContentDataProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ filename: string }>>> = (props) => {
    const [state, setState] = React.useState<ContextState>({ state: DataProviders.DataState.Loading });

    React.useEffect(() => {
        (async (): Promise<void> => {
            setState({
                state: DataProviders.DataState.Loading,
            });

            // TODO: No decision has been made about where the legal content will come from.
            //       For now we'll just pull content from the public directory of the React web app.
            //       (The hostname needs to be specified in our test environment)
            const url = `/${encodeURIComponent(props.filename)}`;

            const payload = await HttpRequest.get<string>({
                resource: url,
                prependApiUrl: process.env.NODE_ENV === 'test', // In acceptance tests this needs to come from the API server (there is no other web server to provide the static content in tests)
                responseType: 'text',
            });

            if (payload.status === 200) {
                setState({
                    value: {
                        content: payload.response,
                    },
                    state: DataProviders.DataState.Loaded,
                });

                return;
            }

            setState({ state: DataProviders.DataState.Error, errorMessage: payload.response });
        })();
    }, [props.filename]);

    return <Context.Provider value={state}>{props.children}</Context.Provider>;
};

const TestLegalContentDataProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ value: ContextState }>>> = (props) => (
    <Context.Provider value={props.value}>{props.children}</Context.Provider>
);

export { LegalContentDataProvider, TestLegalContentDataProvider, useLegalContentData };
