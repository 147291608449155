import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_LUMINARY_LIST } from './url-constants';

type LuminaryListRequestEndpointSpecifications = ApiEndpointSpecification;
export const LuminaryListRequestEndpointSpecification: LuminaryListRequestEndpointSpecifications = {
    url: GET_LUMINARY_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'LuminaryListRequestParams',
    requestBodySchemaName: 'LuminaryListRequestBody',
    okResponseSchemaName: 'LuminaryListRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeLuminaryListRequest = Helpers.makeApiRequestFn<
    Types.LuminaryListRequestParams,
    Types.LuminaryListRequestBody,
    Types.LuminaryListRequestOkResponse
>(LuminaryListRequestEndpointSpecification);
