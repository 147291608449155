import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import {GET_SLA_LIST } from './url-constants';

type slaListRequestEndpointSpecifications = ApiEndpointSpecification;
export const slaListRequestEndpointSpecification: slaListRequestEndpointSpecifications = {
    url: GET_SLA_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'SLAListRequestParams',
    requestBodySchemaName: 'SLAListRequestBody',
    okResponseSchemaName: 'SLAListRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeSLAListRequest = Helpers.makeApiRequestFn<
    Types.SLAListRequestParams,
    Types.SLAListRequestBody,
    Types.SLAListRequestOkResponse
>(slaListRequestEndpointSpecification);
