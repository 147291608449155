// eslint-disable-next-line
import React, { useEffect } from 'react';
import {
    ListItem,
    ListItemText,
    Typography,
    useMediaQuery,
} from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { SelectionData, SelectionList } from './SelectionListData';
import { styled, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { reportTypePreferenceList } from './AccountPreferencesData';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { summaryReportTypeList } from './AccountPreferencesData';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        borderBottom: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
        },
    },
}));

type Props = {
    primaryList: SelectionList;
    title?: string;
    primarySelectedItem: number;
    isNotification?: boolean;
    secondaryList?: SelectionList;
    secondarySelectedItem?: number;
    handleSelectionPrimary: Function;
    handleSelectionSecondary?: Function;
    paramType: string;
    paramType2?: string;
    testid?: string;
    disableState?: boolean;
};

export const UserSelectionItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('lg'));
    const [openSummaryFrequency, setOpenSummaryFrequency] = React.useState(false);

    const getUserSelection = (list: SelectionList, userSelectionValue: string): SelectionData => {
        const filteredData = list.filter((lang) => lang.key.toString() === userSelectionValue);
        const userSelectionValues = filteredData.length > 0 ? filteredData[0] : list[0];
        return userSelectionValues;
    };

    const getAPISelection = (list: SelectionList, userSelectionKey: number): SelectionData => {
        const filteredData = list.filter((lang) => lang.key === userSelectionKey);
        const userSelectionKeys = filteredData.length > 0 ? filteredData[0] : list[0];
        return userSelectionKeys;
    };
    const [primarySelection, setPrimarySelection] = React.useState(getAPISelection(props.primaryList, props.primarySelectedItem));
    const [secondarySelection, setSecondarySelection] = React.useState(getAPISelection(props.secondaryList ? props.secondaryList : reportTypePreferenceList, props.secondarySelectedItem ? props.secondarySelectedItem : 1));

    useEffect(() => {
        if (props.primaryList) {
            const select = getUserSelection(props.primaryList, props.primarySelectedItem.toString());
            setPrimarySelection(select);
        }
    }, [props.primarySelectedItem]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.secondaryList) {
            const select = getUserSelection(props.secondaryList, props.secondarySelectedItem ? props.secondarySelectedItem.toString() : '');
            setSecondarySelection(select);
        }
    }, [props.secondarySelectedItem]) // eslint-disable-line react-hooks/exhaustive-deps

    const primaryHandleChange = (event: SelectChangeEvent) => {
        const filteredData = props.primaryList.filter((lang) => lang.value.toString() === event.target.value);
        const userSelectionValues = filteredData.length > 0 ? filteredData[0] : props.primaryList[0];
        setPrimarySelection(userSelectionValues);
        props.handleSelectionPrimary(userSelectionValues, props.paramType);
        if (event.target.value === summaryReportTypeList[2].value)
            setOpenSummaryFrequency(true);
        else
            setOpenSummaryFrequency(false);
    };

    const secondaryHandleChange = (event: SelectChangeEvent) => {
        const filteredData = props.secondaryList?.filter((lang) => lang.value.toString() === event.target.value);
        const userSelectionValues = filteredData !== undefined && filteredData.length > 0 ? filteredData[0] : undefined;
        if (userSelectionValues !== undefined) {
            setSecondarySelection(userSelectionValues);

            if (props.handleSelectionSecondary)
                props.handleSelectionSecondary(userSelectionValues, props.paramType2);
        }
    };

    useEffect(() => {
        if (primarySelection.value === summaryReportTypeList[2].value) {
            setOpenSummaryFrequency(true);
        }
    }, [primarySelection]);

    return (
        <ListItem style={{ display: 'flex', justifyContent: 'row wrap', }} >
            <ListItemText data-testid="list-item-text" style={{ height: 'auto' }}>
                <Typography data-testid={props.testid} style={{ fontWeight: 600, fontSize: 16, lineHeight: 2, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                    {t(primarySelection.description)}
                </Typography>
                <Typography style={{ fontStyle: 'normal', fontWeight: 400, fontSize: 14, lineHeight: 1, color: PXBColors.gray[500] }}>{props.title}</Typography>
            </ListItemText>
            {props.isNotification && (<FormControl sx={{ m: 1 }} variant="standard">
                <Select
                    style={{ width: md ? '25ch' : 'auto', backgroundColor: PXBColors.white[200], height: '40' }}
                    labelId="secondary-select-label"
                    id="secondary-select-label"
                    data-testid="secondary-select-label"
                    value={secondarySelection.value}
                    disabled={openSummaryFrequency || props.disableState}
                    onChange={secondaryHandleChange}
                    input={<BootstrapInput />}
                >
                    {props.secondaryList?.map((option) => (
                        <MenuItem
                            key={option.key} value={option.value} data-testid={option.value}
                            style={{
                                display: 'flex',
                                justifyContent: 'left', fontSize: 16,
                                lineHeight: 1.5, fontWeight: 400,
                                overflow: 'hidden',
                                boxSizing: 'border-box',
                                whiteSpace: 'nowrap',
                                minHeight: 48,
                                paddingTop: 6,
                                paddingBottom: 6,
                                width: 'auto',
                                paddingLeft: 16,
                                paddingRight: 16,
                                textAlign: 'center'
                            }}>
                            {option.value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>)}
            <FormControl sx={{ m: 1 }} variant="standard">
                <Select
                    style={{ width: md ? '25ch' : 'auto', backgroundColor: PXBColors.white[200], height: '40' }}
                    labelId="primary-select-label"
                    id="primary-select-label"
                    data-testid='primary-select-label'
                    value={primarySelection.value}
                    disabled={props.disableState}
                    onChange={primaryHandleChange}
                    input={<BootstrapInput />}
                >
                    {props.primaryList.map((option) => (
                        <MenuItem sx={{ textAlign: 'center' }}
                            key={option.key} value={option.value} data-testid={option.value}
                            style={{
                                display: 'flex',
                                justifyContent: 'left', fontSize: 16,
                                lineHeight: 1.5, fontWeight: 400,
                                overflow: 'hidden',
                                boxSizing: 'border-box',
                                whiteSpace: 'nowrap',
                                minHeight: 48,
                                paddingTop: 6,
                                paddingBottom: 6,
                                width: 'auto',
                                paddingLeft: 16,
                                paddingRight: 16
                            }}>
                            {option.value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </ListItem>
    );
};
