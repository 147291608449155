import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import {GET_PLATFORM_LIST } from './url-constants';

type PlatformListEndpointSpecifications = ApiEndpointSpecification;
export const PlatformListEndpointSpecification: PlatformListEndpointSpecifications = {
    url: GET_PLATFORM_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'PlatformListRequestParams',
    requestBodySchemaName: 'PlatformListRequestBody',
    okResponseSchemaName: 'PlatformListOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokePlatformList = Helpers.makeApiRequestFn<
    Types.PlatformListRequestParams,
    Types.PlatformListRequestBody,
    Types.PlatformListOkResponse
>(PlatformListEndpointSpecification);
