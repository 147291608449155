// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { ItemList } from '../../types/logbook-type';
import { EMPTY_STRING } from '../../constants';
import { OrganizationData } from '../../types/organization';
import { Selectors } from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Thunks } from '../../actions';

const orgDetailsDefault: OrganizationData[] = [{
    projectId: "",
    name: "",
    description: "",
    country: "",
    address: "",
    postalCode: "",
    city: "",
    countryCode: "",
    phone: "",
    email: "",
    systemCount: 11,
    buildingCount: 22,
    gatewayCount: 0,
    adminCount: 2,
    viewerCount: 14,
    isAdmin: false,
    isFavourite: false,
    address2: '',
    primaryFirstName: null,
    primaryLastName: null,
    secondaryFirstName: null,
    secondaryLastName: null,
    secondaryEmail: null,
    secondaryPhone: null,
    secondaryCountryCode: null
}];

type Props = {
    closeClick: Function;
    openDialogFlag: boolean;
    orgList: ItemList;
};
export const AssetAddBuildingDialog: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const [projects] = React.useState<ItemList>(props.orgList);
    const organization = useSelector(Selectors.Organization.organization);
    const dispatch = useDispatch();
    const [orgSelection, setOrgSelection] = React.useState('');
    const history = useHistory();
    const [organizationDetails, setOrganizationDetails] = useState<OrganizationData[]>(orgDetailsDefault);
    const goToCreateBuilding = (): void => {
        pushAppRoute(history,
            { type: 'UpsertBuilding', orgId: orgSelection },
            { backRoute: 'OrganizationDetails', orgId: orgSelection });
    };
    const handleSetOrganization = (event: SelectChangeEvent) => {
        setOrgSelection(event.target.value);
    };

    function closeSelectOrgDialog(): void {
        props.closeClick({});
    }
    const onCancel = () => {
        closeSelectOrgDialog();
        setOrgSelection(EMPTY_STRING);
    }
    useEffect(() => {
        props.openDialogFlag &&
        dispatch(Thunks.Organization.getOrganizationDetailsList(''));
    }, [props.openDialogFlag]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
            if (!organization.orgListLoading && organization.orgListSuccess) {
                setOrganizationDetails(organization.orgList.detail);
            } else if (!organization.orgListLoading && organization.orgListFail) {
                setOrganizationDetails(orgDetailsDefault);
            }
    }, [organization.orgListLoading, organization.orgListSuccess, organization.orgListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const organizationFilteredList: OrganizationData[] = organizationDetails.filter((data, device) => { return (data.isAdmin === true) })

    // const organizationFilteredList: OrganizationData[] = projects.filter((data, device) => { return (data.isAdmin === true) })

    return (
        <Dialog data-testid='orgSelectDialog' id="org-select-dialog" maxWidth="xs" open={props.openDialogFlag} onClose={closeSelectOrgDialog}>
            <DialogTitle data-testid='dialogHeader'>{t('ASSETS_DETAILS.ADD_BUILDING_ASSET')}</DialogTitle>
            <Divider></Divider>
            <DialogContent style={{ paddingTop: 20 }}>
                <>
                    <div>
                        <FormControl variant={'filled'} fullWidth>
                            <InputLabel data-testid='inputLabel' >
                                {t('ASSETS_DETAILS.SELECT_ORGANIZATION_LABEL')}
                            </InputLabel>
                            <Select
                                variant={'filled'}
                                fullWidth style={{ marginBottom: 36, backgroundColor: PXBColors.white[500] }}
                                labelId="org-select-label"
                                inputProps={{ 'data-testid': 'org-select' }}
                                data-testid="org-select"
                                value={orgSelection}
                                onChange={handleSetOrganization}
                            >
                                {organizationFilteredList.map((data) => {
                                    return (
                                        <MenuItem id="org-list" key={data.projectId} value={data.projectId}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left', fontSize: 14,
                                                lineHeight: 1.5, fontWeight: 400,
                                                overflow: 'hidden',
                                                boxSizing: 'border-box',
                                                whiteSpace: 'nowrap',
                                                minHeight: 48,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                width: 'auto',
                                                paddingLeft: 16,
                                                paddingRight: 16
                                            }}>
                                            {data.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </>
            </DialogContent>
            <Divider style={{ color: 'grey' }} />
            <DialogActions style={{ justifyContent: 'space-between', marginTop: 15, marginBottom: 15, marginLeft: 16, marginRight: 17 }}>
                <Button style={{ textTransform: 'none' }} id="btnCancel" data-testid="btnCancel" variant="outlined" color="primary" onClick={(): void => onCancel()}>
                    {t('ACTIONS.CANCEL')}
                </Button>
                <Button
                    style={{
                        background: PXBColors.blue[500], color: PXBColors.white[100],
                        opacity: orgSelection ? 1 : 0.5,
                        textTransform: 'none'
                    }}
                    disabled={!orgSelection}
                    id="btnConfirm"
                    data-testid="btnConfirm"
                    variant="contained"
                    onClick={goToCreateBuilding}
                >
                    {t('ACTIONS.CONFIRM')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

