import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_ORG_USERS } from './url-constants';

type ProjectUserListRequestEndpointSpecifications = ApiEndpointSpecification;
export const ProjectUserListRequestEndpointSpecification: ProjectUserListRequestEndpointSpecifications = {
    url: GET_ORG_USERS,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'ProjectUserListRequestParams',
    requestBodySchemaName: 'ProjectUserListRequestBody',
    okResponseSchemaName: 'ProjectUserListRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeProjectUserListRequest = Helpers.makeApiRequestFn<
    Types.ProjectUserListRequestParams,
    Types.ProjectUserListRequestBody,
    Types.ProjectUserListRequestOkResponse
>(ProjectUserListRequestEndpointSpecification);
