import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_DELETE } from './url-constants';

type UserDeleteEndpointSpecifications = ApiEndpointSpecification;
export const UserDeleteEndpointSpecification: UserDeleteEndpointSpecifications = {
    url: USER_DELETE,
    method: HttpMethod.DELETE,
    requestParamsSchemaName: 'UserDeleteRequestParams',
    requestBodySchemaName: 'UserDeleteRequestBody',
    okResponseSchemaName: 'UserDeleteOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeUserDelete = Helpers.makeApiRequestFn<
    Types.UserDeleteRequestParams,
    Types.UserDeleteRequestBody,
    Types.UserDeleteOkResponse
>(UserDeleteEndpointSpecification);
