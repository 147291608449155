import { InitialState } from '../state';
import { AppReducer } from './app-reducer';

export const navigationReducer: AppReducer<'Navigation'> = (state = InitialState.Navigation, action): typeof state => {
    switch (action.type) {
        case 'Navigation/DrawerToggled':
            return {
                ...state,
                drawerOpen: action.payload.open,
                drawerVariant: action.payload.variant,
            };
        default:
            return state;
    }
};
