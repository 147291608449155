import React from 'react';

const useMounted = (): React.MutableRefObject<boolean> => {
    const mounted = React.useRef(true);
    React.useEffect(
        () => (): void => {
            mounted.current = false;
        },
        []
    );
    return mounted;
};

export default useMounted;
