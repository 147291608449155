// eslint-disable-next-line
import React, { useEffect } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { List } from '@mui/material';
import { number } from '@storybook/addon-knobs';
import { ChannelValue, InfoListItem, ScoreCard } from '@brightlayer-ui/react-components';
import headerBackgroundImage from '../../assets/images/background_topology.png';
import { useDispatch } from 'react-redux';
import { OrganizationThunks } from '../../actions/thunks/organization-thunks';
import { ENTITY_TYPE } from '../enum/ENUM';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Star, StarBorder } from '@mui/icons-material';
import { OrganizationActions } from '../../actions/actions/organization-actions';

type Props = {
    id: string;
    title: string;
    subtitle: string;
    headerInfo: string;
    systemCount: number;
    buildingCount: number;
    orgAdminCount: number;
    orgViewerCount: number;
    loadingComponent: React.ElementType;
    isFavorite: number;
    onClick: Function;
};

export const OrgDetailsCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));

    const viewOrganizationClick = (): void => {
        props.onClick({ orgId: props.id, orgName: props.title });
    };

    const handleClick = (): void => {
        dispatch(OrganizationThunks.markAsFav(
            props.id,
            ENTITY_TYPE.ORGANIZATION
        ));
    }

    const handleRemoveMarkAsFav = (): void => {
        dispatch(OrganizationThunks.removeMarkAsFav(
            props.id,
            ENTITY_TYPE.ORGANIZATION
        ));
    }

    useEffect(() => {
        dispatch(OrganizationActions.removeMarkAsFavUnmounted());
        dispatch(OrganizationActions.markasFavUnmounted());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const systemText = props.systemCount > 1 ? t('ORGANIZATION_MANAGE.SYSTEM_COUNT_S') : t('ORGANIZATION_MANAGE.SYSTEM_COUNT');
    const buildingText = props.buildingCount > 1 ? t('ORGANIZATION_MANAGE.BUILDING_COUNT_S') : t('ORGANIZATION_MANAGE.BUILDING_COUNT');
    const orgAdminText = props.orgAdminCount > 1 ? t('ORGANIZATION_MANAGE.ADMIN_COUNT_S') : t('ORGANIZATION_MANAGE.ADMIN_COUNT');
    const orgViewerText = props.orgViewerCount > 1 ? t('ORGANIZATION_MANAGE.VIEWER_COUNT_S') : t('ORGANIZATION_MANAGE.VIEWER_COUNT');
    const scoreCardCss = { minWidth: md ? 350 : 300, maxWidth: md ? 370 : 320, margin: "0 45px 30px 0" }

    return (
        <ScoreCard
            id="list-item-card"
            data-testid="list-item-card"
            style={scoreCardCss}
            headerTitle={props.title}
            headerSubtitle={props.subtitle}
            headerInfo={props.headerInfo}
            headerColor={PXBColors.blue[500]}
            headerFontColor={PXBColors.white[50]}
            headerBackgroundImage={headerBackgroundImage}
            actionItems={[props.isFavorite === 1 ? <Star data-testid='fav-button'  onClick={handleRemoveMarkAsFav} /> : <StarBorder onClick={handleClick} />]}
            actionLimit={number('actionLimit', 3, { range: true, min: 1, max: 6, step: 1 })}
            actionRow={<InfoListItem dense chevron data-testid="bottom-view-org" title={t('ORGANIZATION_MANAGE.VIEW_ORGANIZATION')} hidePadding onClick={viewOrganizationClick} translate={'no'} placeholder={undefined} />}
            translate={'no'} placeholder={undefined}    >
            <List style={{ margin: 15, display: 'flex', flexDirection: 'column' }}>
                <ChannelValue style={{ marginTop: 10 }} data-testid="list-item-text-system" value={props.systemCount} units={systemText} placeholder={undefined} />
                <ChannelValue style={{ marginTop: 10 }} data-testid="list-item-text-building" value={props.buildingCount} units={buildingText} placeholder={undefined} />
                <ChannelValue style={{ marginTop: 10 }} data-testid="list-item-text-admin" value={props.orgAdminCount} units={orgAdminText} placeholder={undefined} />
                <ChannelValue style={{ marginTop: 10 }} data-testid="list-item-text-viewer" value={props.orgViewerCount} units={orgViewerText} placeholder={undefined} />
            </List>
        </ScoreCard>
    );
};
