import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { ADD_PLATFORM, ADD_SERVICE_MANAGER } from './url-constants';

type AddServiceManagerEndpointSpecifications = ApiEndpointSpecification;
export const AddServiceManagerEndpointSpecification: AddServiceManagerEndpointSpecifications = {
    url: ADD_SERVICE_MANAGER,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'AddServiceManagerRequestParams',
    requestBodySchemaName: 'AddServiceManagerRequestBody',
    okResponseSchemaName: 'AddServiceManagerRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeAddServiceManager = Helpers.makeApiRequestFn<
    Types.AddServiceManagerRequestParams,
    Types.AddServiceManagerRequestBody,
    Types.AddServiceManagerOkResponse
>(AddServiceManagerEndpointSpecification);
