import { UserInvitationDetailModel } from "../types";

export type CountryList = Array<{
    name: string;
    code: string;
}>;

export type RegistrationState = {
    loading: boolean;
    success: boolean;
    eulaLoading: boolean;
    eula: string;
    validationSuccess: boolean;
    validationloading: boolean;
    errorCode: string;
    message: string;
    email: string;
    organizationName: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    existingUser: boolean;
    inviteUserLoading: boolean;
    inviteUserSuccess: boolean;
    validateEmailLoading: boolean,
    validateEmailSuccess: boolean,
    validateEmailFail: boolean,
    validateEmailUserType: number,
    validateVerificationUserType : number,
    verificationCodeLoading: boolean,
    verificationCodeSuccess: boolean,
    verificationCodeFail: boolean,
    verifyCodeLoading: boolean,
    verifyCodeSuccess: boolean,
    verifyCodeFail: boolean,
    invitationLoading: boolean,
    invitationSuccess: boolean,
    invitationFail: boolean,
    invitationDetail: UserInvitationDetailModel,
    upsertUserAccessProfileLoading: boolean,
    upsertUserAccessProfileSuccess: boolean,
    upsertUserAccessProfileFail: boolean,
    countryListLoading: boolean,
    countryListSuccess: boolean,
    countryListFail: boolean,
    countryList: CountryList,
    registrationLoading: boolean,
    registrationSuccess: boolean,
    registrationFail: boolean,
    verificationCode: string,
};

export type Country = {
    name: string;
    code: string;
};

export const initialRegistrationState: RegistrationState = {
    eulaLoading: false,
    eula: '',
    loading: false,
    success: false,
    validationSuccess: false,
    validationloading: true,
    errorCode: '',
    message: '',
    email: '',
    organizationName: '',
    firstName: '',
    lastName: '',
    phone: '',
    existingUser: false,
    inviteUserLoading: false,
    inviteUserSuccess: false,
    validateEmailLoading: false,
    validateEmailSuccess: false,
    validateEmailUserType: 0,
    validateVerificationUserType:0,
    validateEmailFail: false,
    verificationCodeLoading: false,
    verificationCodeSuccess: false,
    verificationCodeFail: false,
    verifyCodeLoading: false,
    verifyCodeSuccess: false,
    verifyCodeFail: false,
    invitationLoading: false,
    invitationSuccess: false,
    invitationFail: false,
    invitationDetail: { emailId: '', userType: 1 },
    upsertUserAccessProfileLoading: false,
    upsertUserAccessProfileSuccess: false,
    upsertUserAccessProfileFail: false,
    countryListLoading: false,
    countryListSuccess: false,
    countryListFail: false,
    countryList: [],
    registrationLoading: false,
    registrationSuccess: false,
    registrationFail: false,
    verificationCode: ''
};
