// eslint-disable-next-line
import React from 'react';
import { StatusLabel } from '../StatusLabel';
import * as PXBColors from '@brightlayer-ui/colors';

type Props = {
    label: string
}

export const GenericStatusBlueLabel: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    return (
        <StatusLabel
            status={props.label}
            foregroundColor={PXBColors.blue[600]}
            backgroundColor={PXBColors.blue[50]}
            testId="scheduled-status-label"
        />
    );
};
