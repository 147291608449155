import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { UPSERT_USER_ACCESS_PROFILE } from './url-constants';

type UpsertUserAccessProfileEndpointSpecifications = ApiEndpointSpecification;
export const UpsertUserAccessProfileEndpointSpecification: UpsertUserAccessProfileEndpointSpecifications = {
    url: UPSERT_USER_ACCESS_PROFILE,
    method: HttpMethod.PUT,
    requestParamsSchemaName: 'UserAccessProfileRequestParams',
    requestBodySchemaName: 'UserAccessProfileRequestBody',
    okResponseSchemaName: 'UserAccessProfileOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeUpsertUserAccessProfile = Helpers.makeApiRequestFn<
    Types.UserAccessProfileRequestParams,
    Types.UserAccessProfileRequestBody,
    Types.UserAccessProfileOkResponse
>(UpsertUserAccessProfileEndpointSpecification);
