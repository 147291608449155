import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_SERVICE_PARTNER_DETAIL } from './url-constants';

type ServicePartnerDetailEndpointSpecification = ApiEndpointSpecification;
export const ServicePartnerDetailEndpointSpecifications: ServicePartnerDetailEndpointSpecification = {
    url: GET_SERVICE_PARTNER_DETAIL,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'ServicePartnerDetailRequestParams',
    requestBodySchemaName: 'ServicePartnerDetailRequestBody',
    okResponseSchemaName: 'ServicePartnerDetailOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeServicePartnerDetail = Helpers.makeApiRequestFn<
    Types.ServicePartnerDetailRequestParams,
    Types.ServicePartnerDetailRequestBody,
    Types.ServicePartnerDetailOkResponse
>(ServicePartnerDetailEndpointSpecifications);
