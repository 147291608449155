// eslint-disable-next-line
import React, { useState } from 'react';
import { CardContent, TextField, Typography, Button, Divider } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { VERIFICATION_CODE } from '../../constants';

type Props = {
    verifyEmail?: string;
    errorMessage: string;
    resendCodeClicked?: Function
    changeNextEnabled?: Function;
    changeNextLabel?: Function;
    onVerifyCodeChanged?: Function;
};

export const VerifyEmail: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const { verifyEmail, errorMessage, resendCodeClicked, changeNextEnabled, changeNextLabel, onVerifyCodeChanged } = props;
    const [verifyCode, setVerifyCode] = useState(verifyEmail || '');

    if (changeNextEnabled) {
        changeNextEnabled(VERIFICATION_CODE.test(verifyCode));
    }
    if (changeNextLabel) {
        changeNextLabel(t('ACTIONS.NEXT'));
    }

    const onChangeVerifyCode = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setVerifyCode(e.target.value);
            if (onVerifyCodeChanged) onVerifyCodeChanged(e.target.value);
        }
    };

    const onResendVerificationCode = (): void => {
        if (resendCodeClicked) {
            resendCodeClicked()
        }
    }

    return (
        <>
            <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }}>
                <Typography variant="body1" component="p" data-testid='verificationTextInfo' style={{ marginBottom: 40 }}>
                    {t('VERIFY_EMAIL.EMAIL_INFO')}
                </Typography>
                <Divider />
                <TextField
                    date-testid='placeholderCheck'
                    style={{ marginTop: 32 }}
                    variant={'filled'}
                    label={t('FORMS.VERIFICATION_CODE')}
                    inputProps={{ 'data-testid': 'verify-code', maxLength: 8 }}
                    value={verifyEmail}
                    error={Boolean(errorMessage)}
                    helperText={t(errorMessage)}
                    required
                    fullWidth
                    onChange={(e): void => onChangeVerifyCode(e)}
                />
                <div style={{ width: 'wrap', marginTop: 36, left: 10, display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" component="p" data-testid='textCheck'>
                        {t('ACTIONS.RESEND_VERIFICATION_EMAIL_LABEL')}
                    </Typography>
                    <Button
                        type="submit"
                        color="primary"
                        style={{ width: 'wrap', textTransform: 'none', fontSize:16 }}
                        data-testid="sumbit-verify-code"
                        onClick={onResendVerificationCode}
                    >
                        {t('ACTIONS.RESEND_VERIFICATION_EMAIL')}
                    </Button>
                </div>
            </CardContent>
        </>
    );
};
