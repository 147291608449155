// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import * as PXBColors from '@brightlayer-ui/colors';
import { GetApp } from '@mui/icons-material';
import { CircularProgress, IconButton, List, ListSubheader } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Thunks } from '../../actions';
import { GenericToolbar } from '../GenericToolbar';
import { ToolbarColor } from '../GenericToolbar/GenericToolbar';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Selectors } from '../../selectors';
import { EmptyState, InfoListItem } from '@brightlayer-ui/react-components';
import NavReport from '../../assets/images/nav_reports.svg';
import { ENTITY_TYPE, getDeviceType } from '../../app/enum/ENUM';
import { monthFormatDate } from '../../app/enum/DateTimeFormat';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { OrganizationActions } from '../../actions/actions/organization-actions';
import { AssetActions } from '../../actions/actions/asset-actions';
import { ReportData } from '../../types/report';

type Props = {
    location: any;
};

export const ReportsDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const history = useHistory();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const report = useSelector(Selectors.Report.reports);
    //@ts-ignore
    const { entityId, entityType } = useParams();
    const [emptyHealthReportList, setEmptyHealthReportList] = React.useState(false);
    const [healthReportList, setHealthReportList] = useState<ReportData>([]);
    const [buildingName, setBuildingName] = useState('Loading...');
    const organization = useSelector(Selectors.Organization.organization);
    const asset = useSelector(Selectors.Dashboard.asset);
    const [systemName, setSystemName] = useState('Loading...');
    const [systemType, setSystemType] = useState(0);


    useEffect(() => {
        if (entityType == ENTITY_TYPE.BUILDING.toString()) {
            dispatch(Thunks.Report.getHealthReport(ENTITY_TYPE.BUILDING.toString(), entityId ? entityId : ""));
            dispatch(Thunks.Organization.getBuildingDetailsList('', entityId));
        }
        else {
            dispatch(Thunks.Report.getHealthReport(ENTITY_TYPE.SYSTEM.toString(), entityId ? entityId : ""));
            dispatch(Thunks.Asset.getDeviceList('', '', entityId, '', ''));
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!organization.buildingListLoading && organization.buildingListSuccess) {
            if (organization.buildingList.length)
                setBuildingName(organization.buildingList[0].name);
        } else if (!organization.buildingListLoading && organization.buildingListFail) {
            addToast(t('TOAST_MSG.BUILDING_LIST_API_FAILURE'), 'error');
        }
        dispatch(OrganizationActions.buildingListUnmounted());
    }, [organization.buildingListLoading, organization.buildingListSuccess, organization.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.deviceListLoading && asset.deviceListSuccess) {
            if (asset.deviceList.detail.length) {
                setSystemName(asset.deviceList.detail[0].systemName);
                setSystemType(asset.deviceList.detail[0].type);
            }
        } else if (!asset.deviceListLoading && asset.deviceListFail) {
            addToast(t('TOAST_MSG.DEVICE_LIST_API_FAILURE'), 'error');
        }
        dispatch(AssetActions.deviceListUnmounted());
    }, [asset.deviceListLoading, asset.deviceListSuccess, asset.deviceListFail]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!report.healthReportListLoading && report.healthReportListSuccess) {
            setEmptyHealthReportList(report.healthReportList.detail.length <= 0);
            setHealthReportList(report.healthReportList.detail);
        } else if (!report.healthReportListLoading && report.healthReportListFail) {
            setEmptyHealthReportList(true);
            setHealthReportList([]);
        }
    }, [report.healthReportListLoading, report.healthReportListSuccess, report.healthReportListFail]); // eslint-disable-line react-hooks/exhaustive-deps


    const onReportSelected = (entityType: number, entityId: string, year: number, month: number) => {
        //TO-DO download report details
        dispatch(Thunks.Report.downloadHealthReport(entityType, entityId, year, month));
    };

    const header = entityType == ENTITY_TYPE.BUILDING.toString() ? buildingName : "\"" + systemName + "\" " + getDeviceType(systemType);

    return (
        <>
            <div data-testid="reports-details-container">
                <GenericToolbar
                    title={t('DASHBOARD.DASHBOARD_REPORTS_TITLE')}
                    subtitle={header}
                    showBackButton={true}
                    backButtonOnClick={(): void => {
                        pushAppRoute(history,
                            { type: 'Reports' },
                            { backRoute: 'ReportsDetails' });
                    }}
                    color={ToolbarColor.Default}
                />

                {!emptyHealthReportList && !report.healthReportListLoading &&
                    healthReportList.length > 0 && <List
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            position: 'relative',
                            maxHeight: 300,
                            '& ul': { padding: 0 },
                        }}
                        subheader={<li />}
                    >
                        {healthReportList.map((item, key) => (
                            <li key={`section-report-${item.reports ? item.year + item.reports[0].month : item.year + key}`}>
                                <ul>
                                    <ListSubheader
                                        data-testid="report-year"
                                        style={{
                                            backgroundColor: PXBColors.white[200],
                                            color: PXBColors.blue[500],
                                            width: '50%', marginLeft: 'auto',
                                            marginRight: 'auto',
                                            marginBottom: 28,
                                            marginTop: 28,
                                            fontSize: 34,
                                            fontWeight: 400,
                                            paddingLeft: 0
                                        }}>{item.year}</ListSubheader>
                                    {item.reports && item.reports.map((reportitem) => (
                                        <>
                                            <InfoListItem
                                                style={{
                                                    justifyContent: 'space-between',
                                                    width: '50%', marginLeft: 'auto', marginRight: 'auto',
                                                    backgroundColor: PXBColors.white[50]
                                                }}
                                                translate="no"
                                                avatar={false}
                                                hidePadding
                                                divider={'full'}
                                                data-testid="monthly-report"
                                                title={t('REPORTS.MONTHLY_REPORT')}
                                                subtitle={monthFormatDate(reportitem.fromDate, reportitem.toDate)}
                                                info={reportitem.issueCount > 0 ? t('REPORTS.ISSUES', { replace: { issueCount: reportitem.issueCount } }) : t('REPORTS.NO_ISSUES')}
                                                rightComponent={<IconButton
                                                    data-testid="report-details"
                                                    onClick={() => { onReportSelected(reportitem.entityType, reportitem.entityId, parseInt(item.year), reportitem.month); } }
                                                >
                                                    <GetApp id="report-list-item-download-icon" data-testid="report-list-item-download-icon" />
                                                </IconButton>} placeholder={undefined}                                            ></InfoListItem>
                                        </>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </List>
                }

                {report.healthReportListLoading && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!report.healthReportListLoading && emptyHealthReportList && <EmptyState style={{ flex: 1, height: 400 }}
                title={t('ORGANIZATION_MANAGE.ERROR_NO_REPORT_FOUND')} icon={<img
                    src={NavReport}
                    alt="nav_report" />} placeholder={undefined} />}
            </div>
        </>
    );
};
