// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import {
    Divider,
    Card,
    MenuItem,
    Typography,
    Box,
} from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { Button } from '@mui/material';
import { AccountCircle, Call, ChevronRight, Delete, Edit, Fingerprint, Notes, Person, Room } from '@mui/icons-material';
import { OrgDetailListItem } from '../../components/OverviewList/OrgDetailListItem';
import { COLOR_GRAY_ICON } from '../../constants/color-codes';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { PlatformSystemInfoList, platformDetail } from '../../types/platform';
import { AssetListItem } from '../../components/AssetListItem/AssetListItem';
import { ACTION_TYPE_NEXT } from '../../constants';
import { vh_18, vh_65, vh_900, vw_300 } from '../../constants/dimentions';
import { PlatformSystemInfo } from './PlatformSystemInfoDetail';
import { useDispatch, useSelector } from 'react-redux';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import { EditPlatformDialog } from '../user-management/EditPlatformDialog';

type Props = {
    platformInfo: platformDetail;
};

export const PassInfoCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const [isPlatformSystemInfoDialogOpen, setIsPlatformSystemInfoDialogOpen] = React.useState(false);
    const [openEditPlatformDialog, setOpenEditPlatformDialog] = React.useState(false);
    const user = useSelector(Selectors.Usermanagement.usermanagement);

    const platFormSystemInfoDialogClose = (): void => {
        setIsPlatformSystemInfoDialogOpen(false);
    };

    const [platformSystemInfo, setPlatformSystemInfo] = useState<PlatformSystemInfoList>([]);
    const [systemCount, setSystemCount] = useState<number>();


    useEffect(() => {
        if (!user.platformSystemInfoLoading && user.platformSystemInfoSuccess) {
            setPlatformSystemInfo(user.platformSystemInfo);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setSystemCount(user.platformSystemInfo[0].SystemCount + user.platformSystemInfo[1].SystemCount + user.platformSystemInfo[2].SystemCount + user.platformSystemInfo[3].SystemCount + user.platformSystemInfo[4].SystemCount + user.platformSystemInfo[5].SystemCount + user.platformSystemInfo[6].SystemCount);
        } else if (!user.platformSystemInfoLoading && user.platformSystemInfoFail) {
            setPlatformSystemInfo([]);
        }
    }, [user.platformSystemInfoLoading, user.platformSystemInfoSuccess, user.platformSystemInfoFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const onBuildingContactSelect = (): void => {
        setIsPlatformSystemInfoDialogOpen(true);
    };

    return (
        <Card style={{ height: 'fit-content', flex: '1 1 0px', overflow: 'auto' }}
            id="platform-detail-info-container"
            data-testid="platform-detail-info-container">
            <div style={{ display: 'flex', justifyContent: 'center', padding: 16 }} data-testid="platform-logo">
                {props.platformInfo.imageBase64 ? <img style={{ display: 'flex', width: 100, height: 100 }} src={"data:image/jpeg;base64," + props.platformInfo.imageBase64} alt="" /> : <AccountCircle style={{ display: 'flex', width: 96, height: 96, justifyContent: 'center', alignItems: 'center', flexShrink: 0, color: COLOR_GRAY_ICON }} />}
            </div>
            <Divider />
            <InfoListItem data-testid='platform-name' title={props.platformInfo.customerName}
                icon={<Person style={{ color: COLOR_GRAY_ICON }} />}
                iconAlign={'center'}
                divider={'full'} placeholder={undefined}
            />
            <InfoListItem data-testid='platform-number' title={props.platformInfo.customerNumber} subtitle={t('CUSTOMER_DETAILS.CUSTOMER_NUMBER')}
                icon={<Fingerprint style={{ color: COLOR_GRAY_ICON }} />}
                iconAlign={'center'}
                divider={'full'} placeholder={undefined}
            />
            <OrgDetailListItem
                title1={props.platformInfo.customerAddress1}
                title2={props.platformInfo.CustomerAddress2 + ' ' + props.platformInfo.CustomerCity}
                title3={props.platformInfo.country}
                subtitle={t('ORGANIZATION_DETAILS.ADDRESS')}
                icon={<Room style={{ color: COLOR_GRAY_ICON }} />}
                iconAlignment={'center'}
                displayAction={false}
                dividerDisable={true}
            />
            <Divider />
            <OrgDetailListItem
                title1={'+' + (props.platformInfo.customerCountryCode ? props.platformInfo.customerCountryCode : '') + ' ' + (props.platformInfo.customerPhone ? props.platformInfo.customerPhone : '')}
                title2={''}
                title3={''}
                subtitle={t('FORMS.PHONE_NUMBER')}
                icon={<Call style={{ color: COLOR_GRAY_ICON }} />}
                iconAlignment={'center'}
                displayAction={false}
                dividerDisable={true}
            />
            <Divider />
            <OrgDetailListItem
                title1={''}
                title2={''}
                title3={''}
                subtitle={props.platformInfo.customerComments}
                subtitle2={''}
                icon={<Notes style={{ color: COLOR_GRAY_ICON }} />}
                iconAlignment={'center'}
                displayAction={false}
                dividerDisable={true}
            />
            <Divider />
            <AssetListItem
                key={'device-profile-building-contact'}
                title={t('SEARCH.ALL_SYSTEMS') + ' ' + '(' + systemCount + ')'}
                subtitle={''}
                imageType={ACTION_TYPE_NEXT}
                OnItemSelected={(): void => onBuildingContactSelect()}
                divider={true}
                titleBold={true}
            ></AssetListItem>

            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15, marginRight: 15, marginBottom: 15 }} >
                <Button
                    style={{
                        display: 'flex',
                        height: '36px',
                        padding: '0px 16px 0px 12px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '8px',
                        alignSelf: 'stretch',
                        backgroundColor: PXBColors.blue[500],
                        color: PXBColors.white[50],
                        marginTop: 15,
                        textTransform: 'none',
                        borderRadius: '4px'
                    }}
                    id="platform-edit-button"
                    data-testid="platform-edit-button"
                    variant="contained"
                    onClick={() => {
                        setOpenEditPlatformDialog(true)
                        dispatch(Thunks.UserManagement.getPlatformDetail(props.platformInfo.id))
                    }}
                >
                    <Edit style={{ padding: 3 }} />
                    {t('CUSTOMER_DETAILS.EDIT_PLATFORM_INFO')}
                </Button>


                <Button
                    disabled={true}
                    style={{
                        display: 'flex',
                        height: '36px',
                        padding: '0px 16px 0px 12px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '8px',
                        alignSelf: 'stretch',
                        borderRadius: '4px',
                        backgroundColor: PXBColors.white[50],
                        color: PXBColors.red[500],
                        borderColor: PXBColors.red[500],
                        marginTop: 15,
                        textTransform: 'none',
                        opacity: 0.5
                    }}
                    id="platform-delete-button"
                    data-testid="platform-delete-button"
                    variant="outlined"
                    onClick={(): void => { }}
                >
                    <Delete style={{ padding: 3 }} />
                    {t('CUSTOMER_DETAILS.DELETE_CUSTOMER')}
                </Button>

            </div>
            <Box
                display="flex"
                style={{
                    flexDirection: 'column',
                    top: vh_65,
                    justifyContent: 'flex-end',
                    right: vh_18,
                    width: vw_300,
                    zIndex: 100,
                    minHeight: vh_900,
                    backgroundColor: PXBColors.white[500],
                    position: 'absolute',
                    transitionDelay: '0.2s',
                    visibility: isPlatformSystemInfoDialogOpen ? 'visible' : 'hidden',
                }}
                data-testid="buildingDetails"
            >
                <PlatformSystemInfo
                    closeClick={platFormSystemInfoDialogClose} PlatformId={props.platformInfo.id} />
            </Box>
            <EditPlatformDialog
                openDialogFlag={openEditPlatformDialog} closeClick={() => setOpenEditPlatformDialog(false)

                } ></EditPlatformDialog>
        </Card>
    );
};
