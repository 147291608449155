// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link, useLocation, useHistory } from 'react-router-dom';
window.Buffer = window.Buffer || require("buffer").Buffer;

// Material-UI components
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    CircularProgress,
    Select,
    MenuItem,
    SelectChangeEvent,
    FormControl,
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { BrandedCardContainer } from '../../components/BrandedCardContainer/BrandedCardContainer';

// Constants
import { INVITE_USER_TYPE_KEY, LOGIN_ATTEMPTS, LOGIN_PLATFORM } from '../../constants';
import * as PXBColors from '@brightlayer-ui/colors';
import BrandLogo from '../../assets/images/eaton_logo.svg';
import BrightlayerBuildings from '../../assets/images/Brightlayer_Buildings.svg';
import BackgroundImage from '../../assets/images/Login_Background.png';
import cyberCertifyLogo from '../../assets/images/cybersecurity_certified.svg';
import ProjectName from '../../assets/images/safety-management-system.svg'
import LanguageIcon from '../../assets/images/language-icon.svg'
import {
    useAuthState,
    isAuthenticated,
    useAuthHelper,
    getRememberMe,
    getUserEmail,
} from '../../components/AuthContextProvider';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { EmptyState } from '@brightlayer-ui/react-components';
import { toBase64 } from '../../lib/encrypt-helpers';
import { getInviteUserModel, INVITE_USER_TYPE, LANGUAGE } from '../enum/ENUM';
import { COLOR_GRAY_ICON } from '../../constants/color-codes';
import { pushAppRoute } from '../../data/domain/route-manager';
import { AuthenticationActions } from '../../actions/actions/authentication-actions';
import { validEmail } from '../../lib/form-validator';
import { useTheme } from '@mui/material';
import './main.css';
import { getLanguageList, getLanguageListvalue, languageList } from '../../components/UserDetails/AccountPreferencesData';
import i18n from '../../data/translations/i18n';
import { SessionStorage } from '../../components/AuthContextProvider/session-storage';

export const Login: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const history = useHistory();
    const location: any = useLocation();
    const theme = useTheme();
    const { t } = useLanguageLocale();
    const authState = useAuthState();
    const authHelper = useAuthHelper();
    const dispatch = useDispatch();
    const inActiveLogoutMessage = useSelector(Selectors.Dashboard.inActiveLogOut);
    const loginState = useSelector(Selectors.Authentication.login);
    const rememberMeChecked = getRememberMe(authState);
    const rememberMeEmail = getUserEmail(authState) || '';
    const [inActiveLogout, setInactiveLogOut] = useState(inActiveLogoutMessage);
    const [showPassword, setShowPassword] = useState(false);
    const Language = getLanguageList(SessionStorage.readLanguage());
    const [email, setEmail] = useState<string>(rememberMeEmail);
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(rememberMeChecked);
    const [selectedLanguage, setSelectedLanguage] = React.useState(Language.value);
    const [selectedLanguageKey, setSelectedLanguageKey] = React.useState(Language.key);
    const [changeLanguageState, setChangeLanguageState] = React.useState(true);

    //const registrationLink = "/register/0/self-registration/"; // ToDo - hanlde simple navigation
    const registrationLink = "/register/" + toBase64(INVITE_USER_TYPE_KEY + INVITE_USER_TYPE.TYPE_NEW_USER_SELF_REGISTER) + "/" + getInviteUserModel(INVITE_USER_TYPE.TYPE_NEW_USER_SELF_REGISTER).value + "/";

    useEffect(() => {
        const language = getLanguageListvalue(selectedLanguage);
        i18n.changeLanguage(language.code);
        setChangeLanguageState(false);
    }, [navigator.language, changeLanguageState, Language.value]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isAuthenticated(authState)) {
        const { from } =
            location && location.state && 'from' in location.state ? location.state : { from: { pathname: '/' } };
        return <Redirect to={from} />;
    }

    const goToRegistration = (usertype: string, email: string): void => {
        pushAppRoute(history, { type: 'Registration', inviteUserType: usertype, inviteUserEmail: email });
    };

    if (!isAuthenticated(authState) && (loginState.errorCode === 9137)) {
        dispatch(AuthenticationActions.loginUnmount());
        goToRegistration(toBase64(INVITE_USER_TYPE_KEY + INVITE_USER_TYPE.TYPE_NEW_USER_INCOMPLETE_REGISTER), email);
    }
    // This is for other adopter incomplete navigation to company details
    if (!isAuthenticated(authState) && loginState.errorCode === 9161) {
        goToRegistration(toBase64(INVITE_USER_TYPE_KEY + INVITE_USER_TYPE.TYPE_NEW_OTHER_ADOPTER_USER_INCOMPLETE_REGISTER), email);
        dispatch(AuthenticationActions.loginUnmount());
    }

    const canLogIn = (): boolean => Boolean(validEmail(email) && password.length > 0);
    const onLogIn = (): void => {
        setInactiveLogOut(false);
        dispatch(Thunks.Authentication.login(email, password, remember, authHelper.onUserAuthenticated,  LOGIN_PLATFORM, '', selectedLanguageKey));
        sessionStorage.setItem(LOGIN_ATTEMPTS , '1')
    };
    const handleClickShowPassword = (): void => {
        setShowPassword(!showPassword);
    };
    const selectionLanguageChangeHandler = (event: SelectChangeEvent) => {
        setSelectedLanguage(event.target.value as string);
        const language = getLanguageListvalue(event.target.value as string);
        setSelectedLanguageKey(language.key);
        i18n.changeLanguage(language.code);
        SessionStorage.saveLanguage(language.code || LANGUAGE.ENGLISH);
    }

    return (
        <>
            <BrandedCardContainer data-testid="login-container">
                <>
                    {loginState.loading && <EmptyState style={{ flex: 1, backgroundColor: PXBColors.white[50] }}
                        icon={<CircularProgress id="progress-spinner" />} title={t('LABELS.LOGGING_IN')} placeholder={undefined} />
                    }
                    {!loginState.loading &&
                        <form
                            onSubmit={(evt): void => {
                                evt.preventDefault();
                                onLogIn();
                            }}
                        >
                            <div style={{ padding: '0px' }} data-testid="login-form">
                                <div
                                    className="bg_image"
                                    style={{
                                        backgroundImage: `url(${BackgroundImage})`,
                                        backgroundSize: 'cover',
                                        top: '150px',
                                        height: '150px',
                                        color: '#FFFFFF',
                                    }}
                                >
                                    <div style={{ display: 'flex' }}>
                                        <img
                                            data-testid="logo"
                                            style={{ paddingTop: 50, paddingLeft: 50, paddingRight: '30px', display: 'flex', flexDirection: 'column' }}
                                            src={ProjectName}
                                            alt="logo"
                                        />
                                    </div>
                                </div>
                                {inActiveLogout && (
                                    <Typography
                                        variant="subtitle1"
                                        color="error"
                                        style={{ paddingBottom: 10, paddingLeft: 25 }}
                                    >
                                        {t('INACTIVITY.INACTIVE_LOGOUT')}
                                    </Typography>
                                )}
                            </div>
                            <div style={{ padding: '0px 40px', display: 'flex', flexDirection: 'column' }}>
                                <TextField
                                    label={t('LABELS.EMAIL')}
                                    id="email"
                                    data-testid="email-label"
                                    name="email"
                                    className='formFields'
                                    value={email}
                                    onChange={(evt: ChangeEvent<HTMLInputElement>): void => setEmail(evt.target.value)}
                                    variant="filled"
                                    error={email.length > 0 && !validEmail(email) || Boolean(loginState.errorMessage)}
                                    helperText={(email.length > 0 && !validEmail(email) ? t('FORMS.INVALID_EMAIL_ERROR') : '') || t(loginState.errorMessage)}
                                    inputProps={{
                                        'data-testid': 'login-email',
                                    }}
                                />
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    data-testid="password-label"
                                    label={t('LABELS.PASSWORD')}
                                    className="formFields"
                                    value={password}
                                    onChange={(evt: ChangeEvent<HTMLInputElement>): void => setPassword(evt.target.value)}
                                    variant="filled"
                                    error={Boolean(loginState.errorMessage)}
                                    helperText={t(loginState.errorMessage)}
                                    inputProps={{
                                        'data-testid': 'password',
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    data-testid="toggle-pass-visibility"
                                                    aria-label="Toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        buttonRow: {
                                            mb: 5,
                                            flexWrap: 'nowrap',
                                            backgroundColor: PXBColors.lightBlue,
                                            [theme.breakpoints.down('xs')]: {
                                                flexWrap: 'wrap',
                                                flexDirection: 'column-reverse',
                                                justifyContent: 'center'
                                            }
                                        }
                                    }
                                    }
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={remember}
                                                data-testid="remember-me"
                                                onChange={(evt): void => setRemember(evt.target.checked)}
                                            />
                                        }
                                        label={t('ACTIONS.REMEMBER')}
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={!canLogIn()}
                                        style={{
                                            width: 150, textTransform: 'none', backgroundColor: canLogIn() ? PXBColors.blue[500] : PXBColors.blue[50],
                                            color: canLogIn() ? PXBColors.white[50] : PXBColors.blue[200], fontWeight: 600
                                        }}
                                        data-testid="login-btn"
                                    >
                                        {t('ACTIONS.LOG_IN')}
                                    </Button>
                                </Grid>
                                <div style={{ textAlign: 'center', paddingBottom: '2%', paddingTop: '5%' }}>

                                    <Select
                                        style={{ width: 135, height: 38, borderRadius: 32, backgroundImage: `url(${LanguageIcon})`, backgroundRepeat: 'no-repeat', backgroundPosition: ' 8% 50%', paddingLeft: '5%', color: PXBColors.blue[500], borderStyle: 'none', borderColor: PXBColors.blue[500] }}
                                        labelId="primary-select-label"
                                        id="primary-select-label"
                                        data-testid='primary-select-label'
                                        value={selectedLanguage}
                                        onChange={selectionLanguageChangeHandler}

                                    >
                                        {languageList.map((option) => (
                                            <MenuItem sx={{}}
                                                key={option.key} value={option.value} data-testid={option.value}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left', fontSize: 16,
                                                    fontWeight: 400,
                                                    overflow: 'hidden',
                                                    boxSizing: 'border-box',
                                                    whiteSpace: 'nowrap',
                                                    minHeight: 48,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    width: 'auto',
                                                    paddingLeft: 16,
                                                    paddingRight: 16,
                                                    borderColor: PXBColors.blue[500]
                                                }}>
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </div>

                                <div data-testid="bottom-area" style={{ textAlign: 'center', paddingBottom: '2%', paddingTop: '5%' }}>
                                    <Typography variant="body2">
                                        <Link
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 14,
                                                textTransform: 'none',
                                                color: PXBColors.blue[500]
                                            }}
                                            to="/forgot-password"
                                            data-testid="forgot-password-link"
                                        >
                                            {t('LABELS.FORGOT_PASSWORD')}
                                        </Link>
                                    </Typography>
                                    <br />
                                    <Typography variant="body2" style={{ color: COLOR_GRAY_ICON }}>
                                        {t('LABELS.NEED_ACCOUNT')}{' '}
                                        <Link
                                            style={{
                                                fontWeight: 600,
                                                textTransform: 'none',
                                                color: PXBColors.blue[500]
                                            }}
                                            to={registrationLink} data-testid="signup-link">
                                            {t('LABELS.SIGN_UP')}
                                        </Link>
                                    </Typography>
                                    <br />
                                </div>
                                <div style={{ textAlign: 'center' }} data-testid="cyber-logo">
                                    <img src={cyberCertifyLogo} alt="logo" />
                                </div>
                            </div>
                        </form>
                    }
                </>
            </BrandedCardContainer>
        </>
    );
};
