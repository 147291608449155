// eslint-disable-next-line
import React from 'react';
import ListItem from '@mui/material/ListItem';
import { Typography, IconButton, ListItemText } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { COLOR_GRAY } from '../../constants/color-codes';
import * as PXBColors from '@brightlayer-ui/colors';

type Props = {
    id: string;
    title: string;
    subtitle: string;
    OnItemSelected?: Function;
};

export const CardListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    // const classes = useStyles();
    const { OnItemSelected } = props;

    const OnSelected = (id: string, name: string): void => {
        if (OnItemSelected) OnItemSelected(id, name);
    };

    return (
        <div style={{ background: PXBColors.white[50], flex: '1 1 0px', overflow: 'auto', marginTop: 20, marginBottom: 20, borderRadius: 5, border: '1px solid rgba(222,224,227, 1)' }}>
            <ListItem id="list-item"
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                onClick={(): void => OnSelected(props.id, props.title)}
                secondaryAction={
                    <IconButton edge="end" id="list-item-next-icon" style={{ marginLeft: "auto" }} >
                        <ChevronRight />
                    </IconButton>
                }>

                <ListItemText id="list-item-text">
                    <Typography
                        data-testid="list-item-title"
                        variant='subtitle1'
                    >
                        {props.title}
                    </Typography>

                    <Typography
                        data-testid="list-item-subtitle" variant="body2" display="inline"
                        sx={{
                            lineHeight: 1.25,
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            overflow: 'hidden',
                            wordWrap: 'break-word'
                        }}
                        style={{ marginTop: 3, color: COLOR_GRAY }}>
                        {props.subtitle}
                    </Typography>
                </ListItemText>
            </ListItem>
        </div>
    );
};
