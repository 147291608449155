// eslint-disable-next-line
import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ItemList } from '../../types/logbook-type';
import * as PXBColors from '@brightlayer-ui/colors';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';

type Props = {
    value: string;
    list: ItemList;
    handleSelection: Function;
    selected: string;
    defaultSelection: string;
    disabled?: boolean;
};
export const DropDownListComponent: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    const ddList = props.list;
    const { t } = useLanguageLocale();

    const [selection, setSelection] = React.useState('');
    const [backgroundColor, setBackgroundColor] = React.useState(getBackgroundColor(''));
    const [fontColor, setFontColor] = React.useState(getFontColor(''));

    function getBackgroundColor(selectedId: string): string {
        return selectedId !== '' ? PXBColors.lightBlue[50] : PXBColors.white[50];
    }

    function getFontColor(selectedId: string): string {
        return selectedId !== '' ? PXBColors.blue[500] : PXBColors.black[500];
    }

    useEffect(() => {
        setSelection('');
        setBackgroundColor(getBackgroundColor(''));
        setFontColor(getFontColor(''));
    }, [props.selected]);

    useEffect(() => {
        setTimeout(() => {
            if (props.defaultSelection !== '') {
                handleChange({ target: { value: props.defaultSelection } });
            }
        }, 700);
    }, [props.defaultSelection]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event: { target: { value: string } }) => {
        setSelection(event.target.value);
        setBackgroundColor(getBackgroundColor(event.target.value));
        setFontColor(getFontColor(event.target.value));
        props.handleSelection(event.target.value);
    };

    return (

        <Select
            id="asset-component-dropdown"
            data-testid="asset-component-dropdown"
            disabled={props.disabled}
            style={{
                borderColor: '0px solid #007BC1',
                backgroundColor: backgroundColor,
                marginRight: '1%',
                width: 'auto',
                height: 32,
                borderBlock: 0,
                outline: 'none',
                borderRadius: 32,
                overflow: 'hidden',
                marginTop: 28,
                fontSize: 14,
                color: fontColor,
                fontStyle: 'normal',
                fontWeight: 400,
            }}
            value={selection}
            onChange={handleChange}
            displayEmpty
        >
            <MenuItem data-testid="dropdown-title" value=""
                style={{
                    display: 'flex',
                    justifyContent: 'left', fontSize: 14,
                    lineHeight: 1.5, fontWeight: 400,
                    overflow: 'hidden',
                    boxSizing: 'border-box',
                    whiteSpace: 'nowrap',
                    minHeight: 48,
                    paddingTop: 6,
                    paddingBottom: 6,
                    width: 'auto',
                    paddingLeft: 16,
                    paddingRight: 16
                }}
            >{props.value}</MenuItem>
            {ddList.map((data, val) => {
                return (
                    <MenuItem id="asset-component-list" data-testid="dropdown-title" key={data.id} value={data.id}
                        style={{
                            display: 'flex',
                            justifyContent: 'left', fontSize: 14,
                            lineHeight: 1.5, fontWeight: 400,
                            overflow: 'hidden',
                            boxSizing: 'border-box',
                            whiteSpace: 'nowrap',
                            minHeight: 48,
                            paddingTop: 6,
                            paddingBottom: 6,
                            width: 'auto',
                            paddingLeft: 16,
                            paddingRight: 16
                        }}>
                        {t(data.name)}
                    </MenuItem>
                );
            })}
        </Select>
    );
};
