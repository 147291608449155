// eslint-disable-next-line
import React from 'react';
import { Data } from '../../types/data-providers';
import { useOrganizationList } from './OrganizationListProvider';
import { useAppOrganizationData } from '../../components/AppDataProvider';

type ContextState = Data<{
    id: string;
    name: string;
    address: string;
}>;

const Context = React.createContext<ContextState | null>(null);

function useOrganizationData(): ContextState {
    const contextState = React.useContext(Context);
    if (contextState === null) {
        throw new Error('useOrganizationData must be used within a OrganizationDataProvider');
    }

    return contextState;
}

const OrganizationDataProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = (props) => {
    const organizationList = useOrganizationList();
    const organizationState = useAppOrganizationData(organizationList.selectedOrganization);
    return <Context.Provider value={organizationState}>{props.children}</Context.Provider>;
};

const TestOrganizationDataProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ value: ContextState }>>> = (props) => (
    <Context.Provider value={props.value}>{props.children}</Context.Provider>
);

export { OrganizationDataProvider, useOrganizationData, TestOrganizationDataProvider };
