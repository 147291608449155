// eslint-disable-next-line
import React, { useState } from 'react';
import { FormControl, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Check, Visibility, VisibilityOff } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { LENGTH_REGEX, UPPER_CASE_REGEX, LOWER_CASE_REGEX, NUMBERS_REGEX, SPECIAL_CHAR } from '../../constants';

type Props = {
    password?: string;
    confirm?: string;
    passwordLabel?: string;
    confirmLabel?: string;
    onPasswordChange?: Function;
    passwordValidate?: Function;
};

export type PasswordRequirement = {
    description: string;
    regex: RegExp;
};

export const SetPasswordControl: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const PASSWORD_REQ_LENGTH: PasswordRequirement = {
        description: t('PASSWORD_REQUIREMENTS.LENGTH'),
        regex: LENGTH_REGEX,
    };
    const PASSWORD_REQ_NUMBER: PasswordRequirement = {
        description: t('PASSWORD_REQUIREMENTS.NUMBERS'),
        regex: NUMBERS_REGEX,
    };
    const PASSWORD_UPPER_CASE: PasswordRequirement = {
        description: t('PASSWORD_REQUIREMENTS.UPPER'),
        regex: UPPER_CASE_REGEX,
    };
    const PASSWORD_LOWER_CASE: PasswordRequirement = {
        description: t('PASSWORD_REQUIREMENTS.LOWER'),
        regex: LOWER_CASE_REGEX,
    };
    const PASSWORD_SPECIAL_CHAR: PasswordRequirement = {
        description: t('PASSWORD_REQUIREMENTS.SPECIAL'),
        regex: SPECIAL_CHAR,
    };
    const passwordRequirements: PasswordRequirement[] = [
        PASSWORD_REQ_LENGTH,
        PASSWORD_REQ_NUMBER,
        PASSWORD_UPPER_CASE,
        PASSWORD_LOWER_CASE,
        PASSWORD_SPECIAL_CHAR,
    ];
    const {
        password,
        confirm,
        passwordLabel = t('FORMS.PASSWORD'),
        confirmLabel = t('FORMS.CONFIRM_PASSWORD'),
        onPasswordChange,
        passwordValidate,
    } = props;
    const [passwordVal, setPassword] = useState(password || '');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmVal, setConfirm] = useState(confirm || '');

    const specialCharsValidation = (): boolean => {
        const spec = passwordVal.match(SPECIAL_CHAR); //eslint-disable-line @typescript-eslint/prefer-regexp-exec
        if (spec && spec.length < 1) return true;
        return false;
    };

    const arePasswordsValid = (newPassword: string, confirmPassword: string): boolean => {
        if (
            !(
                PASSWORD_REQ_LENGTH.regex.test(newPassword) &&
                PASSWORD_UPPER_CASE.regex.test(newPassword) &&
                PASSWORD_LOWER_CASE.regex.test(newPassword) &&
                PASSWORD_REQ_NUMBER.regex.test(newPassword) &&
                PASSWORD_SPECIAL_CHAR.regex.test(newPassword)
            )
        ) {
            return false;
        }

        if (newPassword !== confirmPassword) {
            return false;
        }
        if (specialCharsValidation()) {
            return false;
        }

        return true;
    };
    const passwordCheck = (requirement: PasswordRequirement): string => {
        let tickColor: string = new RegExp(requirement.regex).test(passwordVal)
            ? PXBColors.blue[500]
            : PXBColors.gray[200];

        if (requirement === PASSWORD_SPECIAL_CHAR) {
            const spec = passwordVal.match(SPECIAL_CHAR); //eslint-disable-line @typescript-eslint/prefer-regexp-exec
            if (spec && spec.length >= 1) tickColor = PXBColors.blue[500];
            else tickColor = PXBColors.gray[200];
        }
        return tickColor;
    };

    return (
        <>
            <FormControl variant={'filled'} fullWidth>
                <TextField
                    id={'new-password'}
                    type={showPassword ? 'text' : 'password'}
                    value={passwordVal}
                    label={passwordLabel}
                    variant="filled"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label={t('FORMS.TOGGLE_PASSWORD_VISIBILITY')}
                                    onClick={(): void => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{ maxLength: 16, 'data-testid': 'new-password' }}
                    onChange={(e): void => {
                        setPassword(e.target.value);
                        if (onPasswordChange) onPasswordChange(e.target.value);
                    }}
                    onKeyUp={(): void => {
                        if (passwordValidate) passwordValidate(arePasswordsValid(passwordVal, confirmVal));
                    }}
                ></TextField>
            </FormControl>

            {/* Password Requirements */}
            <div id={'validators-section'} style={{ margin: 8 }}>
                {passwordRequirements.map((requirement, index) => (
                    <Typography
                        key={`REQ_${requirement.description}`}
                        variant={'caption'}
                        style={{ display: 'flex', alignItems: 'center', color: PXBColors.gray[300] }}
                    >
                        <Check
                            id={`validator-checkbox-${index}`}
                            style={{
                                marginRight: 4,
                                color: passwordCheck(requirement),
                            }}
                        />
                        {requirement.description}
                    </Typography>
                ))}
            </div>
            <TextField
                id={'confirm-password'}
                type={showConfirmPassword ? 'text' : 'password'}
                style={{ marginTop: 24 }}
                variant={'filled'}
                label={`${confirmLabel}`}
                inputProps={{ maxLength: 16, 'data-testid': 'confirm-password' }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={t('FORMS.TOGGLE_PASSWORD_VISIBILITY')}
                                onClick={(): void => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                value={confirmVal}
                error={passwordVal !== confirmVal && confirmVal.length > 0}
                helperText={passwordVal !== confirmVal && confirmVal.length > 0 ? t('FORMS.PASS_MATCH_ERROR') : ''}
                fullWidth
                onChange={(e): void => {
                    setConfirm(e.target.value);
                }}
                onKeyUp={(): void => {
                    if (passwordValidate) passwordValidate(arePasswordsValid(passwordVal, confirmVal));
                }}
            />
        </>
    );
};
