import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { PREVIOUS_LOG_TIMELINE } from './url-constants';

type LatestLogEndpointSpecifications = ApiEndpointSpecification;
export const LatestLogEndpointSpecification: LatestLogEndpointSpecifications = {
    url: PREVIOUS_LOG_TIMELINE,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'PreviousLogRequestParams',
    requestBodySchemaName: 'PreviousLogRequestBody',
    okResponseSchemaName: 'PreviousLogOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokePreviousLogTimeLine = Helpers.makeApiRequestFn<
    Types.PreviousLogRequestParams,
    Types.PreviousLogRequestBody,
    Types.PreviousLogOkResponse
>(LatestLogEndpointSpecification);
