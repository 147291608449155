// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { OverviewListItem } from './OverviewListItem';
import Collapse from '@mui/material/Collapse';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { OverviewList, OverviewSelectedData } from '../../types/overview-type';
import { Divider, TablePagination } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { RECORD_PER_PAGE_LIMIT } from '../../constants';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';

type Props = {
    header: string;
    list: OverviewList;
    defaultOpenState: boolean;
    OnItemSelected?: Function;
};

export const OverviewStatusList: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const [open, setOpen] = React.useState(props.defaultOpenState);
    const { list, OnItemSelected } = props;
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [pageList, setPageList] = React.useState<OverviewList>([]);

    useEffect(() => {
        if (list.length > 0) {
            setTotalRecords(list.length);
            setPageList(getPaginatedList(list));
        }
        else{
            setPageList([]);
        }
    }, [list]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPageList(getPaginatedList(list));
    }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const getPaginatedList = (list: OverviewList): OverviewList => {
        let startIndex = currentPage * rowsPerPage;
        let endIndex = startIndex + rowsPerPage;
        return list.slice(startIndex, endIndex);
    };


    const handleClick = () => {
        setOpen(!open);
    };

    const setSelected = (selectedData: OverviewSelectedData): void => {
        if (OnItemSelected) OnItemSelected(selectedData);
    };

    return (
        <div data-testid="building-status-list" >
            <List sx={{ width: 'auto', bgcolor: 'background.paper' }} component="nav" id="status-list-header">
                <ListItemButton style={{ paddingLeft: 16, paddingRight: 16, display: 'flex', height: 48 }} onClick={handleClick} id="list-header-item-button" data-testid='list-header-item-button'>
                    <ListItemText disableTypography style={{ fontSize: 14, fontWeight: 600, color: PXBColors.blue[500] }} primary={props.header} />
                    {open ? (
                        <KeyboardArrowUp style={{ color: PXBColors.blue[500] }} id="list-header-up-icon" data-testid='list-header-up-icon' />
                    ) : (
                        <KeyboardArrowDown style={{ color: PXBColors.blue[500] }} id="list-header-down-icon" data-testid='list-header-down-icon' />
                    )}
                </ListItemButton>
                {open && list.length != 0 ? <Divider /> : ''}
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding id="status-list">
                        {pageList?.map((data, building) => {
                            return (
                                <OverviewListItem
                                    key={'building-key-' + data.id}
                                    value={{
                                        id: data.id,
                                        name: data.name,
                                        elStatus: data.elStatus,
                                        city: data.city,
                                        projectId: data.projectId,
                                        projectName: data.projectName,
                                        gatewayId: data.gatewayId,
                                        primaryFirstName: data.primaryFirstName,
                                        primaryLastName: data.primaryLastName,
                                        systemCount: data.systemCount
                                    }}
                                    OnItemSelected={(selectedData: OverviewSelectedData): void => setSelected(selectedData)} 
                                    isLast={pageList[pageList.length - 1].id === data.id}> </OverviewListItem>
                            );
                        })}
                    </List>
                </Collapse>
                {open && list.length > 10 && <TablePagination
                    component="div"
                    data-testid="pagination-bar"
                    count={totalRecords}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("LABELS.ITEMS_PER_PAGE")}
                    showFirstButton showLastButton
                />}
            </List>
        </div>
    );
};
