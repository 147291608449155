import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { UPDATE_USER_PROFILE } from './url-constants';

type UpdateUserRoleEndpointSpecifications = ApiEndpointSpecification;
export const UpdateUserRoleEndpointSpecification: UpdateUserRoleEndpointSpecifications = {
    url: UPDATE_USER_PROFILE,
    method: HttpMethod.PUT,
    requestParamsSchemaName: 'UpdateUserRoleRequestParams',
    requestBodySchemaName: 'UpdateUserRoleRequestBody',
    okResponseSchemaName: 'UpdateUserRoleOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeUpdateRole = Helpers.makeApiRequestFn<
    Types.UpdateUserRoleRequestParams,
    Types.UpdateUserRoleRequestBody,
    Types.UpdateUserRoleOkResponse
>(UpdateUserRoleEndpointSpecification);
