import React, { useEffect, useState } from "react";
import { useLanguageLocale } from "../../hooks/language-locale-hooks";
import { Box, Card, Divider, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SummaryData, StatisitcData, SummaryList, StaticalDashboardList } from "../../types/statistic-summary";
import { DEVICE_STATUS } from "../enum/ENUM";
import { EmptyState } from "@brightlayer-ui/react-components";
import { StatisticSummaryItem } from "./StatisticSummaryItem";
import * as PXBColors from '@brightlayer-ui/colors';
import * as MuiCore from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { Selectors } from "../../selectors";
import { CustomerActions } from "../../actions/actions/customer-actions";
import { getCommaNumberFormat } from "../enum/GenericFormat";
import { TAB_BUILDING_KEY, TAB_SYSTEM_KEY } from "../assets/Assets";

type Props = {
    userId?: string;
    projectId: string;
    buildingId?: string;
    elStatusClick?: Function;
    buildingStatusClick?: Function;
    viewAllClicked?: Function;
};


export const OverviewCustomerStaticalSummary: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useDispatch();
    const [buildingList, setBuildingList] = useState<SummaryList>([]);
    const [systemList, setSystemList] = useState<SummaryList>([]);
    const [lumanariesList, setLumanariesList] = useState<SummaryList>([]);
    const [totalBuildingCount, setTotalBuildingCount] = React.useState(0);
    const [totalSystemCount, setTotalSystemCount] = React.useState(0);
    const [totalLumanariesCount, setTotalLumanariesCount] = React.useState(0);
    const dashboard = useSelector(Selectors.Dashboard.dashboard);

    const getStatusIcon = (status: number, count: number): SummaryData => {
        switch (status) {
            case DEVICE_STATUS.STATUS_READY:
                return {
                    name: t('STATUS.STATUS_READY'),
                    elstatus: DEVICE_STATUS.STATUS_READY,
                    count: count,
                };
            case DEVICE_STATUS.STATUS_BLOCK:
                return {
                    name: t('STATUS.STATUS_BLOCK'),
                    elstatus: DEVICE_STATUS.STATUS_BLOCK,
                    count: count,
                };
            case DEVICE_STATUS.STATUS_ERROR:
                return {
                    name: t('STATUS.STATUS_ERROR'),
                    elstatus: DEVICE_STATUS.STATUS_ERROR,
                    count: count,
                };
            case DEVICE_STATUS.STATUS_WARNING:
                return {
                    name: t('STATUS.STATUS_WARNING'),
                    elstatus: DEVICE_STATUS.STATUS_WARNING,
                    count: count,
                };

            case DEVICE_STATUS.STATUS_OFFLINE:
                return {
                    name: t('STATUS.STATUS_OFFLINE'),
                    elstatus: DEVICE_STATUS.STATUS_OFFLINE,
                    count: count,
                };
            case DEVICE_STATUS.STATUS_DT:
                return {
                    name: t('STATUS.STATUS_DT'),
                    elstatus: DEVICE_STATUS.STATUS_DT,
                    count: count,
                };
            case DEVICE_STATUS.STATUS_EMPTY:
                return {
                    name: t('STATUS.STATUS_EMPTY'),
                    elstatus: DEVICE_STATUS.STATUS_EMPTY,
                    count: count,
                };
            default:
                return {
                    name: t('STATUS.STATUS_OFFLINE'),
                    elstatus: DEVICE_STATUS.STATUS_OFFLINE,
                    count: count,
                };
        }
    };

    const defaultData: StatisitcData = {
        dt: 0,
        error: 0,
        ready: 0,
        blocked: 0,
        offLine: 0,
        warning: 0,
        empty: 0,
        totalCount: 0,
    };

    useEffect(() => {
        updateBuildingStatisticData(defaultData);
        updateSystemStatisticData(defaultData);
        updateLumanariesStatisticData(defaultData);
    }, [props.projectId]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateBuildingStatisticData = (building: StatisitcData): SummaryList => {
        const buildingDefault: SummaryList = [];
        buildingDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_READY, building.ready));
        buildingDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_WARNING, building.warning));
        buildingDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_ERROR, building.error));
        buildingDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_BLOCK, building.blocked));
        buildingDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_EMPTY, building.empty ? building.empty : 0));
        buildingDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_OFFLINE, building.offLine));
        setBuildingList(buildingDefault);
        setTotalBuildingCount(building.totalCount);
        return buildingDefault;
    };

    const updateSystemStatisticData = (system: StatisitcData): SummaryList => {
        const systemListDefault: SummaryList = [];
        systemListDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_READY, system.ready));
        systemListDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_WARNING, system.warning));
        systemListDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_ERROR, system.error));
        systemListDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_BLOCK, system.blocked));
        systemListDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_DT, system.dt ? system.dt : 0));
        systemListDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_OFFLINE, system.offLine));

        setSystemList(systemListDefault);
        setTotalSystemCount(system.totalCount);
        return systemListDefault;
    };

    const updateLumanariesStatisticData = (lumanarieis: StatisitcData): SummaryList => {
        const lumanarieistDefault: SummaryList = [];
        lumanarieistDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_READY, lumanarieis.ready));
        lumanarieistDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_WARNING, lumanarieis.warning));
        lumanarieistDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_ERROR, lumanarieis.error));
        lumanarieistDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_BLOCK, lumanarieis.blocked));
        lumanarieistDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_DT, lumanarieis.dt ? lumanarieis.dt : 0));
        lumanarieistDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_OFFLINE, lumanarieis.offLine));

        setLumanariesList(lumanarieistDefault);
        setTotalLumanariesCount(lumanarieis.totalCount);
        return lumanarieistDefault;
    };

    useEffect(() => {
        if (!dashboard.statisticSummaryDashboardLoading && dashboard.statisticSummaryDashboardSuccess) {
            if (dashboard.statisticSummaryDashboard.statisticalBuildingData) {
                const buildingListData: SummaryList = updateBuildingStatisticData(dashboard.statisticSummaryDashboard?.statisticalBuildingData)
                setBuildingList(buildingListData);
            }
            if (dashboard.statisticSummaryDashboard.statisticalSystemData) {
                const systemListData: SummaryList = updateSystemStatisticData(dashboard.statisticSummaryDashboard?.statisticalSystemData)
                setSystemList(systemListData);
            }
            if (dashboard.statisticSummaryDashboard.statisticalLuminariesData) {
                const lumanariesList: SummaryList = updateLumanariesStatisticData(dashboard.statisticSummaryDashboard?.statisticalLuminariesData);
                setLumanariesList(lumanariesList);
            }
            setTotalLumanariesCount(dashboard.statisticSummaryDashboard?.luminaryCount ?? 0);
        } else if (!dashboard.statisticSummaryDashboardLoading && dashboard.statisticSummaryDashboardFail) {
            setBuildingList([]);
            setSystemList([]);
            setTotalBuildingCount(0);
            setTotalSystemCount(0);
            setTotalLumanariesCount(0);
        }
        dispatch(CustomerActions.dashboardCustomerListDataUnmounted());
    }, [dashboard.statisticSummaryDashboardLoading, dashboard.statisticSummaryDashboardSuccess, dashboard.statisticSummaryDashboardFail]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleDisplayELStatus = (value: {
        elstatus: any
    }): void => {
        if (props.elStatusClick)
            props.elStatusClick({ elstatus: value.elstatus });
    };

    const handleDisplayBuildingStatus = (value: {
        elstatus: any
    }): void => {
        if (props.buildingStatusClick)
            props.buildingStatusClick({ elstatus: value.elstatus });
    };

    const onViewAllClick = (tab: string) => {
        if (props.viewAllClicked)
            props.viewAllClicked(tab);
    }

    return (
        <>
            <Card
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    zIndex: 3,
                }}
                data-testid="sm-status-list"
            >
                {/* Building Page hide building statical summary */}
                {!props.buildingId && <>
                    {dashboard.statisticSummaryDashboardLoading && <EmptyState style={{ flex: 1, backgroundColor: PXBColors.white[50] }}
                        icon={<MuiCore.CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                            paddingLeft: 16,
                            paddingRight: 16,
                            width: '100%',
                            height: 52,
                            justifyContent: 'space-between'
                        }}
                    >

                        {!dashboard.statisticSummaryDashboardLoading && <Typography
                            variant="subtitle1"
                            data-testid="building-count"
                            style={{
                                color: PXBColors.blue[400],
                            }}
                        >
                            {(totalBuildingCount > 1 ? t('ENTITY_TYPE.BUILDING') + t('COMMON.S') : t('ENTITY_TYPE.BUILDING')) + ' (' + getCommaNumberFormat(totalBuildingCount) + ')'}
                        </Typography>}
                        {/* ToDo- Hide viewall for SM until API avail for implementation */}
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
                            onClick={() => onViewAllClick(TAB_BUILDING_KEY)} >
                            <Typography
                                variant="subtitle2"
                                data-testid="building-view-all"
                                style={{
                                    color: PXBColors.blue[400],
                                    cursor: 'pointer'
                                }}
                                id="building-all"
                                onClick={() => onViewAllClick(TAB_BUILDING_KEY)}
                            >
                                {t('LABELS.VIEW_ALL')}
                            </Typography>
                            <MuiCore.IconButton style={{ alignItems: 'right', width: '8%', padding: '0' }}>
                                {' '}
                                <MuiIcons.ChevronRight />{' '}
                            </MuiCore.IconButton>
                        </div>
                    </div>




                    {!dashboard.statisticSummaryDashboardLoading &&
                        <div style={{ width: '100%', padding: 16, paddingTop: 8 }}>
                            <Grid sx={{ flexGrow: 1 }} style={{ minHeight: 110 }}>
                                <Grid item xs={'auto'}>
                                    <Grid container justifyContent="space-evenly" spacing={1} columns={{ xs: 3, sm: 3 }}>
                                        {buildingList.map((value, building) => {
                                            return (
                                                <Grid key={value.elstatus} item xs={1} sm={1} >
                                                    <Box>
                                                        <StatisticSummaryItem
                                                            displayClick={handleDisplayBuildingStatus}
                                                            name={value.name}
                                                            count={value.count}
                                                            elstatus={value.elstatus}
                                                            testid={value.name}
                                                        ></StatisticSummaryItem>
                                                    </Box>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    }
                    <Divider style={{ width: '100%' }} />
                </>}
                {dashboard.statisticSummaryDashboardLoading && <EmptyState style={{ flex: 1, backgroundColor: PXBColors.white[50] }}
                    icon={<MuiCore.CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!dashboard.statisticSummaryDashboardLoading && <>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                            paddingLeft: 16,
                            paddingRight: 16,
                            width: '100%',
                            height: 52,
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            data-testid="system-count"
                            style={{
                                color: PXBColors.blue[400],
                            }}
                        >
                            {(totalSystemCount > 1 ? t('ENTITY_TYPE.SYSTEM') + t('COMMON.S') : t('ENTITY_TYPE.SYSTEM')) + ' (' + getCommaNumberFormat(totalSystemCount) + ')'}
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
                            onClick={() => onViewAllClick(TAB_SYSTEM_KEY)}
                        >
                            <Typography
                                variant="subtitle2"
                                data-testid="system-view-all"
                                style={{
                                    color: PXBColors.blue[400],
                                    cursor: 'pointer'
                                }}
                                id="system-all"

                            >
                                {t('LABELS.VIEW_ALL')}
                            </Typography>
                            <MuiCore.IconButton style={{ alignItems: 'right', width: '8%', padding: '0' }}>
                                {' '}
                                <MuiIcons.ChevronRight />{' '}
                            </MuiCore.IconButton>
                        </div>
                    </div>
                    <div style={{ width: '100%', padding: 16, paddingTop: 8 }}>
                        <Grid sx={{ flexGrow: 1 }} style={{ minHeight: 110 }}>
                            <Grid item xs={'auto'}>
                                <Grid container justifyContent="space-evenly" spacing={1} columns={{ xs: 3, sm: 3 }}>
                                    {systemList.map((value, building) => {
                                        return (
                                            <Grid key={value.elstatus} item xs={1} sm={1} >
                                                <Box>
                                                    <StatisticSummaryItem
                                                        displayClick={handleDisplayELStatus}
                                                        name={value.name}
                                                        count={value.count}
                                                        elstatus={value.elstatus}
                                                        testid={value.name}
                                                    ></StatisticSummaryItem>
                                                </Box>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <Divider style={{ width: '100%' }}></Divider>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                            paddingLeft: 16,
                            paddingRight: 16,
                            width: '100%',
                            height: 52,
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            data-testid="luminaire-count"
                            style={{
                                color: PXBColors.blue[400],
                            }}
                        >
                            {(totalLumanariesCount > 1 ? t('DEVICE_DETAILS.LUMINAIRE') + t('COMMON.S') : t('DEVICE_DETAILS.LUMINAIRE')) + ' (' + getCommaNumberFormat(totalLumanariesCount) + ')'}
                        </Typography>
                        {/* ToDo- Hide viewall for SM untill UI/API avail for implementation */}
                        {/* <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <Typography
                            variant="subtitle2"
                            data-testid="lum-all"
                            style={{
                                color: PXBColors.blue[400],
                            }}
                            id="lum-view-all"
                        >
                            {t('LABELS.VIEW_ALL')}
                        </Typography>
                        <MuiCore.IconButton style={{ alignItems: 'right', width: '8%', padding: '0' }}>
                            {' '}
                            <MuiIcons.ChevronRight />{' '}
                        </MuiCore.IconButton>
                    </div> */}
                    </div>
                </>}
            </Card >
        </>
    )

};
