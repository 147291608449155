import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_REGISTRATION_VERIFICATION_CODE_REQUEST } from './url-constants';

type UserRegistrationVerificationCodeRequestEndpointSpecifications = ApiEndpointSpecification;
export const UserRegistrationVerificationCodeRequestEndpointSpecification: UserRegistrationVerificationCodeRequestEndpointSpecifications = {
    url: USER_REGISTRATION_VERIFICATION_CODE_REQUEST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'UserRegistrationVerificationCodeRequestRequestParams',
    requestBodySchemaName: 'UserRegistrationVerificationCodeRequestRequestBody',
    okResponseSchemaName: 'UserRegistrationVerificationCodeRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeUserRegistrationVerificationCodeRequest = Helpers.makeApiRequestFn<
    Types.UserRegistrationVerificationCodeRequestRequestParams,
    Types.UserRegistrationVerificationCodeRequestRequestBody,
    Types.UserRegistrationVerificationCodeRequestOkResponse
>(UserRegistrationVerificationCodeRequestEndpointSpecification);
