// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { Divider, Card, ListSubheader, Typography, Collapse, CircularProgress } from '@mui/material';
import List from '@mui/material/List';
import { SystemLogList, SystemLogListData } from '../../types/system-log';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as MuiCore from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import { AppRoute } from '../../routes';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import * as PXBColors from '@brightlayer-ui/colors';
import ListItemButton from '@mui/material/ListItemButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { EmptyState } from '@brightlayer-ui/react-components';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider/component';
import { SystemServiceLogListItem } from '../../components/SystemServiceLog/SystemServiceLogListItem';

type Props = {
    userId?: string;
    projectId?: string;
    buildingId?: string;
    isDashboard?: boolean;
    isService?: boolean;
    defaultOpenState: boolean;
    title?: string;
    OnLogSelected: Function;
    onLimitOffSetChange?: Function;
    isFilterApplied?: boolean;
    resetFilterFlag?: Function;
};

const testSystemServiceLogs: SystemLogList =
    [
        {
            id: "d8bf2653-b216-4992-8014-fbd2f331a78e",
            logSourceId: "030b8855-a64b-46f4-8324-3c7dc6950dae",
            partitionKey: "LogStatisticalDetail_2022",
            name: "Inspection",
            description: "Description text goes here (do you need the description?)",
            title: "Annual inspection",
            datetimeutc: "2023-06-01T03:03:58Z",
            elstatus: 4,
            projectId: "ab05134c-6a46-4792-a391-30c684957005",
            projectName: "Elkhart Country Historical Museum",
            buildingName: "Museum Building Block 1",
            buildingId: "a199411e-4be7-46f1-836a-f8a23e2b81ca",
            systemName: "DG02",
            systemId: "030b8855-a64b-46f4-8324-3c7dc6950dae",
            sublocation: "Second floor",
            isactive: false,
            logSourceName: "Elkhart System 01",
            systemPartNumber: "P876",
            systemType: 2,
            acknowledgedTime: "2023-04-13T12:03:40.1872252Z",
            isAcknowledged: true,
            acknowledgedBy: "John Deph",
            logSourcePartNumber: "PN232158",
            logSourceSerialNumber: "LUM32445",
            logSourceDeviceType: 4,
            logStartDate: "",
            logEndDate: "",
            comment: "",
            subDevice: "",
        },
        {
            id: "f23836be-c9ce-456e-934f-3260e56f2826",
            logSourceId: "030b8855-a64b-46f4-8124-3c7dc6950dae",
            partitionKey: "LogStatisticalDetail_2022",
            name: "Inspection",
            description: "Description text goes here (do you need the description?)",
            title: "Annual inspection",
            datetimeutc: "2022-06-01T03:03:58Z",
            elstatus: 2,
            projectId: "ab05134c-6a46-4792-a391-30c684957005",
            projectName: "Elkhart Country Historical Museum",
            buildingName: "Museum Building Block 1",
            buildingId: "a199411e-4be7-46f1-836a-f8a23e2b81ca",
            systemName: "DG08",
            systemId: "030b8855-a64b-46f4-8324-3c7dc6950dae",
            sublocation: "Second floor",
            isactive: false,
            logSourceName: "Elkhart System 01",
            systemPartNumber: "P876",
            systemType: 2,
            acknowledgedTime: "2023-04-13T12:03:40.1872252Z",
            isAcknowledged: true,
            acknowledgedBy: "Abhinav Ahirwar",
            logSourcePartNumber: "PN232158",
            logSourceSerialNumber: "LUM32445",
            logSourceDeviceType: 4,
            logStartDate: "",
            logEndDate: "",
            comment: "",
            subDevice: "",
        }, {
            id: "f23836be-c9ce-456e-934f-3260e56f282e",
            logSourceId: "030b8855-a64b-46f4-8324-3c7dc6910dae",
            partitionKey: "LogStatisticalDetail_2022",
            name: "Maintenance",
            description: "Description text goes here (do you need the description?)",
            title: "text here",
            datetimeutc: "2023-04-01T03:03:58Z",
            elstatus: 4,
            projectId: "ab05134c-6a46-4792-a391-30c684957005",
            projectName: "Elkhart Country Historical Museum",
            buildingName: "Museum Building Block 1",
            buildingId: "a199411e-4be7-46f1-836a-f8a23e2b81ca",
            systemName: "DG04",
            systemId: "030b8855-a64b-46f4-8324-3c7dc6950dae",
            sublocation: "Second floor",
            isactive: false,
            logSourceName: "Elkhart System 01",
            systemPartNumber: "P876",
            systemType: 2,
            acknowledgedTime: "2023-04-13T12:03:40.1872252Z",
            isAcknowledged: true,
            acknowledgedBy: "Abhinav Ahirwar",
            logSourcePartNumber: "PN232158",
            logSourceSerialNumber: "LUM32445",
            logSourceDeviceType: 4,
            logStartDate: "",
            logEndDate: "",
            comment: "",
            subDevice: "",
        },
        {
            id: "f23836be-c9ce-456e-934f-3260e56f182e",
            logSourceId: "030b8855-a64b-46f4-8324-3c7dc6950dpe",
            partitionKey: "LogStatisticalDetail_2022",
            name: "Maintenance",
            description: "Description text goes here (do you need the description?)",
            title: "text here",
            datetimeutc: "2023-01-01T03:03:58Z",
            elstatus: 4,
            projectId: "ab05134c-6a46-4792-a391-30c684957005",
            projectName: "Elkhart Country Historical Museum",
            buildingName: "Museum Building Block 1",
            buildingId: "a199411e-4be7-46f1-836a-f8a23e2b81ca",
            systemName: "DG09",
            systemId: "030b8855-a64b-46f4-8324-3c7dc6950dae",
            sublocation: "Second floor",
            isactive: true,
            logSourceName: "Elkhart System 01",
            systemPartNumber: "P876",
            systemType: 2,
            acknowledgedTime: "2023-04-13T12:03:40.1872252Z",
            isAcknowledged: false,
            acknowledgedBy: "Abhinav Ahirwar",
            logSourcePartNumber: "PN232158",
            logSourceSerialNumber: "LUM32445",
            logSourceDeviceType: 4,
            logStartDate: "",
            logEndDate: "",
            comment: "",
            subDevice: "",
        },
    ];

const testSystemServiceLogsListData: SystemLogList = testSystemServiceLogs;
const testSystemServiceLogListData: SystemLogListData = {
    detail: testSystemServiceLogsListData,
    totalRecordCount: 1,
    totalPage: 1,
};


export const OverviewSystemServiceDueLogs: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const [open, setOpen] = React.useState(props.defaultOpenState);
    const { t } = useLanguageLocale();
    const logbook = useSelector(Selectors.Logbook.logbook);
    const [systemLogList, setSystemLogList] = useState<SystemLogList>(testSystemServiceLogs);
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const [emptyLogList, setEmptyLogList] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    //TODO- pagination code comment till API availble
    // const [totalRecords, setTotalRecords] = React.useState(0);
    // const [currentPage, setCurrentPage] = useState(0);

    // const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);

    // const handleChangePage = (
    //     event: React.MouseEvent<HTMLButtonElement> | null,
    //     newPage: number,
    // ) => {
    //     setCurrentPage(newPage);
    //     if (props.onLimitOffSetChange)
    //         props.onLimitOffSetChange(newPage + 1, rowsPerPage, props.isService);
    // };

    // const handleChangeRowsPerPage = (
    //     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    // ) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setCurrentPage(0);
    //     if (props.onLimitOffSetChange)
    //         props.onLimitOffSetChange(1, parseInt(event.target.value, 10), props.isService);
    // };

    useEffect(() => {
        if (props.isFilterApplied) {
            //TODO-later when pagi avail
            // setCurrentPage(0);
            if (props.resetFilterFlag) {
                props.resetFilterFlag(false);
            }
        }
    }, [props.isFilterApplied]); // eslint-disable-line react-hooks/exhaustive-deps


    // TODO - API call later
    // useEffect(() => {
    //     if (props.isLatestLogs) {
    //         if (logbook.latestLogListLoading) {
    //             setEmptyLogList(false);
    //         }
    //         if (!logbook.latestLogListLoading && logbook.latestLogListSuccess) {
    //             if (props.isDashboard && logbook.latestLogList) {
    //                 setSystemLogList(logbook.latestLogList.detail.slice(0, 10));
    //             } else {
    //                 setTotalRecords(logbook.latestLogList.totalRecordCount ? logbook.latestLogList.totalRecordCount : 0);
    //                 setSystemLogList(logbook.latestLogList.detail.slice(0, rowsPerPage));
    //             }
    //             setEmptyLogList(logbook.latestLogList.detail.length <= 0)
    //         } else if (!logbook.latestLogListLoading && logbook.latestLogListFail) {
    //             console.log('latest log list loading failed ');
    //             setSystemLogList(testSystemServiceLogs);
    //             // setEmptyLogList(true);
    //         }
    //     }
    // }, [logbook.latestLogListLoading, logbook.latestLogListSuccess, logbook.latestLogListFail]); // eslint-disable-line react-hooks/exhaustive-deps
    // useEffect(() => {
    //     if (!props.isLatestLogs) {
    //         if (logbook.earlierLogListLoading) {
    //             setEmptyLogList(false);
    //         }
    //         if (!logbook.earlierLogListLoading && logbook.earlierLogListSuccess) {
    //             setTotalRecords(logbook.earlierLogList.totalRecordCount ? logbook.earlierLogList.totalRecordCount : 0)
    //             if (props.isDashboard)
    //                 setSystemLogList(logbook.earlierLogList.detail.slice(0, 10));
    //             else
    //                 setSystemLogList(logbook.earlierLogList.detail.slice(0, rowsPerPage));
    //             setEmptyLogList(logbook.earlierLogList.detail.length <= 0)

    //         } else if (!logbook.earlierLogListLoading && logbook.earlierLogListFail) {
    //             console.log('latest log list loading failed ');
    //             setSystemLogList(testSystemServiceLogs);
    //             // setEmptyLogList(true);
    //         }
    //     }
    // }, [logbook.earlierLogListLoading, logbook.earlierLogListSuccess, logbook.earlierLogListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const viewAllEventsAppRoute: AppRoute = {
        type: 'OverviewSiteTimeline',
        siteId: '1',
        locationId: '1',
    };

    const OnLogSelected = (logId: string, logSourceId: string): void => {
        props.OnLogSelected(logId, logSourceId);
    };

    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="system-log-list-container">
                <List
                    component="div"
                    disablePadding
                    id="system-log-list"
                    data-testid="system-log-list"
                    style={props.isDashboard ? { minHeight: 'auto' } : {}}
                    subheader={
                        <>
                            <ListSubheader
                                color="primary"
                                component="div"
                                data-testid="system-log-list-header"
                                id="system-log-list-header"
                                style={{
                                    display: 'flex',
                                    fontWeight: 600,
                                    fontSize: 14,
                                    alignItems: 'center',
                                    overflow: 'auto',
                                    whiteSpace: 'nowrap',
                                    marginLeft: 10,
                                    height: 45,
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    style={{
                                        alignItems: 'left',
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: PXBColors.blue[400],
                                        width: '92.3%',
                                        paddingLeft: '10',
                                    }}
                                    id="system-log-title"
                                    data-testid="system-log-title"
                                >
                                    {props.title}
                                </Typography>

                                <ListItemButton
                                    onClick={handleClick}
                                    id="list-header-item-button"
                                    style={{
                                        width: '7.41%',
                                    }}
                                >
                                    {open ? (
                                        <KeyboardArrowUp data-testid="arrow-up" id="list-header-up-icon" />
                                    ) : (
                                        <KeyboardArrowDown data-testid="arrow-down" id="list-header-down-icon" />
                                    )}
                                </ListItemButton>
                            </ListSubheader>
                            <Divider />
                        </>
                    }
                >
                    {(logbook.earlierLogListLoading) && <EmptyState style={{ flex: 1, minHeight: 300, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

                    {!(logbook.earlierLogListLoading) && <Collapse in={open} timeout="auto" unmountOnExit>
                        {emptyLogList && <EmptyState style={{ flex: 1, height: 200 }}
                        title={t('DASHBOARD.NO_DATA')} icon={undefined} placeholder={undefined} />}
                        {systemLogList?.map((data, logs) => {
                            return (
                                <SystemServiceLogListItem
                                    key={'system-log-key-' + data.id}
                                    OnItemSelected={(logId: string, logSourceId: string): void => OnLogSelected(logId, logSourceId)}
                                    value={{
                                        id: data.id,
                                        comment: data.comment,
                                        logSourceId: data.logSourceId,
                                        partitionKey: data.partitionKey,
                                        name: data.name,
                                        title: data.title,
                                        description: data.description,
                                        datetimeutc: data.datetimeutc,
                                        elstatus: data.elstatus,
                                        systemId: data.systemId,
                                        systemName: data.systemName,
                                        logSourceName: data.logSourceName,
                                        projectId: data.projectId,
                                        projectName: data.projectName,
                                        buildingName: data.buildingName,
                                        buildingId: data.buildingId,
                                        sublocation: data.sublocation,
                                        isactive: data.isactive,
                                        systemPartNumber: data.systemPartNumber,
                                        systemType: data.systemType,
                                        logSourcePartNumber: data.logSourcePartNumber,
                                        logSourceSerialNumber: data.logSourceSerialNumber,
                                        logSourceDeviceType: data.logSourceDeviceType,
                                        logStartDate: data.logStartDate,
                                        logEndDate: data.logEndDate,
                                        acknowledgedTime: data.acknowledgedTime,
                                        isAcknowledged: data.isAcknowledged,
                                        acknowledgedBy: data.acknowledgedBy,
                                        subDevice: data.subDevice
                                    }}
                                ></SystemServiceLogListItem>
                            );
                        })}
                        {/* TODO- pagination once API available */}
                        {/* {!emptyLogList && !props.isDashboard && <TablePagination
                            component="div"
                            data-testid="pagination-logbook"
                            count={totalRecords}
                            page={currentPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t("LABELS.ITEMS_PER_PAGE")}
                            showFirstButton showLastButton
                        />} */}
                    </Collapse>}
                </List>
                <div data-testid='viewScheduleJobLink'>
                    {props.isDashboard && <Divider />}

                    {/* //TODO- Hide for SM untill UI/API available */}
                    {/* {props.isDashboard && (
                        //TODO later when nav required
                        // <ActionRow data-testid='view-logbook-link' label={t('LABELS.VIEW_ALL')} appRoute={viewAllEventsAppRoute} />
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 16, }}>
                                <Typography
                                    variant="subtitle2"
                                    data-testid="view-all"
                                    style={{
                                        color: PXBColors.blue[400],
                                    }}
                                    id="project-admin"
                                >
                                    {t('LABELS.VIEW_ALL')}
                                </Typography>
                                <MuiCore.IconButton style={{ alignItems: 'right', width: '8%', padding: '0' }}>
                                    {' '}
                                    <MuiIcons.ChevronRight />{' '}
                                </MuiCore.IconButton>
                            </div>
                        </>
                    )} */}
                </div>
            </Card>
        </>
    );
};
