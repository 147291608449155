import { InitialState } from '../state';
import { customerData } from '../state/assets-state';
import { AppReducer } from './app-reducer';

export const assetReducer: AppReducer<'Asset'> = (state = InitialState.Asset, action): typeof state => {
    switch (action.type) {
        case 'Asset/deviceList/Started':
            return {
                ...state,
                deviceListLoading: true,
                deviceListSuccess: false,
                deviceListFail: false,
                deviceListErrorCode: '',
            };
        case 'Asset/deviceList/Succeeded':
            return {
                ...state,
                deviceListLoading: false,
                deviceListSuccess: true,
                deviceListFail: false,
                deviceListErrorCode: '',
                deviceList: action.payload.deviceList,
            };
        case 'Asset/deviceList/Failed':
            return {
                ...state,
                deviceListLoading: false,
                deviceListSuccess: false,
                deviceListFail: true,
                deviceListErrorCode: action.payload.errorCode,
            };

        case 'Asset/deviceList/Unmounted':
            return {
                ...state,
                deviceListLoading: false,
                deviceListSuccess: false,
                deviceListFail: false,
                deviceListErrorCode: '',
            };
        case 'Customer/deviceCustomerData/Started':
            return {
                ...state,
                deviceCustomerDataLoading: true,
                deviceCustomerDataSuccess: false,
                deviceCustomerDataFail: false,
                deviceCustomerDataErrorCode: '',
            };
        case 'Customer/deviceCustomerData/Succeeded':
            return {
                ...state,
                deviceCustomerDataLoading: false,
                deviceCustomerDataSuccess: true,
                deviceCustomerDataFail: false,
                deviceCustomerDataErrorCode: '',
                deviceCustomerData: action.payload.customerData,
            };
        case 'Customer/deviceCustomerData/Failed':
            return {
                ...state,
                deviceCustomerDataLoading: false,
                deviceCustomerDataSuccess: false,
                deviceCustomerDataFail: true,
                deviceCustomerDataErrorCode: action.payload.errorCode,
            };

        case 'Customer/deviceCustomerData/Unmounted':
            return {
                ...state,
                deviceCustomerDataLoading: false,
                deviceCustomerDataSuccess: false,
                deviceCustomerDataFail: false,
                deviceCustomerDataErrorCode: '',
                deviceCustomerData: customerData
            };
        case 'Customer/updateCustomerDetail/Started':
            return {
                ...state,
                updateCustomerDetailLoading: true,
                updateCustomerDetailSuccess: false,
                updateCustomerDetailFail: false,
                updateCustomerDetailErrorMessage: '',
            };
        case 'Customer/updateCustomerDetail/Succeeded':
            return {
                ...state,
                updateCustomerDetailLoading: false,
                updateCustomerDetailSuccess: true,
                updateCustomerDetailFail: false,
                updateCustomerDetailErrorMessage: '',
                updateCustomerDetailId: action.payload.id
            };
        case 'Customer/updateCustomerDetail/Failed':
            return {
                ...state,
                updateCustomerDetailLoading: false,
                updateCustomerDetailSuccess: false,
                updateCustomerDetailFail: true,
                updateCustomerDetailErrorMessage: action.payload.errorCode,
            };

        case 'Customer/updateCustomerDetail/Unmounted':
            return {
                ...state,
                updateCustomerDetailLoading: false,
                updateCustomerDetailSuccess: false,
                updateCustomerDetailFail: false,
                updateCustomerDetailErrorMessage: '',
                updateCustomerDetailId: ''
            };

        case 'Asset/luminaryList/Started':
            return {
                ...state,
                luminaryListLoading: true,
                luminaryListSuccess: false,
                luminaryListFail: false,
                luminaryListErrorCode: '',
            };
        case 'Asset/luminaryList/Succeeded':
            return {
                ...state,
                luminaryListLoading: false,
                luminaryListSuccess: true,
                luminaryListFail: false,
                luminaryListErrorCode: '',
                luminaryList: action.payload.luminaryListData,
            };
        case 'Asset/luminaryList/Failed':
            return {
                ...state,
                luminaryListLoading: false,
                luminaryListSuccess: false,
                luminaryListFail: true,
                luminaryListErrorCode: action.payload.errorCode,
            };

        case 'Asset/luminaryList/Unmounted':
            return {
                ...state,
                luminaryListLoading: false,
                luminaryListSuccess: false,
                luminaryListFail: false,
                luminaryListErrorCode: '',
            };
        case 'Asset/gatewayDetailsList/Started':
            return {
                ...state,
                gatewayDetailsListLoading: true,
                gatewayDetailsListSuccess: false,
                gatewayDetailsListFail: false,
                gatewayDetailsListErrorCode: '',
            };
        case 'Asset/gatewayDetailsList/Succeeded':
            return {
                ...state,
                gatewayDetailsListLoading: false,
                gatewayDetailsListSuccess: true,
                gatewayDetailsListFail: false,
                gatewayDetailsListErrorCode: '',
                gatewayDetailsList: action.payload.gatewayDetailsList,
            };
        case 'Asset/gatewayDetailsList/Failed':
            return {
                ...state,
                gatewayDetailsListLoading: false,
                gatewayDetailsListSuccess: false,
                gatewayDetailsListFail: true,
                gatewayDetailsListErrorCode: action.payload.errorCode,
            };

        case 'Asset/gatewayDetailsList/Unmounted':
            return {
                ...state,
                gatewayDetailsListLoading: false,
                gatewayDetailsListSuccess: false,
                gatewayDetailsListFail: false,
                gatewayDetailsListErrorCode: '',
            };

        case 'Asset/slaList/Started':
            return {
                ...state,
                slaListLoading: true,
                slaListSuccess: false,
                slaListFail: false,
                slaListErrorCode: '',
            };
        case 'Asset/slaList/Succeeded':
            return {
                ...state,
                slaListLoading: false,
                slaListSuccess: true,
                slaListFail: false,
                slaListErrorCode: '',
                slaList: action.payload.SLADetailsList,
            };
        case 'Asset/slaList/Failed':
            return {
                ...state,
                slaListLoading: false,
                slaListSuccess: false,
                slaListFail: true,
                slaListErrorCode: action.payload.errorCode,
            };

        case 'Asset/slaList/Unmounted':
            return {
                ...state,
                slaListLoading: false,
                slaListSuccess: false,
                slaListFail: false,
                slaListErrorCode: '',
            };

        case 'Asset/slaCustomerDetails/Started':
            return {
                ...state,
                slaCustomerListLoading: true,
                slaCustomerListSuccess: false,
                slaCustomerListFail: false,
                slaCustomerListErrorCode: '',
            };
        case 'Asset/slaCustomerDetails/Succeeded':
            return {
                ...state,
                slaCustomerListLoading: false,
                slaCustomerListSuccess: true,
                slaCustomerListFail: false,
                slaCustomerListErrorCode: '',
                slaCustomerList: action.payload.customerDetailsList,
            };

        case 'Asset/slaCustomerDetails/Failed':
            return {
                ...state,
                slaCustomerListLoading: false,
                slaCustomerListSuccess: false,
                slaCustomerListFail: true,
                slaCustomerListErrorCode: action.payload.errorCode,
            };

        case 'Asset/slaCustomerDetails/Unmounted':
            return {
                ...state,
                slaCustomerListLoading: false,
                slaCustomerListSuccess: false,
                slaCustomerListFail: false,
                slaCustomerListErrorCode: '',
            };

        default:
            return state;
    }
};
