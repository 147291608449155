/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const getTrendlineIdentifier = (channelId: number, deviceId: string): string => `${channelId}-${deviceId}`;

export function mergeSimilarKeys(array: any) {
    const output = array.reduce((o: any, cur: any) => {
        // Get the index of the key-value pair.
        const occurs = o.reduce((n: any, item: any, i: any) => (item.deviceId === cur.deviceId ? i : n), -1);

        // If the name is found,
        if (occurs >= 0) {
            // append the current value to its list of values.
            o[occurs].channelIds = o[occurs].channelIds.concat(cur.channelId);

            // Otherwise,
        } else {
            // add the current item to o (but make sure the value is an array).
            const obj = {
                deviceId: cur.deviceId,
                channelIds: [cur.channelId],
            };
            /* eslint-disable no-param-reassign */
            o = o.concat([obj]);
        }

        return o;
    }, []);
    return output;
}
