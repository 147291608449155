import { ReportListData } from "../types/report";

export type ReportsEntity = {
    entityType: 0,
    entityId: '',
    fromDate: '',
    toDate: '',
    issueCount: '',
    month: 0,
    createdDate: '',
};
export type Report = {
    year: '',
    reports: [];
}

export const defaultReportList : ReportListData = {
    detail: [],
    totalRecordCount: 0,
    totalPage: 0
}

export type ReportState = {
    healthReportListLoading: boolean;
    healthReportListSuccess: boolean;
    healthReportListFail: boolean;
    healthReportList: ReportListData;
    healthReportErrorCode: string;

    downloadHealthReportLoading: boolean;
    downloadHealthReportSuccess: boolean;
    downloadHealthReportFail: boolean;
    downloadHealthReport: string;
    downloadHealthReportErrorCode: string;
};

export const initialReportState: ReportState = {
    healthReportListLoading: false,
    healthReportListSuccess: false,
    healthReportListFail: false,
    healthReportList: defaultReportList,
    healthReportErrorCode: '',

    downloadHealthReportLoading: false,
    downloadHealthReportSuccess: false,
    downloadHealthReportFail: false,
    downloadHealthReport: '',
    downloadHealthReportErrorCode: '',
};

