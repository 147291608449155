import { AuthContextState } from './types';

export type Action =
    | { type: 'userAuthenticated'; payload: { email: string; userId: string; rememberMe: boolean; roleTypeId?: number } }
    | { type: 'userNotAuthenticated' }
    | { type: 'userNotAuthenticatedWithRememberMe'; payload: { email: string; rememberMe: boolean } };

export function reducer(state: AuthContextState, action: Action): AuthContextState {
    switch (action.type) {
        case 'userAuthenticated':
            return {
                email: action.payload.email,
                userId: action.payload.userId,
                rememberMeDetails: {
                    email: action.payload.email,
                    rememberMe: action.payload.rememberMe,
                },
                roleTypeId: action.payload.roleTypeId,
            };
        case 'userNotAuthenticated':
            return { email: undefined, userId: undefined, rememberMeDetails: {} };
        case 'userNotAuthenticatedWithRememberMe':
            return {
                rememberMeDetails: {
                    email: action.payload.email,
                    rememberMe: action.payload.rememberMe,
                },
            };
        default:
            return state;
    }
}
