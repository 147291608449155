// eslint-disable-next-line
import React, { ChangeEvent, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as PXBColors from '@brightlayer-ui/colors';

// Material-UI components
import { Button, CardActions, CardContent, CardHeader, CircularProgress, Divider, TextField, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

//@ts-ignore
import { EmptyState } from '@brightlayer-ui/react-components';

import { BrandedCardContainer } from '../../components/BrandedCardContainer/BrandedCardContainer';
import { CONTACT_PHONENO } from '../../constants';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import { validEmail } from '../../lib/form-validator';
import Grid from '@mui/material/Grid';

export const ForgotPassword: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const { t } = useLanguageLocale();

    const forgetPassword = useSelector(Selectors.Authentication.forgotPassword);
    const history = useHistory();

    const [email, setEmail] = useState('');

    const dispatch = useDispatch();

    const canContinue = (): boolean => validEmail(email) && !forgetPassword.loading;

    const goLogin = (): void => {
        pushAppRoute(history, { type: 'Login' });
    };

    const onContinue = (): void => {
        if (forgetPassword.success) {
            goLogin();
        } else {
            dispatch(Thunks.Authentication.forgotPassword(email));
        }
    };

    const onBack = (): void => {
        goLogin();
    };

    let body: JSX.Element;
    if (forgetPassword.loading) {
        body = (
            <EmptyState style={{ flex: 1, backgroundColor: PXBColors.white[50] }}
                icon={<CircularProgress id="progress-spinner" />} title={''} placeholder={undefined} />
        );
    } else if (forgetPassword.success) {
        body = (
            <div
                style={{ display: 'flex', flex: '1 1 0%', justifyContent: 'center', height: '100%' }}
                data-testid="forgot-password-confirmation-content"
            >
                <EmptyState
                    icon={<CheckCircle style={{ fontSize: 100, marginBottom: 16 }} />}
                    title={t('MESSAGES.EMAIL_SENT')} placeholder={undefined} />
            </div>
        );
    } else {
        body = (
            <div data-testid="forgot-password-entry-content">
                <Typography>
                    <Trans i18nKey={'FORGOT_PASSWORD.INSTRUCTIONS'}></Trans>
                    <a href={`tel:${CONTACT_PHONENO}`} style={{ textDecoration: 'none' }}>
                        {CONTACT_PHONENO} .
                    </a>
                </Typography>

                <Divider style={{ margin: '32px 0' }} />

                <TextField
                    label={t('LABELS.EMAIL')}
                    fullWidth
                    id="email"
                    value={email}
                    onChange={(evt: ChangeEvent<HTMLInputElement>): void => setEmail(evt.target.value)}
                    variant="filled"
                    error={email.length > 0 && !validEmail(email)}
                    helperText={email.length > 0 && !validEmail(email) ? (t('FORMS.INVALID_EMAIL_ERROR')) : ''}
                    inputProps={{
                        'data-testid': 'email-input',
                    }}
                />
            </div>
        );
    }

    return (
        <BrandedCardContainer>
            <>
                <CardHeader
                    data-testid="title"
                    title={
                        <Typography aria-label='title' variant={'h6'} style={{ fontWeight: 600 }}>
                            {t('HEADER.FORGOT_PASSWORD')}
                        </Typography>
                    }
                />
                <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }}>{body}</CardContent>
                <Divider />
                <CardActions style={{ padding: 16 }}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        style={{ width: '100%', justifyContent: 'space-between' }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={forgetPassword.success}
                            onClick={onBack}
                            style={{ width: 100, textTransform: 'none' }}
                            data-testid="back-button"
                        >
                            {t('ACTIONS.BACK')}
                        </Button>
                        <Button
                            variant="contained"
                            disabled={!canContinue()}
                            color="primary"
                            onClick={onContinue}
                            style={{
                                width: 100, textTransform: 'none', backgroundColor: canContinue() ? PXBColors.blue[500] : PXBColors.blue[50],
                                color: canContinue() ? PXBColors.white[50] : PXBColors.blue[200],
                            }}
                            data-testid="next-button"
                        >
                            {forgetPassword.success ? t('ACTIONS.DONE') : t('ACTIONS.SEND')}
                        </Button>
                    </Grid>
                </CardActions>
            </>
        </BrandedCardContainer>
    );
};
