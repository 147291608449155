// eslint-disable-next-line
import React from 'react';
import { Checkbox, Typography } from '@mui/material';
import { ENTITY_TYPE, getEntityType } from '../../app/enum/ENUM';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { InvitationRequestData } from '../../types/manage-users';

type Props = {
    userInvitationData: InvitationRequestData,
    onCheckboxClick: Function;
    isSelectedAll: boolean;
    hideCheckBox?: boolean;
};

export const ApprovalInviteCard: React.FC<Props> = (props: Props) => {
    const { t } = useLanguageLocale();

    if (props.userInvitationData.isSelected === undefined) { props.userInvitationData.isSelected = false }

    const getInvitationTypeTitle = (type: number) => {
        return ' (' + t(getEntityType(type)) + ')';
    }

    const handleClick = (event: React.ChangeEvent<HTMLElement>,
        checked: boolean,) => {
        if (!props.isSelectedAll) {
            props.userInvitationData.isSelected = checked;
            props.onCheckboxClick(props.userInvitationData);
        }
    }

    return (
        <div style={{ display: 'flex', paddingTop: 10 }}>
            {!props.hideCheckBox && <div style={{ flex: 'left', margin: 20, alignContent: 'center' }}>
                <Checkbox id="requested-invite-checkbox"
                    defaultChecked={props.isSelectedAll}
                    onChange={handleClick}
                    checked={props.userInvitationData.isSelected}
                    value={props.userInvitationData.isSelected} />
            </div>}
            <div style={{ flex: 'left' }}>
                <Typography
                    id="requested-invite-name-label"
                    data-testid="requested-invite-name-label"
                    style={{ alignSelf: 'center', fontWeight: 600 }} variant="subtitle1">
                    {props.userInvitationData.invitationType === ENTITY_TYPE.ORGANIZATION ? props.userInvitationData.organizationName + getInvitationTypeTitle(props.userInvitationData.invitationType)
                        : (props.userInvitationData.invitationType === ENTITY_TYPE.BUILDING ? props.userInvitationData.buldingName + getInvitationTypeTitle(props.userInvitationData.invitationType)
                            : props.userInvitationData.systemName + getInvitationTypeTitle(props.userInvitationData.invitationType))}
                </Typography>
                <Typography
                    id="requested-invite-address-label"
                    data-testid="requested-invite-address-label"
                    style={{ alignSelf: 'center' }} variant="body2">
                    {props.userInvitationData.invitationType === ENTITY_TYPE.ORGANIZATION ? props.userInvitationData.city + ', ' + props.userInvitationData.country :
                        (props.userInvitationData.invitationType === ENTITY_TYPE.BUILDING ? props.userInvitationData.organizationName
                            : props.userInvitationData.buldingName)}
                </Typography>
                <Typography
                    id="requested-invite-by-label"
                    data-testid="requested-invite-by-label"
                    style={{ alignSelf: 'center', fontWeight: 300 }} variant="body2">
                    {t('USER_INVITATION_MANAGEMENT.REQUESTEDBY') + ' ' + props.userInvitationData.requestedByUser}
                </Typography>
            </div>
        </div>
    )
}


