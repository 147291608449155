// eslint-disable-next-line
import React from 'react';
import {
    TableRow,
    IconButton,
    Typography,
    TableCell,
} from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { AccountCircle, ChevronRight, StarBorder } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useDispatch } from 'react-redux';
import { ServiceManagerCustomerDetailListItems } from '../../types/serviceManagers';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { TAB_CUSTOMERS_KEY, updateUserTabSession } from '../../app/user-management/ManageUser';

type Props = {
    row: ServiceManagerCustomerDetailListItems;
    selectedCustomer: string;
    displayClick: Function
};

export const CustomersListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const row: ServiceManagerCustomerDetailListItems = props.row;
    const dispatch = useDispatch();
    const history = useHistory();

    const handleDisplay = () => {
        props.displayClick({ customerData: row });
    };

    const NavigateToCustomerDetails = (): void => {
        pushAppRoute(
            history,
            { type: 'CustomerDetails' },
            { backRoute: 'ManageUsers', customerNo: row.customerNumber , customerId: row.id }

        );
        updateUserTabSession(TAB_CUSTOMERS_KEY);
    };

    return (
        <TableRow
            id={`row${row.id}`}
            data-testid={`row${row.id}`}
            style={{ cursor: 'pointer', width: 'auto', backgroundColor: props.selectedCustomer === row.id ? PXBColors.blue[50] : PXBColors.white[100] }}
            onClick={(): void => handleDisplay()}
        >
            <TableCell style={{ width: '0%', paddingLeft: 10 }}
                id="sla-list-item-favorite"
                data-testid="sla-list-item-favorite"
                padding="checkbox">
                {<StarBorder style={{ color: '#727E84' }} />}
            </TableCell>
            <TableCell
                style={{ width: '25%' }}
                id="users-servicePartner-list-name"
                data-testid="users-servicePartner-list-name"
                align="left"
                padding="checkbox"
            >
                <div style={{ display: 'flex', height: 40, gap: row.imageBase64 ? 20 : 16, alignItems: 'center' }}>
                    {row.imageBase64 ? <img style={{ height: 30, width: 30, borderRadius: '50%' }} src={"data:image/jpeg;base64," + row.imageBase64} alt="ListItemAvatar" /> :
                        <AccountCircle fontSize='large' style={{ color: PXBColors.gray[500] }}></AccountCircle>
                    }
                    {row.companyName}
                </div>
            </TableCell>
            <TableCell align="left" style={{ minWidth: 152 }}
                id="users-servicePartner-list-country-name"
                data-testid="users-servicePartner-list-country-name"
                padding="checkbox">
                {row.country}
            </TableCell>

            <TableCell align="left" style={{ minWidth: 152 }}
                id="users-servicePartner-list-customer-city"
                data-testid="users-servicePartner-list-customer-city"
                padding="checkbox">
                {row.city}
            </TableCell>
            <TableCell align="left" style={{ minWidth: 152 }}
                id="users-servicePartner-list-customer-customerNumber"
                data-testid="users-servicePartner-list-item-customer-customerNumber"
                padding="checkbox">
                {row.customerNumber}
            </TableCell>
            <TableCell align="left"
                id="PAAS-list-item-status"
                data-testid="PAAS-list-item-status"
                padding="checkbox">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <div style={{ minWidth: 25 }}>
                        <CheckCircleIcon color="success" fontSize="small" /></div>


                    <Typography
                        id="servicePartner-status"
                        data-testid="servicePartner-status"
                        style={{ textTransform: 'none', marginLeft: 10, fontWeight: 600 }} variant="body2">
                        {row.status ? t('DEVICE_DETAILS.INACTIVE') : t('LOGBOOK_DETAILS.ACTIVE')}
                    </Typography>
                </div>
            </TableCell>

            <TableCell style={{ width: '0%' }}
                id="users-servicePartner-list-item-next"
                data-testid="users-servicePartner-list-item-next" onClick={() => NavigateToCustomerDetails()}>
                <IconButton edge="end" id="users-servicePartner-list-item-next-icon" style={{ marginLeft: "auto" }}>
                    <ChevronRight />
                </IconButton>
            </TableCell>
            <TableCell align="left" padding="checkbox" style={{ width: '0%', padding: 0 }}
                id="users-servicePartner-list-item-empty"
                data-testid="users-servicePartner-list-item-empty">
            </TableCell>
        </TableRow>
    );
};
