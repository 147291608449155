import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { SYSTEM_LOG_TIMELINE } from './url-constants';

type SystemLogEndpointSpecifications = ApiEndpointSpecification;
export const SystemLogEndpointSpecification: SystemLogEndpointSpecifications = {
    url: SYSTEM_LOG_TIMELINE,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'SystemLogRequestParams',
    requestBodySchemaName: 'SystemLogRequestBody',
    okResponseSchemaName: 'SystemLogOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeSystemLogTimeLine = Helpers.makeApiRequestFn<
    Types.SystemLogRequestParams,
    Types.SystemLogRequestBody,
    Types.SystemLogOkResponse
>(SystemLogEndpointSpecification);
