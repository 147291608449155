// eslint-disable-next-line
import React, { useEffect } from 'react';
import {
    Typography,
    Divider,
    Card,
    MenuItem,
} from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { Button } from '@mui/material';
import { AccountCircle, Call, ChevronRight, Delete, Edit, Fingerprint, Notes, Person, Room } from '@mui/icons-material';
import { OrgDetailListItem } from '../../components/OverviewList/OrgDetailListItem';
import { COLOR_GRAY_ICON } from '../../constants/color-codes';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { DeviceCustomerDetail } from '../../types/customer-detail';
import { ActionType } from '../../constants';
import { useAuthState } from '../../components/AuthContextProvider';
import { getUserRoleType } from '../../components/AuthContextProvider/component';
import { USER_ROLE_TYPE } from '../enum/ENUM';
import { TAB_BUILDING_KEY, TAB_SYSTEM_KEY, updateTabSession } from './Assets';

type Props = {
    deviceId: string;
    projectId: string;
    customerInfo: DeviceCustomerDetail;
    customerId: string;
    customerNo: string;
};

export const CustomerInfoCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const history = useHistory();
    const authState = useAuthState();
    const usertype = getUserRoleType(authState);


    const goToCreateCustomer = (): void => {
        pushAppRoute(history,
            { type: 'AddCustomerDetails', actionType: ActionType.EDIT },
            { backRoute: 'Assets', deviceId: props.deviceId, projectId: props.projectId,customerNo: props.customerNo , customerId: props.customerId }
        );
    };

    const goToCustomerList = (): void => {
        pushAppRoute(history,
            { type: 'AddCustomerDetails', actionType: ActionType.EDIT },
            { backRoute: 'Assets', deviceId: props.deviceId, projectId: props.projectId, }
        );
    };

    const navigateToAssetSystems = (): any => {
        pushAppRoute(
            history,
            { type: 'Assets' },
            { backRoute: 'CustomerDetails', customerID: props.customerId }
        );
        updateTabSession(TAB_SYSTEM_KEY);
    };

    const navigateToAssetBuildings = (): any => {
        pushAppRoute(
            history,
            { type: 'Assets' },
            { backRoute: 'CustomerDetails', customerID: props.customerId }
        );
        updateTabSession(TAB_BUILDING_KEY);
    };

    return (
        <Card style={{ height: 'fit-content', flex: '1 1 0px', overflow: 'auto' }}
            id="customer-detail-info-container"
            data-testid="customer-detail-info-container">
            <div style={{ display: 'flex', justifyContent: 'center', padding: 16 }} data-testid="customer-logo">
                {props.customerInfo.imageBase64 ? <img style={{ display: 'flex', width: 100, height: 100 }} src={"data:image/jpeg;base64," + props.customerInfo.imageBase64} alt="" /> : <AccountCircle style={{ display: 'flex', width: 96, height: 96, justifyContent: 'center', alignItems: 'center', flexShrink: 0, color: COLOR_GRAY_ICON }} />}
            </div>
            <Divider />
            <InfoListItem data-testid='customer-name' title={props.customerInfo.companyName}
                icon={<Person style={{ color: COLOR_GRAY_ICON }} />}
                iconAlign={'center'}
                divider={'full'} placeholder={undefined}
            />
            <InfoListItem data-testid='customer-number' title={props.customerInfo.customerNumber} subtitle={t('CUSTOMER_DETAILS.CUSTOMER_NUMBER')}
                icon={<Fingerprint style={{ color: COLOR_GRAY_ICON }} />}
                iconAlign={'center'}
                divider={'full'} placeholder={undefined}
                onClick={() => goToCustomerList()}
            />
            <OrgDetailListItem
                title1={props.customerInfo.companyAddress1}
                title2={props.customerInfo.companyAddress2 + ' ' + props.customerInfo.city}
                title3={props.customerInfo.country}
                subtitle={t('ORGANIZATION_DETAILS.ADDRESS')}
                icon={<Room style={{ color: COLOR_GRAY_ICON }} />}
                iconAlignment={'center'}
                displayAction={false}
                dividerDisable={true}
            />
            <Divider />
            <OrgDetailListItem
                title1={'+' + (props.customerInfo.companyCountryCode ? props.customerInfo.companyCountryCode : '') + ' ' + (props.customerInfo.companyPhone ? props.customerInfo.companyPhone : '')}
                title2={''}
                title3={''}
                subtitle={t('FORMS.PHONE_NUMBER')}
                icon={<Call style={{ color: COLOR_GRAY_ICON }} />}
                iconAlignment={'center'}
                displayAction={false}
                dividerDisable={true}
            />
            <Divider />
            <OrgDetailListItem
                title1={''}
                title2={''}
                title3={''}
                subtitle={props.customerInfo.comments}
                subtitle2={''}
                icon={<Notes style={{ color: COLOR_GRAY_ICON }} />}
                iconAlignment={'center'}
                displayAction={false}
                dividerDisable={true}
            />
            <Divider />
            <MenuItem divider={true} style={{
                display: 'flex', paddingTop: 14, paddingRight: 16, paddingBottom: 14, paddingLeft: 22, justifyContent: 'space-between',
                alignItems: 'flex-start', alignSelf: 'stretch'
            }} data-testid='allSystemNavigationClick' onClick={navigateToAssetSystems}>
                <Typography data-testid='allSystemId' color={PXBColors.black[500]} style={{ fontSize: 16, fontWeight: 600, lineHeight: 'normal' }}>

                    {t('SEARCH.ALL_SYSTEMS')}</Typography>
                <ChevronRight ></ChevronRight>
            </MenuItem>
            <MenuItem divider={true} style={{
                display: 'flex', paddingTop: 14, paddingRight: 16, paddingBottom: 14, paddingLeft: 22, justifyContent: 'space-between',
                alignItems: 'flex-start', alignSelf: 'stretch'
            }} data-testid='allBuildingNavigationClick' onClick={navigateToAssetBuildings}>
                <Typography data-testid='allBuildingsId' color={PXBColors.black[500]} style={{ fontSize: 16, fontWeight: 600, lineHeight: 'normal' }}>

                    {t('SEARCH.ALL_BUILDINGS')}</Typography>
                <ChevronRight ></ChevronRight>
            </MenuItem>

            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15, marginRight: 15, marginBottom: 15 }} >
                {((usertype === USER_ROLE_TYPE.SERVICE_MANAGER)) &&
                    <Button
                        style={{
                            backgroundColor: PXBColors.blue[700],
                            color: PXBColors.white[50],
                            marginTop: 15,
                            textTransform: 'none',
                        }}
                        id="customerInfo-edit-button"
                        data-testid="customerInfo-edit-button"
                        variant="contained"
                        onClick={(): void => goToCreateCustomer()}
                    >
                        <Edit style={{ padding: 3 }} />
                        {t('CUSTOMER_DETAILS.EDIT_CUSTOMER_INFO')}
                    </Button>
                }
                {usertype === USER_ROLE_TYPE.SERVICE_MANAGER &&
                    <Button
                        style={{
                            backgroundColor: PXBColors.white[50],
                            color: PXBColors.red[500],
                            borderColor: PXBColors.red[500],
                            marginTop: 15,
                            textTransform: 'none',
                        }}
                        id="customer-delete-button"
                        data-testid="customer-delete-button"
                        variant="outlined"
                        onClick={(): void => { }}
                    >
                        <Delete style={{ padding: 3 }} />
                        {t('CUSTOMER_DETAILS.DELETE_CUSTOMER')}
                    </Button>
                }
            </div>
        </Card>
    );
};
