import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_REGISTRATION } from './url-constants';

type UserRegistrationEndpointSpecifications = ApiEndpointSpecification;
export const UserRegistrationEndpointSpecification: UserRegistrationEndpointSpecifications = {
    url: USER_REGISTRATION,
    method: HttpMethod.PUT,
    requestParamsSchemaName: 'UserRegistrationRequestParams',
    requestBodySchemaName: 'UserRegistrationRequestBody',
    okResponseSchemaName: 'UserRegistrationOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeUserRegistration = Helpers.makeApiRequestFn<
    Types.UserRegistrationRequestParams,
    Types.UserRegistrationRequestBody,
    Types.UserRegistrationOkResponse
>(UserRegistrationEndpointSpecification);
