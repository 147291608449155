import * as ApiRequests from '../../api/requests';
import { AppThunk } from '../actions';
import { AssetActions } from '../actions/asset-actions';

export const AssetThunks = {
    getDeviceList: (
        projectId: string,
        buildingId: string,
        systemId: string,
        searchText?: string,
        type?: string,
        status?: string,
        gateway?: string,
        customerId?: string,
        offset?: string,
        limit?: string,
        isFavorite?: boolean
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(AssetActions.deviceListStarted());

        const payload = await ApiRequests.requestDeviceList(
            {
                projectId: projectId,
                buildingId: buildingId,
                systemId: systemId,
                type: type,
                status: status,
                gateWayId: gateway,
                searchText: searchText,
                customerId: customerId,
                offset: offset,
                limit: limit,
                isFavorite: isFavorite
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(AssetActions.deviceListSucceeded(
                payload.response.data,
            ));
            return;
        }

        dispatch(AssetActions.deviceListFailed(
            `${ApiRequests.extractResponseNotOkCode(payload.response)}`
        ));
    },

    getLuminaryList: (
        systemId: string,
        luminaryId: string,
        limit?: string,
        offSet?: string,
        circuit?: string[],
        ATSD?: string[],

    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(AssetActions.luminaryListStarted());

        const payload = await ApiRequests.requestLuminaryList(
            {
                systemId: systemId,
                luminaryId: luminaryId,
                limit: limit,
                offSet: offSet,
                circuit: circuit,
                ATSD: ATSD
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(AssetActions.luminaryListSucceeded(
                payload.response.data
            ));
            return;
        }

        dispatch(AssetActions.luminaryListFailed(
            `${ApiRequests.extractResponseNotOkCode(payload.response)}`
        ));
    },
    getGatewayDetailsList: (
        projectId: string,
        buildingId: string,
        gatewayId: string,
        customerId?: string,
        searchText?: string,
        offSet?: string,
        limit?: string,
        isFavorite?: boolean,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(AssetActions.gatewayDetailsListStarted());

        const payload = await ApiRequests.requestGatewayDetailsList(
            {
                projectId: projectId,
                buildingId: buildingId,
                gatewayId: gatewayId,
                customerId: customerId,
                searchText: searchText,
                offset: offSet,
                limit: limit,
                isFavorite: isFavorite
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(AssetActions.gatewayDetailsListSucceeded(
                payload.response.data,
            ));
            return;
        }

        dispatch(AssetActions.gatewayDetailsListFailed(
            `${ApiRequests.extractResponseNotOkCode(payload.response)}`
        ));
    },

    getSLAList: (
        projectId: string,
        buildingId: string,
        customerNumber: string,
        status: string,
        offSet?: string,
        Limit?: string,
        searchText?: string,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(AssetActions.slaListStarted());

        const payload = await ApiRequests.requestSLAList(
            {
                projectId: projectId,
                buildingId: buildingId,
                customerNumber: customerNumber,
                status: status,
                offSet: offSet,
                Limit: Limit,
                searchText: searchText,
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(AssetActions.slaListSucceeded(
                payload.response.data,
            ));
            return;
        }

        dispatch(AssetActions.slaListFailed(
            `${ApiRequests.extractResponseNotOkCode(payload.response)}`
        ));
    },

    getSlaCustomerList: (
        searchText: string,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(AssetActions.slaCustomerListStarted());

        const payload = await ApiRequests.requestSLACustomerList(
            {
                searchText: searchText,
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(AssetActions.slaCustomerListSucceeded(
                payload.response.data,
            ));
            return;
        }

        dispatch(AssetActions.slaCustomerListFailed(
            `${ApiRequests.extractResponseNotOkCode(payload.response)}`
        ));
    },

    getDeviceCustomerData: (systemId?: string, customerNumber?: string): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(AssetActions.deviceCustomerDataStarted());

        const payload = await ApiRequests.getCustomerDetail({ systemId, customerNumber }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(
                AssetActions.deviceCustomerDataSucceeded(payload.response.data)
            );
        } else {
            dispatch(AssetActions.deviceCustomerDataFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },
    updateCustomer: (
        CustomerId: string,
        companyName: string,
        customerNumber: string,
        companyAddress1: string,
        companyAddress2: string,
        postalCode: string,
        city: string,
        country: string,
        companyCountryCode: string,
        companyPhone: string,
        comments: string,
        primaryFirstName: string,
        primaryLastName: string,
        primaryCountryCode: string,
        primaryPhone: string,
        primaryCountryCode2: string,
        primaryPhone2: string,
        primaryEmail: string,
        primaryContactType: number,
        primaryContactComments: string,
        secondaryFirstName: string,
        secondaryLastName: string,
        secondaryCountryCode: string,
        secondaryPhone: string,
        secondaryCountryCode2: string,
        secondaryPhone2: string,
        secondaryEmail: string,
        secondaryContactType: number,
        secondaryContactComments: string,
        imageBase64: string,
        imageType: string,

    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(AssetActions.updateCustomerStarted());

        const payload = await ApiRequests.updateCustomer(
            {
                CustomerId: CustomerId,
                companyName: companyName,
                customerNumber: customerNumber,
                companyAddress1: companyAddress1,
                companyAddress2: companyAddress2,
                postalCode: postalCode,
                city: city,
                country: country,
                companyCountryCode: companyCountryCode,
                companyPhone: companyPhone,
                comments: comments,
                primaryFirstName: primaryFirstName,
                primaryLastName: primaryLastName,
                primaryCountryCode: primaryCountryCode,
                primaryPhone: primaryPhone,
                primaryCountryCode2: primaryCountryCode2,
                primaryPhone2: primaryPhone2,
                primaryEmail: primaryEmail,
                primaryContactType: primaryContactType,
                primaryContactComments: primaryContactComments,
                secondaryFirstName: secondaryFirstName,
                secondaryLastName: secondaryLastName,
                secondaryCountryCode: secondaryCountryCode,
                secondaryPhone: secondaryPhone,
                secondaryCountryCode2: secondaryCountryCode2,
                secondaryPhone2: secondaryPhone2,
                secondaryEmail: secondaryEmail,
                secondaryContactType: secondaryContactType,
                secondaryContactComments: secondaryContactComments,
                imageBase64: imageBase64,
                imageType: imageType,
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(AssetActions.updateCustomerSucceeded(payload.response.data.id));
            return;
        }
        dispatch(AssetActions.updateCustomerFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },
};
