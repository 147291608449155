import { AppRoutes, AppRoute } from '../../routes';
import { History } from 'history';

export const getAppRouteUrl = (destination: AppRoute): string | undefined => {
    const url = AppRoutes[destination.type];
    if (!url) {
        return undefined;
    }

    const keys = Object.keys(destination);

    const path = keys.reduce((acc, key) => {
        const re = new RegExp(`(:${key})(\\/|$)`);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return acc.replace(re, `${encodeURIComponent((destination as any)[key])}$2`);
    }, url);

    return path;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pushAppRouteOverview = (history: History<any>, appRoute: AppRoute, locationState?: any): void => {
    const url = getAppRouteUrl(appRoute);
    if (!url) {
        console.error('Invalid app route', appRoute);
        return;
    }
    const location = {
        pathname: url,
        state: locationState,
    };
    history.push(location);
};
