// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Card, Divider, Button, CircularProgress, DialogTitle, Dialog, DialogContent, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, TextField, InputAdornment, IconButton, DialogActions, Portal, Snackbar } from '@mui/material';
import { Business, Call, ContactPhone, DeleteForever, Edit, Email, Group, Notes, Room, Visibility, VisibilityOff } from '@mui/icons-material';
import * as PXBColors from '@brightlayer-ui/colors';
import { Typography } from '@mui/material';
import { OrgDetailListItem } from '../../components/OverviewList/OrgDetailListItem';
import { COLOR_GRAY_ICON } from '../../constants/color-codes';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { OrgBuildingData, OrgBuildingList } from '../../types/organization';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { EmptyState } from '@brightlayer-ui/react-components';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { ENTITY_TYPE } from '../enum/ENUM';
import Merge from '../../assets/images/Merge.svg';
import { DialogContentText } from '@material-ui/core';
import { ItemList } from '../../types/logbook-type';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { CardItemList } from '../../types/overview-type';
import { BuildingList } from '../../types/building';
import { OrganizationThunks } from '../../actions/thunks/organization-thunks';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { useAuthHelper } from '../../components/AuthContextProvider';
import { OrganizationActions } from '../../actions/actions/organization-actions';
import { Thunks } from '../../actions';

const buildingDetailsDefault: OrgBuildingData = {
    buildingId: "",
    name: "",
    parentProjectId: "",
    projectName: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    contact: '',
    email: "",
    countryCode: "",
    phone: "",
    description: "",
    adopterId: '',
    latitude: "",
    longitude: "",
    gatewayCount: 0,
    systemCount: 0,
    adminCount: 0,
    viewerCount: 0,
    isAdmin: false,
    isFavourite: false,
    address2: '',
    secondaryEmail: '',
    secondaryCountryCode: '',
    secondaryPhone: '',
    primaryFirstName: '',
    primaryLastName: '',
    secondaryFirstName: null,
    secondaryLastName: null
};

type Props = {
    nameUpdate: Function;
    onClick: Function;
    onUserClick: Function;
    buildingId: string,
    orgId: string
};

export const BuildingInfo: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const history = useHistory();
    const dispatch = useDispatch();
    const DEFAULT_ID: string = '';
    const [buildingDetails, setBuildingDetails] = useState<OrgBuildingData>(buildingDetailsDefault);
    const organization = useSelector(Selectors.Organization.organization);
    const [mergeAssetBuildingDialog, setmergeAssetBuildingDialog] = React.useState(false);
    const [buildingSelection, setBuildingSelection] = React.useState(DEFAULT_ID);
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [buildingList, setBuildingList] = useState<ItemList>([]);
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const authHelper = useAuthHelper();
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [alertErrorState, setAlertErrorState] = useState(false);
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');

    const fieldsComplete = (fields: string[]): boolean => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].length < 1) {
                return false;
            }
        }
        return true;
    };

    const editBuilding = (): void => {
        pushAppRoute(history,
            { type: 'EditBuilding', orgId: props.orgId, buildingId: props.buildingId },
            { backRoute: 'OrgBuildingDetails', assetId: props.buildingId, assetType: ENTITY_TYPE.BUILDING, title: '', orgId: props.orgId, buildingId: props.buildingId });
    };

    const mergeBuildingAssets = (): void => {
        setmergeAssetBuildingDialog(true);
    };

    const deleteForever = (): void => {
    };

    const goToUserDetails = (): void => {
        props.onUserClick();
    };

    const goToOrganizationDetails = (): void => {
        props.onClick({ orgId: buildingDetails.parentProjectId, orgName: buildingDetails.projectName });
    };

    const handleCloseTransferBuildingDialog = () => {
        setmergeAssetBuildingDialog(false);

    };
    const handleSetBuilding = (event: SelectChangeEvent) => {
        setBuildingSelection(event.target.value);
    };
    const handleClickShowPassword = (): void => {
        setShowPassword(!showPassword);
    };
    const buildingData = (list: BuildingList): ItemList => {
        return list?.map((data, building) => {
            return {
                id: data.buildingId,
                name: data.name,
            };
        });
    };
    const onTransferClick = (): void => {
        dispatch(OrganizationThunks.mergeAssetBuilding(
            props.buildingId,
            buildingSelection,
            password,
            authHelper
        ));
    }
    const handleMergeBuildingAssetResponse = (): void => {
        if (!organization.mergeBuildingAssetLoading && organization.mergeBuildingAssetFail) {
            if (organization.mergeBuildingAssetErrorCode === '9001')
                setAlertErrorMessage(t('USERS.ADD_PAAS_MANAGER_ERROR_CODE_9001'));
            else if (organization.mergeBuildingAssetErrorCode === '9048')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_MERGE_ASSET_9048'));
            else if (organization.mergeBuildingAssetErrorCode === '9210')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_MERGE_ASSET_9210'));
            else if (organization.mergeBuildingAssetErrorCode === '9004')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_MERGE_ASSET_9004'));
            else if (organization.mergeBuildingAssetErrorCode === '9212')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_MERGE_ASSET_9212'));
            else if (organization.mergeBuildingAssetErrorCode === '9211')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_MERGE_ASSET_9211'));
            else if (organization.mergeBuildingAssetErrorCode === '9213')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_MERGE_ASSET_9213'));
            else if (organization.mergeBuildingAssetErrorCode === '9044')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_MERGE_ASSET_9044'));
            else if (organization.mergeBuildingAssetErrorCode === '9214')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_MERGE_ASSET_9214'));
            else
                setAlertErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }

    };

    useEffect(() => {
        dispatch(Thunks.Dashboard.getBuildingList(props.orgId));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.buildingListLoading && organization.buildingListSuccess) {
            setBuildingDetails(organization.buildingList[0]);
            if (props.nameUpdate) { props.nameUpdate(organization.buildingList[0].name) }
        } else if (!organization.buildingListLoading && organization.buildingListFail) {
            setBuildingDetails(buildingDetailsDefault);
        }
    }, [organization.buildingListLoading, organization.buildingListSuccess, organization.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            setBuildingList(buildingData(dashboard.buildingList.detail.filter((data) => (data.isAdmin === true) && (data.buildingId != props.buildingId))));
        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            setBuildingList([]);
        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.mergeBuildingAssetLoading && organization.mergeBuildingAssetSuccess) {
            handleCloseTransferBuildingDialog();
            history.goBack();
        }
        else if (!organization.mergeBuildingAssetLoading && organization.mergeBuildingAssetFail) {
            handleMergeBuildingAssetResponse()
            setAlertErrorState(true);
            dispatch(OrganizationActions.mergeBuildingAssetUnmounted());
        }
    }, [organization.mergeBuildingAssetLoading, organization.mergeBuildingAssetSuccess, organization.mergeBuildingAssetFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.upsertBuildingDetailLoading && organization.upsertBuildingDetailSuccess) {
            setAlertSuccessMessage(t('TOAST_MSG.TOAST_SUCCESS_BUILDING_UPDATE'));
            setDisplaySuccessMessage(true);
        }
        dispatch(OrganizationActions.upsertBuildingUnmounted());
    }, [organization.upsertBuildingDetailLoading, organization.upsertBuildingDetailSuccess])// eslint-disable-line react-hooks/exhaustive-deps

    const usersAdminText = buildingDetails.adminCount > 1 ? buildingDetails.adminCount + ' ' + t('DASHBOARD.USERS_ADMIN_S') : buildingDetails.adminCount + ' ' + t('DASHBOARD.USERS_ADMIN');
    const usersVieweText = buildingDetails.viewerCount > 1 ? buildingDetails.viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER_S') : buildingDetails.viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER');

    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }}
                id="building-detail-info-container"
                data-testid="building-detail-info-container">

                {organization.buildingListLoading && <EmptyState style={{ flex: 1, minHeight: 400 }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

                {!organization.buildingListLoading && <div>
                    <Typography variant="body2" style={{ alignItems: 'left', fontSize: 20, fontWeight: 600, width: '90%', margin: 20, marginBottom: 5 }}>
                        {buildingDetails.name}
                    </Typography>

                    <OrgDetailListItem
                        title1={buildingDetails.address + ' ' + buildingDetails.address2}
                        title2={buildingDetails.postalCode + ' ' + buildingDetails.city}
                        title3={buildingDetails.country}
                        subtitle={t('ORGANIZATION_DETAILS.ADDRESS')}
                        icon={<Room style={{ color: COLOR_GRAY_ICON }} />}
                        iconAlignment={'center'}
                        displayAction={false}
                    />

                    <OrgDetailListItem
                        title1={'+' + buildingDetails.countryCode + ' ' + buildingDetails.phone}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.PHONE_NUMBER')}
                        icon={<Call style={{ color: COLOR_GRAY_ICON }} />}
                        iconAlignment={'center'}
                        displayAction={false}
                    />

                    <OrgDetailListItem
                        title1={buildingDetails.email}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.EMAIL_ADDRESS')}
                        icon={<Email style={{ color: COLOR_GRAY_ICON }} />}
                        iconAlignment={'center'}
                        displayAction={false}
                    />

                    <OrgDetailListItem
                        title1={buildingDetails.projectName}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.ORGANIZATION')}
                        icon={<Business style={{ color: COLOR_GRAY_ICON }} />}
                        iconAlignment={'center'}
                        displayAction={true}
                        OnItemSelected={goToOrganizationDetails}
                    />
                    <OrgDetailListItem
                        title1={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT')}
                        title2={''}
                        title3={''}
                        subtitle={(buildingDetails.primaryFirstName ? buildingDetails.primaryFirstName : '') + ' ' + (buildingDetails.primaryLastName ? buildingDetails.primaryLastName : '')}
                        icon={<ContactPhone style={{ color: COLOR_GRAY_ICON }} />}

                        displayAction={false}
                        dividerDisable={true} iconAlignment={'center'} />
                    <OrgDetailListItem
                        title1={'+' + buildingDetails.countryCode + ' ' + buildingDetails.phone}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT_PHONE_NUMBER')}

                        displayAction={false}
                        dividerDisable={true} icon={undefined} iconAlignment={''} />
                    <OrgDetailListItem
                        title1={buildingDetails.email ? buildingDetails.email : ''}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT_EMAIL_ADDRESS')}
                        displayAction={false} icon={undefined} iconAlignment={''} />
                    <OrgDetailListItem
                        title1={t('ORGANIZATION_DETAILS.SECONDARY_CONTACT')}
                        title2={''}
                        title3={''}
                        subtitle={(buildingDetails.secondaryFirstName ? buildingDetails.secondaryFirstName : '') + ' ' + (buildingDetails.secondaryLastName ? buildingDetails.secondaryLastName : '')}
                        icon={<ContactPhone style={{ color: COLOR_GRAY_ICON }} />}

                        displayAction={false}
                        dividerDisable={true} iconAlignment={'center'} />
                    <OrgDetailListItem
                        title1={buildingDetails.secondaryPhone ? '+' + buildingDetails.secondaryCountryCode + ' ' + buildingDetails.secondaryPhone : ''}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.SECONDARY_CONTACT_PHONE_NUMBER')}

                        displayAction={false}
                        dividerDisable={true} icon={undefined} iconAlignment={''} />
                    <OrgDetailListItem
                        title1={buildingDetails.secondaryEmail ? buildingDetails.secondaryEmail : ''}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.SECONDARY_CONTACT_EMAIL_ADDRESS')}
                        displayAction={false} icon={undefined} iconAlignment={''} />

                    <OrgDetailListItem
                        title1={usersAdminText + ', ' + usersVieweText}
                        title2={''}
                        title3={''}
                        subtitle={t('ORGANIZATION_DETAILS.USERS')}
                        icon={<Group style={{ color: COLOR_GRAY_ICON }} />}
                        iconAlignment={'center'}
                        displayAction={true}
                        OnItemSelected={goToUserDetails}
                    />

                    <OrgDetailListItem
                        title1={''}
                        title2={''}
                        title3={''}
                        subtitle={''}
                        subtitle2={buildingDetails.description}
                        icon={<Notes style={{ color: COLOR_GRAY_ICON }} />}
                        iconAlignment={'center'}
                        displayAction={false}
                        dividerDisable={true}
                    />

                    <Divider />

                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15, marginRight: 15, marginBottom: 15 }} >

                        <Button
                            style={{
                                backgroundColor: PXBColors.blue[700],
                                color: PXBColors.white[50],
                                marginTop: 15,
                                opacity: buildingDetails.isAdmin ? 1 : 0.5,
                                textTransform: 'none'
                            }}
                            id="building-edit-button"
                            data-testid="building-edit-button"
                            variant="contained"
                            disabled={!buildingDetails.isAdmin}
                            onClick={(): void => editBuilding()}
                        >
                            <Edit style={{ padding: 3 }} />
                            {t('ORGANIZATION_DETAILS.EDIT_BUILDING')}
                        </Button>


                        <Button
                            style={{
                                backgroundColor: PXBColors.white[50],
                                borderColor: PXBColors.blue[700],
                                marginTop: 15,
                                opacity: buildingDetails.isAdmin ? 1 : 0.5,
                                color: PXBColors.blue[500],
                                textTransform: 'none'
                            }}
                            disabled={buildingDetails.isAdmin ? (buildingList.length === 0) || (buildingDetails.gatewayCount === 0 && buildingDetails.systemCount === 0) : true}
                            id="building-merge-button"
                            data-testid="building-merge-button"
                            variant="outlined"
                            onClick={(): void => mergeBuildingAssets()}
                        >
                            <img src={Merge} alt="merge" style={{ marginRight: 8 }} />
                            {t('ORGANIZATION_DETAILS.MERGE_ASSETS')}
                        </Button>

                        <Button
                            style={{
                                backgroundColor: PXBColors.white[50],
                                color: PXBColors.red[500],
                                borderColor: PXBColors.red[500],
                                marginTop: 15,
                                opacity: buildingDetails.isAdmin ? 1 : 0.5,
                                textTransform: 'none'
                            }}
                            id="building-delete-button"
                            data-testid="building-delete-button"
                            disabled={true}
                            variant="outlined"
                            onClick={(): void => deleteForever()}
                        >
                            <DeleteForever style={{ padding: 3 }} />
                            {t('ORGANIZATION_DETAILS.DELETE_FOREVER')}
                        </Button>
                    </div>
                </div>}
            </Card>

            {mergeAssetBuildingDialog && <Dialog id="transfer-building-dialog" data-testid="merge-asset" maxWidth="xs" open={true}>
                <DialogTitle id="dialog-header"  >{t('MERGE_ASSETS.MERGE_ASSETS_TITLE')}</DialogTitle>
                <DialogContent>
                    <DialogContentText data-testid="dialog-header-text" style={{ marginBottom: 20 }}>
                        {t('MERGE_ASSETS.HEADER', { replace: { gatewayCount: buildingDetails.gatewayCount, systemCount: buildingDetails.systemCount } })}
                    </DialogContentText>
                    <>
                        <div>
                            <FormControl variant={'filled'} data-testid="transfer-to" fullWidth>
                                <InputLabel >
                                    {t('TRANSFER_BUILDINGS.TRANSFER_TO')}
                                </InputLabel>
                                <Select
                                    variant={'filled'}
                                    fullWidth style={{ marginBottom: 36, backgroundColor: PXBColors.white[500] }}
                                    labelId="transfer-building-label"
                                    inputProps={{ 'data-testid': 'transfer-building' }}
                                    data-testid="transfer-building"
                                    value={buildingSelection}
                                    onChange={handleSetBuilding}
                                >
                                    {buildingList.map((data) => {
                                        return (
                                            <MenuItem id="org-list" key={data.id} value={data.id}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left', fontSize: 14,
                                                    lineHeight: 1.5, fontWeight: 400,
                                                    overflow: 'hidden',
                                                    boxSizing: 'border-box',
                                                    whiteSpace: 'nowrap',
                                                    minHeight: 48,
                                                    paddingTop: 6,
                                                    paddingBottom: 6,
                                                    width: 'auto',
                                                    paddingLeft: 16,
                                                    paddingRight: 16
                                                }}>
                                                {data.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <Typography style={{ marginBottom: 16 }} data-testid='transfer-password'>{t('TRANSFER_BUILDINGS.ACTION')} </Typography>
                            <TextField
                                fullWidth style={{ marginBottom: 35 }}
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                name="password"
                                label={t('TRANSFER_BUILDINGS.PASSWORD')}
                                value={password}
                                onChange={(evt: ChangeEvent<HTMLInputElement>): void => setPassword(evt.target.value)}
                                variant="filled"
                                error={Boolean(organization.transferBuildingErrorMessage)}
                                helperText={t(organization.transferBuildingErrorMessage)}
                                inputProps={{
                                    'data-testid': 'password',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </>
                </DialogContent>
                <Divider style={{ color: 'grey' }} />
                <DialogActions style={{ justifyContent: 'space-between', marginTop: 15, marginBottom: 15, marginLeft: 16, marginRight: 17 }}>
                    <Button style={{ textTransform: 'none' }} id="btnCancel" data-testid="btnCancel" variant="outlined" color="primary" onClick={handleCloseTransferBuildingDialog}>
                        {t('ACTIONS.CANCEL')}
                    </Button>
                    <Button
                        style={{
                            background: PXBColors.red[500], color: PXBColors.white[50],
                            opacity: fieldsComplete([buildingSelection, password]) ? 1 : 0.5,
                            textTransform: 'none'
                        }}
                        disabled={!fieldsComplete([buildingSelection, password])}
                        id="btnTransfer"
                        data-testid="btnTransfer"
                        variant="contained"
                        onClick={onTransferClick}
                    >
                        {t('MERGE_ASSETS.MERGE_DELETE')}
                    </Button>
                </DialogActions>
            </Dialog>
            }
            {
                alertErrorState &&
                <Portal>
                    <Snackbar
                        open={true}
                        onClick={() => setAlertErrorState(false)}
                        autoHideDuration={20}
                        title={''}
                        message={alertErrorMessage}
                        action={
                            <Button color="inherit" size="small" onClick={() => setAlertErrorState(false)}>
                                {t('ACTIONS.RETRY')}
                            </Button>
                        }
                        ContentProps={{ sx: { background: PXBColors.red[500] } }}
                        anchorOrigin={{ vertical: 'top', horizontal: "center" }}
                        sx={{ bottom: { xs: 90, sm: 0 } }}

                        style={{ height: '100%', width: 200, flexGrow: 0 }}
                    />
                </Portal>}

            {
                displaySuccessMessage &&
                <Snackbar
                    open={true}
                    onClick={() => setDisplaySuccessMessage(false)}
                    autoHideDuration={20}
                    title={''}
                    message={alertSuccessMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setAlertErrorState(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.black[900] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }
        </>
    );
};
