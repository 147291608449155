
// eslint-disable-next-line
import React, { useState } from 'react';
import { useAppData } from '../../components/AppDataProvider';
import { isDataLoaded } from '../../types/data-providers';
import { SELECTED_ORGANIZATION } from '../../constants';

type Organization = { id: string; name: string };
export type ContextState = {
    organizationList: Organization[];
    selectedOrganization: string;
    updateSelectedOrganization: (organizationId: string) => void;
    orgListLoaded: boolean;
    setTabClicked: (flag: boolean) => void;
    isTabClicked: boolean;
};
const Context = React.createContext<ContextState | null>(null);

function useOrganizationList(): ContextState {
    const contextState = React.useContext(Context);
    if (contextState === null) {
        throw new Error('useOrganizationList must be used within a OrganizationListProvider');
    }

    return contextState;
}
const OrganizationListProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = (props) => {
    const appData = useAppData();
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [isTabClicked, setTabClick] = useState(true);
    const updateSelectedOrganization = (organizationId: string): void => {
        sessionStorage.setItem(SELECTED_ORGANIZATION, organizationId);
        setSelectedOrganization(organizationId);
    };
    const setTabClicked = (flag: boolean): void => {
        setTabClick(flag);
    }
    let organizationList;
    organizationList = {
        organizationList: [],
        selectedOrganization: '',
        updateSelectedOrganization: updateSelectedOrganization,
        orgListLoaded: false,
        setTabClicked: setTabClicked,
        isTabClicked: isTabClicked
    };
    if (isDataLoaded(appData) && appData.value.organizations.ok) {
        const emptyOrganizations: Organization[] = [];
        const organizations = Object.values(appData.value.organizations.value).reduce((memo, org, i) => {
            memo[i] = {
                id: Object.values(org)[1].id,
                name: Object.values(org)[1].name,
            };
            return memo;
        }, emptyOrganizations);

        if (selectedOrganization === '2' && organizations.length > 0) {
            const value = ''
            //'orgId' in currentAppRoute ? currentAppRoute.orgId : window.localStorage.getItem(SELECTED_ORGANIZATION);
            setSelectedOrganization(value ? value : organizations[0].id);
        }
        organizationList = {
            organizationList: organizations,
            selectedOrganization: selectedOrganization,
            updateSelectedOrganization: updateSelectedOrganization,
            orgListLoaded: true,
            setTabClicked: setTabClicked,
            isTabClicked: isTabClicked
        };
    }

    return <Context.Provider value={organizationList}>{props.children}</Context.Provider>;
};

export { OrganizationListProvider, useOrganizationList };
