import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_REGISTRATION_ROLES } from './url-constants';

type UserRegistrationRoleEndpointSpecifications = ApiEndpointSpecification;
export const UserRegistrationRoleEndpointSpecification: UserRegistrationRoleEndpointSpecifications = {
    url: USER_REGISTRATION_ROLES,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'UserRegistrationRoleRequestParams',
    requestBodySchemaName: 'UserRegistrationRoleRequestBody',
    okResponseSchemaName: 'UserRegistrationRoleOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeUserRegistrationRole = Helpers.makeApiRequestFn<
    Types.UserRegistrationRoleRequestParams,
    Types.UserRegistrationRoleRequestBody,
    Types.UserRegistrationRoleOkResponse
>(UserRegistrationRoleEndpointSpecification);
