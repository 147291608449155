import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { UPDATE_USER_INVITATION } from './url-constants';

type UpdateUserInvitationEndpointSpecifications = ApiEndpointSpecification;
export const UpdateUserInvitationEndpointSpecification: UpdateUserInvitationEndpointSpecifications = {
    url: UPDATE_USER_INVITATION,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'UpdateUserInvitationRequestParams',
    requestBodySchemaName: 'UpdateUserInvitationRequestBody',
    okResponseSchemaName: 'UpdateUserInvitationOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeUpdateUserInvitation = Helpers.makeApiRequestFn<
    Types.UpdateUserInvitationRequestParams,
    Types.UpdateUserInvitationRequestBody,
    Types.UpdateUserInvitationOkResponse
>(UpdateUserInvitationEndpointSpecification);
