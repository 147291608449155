import { ActionType, LegalType } from '../../types';

export const LegalActions = {
    loadOpenSourceStarted: () =>
        ({
            type: 'Legal/LoadOpenSource/Started',
        } as const),

    loadOpenSourceSucceeded: () =>
        ({
            type: 'Legal/LoadOpenSource/Succeeded',
            payload: {
                list: [
                    { id: LegalType.ReactJs, name: 'React' },
                ] as Array<{
                    id: LegalType;
                    name: string;
                }>,
                data: '',
                type: LegalType.OpenSource,
            },
        } as const),

    loadOpenSourceFailed: () =>
        ({
            type: 'Legal/LoadOpenSource/Failed',
        } as const),
};

export type LegalAction = ActionType<typeof LegalActions>;
