export * from './sort-naturally';

// For use in switch statements
// https://stackoverflow.com/a/39419171/4592309
export function assertUnreachable(x: never): never {
    throw new Error(`Didn't expect to get here. Value ${x}`);
}
/**
 * For use in switch statements.
 * Similar to `assertUnreachable`, but less strict.
 * Provide a default value or function to return.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
export function defaultUnreachable(x: never, defaultValue: any | (() => any)) {
    if (typeof defaultValue === 'function') {
        return defaultValue();
    }
    return defaultValue;
}

function parseNumber(s: string | undefined | null, parseFn: (s: string) => number): number | null {
    if (s) {
        const val = parseFn(s);
        return !Number.isNaN(val) ? val : null;
    }
    return null;
}

export const parseInteger = (s: string | undefined | null): number | null => parseNumber(s, Number.parseInt);
export const parseFloat = (s: string | undefined | null): number | null => parseNumber(s, Number.parseFloat);

export const makeWaitForTurn = (
    semaphore: { [k: string]: Promise<unknown> },
    concurrentPromisesAllowed: number,
    intervalMs: number
) => (): Promise<void> =>
    new Promise((resolve) => {
        const timerId = setInterval(() => {
            if (Object.keys(semaphore).length <= concurrentPromisesAllowed) {
                clearInterval(timerId);
                resolve();
                return;
            }
        }, intervalMs);
    });
