import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_REGISTRATION_REQUEST } from './url-constants';

type UserRegistrationRequestEndpointSpecifications = ApiEndpointSpecification;
export const UserRegistrationRequestEndpointSpecification: UserRegistrationRequestEndpointSpecifications = {
    url: USER_REGISTRATION_REQUEST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'UserRegistrationRequestRequestParams',
    requestBodySchemaName: 'UserRegistrationRequestRequestBody',
    okResponseSchemaName: 'UserRegistrationRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeUserRegistrationRequest = Helpers.makeApiRequestFn<
    Types.UserRegistrationRequestRequestParams,
    Types.UserRegistrationRequestRequestBody,
    Types.UserRegistrationRequestOkResponse
>(UserRegistrationRequestEndpointSpecification);
