// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import {
    Typography,
    Card,
    IconButton,
} from '@material-ui/core';
import ListItem from '@mui/material/ListItem';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Close } from '@mui/icons-material';
import * as PXBColors from '@brightlayer-ui/colors';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { Thunks } from '../../actions';
import { UserDetailsData } from '../../types/user-details-data';
import { defaultUserDetail } from '../../components/UserDetails/UserDetails';
import { getUserEmail, getUserId, useAuthHelper, useAuthState } from '../../components/AuthContextProvider';
import CircularProgress from '@mui/material/CircularProgress';
import { EmptyState } from '@brightlayer-ui/react-components';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Link, List, MenuItem, Portal, Select, Snackbar } from '@mui/material';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { getUserRoleByName, USER_ROLE_TYPE, userRoles } from '../enum/ENUM';
import { SelectionData } from '../../components/UserDetails/SelectionListData';
import { styled, useTheme } from '@mui/material/styles';
import { InputBase } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { COLOR_GRAY } from '../../constants/color-codes';
import { AlarmBadgeStatus } from '../../components/AlarmBadge/AlarmBadge';
import { TooltipItem } from '../../components/TooltipItem/TooltipItem';
import { getControlledText } from '../enum/GenericFormat';
import { ViewAccessDetailsDialog } from './ViewAccessDetailsDialog';
import { UserAdvanceAccessDetail, UserData } from '../../types/manage-users';
import { UsersActions } from '../../actions/actions/users-actions';
import { ItemList } from '../../types/logbook-type';
import { user } from '../../../fake-api-server/lib/generate';
import { replace } from 'connected-react-router';
import { map } from 'lodash';


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        borderBottom: '1px solid #ced4da',
        fontSize: 16,
        color: '#424E54',
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
        },
    },
}));


type Props = {
    closeClick: Function;
    otherUserId: string;
    userRole: string;
    entityId: string;
    entityName: string;
    entityType: number;
    loggedInUserData: UserData[];
};


export const ManageUserDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const authHelper = useAuthHelper();
    const authState = useAuthState();
    const userId = getUserId(authState) || '';
    const emailId = getUserEmail(useAuthState());
    const entityUserData = useSelector(Selectors.Users.users);
    const [userDetail, setUserDetail] = useState<UserDetailsData>(defaultUserDetail);
    const [showUserDetailCard, setShowUserDetailCard] = useState(false);
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [emailAdvance, setEmailAdvance] = useState<string>('');
    const [userAccessDetailData, setUserAccessDetailData] = React.useState<UserAdvanceAccessDetail>();
    const usersSelector = useSelector(Selectors.Users.users);
    const [isUserAdvanceInviteCallStatus, setUserAdvanceInviteCallStatus] = useState(true);
    const [organizationCheckList, setOrganizationCheckList] = React.useState<ItemList>([]);
    const orgSelectAllId = '-1';
    const [openAdvanceDialog, setOpenAdvanceDialog] = React.useState(false);
    const [openRemoveDialog,setOpenRemoveDialog] = React.useState(false);

    useEffect(() => {
        if (props.otherUserId !== '') {
            setUserAdvanceInviteCallStatus(false);
            dispatch(UsersActions.inviteUserUnmount());
            dispatch(Thunks.Users.getEntityUserData(props.otherUserId, authHelper, props.entityId));
        }
    }, [props.otherUserId]); // eslint-disable-line react-hooks/exhaustive-deps

    const selectedUserRole: SelectionData[] = userRoles.filter(role => role.value === props.userRole);

    useEffect(() => {
        if (!usersSelector.getuserAdvanceAccessLoading && usersSelector.getuserAdvanceAccessSuccess) {
            setUserAccessDetailData(usersSelector.getuserAdvanceAccess);
            setUserAdvanceInviteCallStatus(true);
        }
        else if (usersSelector.getuserAdvanceAccessFail && !usersSelector.getuserAdvanceAccessLoading) {
            setUserAdvanceInviteCallStatus(false);
            dispatch(UsersActions.inviteUserUnmount());
        }
    }, [usersSelector.getuserAdvanceAccessFail, usersSelector.getuserAdvanceAccessLoading, usersSelector.getuserAdvanceAccessSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    const onCancelAdvanceInvite = (): void => {
        setOpenAdvanceDialog(false);
    };

    useEffect(() => {
        if (!entityUserData.getEntityUserDetailLoading && entityUserData.getEntityUserDetailSuccess) {
            setUserDetail(entityUserData.entityUserDetail);
            getUserAdvanceAccessDetails(entityUserData.entityUserDetail.email_id)
            setShowUserDetailCard(true);
        } else if (!entityUserData.getEntityUserDetailLoading && entityUserData.getEntityUserDetailFail) {
            setUserDetail(defaultUserDetail);
            getUserAdvanceAccessDetails(defaultUserDetail.email_id);
        }
    }, [entityUserData.getEntityUserDetailLoading, entityUserData.getEntityUserDetailSuccess, entityUserData.getEntityUserDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const getUserAdvanceAccessDetails = (userEmailId: string) => {
        const selectAll = organizationCheckList.filter((org) => org.id === orgSelectAllId);
        var selectedOrganizations: string[] = [];
        if (selectAll.length > 0) {
            const allOrganizations: string[] = organizationCheckList.map((organization) => organization.id);
            selectedOrganizations = allOrganizations.filter((organization) => organization !== orgSelectAllId);
        } else {
            selectedOrganizations = organizationCheckList.map((organization) => organization.id);
        }

        if (userEmailId === emailId) {
            dispatch(Thunks.Users.getUserAdvanceAccessDetail('', selectedOrganizations));
        } else {
            dispatch(Thunks.Users.getUserAdvanceAccessDetail(userEmailId, selectedOrganizations));
        }
    }
    const removeAccess = () => {
            setOpenRemoveDialog(false);
            props.closeClick({});
            dispatch(Thunks.Users.removeAccess(props.otherUserId,props.entityId,props.entityType));
    }

 function closeUserDetailDrawer(): void {
        props.closeClick({});
    }
    const leaveOrganization = (): void => {
        props.loggedInUserData.map((item) => {
            if (getUserRoleByName(item.role).key === USER_ROLE_TYPE.EL_ORG_ADMIN || getUserRoleByName(item.role).key === USER_ROLE_TYPE.EF_BLDG_ADMIN_ORG_VIEWER || getUserRoleByName(item.role).key === USER_ROLE_TYPE.EF_SYSTEM_ADMIN_GATEWAY_VIEWER) {
                setOpenRemoveDialog(true)
            }
            else {
                setOpenRemoveDialog(false)
            }
        })
    };

    const handleOpendialog = () => {
        setOpenAdvanceDialog(true);
    }

    const isLoggedInUser: boolean = emailId === userDetail.email_id;

    const userName = isLoggedInUser ? (userDetail.firstname + " " + userDetail.lastname + " " + t('USER_INVITATION_MANAGEMENT.YOU')) : (userDetail.firstname + " " + userDetail.lastname);
    const successMessage = t('MANAGE_USERS.REMOVE_USER', { replace: {name: userDetail.display_name, title: props.entityName } })

    return (
        <>{entityUserData.getEntityUserDetailLoading && <EmptyState style={{ flex: 1, minHeight: 800, backgroundColor: PXBColors.white[50] }}
            icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
            <div>
                {!showUserDetailCard && !entityUserData.getEntityUserDetailLoading && <div><EmptyState style={{ height: 0, minHeight: 0 }}
                    title="" icon={undefined} placeholder={undefined} /><IconButton style={{ marginLeft: 80 }} id="userDetailProgressBar" data-testid="userDetailProgressBar-dataId" onClick={(): void => closeUserDetailDrawer()}>
                        <Close />
                    </IconButton>
                    <Typography style={{ paddingTop: 250, fontWeight: 400 }}>No data found</Typography>
                </div>
                }
            </div>

            {showUserDetailCard && !entityUserData.getEntityUserDetailLoading && entityUserData.getEntityUserDetailSuccess &&
                <Card style={{ flex: '1 1 0px' }} data-testid="entityUser-details-container">
                    <ListItem style={{
                        width: '100%',
                    }}
                        secondaryAction={
                            <IconButton id="entityUser-detail-close" data-testid="entityUser-detail-close" style={{ top: -10, right: -10, marginLeft: "auto", justifyContent: 'start' }} onClick={(): void => closeUserDetailDrawer()}>
                                <Close />
                            </IconButton>
                        }>
                        <div>
                            <TooltipItem
                                title={userName}
                                isTopPlacement={true}
                                lenghtLimit={22}
                                component={<Typography
                                    variant="h6"
                                    id="list-item-title" data-testid="list-item-title"
                                    style={{ fontSize: 20, fontWeight: 600, fontStyle: 'normal', color: PXBColors.black[500], top: 24, left: 16 }}>
                                    {getControlledText(userName, 24)}
                                </Typography>}>
                            </TooltipItem>
                            <div style={{ display: 'flex' }}>
                                <Typography variant="body1" style={{ fontSize: 14, fontWeight: 400, fontStyle: 'normal', color: PXBColors.black[500] }} id="userDetailEmailId" data-testid="userDetailEmailTestId" >{userDetail.email_id}</Typography>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Typography variant="body1" style={{ fontSize: 14, fontWeight: 400, fontStyle: 'normal', color: PXBColors.black[500] }} id="userDetailPhoneNumber" data-testid="userDetailPhoneNumberTestId" >{"+" + userDetail.countryCode + " " + userDetail.phone}</Typography>
                            </div>
                        </div>
                    </ListItem>
                    <List>
                        <ListItem style={{ padding: '8px 16px 8px 16px' }}>
                            {t('DEVICE_DETAILS.STATUS')}
                        </ListItem>
                        <ListItem style={{ padding: '8px 16px 8px 16px' }}>
                            <AlarmBadgeStatus
                                statusName={userDetail.userStatus ? t('DASHBOARD.TIMELINE_DETAILS.INACTIVE') : t('DASHBOARD.TIMELINE_DETAILS.OPEN')}
                                statusColor={userDetail.userStatus ? PXBColors.yellow[500] : PXBColors.green[500]}
                                foregroundColor={userDetail.userStatus ? PXBColors.black[500] : PXBColors.white[50]} alarmStatus={''} />
                            <Typography variant="body1" style={{ fontSize: 14, fontWeight: 400, fontStyle: 'normal', color: PXBColors.black[500] }}>{userDetail.lastSeen}</Typography>
                        </ListItem>

                        {(!isLoggedInUser) ? <div style={{ padding: '10px 16px 10px 16px', marginBottom: 12 }}>
                            <Select
                                disabled
                                variant='standard'
                                style={{ width: 200, backgroundColor: PXBColors.white[200], fontSize: 16 }}
                                labelId="role-select-label"
                                id="role-select-label"
                                value={props.userRole}
                                input={<BootstrapInput />
                                }
                            >
                                {userRoles.map((option) => (
                                    <MenuItem style={{
                                        display: 'flex',
                                        lineHeight: 1.5,
                                        fontWeight: 400,
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                        whiteSpace: 'nowrap',
                                        minHeight: 48,
                                        width: 'auto',
                                        textAlign: 'center',
                                        fontSize: md ? 16 : 10, color: COLOR_GRAY
                                    }}
                                        key={option.key} value={option.value}>
                                        {option.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div> : <Typography style={{ paddingLeft: 16, fontWeight: 400, fontSize: 14 }}>{selectedUserRole.length ? selectedUserRole[0].description : t('MANAGE_USERS.NOT_APPLICABLE')}</Typography>}
                        <div style={{ padding: '8px 16px 8px 16px' }}>
                            <Typography data-testid="companyTitle" style={{ fontWeight: 400, fontSize: 14 }}>{t('USER_INVITATION_MANAGEMENT.COMPANY')}</Typography>
                            <TooltipItem
                                title={userDetail.company_name}
                                isTopPlacement={true}
                                lenghtLimit={30}
                                component={
                                    <Typography data-testid="companyName" variant='subtitle1' style={{ fontWeight: 600, fontSize: 16 }}>{getControlledText(userDetail.company_name, 24)}</Typography>
                                }>
                            </TooltipItem>

                            <TooltipItem
                                title={userDetail.company_address}
                                isTopPlacement={true}
                                lenghtLimit={30}
                                component={
                                    <Typography data-testid="companyAddress" variant='subtitle1' style={{ fontWeight: 600, fontSize: 16 }}>{getControlledText(userDetail.company_address, 24)}</Typography>
                                }>
                            </TooltipItem>

                            <TooltipItem
                                title={userDetail.company_postalcode + " " + userDetail.company_city}
                                isTopPlacement={true}
                                lenghtLimit={30}
                                component={
                                    <Typography data-testid="companyCity" style={{ fontWeight: 600, fontSize: 16 }}>{getControlledText(userDetail.company_postalcode + " " + userDetail.company_city, 24)}</Typography>
                                }>
                            </TooltipItem>
                            <Typography data-testid="companyCountry" style={{ fontWeight: 600, fontSize: 16 }}>{userDetail.company_country}</Typography>
                            <Typography data-testid="companyPhoneNumber" style={{ fontWeight: 600, paddingBottom: 20, fontSize: 16 }}>{(userDetail.company_countrycode && userDetail.company_phoneNumner) ? ('+' + userDetail.company_countrycode + " " + userDetail.company_phoneNumner) : '--'}</Typography>
                        </div>
                        <Link data-testid="viewAllAccessRightsLink" onClick={handleOpendialog} style={{ paddingLeft: 16, fontWeight: 500 }}>{t('USER_INVITATION_MANAGEMENT.VIEW_ALL_ACCESS_RIGHTS')}</Link>
                    </List>
                    <Divider style={{ paddingTop: 20 }} />
                    <Button startIcon={<RemoveCircleOutlineOutlinedIcon />} style={{ width: '80%', alignItems: 'center', textTransform: 'capitalize', fontWeight: 600, fontStyle: 'normal', fontSize: '14px', display: 'flex', top: '3%', marginLeft: 35, border: '1px solid', borderColor: PXBColors.red[500], opacity: 1, color: PXBColors.red[500] }}
                        id="quitOrganization"
                        data-testid="quitOrganizationButton"
                        variant="outlined"
                        color='error'
                        onClick={(): void => leaveOrganization()}
                    >
                        {isLoggedInUser ? t('USER_INVITATION_MANAGEMENT.LEAVE_ORGANIZATION') : t('USER_INVITATION_MANAGEMENT.REMOVE_USER')}
                    </Button>
                </Card >}
            <Dialog id="invite-user-dialog" data-testid="invite-user-dialog"
                maxWidth="md" open={openAdvanceDialog}
                sx={{ maxHeight: 900, overflow: 'hidden' }}
            >
                <ViewAccessDetailsDialog
                    isLoggedInUser={isLoggedInUser}
                    fullname={`${userDetail.firstname} ${userDetail.lastname}`}
                    firstName={`${userDetail.firstname}`}
                    userAccessDetailData={userAccessDetailData ? userAccessDetailData : {
                        inviteByUserEntityAccessDetail: [],
                        inviteUserEntityAccessDetail: []
                    }}
                    onCancelAdvance={onCancelAdvanceInvite}
                    isAdvanceTreeDataAvailable={isUserAdvanceInviteCallStatus}
                ></ViewAccessDetailsDialog>
            </Dialog>
            {openRemoveDialog && <Portal>
                    <Dialog open={true} style={{ position: 'absolute' }}>

                        <DialogTitle data-testid='addslaformId' sx={{ m: 0, p: 2.5 }} style={{ paddingLeft: 24, paddingTop: 24, paddingBottom: 24, height: 59 }} id="addsla-dialog-title">
                          {getControlledText(successMessage, 22)}
                        </DialogTitle>
                        <DialogContent style={{ height: 50,marginTop: 10}} data-testid='invitationText'>
                            {t('USER_INVITATION_MANAGEMENT.INVITATION_TEXT')}
                        </DialogContent>

                        <DialogActions style={{ paddingLeft: 10, paddingRight: 10 ,paddingTop: 10 ,display: 'flex', flexDirection: 'column',alignItems: 'flex-end'}}>
                            <Button style={{ textTransform: 'none', color: PXBColors.red[500],marginRight: 10,paddingBottom: 8,border: 'none'}} id="removeAccess" data-testid="removeAccess" variant="outlined" color="primary" onClick={(): void => removeAccess()}>
                               {isLoggedInUser ? t('USER_INVITATION_MANAGEMENT.LEAVE_ORGANIZATION') : t('USER_INVITATION_MANAGEMENT.REMOVE_USER')}
                            </Button>
                            <Button style={{ textTransform: 'none', color: PXBColors.blue[500],border: 'none' ,marginRight: 10,paddingTop: 8,width: 77 }} id="btnCancel" data-testid="btnCancel" variant="outlined" color="primary" onClick={(): void => setOpenRemoveDialog(false)}>
                                {t('ACTIONS.CANCEL')}
                            </Button>
                        </DialogActions>

                    </Dialog>
                </Portal>}
        </>
    );
};
