import { AppThunk } from '../actions';
import * as ApiRequests from '../../api/requests';
import { UsersActions } from '../actions/users-actions';
import { UserInviteEntityModel } from '../../types';
import { InviteEntities, RequestInviteEntities } from '../../types/manage-users';
import { AuthHelper } from '../../types/auth-helper-types';

export const UsersThunks = {
    getOrgUserList: (id: string): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UsersActions.userListStarted());

        const payload = await ApiRequests.requestUserList(
            {
                id: id,
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(UsersActions.userListSucceeded(payload.response.data));
            return;
        }

        dispatch(UsersActions.userListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },
    getEntityUserData: (userId: string, authHelper: AuthHelper, entityId?: string): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(UsersActions.getEntityUserDetailStarted());
        const payload = await ApiRequests.getUserSettings({ userId, entityId }, { authHelper });

        if (payload.ok) {
            dispatch(
                UsersActions.getEntityUserDetailSucceeded(payload.response.data)
            );
        } else {
            dispatch(UsersActions.getEntityUserDetailFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },

    inviteUser: (emailId: string, inviteAsColleague: boolean, inviteEntities: Array<UserInviteEntityModel>, userRoleType?: number): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UsersActions.inviteUserStarted());

        const payload = await ApiRequests.inviteUser(
            {
                emailId: emailId,
                inviteAsColleague: inviteAsColleague,
                inviteEntities: inviteEntities,
                userRoleType: userRoleType
            },
            { authHelper: undefined }
        );
        if (payload.ok) {
            dispatch(UsersActions.inviteUserSucceeded(payload.response.data));
            return;
        }
        else {
            dispatch(UsersActions.inviteUserFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },

    userInvitationHistory: (inviteStatusType: number[]): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UsersActions.userInviteHistoryStarted());

        const payload = await ApiRequests.getUserInvitationHistory(
            { inviteStatusType: inviteStatusType },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(UsersActions.userInviteHistorySucceeded(payload.response.data));
            return;
        }

        dispatch(UsersActions.userInviteHistoryFailed(
            `${ApiRequests.extractResponseNotOkCode(payload.response)}`
        ));
    },

    adminApprovalHistory: (): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UsersActions.adminApprovalInviteHistoryStarted());

        const payload = await ApiRequests.getAdminApprovalHistory(
            {},
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(UsersActions.adminApprovalInviteHistorySucceeded(payload.response.data));
            return;
        }

        dispatch(UsersActions.adminApprovalInviteHistoryFailed(
            `${ApiRequests.extractResponseNotOkCode(payload.response)}`
        ));
    },

    updateUserInvitation: (invitationStatus: number, inviteEntities: Array<InviteEntities>, addToast: Function,): AppThunk => async (dispatch): Promise<void> => {

        dispatch(UsersActions.updateUserInvitationStarted());

        const payload = await ApiRequests.updateUserInvitation(
            {
                invitationStatus: invitationStatus,
                inviteEntities: inviteEntities
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(UsersActions.updateUserInvitationSucceeded(payload.response.data));
            return;
        }
        dispatch(UsersActions.updateUserInvitationFailed(`${ApiRequests.extractResponseNotOkMessage(payload.response)}`));
    },

    updateInvitationRequest: (invitationStatus: string, inviteEntities: Array<RequestInviteEntities>, addToast: Function,): AppThunk => async (dispatch): Promise<void> => {

        dispatch(UsersActions.updateInvitationRequestStarted());

        const payload = await ApiRequests.updateInvitationRequest(
            {
                status: invitationStatus,
                inviteApproveEntity: inviteEntities
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(UsersActions.updateInvitationRequestSucceeded(payload.response.data));
            return;
        }
        dispatch(UsersActions.updateInvitationRequestFailed(`${ApiRequests.extractResponseNotOkMessage(payload.response)}`));
    },

    userSendInvitationAccessRequest: (entityId: string, addToast: Function): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UsersActions.userInviteAccessRequestStarted());

        const payload = await ApiRequests.sendInvitationAccessRequest(
            { entityId: entityId },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(UsersActions.userInviteAccessRequestSucceeded());
            addToast((payload.response.message));
            return;
        }

        addToast(`${ApiRequests.extractResponseNotOkMessage(payload.response)}`);
        dispatch(UsersActions.userInviteAccessRequestFailed(
            `${ApiRequests.extractResponseNotOkCode(payload.response)}`
        ));
    },


    getUserAdvanceAccessDetail: (emailId: string, organizationList: string[]): AppThunk => async (dispatch): Promise<void> => {

        dispatch(UsersActions.userAdvanceAccessStarted());
        const payload = await ApiRequests.userAdvanceAccessDetail(
            {
                emailId: emailId,
                organizationList: organizationList,
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(UsersActions.userAdvanceAccessSucceeded(payload.response.data));
            return;
        }

        dispatch(UsersActions.userAdvanceAccessFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    removeAccess : (
        userId: string,
        entityId: string,
        entityType: number): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UsersActions.removeAccessStarted());
        const payload = await ApiRequests.removeAccess(
            {
                userId: userId,
                entityId: entityId,
                entityType: entityType

            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(UsersActions.removeAccessSucceeded(payload.response.data));
            return;
        }

        dispatch(UsersActions.removeAccessFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    }

};
