import { RegistrationActions } from '../actions/registration-actions';
import { AppThunk } from '../actions';
import * as ApiRequests from '../../api/requests';
import { HttpRequest } from '../../api/http-request';
import { AuthHelper } from '../../types';
import { INVITE_USER_TYPE } from '../../app/enum/ENUM';

export const RegistrationThunks = {
    loadEULA: (language: string): AppThunk => async (dispatch): Promise<void> => {
        dispatch(RegistrationActions.loadEulaStarted(language));

        // TODO: No decision has been made about where the EULA content will come from.
        //       For now we'll just pull content from the public directory of the React web app.
        const url = '/EULA.txt';

        const payload = await HttpRequest.get<string>({
            resource: url,
            prependApiUrl: process.env.NODE_ENV === 'test', // In acceptance tests this needs to come from the API server (there is no other web server to provide the static content in tests)
            responseType: 'text',
            headers: {
                language,
            },
        });

        if (payload.status === 200) {
            dispatch(RegistrationActions.loadEulaSucceeded(payload.response));

            return;
        }

        dispatch(RegistrationActions.loadEulaFailed());
    },

    validateEmailRegistrationRequest: (email: string): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(RegistrationActions.validateEmailRegistrationStarted());

        const payload = await ApiRequests.validateEmailRegistrationRequest(
            {
                email
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(RegistrationActions.validateEmailRegistrationSucceeded(
                payload.response.data.userType,
                payload.response.message,
            ));
            return;
        }

        dispatch(
            RegistrationActions.validateEmailRegistrationFailed(
                `${ApiRequests.extractResponseNotOkCode(payload.response)}`
            )
        );
    },

    countryCodeRequest: (): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(RegistrationActions.countryListStarted());

        const payload = await ApiRequests.getCountryListRegistrationRequest(
            {},
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(RegistrationActions.countryListSucceeded(
                payload.response.data,
            ));
            return;
        }

        dispatch(
            RegistrationActions.countryListFailed(
                `${ApiRequests.extractResponseNotOkCode(payload.response)}`
            )
        );
    },

    validateUserRegistrationRequest: (validationcode: string): AppThunk => async (dispatch): Promise<void> => {
        dispatch(RegistrationActions.validateUserRegistrationStarted());

        const payload = await ApiRequests.validateUserRegistrationRequest(
            { validationcode },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(
                RegistrationActions.validateUserRegistrationSucceeded(
                    payload.response.data.fname,
                    payload.response.data.lname,
                    payload.response.data.phone
                )
            );

            return;
        }

        dispatch(
            RegistrationActions.validateUserRegistrationFailed(
                `${ApiRequests.extractResponseNotOkCode(payload.response)}`
            )
        );
    },


    sendVerificatinCodeUserRegistration: (
        email: string,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(RegistrationActions.verificationCodeStarted());

        const payload = await ApiRequests.requestUserRegistrationVerificationCode(
            { user: email },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(RegistrationActions.verificationCodeSucceeded(
                payload.response.data.userType,
            ));
            return;
        }

        dispatch(RegistrationActions.verificationCodeFailed(
            `${ApiRequests.extractResponseNotOkCode(payload.response)}`
        ));
    },



    verifyCodeUserRegistration: (
        email: string,
        verificationcode: string,
        password: string,
        userType: string,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(RegistrationActions.verifyCodeStarted());
        const payload = await ApiRequests.requestUserRegistrationVerifyCode(
            { emailId: email, verificationcode: verificationcode, password: password, userType: userType },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(RegistrationActions.verifyCodeSucceeded(payload.response.data.verificationCode));
            return;
        }

        dispatch(RegistrationActions.verifyCodeFailed(
            `${ApiRequests.extractResponseNotOkCode(payload.response)}`
        ));
    },

    getUserInvitationDetails: (
        invitationId: string
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(RegistrationActions.invitationDetailsStarted());

        const payload = await ApiRequests.getInvitationDetails(
            { invitationId: invitationId },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(RegistrationActions.invitationDetailsSucceeded(payload.response.data));
            return;
        }

        dispatch(RegistrationActions.invitationDetailsFailed(
            `${ApiRequests.extractResponseNotOkCode(payload.response)}`
        ));
    },

    //TODO- Unused API- not used (we need to remove )
    upsertUserAccessProfile: (
        emailId: string
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(RegistrationActions.upsertUserAccessProfileStarted());

        const payload = await ApiRequests.upsertUserAccessProfile(
            { emailId: emailId },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(RegistrationActions.upsertUserAccessProfileSucceeded());
            return;
        }

        dispatch(RegistrationActions.upsertUserAccessProfileFailed(
            `${ApiRequests.extractResponseNotOkCode(payload.response)}`
        ));
    },

    completeRegistration: (
        userData: {
            firstName: string;
            lastName: string;
            phoneNumber: string;
            email: string;
            password: string;
            countryCode: string;
            company_name: string;
            company_country: string;
            company_countrycode: string;
            company_phonenumner: string;
            company_address: string;
            company_postalcode: string;
            company_city: string;
            language: number;
        },
        invitedUserType: number,
        userType: number
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(RegistrationActions.registrationStarted());
        const payload = (invitedUserType === INVITE_USER_TYPE.TYPE_NEW_USER || invitedUserType === INVITE_USER_TYPE.TYPE_NEW_USER_INCOMPLETE_REGISTER || userType === INVITE_USER_TYPE.TYPE_EATON_USER || userType === INVITE_USER_TYPE.TYPE_NEW_USER) ?
            await ApiRequests.registerSelfUser(
                {
                    email_id: userData.email,
                    firstname: userData.firstName,
                    lastname: userData.lastName,
                    countrycode: userData.countryCode,
                    phone: userData.phoneNumber,
                    company_name: userData.company_name,
                    company_country: userData.company_country,
                    company_countrycode: userData.company_countrycode,
                    company_phonenumner: userData.company_phonenumner,
                    company_address: userData.company_address,
                    company_postalcode: userData.company_postalcode,
                    company_city: userData.company_city,
                    language: userData.language,
                },
                { authHelper: undefined }
            )
            : await ApiRequests.registerUser(
                {
                    emailId: userData.email,
                    company_name: userData.company_name,
                    company_country: userData.company_country,
                    company_countrycode: userData.company_countrycode,
                    company_phoneNumner: userData.company_phonenumner,
                    company_address: userData.company_address,
                    company_postalcode: userData.company_postalcode,
                    company_city: userData.company_city,
                    language: userData.language,
                },
                { authHelper: undefined }
            );


        if (payload.ok) {
            dispatch(
                RegistrationActions.registrationSucceeded()
            );

            return;
        }

        const erroCode = ApiRequests.extractResponseNotOkCode(payload.response);
        dispatch(RegistrationActions.registrationFailed(erroCode.toString()));
    },

    inviteUserRegistration: (
        email: string,
        roleLocationList: Array<{ roleId: string; siteIds: string[] }>,
        addToast: Function
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(RegistrationActions.inviteUserStarted());

        const payload = await ApiRequests.requestUserRegistration(
            {
                user: email,
                role: roleLocationList,
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(RegistrationActions.inviteUserSucceeded());

            return;
        }
        const errorCode = ApiRequests.extractResponseNotOkCode(payload.response);
        const errorMessage = ApiRequests.extractErrorCodeWithMessage(payload.response);
        // **  Error code coming from server side :"User already registered for given organization, failed to send invitation"
        if (errorCode !== 3007 && errorCode !== 9007)
            addToast(ApiRequests.extractResponseNotOkMessage(payload.response), 'error');
        dispatch(RegistrationActions.inviteUserFailed(errorMessage));
    },
};
