// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { CardContent, FormControl, InputLabel, TextField } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Typography, Divider, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CountryList } from '../../state/registration-state';
import { ALPHABETICAL, FIRST_NAME_MAX_LENGTH, LAST_NAME_MAX_LENGTH, PHONE_MAX_LENGTH, PHONE_MIN_LENGTH, PHONE_NUMBER } from '../../constants';
import { validPhoneNumberLength } from '../../lib/form-validator';

type Props = {
    first?: string;
    last?: string;
    phone?: string;
    countryCode: string;
    countryList: CountryList;
    onFirstChanged?: Function;
    onLastChanged?: Function;
    onCountryCodeChanged?: Function;
    onPhoneChanged?: Function;
    changeNextLabel?: Function;
    changeNextEnabled?: Function;
};

const fieldsComplete = (fields: string[]): boolean => {
    for (let i = 0; i < fields.length; i++) {
        if (fields[i].length < 1) {
            return false;
        }
    }
    return true;
};


export const AccountDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const {
        first,
        last,
        phone,
        countryCode,
        countryList,
        onFirstChanged,
        onLastChanged,
        onCountryCodeChanged,
        onPhoneChanged,
        changeNextLabel,
        changeNextEnabled,
    } = props;
    const [firstName, setFirstName] = useState(first || '');
    const [lastName, setLastName] = useState(last || '');
    const [phoneNumber, setPhoneNumber] = useState(phone || '');
    const [country, setCountryCode] = useState(countryCode || '');

    if (changeNextLabel) {
        changeNextLabel(t('ACTIONS.NEXT'));
    }
    if (changeNextEnabled) {
        changeNextEnabled(fieldsComplete([firstName, lastName, phoneNumber, country]) && validPhoneNumberLength(phoneNumber));
    }

    const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ALPHABETICAL.test(e.target.value)) {
            setFirstName(e.target.value as string);
            if (onFirstChanged) onFirstChanged(e.target.value);
        }
    };

    const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ALPHABETICAL.test(e.target.value)) {
            setLastName(e.target.value as string);
            if (onLastChanged) onLastChanged(e.target.value);
        }
    };

    const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setPhoneNumber(e.target.value);
            if (onPhoneChanged) onPhoneChanged(e.target.value);
        }
    };

    const onChangeCountryCode = (event: SelectChangeEvent) => {
        setCountryCode(event.target.value as string);
        if (onCountryCodeChanged) onCountryCodeChanged(event.target.value);
    };

    useEffect(() => {
        if (countryList !== undefined && country === '' && countryList.length > 0) {
            setCountryCode(countryList[0].code);
            if (onCountryCodeChanged) onCountryCodeChanged(countryList[0].code);
        }
    }, [countryList, country, onCountryCodeChanged]);

    return (
        <>
            <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }}>
                <Typography data-testid='header-1' variant="body1">{t('REGISTRATION.STEPS_MESSAGE.CREATE_ACCOUNT_USER_DETAILS')}</Typography>

                <Divider style={{ marginTop: 24 }} />

                <TextField
                    style={{ marginTop: 24 }}
                    variant={'filled'}
                    label={t('FORMS.FIRST_NAME')}
                    inputProps={{ 'data-testid': 'first-name', maxLength: FIRST_NAME_MAX_LENGTH }}
                    value={firstName}
                    required
                    fullWidth
                    onChange={onChangeFirstName}
                />
                <TextField
                    style={{ marginTop: 24 }}
                    variant={'filled'}
                    label={t('FORMS.LAST_NAME')}
                    inputProps={{ 'data-testid': 'last-name', maxLength: LAST_NAME_MAX_LENGTH }}
                    value={lastName}
                    required
                    fullWidth
                    onChange={onChangeLastName}
                />

                <FormControl variant={'filled'} style={{ marginTop: 24, marginRight: '2%' }}>
                    <InputLabel data-testid='countryCodeRequiredId' id="country-name-label">{'*'}</InputLabel>
                    <Select
                        style={{ width: 91.5, textAlign: 'center' }}
                        variant={'filled'}
                        labelId="countrycode-label"
                        inputProps={{ 'data-testid': 'country-code', maxLength: 3 }}
                        id="countrycode-testid"
                        defaultValue={country}
                        value={country}
                        onChange={onChangeCountryCode}
                    >
                        {countryList?.map((e, code) => {
                            return (
                                <MenuItem id="country-code-list" key={code} value={e.code}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'left', fontSize: 14,
                                        lineHeight: 1.5, fontWeight: 400,
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                        whiteSpace: 'nowrap',
                                        minHeight: 48,
                                        paddingTop: 6,
                                        paddingBottom: 6,
                                        width: 'auto',
                                        paddingLeft: 16,
                                        paddingRight: 16
                                    }}>
                                    {'+' + e.code}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>


                <TextField
                    style={{ marginTop: 24, width: '76%' }}
                    variant={'filled'}
                    label={t('FORMS.PHONE_NUMBER')}
                    inputProps={{ 'data-testid': 'phone-number', minLength: PHONE_MIN_LENGTH, maxLength: PHONE_MAX_LENGTH }}
                    value={phoneNumber}
                    required
                    fullWidth
                    onChange={(e): void => onChangePhoneNumber(e)}
                />
            </CardContent>
        </>
    );
};
