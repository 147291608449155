// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Actions, Thunks } from '../../actions';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import EditIcon from '@mui/icons-material/Edit';
import { UserDetailsListItem } from './UserDetailListItem';
import { UserDetailsData } from '../../types/user-details-data';
import { IconButton, ListItemButton, ListItemText } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { alarmsTypeList, appTheme, dateFormat, reportTypePreferenceList, languageList, productUpdatesTypeList, statusUpdatesTypeList, summaryReportTypeList, timeZone, units } from './AccountPreferencesData';
import { UserSelectionItem } from './UserSelectionItem';
import {
    Paper,
    List,
    ListItem,
    Divider,
    Typography,
    ListSubheader,
    Switch,
    Collapse,
} from '@mui/material';
import Button from '@mui/material/Button';
import * as PXBColors from '@brightlayer-ui/colors';
import LockIcon from '@material-ui/icons/Lock';
import moment from 'moment';
import { EATON_USER_DOMAIN, SELECTED_LANGUAGE } from '../../constants';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRoleType, useAuthHelper, useAuthState } from '../AuthContextProvider';
import { Selectors } from '../../selectors';
import { ConfirmationPopUp } from '../ConfirmationPopUp/ConfirmationPopUp';
import { useTranslation } from 'react-i18next';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { UserSettingsActions } from '../../actions/actions/user-settings-actions';
import { SessionStorage } from '../AuthContextProvider/session-storage';
import { getControlledText } from '../../app/enum/GenericFormat';
import { DeleteUser } from './DeleteUser';
import { USER_ROLE_TYPE } from '../../app/enum/ENUM';
import { TooltipItem } from '../TooltipItem/TooltipItem';

export const defaultUserDetail: UserDetailsData = {
    firstname: '',
    lastname: '',
    display_name: '',
    phone: '',
    email_id: '',
    job_title: null,
    department: null,
    countryCode: '',
    user_phone_number: '',
    company_name: '',
    company_phoneNumner: '',
    company_address: '',
    company_country: '',
    company_countrycode: '',
    company_complete_phone_number: '',
    company_city: '',
    company_postalcode: '',
    accountCreated: '',
    language: 0,
    timeZone: 1,
    dateFormat: 1,
    unitSystem: 1,
    appTheme: 1,
    isAutologoutEnabled: '',
    reportTypePreference: 1,
    reportFrequencyNotificationType: 1,
    productUpdateNotificationType: 1,
    alarmNotificationType: 1,
    statusUpdateNotificationType: 1,
    lastSeen: "3 days ago",
    userStatus: 0,
    roleTypeId: 0,
    platformName: ''
}

export const UserDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<{
    userId: string;
    emailId: string;
    openPassword: Function;
    openSMSModal: Function;
    openDisclaimer: Function;
    hasDirty: Function;
    confirmDiscard: boolean;
}>>> = (props) => {
    const { t } = useLanguageLocale();
    const { i18n } = useTranslation();
    const authState = useAuthState();
    // const classes = useStyles();
    const userData = useSelector(Selectors.User.user);
    const [userDetail, setUserDetail] = useState<UserDetailsData>(defaultUserDetail);
    const company_complete_phone_number = `${"+"}${userDetail.company_countrycode} ${userDetail.company_phoneNumner}`;
    const companyPhoneNumber = (userDetail.company_countrycode || userDetail.company_phoneNumner) !== null ? company_complete_phone_number : '(if available)';
    const [edit, setEdit] = useState(false);// eslint-disable-line @typescript-eslint/no-unused-vars
    const [openPreferences, setOpenPreferences] = React.useState(true);
    const [openNotification, setOpenNotification] = React.useState(true);
    const isEatonAdmin = userDetail.email_id.toLowerCase().endsWith(EATON_USER_DOMAIN);
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const authHelper = useAuthHelper();
    const usertype = getUserRoleType(authState);
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [isAutoLogout, setAutoLogout] = React.useState(false);
    const [selectedLanguage, setSelectedLanguage] = React.useState('');
    const history = useHistory();
    const [openDeleteUserDialog, setOpenDeleteUserDialog] = React.useState(false);

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    const setLang = (value: number) => {
        // eslint-disable-next-line
        let userLang = languageList.filter((lang) => {
            if (lang.key === value)
                return lang.code;
        });
        if (userLang.length > 0 && userLang[0].code)
            setSelectedLanguage(userLang[0].code);
    };

    useEffect(() => {
        dispatch(UserSettingsActions.deleteUserUnmounted());
        dispatch(Thunks.UserSettings.getUserData(props.userId, authHelper));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Handle primary selection
    const handleSelection = (value: { key: string }, type: string) => {
        if (value.key && type) {
            dispatch(Thunks.UserSettings.updateUserNotification(
                authHelper,
                addToast,
                type === 'firstname' ? value.key : undefined,
                type === 'lastname' ? value.key : undefined,
                type === 'countryCode' ? value.key : undefined,
                type === 'company_name' ? value.key : undefined,
                type === 'firstname' ? value.key : undefined,
                type === 'company_phoneNumner' ? value.key : undefined,
                type === 'company_countrycode' ? value.key : undefined,
                type === 'company_address' ? value.key : undefined,
                type === 'company_city' ? value.key : undefined,
                type === 'company_postalcode' ? value.key : undefined,
                type === 'company_country' ? value.key : undefined,
                type === 'language' ? value.key : undefined,
                type === 'timeZone' ? value.key : undefined,
                type === 'dateFormat' ? value.key : undefined,
                type === 'unitSystem' ? value.key : undefined,
                type === 'appTheme' ? value.key : undefined,
                type === 'isAutologoutEnabled' ? value.key : undefined,
                type === 'reportTypePreference' ? value.key : undefined,
                type === 'reportFrequencyNotificationType' ? value.key : undefined,
                type === 'productUpdateNotificationType' ? value.key : undefined,
                type === 'alarmNotificationType' ? value.key : undefined,
                type === 'statusUpdateNotificationType' ? value.key : undefined,
            ));
            if (type === 'language' && value.key) {
                setLang(parseInt(value.key));
            }
        }
        else {
            addToast("Please fill all the details properly before submission.");
        }
    };

    const disableAutomaticLogout = (value: boolean): void => {
        setAutoLogout(!isAutoLogout);
        handleSelection({ key: isAutoLogout ? "TRUE" : "FALSE" }, 'isAutologoutEnabled');
        setShowDisclaimer(false);
    };

    const closePopUp = (): void => {
        setShowDisclaimer(false);
    };

    const goToModifyUserDetails = (): void => {
        pushAppRoute(history,
            { type: 'ModifyUserDetails' },
            { backRoute: 'Settings', changeId: '0' });
    };

    const handleClickPreferences = () => {
        setOpenPreferences(!openPreferences);
    };
    const handleClickNotification = () => {
        setOpenNotification(!openNotification);
    };

    useEffect(() => {
        if (props.confirmDiscard) setEdit(false);
    }, [props.confirmDiscard]);

    useEffect(() => {
        if (!userData.getUserDetailLoading && userData.getUserDetailSuccess) {
            setUserDetail(userData.userDetail);
            setLang(userData.userDetail.language);
            SessionStorage.saveLanguage(selectedLanguage);
            changeLanguage(selectedLanguage);
        } else if (!userData.getUserDetailLoading && userData.getUserDetailFail) {
            console.log('user detail loading failed');
        }
    }, [userData.getUserDetailLoading, userData.getUserDetailSuccess, userData.getUserDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!userData.updateUserDetailLoading && userData.updateUserDetailSuccess) {
            addToast(t('TOAST_MSG.TOAST_SUCCESS_UPDATE_USER_DETAILS'), 'success');
            dispatch(Thunks.UserSettings.getUserData(props.userId, authHelper));
            setUserDetail(userData.userDetail);
            changeLanguage(selectedLanguage);
            SessionStorage.saveLanguage(selectedLanguage);
            dispatch(UserSettingsActions.updateUserDetailUnmounted());
        }
        else if (!userData.updateUserDetailLoading && userData.updateUserDetailFail) {
            addToast(t('TOAST_MSG.TOAST_ERROR_UPDATE_USER_DETAILS'), 'error');
        }
    }, [userData.updateUserDetailLoading, userData.updateUserDetailSuccess, userData.updateUserDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Paper style={{ marginTop: 20 }} data-testid="user-details-container">
                <List style={{ paddingBottom: '0' }}>
                    <ListItem
                        data-testid="list-user-name">

                        <ListItemText>
                            <Typography data-testid="user-name" style={{ paddingLeft: 16, fontWeight: 600, fontSize: 24, lineHeight: 2, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                                <TooltipItem
                                    title={userDetail.firstname ? userDetail.firstname + ' ' + userDetail.lastname : ''}
                                    isTopPlacement={false}
                                    lenghtLimit={35}
                                    component={
                                        <Typography variant="body2" style={{ fontSize: 24 }} id="header-location" data-testid="header-location" >{getControlledText(userDetail.firstname, 20) + ' ' + getControlledText(userDetail.lastname, 20)}</Typography>
                                    }>
                                </TooltipItem>
                            </Typography>
                            <Typography style={{ paddingLeft: 16, fontWeight: 400, fontSize: 14, lineHeight: 1, color: PXBColors.gray[500] }}>{t('USER_SETTINGS.NAME')}</Typography>
                        </ListItemText>
                        <IconButton color={'inherit'} edge={'start'} size="large" onClick={(): void => goToModifyUserDetails()}>
                            <EditIcon />
                        </IconButton>
                    </ListItem>
                    <List style={{ display: 'flex', flexFlow: 'row wrap' }}>
                        <List style={{ alignItems: 'left', justifyContent: 'flex-start', minWidth: '40%' }}>
                            <ListItem data-testid='list-user-email' style={{ paddingLeft: 16 }}>
                                <UserDetailsListItem title={t('USER_SETTINGS.EMAIL')} value={userDetail.email_id} testid="user-email" />
                            </ListItem>
                            <ListItem data-testid='list-user-phone-number' style={{ paddingLeft: 16 }}>
                                <UserDetailsListItem title={t('USER_SETTINGS.PHONE_NUMBER')} value={`${"+"}${userDetail.countryCode} ${userDetail.phone}`} testid="user-phone-number" />
                            </ListItem>
                        </List>
                        <List style={{ alignItems: 'right', justifyContent: 'flex-end', minWidth: '40%' }}>
                            <ListItem data-testid='list-user-company-name' style={{}}>
                                <UserDetailsListItem title={t('USER_SETTINGS.COMPANY_NAME')} value={userDetail.company_name} testid="user-company-name"></UserDetailsListItem>
                            </ListItem>
                            <ListItem data-testid='list-user-company-address' style={{}}>
                                <UserDetailsListItem title={t('USER_SETTINGS.COMPANY_ADDRESS')} value={userDetail.company_address + ', ' + userDetail.company_city + ', ' + userDetail.company_country + " " + userDetail.company_postalcode} testid="user-company-address" />
                            </ListItem>
                            <ListItem data-testid='list-user-company-phone-number' style={{}}>
                                <UserDetailsListItem title={t('USER_SETTINGS.COMPANY_PHONE_NUMBER')} value={companyPhoneNumber} testid="user-company-phone-number" />
                            </ListItem>
                        </List>
                    </List>
                    <Divider />
                    <ListItem style={{ display: 'flex' }}>
                        <UserDetailsListItem title={t('USER_SETTINGS.PASSWORD')} value={'******'} testid="change-password" />
                        <Button style={{ alignItems: 'center', marginRight: 16, textTransform: 'capitalize', fontWeight: 600, fontStyle: 'normal', fontSize: '14px', display: 'flex' }}
                            id="btnChangePassword"
                            data-testid="button-change-password"
                            variant="outlined"
                            color="primary"
                            onClick={(): void => props.openPassword(true)}
                        >
                            {t('USER_SETTINGS.CHANGE_PASSWORD')}
                        </Button>
                    </ListItem>
                    <Divider />
                    <ListItem style={{ display: 'flex' }}>
                        <UserDetailsListItem data-testid='deleteAccount' title={t('USER_SETTINGS.ACCOUNT_CREATED_DATE')} value={getFormattedDate(userDetail.accountCreated)} testid="delete-account-date" />
                        <Button style={{ alignItems: 'center', marginRight: 16, textTransform: 'capitalize', fontWeight: 600, fontStyle: 'normal', fontSize: '14px', display: 'flex' }}
                            id="deleteAccount"
                            data-testid="button-delete-account"
                            variant="outlined"
                            color="error"
                            disabled={usertype === USER_ROLE_TYPE.PAAS_SERVICE_MANAGER || usertype === USER_ROLE_TYPE.SERVICE_MANAGER}
                            onClick={() => { setOpenDeleteUserDialog(true) }}
                        >
                            {t('USER_SETTINGS.DELETE_ACCOUNT')}
                        </Button>
                    </ListItem>
                </List>
            </Paper>
            <Paper style={{ marginTop: 20 }} data-testid="preference-container">
                <Divider />
                <List style={{ paddingBottom: '0' }}
                    subheader={
                        <>
                            <ListSubheader
                                color="primary"
                                component="div"
                                data-testid="user-preference-header"
                                id="user-preference-header"
                                style={{
                                    display: 'flex',
                                    fontWeight: 600,
                                    fontSize: 14,
                                    alignItems: 'center',
                                    overflow: 'auto',
                                    whiteSpace: 'nowrap',
                                    height: 48,
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    style={{

                                        fontStyle: 'normal',
                                        alignItems: 'center',
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: PXBColors.blue[500],
                                        width: '100%',
                                        paddingLeft: 16,
                                    }}
                                    id="preference-title"
                                    data-testid="preference-title"
                                >
                                    {t('USER_SETTINGS.PREFERENCES_HEADER')}
                                </Typography>
                                <ListItemButton
                                    onClick={handleClickPreferences}
                                    id="list-header-item-button"
                                >
                                    {openPreferences ? (
                                        <KeyboardArrowUp id="list-header-up-icon" />
                                    ) : (
                                        <KeyboardArrowDown id="list-header-down-icon" />
                                    )}
                                </ListItemButton>
                            </ListSubheader>
                            <Divider />
                        </>
                    }>
                    <Collapse in={openPreferences} timeout="auto" unmountOnExit>
                        <ListItem
                            data-testid="list-user-language">
                            <UserSelectionItem testid="user-language" title={t('USER_SETTINGS.LANGUAGE')} primaryList={languageList} primarySelectedItem={userDetail.language} handleSelectionPrimary={handleSelection} paramType={"language"} disableState = {false} />
                        </ListItem>
                        <Divider />
                        <ListItem data-testid='list-user-time-zone'>
                            <UserSelectionItem testid='user-time-zone' title={t('USER_SETTINGS.TIME_ZONE')} primaryList={timeZone} primarySelectedItem={userDetail.timeZone} handleSelectionPrimary={handleSelection} paramType={'timeZone'} disableState = {true} />
                        </ListItem>
                        <Divider />
                        <ListItem data-testid='list-user-date-format' >
                            <UserSelectionItem testid='user-date-format' title={t('USER_SETTINGS.DATE_FORMAT')} primaryList={dateFormat} primarySelectedItem={userDetail.dateFormat} handleSelectionPrimary={handleSelection} paramType={'dateFormat'} disableState = {true} />
                        </ListItem>
                        <Divider />
                        <ListItem data-testid='list-user-units' >
                            <UserSelectionItem testid='user-units' title={t('USER_SETTINGS.UNITS')} primaryList={units} primarySelectedItem={userDetail.unitSystem} handleSelectionPrimary={handleSelection} paramType={'unitSystem'} disableState = {true} />
                        </ListItem>
                        <Divider />
                        <ListItem data-testid='list-user-application-theme-color' >
                            <UserSelectionItem testid='user-application-theme-color' title={t('USER_SETTINGS.APPLICATION_THEME_COLOR')} primaryList={appTheme} primarySelectedItem={userDetail.appTheme} handleSelectionPrimary={handleSelection} paramType={'appTheme'} disableState = {true} />
                        </ListItem>
                        <Divider />
                        <ListItem data-testid='list-automatic-logout-toggle' style={{ display: 'flex' }}>
                            <UserDetailsListItem data-testid='automatic-logout-toggle' title={t('USER_SETTINGS.AUTOMATIC_LOGOUT')} value={(userDetail.isAutologoutEnabled === 'TRUE') ? 'Enabled (Recommended)' : 'Disabled'} testid="automatic-logout-toggle" />
                            <Switch style={{ marginRight: 16 }}
                                id="automaticLogOut"
                                data-testid="automatic-logout-toggle-btn"
                                checked={(userDetail.isAutologoutEnabled === 'TRUE') ? true : false}
                                onChange={(e: any): void => {
                                    if (!e.target.checked) setShowDisclaimer(true);
                                    else disableAutomaticLogout(e.target.checked);
                                }}
                                color="primary"
                            />
                        </ListItem>
                        <Divider />
                    </Collapse>
                </List>
            </Paper>
            { usertype !== USER_ROLE_TYPE.PAAS_SERVICE_MANAGER &&
            <Paper style={{ marginTop: 20 }} data-testid="notification-container">

                <Divider />
                <List style={{ paddingBottom: '0' }}
                    subheader={
                        <>
                            <ListSubheader
                                color="primary"
                                component="div"
                                data-testid="user-preference-header"
                                id="user-preference-header"
                                style={{
                                    display: 'flex',
                                    fontWeight: 600,
                                    fontSize: 14,
                                    alignItems: 'center',
                                    overflow: 'auto',
                                    whiteSpace: 'nowrap',
                                    height: 48,
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    style={{
                                        alignItems: 'center',
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: PXBColors.blue[500],
                                        width: '100%',
                                        paddingLeft: 16,
                                    }}
                                    id="notification-title"
                                    data-testid="notification-title"
                                >
                                    {t('USER_SETTINGS.NOTIFICATIONS_HEADER')}
                                </Typography>
                                <ListItemButton
                                    onClick={handleClickNotification}
                                    id="list-header-item-button"
                                >
                                    {openNotification ? (
                                        <KeyboardArrowUp id="list-header-up-icon" />
                                    ) : (
                                        <KeyboardArrowDown id="list-header-down-icon" />
                                    )}
                                </ListItemButton>
                            </ListSubheader>
                            <Divider />
                        </>
                    }>
                    <Collapse in={openNotification} timeout="auto" unmountOnExit>
                        <ListItem
                            data-testid="list-summary-id">
                            <UserSelectionItem testid="summary-id" title={t('USER_SETTINGS.HEALTH_REPORT')} isNotification={true} primaryList={summaryReportTypeList} primarySelectedItem={userDetail.reportFrequencyNotificationType} secondaryList={reportTypePreferenceList} secondarySelectedItem={userDetail.reportTypePreference} handleSelectionPrimary={handleSelection} handleSelectionSecondary={handleSelection} paramType={'reportFrequencyNotificationType'} paramType2={'reportTypePreference'} disableState = {false} />
                        </ListItem>
                        <Divider />
                        <ListItem data-testid='list-productUpdateId' >
                            <UserSelectionItem testid='productUpdateId' title={t('USER_SETTINGS.PRODUCT_UPDATES')} primaryList={productUpdatesTypeList} primarySelectedItem={userDetail.productUpdateNotificationType} handleSelectionPrimary={handleSelection} paramType={'productUpdateNotificationType'} disableState = {true}/>
                        </ListItem>
                        <Divider />
                        <ListItem data-testid='list-alarmId' >
                            <UserSelectionItem testid='alarmId' title={t('USER_SETTINGS.ALARMS')} primaryList={alarmsTypeList} primarySelectedItem={userDetail.alarmNotificationType} handleSelectionPrimary={handleSelection} paramType={'alarmNotificationType'} disableState = {false} />
                        </ListItem>
                        <Divider />
                        <ListItem data-testid='list-statusUpdatesId' >
                            <UserSelectionItem testid='statusUpdatesId' title={t('USER_SETTINGS.STATUS_UPDATES')} primaryList={statusUpdatesTypeList} primarySelectedItem={userDetail.statusUpdateNotificationType} handleSelectionPrimary={handleSelection} paramType={'statusUpdateNotificationType'} disableState = {true} />
                        </ListItem>
                        <Divider />
                    </Collapse>
                </List>
            </Paper>
            }
            {showDisclaimer && (
                <ConfirmationPopUp
                    icon={<LockIcon
                        style={{
                            width: 60,
                            height: 60,
                            color: PXBColors.gray[500],
                        }}
                    />}
                    title={t('USER_SETTINGS.AUTOMATIC_LOGOUT_TITLE')}
                    body={
                        <>
                            <Typography variant="subtitle2" style={{ marginBottom: 30 }}>
                                {t('USER_SETTINGS.AUTOMATIC_LOGOUT_SUBTITLE')}
                            </Typography>

                            <Typography variant="subtitle2" component="p" style={{ marginBottom: 40 }}>
                                <b>{t('USER_SETTINGS.WARNING')}</b>: {t('USER_SETTINGS.AUTOMATIC_LOGOUT_BODY')}
                            </Typography>
                        </>
                    }
                    okButtonText={t('ACTIONS.DISABLE')}
                    cancelButtonText={t('ACTIONS.CANCEL')}
                    show={showDisclaimer}
                    closeDialog={closePopUp}
                    handleOk={(): void => {
                        disableAutomaticLogout(false);
                    }}
                    width="xs"
                    okButtonShow={true}
                    cancelButtonsShow={true}
                ></ConfirmationPopUp>
            )}
            <DeleteUser
                openDialogFlag={openDeleteUserDialog} closeClick={() => setOpenDeleteUserDialog(false)} ></DeleteUser>
        </>
    );
};
export const getFormattedDate = (dateTime: string): string => {
    return moment(dateTime).format('DD MMM YYYY');
}
