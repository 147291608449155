// eslint-disable-next-line
import React, { useEffect } from 'react';
import {
    TableRow,
    IconButton,
    Typography,
    TableCell,
    Icon,
} from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ChevronRight, Star, StarBorder } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { DeviceAssetData } from '../../types/device';
import { useDispatch } from 'react-redux';
import { DeviceState, ENTITY_TYPE, getDeviceStatusState, getDeviceType } from '../../app/enum/ENUM';
import { OrganizationThunks } from '../../actions/thunks/organization-thunks';
import { SLADataList, SLAListItems } from '../../types/sla';
import { wrap } from 'module';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EngineeringIcon from '@mui/icons-material/Engineering';

type Props = {
    row: SLAListItems;
    selectedSLA: string;
    displayClick: Function;
    displaySLADetailsClick: Function;
};

export const AssetSLAListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const row: SLAListItems = props.row;
    const dispatch = useDispatch();

    const handleDisplay = () => {
        props.displayClick({ SLAData: row });
    };
    const handleClick = (): void => {
        dispatch(OrganizationThunks.markAsFav(
            row.systemId,
            ENTITY_TYPE.SYSTEM
        ));
    }

    const handleSlaDetailsOpen = () => {
        props.displaySLADetailsClick({ isOpen: true });
    }

    const deviceState: DeviceState = getDeviceStatusState(row.systemStatus);

    const formatDate = (timestamp: any) => {
        const date = new Date(timestamp)
        const sDate = date.getDate();
        const sYear = date.getFullYear();
        const month = date.getMonth();
        //** To get month in string **//
        date.setMonth(month) // starts with 0, so 0 is January
        const sMonth = date.toLocaleString('en-EN', { month: "long" }).slice(0, 3);
        return `${sDate} ${sMonth} ${sYear}`;
    }

    return (
        <TableRow
            id={`row${row.systemId}`}
            data-testid={`row${row.systemId}`}
            style={{ cursor: 'pointer', width: 'auto', backgroundColor: props.selectedSLA === row.sLANumber ? PXBColors.blue[50] : PXBColors.white[100] }}
            onClick={(): void => handleDisplay()}
        >
            <TableCell style={{ width: '0%', paddingLeft: 10 }}
                id="sla-list-item-favorite"
                data-testid="sla-list-item-favorite"
                padding="checkbox">
                {<StarBorder style={{ color: '#727E84' }} onClick={handleClick} />}
            </TableCell>
            <TableCell
                id="sla-list-sla-number"
                data-testid="sla-list-sla-number"
                align="left"
                style={{ width: 'auto' }}
                padding="checkbox"
            >
                {row.sLANumber}
            </TableCell>

            <TableCell align="left" style={{ width: 'auto' }}
                id="sla-list-cmpny-name"
                data-testid="sla-list-cmpny-name"
                padding="checkbox">
                {row.companyName}
            </TableCell>

            <TableCell align="left" style={{ width: 'auto' }}
                id="sla-list-customer-city"
                data-testid="sla-list-item-customer-city"
                padding="checkbox">
                {row.customerCity}
            </TableCell>
            <TableCell align="left" style={{ width: 'auto' }}
                id="sla-list-item-building-name"
                data-testid="sla-list-item-building-name"
                padding="checkbox">
                {`${row.projectName} > ${row.buildingName}`}
            </TableCell>

            <TableCell align="left" style={{ width: 'auto' }}
                id="sla-list-item-system-name"
                data-testid="sla-list-item-system-name"
                padding="checkbox">
                {`"${row.systemName}", ${getDeviceType(row.type)}`}
                {!row.luminariesNotPartOfSLA &&
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'left',
                        }}
                    >
                        <div style={{ minWidth: 20, paddingTop: '0px' }}>
                            <CheckCircleOutlineIcon style={{ fontSize: 'small' }} />
                        </div>

                        <Typography variant="body2" style={{ fontSize: 12 }} id="sla-system-luminaire" data-testid="sla-system-luminaire" >Luminaire Monitoring</Typography>
                    </div>
                }
            </TableCell>

            <TableCell align="left" style={{ width: 'auto', paddingLeft: 10 }}
                id="sla-list-item-status"
                data-testid="sla-list-item-status">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <div style={{ minWidth: 25 }}>
                        <img
                            src={deviceState.statusImage}
                            alt="logo"
                            style={{ display: 'flex', alignItems: 'right' }}
                            id="device-status-icon"
                            data-testid="device-status-icon"
                        /></div>
                    <Typography
                        id="device-status-button"
                        data-testid="device-status-button"

                        style={{ color: deviceState.statusColor, textTransform: 'none', marginLeft: 10, fontWeight: 600 }} variant="body2">
                        {t(deviceState.statusTitle)}
                    </Typography>
                </div>
            </TableCell>
            <TableCell align="left" style={{ width: 'auto' }}
                id="sla-list-item-expiry-date"
                data-testid="sla-list-item-expiry-date"
                padding="checkbox">
                {''}
            </TableCell>
            <TableCell align="left"
                id="sla-list-item-technician"
                data-testid="sla-list-item-technician"
                padding="checkbox">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <div style={{ minWidth: 25 }}>
                        <Icon
                            style={{ display: 'flex', alignItems: 'right' }}
                            id="device-status-icon"
                            data-testid="device-status-icon"><EngineeringIcon color='error' /></Icon></div>
                    <Typography
                        id="device-status-button"
                        data-testid="device-status-button"

                        style={{ color: '#CA3C3D', textTransform: 'none', marginLeft: 10, fontWeight: 600 }} variant="body2">
                        {'Unassigned'}
                    </Typography>
                </div>
            </TableCell>
            <TableCell style={{ width: '0%' }}
                id="sla-list-item-next"
                data-testid="sla-list-item-next" onClick={() => handleDisplay()}>
                <IconButton edge="end" id="list-item-next-icon" style={{ marginLeft: "auto" }} onClick={handleSlaDetailsOpen} >
                    <ChevronRight />
                </IconButton>
            </TableCell>
            <TableCell align="left" padding="checkbox" style={{ width: '0%', padding: 0 }}
                id="sla-list-item-empty"
                data-testid="sla-list-item-empty">
            </TableCell>
        </TableRow>
    );
};
