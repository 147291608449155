// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Add, CheckBox, CheckBoxOutlineBlank, Flag, ManageAccounts, RemoveCircle, SupervisorAccount } from '@mui/icons-material';
import { DialogContent, IconButton, DialogTitle, Typography, TextField, FormControl, Select, MenuItem, InputLabel, SelectChangeEvent, Divider, Dialog, Button, DialogActions, ListItem, ListItemAvatar, ListItemText, Snackbar, Autocomplete, Checkbox, useMediaQuery, useTheme, CircularProgress } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { ADD_ORGANIZATION_ID, ActionType, BUILDING_CHARACTER_LIMIT, EMPTY_STRING, ORGANIZATTION_NAME } from '../../constants';
import * as PXBColors from '@brightlayer-ui/colors';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import LanguageIcon from '@mui/icons-material/Language';
import { Selectors } from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { CountryList } from '../../state/registration-state';
import { Thunks } from '../../actions';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { UsermanagementThunks } from '../../actions/thunks/user-management-thunks';
import { useAuthHelper } from '../../components/AuthContextProvider';
import { usermanagementActions } from '../../actions/actions/usermanagement-actions';
import { ItemList } from '../../types/logbook-type';
import { ServicePartnerCustomerDataList } from '../../types/servicePartnerCustomerList';
import { TAB_SERVICE_MANAGERS_KEY, updateUserTabSession } from './ManageUser';
import Vector from '../../assets/images/Vector.svg';
import { platformDetail } from '../../types/platform';
import { defaultPlatformDetail } from '../../state/usermanagement-state';
import { useToasts } from '../../data/DataProviders/ToastProvider';

type Props = {
    closeClick: Function;
    openDialogFlag: boolean;
};

export const EditPlatformDialog: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const history = useHistory();
    const [platformName, setPlatformName] = useState('');
    const countryListLoading = useSelector(Selectors.Registration.countryListLoading);
    const countryListSuccess = useSelector(Selectors.Registration.countryListSuccess);
    const [countryList, setCountryList] = useState<CountryList>([]);
    const [countryListData, setCountryListData] = React.useState<CountryList>([]);
    const registration = useSelector(Selectors.Registration.registration);
    const [servicePartnerName, setServicePartnerName] = useState('');
    const [mainServiceManager, setMainServiceManager] = useState('');
    const [countryName, setCountryName] = useState('');
    const [saveButtonDisable, setSaveButtonDisable] = useState(true);
    const authHelper = useAuthHelper();
    const userManagement = useSelector(Selectors.Usermanagement.usermanagement);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [paasManagerData, setPaasManagerData] = React.useState<ItemList>([]);//with label
    const [serviceManagerEmailId, setServiceManagerEmailId] = useState<string>('');
    const [serviceManagerCustomerId, setServiceManagerCustomerId] = useState<string>('');
    const [inputLabel, setInputLabel] = useState<string>(t('USERS.SERVICE_MANAGER_LABEL'));
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const user = useSelector(Selectors.Usermanagement.usermanagement);
    const [platformDetail, setPlatformDetail] = useState<platformDetail>(defaultPlatformDetail);
    const [platformId, setPlatformId] = useState('');
    const addPaas: ItemList = [{ id: ADD_ORGANIZATION_ID, name: t('USERS.CREATE_NEW_CUSTOMER') }];

    useEffect(() => {
        setupServicePartner([]);
        dispatch(Thunks.Registration.countryCodeRequest());
        dispatch(Thunks.UserManagement.getServicePartnerCustomersList());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!countryListLoading && countryListSuccess) {
            setCountryList(registration.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1));
            setup(registration.countryList);
        }
    }, [countryListLoading, countryListSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    const setup = (countryList: CountryList): void => {
        const addOrganization: CountryList = [{ code: ADD_ORGANIZATION_ID, name: t('ASSETS_DETAILS.CREATE_NEW_CUSTOMER_BUTTON') }];
        const list = addOrganization.concat(countryList);
        setCountryListData(list);
    }

    const addNewOrganization = (): void => {
        pushAppRoute(history,
            { type: 'CreateOrganization' },
            { backRoute: 'Organizations' });
    }

    const handleAddPlatformResponse = (): void => {
        if (!userManagement.addPlatformLoading && userManagement.addPlatformFail) {
            if (userManagement.addPlatformErrorCode === '3048')
                setAlertErrorMessage(t('USERS.MANAGE_PLATFORM_ERROR_CODE_3048'));
            else if (userManagement.addPlatformErrorCode === '3049')
                setAlertErrorMessage(t('USERS.MANAGE_PLATFORM_ERROR_CODE_3049'));
            else if (userManagement.addPlatformErrorCode === '9001')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_ERROR_INVAILD_TOKEN'));
            else if (userManagement.addPlatformErrorCode === '3045')
                setAlertErrorMessage(t('USERS.MANAGE_PLATFORM_ERROR_CODE_3045'));
            else if (userManagement.addPlatformErrorCode === '3046')
                setAlertErrorMessage(t('USERS.MANAGE_PLATFORM_ERROR_CODE_3046'));
            else if (userManagement.addPlatformErrorCode === '3047')
                setAlertErrorMessage(t('USERS.MANAGE_PLATFORM_ERROR_CODE_3047'));
            else if (userManagement.addPlatformErrorCode === '900')
                setAlertErrorMessage(t('USERS.MANAGE_PLATFORM_ERROR_CODE_900'));
            else
                setAlertErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }

    };

    useEffect(() => {
        if (!userManagement.addPlatformLoading && userManagement.addPlatformSuccess) {
            dispatch(Thunks.UserManagement.getPlatformDetail(platformId))
            setAlertSuccessMessage(t('USERS.EDIT_PLATFORM_SUCCESS_200'));
            setDisplaySuccessMessage(true);
            onCancel();

        } else if (!userManagement.addPlatformLoading && userManagement.addPlatformFail) {
            handleAddPlatformResponse();
            setDisplayErrorMessage(true);
            closeSelectOrgDialog();

        }
        dispatch(usermanagementActions.addPlatformUnmounted());
        setInputLabel(t('USERS.SERVICE_MANAGER_LABEL'));
    }, [userManagement.addPlatformLoading, userManagement.addPlatformSuccess, userManagement.addPlatformFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSave = () => {
        dispatch(UsermanagementThunks.addPlatform(platformName, platformId, countryName, serviceManagerCustomerId, serviceManagerEmailId, authHelper));
    };

    useEffect(() => {
        if (platformDetail.name === platformName && platformDetail.country === countryName)
            setSaveButtonDisable(true);
        else if (platformName === '') {
            setSaveButtonDisable(true);
        }
        else
            setSaveButtonDisable(false);
    }, [platformName, countryName]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeCountryName = (event: SelectChangeEvent) => {
        const countryNameData = event.target.value as string
        setCountryName(countryNameData);
    };

    const onPlatformNameChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        if (event.target.value === '' || ORGANIZATTION_NAME.test(event.target.value as string)) {
            setPlatformName(event.target.value as string);
        }
    };

    function closeSelectOrgDialog(): void {
        props.closeClick({});
    }

    const onCancel = () => {
        closeSelectOrgDialog();
        setPlatformName(EMPTY_STRING);
        setServicePartnerName(EMPTY_STRING);
        setCountryName(EMPTY_STRING);
    }
    const servicePartnerCustomerListData = (list: ServicePartnerCustomerDataList): ItemList => {
        return list?.map((data, servicePartner) => {
            return {
                id: data.serviceManagerEmail,
                name: data.companyName,
            };
        });
    };

    useEffect(() => {
        if (!user.platformDetailLoading && user.platformDetailSuccess) {
            setPlatformDetail(user.platformDetail);
            setPlatformName(user.platformDetail.name);
            setCountryName(user.platformDetail.country);
            setInputLabel(user.platformDetail.serviceManagerFirstName + ' ' + user.platformDetail.serviceManagerLastName);
            setServiceManagerCustomerId(user.platformDetail.customerId);
            setServiceManagerEmailId(user.platformDetail.serviceManagerEmail);
            setPlatformId(user.platformDetail.id);
        } else if (!user.platformDetailLoading && user.platformDetailFail) {
            addToast(t('TOAST_MSG.TOAST_ERROR_GET_PASS_DETAILS'), 'error');
        }
    }, [user.platformDetailLoading, user.platformDetailSuccess, user.platformDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!userManagement.servicePartnerCustomersListLoading && userManagement.servicePartnerCustomersListSuccess) {
            setupServicePartner(servicePartnerCustomerListData(userManagement.servicePartnerCustomerList));
        } else if (!userManagement.servicePartnerCustomersListLoading && userManagement.servicePartnerCustomersListFail) {
            setupServicePartner([]);
        }
    }, [userManagement.servicePartnerCustomersListLoading, userManagement.servicePartnerCustomersListSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    const setupServicePartner = (servicePartnerCustomerList: ItemList): void => {
        const list = addPaas.concat(servicePartnerCustomerList.sort((a, b) => (a.name > b.name) ? 1 : -1));
        setPaasManagerData(list);
    }

    const addNewCustomer = (): void => {
        pushAppRoute(history,
            { type: 'CreateServicePartner' ,actionType: ActionType.ADD },
            { backRoute: 'ManageUsers' });
        updateUserTabSession(TAB_SERVICE_MANAGERS_KEY);
    }

    const setServicePartnerDetails = (newValue: any): void => {
        if (newValue?.name === platformDetail.customerName)
            setSaveButtonDisable(true);
        else if (newValue?.name === undefined)
            setSaveButtonDisable(true);
        else
            setSaveButtonDisable(false);
        setServiceManagerEmailId(newValue?.id as unknown as string);
        let selectedServicePartner = userManagement.servicePartnerCustomerList.find((data) => (data.serviceManagerEmail === newValue?.id && data.companyName === newValue?.name));
        setServiceManagerCustomerId(selectedServicePartner?.id as unknown as string);
        let firstName = selectedServicePartner?.serviceManagerFirstName !== undefined ? selectedServicePartner?.serviceManagerFirstName : '';
        let lastName = selectedServicePartner?.serviceManagerLastName !== undefined ? selectedServicePartner?.serviceManagerLastName : '';
        setInputLabel(`${firstName} ${lastName}`);

    }

    return (
        <>
            {user.platformDetailSuccess && <Dialog id="service-manager-dialog" maxWidth="md" open={props.openDialogFlag} >
                <DialogTitle data-testid='addPlatformId' sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {t('USERS.EDIT_PLATFORM_HEADER')}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onCancel}
                    style={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers style={{ paddingTop: 0, minWidth: 755 }}>
                    <ListItem data-testid='platformName-item' alignItems="flex-start" style={{ paddingLeft: 0, gap: 24 }}>
                        <ListItemAvatar>
                            <RemoveCircle fontSize='large' style={{ color: PXBColors.blue[50], position: 'absolute', height: 60, width: 50 }} >
                            </RemoveCircle>
                            <LanguageIcon fontSize='medium' style={{ color: PXBColors.blue[500], position: 'absolute', transform: 'translate(-50%, -50%)', backgroundColor: PXBColors.blue[50], borderRadius: 20, top: '55%', left: '25' }} />
                        </ListItemAvatar>
                        <ListItemText

                            primary={
                                <Typography variant='h6'>{t('USERS.PLATFORM_NAME_HEADER')}</Typography>
                            }
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        data-testid='platformNameSubheaderId'
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body1"
                                        color="text.primary"
                                    >
                                        {t('USERS.PLATFORM_NAME_SUBHEADER')}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <TextField
                        style={{ fontSize: '16px', color: PXBColors.black[500], left: 79, minWidth: 392 }}
                        id="platformName"
                        data-testid='platformNameLabelId'
                        variant={'filled'}
                        label={t('USERS.PLATFORM_NAME_HEADER')}
                        required
                        defaultValue={platformDetail.name}
                        value={platformName}
                        onChange={(e): void => onPlatformNameChange(e)}
                        inputProps={{ 'data-testid': 'platformNameID', maxLength: BUILDING_CHARACTER_LIMIT }}
                    />
                    <div data-testid='platformNameHelperTextId'
                        style={{ color: PXBColors.gray[500], marginBottom: 12 }}>
                        <small className="form-text" style={{ paddingLeft: '11%', fontSize: 12 }}>
                            {t('USERS.PLATFORM_HELPER_TEXT')}</small>
                        <Typography data-testid='platformNameLimitId' style={{ float: 'right', fontSize: 12, color: PXBColors.gray[500], paddingRight: '34%' }}>
                            {platformName.length}/{BUILDING_CHARACTER_LIMIT}
                        </Typography>
                    </div>
                    <ListItem data-testid='servicePartnerItem' alignItems="flex-start" style={{ paddingLeft: 0, gap: 24 }}>
                        <ListItemAvatar>
                            <RemoveCircle fontSize='large' style={{ color: PXBColors.blue[50], position: 'absolute', height: 60, width: 50 }} >
                            </RemoveCircle>
                            <SupervisorAccount fontSize='small' style={{ color: PXBColors.blue[500], position: 'absolute', transform: 'translate(-35%, -90%)', backgroundColor: PXBColors.blue[50], borderRadius: '20', top: '55%', left: '25', display: 'block' }} />
                            <img src={Vector} alt="Vector Logo" style={{ color: PXBColors.blue[500], position: 'absolute', transform: 'translate(-60%, -65%)', top: '59%', paddingLeft: '9%', paddingTop: '1%' }} />
                        </ListItemAvatar>
                        <ListItemText

                            primary={
                                <Typography data-testid='servicePartnerHeaderId' variant='h6'>{t('USERS.SERVICE_PARTNER_HEADER')}</Typography>
                            }
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        data-testid='servicePartnerId'
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body1"
                                        color="text.primary"
                                    >
                                        {t('USERS.CUSTOMER_NAME_SUBHEADER')}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>

                    <div style={{
                        paddingLeft: 77,
                    }}>
                        <FormControl variant={'filled'} sx={{ width: '65%' }}>
                            <Autocomplete
                                defaultValue={{
                                    id: platformDetail.serviceManagerEmail,
                                    name: platformDetail.customerName
                                }}
                                id="invite-user-select-org"
                                data-testid="autocomplete-org"
                                style={{ backgroundColor: '#f7f8f8' }}
                                options={paasManagerData}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.id === addPaas[0].id ? t('DASHBOARD.ALL_ORGANIZATION') : option.name}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <div data-testid="select-option" style={{ flex: '1 1 0px', overflow: 'auto', fontSize: md ? 16 : 10, display: 'inline' }}>
                                            {option.name !== t('USERS.CREATE_NEW_CUSTOMER') ? <Checkbox
                                                data-testid="checkbox-autocomplete"
                                                icon={<CheckBoxOutlineBlank />}
                                                checkedIcon={<CheckBox />}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                                onChange={() => { }}
                                            /> :
                                                <div style={{ float: 'left' }}>
                                                    <Add fontSize='medium' style={{ marginRight: 23, marginLeft: 11 }} />
                                                </div>
                                            }
                                            {option.name}
                                            {option.id === addPaas[0].id && <Divider style={{ paddingTop: 15 }} />}
                                        </div>
                                    </li>
                                )}
                                onChange={(event, newValue, reason) => {
                                    if (
                                        event.type === 'keydown' &&
                                        (event as React.KeyboardEvent).key === 'Backspace' &&
                                        reason === 'removeOption'
                                    ) { return; }
                                    if (newValue?.name === t('USERS.CREATE_NEW_CUSTOMER')) {
                                        addNewCustomer();
                                    }
                                    else {
                                        setServicePartnerDetails(newValue);

                                    }
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        data-testid="invite-user-auto-org-text"
                                        {...params}
                                        label={t('USERS.SERVICE_PARTNER_LABEL')}
                                        variant="filled"
                                    />
                                )
                                }
                            />
                        </FormControl>
                    </div>
                    <ListItem data-testid='country-item' alignItems="flex-start" style={{ paddingLeft: 0, gap: 24 }}>
                        <ListItemAvatar>
                            <RemoveCircle fontSize='large' style={{ color: PXBColors.blue[50], position: 'absolute', height: 60, width: 50 }} >
                            </RemoveCircle>
                            <Flag fontSize='medium' style={{ color: PXBColors.blue[500], position: 'absolute', transform: 'translate(-50%, -50%)', backgroundColor: PXBColors.blue[50], borderRadius: 20, top: '55%', left: '25' }} />
                        </ListItemAvatar>
                        <ListItemText

                            primary={
                                <Typography data-testid='CountryHeaderId' variant='h6'>{t('USERS.COUNTRY')}</Typography>
                            }
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        data-testid='countryId'
                                        component="span"
                                        variant="body1"
                                        color="text.primary"
                                    >
                                        {t('USERS.COUNTRY_SUBHEADER')}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>

                    <div style={{
                        paddingLeft: 77,
                    }}>
                        <FormControl variant={'filled'} >
                            <InputLabel >
                                {t('USERS.COUNTRY')}
                            </InputLabel>
                            <Select
                                variant={'filled'}
                                style={{ marginBottom: 12, backgroundColor: PXBColors.white[500], minWidth: 400 }}
                                labelId="country-label"
                                inputProps={{ 'data-testid': 'country-label' }}
                                data-testid="country-labelid"
                                value={countryName}
                                defaultValue={platformDetail.country}
                                onChange={onChangeCountryName}
                            >
                                {countryList?.map((e, code) => {
                                    return (
                                        <MenuItem id="country-name-list" key={code} value={e.name}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left', fontSize: 14,
                                                lineHeight: 1.5, fontWeight: 400,
                                                overflow: 'hidden',
                                                boxSizing: 'border-box',
                                                whiteSpace: 'nowrap',
                                                minHeight: 48,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                width: 'auto',
                                                paddingLeft: 16,
                                                paddingRight: 16
                                            }}>
                                            {e.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>

                    <ListItem data-testid='service-manager-id' alignItems="flex-start" style={{ paddingLeft: 0, gap: 24 }}>
                        <ListItemAvatar>
                            <RemoveCircle fontSize='large' style={{ color: PXBColors.blue[50], position: 'absolute', height: 60, width: 50 }} >
                            </RemoveCircle>
                            <ManageAccounts fontSize='medium' style={{ color: PXBColors.blue[500], position: 'absolute', transform: 'translate(-50%, -50%)', backgroundColor: PXBColors.blue[50], borderRadius: 20, top: '55%', left: '25' }} />
                        </ListItemAvatar>
                        <ListItemText

                            primary={
                                <Typography data-testid='mainServiceManagerHeaderId' variant='h6'>{t('USERS.MAIN_SERVICE_MANAGER_HEADER')}</Typography>
                            }
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        data-testid='mainServiceManagerId'
                                        component="span"
                                        variant="body1"
                                        color="text.primary"
                                    >
                                        {t('USERS.MAIN_SERVICE_MANAGER_SUBHEADER')}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>

                    <div style={{
                        paddingLeft: 77,
                    }}>
                        <TextField
                            variant={'filled'}
                            disabled={true}
                            style={{ marginBottom: 12, minWidth: 400 }}
                            inputProps={{
                                style: { backgroundColor: PXBColors.gray[50] },
                                'data-testid': 'service-manager-label'
                            }}
                            data-testid="service-manager-labelid"
                            value={inputLabel}
                        >
                        </TextField>
                    </div>
                </DialogContent>
                <Divider style={{ color: 'grey' }} />
                <DialogActions style={{ marginBottom: 15, marginLeft: 16, marginRight: 17, marginTop: 15 }}>
                    <Button
                        style={{
                            background: PXBColors.blue[500],
                            color: PXBColors.white[100],
                            opacity: saveButtonDisable ? 0.5 : 1,
                            textTransform: 'none',
                            display: 'flex',
                            width: '90px',
                            height: '36px',
                            padding: '0px 16px 0px 12px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px',
                            flexShrink: 0,
                            borderRadius: '4px'
                        }}
                        startIcon={<SaveIcon />}
                        disabled={saveButtonDisable}
                        id="btnSaveToAddServiceManagerId"
                        data-testid="btnSaveToAddPlatformDialog"
                        variant="contained"
                        onClick={handleSave}
                    >
                        {t('ACTIONS.SAVE')}
                    </Button>
                </DialogActions>
            </Dialog>}
            {
                displaySuccessMessage &&
                <Snackbar
                    open={true}
                    onClick={() => setDisplaySuccessMessage(false)}
                    autoHideDuration={20}
                    title={''}
                    message={alertSuccessMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplaySuccessMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.black[900] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

            {
                displayErrorMessage &&
                <Snackbar
                    open={true}
                    onClick={() => setDisplayErrorMessage(false)}
                    autoHideDuration={20}
                    title={''}
                    message={alertErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}

                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

        </>
    )
};
