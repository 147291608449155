import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { PLATFORM_SYSTEM_INFO } from './url-constants';

type getPlatformSystemInfoSpecifications = ApiEndpointSpecification;
export const getPlatformSystemInfoSpecification: getPlatformSystemInfoSpecifications = {
    url: PLATFORM_SYSTEM_INFO,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'GetPlatformSystemInfoRequestParams',
    requestBodySchemaName: 'GetPlatformSystemInfoRequestBody',
    okResponseSchemaName: 'GetPlatformSystemInfoOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokePlatformSystemInfo = Helpers.makeApiRequestFn<
    Types.GetPlatformSystemInfoRequestParams,
    Types.GetPlatformSystemInfoRequestBody,
    Types.GetPlatformSystemInfoOkResponse
>(getPlatformSystemInfoSpecification);
