// eslint-disable-next-line
import React from 'react';
import { Grid } from '@mui/material';
import { UserInvitationData, UserInvitationList } from '../../types/manage-users';
import { AcceptInviteCard } from './AcceptInviteCard';

type Props = {
    datalist: UserInvitationList;
    OnItemSelected: Function;
    isSelectedAll:boolean;
};

export const AcceptInviteList: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    const setSelectedData = (userSelectedData: UserInvitationData): void => {
        props.OnItemSelected(userSelectedData);
    };

    return (
        <>
            <div>
                <Grid data-testid="invite-user-container" container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {props.datalist.map((invitedUser, index) => (
                        <Grid item xs={4} sm={6} md={6} key={index} data-testid="invite-user-list">
                            <AcceptInviteCard userInvitationData={invitedUser} onCheckboxClick={setSelectedData} isSelectedAll={props.isSelectedAll}/>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </>
    )

}