// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { SEPERATOR_COMMA } from '../../constants';
import { OverviewList, OverviewSelectedData } from '../../types/overview-type';
import { OverviewListComponent } from './OverviewListComponent';
import { DeviceList } from '../../types/device';
import { DEVICE_STATUS, ENTITY_TYPE, getDeviceType } from '../enum/ENUM';

type Props = {
    userId?: string;
    buildingId: string;
    buildingName: string;
    buildingLocation: string;
    projectName: string;
    OnDeviceSelected?: Function;
    OnUserSelected?: Function;
    OnEditSelected?: Function;
    OnSearchTextChanged?: Function;
    buildingContactName?: string;
};

export const OverviewDeviceList: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    const { t } = useLanguageLocale();
    const [buildingId, setBuildingId] = React.useState(props.buildingId);
    const [buildingName, setBuildingName] = React.useState(props.buildingName);
    const [buildingLocation, setBuildingLocation] = React.useState(props.buildingLocation);
    const [users, setUsers] = React.useState(t('MESSAGES.LOADING'));
    const { OnDeviceSelected, OnUserSelected, OnEditSelected, OnSearchTextChanged } = props;
    const [buildingContactName, setBuildingContactName] = React.useState(props.buildingContactName)

    const usersSelector = useSelector(Selectors.Users.users);
    const asset = useSelector(Selectors.Dashboard.asset);

    const [deviceList, setDeviceList] = useState<OverviewList>([]);

    const [devicesReady, setDevicesReady] = useState<OverviewList>([]);
    const [devicesAnomaly, setDevicesAnomaly] = useState<OverviewList>([]);
    const [devicesBlock, setDevicesBlock] = useState<OverviewList>([]);

    const setDevice = (device: OverviewSelectedData): void => {
        if (OnDeviceSelected) OnDeviceSelected(device);
    };

    const OnUserSelectedAction = (): void => {
        if (OnUserSelected) OnUserSelected();
    };

    const OnEditSelectedAction = (): void => {
        if (OnEditSelected) OnEditSelected();
    };

    const getDeviceListByStatus = (status: number, list: DeviceList): DeviceList => {
        return list?.filter((data, device) => {
            if (data.systemStatus === status) return (
                {
                    id: data.systemId,
                    name: data.systemName,
                    status: data.systemStatus,
                    location: data.type + (data.subLocation ? SEPERATOR_COMMA + data.subLocation : ''),
                }
            );
            else
                return null;
        });
    };

    const getDeviceList = (list: DeviceList): OverviewList => {
        return list?.map((data, device) => {
            return (
                {
                    id: data.systemId,
                    name: data.systemName,
                    elStatus: data.systemStatus,
                    city: getDeviceType(data.type) + (data.subLocation ? SEPERATOR_COMMA + data.subLocation : ''),
                    projectId: '',
                    projectName: '',
                    gatewayId: data.gateWayId,
                }
            );
        })
    };

    const updateListData = (list: DeviceList): void => {
        setDeviceList(getDeviceList(list));

        setDevicesReady(getDeviceList(getDeviceListByStatus(DEVICE_STATUS.STATUS_READY, list)));

        setDevicesBlock(getDeviceList(getDeviceListByStatus(DEVICE_STATUS.STATUS_BLOCK, list)));

        const deviceOffline = getDeviceList(getDeviceListByStatus(DEVICE_STATUS.STATUS_OFFLINE, list));
        const deviceError = getDeviceList(getDeviceListByStatus(DEVICE_STATUS.STATUS_ERROR, list));
        const deviceWarning = getDeviceList(getDeviceListByStatus(DEVICE_STATUS.STATUS_WARNING, list));
        const deviceDT = getDeviceList(getDeviceListByStatus(DEVICE_STATUS.STATUS_DT, list));
        const deviceOfflineDisable = getDeviceList(getDeviceListByStatus(DEVICE_STATUS.STATUS_OFFLINE_DISABLE, list));

        setDevicesAnomaly(deviceOffline.concat(deviceError).concat(deviceWarning).concat(deviceOfflineDisable).concat(deviceDT));
    };

    const updateUserListData = (adminCount: number, viewerCount: number): void => {
        const adminsText = adminCount > 0 ? (adminCount > 1 ? adminCount + ' ' + t('DASHBOARD.USERS_ADMIN_S') : adminCount + ' ' + t('DASHBOARD.USERS_ADMIN')) : '';
        const seperatorText = viewerCount > 0 ? SEPERATOR_COMMA : '';
        const viewersText = viewerCount > 0 ? (viewerCount > 1 ? viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER_S') : viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER')) : '';
        setUsers(adminsText + seperatorText + viewersText)
    }

    useEffect(() => {
        setBuildingId(props.buildingId);
        setBuildingName(props.buildingName);
        setBuildingLocation(props.buildingLocation);
        setBuildingContactName(props.buildingContactName);
    }, [props.buildingId, props.buildingName, props.buildingLocation]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.deviceListLoading && asset.deviceListSuccess) {
            updateListData(asset.deviceList.detail);
        } else if (!asset.deviceListLoading && asset.deviceListFail) {
            updateListData([]);
        }
    }, [asset.deviceListLoading, asset.deviceListSuccess, asset.deviceListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!usersSelector.userListLoading && usersSelector.userListSuccess) {
            updateUserListData(usersSelector.userList.AdminCount, usersSelector.userList.viewersCount);
        } else if (!usersSelector.userListLoading && usersSelector.userListFail) {
            updateUserListData(0, 0);
        }
    }, [usersSelector.userListLoading, usersSelector.userListSuccess, usersSelector.userListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <OverviewListComponent
                isDeviceList={true}
                data-testid="overview-device-list-container"
                userId={props.userId}
                selectId={buildingId}
                buildingID={buildingId}
                selectName={buildingName}
                selectLocation={buildingLocation}
                list={deviceList}
                buildingContactName={buildingContactName}
                listReady={devicesReady}
                listAnomaly={devicesAnomaly}
                listBlock={devicesBlock}
                users={users}
                displayAllList={true}
                listType={ENTITY_TYPE.SYSTEM}
                OnItemSelected={(device: OverviewSelectedData): void => setDevice(device)}
                OnUserSelected={(buildingId: string): void => OnUserSelectedAction()}
                OnEditSelected={(): void => OnEditSelectedAction()}
                OnSearchTextChanged={(query: string): void => {
                    if (OnSearchTextChanged)
                        OnSearchTextChanged(query);
                }}
                isDataLoading={asset.deviceListLoading} listEmpty={[]}>
            </OverviewListComponent>
        </>
    );
};
