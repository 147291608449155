// eslint-disable-next-line
import * as React from 'react';
import {
    TableRow,
    Typography,
    Button,

} from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
// import userStyles from '../../app/user-management/UserStyle';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { InviteData } from '../../types/manage-users';
import { COLOR_GRAY } from '../../constants/color-codes';
import { getShortDate } from '../../app/enum/DateTimeFormat';
import { INVITE_STATUS } from '../../app/enum/ENUM';
import TableCell from '@mui/material/TableCell';
import { TooltipItem } from '../TooltipItem/TooltipItem';
import { getControlledText } from '../../app/enum/GenericFormat';

type Props = {
    row: InviteData;
    actionClick: Function;
    inviteType: number;
};

export const InviteListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    // const userClasses = userStyles();
    const { t } = useLanguageLocale();
    const row: InviteData = props.row;

    const actionClick = () => {
        props.actionClick({ inviteData: row, inviteType: props.inviteType });
    };

    const inviteDate = getShortDate(row.invitationDate);
    const expiryDate = getShortDate(row.expirationDate);

    const actionButtonText = props.inviteType === INVITE_STATUS.REQUESTED ? t('USER_INVITATION_MANAGEMENT.INVITE_PENDING') : props.inviteType === INVITE_STATUS.DENIED ? t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPT') : props.inviteType === INVITE_STATUS.ACCEPTED ? t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPTED_BUTTON') : t('USER_INVITATION_MANAGEMENT.INVITE_REQUEST');

    return (
        <TableRow
            id={`row${row.invitationId}`}
            data-testid={`row${row.invitationId}`}
            style={{ cursor: 'pointer', width: '100%' }}
        >
            <TableCell style={{ width: '0%', paddingLeft: 10 }}
                id="invite-list-item-blank"
                data-testid="invite-list-item-blank">

            </TableCell>

            <TableCell
                id="invite-list-item-name"
                data-testid="invite-list-item-name"
                align="left"
                padding="checkbox"
                style={{ width: '35%', paddingLeft: 20 }}
            >
                <TooltipItem
                    title={row.entityName}
                    isTopPlacement={true}
                    lenghtLimit={24}
                    component={
                        <Typography variant="body2" style={{
                            fontSize: 14,
                            color: COLOR_GRAY,
                        }}
                            id="invite-list-item-name-title"
                            data-testid="invite-list-item-name-title"
                        >{getControlledText(row.entityName, 24)}</Typography>
                    }></TooltipItem>



                <Typography
                    variant="body2"
                    style={{
                        fontSize: 12,
                        color: COLOR_GRAY,
                    }}
                    id="invite-list-item-name-subtitle"
                    data-testid="invite-list-item-name-subtitle"
                >
                    {row.entityTypeTitle}
                </Typography>

            </TableCell>

            <TableCell align="left" padding="checkbox" style={{ width: '15%', paddingLeft: 15 }}
                id="invite-list-item-date"
                data-testid="invite-list-item-date">
                {inviteDate}
            </TableCell>
            <TableCell align="left" padding="checkbox" style={{ width: '20%', padding: 0, paddingLeft: 15 }}
                id="invite-list-item-name-inviteby"
                data-testid="invite-list-item-name-inviteby">
                <TooltipItem
                    title={row.invitedByUser}
                    isTopPlacement={false}
                    lenghtLimit={15}
                    component={
                        <Typography variant="body2" id="header-location" data-testid="header-location" >{getControlledText(row.invitedByUser, 15)}</Typography>
                    }>
                </TooltipItem>
            </TableCell>

            <TableCell align="left" padding="checkbox" style={{ width: '15%', padding: 0, paddingLeft: 15 }}
                id="invite-list-item-expired"
                data-testid="invite-list-item-expired">
                {expiryDate}
            </TableCell>

            <TableCell align="left" style={{ width: '15%' }}
                id="invite-list-item-action"
                data-testid="invite-list-item-action">
                <Button
                    style={{
                        borderColor: (props.inviteType === INVITE_STATUS.REQUESTED || props.inviteType === INVITE_STATUS.ACCEPTED) ? PXBColors.gray[500] : PXBColors.blue[500],
                        backgroundColor: PXBColors.white[50],
                        color: (props.inviteType === INVITE_STATUS.REQUESTED || props.inviteType === INVITE_STATUS.ACCEPTED) ? PXBColors.gray[500] : PXBColors.blue[500],
                        opacity: (props.inviteType === INVITE_STATUS.REQUESTED || props.inviteType === INVITE_STATUS.ACCEPTED) ? 0.5 : 1,
                        flexDirection: 'row',
                        marginTop: 20,
                        marginLeft: 20,
                        marginBottom: 20,

                    }}
                    id="invite-list-item-button-action"
                    data-testid="invite-list-item-button-action"
                    variant="outlined"
                    disabled={props.inviteType === INVITE_STATUS.REQUESTED || props.inviteType === INVITE_STATUS.ACCEPTED}
                    color="primary"
                    onClick={actionClick}>
                    {actionButtonText}
                </Button>
            </TableCell>

            <TableCell style={{ width: '5%' }} />

            <TableCell align="left" padding="checkbox" style={{ width: '0%', padding: 0 }} />

        </TableRow>
    );
};
