import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_CUSTOMER_DETAIL } from './url-constants';

type CustomerDetailEndpointSpecification = ApiEndpointSpecification;
export const CustomerDetailEndpointSpecifications: CustomerDetailEndpointSpecification = {
    url: GET_CUSTOMER_DETAIL,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'CustomerDetailRequestParams',
    requestBodySchemaName: 'CustomerDetailRequestBody',
    okResponseSchemaName: 'CustomerDetailOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeCustomerDetail = Helpers.makeApiRequestFn<
    Types.CustomerDetailRequestParams,
    Types.CustomerDetailRequestBody,
    Types.CustomerDetailOkResponse
>(CustomerDetailEndpointSpecifications);
