export type LocationData = Array<{
    locationId: string;
    locationName: string;
    address: string | null;
}>;
export type OrganizationInfo = {
    organizationId: string;
    organizationName: string;
    address: string;
};
export type ProjectInfo = {
    projectId: string;
    name: string;
    country: string;
    city: string;
};
export type OrganizationData = {
    id: string;
    name: string;
    address: string;
};
export type ProjectData = {
    id: string;
    name: string;
    address: string;
};

export type SiteRegistration = {
    type: string;
    name: string;
    description: string;
    contact: string;
    email: string;
    phone: string;
    parentSiteId: string;
    siteAttributes: {
        address: string;
        city: string;
        state: string;
        zipCode: string;
        latitude?: string;
        longitude?: string;
        siteAutoUpdate?: boolean;
        alarmAutoUpdate?: boolean;
    };
};

export enum EntityType {
    Location = 'location',
    Site = 'site',
}
export enum Status {
    Active = 'Active',
    InActive = 'Inactive',
    Pending = 'Pending',
    Configured = 'Configured',
    Archive = 'Archive',
    Unassigned = 'Unassigned',
    Online = 'Online',
    Offline = 'Offline',
    Ready = 'Ready',
    Warning = 'Warning',
    Block = 'Block',
    Error = 'Error',
}
export type SiteInfo = { siteId: string; siteName: string };
export type LocationInfo = {
    locationId: string;
    locationName: string;
    address: string;
    displayOrder: number;
    siteCount: number;
    status: string;
};
export type SelectedOrgInfo = {
    organizationId: string;
    organizationName: string;
    address: string;
    locations: LocationInfo[];
};
export type ChannelData = {
    deviceId: string;
    channels: number[];
};
export type SiteProfile = {
    id: string;
    type: string;
    name: string;
    description: string | null;
    contact: string | null;
    email: string | null;
    phone: string | null;
    parentSiteId: string;
    status: string | null;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    displayOrder?: number;
    alarmAutoUpdate?: boolean | null;
    siteAutoUpdate?: boolean | null;
    priorityChannels?: ChannelData[] | null;
    oddChannels?: ChannelData[] | null;
    trendChannels?: ChannelData[] | null;
    batteryChannels?: ChannelData[] | null;
    gatewayId?: string | null;
    syncChannel?: number[] | null;
    latitude?: string | null;
    longitude?: string | null;
};
