// eslint-disable-next-line
import React from 'react';
import ListItem from '@mui/material/ListItem';
import { Typography, IconButton, ListItemText, Divider } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { FilterNone } from '@mui/icons-material';
import { ACTION_TYPE_COPY, ACTION_TYPE_NEXT, ACTION_TYPE_NONE } from '../../constants';
import * as PXBColors from '@brightlayer-ui/colors';
import { TooltipItem } from '../TooltipItem/TooltipItem';
import { getControlledText } from '../../app/enum/GenericFormat';


type Props = {
    title: string | null;
    subtitle: string;
    subtitle2?: string;
    imageType: string;
    OnItemSelected?: Function;
    divider: boolean,
    titleBold: boolean,
    onSelect?: boolean,
};

export const AssetListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { OnItemSelected } = props;
    const onSelectCss = props.onSelect && props.imageType === ACTION_TYPE_NEXT;

    const setSelected = (selectedData: string): void => {
        if (OnItemSelected) OnItemSelected(selectedData);
    };

    return (
        <>
            <ListItem data-testid="list-view-item"
                style={{ cursor: props.imageType === ACTION_TYPE_NEXT ? 'pointer' : 'cursor', display: 'flex', height: 'wrap', backgroundColor: onSelectCss ? PXBColors.blue[50] : PXBColors.white[100] }}
                onClick={props.imageType === ACTION_TYPE_NEXT ? (): void => { setSelected(props.subtitle) } : (): void => { }}
                secondaryAction={
                    props.imageType === ACTION_TYPE_NEXT ?
                        <IconButton edge="end" id="action-next" data-testid="action-next" style={{ marginLeft: "auto", paddingRight: 16 }} onClick={(): void => setSelected(props.subtitle)}>
                            <ChevronRight />
                        </IconButton>

                        : props.imageType === ACTION_TYPE_COPY &&
                        <IconButton edge="end" id="action-copy" data-testid="action-copy" style={{ marginLeft: "auto", paddingRight: 16 }} onClick={(): void => setSelected(props.subtitle)}>
                            <FilterNone />
                        </IconButton>
                }>

                <ListItemText style={{ color: onSelectCss ? PXBColors.blue[500] : PXBColors.black[500] }} data-testid="list-data-view">

                    {props.title !== null && <TooltipItem
                        title={props.title}
                        isTopPlacement={true}
                        lenghtLimit={22}
                        component={
                            <Typography
                                id="info-title"
                                data-testid="info-title"
                                variant="body2"
                                display="block"
                                style={{ fontSize: props.titleBold ? 16 : 14, fontWeight: props.titleBold ? 600 : 400 }}>
                                {getControlledText(props.title, 40)}
                            </Typography>}>
                    </TooltipItem>}

                    {props.subtitle !== null && <TooltipItem
                        title={props.subtitle}
                        isTopPlacement={false}
                        lenghtLimit={22}
                        component={
                            <Typography
                                id="info-subtitle"
                                data-testid="info-subtitle"
                                variant="body2"
                                display="block"
                                sx={{
                                    lineHeight: 1.25,
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                }}
                                style={{ fontSize: props.titleBold ? 14 : 16, fontWeight: props.titleBold ? 400 : 600 }}>
                                {getControlledText(props.subtitle, 40)}
                            </Typography>
                        }>
                    </TooltipItem>}
                    {props.subtitle2 !== null && <TooltipItem
                        title={props.subtitle2 ? props.subtitle2 : ''}
                        isTopPlacement={false}
                        lenghtLimit={22}
                        component={
                            <Typography
                                id="info-subtitle"
                                data-testid="info-subtitle"
                                variant="body2"
                                display="block"
                                sx={{
                                    lineHeight: 1.25,
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                }}
                                style={{ fontSize: props.titleBold ? 14 : 16, fontWeight: props.titleBold ? 400 : 600 }}>
                                {getControlledText(props.subtitle2 ? props.subtitle2 : '', 40)}
                            </Typography>
                        }>
                    </TooltipItem>}
                </ListItemText>
            </ListItem>

            {props.divider && <Divider style={{ marginLeft: 10 }} />}

        </>
    );
};
