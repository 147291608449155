import { ActionType, UserInvitationDetailModel } from '../../types';
import { CountryList } from '../../state/registration-state';

export const RegistrationActions = {
    loadEulaStarted: (language: string) =>
    ({
        type: 'Registration/LoadEula/Started',
        payload: { language },
    } as const),

    loadEulaSucceeded: (content: string) =>
    ({
        type: 'Registration/LoadEula/Succeeded',
        payload: { content },
    } as const),

    loadEulaFailed: () =>
    ({
        type: 'Registration/LoadEula/Failed',
    } as const),

    validateUserRegistrationStarted: () =>
    ({
        type: 'Registration/ValidateUser/Started',
    } as const),

    validateUserRegistrationSucceeded: (fname?: string | null, lname?: string | null, phone?: string | null) =>
    ({
        type: 'Registration/ValidateUser/Succeeded',
        payload: { fname, lname, phone },
    } as const),

    validateUserRegistrationFailed: (errorCode: string) =>
    ({
        type: 'Registration/ValidateUser/Failed',
        payload: { errorCode },
    } as const),

    validateEmailRegistrationStarted: () =>
    ({
        type: 'Registration/ValidateEmailVerify/Started',
    } as const),

    validateEmailRegistrationSucceeded: (userType: number, message?: string | null) =>
    ({
        type: 'Registration/ValidateEmailVerify/Succeeded',
        payload: { userType, message },
    } as const),

    validateEmailRegistrationFailed: (errorCode: string) =>
    ({
        type: 'Registration/ValidateEmailVerify/Failed',
        payload: { errorCode },
    } as const),
    validateEmailRegistrationUnmounted: () =>
    ({
        type: 'Registration/ValidateEmailVerify/Unmounted',
    } as const),

    registerUserStarted: () =>
    ({
        type: 'Registration/RegisterUser/Started',
    } as const),

    registerUserSucceeded: (email: string) =>
    ({
        type: 'Registration/RegisterUser/Succeeded',
        payload: { email },
    } as const),

    registerUserFailed: (errorCode: string) =>
    ({
        type: 'Registration/RegisterUser/Failed',
        payload: { errorCode },
    } as const),
    inviteUserStarted: () =>
    ({
        type: 'Registration/InviteUser/Started',
    } as const),

    inviteUserSucceeded: () =>
    ({
        type: 'Registration/InviteUser/Succeeded',
    } as const),

    inviteUserFailed: (errorCode: string) =>
    ({
        type: 'Registration/InviteUser/Failed',
        payload: { errorCode },
    } as const),
    inviteUserUnmounted: () =>
    ({
        type: 'Registration/InviteUser/Unmounted',
    } as const),

    verificationCodeStarted: () =>
    ({
        type: 'Registration/verificationCode/Started',
    } as const),

    verificationCodeSucceeded: (userType: number) => //getVerificationCode
    ({
        type: 'Registration/verificationCode/Succeeded',
        payload: { userType },
    } as const),

    verificationCodeFailed: (errorCode: string) =>
    ({
        type: 'Registration/verificationCode/Failed',
        payload: { errorCode },
    } as const),

    verificationCodeUnmounted: () =>
    ({
        type: 'Registration/verificationCode/Unmounted',
    } as const),

    verifyCodeStarted: () =>
    ({
        type: 'Registration/verifyCode/Started',
    } as const),

    verifyCodeSucceeded: (verificationCode?: string) =>
    ({
        type: 'Registration/verifyCode/Succeeded',
        payload: { verificationCode },
    } as const),

    verifyCodeFailed: (errorCode: string) =>
    ({
        type: 'Registration/verifyCode/Failed',
        payload: { errorCode },
    } as const),

    verifyCodeUnmounted: () =>
    ({
        type: 'Registration/verifyCode/Unmounted',
    } as const),

    invitationDetailsStarted: () =>
    ({
        type: 'Registration/invitationDetails/Started',
    } as const),

    invitationDetailsSucceeded: (invitationDetail: UserInvitationDetailModel) =>
    ({
        type: 'Registration/invitationDetails/Succeeded',
        payload: { invitationDetail },
    } as const),

    invitationDetailsFailed: (errorCode: string) =>
    ({
        type: 'Registration/invitationDetails/Failed',
        payload: { errorCode },
    } as const),

    invitationDetailsUnmounted: () =>
    ({
        type: 'Registration/invitationDetails/Unmounted',
    } as const),

    upsertUserAccessProfileStarted: () =>
    ({
        type: 'Registration/upsertUserAccessProfile/Started',
    } as const),

    upsertUserAccessProfileSucceeded: () =>
    ({
        type: 'Registration/upsertUserAccessProfile/Succeeded',
    } as const),

    upsertUserAccessProfileFailed: (errorCode: string) =>
    ({
        type: 'Registration/upsertUserAccessProfile/Failed',
        payload: { errorCode },
    } as const),

    upsertUserAccessProfileUnmounted: () =>
    ({
        type: 'Registration/upsertUserAccessProfile/Unmounted',
    } as const),

    countryListStarted: () =>
    ({
        type: 'Registration/countryList/Started',
    } as const),

    countryListSucceeded: (countryList: CountryList) =>
    ({
        type: 'Registration/countryList/Succeeded',
        payload: { countryList }
    } as const),

    countryListFailed: (errorCode: string) =>
    ({
        type: 'Registration/countryList/Failed',
        payload: { errorCode },
    } as const),

    countryListUnmounted: () =>
    ({
        type: 'Registration/countryList/Unmounted',
    } as const),

    registrationStarted: () =>
    ({
        type: 'Registration/registration/Started',
    } as const),

    registrationSucceeded: () =>
    ({
        type: 'Registration/registration/Succeeded',
    } as const),

    registrationFailed: (errorCode: string) =>
    ({
        type: 'Registration/registration/Failed',
        payload: { errorCode },
    } as const),

    registrationUnmounted: () =>
    ({
        type: 'Registration/registration/Unmounted',
    } as const),


};

export type RegistrationAction = ActionType<typeof RegistrationActions>;
