import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import {GET_SLA_CUSTOMER_LIST } from './url-constants';

type slaCustomerListRequestEndpointSpecifications = ApiEndpointSpecification;
export const slaCustomerListRequestEndpointSpecification: slaCustomerListRequestEndpointSpecifications = {
    url: GET_SLA_CUSTOMER_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'SLACustomerListRequestParams',
    requestBodySchemaName: 'SLACustomerListRequestBody',
    okResponseSchemaName: 'SLACustomerListRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeSLACustomerListRequest = Helpers.makeApiRequestFn<
    Types.SLACustomerListRequestParams,
    Types.SLACustomerListRequestBody,
    Types.SLACustomerListRequestOkResponse
>(slaCustomerListRequestEndpointSpecification);
