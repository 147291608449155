import { DECIMAL_NUMBER } from "../../constants";

//Generic function for getting controlled text
export const getControlledText = (text: string, max: number) => {
    if (text != null && text != undefined && text != '') {
        if (text.length > max) return text.substring(0, max) + '...'
        else return text
    } else return '';
};

export const getCommaNumberFormat = (value: number) => {
    if (value > 999)
        return value.toLocaleString("en-US");
    else return value;
};