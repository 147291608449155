// eslint-disable-next-line
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, CardActions, CardContent, CardHeader, CircularProgress, Typography, } from '@mui/material';
import { BrandedCardContainer } from '../../components/BrandedCardContainer/BrandedCardContainer';
import { INVITE_USER_TYPE_KEY } from '../../constants';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useDispatch, useSelector } from 'react-redux';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import { toBase64 } from '../../lib/encrypt-helpers';
import { INVITE_USER_TYPE } from '../enum/ENUM';
import {
    useAuthState,
    isAuthenticated,
    useAuthHelper,
    getUserEmail,
    getUserId,
} from '../../components/AuthContextProvider';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import Grid from '@mui/material/Grid';

export const UserInvitation: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const { t } = useLanguageLocale();
    const history = useHistory();
    // const classes = useStyles();
    // @ts-ignore
    const { invitationId } = useParams();
    const dispatch = useDispatch();
    const registration = useSelector(Selectors.Registration.registration);

    const authState = useAuthState();
    const authHelper = useAuthHelper();

    useEffect(() => {
        dispatch(Thunks.Registration.getUserInvitationDetails(invitationId));
        console.log('invitationId - ' + invitationId);//To Debug in release mode
    }, [invitationId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleInvitationNavigation = (): void => {
        if (!registration.invitationLoading && registration.invitationSuccess) {
            if (registration.invitationDetail.userType === INVITE_USER_TYPE.TYPE_SAME_ADOPTOR_USER) {
                goLogin();//Existing User
            } else {
                goToRegistration(toBase64(INVITE_USER_TYPE_KEY + registration.invitationDetail.userType), registration.invitationDetail.emailId);
            }
        } else if (!registration.invitationLoading && registration.invitationFail) {
            //ToDo - Display failure UI
        }
    };

    const goLogin = (): void => {
        pushAppRoute(history, { type: 'Login' });
    };

    const goToRegistration = (usertype: string, email: string): void => {
        pushAppRoute(history, { type: 'Registration', inviteUserType: usertype, inviteUserEmail: email });
    };

    if (isAuthenticated(authState) && !registration.invitationLoading && registration.invitationSuccess) {
        const userLoggedInEmail = getUserEmail(authState) || '';
        const userId = getUserId(authState);
        const isSameAdapterUser = registration.invitationDetail.userType === INVITE_USER_TYPE.TYPE_SAME_ADOPTOR_USER;
        if (isSameAdapterUser && registration.invitationDetail.emailId === userLoggedInEmail) {
            handleInvitationNavigation();
        } else if (isSameAdapterUser && registration.invitationDetail.emailId !== userLoggedInEmail) {
            dispatch(Thunks.Authentication.logout(userId, history, authHelper.onUserNotAuthenticated, authHelper));
            handleInvitationNavigation();
        } else {
            dispatch(Thunks.Authentication.logout(userId, history, authHelper.onUserNotAuthenticated, authHelper));
            handleInvitationNavigation();
        }
    };

    if (!isAuthenticated(authState)) {
        handleInvitationNavigation();
    };

    return (
        <BrandedCardContainer>
            <>
                {registration.invitationLoading && <CardContent
                    sx={{
                        flex: '1 1 0px',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress id={'progress-spinner'} />
                </CardContent>}

                {!registration.invitationLoading && registration.invitationFail && <>
                    <CardHeader
                        data-testid="title"
                        title={
                            <Typography variant={'h6'} style={{ fontWeight: 600 }}>
                                {t('MANAGE_USERS.INVITATION_FAIL_HEADER')}
                            </Typography>
                        }
                    />
                    <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }}> {t('MANAGE_USERS.INVITATION_FAIL_BODY')}</CardContent>

                    <CardActions style={{ padding: 16 }}>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            style={{ width: '100%', justifyContent: 'space-between' }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ width: '100%' }}
                                data-testid="request-invitation-button"
                                onClick={goLogin}
                            >
                                {t('MANAGE_USERS.INVITATION_REQUEST_ACTION')}
                            </Button>

                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ width: '100%', marginTop: 20 }}
                                data-testid="back-button"
                                onClick={goLogin}
                            >
                                {t('ACTIONS.BACK')}
                            </Button>
                        </Grid>
                    </CardActions>
                </>}
            </>
        </BrandedCardContainer>
    );
};
