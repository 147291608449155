import { ActionType, UserDetailsData } from '../../types';
import { UserListData, UpdateInvitationList, UserInvitationTypeList, InvitationRequestList, UserAdvanceAccessDetail, RemoveAccess, SentInviteResult } from '../../types/manage-users';

export const UsersActions = {
    userListStarted: () =>
    ({
        type: 'Users/userList/Started',
    } as const),

    userListSucceeded: (userList: UserListData) =>
    ({
        type: 'Users/userList/Succeeded',
        payload: { userList }
    } as const),

    userListFailed: (errorCode: string) =>
    ({
        type: 'Users/userList/Failed',
        payload: { errorCode },
    } as const),
    getEntityUserDetailStarted: () =>
    ({
        type: 'Users/entityUserDetail/Started',
    } as const),

    getEntityUserDetailSucceeded: (entityUserDetail: UserDetailsData) =>
    ({
        type: 'Users/entityUserDetail/Succeeded',
        payload: { entityUserDetail },
    } as const),

    getEntityUserDetailFailed: (errorCode: string) =>
    ({
        type: 'Users/entityUserDetail/Failed',
        payload: { errorCode },
    } as const),

    getEntityUserDetailUnmounted: () =>
    ({
        type: 'Users/entityUserDetail/Unmounted',
    } as const),

    inviteUserStarted: () =>
    ({
        type: 'Users/inviteUser/Started',
    } as const),

    inviteUserSucceeded: (sentInviteResult: SentInviteResult) =>
    ({
        type: 'Users/inviteUser/Succeeded',
        payload: {sentInviteResult}
    } as const),

    inviteUserFailed: (errorCode: string) =>
    ({
        type: 'Users/inviteUser/Failed',
        payload: { errorCode },
    } as const),

    inviteUserUnmount: () =>
    ({
        type: 'Users/inviteUser/Unmount',
    } as const),

    userInviteHistoryStarted: () =>
    ({
        type: 'Users/invitationHistory/Started',
    } as const),

    userInviteHistorySucceeded: (userInvitationHistory: UserInvitationTypeList) =>
    ({
        type: 'Users/invitationHistory/Succeeded',
        payload: { userInvitationHistory }
    } as const),

    userInviteHistoryFailed: (errorCode: string) =>
    ({
        type: 'Users/invitationHistory/Failed',
        payload: { errorCode },
    } as const),

    userInviteHistoryUnmounted: () =>
    ({
        type: 'Users/invitationHistory/Unmounted',
    } as const),

    adminApprovalInviteHistoryStarted: () =>
    ({
        type: 'Users/adminInvitationHistory/Started',
    } as const),

    adminApprovalInviteHistorySucceeded: (invitationRequestList: InvitationRequestList) =>
    ({
        type: 'Users/adminInvitationHistory/Succeeded',
        payload: { invitationRequestList }
    } as const),

    adminApprovalInviteHistoryFailed: (errorCode: string) =>
    ({
        type: 'Users/adminInvitationHistory/Failed',
        payload: { errorCode },
    } as const),

    adminApprovalInviteHistoryUnmounted: () =>
    ({
        type: 'Users/adminInvitationHistory/Unmounted',
    } as const),

    userInviteAccessRequestStarted: () =>
    ({
        type: 'Users/invitationAccessRequest/Started',
    } as const),

    userInviteAccessRequestSucceeded: () =>
    ({
        type: 'Users/invitationAccessRequest/Succeeded',
        payload: {}
    } as const),

    userInviteAccessRequestFailed: (errorCode: string) =>
    ({
        type: 'Users/invitationAccessRequest/Failed',
        payload: { errorCode },
    } as const),

    updateUserInvitationStarted: () =>
    ({
        type: 'Users/updateUserInvitation/Started',
    } as const),

    updateUserInvitationSucceeded: (updateInvitationList: UpdateInvitationList) =>
    ({
        type: 'Users/updateUserInvitation/Succeeded',
        payload: { updateInvitationList }
    } as const),

    updateUserInvitationFailed: (errorCode: string) =>
    ({
        type: 'Users/updateUserInvitation/Failed',
        payload: { errorCode },
    } as const),

    updateInvitationRequestStarted: () =>
    ({
        type: 'Users/updateInvitationRequest/Started',
    } as const),

    updateInvitationRequestSucceeded: (updateInvitationList: UpdateInvitationList) =>
    ({
        type: 'Users/updateInvitationRequest/Succeeded',
        payload: { updateInvitationList }
    } as const),

    updateInvitationRequestFailed: (errorCode: string) =>
    ({
        type: 'Users/updateInvitationRequest/Failed',
        payload: { errorCode },
    } as const),

    userAdvanceAccessStarted: () =>
    ({
        type: 'Users/userAdvanceAccess/Started',
    } as const),

    userAdvanceAccessSucceeded: (userAdvanceAccessDetail: UserAdvanceAccessDetail) =>
    ({
        type: 'Users/userAdvanceAccess/Succeeded',
        payload: { userAdvanceAccessDetail }
    } as const),

    userAdvanceAccessFailed: (errorCode: string) =>
    ({
        type: 'Users/userAdvanceAccess/Failed',
        payload: { errorCode },
    } as const),

    userAdvanceAccessUnmounted: () =>
    ({
        type: 'Users/userAdvanceAccess/Unmounted',
    } as const),
    removeAccessStarted: () =>
        ({
            type: 'Users/removeAccess/Started',
        } as const),
    
    removeAccessSucceeded: (removeAccess: RemoveAccess) =>
        ({
            type: 'Users/removeAccess/Succeeded',
            payload: { removeAccess }
        } as const),
    
    removeAccessFailed: (errorCode: string) =>
        ({
            type: 'Users/removeAccess/Failed',
            payload: { errorCode },
        } as const),
    
    removeAccessUnmounted: () =>
        ({
            type: 'Users/removeAccess/Unmounted',
        } as const),

};

export type UsersAction = ActionType<typeof UsersActions>;
