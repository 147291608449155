import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { HEALTH_REPORT_DOWNLOAD } from './url-constants';

type DownloadHealthReportEndpointSpecifications = ApiEndpointSpecification;
export const DownloadHealthReportEndpointSpecification: DownloadHealthReportEndpointSpecifications = {
    url: HEALTH_REPORT_DOWNLOAD,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'DownloadHealthReportRequestParams',
    requestBodySchemaName: 'DownloadHealthReportRequestBody',
    okResponseSchemaName: 'DownloadHealthReportRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeDownloadHealthReport = Helpers.makeApiRequestFn<
    Types.DownloadHealthReportRequestParams,
    Types.DownloadHealthReportRequestBody,
    Types.DownloadHealthReportRequestOkResponse
>(DownloadHealthReportEndpointSpecification);
