// eslint-disable-next-line
import React from 'react';
import * as PXBColors from '@brightlayer-ui/colors';
import Archive from '@mui/icons-material/Archive';
import { Status } from '../../types';
import { ListItemTag } from '@brightlayer-ui/react-components/core/ListItemTag';

export const StatusTag: React.FC<React.PropsWithChildren<React.PropsWithChildren<{
    status: string;
    style?: React.CSSProperties;
}>>> = (props) => (
    <>
        {props.status === Status.Active && (
            <ListItemTag
                translate="no"
                label={'Active'}
                backgroundColor={PXBColors.green[500]}
                fontColor={PXBColors.white[500]}
                style={{ ...props.style }} placeholder={undefined}            />
        )}

        {(props.status === Status.Pending || props.status === Status.InActive) && (
            <ListItemTag
                translate="no"
                label={props.status === Status.Pending ? 'Pending' : 'InActive'}
                backgroundColor={PXBColors.gray[500]}
                fontColor={PXBColors.white[500]}
                style={{ ...props.style }} placeholder={undefined}            />
        )}

        {props.status === Status.Unassigned && (
            <ListItemTag
                translate="no"
                label={'Unassigned'}
                backgroundColor={PXBColors.gold[500]}
                fontColor={PXBColors.white[500]}
                style={{ ...props.style }} placeholder={undefined}            />
        )}

        {props.status === Status.Configured && (
            <ListItemTag
                translate="no"
                label={'Configured'}
                backgroundColor={PXBColors.gold[500]}
                fontColor={PXBColors.white[500]}
                style={{ ...props.style }} placeholder={undefined}            />
        )}

        {props.status === Status.Archive && (
            <Archive fontSize="small" style={{ color: PXBColors.gray[500], ...props.style }} />
        )}
        {props.status === Status.Online && (
            <ListItemTag
                translate="no"
                label={'Online'}
                backgroundColor={PXBColors.green[500]}
                fontColor={PXBColors.white[500]}
                style={{ ...props.style }} placeholder={undefined}            />
        )}
        {props.status === Status.Offline && (
            <ListItemTag
                translate="no"
                label={'Offline'}
                backgroundColor={PXBColors.gray[500]}
                fontColor={PXBColors.white[500]}
                style={{ ...props.style }} placeholder={undefined}            />
        )}
        {props.status === Status.Ready && (
            <ListItemTag
                translate="no"
                label={'Ready'}
                backgroundColor={PXBColors.green[500]}
                fontColor={PXBColors.white[500]}
                style={{ ...props.style }} placeholder={undefined}            />
        )}
        {props.status === Status.Warning && (
            <ListItemTag
                translate="no"
                label={'Warning'}
                backgroundColor={PXBColors.yellow[500]}
                fontColor={PXBColors.black[500]}
                style={{ ...props.style }} placeholder={undefined}            />
        )}
        {props.status === Status.Block && (
            <ListItemTag
                translate="no"
                label={'Blocked'}
                backgroundColor={PXBColors.orange[500]}
                fontColor={PXBColors.white[500]}
                style={{ ...props.style }} placeholder={undefined}            />
        )}
        {props.status === Status.Error && (
            <ListItemTag
                translate="no"
                label={'Error'}
                backgroundColor={PXBColors.red[500]}
                fontColor={PXBColors.white[500]}
                style={{ ...props.style }} placeholder={undefined}            />
        )}
    </>
);
