import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_DEVICE_LIST } from './url-constants';

type DeviceListRequestEndpointSpecifications = ApiEndpointSpecification;
export const DeviceListRequestEndpointSpecification: DeviceListRequestEndpointSpecifications = {
    url: GET_DEVICE_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'DeviceListRequestParams',
    requestBodySchemaName: 'DeviceListRequestBody',
    okResponseSchemaName: 'DeviceListRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeDeviceListRequest = Helpers.makeApiRequestFn<
    Types.DeviceListRequestParams,
    Types.DeviceListRequestBody,
    Types.DeviceListRequestOkResponse
>(DeviceListRequestEndpointSpecification);
