import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { dashboardReducer } from './dashboard-reducer';
import { State } from '../state';
import { legalReducer } from './legal-reducer';
import { navigationReducer } from './navigation-reducer';
import { authReducer } from './auth-reducer';
import { registrationReducer } from './registration-reducer';
import { userSettingsReducer } from './user-settings-reducer';
import { signalrConnectionReducer } from './signalr-connection-reducer';
import { logbookReducer } from './logbook-reducer';
import { assetReducer } from './assets-reducer';
import { organizationReducer } from './organization-reducer';
import { usersReducer } from './users-reducer';
import { reportReducer } from './report-reducer';
import { customerReducer } from './customer-reducer';
import { usermanagementReducer } from './usermanagement-reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Reducer = (history: History<any>): any =>
    combineReducers<State>({
        // The following broke in a new version of something related to react-router.
        // Might have been type types file for one of those libraries. It came up
        // when 'npm install' on CI brought in a newer version, and then we upgraded
        // to get the same version because 'yarn install' doesn't work in CI. Using
        // "as any" to get around the type problem. Things seem to work just fine.
        router: connectRouter(history) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
        Authentication: authReducer,
        Dashboard: dashboardReducer,
        Organization: organizationReducer,
        Logbook: logbookReducer,
        Asset: assetReducer,
        Users: usersReducer,
        Legal: legalReducer,
        Navigation: navigationReducer,
        Registration: registrationReducer,
        User: userSettingsReducer,
        Customer: customerReducer,
        SignalRConnection: signalrConnectionReducer,
        Report: reportReducer,
        Usermanagement: usermanagementReducer,
    });

export default Reducer;
