
import * as ApiRequests from '../../api/requests';
import { AppThunk } from '../actions';
import { ReportActions } from '../actions/report-action';

export const ReportThunks = {

    getHealthReport: (
        entityType: string,
        entityId: string,
        offset?: string,
        limit?: string
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(ReportActions.HealthReportListStarted());
        const payload = await ApiRequests.getHealthReportList(
            {
                entityType: entityType,
                entityId: entityId,
                offset: offset,
                limit: limit

            },
            { authHelper: undefined }
        );
        if (payload.ok) {
            dispatch(ReportActions.HealthReportListSucceeded(payload.response.data));
            return;
        }
        dispatch(ReportActions.HealthReportListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    downloadHealthReport: (
        entityType: number,
        entityId: string,
        year: number,
        month: number
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(ReportActions.DonwloadHealthReportStarted());
        const payload = await ApiRequests.downloadHealthReport(
            {
                entityType: entityType,
                entityId: entityId,
                year: year,
                month: month
            },
            { authHelper: undefined }
        );
        if (payload.ok) {
             //Direct donwload from api helper //TODO in case required handling for success
            // dispatch(ReportActions.DonwloadHealthReportSucceeded(payload.response.data));
            return;
        }
        dispatch(ReportActions.DonwloadHealthReportFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

}