// eslint-disable-next-line
import React from 'react';
import ListItem from '@mui/material/ListItem';
import { Typography, IconButton, ListItemText, Divider, ListItemSecondaryAction } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { COLOR_GRAY_ICON } from '../../constants/color-codes';
import * as PXBColors from '@brightlayer-ui/colors';
import { SelectionList, SelectionData } from '../UserDetails/SelectionListData';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TooltipItem } from '../TooltipItem/TooltipItem';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { AlarmBadgeStatus } from '../AlarmBadge/AlarmBadge';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { getControlledText } from '../../app/enum/GenericFormat';
import { USER_ROLE_TYPE } from '../../app/enum/ENUM';

type Props = {
    id: string;
    emailId: string
    title: string;
    subtitle: string;
    role: string;
    userStatus: number;
    lastSeen: string;
    OnItemSelected?: Function;
    userRoles: SelectionList;
    hideDivider?: boolean;
};

export const AssetUserListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    // const classes = useStyles();
    const { t } = useLanguageLocale();
    const { OnItemSelected } = props;
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('lg'));

    const getSelectedUserRole = (role: string): SelectionData => {
        const selectedRole = props.userRoles.filter((roles) => roles.value === role);
        return selectedRole.length > 0 ? selectedRole[0] : props.userRoles[0];
    };

    const selectedUserRole: SelectionData = props.userRoles.filter(role => role.value === props.role)[0] || props.userRoles[0];
    const userRoleSelection = getSelectedUserRole(selectedUserRole.value);

    const OnSelected = (id: string, name: string, emailId: string, role: string): void => {
        if (OnItemSelected) OnItemSelected(id, name, emailId, role);
    };

    return (
        <div style={{ background: PXBColors.white[50], flex: '1 1 0px', overflow: 'auto' }}>
            <ListItem
                id="list-item"
                data-testid="list-item"
                style={{ display: 'flex', justifyContent: 'flex-end', height: 65 }}
                onClick={(): void => OnSelected(props.id, props.title, props.emailId, props.role)}>
                <ListItemSecondaryAction style={{ right: 0, top: '63%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        <div
                            style={{
                                display: 'flex',
                                width: userRoleSelection.key == USER_ROLE_TYPE.EL_ORG_ADMIN ? 170 : 190,
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                flexShrink: 0,
                            }}
                        >
                            {userRoleSelection.description}
                        </div>
                        {userRoleSelection.key === USER_ROLE_TYPE.EL_ORG_ADMIN ? (
                            <LockOutlinedIcon style={{ color: 'gray', flexShrink: 0 }} />
                        ) : (
                            ''
                        )}

                        <IconButton edge="end" id="list-item-next-icon" style={{ marginLeft: 'auto', marginRight: 16 }}>
                            <ChevronRight />
                        </IconButton>
                    </div>
                </ListItemSecondaryAction>

                <ListItemText id="list-item-text" data-testid="list-item-text">
                    <div style={{ display: 'flex' }}>
                        <TooltipItem
                            title={props.title}
                            isTopPlacement={true}
                            lenghtLimit={22}
                            component={
                                <Typography
                                    id="list-item-title"
                                    data-testid="list-item-title"
                                    style={{ fontSize: 16, fontWeight: 600, width: '34%' }}
                                >
                                    {getControlledText(props.title, 20)}
                                </Typography>
                            }
                        ></TooltipItem>
                        <div id="userStatusId" data-testid="userStatusId" style={{ rowGap: 10 }}>
                            <AlarmBadgeStatus
                                statusName={props.userStatus ? t('DASHBOARD.TIMELINE_DETAILS.INACTIVE') : t('DASHBOARD.TIMELINE_DETAILS.OPEN')}
                                statusColor={props.userStatus ? PXBColors.yellow[500] : PXBColors.green[500]}
                                foregroundColor={props.userStatus ? PXBColors.black[500] : PXBColors.white[50]}
                                alarmStatus={''}
                            />
                        </div>
                    </div>
                    <TooltipItem
                        title={props.subtitle}
                        isTopPlacement={true}
                        lenghtLimit={md ? 35 : 15}
                        component={
                            <Typography
                                id="list-item-subtitle"
                                data-testid="list-item-subtitle"
                                variant="body2"
                                display="inline"
                                sx={{
                                    lineHeight: 1.25,
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                }}
                                style={{ fontSize: 14, marginTop: 3, color: COLOR_GRAY_ICON }}
                            >
                                {getControlledText(props.subtitle, md ? 35 : 15)}</Typography>
                        }></TooltipItem>
                </ListItemText>
            </ListItem>
            {props.hideDivider && <Divider style={{ marginLeft: 16 }} />}
        </div>
    );
};
