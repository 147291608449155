import { AppRoutes, AppRoute, determineCurrentAppRoute } from '../routes';
import * as React from 'react';
import * as ReactRouter from 'react-router';

type DiscriminatedAppRoute<T extends AppRoute['type']> = Extract<AppRoute, { type: T }>;

export const useAppRoute = <TAppRouteType extends AppRoute['type']>(
    appRouteType: TAppRouteType
): DiscriminatedAppRoute<TAppRouteType> => {
    const location = ReactRouter.useLocation();
    const match = ReactRouter.matchPath(location.pathname, AppRoutes[appRouteType]);
    if (match && match.isExact) {
        const currentAppRoute = {
            type: appRouteType,
            ...match.params,
        };

        return currentAppRoute as DiscriminatedAppRoute<TAppRouteType>;
    }

    throw new Error(`Could Not Match Route Type ${appRouteType} for ${location.pathname}`);
};

export const useCurrentAppRoute = (): AppRoute => {
    const location = ReactRouter.useLocation();
    return React.useMemo(() => determineCurrentAppRoute(location.pathname), [location.pathname]);
};
