// eslint-disable-next-line
import React, { useState } from 'react';
import { CardContent, TextField } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Typography, Divider } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ALPHABETICAL, COMPANY_ADDRESS, COMPANY_CITY_MAX_LENGTH, COMPANY_POSTALCODE_MAX_LENGTH, COMPANY_POSTALCODE_MIN_LENGTH, COMPANY_POSTAL_CODE, COMPANY_REGISTRATION_ADDRESS_MAX_LENGTH } from '../../constants';

type Props = {
    companyname?: string;
    countryname?: string;
    companyaddress?: string;
    companypostalcode?: string;
    companycity?: string;
    errorMessage?: string;
    onCompanyNameChanged?: Function;
    onCompanyAddressChanged?: Function;
    onCompanyPostalCodeChanged?: Function;
    onCompanyCityChanged?: Function;
    changeNextLabel?: Function;
    changeNextEnabled?: Function;
};

const fieldsComplete = (fields: string[]): boolean => {
    for (let i = 0; i < fields.length; i++) {
        if (fields[i].length < 1) {
            return false;
        }
    }
    return true;
};

export const CompanyAddress: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const {
        companyaddress,
        companypostalcode,
        companycity,
        errorMessage,
        onCompanyAddressChanged,
        onCompanyPostalCodeChanged,
        onCompanyCityChanged,
        changeNextLabel,
        changeNextEnabled,
    } = props;
    const [CompanyAddress, setCompanyAddress] = useState(companyaddress || '');
    const [CompanyPostalCode, setCompanyPostalCode] = useState(companypostalcode || '');
    const [CompanyCity, setCompanyCity] = useState(companycity || '');

    if (changeNextLabel) {
        changeNextLabel(t('ACTIONS.NEXT'));
    }
    if (changeNextEnabled) {
        changeNextEnabled(fieldsComplete([CompanyAddress, CompanyPostalCode, CompanyCity]) && CompanyPostalCode.length > 3);
    }

    const onCompanyAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_ADDRESS.test(e.target.value)) {
            setCompanyAddress(e.target.value);
            if (onCompanyAddressChanged) onCompanyAddressChanged(e.target.value);
        }
    };

    const onCompanyPostalCodeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_POSTAL_CODE.test(e.target.value)) {
            setCompanyPostalCode(e.target.value as string);
            if (onCompanyPostalCodeChanged) onCompanyPostalCodeChanged(e.target.value);
        }
    };

    const onCompanyCityChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ALPHABETICAL.test(e.target.value)) {
            setCompanyCity(e.target.value as string);
            if (onCompanyCityChanged) onCompanyCityChanged(e.target.value);
        }
    };

    return (
        <>
            <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }}>
                <Typography variant="body1">
                    {t('REGISTRATION.STEPS_MESSAGE.CREATE_ACCOUNT_COMPANY_DETAILS')}
                </Typography>

                <Divider style={{ marginTop: 24 }} />
                <TextField
                    style={{ marginTop: 24 }}
                    id="companyaddress"
                    variant={'filled'}
                    label={t('FORMS.COMPANY_ADDRESS')}
                    value={CompanyAddress}
                    required
                    fullWidth
                    onChange={(e): void => onCompanyAddressChange(e)}
                    inputProps={{ 'data-testid': 'company-address', maxLength: COMPANY_REGISTRATION_ADDRESS_MAX_LENGTH }}
                />
                <br />
                <TextField
                    style={{ marginTop: 24, width: '30%', marginRight: 10, textAlign: 'center' }}
                    id="companypostalcode"
                    variant={'filled'}
                    label={t('FORMS.POSTAL_CODE')}
                    inputProps={{ 'data-testid': 'company-postal-code', maxLength: COMPANY_POSTALCODE_MAX_LENGTH, minLength: COMPANY_POSTALCODE_MIN_LENGTH }}
                    value={CompanyPostalCode}
                    required
                    fullWidth
                    onChange={(e): void => onCompanyPostalCodeChange(e)}
                />
                <TextField
                    style={{ marginTop: 24, width: '67%', textAlign: 'center' }}
                    id="companycity"
                    variant={'filled'}
                    label={t('FORMS.CITY')}
                    inputProps={{ 'data-testid': 'company-city', maxLength: COMPANY_CITY_MAX_LENGTH }}
                    value={CompanyCity}
                    required
                    fullWidth
                    onChange={(e): void => onCompanyCityChange(e)}
                />
                <Typography variant="body2"
                    style={{ marginTop: 16, color: PXBColors.red[500], fontSize: "small" }}
                >
                    {errorMessage}
                </Typography>
            </CardContent>
        </>
    );
};
