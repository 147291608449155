import { GatewayDetailsList, GatewayListData } from '../types/gateway';
import { DeviceList, DeviceListData } from '../types/device';
import { LuminaryListData } from '../types/luminary';
import { DeviceCustomerDetail } from '../types/customer-detail';
import { SLADataList, SLAListData } from '../types/sla';
import { slaCustomerList } from '../types/sla-customer-details';
import { string } from 'yargs';

const defaultLumListData: LuminaryListData = {
    detail: [],
    totalRecordCount: 0,
    totalPage: 0
}
export const customerData: DeviceCustomerDetail = {
    customerId: '',
    sLACount: 0,
    expiryDate: '',
    companyName: '',
    customerNumber: '',
    companyAddress1: '',
    companyAddress2: '',
    city: '',
    country: '',
    postalCode: '',
    companyCountryCode: '',
    companyPhone: '',
    comments: '',
    primaryCountryCode: '',
    primaryFirstName: '',
    primaryLastName: '',
    primaryPhone: '',
    primaryCountryCode2: '',
    primaryPhone2: '',
    primaryEmail: '',
    primaryContactType: 0,
    primaryContactComments: '',
    secondaryFirstName: '',
    secondaryLastName: '',
    secondaryCountryCode: '',
    secondaryPhone: '',
    secondaryCountryCode2: '',
    secondaryPhone2: '',
    secondaryContactType: 0,
    secondaryContactComments: '',
    secondaryEmail: '',
    systemId: '',
    imageBase64: '',
    imageType: '',
    systemCount: 0,
    buildingCount: 0,
};

const getSLAData: SLAListData = {
    detail: [{
        sLANumber: '',
        companyName: '',
        customerNumber: '0',
        customerCity: '',
        projectName: '',
        projectId: '0',
        buildingName: '',
        buildingId: '0',
        systemName: '',
        systemId: '0',
        type: 0,
        systemStatus: 0,
        luminariesNotPartOfSLA: '',
        expiryDate: '0001-01-01T00:00:00'
    }],
    totalRecordCount: 0,
    totalPage: 0
}
const defaultGatewayListData: GatewayListData = {
    detail: [],
    totalRecordCount: 0,
    totalPage: 0
}
const defaultSystemListData: DeviceListData = {
    detail: [],
    totalRecordCount: 0,
    totalPage: 0
}


export type AssetState = {
    deviceListLoading: boolean;
    deviceListSuccess: boolean;
    deviceListFail: boolean;
    deviceList: DeviceListData,
    deviceListErrorCode: string;

    deviceCustomerDataLoading: boolean;
    deviceCustomerDataSuccess: boolean;
    deviceCustomerDataFail: boolean;
    deviceCustomerData: DeviceCustomerDetail,
    deviceCustomerDataErrorCode: string;

    updateCustomerDetailLoading: boolean;
    updateCustomerDetailSuccess: boolean;
    updateCustomerDetailFail: boolean;
    updateCustomerDetailErrorMessage: string;
    updateCustomerDetailId: string;

    luminaryListLoading: boolean;
    luminaryListSuccess: boolean;
    luminaryListFail: boolean;
    luminaryList: LuminaryListData,
    luminaryListErrorCode: string;

    gatewayDetailsListLoading: boolean;
    gatewayDetailsListSuccess: boolean;
    gatewayDetailsListFail: boolean;
    gatewayDetailsList: GatewayListData,
    gatewayDetailsListErrorCode: string;

    slaListLoading: boolean;
    slaListSuccess: boolean;
    slaListFail: boolean;
    slaList: SLAListData,
    slaTotalPages: number,
    slaListErrorCode: string;

    slaCustomerListLoading: boolean;
    slaCustomerListSuccess: boolean;
    slaCustomerListFail: boolean;
    slaCustomerList: slaCustomerList,
    slaCustomerListErrorCode: string;
};

export const initialAssetState: AssetState = {
    deviceListLoading: false,
    deviceListSuccess: false,
    deviceListFail: false,
    deviceList: defaultSystemListData,
    deviceListErrorCode: '',

    deviceCustomerDataLoading: false,
    deviceCustomerDataSuccess: false,
    deviceCustomerDataFail: false,
    deviceCustomerData: customerData,
    deviceCustomerDataErrorCode: '',

    updateCustomerDetailLoading: false,
    updateCustomerDetailSuccess: false,
    updateCustomerDetailFail: false,
    updateCustomerDetailErrorMessage: '',
    updateCustomerDetailId:'',

    luminaryListLoading: false,
    luminaryListSuccess: false,
    luminaryListFail: false,
    luminaryList: defaultLumListData,
    luminaryListErrorCode: '',

    gatewayDetailsListLoading: false,
    gatewayDetailsListSuccess: false,
    gatewayDetailsListFail: false,
    gatewayDetailsList: defaultGatewayListData,
    gatewayDetailsListErrorCode: '',

    slaListLoading: false,
    slaListSuccess: false,
    slaListFail: false,
    slaList: getSLAData,
    slaTotalPages: 0,
    slaListErrorCode: '',

    slaCustomerListLoading: false,
    slaCustomerListSuccess: false,
    slaCustomerListFail: false,
    slaCustomerList: [],
    slaCustomerListErrorCode: '',

};
