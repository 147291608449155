import { ActionType } from '../../types';
import { ReportListData } from '../../types/report';


export const ReportActions = {
    //get health report list
    HealthReportListStarted: () =>
    ({
        type: 'Report/HealthReportList/Started',
    } as const),

    HealthReportListSucceeded: (healthReportList: ReportListData) =>
    ({
        type: 'Report/HealthReportList/Succeeded',
        payload: { healthReportList },
    } as const),

    HealthReportListFailed: (errorCode: string) =>
    ({
        type: 'Report/HealthReportList/Failed',
        payload: { errorCode },
    } as const),

    HealthReportListUnmounted: () =>
    ({
        type: 'Report/HealthReportList/Unmounted',
    } as const),

    //download health report
    DonwloadHealthReportStarted: () =>
    ({
        type: 'Report/DownloadHealthReport/Started',
    } as const),

    DonwloadHealthReportSucceeded: (data: string) =>
    ({
        type: 'Report/DownloadHealthReport/Succeeded',
        payload: { data },
    } as const),

    DonwloadHealthReportFailed: (errorCode: string) =>
    ({
        type: 'Report/DownloadHealthReport/Failed',
        payload: { errorCode },
    } as const),

    DonwloadHealthReportUnmounted: () =>
    ({
        type: 'Report/DownloadHealthReport/Unmounted',
    } as const),

};



export type ReportAction = ActionType<typeof ReportActions>;