import { AppReducer } from './app-reducer';
import { InitialState } from '../state';

export const registrationReducer: AppReducer<'Registration'> = (
    state = InitialState.Registration,
    action
): typeof state => {
    switch (action.type) {
        case 'Registration/RegisterUser/Started':
            return {
                ...state,
                loading: true,
                success: false,
            };

        case 'Registration/RegisterUser/Succeeded':
            return {
                ...state,
                loading: false,
                success: true,
                email: action.payload.email,
            };

        case 'Registration/RegisterUser/Failed':
            return {
                ...state,
                loading: false,
                success: false,
                errorCode: action.payload.errorCode,
            };

        case 'Registration/LoadEula/Started':
            return {
                ...state,
                eulaLoading: true,
                eula: '',
            };

        case 'Registration/LoadEula/Succeeded':
            return {
                ...state,
                eulaLoading: false,
                eula: action.payload.content,
            };

        case 'Registration/LoadEula/Failed':
            return {
                ...state,
                eulaLoading: false,
                eula: '',
            };

        case 'Registration/ValidateUser/Started':
            return {
                ...state,
                validationloading: true,
                validationSuccess: false,
            };

        case 'Registration/ValidateUser/Succeeded':
            return {
                ...state,
                validationloading: false,
                validationSuccess: true,
                firstName: action.payload.fname,
                lastName: action.payload.lname,
                phone: action.payload.phone,
                existingUser:
                    action.payload.fname !== undefined ||
                        action.payload.lname !== undefined ||
                        action.payload.fname === null ||
                        action.payload.lname === null
                        ? true
                        : false,
            };

        case 'Registration/ValidateUser/Failed':
            return {
                ...state,
                validationloading: false,
                validationSuccess: false,
                errorCode: action.payload.errorCode,
            };

        case 'Registration/InviteUser/Started':
            return {
                ...state,
                inviteUserLoading: true,
                inviteUserSuccess: false,
                errorCode: '',
            };

        case 'Registration/InviteUser/Succeeded':
            return {
                ...state,
                inviteUserLoading: false,
                inviteUserSuccess: true,
                errorCode: '',
            };

        case 'Registration/InviteUser/Failed':
            return {
                ...state,
                inviteUserLoading: false,
                inviteUserSuccess: false,
                errorCode: action.payload.errorCode,
            };

        case 'Registration/InviteUser/Unmounted':
            return {
                ...state,
                inviteUserLoading: false,
                inviteUserSuccess: false,
                errorCode: '',
            };

        case 'Registration/ValidateEmailVerify/Started':
            return {
                ...state,
                validateEmailLoading: true,
                validateEmailSuccess: false,
                validateEmailFail: false,
                errorCode: '',
            };

        case 'Registration/ValidateEmailVerify/Succeeded':
            return {
                ...state,
                validateEmailLoading: false,
                validateEmailSuccess: true,
                validateEmailFail: false,
                validateEmailUserType: action.payload.userType,
                errorCode: '',
            };

        case 'Registration/ValidateEmailVerify/Failed':
            return {
                ...state,
                validateEmailLoading: false,
                validateEmailSuccess: false,
                validateEmailFail: true,
                errorCode: action.payload.errorCode,//'Validate email failed'//
            };

        case 'Registration/ValidateEmailVerify/Unmounted':
            return {
                ...state,
                validateEmailLoading: false,
                validateEmailSuccess: false,
                validateEmailFail: false,
                errorCode: '',
            };

        case 'Registration/verificationCode/Started':
            return {
                ...state,
                verificationCodeLoading: true,
                verificationCodeSuccess: false,
                verificationCodeFail: false,
                errorCode: '',
            };

        case 'Registration/verificationCode/Succeeded':
            return {
                ...state,
                verificationCodeLoading: false,
                verificationCodeSuccess: true,
                verificationCodeFail: false,
                errorCode: '',
                validateVerificationUserType: action.payload.userType,
            };

        case 'Registration/verificationCode/Failed':
            return {
                ...state,
                verificationCodeLoading: false,
                verificationCodeSuccess: false,
                verificationCodeFail: true,
                errorCode: action.payload.errorCode,
            };

        case 'Registration/verificationCode/Unmounted':
            return {
                ...state,
                verificationCodeLoading: false,
                verificationCodeSuccess: false,
                verificationCodeFail: false,
                errorCode: '',
            };

        case 'Registration/verifyCode/Started':
            return {
                ...state,
                verifyCodeLoading: true,
                verifyCodeSuccess: false,
                verifyCodeFail: false,
                errorCode: '',
                verificationCode: ''
            };

        case 'Registration/verifyCode/Succeeded':
            return {
                ...state,
                verifyCodeLoading: false,
                verifyCodeSuccess: true,
                verifyCodeFail: false,
                errorCode: '',
                verificationCode: action.payload.verificationCode ? action.payload.verificationCode : '',
            };
        case 'Registration/verifyCode/Failed':
            return {
                ...state,
                verifyCodeLoading: false,
                verifyCodeSuccess: false,
                verifyCodeFail: true,
                errorCode: action.payload.errorCode,
                verificationCode: ''
            };

        case 'Registration/verifyCode/Unmounted':
            return {
                ...state,
                verifyCodeLoading: false,
                verifyCodeSuccess: false,
                verifyCodeFail: false,
                errorCode: '',
                verificationCode: ''
            };

        case 'Registration/invitationDetails/Started':
            return {
                ...state,
                invitationLoading: true,
                invitationSuccess: false,
                invitationFail: false,
                errorCode: '',
            };

        case 'Registration/invitationDetails/Succeeded':
            return {
                ...state,
                invitationLoading: false,
                invitationSuccess: true,
                invitationFail: false,
                errorCode: '',
                invitationDetail: action.payload.invitationDetail,
            };

        case 'Registration/invitationDetails/Failed':
            return {
                ...state,
                invitationLoading: false,
                invitationSuccess: false,
                invitationFail: true,
                errorCode: action.payload.errorCode,
            };

        case 'Registration/invitationDetails/Unmounted':
            return {
                ...state,
                invitationLoading: false,
                invitationSuccess: false,
                invitationFail: false,
                errorCode: '',
            };

        case 'Registration/upsertUserAccessProfile/Started':
            return {
                ...state,
                upsertUserAccessProfileLoading: true,
                upsertUserAccessProfileSuccess: false,
                upsertUserAccessProfileFail: false,
                errorCode: '',
            };

        case 'Registration/upsertUserAccessProfile/Succeeded':
            return {
                ...state,
                upsertUserAccessProfileLoading: false,
                upsertUserAccessProfileSuccess: true,
                upsertUserAccessProfileFail: false,
                errorCode: '',
            };

        case 'Registration/upsertUserAccessProfile/Failed':
            return {
                ...state,
                upsertUserAccessProfileLoading: false,
                upsertUserAccessProfileSuccess: false,
                upsertUserAccessProfileFail: true,
                errorCode: action.payload.errorCode,
            };

        case 'Registration/upsertUserAccessProfile/Unmounted':
            return {
                ...state,
                upsertUserAccessProfileLoading: false,
                upsertUserAccessProfileSuccess: false,
                upsertUserAccessProfileFail: false,
                errorCode: '',
            };

        case 'Registration/countryList/Started':
            return {
                ...state,
                countryListLoading: true,
                countryListSuccess: false,
                countryListFail: false,
                errorCode: '',
            };
        case 'Registration/countryList/Succeeded':
            return {
                ...state,
                countryListLoading: false,
                countryListSuccess: true,
                countryListFail: false,
                errorCode: '',
                countryList: action.payload.countryList,
            };
        case 'Registration/countryList/Failed':
            return {
                ...state,
                countryListLoading: false,
                countryListSuccess: false,
                countryListFail: true,
                errorCode: action.payload.errorCode,
            };

        case 'Registration/countryList/Unmounted':
            return {
                ...state,
                countryListLoading: false,
                countryListSuccess: false,
                countryListFail: false,
                errorCode: '',
            };

        case 'Registration/registration/Started':
            return {
                ...state,
                registrationLoading: true,
                registrationSuccess: false,
                registrationFail: false,
                errorCode: '',
            };
        case 'Registration/registration/Succeeded':
            return {
                ...state,
                registrationLoading: false,
                registrationSuccess: true,
                registrationFail: false,
                errorCode: ''
            };
        case 'Registration/registration/Failed':
            return {
                ...state,
                registrationLoading: false,
                registrationSuccess: false,
                registrationFail: true,
                errorCode: action.payload.errorCode,
            };

        case 'Registration/registration/Unmounted':
            return {
                ...state,
                registrationLoading: false,
                registrationSuccess: false,
                registrationFail: false,
                errorCode: '',
            };

        default:
            return state;
    }
};
