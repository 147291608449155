// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Button, Checkbox, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, TextField, Typography } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { Country, CountryList } from '../../state/registration-state';
import { ActionType, ALPHA_NUMBERIC, COMPANY_ADDRESS, COMPANY_ADDRESS_MAX_LENGTH, COMPANY_CITY, COMPANY_CITY_MAX_LENGTH, COMPANY_POSTALCODE_MAX_LENGTH, COUNTRY_CODE_MAX_LENGTH, CUSTOMER_NUMBER_LIMIT, EMPTY_STRING, FIRST_NAME_MAX_LENGTH, LAST_NAME_MAX_LENGTH, NAME_VALIDATION, ORGANIZATTION_NAME, ORG_CHARACTER_LIMIT, ORG_COMMENTS_LIMIT, ORG_EMAIL_MAX_LENGTH, PHONE_MAX_LENGTH, PHONE_MIN_LENGTH, PHONE_NUMBER } from '../../constants';
import { GenericToolbar } from '../../components/GenericToolbar';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { validEmail, validPhoneNumberLength, validPostalCode } from '../../lib/form-validator';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { Thunks } from '../../actions';
import { CustomerLogo } from './CustomerLogo';
import { ACTION_TYPE, contactTypeSelectionList, contactUpdateList, getContactTypeNumber, getcontactUpdateList } from '../enum/ENUM';
import { DeviceCustomerDetail } from '../../types/customer-detail';
import { customerData } from '../../state/assets-state';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { useParams } from 'react-router';
import { orgDetailsDefault } from '../organization-management/UpsertBuilding';
import { OrganizationData } from '../../types/organization';
import { OrganizationActions } from '../../actions/actions/organization-actions';
import { EmptyState } from '@brightlayer-ui/react-components';
import { AssetActions } from '../../actions/actions/asset-actions';
import { vw_100, vw_110, vw_133, vw_140 } from '../../constants/dimentions';

type Props = {
    location: any;
};

export const AddCustomerDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const history = useHistory();
    // @ts-ignore
    const { actionType = ActionType.ADD } = useParams();
    const { deviceId, projectId, customerNo} = props.location.state;
    const { addToast } = useToasts();
    const asset = useSelector(Selectors.Asset.assets);
    const organization = useSelector(Selectors.Organization.organization);
    const dispatch = useDispatch();
    const [appBarColor, setAppBarColor] = useState(ToolbarColor.Default);
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const { t } = useLanguageLocale();
    const [customerId, setCustomerId] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerNumber, setCustomerNumber] = useState('');
    const [sLACount, setSLACount] = useState(0);
    const [customerComments, setCustomerComments] = React.useState('');
    const [primaryComments, setPrimaryComments] = React.useState('');
    const [secondaryComments, setSecondaryComments] = React.useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyAddress2, setCompanyAddress2] = useState('');
    const [companyPostalCode, setCompanyPostalCode] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [countryName, setCountryName] = useState('');
    const [countryList, setCountryList] = useState<CountryList>([]);
    const [selectedCountry, setSelectedCountry] = useState<Country>({ name: '', code: '' });
    const [countryCode, setCountryCode] = useState('');
    const [primarycountryCode, setPrimaryCountryCode] = useState('');
    const [primaryBackUpcountryCode, setPrimaryBackUpCountryCode] = useState('');
    const [secondarycountryCode, setSecondaryCountryCode] = useState('');
    const [secondaryBackUpcountryCode, setSecondaryBackUpCountryCode] = useState('');
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
    const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState('');
    const [primaryBackUpPhoneNumber, setPrimaryBackUpPhoneNumber] = useState('');
    const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState('');
    const [secondaryBackUpPhoneNumber, setSecondaryBackUpPhoneNumber] = useState('');
    const [primaryContactType, setPrimaryContactType] = React.useState('');
    const [secondaryContactType, setSecondaryContactType] = React.useState('');
    const [primaryEmail, setPrimaryEmail] = useState('');
    const [secondaryEmail, setSecondaryEmail] = useState('');
    const [primaryFirstName, setPrimaryFirstName] = useState('');
    const [primaryLastName, setPrimaryLastName] = useState('');
    const [secondaryFirstName, setSecondaryFirstName] = useState('');
    const [secondaryLastName, setSecondaryLastName] = useState('');
    const [isContactDetailsChecked, setIsContactDetailsChecked] = useState(false);
    const registration = useSelector(Selectors.Registration.registration);
    const countryListLoading = useSelector(Selectors.Registration.countryListLoading);
    const countryListSuccess = useSelector(Selectors.Registration.countryListSuccess);
    const [deviceCustomerData, setDeviceCustomerData] = useState<DeviceCustomerDetail>(customerData);
    const [customerLogo, setCustomerLogo] = useState<string | null | undefined>('');
    const [imageString, setImageString] = useState<string | undefined>('');
    const [organizationDetails, setOrganizationDetails] = useState<OrganizationData>(orgDetailsDefault);
    const customerPrimaryContactType: contactTypeSelectionList[0] = getcontactUpdateList(deviceCustomerData.primaryContactType);
    const customerSecondaryContactType: contactTypeSelectionList[0] = getcontactUpdateList(deviceCustomerData.secondaryContactType);
    const [isOrgAddressAvailable, setIsOrgAddressAvailable] = useState(false);
    const [orgLoaded, setOrgLoaded] = React.useState(false);
    const [customerDetailLoaded, setCustomerDetailLoaded] = React.useState(false);
    const [countryLoaded, setCountryLoaded] = React.useState(false);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [savebuttonState, setSavebuttonState] = React.useState(false);
    const [header, setHeader] = React.useState('');
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [alertFailureDisplayState, setAlertFailureDisplayState] = useState(false);

    const handleToolBar = (flag: boolean) => {
        setSavebuttonState(flag);
        setAppBarColor(flag ? ToolbarColor.Other : ToolbarColor.Default);
    };

    useEffect(() => {
        if ((deviceId || customerNo) && (actionType == ActionType.EDIT))
            dispatch(Thunks.Asset.getDeviceCustomerData(deviceId, customerNo));
        AssetActions.deviceCustomerDataUnmounted();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (actionType == ActionType.EDIT) {
            if (!asset.deviceCustomerDataLoading && asset.deviceCustomerDataSuccess) {
                setDeviceCustomerData(asset.deviceCustomerData);
                setCustomerDetailLoaded(true);
                handleToolBar(false);

            } else if (!asset.deviceCustomerDataLoading && asset.deviceCustomerDataFail) {
                addToast(t('TOAST_MSG.TOAST_ERROR_GET_CUSTOMER_INFO'), 'error');
            }
            dispatch(AssetActions.deviceCustomerDataUnmounted());
        }
    }, [asset.deviceCustomerDataLoading, asset.deviceCustomerDataSuccess, asset.deviceCustomerDataFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (actionType == ActionType.ADD)
            setHeader(t('CUSTOMER_DETAILS.CREATE_CUSTOMER'));
        else
            setHeader(t('CUSTOMER_DETAILS.CREATE_CUSTOMER_HEADER'));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    var primaryContactValue = getContactTypeNumber(primaryContactType);
    var secondaryContactValue = getContactTypeNumber(secondaryContactType);

    var primaryContactNumber = (primaryContactType !== '' && primaryContactType !== undefined) ? primaryContactValue.key : 0;
    var secondaryContactNumber = (secondaryContactType !== '' && secondaryContactType !== undefined) ? secondaryContactValue.key : 0;

    useEffect(() => {
        if (!asset.deviceCustomerDataLoading && asset.deviceCustomerDataSuccess) {
            setSavebuttonState(false);
            setAppBarColor(ToolbarColor.Default);
            if (
                customerLogo === deviceCustomerData.imageBase64 &&
                customerName === deviceCustomerData.companyName &&
                customerNumber === deviceCustomerData.customerNumber &&
                customerComments === deviceCustomerData.comments &&
                companyAddress === deviceCustomerData.companyAddress1 &&
                companyAddress2 === deviceCustomerData.companyAddress2 &&
                companyPostalCode === deviceCustomerData.postalCode &&
                companyCity === deviceCustomerData.city &&
                countryName === deviceCustomerData.country &&
                countryCode === deviceCustomerData.companyCountryCode &&
                companyPhoneNumber === deviceCustomerData.companyPhone &&
                sLACount === deviceCustomerData.sLACount &&
                primaryContactNumber === deviceCustomerData.primaryContactType &&
                primaryEmail === deviceCustomerData.primaryEmail &&
                primaryFirstName === deviceCustomerData.primaryFirstName &&
                primaryLastName === deviceCustomerData.primaryLastName &&
                primarycountryCode === deviceCustomerData.primaryCountryCode &&
                primaryPhoneNumber === deviceCustomerData.primaryPhone &&
                primaryBackUpcountryCode === deviceCustomerData.primaryCountryCode2 &&
                primaryBackUpPhoneNumber === deviceCustomerData.primaryPhone2 &&
                primaryComments === deviceCustomerData.primaryContactComments &&
                secondaryContactNumber === deviceCustomerData.secondaryContactType &&
                secondaryEmail === deviceCustomerData.secondaryEmail &&
                secondaryFirstName === deviceCustomerData.secondaryFirstName &&
                secondaryLastName === deviceCustomerData.secondaryLastName &&
                secondarycountryCode === deviceCustomerData.secondaryCountryCode &&
                secondaryPhoneNumber === deviceCustomerData.secondaryPhone &&
                secondaryBackUpcountryCode === deviceCustomerData.secondaryCountryCode2 &&
                secondaryBackUpPhoneNumber === deviceCustomerData.secondaryPhone2 &&
                secondaryComments === deviceCustomerData.secondaryContactComments
            ) {
                handleToolBar(false);
            }
            else {

                if (fieldsComplete([customerName, customerNumber,
                    companyAddress, companyPostalCode,
                    companyCity, countryName, countryCode,
                    companyPhoneNumber,
                    primaryContactType, primaryEmail, primaryFirstName,
                    primaryLastName, primaryPhoneNumber,
                    primarycountryCode])) {
                    setSavebuttonState(true);
                    setAppBarColor(ToolbarColor.Other);
                    if (companyPostalCode.length > 0) {
                        if (validPostalCode(companyPostalCode)) {
                            handleToolBar(true);
                        }
                        else if (!validPostalCode(companyPostalCode)) {
                            handleToolBar(false);
                        }
                    }

                    if (companyPhoneNumber.length > 0) {
                        if (validPhoneNumberLength(companyPhoneNumber)) {
                            handleToolBar(true);
                        }
                        else if (!validPhoneNumberLength(companyPhoneNumber)) {
                            handleToolBar(false);
                        }
                    }
                    if (primaryPhoneNumber.length > 0) {
                        if (validPhoneNumberLength(primaryPhoneNumber)) {
                            handleToolBar(true);
                        }
                        else if (!validPhoneNumberLength(primaryPhoneNumber)) {
                            handleToolBar(false);
                        }
                    }
                    if (primaryBackUpPhoneNumber.length > 0) {
                        if (validPhoneNumberLength(primaryBackUpPhoneNumber)) {
                            handleToolBar(true);
                        }
                        else if (!validPhoneNumberLength(primaryBackUpPhoneNumber)) {
                            handleToolBar(false);
                        }
                    }
                    if (secondaryPhoneNumber.length > 0) {
                        if (validPhoneNumberLength(secondaryPhoneNumber)) {
                            handleToolBar(true);
                        }
                        else if (!validPhoneNumberLength(secondaryPhoneNumber)) {
                            handleToolBar(false);
                        }
                    }
                    if (secondaryBackUpPhoneNumber.length > 0) {
                        if (validPhoneNumberLength(secondaryBackUpPhoneNumber)) {
                            handleToolBar(true);
                        }
                        else if (!validPhoneNumberLength(secondaryBackUpPhoneNumber)) {
                            handleToolBar(false);
                        }
                    }

                    if (primaryEmail.length > 0) {
                        if (validEmail(primaryEmail)) {
                            handleToolBar(true);
                        }
                        else if (!validEmail(primaryEmail)) {
                            handleToolBar(false);
                        }
                    }
                    if (secondaryEmail.length > 0) {
                        if (validEmail(secondaryEmail)) {
                            handleToolBar(true);
                        }
                        else if (!validEmail(secondaryEmail)) {
                            handleToolBar(false);
                        }
                    }


                    if (companyPhoneNumber.length > 0 && primaryPhoneNumber.length > 0 && companyPostalCode.length > 0 && primaryBackUpPhoneNumber.length > 0 && secondaryPhoneNumber.length > 0 && secondaryBackUpPhoneNumber.length > 0 && primaryEmail.length > 0 && secondaryEmail.length > 0) {
                        if (validPhoneNumberLength(companyPhoneNumber) && validPhoneNumberLength(primaryPhoneNumber) && validPostalCode(companyPostalCode) && validPhoneNumberLength(primaryBackUpPhoneNumber) && validPhoneNumberLength(secondaryPhoneNumber) && validPhoneNumberLength(secondaryBackUpPhoneNumber) && validEmail(primaryEmail) && validEmail(secondaryEmail)) {
                            setSavebuttonState(true);
                            setAppBarColor(ToolbarColor.Other);
                        }
                        else {
                            setSavebuttonState(false);
                            setAppBarColor(ToolbarColor.Default);
                        }
                    }
                }
                else {
                    handleToolBar(false);
                }
            }
        }
        else {
            if (fieldsComplete([customerName, customerNumber,
                companyAddress, companyPostalCode,
                companyCity, countryName, countryCode,
                companyPhoneNumber,
                primaryContactType, primaryEmail, primaryFirstName,
                primaryLastName, primaryPhoneNumber,
                primarycountryCode])) {
                handleToolBar(true);
                setAppBarColor(ToolbarColor.Other);
            }
            else {
                handleToolBar(false);
                setAppBarColor(ToolbarColor.Default);
            }
        }
        // eslint-disable-next-line
    }, [customerLogo, customerName, customerNumber, customerComments, companyAddress, companyAddress2, companyPostalCode, companyCity, countryName,
        countryCode, companyPhoneNumber, primaryContactType, primaryEmail, primaryFirstName, primaryLastName,
        primaryPhoneNumber, primaryBackUpPhoneNumber, primarycountryCode, primaryBackUpcountryCode, primaryComments, secondaryContactType, secondaryEmail,
        secondaryFirstName, secondaryLastName, secondarycountryCode, secondaryPhoneNumber, secondaryBackUpcountryCode, secondaryBackUpPhoneNumber, secondaryComments])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        dispatch(Thunks.Registration.countryCodeRequest());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (projectId === undefined || projectId ==='' || projectId === null) {
            setIsOrgAddressAvailable(false);
            return;
        }
            dispatch(Thunks.Organization.getOrganizationDetailsList(projectId));
    }, [projectId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (actionType == ActionType.EDIT) {
            if (projectId ? orgLoaded : true && countryLoaded && customerDetailLoaded) {
                if (!countryListLoading && !organization.orgListLoading) {
                    setDataLoaded(true);
                }
            }
        }
        else if (actionType == ActionType.ADD) {
            if (projectId ? orgLoaded : true && countryLoaded) {
                if (!countryListLoading && !organization.orgListLoading) {
                    setDataLoaded(true);
                }
            }
        }
        else
            setDataLoaded(false);
    }, [orgLoaded, countryLoaded, customerDetailLoaded, countryListLoading, organization.orgListLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!countryListLoading && countryListSuccess) {
            setCountryList(registration.countryList);
            setCountryLoaded(true);
        }
    }, [countryListLoading, countryListSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.updateCustomerDetailLoading && asset.updateCustomerDetailSuccess) {
            history.goBack();
            if (actionType == ActionType.ADD)
                addToast(t('TOAST_MSG.TOAST_SUCCESS_CUSTOMER_CREATED'), 'success');
            else
                addToast(t('TOAST_MSG.TOAST_SUCCESS_UPDATE_CUSTOMER_DETAILS'), 'success');
        }
        else
            if (!asset.updateCustomerDetailLoading && asset.updateCustomerDetailFail) {
                handleCreateCustomerResponse();
                setAlertFailureDisplayState(true);
            }
        dispatch(AssetActions.updateCustomerUnmounted());
    }, [asset.updateCustomerDetailSuccess, asset.updateCustomerDetailFail, asset.updateCustomerDetailLoading]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!organization.orgListLoading && organization.orgListSuccess) {
            if (organization.orgList.detail.length > 0 && projectId) {
                setOrganizationDetails(organization.orgList.detail[0]);
                setOrgLoaded(true);
                if (fieldsComplete([organization.orgList.detail[0].address ? organization.orgList.detail[0].address : '',
                organization.orgList.detail[0].city,
                organization.orgList.detail[0].postalCode ? organization.orgList.detail[0].postalCode : '',
                organization.orgList.detail[0].country ? organization.orgList.detail[0].country : '',
                organization.orgList.detail[0].countryCode ? organization.orgList.detail[0].countryCode : '',
                organization.orgList.detail[0].phone ? organization.orgList.detail[0].phone : ''])) {
                    setIsOrgAddressAvailable(true);
                }
            } else {
                setOrgLoaded(true);
            }
        } else if (!organization.orgListLoading && organization.orgListFail) {
            setOrganizationDetails(orgDetailsDefault);
            setIsOrgAddressAvailable(false);
            setOrgLoaded(true);
        }
        dispatch(OrganizationActions.orgListUnmounted());
    }, [organization.orgListLoading, organization.orgListSuccess, organization.orgListFail]); // eslint-disable-line react-hooks/exhaustive-deps



    const handleContactDetailCheckbox = (
        event: React.ChangeEvent<HTMLElement>,
        checked: boolean,
    ) => {
        if (checked) {
            setIsContactDetailsChecked(true);
            setCompanyAddress(organizationDetails.address ? organizationDetails.address : '');
            setCompanyAddress2(organizationDetails.address2 ? organizationDetails.address2 : '');
            setCompanyPostalCode(organizationDetails.postalCode ? organizationDetails.postalCode : '');
            setCompanyCity(organizationDetails.city);
            setCountryName(organizationDetails.country);
            setCountryCode(organizationDetails.countryCode);
            setCompanyPhoneNumber(organizationDetails.phone ? organizationDetails.phone : '');
        }
        else if (!checked) {
            setCompanyAddress(EMPTY_STRING);
            setCompanyAddress2(EMPTY_STRING);
            setCompanyCity(EMPTY_STRING);
            setCompanyPostalCode(EMPTY_STRING);
            setCountryName(EMPTY_STRING);
            setIsContactDetailsChecked(false);
            setCountryCode(EMPTY_STRING);
            setCompanyPhoneNumber(EMPTY_STRING);
        }
    };



    const setCustomerData = (customerData: DeviceCustomerDetail) => {
        setCustomerId(customerData.customerId);
        setSLACount(customerData.sLACount);
        setCustomerName(customerData.companyName);
        setCustomerNumber(customerData.customerNumber);
        setCompanyAddress(customerData.companyAddress1);
        setCompanyAddress2(customerData.companyAddress2);
        setCompanyCity(customerData.city);
        setCountryName(customerData.country);
        setCompanyPostalCode(customerData.postalCode);
        setCountryCode(customerData.companyCountryCode);
        setCompanyPhoneNumber(customerData.companyPhone)
        setCustomerComments(customerData.comments);
        setPrimaryCountryCode(customerData.primaryCountryCode ? customerData.primaryCountryCode : '');
        setPrimaryFirstName(customerData.primaryFirstName ? customerData.primaryFirstName : '')
        setPrimaryLastName(customerData.primaryLastName ? customerData.primaryLastName : '')
        setPrimaryPhoneNumber(customerData.primaryPhone ? customerData.primaryPhone : '')
        setPrimaryBackUpCountryCode(customerData.primaryCountryCode2 ? customerData.primaryCountryCode2 : '')
        setPrimaryBackUpPhoneNumber(customerData.primaryPhone2 ? customerData.primaryPhone2 : '');
        setPrimaryEmail(customerData.primaryEmail ? customerData.primaryEmail : '');
        setPrimaryContactType(customerPrimaryContactType.value);
        setPrimaryComments(customerData.primaryContactComments ? customerData.primaryContactComments : '')
        setSecondaryFirstName(customerData.secondaryFirstName ? customerData.secondaryFirstName : '')
        setSecondaryLastName(customerData.secondaryLastName ? customerData.secondaryLastName : '')
        setSecondaryCountryCode(customerData.secondaryCountryCode ? customerData.secondaryCountryCode : '')
        setSecondaryPhoneNumber(customerData.secondaryPhone ? customerData.secondaryPhone : '')
        setSecondaryBackUpCountryCode(customerData.secondaryCountryCode2 ? customerData.secondaryCountryCode2 : '')
        setSecondaryBackUpPhoneNumber(customerData.secondaryPhone2 ? customerData.secondaryPhone2 : '')
        setSecondaryContactType(customerSecondaryContactType.value);
        setSecondaryComments(customerData.secondaryContactComments ? customerData.secondaryContactComments : '')
        setSecondaryEmail(customerData.secondaryEmail ? customerData.secondaryEmail : '')
        setCustomerLogo(customerData.imageBase64 ? customerData.imageBase64 : '');
    };

    useEffect(() => {
        setCustomerData(deviceCustomerData);
    }, [deviceCustomerData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePrimaryContactTypeChange = (event: SelectChangeEvent) => {
        setPrimaryContactType(event.target.value);
    };
    const handleSecondaryContactTypeChange = (event: SelectChangeEvent) => {
        setSecondaryContactType(event.target.value);
    };

    const onCustomerNameChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        if (event.target.value === '' || ORGANIZATTION_NAME.test(event.target.value as string)) {
            setCustomerName(event.target.value as string);
        }
    };
    const onCustomerNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setCustomerNumber(e.target.value);
        }
    };
    const onCompanyAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_ADDRESS.test(e.target.value)) {
            setCompanyAddress(e.target.value);
        }
    };
    const onCompanyAddressChange2 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_ADDRESS.test(e.target.value)) {
            setCompanyAddress2(e.target.value);
        }
    };
    const onCompanyPostalCodeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ALPHA_NUMBERIC.test(e.target.value)) {
            setCompanyPostalCode(e.target.value as string);
        }
    };

    const onCompanyCityChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_CITY.test(e.target.value)) {
            setCompanyCity(e.target.value as string);
        }
    };
    const onChangeCountryName = (event: SelectChangeEvent) => {
        const countryNameData = event.target.value as string
        setCountryName(countryNameData);
        // eslint-disable-next-line
        const countryCodeData = countryList.filter(function (countryData) {
            if (countryData.name === countryNameData)
                return countryData
        });
        setCountryCode(countryCodeData?.[0].code);
    };

    const onChangeCompanyPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setCompanyPhoneNumber(e.target.value);
        }
    };
    const onChangePrimaryPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setPrimaryPhoneNumber(e.target.value);
        }
    };
    const onChangePrimaryBackUpPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setPrimaryBackUpPhoneNumber(e.target.value);
        }
    };
    const onChangeSecondaryPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setSecondaryPhoneNumber(e.target.value);
        }
    };
    const onChangeSecondaryBackUpPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setSecondaryBackUpPhoneNumber(e.target.value);
        }
    };
    const onPrimaryFirstNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setPrimaryFirstName(e.target.value);
        }
    };
    const onPrimaryLastNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setPrimaryLastName(e.target.value);
        }
    };
    const onSecondaryFirstNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setSecondaryFirstName(e.target.value);
        }
    };
    const onSecondaryLastNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setSecondaryLastName(e.target.value);
        }
    };
    const onChangeCountryCode = (event: SelectChangeEvent) => {
        setCountryCode(event.target.value as string);
    };
    const onChangePrimaryCountryCode = (event: SelectChangeEvent) => {
        setPrimaryCountryCode(event.target.value as string);
    };
    const onChangePrimaryBackUpCountryCode = (event: SelectChangeEvent) => {
        setPrimaryBackUpCountryCode(event.target.value as string);
    };
    const onChangeSecondaryCountryCode = (event: SelectChangeEvent) => {
        setSecondaryCountryCode(event.target.value as string);
    };
    const onChangeSecondaryBackUpCountryCode = (event: SelectChangeEvent) => {
        setSecondaryBackUpCountryCode(event.target.value as string);
    };
    const fieldsComplete = (fields: string[]): boolean => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].length < 1) {
                return false;
            }
        }
        return true;
    };

    const onCancel = () => {
        history.goBack();
    }
    const saveData = (): void => {
        if (fieldsComplete([customerName, customerNumber,
            companyAddress, companyPostalCode,
            companyCity, countryName, countryCode,
            companyPhoneNumber,
            primaryContactType, primaryEmail, primaryFirstName,
            primaryLastName, primaryPhoneNumber,
            primarycountryCode])) {
            dispatch(Thunks.Asset.updateCustomer(
                customerId,
                customerName,
                customerNumber,
                companyAddress,
                companyAddress2,
                companyPostalCode,
                companyCity,
                countryName,
                countryCode,
                companyPhoneNumber,
                customerComments,
                primaryFirstName,
                primaryLastName,
                primarycountryCode,
                primaryPhoneNumber,
                primaryBackUpcountryCode,
                primaryBackUpPhoneNumber,
                primaryEmail,
                primaryContactNumber,
                primaryComments,
                secondaryFirstName,
                secondaryLastName,
                secondarycountryCode,
                secondaryPhoneNumber,
                secondaryBackUpcountryCode,
                secondaryBackUpPhoneNumber,
                secondaryEmail,
                secondaryContactNumber,
                secondaryComments,
                customerLogo ? customerLogo : '',
                '',
            ));
        }
        else {
            addToast(t('TOAST_MSG.TOAST_ERROR_MESSAGE'), 'error');
        }
    }

    const handleCreateCustomerResponse = (): void => {
        if ( asset.updateCustomerDetailErrorMessage !== '') {
            if (asset.updateCustomerDetailErrorMessage === '9163')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9163'));
            else if (asset.updateCustomerDetailErrorMessage === '9068')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9068'));
            else if (asset.updateCustomerDetailErrorMessage === '9069')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9069'));
            else if (asset.updateCustomerDetailErrorMessage === '9070')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9070'));
            else if (asset.updateCustomerDetailErrorMessage === '9071')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9071'));
            else if (asset.updateCustomerDetailErrorMessage === '9072')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9072'));
            else if (asset.updateCustomerDetailErrorMessage === '9164')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9164'));
            else if (asset.updateCustomerDetailErrorMessage === '9043')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9043'));
            else if (asset.updateCustomerDetailErrorMessage === '9073')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9073'));
            else if (asset.updateCustomerDetailErrorMessage === '9074')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9074'));
            else if (asset.updateCustomerDetailErrorMessage === '9167')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9167'));
            else if (asset.updateCustomerDetailErrorMessage === '9077')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9077'));
            else if (asset.updateCustomerDetailErrorMessage === '9147')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9147'));
            else if (asset.updateCustomerDetailErrorMessage === '9076')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9076'));
            else if (asset.updateCustomerDetailErrorMessage === '9001')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9001'));
            else if (asset.updateCustomerDetailErrorMessage === '9170')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9170'));
            else if (asset.updateCustomerDetailErrorMessage === '9150')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9150'));
            else if (asset.updateCustomerDetailErrorMessage === '9151')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9151'));
            else if (asset.updateCustomerDetailErrorMessage === '9152')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9152'));
            else if (asset.updateCustomerDetailErrorMessage === '9153')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9153'));
            else if (asset.updateCustomerDetailErrorMessage === '9165')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9165'));
            else if (asset.updateCustomerDetailErrorMessage === '9166')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9166'));
            else if (asset.updateCustomerDetailErrorMessage === '9172')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9172'));
            else if (asset.updateCustomerDetailErrorMessage === '9173')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9173'));
            else if (asset.updateCustomerDetailErrorMessage === '9174')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9174'));
            else if (asset.updateCustomerDetailErrorMessage === '9175')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9175'));
            else if (asset.updateCustomerDetailErrorMessage === '9176')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9176'));
            else if (asset.updateCustomerDetailErrorMessage === '9040')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9040'));
            else if (asset.updateCustomerDetailErrorMessage === '9023')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9023'));
            else if (asset.updateCustomerDetailErrorMessage === '9000')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9000'));
            else if (asset.updateCustomerDetailErrorMessage === '9225')
                setAlertErrorMessage(t('TOAST_MSG.CREATE_CUSTOMER_ERROR_CODE_9225'));
            else
                setAlertErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }
    };

    return (
        <div data-testid="customer-details-container">
            <GenericToolbar
                title={header}
                showBackButton={true}
                showCloseButton={false}
                actionUIState={dataLoaded ? savebuttonState : false}
                backButtonOnClick={(): void => {
                    history.goBack();
                }}
                saveOnClick={(): void => {
                    saveData();
                }}
                cancelOnClick={(): void => {
                    onCancel()
                }}
                color={dataLoaded ? appBarColor : ToolbarColor.Default}
            />
            {!dataLoaded && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
            {dataLoaded && <div data-testid="container-create-customer" style={{ backgroundColor: PXBColors.white[50], height: md ? '100%' : 1600, width: '100%', padding: '10%', paddingTop: '3%', display: md ? 'flex' : '' }}>

                <div data-testid='leftContainerTestId' style={{ float: 'left', width: md ? '49%' : '', paddingRight: '5%' }}>
                    <div data-testid='asteriskHelperText' style={{ display: 'flex', gap: 15, paddingBottom: 28 }}>
                        <InfoIcon style={{ color: PXBColors.blue[500] }} />
                        <Typography data-testid='helpingTextId' variant='body1' style={{ fontWeight: 400, fontSize: 16 }}>{t('FORMS.HELPING_TEXT')}</Typography>
                    </div>
                    <div data-testid='imageContainerId' style={{ height: 340, background: PXBColors.white[50], borderRadius: 4, boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)' }}>
                        <Typography data-testid='imagesTextId' variant='subtitle2' color={PXBColors.blue[500]} style={{ paddingTop: 15, paddingRight: 291, paddingBottom: 14, paddingLeft: 16, height: 48 }}>{t('CUSTOMER_DETAILS.IMAGES')}</Typography>
                        <Divider />
                        <CustomerLogo image={customerLogo} onRemoveExistingImage={(): void => { setCustomerLogo('') }} onImageUploadLocal={(image, imageString): void => { setCustomerLogo(image); setImageString(imageString) }}></CustomerLogo>
                    </div>

                    <div data-testid='customer-details-divId' style={{ marginBottom: 125 }}>
                        <Typography data-testid='customer-details-textId' variant='body1' style={{ fontWeight: 400, fontSize: 16, color: PXBColors.blue[500], fontStyle: 'normal', marginTop: 24 }}>{t('CUSTOMER_DETAILS.CUSTOMER_DETAILS_HEADER')}</Typography>
                        <TextField
                            style={{ marginTop: 24, width: '100%', fontSize: '16px', color: PXBColors.black[500] }}
                            id="customerName"
                            data-testid='customerNameLabelId'
                            variant={'filled'}
                            label={t('CUSTOMER_DETAILS.CUSTOMER_NAME')}
                            fullWidth
                            required
                            value={customerName}
                            onChange={(e): void => onCustomerNameChange(e)}
                            inputProps={{ 'data-testid': 'customerNameId', maxLength: ORG_CHARACTER_LIMIT }}
                        />
                        <div data-testid='customerNameHelperTextId'
                            style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                            <small className="form-text" style={{ width: '85%', float: 'left', paddingLeft: '2%' }}>
                                {t('ORGANIZATION_MANAGE.ORGANIZATION_NAME_HELPER')}</small>
                            <Typography data-testid='CustomerNamecharLimitId' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                                {customerName.length}/{ORG_CHARACTER_LIMIT}
                            </Typography>
                        </div>
                        <TextField
                            style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                            id="customerNumber"
                            data-testid='customerNumberLabelId'
                            variant={'filled'}
                            label={t('CUSTOMER_DETAILS.CUSTOMER_NUMBER')}
                            fullWidth
                            required
                            disabled={actionType == ActionType.EDIT ? true : false}
                            value={customerNumber}
                            onChange={(e): void => onCustomerNumberChange(e)}
                            inputProps={{ 'data-testid': 'customerNumberLabelId2', maxLength: CUSTOMER_NUMBER_LIMIT }}
                        />

                        <div data-testid='customerNumberHelperTextId'
                            style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                            <small className="form-text" style={{ width: '85%', float: 'left', paddingLeft: '2%' }}>
                                {t('CUSTOMER_DETAILS.CUSTOMER_NUMBER_HELPER_TEXT')}</small>
                            <Typography data-testid='customerNumberCharLimitId' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                                {customerNumber.length}/{CUSTOMER_NUMBER_LIMIT}
                            </Typography>
                        </div>

                        <TextField
                            style={{ marginTop: 24, width: '100%', float: 'left', wordWrap: 'break-word', color: PXBColors.black[500] }}
                            id="customercomments"
                            data-testid='customercommentsId'
                            variant={'filled'}
                            label={t('LOGBOOK_DETAILS.COMMENTS')}
                            rows={2}
                            multiline
                            fullWidth
                            onChange={(evt: ChangeEvent<HTMLInputElement>): void => setCustomerComments(evt.target.value)}
                            value={customerComments}
                            inputProps={{ 'data-testid': 'customer-comments', maxLength: ORG_COMMENTS_LIMIT }}
                        />
                        <div data-testid='customerCommentsDivId'
                            style={{ height: 17, width: '100%', color: PXBColors.gray[500], marginBottom: 24 }}>
                            <small data-testid='helperTextIdTechnician' className="form-text" style={{ width: '86%', float: 'left', paddingLeft: '2%' }}>
                                {t('ORGANIZATION_MANAGE.COMMENTS_HELPER')}</small>
                            <Typography data-testid='customerCommentsLimit' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                                {customerComments.length}/{ORG_COMMENTS_LIMIT}
                            </Typography>
                        </div>
                    </div>
                    <div >
                        {isOrgAddressAvailable && <div style={{ display: 'flex' }}>
                            <Checkbox data-testid='contactDetailCheck' id="contact-detail-check"
                                onChange={handleContactDetailCheckbox}
                                checked={isContactDetailsChecked}
                            />
                            <Typography
                                id="org-address-check-lable"
                                data-testid="org-address-check-lable"
                                style={{ fontSize: '16', fontWeight: 400, alignSelf: 'center', color: PXBColors.black[500] }}>
                                {t('USER_SETTINGS.ORG_ADDRESS')}
                            </Typography>
                        </div>}
                        <TextField
                            data-testid='companyAddressId'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="companyaddress"
                            variant={'filled'}
                            disabled={isContactDetailsChecked}
                            required
                            label={t('FORMS.USER_ADDRESS_1')}
                            fullWidth
                            inputProps={{ 'data-testid': 'company-address', maxLength: COMPANY_ADDRESS_MAX_LENGTH }}
                            value={companyAddress}
                            onChange={(e): void => onCompanyAddressChange(e)}
                        />
                        <TextField
                            data-testid='companyAddressId2'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="companyaddress2"
                            variant={'filled'}
                            label={t('FORMS.USER_ADDRESS_2') + t('FORMS.OPTIONAL')}
                            fullWidth
                            disabled={isContactDetailsChecked}
                            inputProps={{ 'data-testid': 'company-address2', maxLength: COMPANY_ADDRESS_MAX_LENGTH }}
                            value={companyAddress2}
                            onChange={(e): void => onCompanyAddressChange2(e)}

                        />
                        <TextField
                            style={{ marginTop: 24, width: '35%', marginRight: '2%', textAlign: 'center' }}
                            id="companypostalcode"
                            data-testid='company-postal-code'
                            variant={'filled'}
                            required
                            label={t('FORMS.POSTAL_CODE')}
                            disabled={isContactDetailsChecked}
                            error={companyPostalCode.length > 0 && !validPostalCode(companyPostalCode)}
                            helperText={companyPostalCode.length > 0 && !validPostalCode(companyPostalCode) ? (t('FORMS.INVALID_POSTAL_CODE')) : ''}
                            inputProps={{ 'data-testid': 'companyPostalCode', maxLength: COMPANY_POSTALCODE_MAX_LENGTH }}
                            fullWidth
                            value={companyPostalCode}
                            onChange={(e): void => onCompanyPostalCodeChange(e)}
                        />
                        <TextField
                            data-testid='companyCityTestId'
                            style={{ marginTop: 24, width: '63%', textAlign: 'center' }}
                            id="companycity"
                            required
                            disabled={isContactDetailsChecked}
                            variant={'filled'}
                            label={t('FORMS.CITY')}
                            inputProps={{ 'data-testid': 'company-city', maxLength: COMPANY_CITY_MAX_LENGTH }}
                            fullWidth
                            value={companyCity}
                            onChange={(e): void => onCompanyCityChange(e)}
                        />
                        <FormControl variant={'filled'} style={{ display: 'flex', marginTop: 24 }}>
                            <InputLabel data-testid='countryNameLabelId' id="country-name-label">{t('FORMS.COUNTRY')}</InputLabel>
                            <Select
                                labelId="country-name-label"
                                id="country-name-label"
                                data-testid='select'
                                fullWidth
                                disabled={isContactDetailsChecked}
                                defaultValue={selectedCountry.name !== '' ? selectedCountry.name : ''}
                                value={countryName}
                                inputProps={{ 'data-testid': 'country-name' }}
                                onChange={onChangeCountryName}
                            >
                                {countryList?.map((e, code) => {
                                    return (
                                        <MenuItem id="country-name-list" key={code} value={e.name}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left', fontSize: 14,
                                                lineHeight: 1.5, fontWeight: 400,
                                                overflow: 'hidden',
                                                boxSizing: 'border-box',
                                                whiteSpace: 'nowrap',
                                                minHeight: 48,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                width: 'auto',
                                                paddingLeft: 16,
                                                paddingRight: 16
                                            }}>
                                            {e.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <div>
                        <FormControl variant={'filled'} style={{ marginTop: 24, marginRight: '2%' }}>
                            <InputLabel data-testid='countryCodeRequiredId' id="country-name-label">{'*'}</InputLabel>
                            <Select
                                style={{ width: vw_110, textAlign: 'center' }}
                                variant={'filled'}
                                labelId="countrycode-label"
                                inputProps={{ 'data-testid': 'country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                                id="countrycode-testid"
                                defaultValue={countryCode}
                                value={countryCode}
                                disabled={isContactDetailsChecked}
                                onChange={() => onChangeCountryCode}
                            >
                                {countryList?.map((e, code) => {
                                    return (
                                        <MenuItem id="country-code-list" key={code} value={e.code}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left', fontSize: 14,
                                                lineHeight: 1.5, fontWeight: 400,
                                                overflow: 'hidden',
                                                boxSizing: 'border-box',
                                                whiteSpace: 'nowrap',
                                                minHeight: 48,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                width: 'auto',
                                                paddingLeft: 16,
                                                paddingRight: 16
                                            }}>
                                            {'+' + e.code}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            </FormControl>

                            <TextField
                                required
                                data-testid='companyPhoneNumberTestId'
                                style={{ marginTop: 24, width: md ? '79%' : '65%' }}
                                variant={'filled'}
                                disabled={isContactDetailsChecked}
                                label={t('USER_SETTINGS.COMPANY_PHONE_NUMBER')}
                                error={companyPhoneNumber.length > 0 && !validPhoneNumberLength(companyPhoneNumber)}
                                helperText={companyPhoneNumber.length > 0 && !validPhoneNumberLength(companyPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                                inputProps={{ 'data-testid': 'phone-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                                fullWidth
                                value={companyPhoneNumber}
                                onChange={(e): void => onChangeCompanyPhoneNumber(e)}
                            />
                        </div>
                    </div>
                </div>

                <div data-testid='customer-detail-right-container' style={{ float: 'right', width: md ? '49%' : '' }}>
                    <Typography style={{ padding: 12, paddingLeft: 0, fontWeight: 400, fontSize: 16, color: PXBColors.blue[500] }} data-testid="primary-contact-header">{t('CUSTOMER_DETAILS.PRIMARY_CONTACT_INFO')}</Typography>
                    <FormControl required variant="filled" sx={{ width: '100%' }}>
                        <InputLabel data-testid='primaryContactTypeId' id="simple-select-filled-label">{t('CUSTOMER_DETAILS.CONTACT_TYPE')}</InputLabel>
                        <Select
                            inputProps={{ 'data-testid': 'primary-contact-type' }}
                            labelId="simple-select-filled-label"
                            id="simple-select-filled"
                            value={primaryContactType}
                            onChange={handlePrimaryContactTypeChange}
                        >
                            {contactUpdateList.map((option) => (
                                <MenuItem sx={{ textAlign: 'center' }}
                                    key={option.key} value={option.value} data-testid={option.value} >
                                    {t(option.value)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        required
                        style={{ marginTop: 24 }}
                        data-testid='primaryEmailTestId'
                        id="primaryemail"
                        variant={'filled'}
                        label={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT_EMAIL_ADDRESS')}
                        error={primaryEmail.length > 0 && !validEmail(primaryEmail)}
                        helperText={primaryEmail.length > 0 && !validEmail(primaryEmail) ? (t('FORMS.INVALID_EMAIL_ERROR')) : ''}
                        inputProps={{ 'data-testid': 'primary-email', maxLength: ORG_EMAIL_MAX_LENGTH }}
                        fullWidth
                        value={primaryEmail}
                        onChange={(e): void => setPrimaryEmail(e.target.value)}
                    />
                    <TextField
                        style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                        id="firstName"
                        data-testid='firstnameLabelId'
                        variant={'filled'}
                        label={t('FORMS.FIRST_NAME')}
                        fullWidth
                        required
                        value={primaryFirstName}
                        onChange={(e): void => onPrimaryFirstNameChange(e)}
                        inputProps={{ 'data-testid': 'firstLabelId2', maxLength: FIRST_NAME_MAX_LENGTH }}
                    />
                    <TextField
                        style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                        id="lastname"
                        data-testid='lastnameLabelId'
                        variant={'filled'}
                        label={t('FORMS.LAST_NAME')}
                        fullWidth
                        required
                        value={primaryLastName}
                        onChange={(e): void => onPrimaryLastNameChange(e)}
                        inputProps={{ 'data-testid': 'lastnameLabelId2', maxLength: LAST_NAME_MAX_LENGTH }}
                    />
                    <Typography style={{ padding: '12px 16px 12px 0px' }} data-testid="phone-no-header">{t('CUSTOMER_DETAILS.PHONE_NUMBERS_HEADER')}</Typography>
                    <div>
                    <FormControl variant={'filled'} style={{ marginTop: 24, marginRight: '2%' }}>
                            <InputLabel data-testid='countryCodeRequiredId' id="country-name-label">{'*'}</InputLabel>
                        <Select
                           style={{ width: vw_140, textAlign: 'center' }}
                            variant={'filled'}
                            labelId="countrycode-label"
                            inputProps={{ 'data-testid': 'primary-country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                            id="countrycode-testid"
                            defaultValue={primarycountryCode}
                            value={primarycountryCode}
                            onChange={(e) => onChangePrimaryCountryCode(e)}
                        >
                            {countryList?.map((e, code) => {
                                return (
                                    <MenuItem id="country-code-list" key={code} value={e.code}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'left', fontSize: 14,
                                            lineHeight: 1.5, fontWeight: 400,
                                            overflow: 'hidden',
                                            boxSizing: 'border-box',
                                            whiteSpace: 'nowrap',
                                            minHeight: 48,
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            width: 'auto',
                                            paddingLeft: 16,
                                            paddingRight: 16
                                        }}>
                                        {'+' + e.code}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        </FormControl>

                        <TextField
                            required
                            data-testid='mobileNumberTestId'
                            style={{ marginTop: 24, width: md ? '75%' : '65%' }}
                            variant={'filled'}
                            label={t('CUSTOMER_DETAILS.MOBILE_NUMBER')}
                            error={primaryPhoneNumber.length > 0 && !validPhoneNumberLength(primaryPhoneNumber)}
                            helperText={primaryPhoneNumber.length > 0 && !validPhoneNumberLength(primaryPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                            inputProps={{ 'data-testid': 'mobile-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                            fullWidth
                            value={primaryPhoneNumber}
                            onChange={(e): void => onChangePrimaryPhoneNumber(e)}
                        />
                    </div>
                    <div>
                        <Select
                            style={{ marginTop: 24, width: md ? '23%' : '33%', marginRight: '2%', textAlign: 'center' }}
                            variant={'filled'}
                            labelId="countrycode-label"
                            inputProps={{ 'data-testid': 'primary-backUp-country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                            id="countrycode-testid"
                            defaultValue={primaryBackUpcountryCode}
                            value={primaryBackUpcountryCode}
                            onChange={(e) => onChangePrimaryBackUpCountryCode(e)}
                        >
                            {countryList?.map((e, code) => {
                                return (
                                    <MenuItem id="country-code-list" key={code} value={e.code}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'left', fontSize: 14,
                                            lineHeight: 1.5, fontWeight: 400,
                                            overflow: 'hidden',
                                            boxSizing: 'border-box',
                                            whiteSpace: 'nowrap',
                                            minHeight: 48,
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            width: 'auto',
                                            paddingLeft: 16,
                                            paddingRight: 16
                                        }}>
                                        {'+' + e.code}
                                    </MenuItem>
                                );
                            })}
                        </Select>

                        <TextField
                            data-testid='officeNumberTestId'
                            style={{ marginTop: 24, width: md ? '75%' : '65%' }}
                            variant={'filled'}
                            label={t('CUSTOMER_DETAILS.OFFICE_NUMBER')}
                            error={primaryBackUpPhoneNumber.length > 0 && !validPhoneNumberLength(primaryBackUpPhoneNumber)}
                            helperText={primaryBackUpPhoneNumber.length > 0 && !validPhoneNumberLength(primaryBackUpPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                            inputProps={{ 'data-testid': 'office-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                            fullWidth
                            value={primaryBackUpPhoneNumber}
                            onChange={(e): void => onChangePrimaryBackUpPhoneNumber(e)}
                        />
                    </div>
                    <TextField
                        style={{ marginTop: 24, width: '100%', float: 'left', wordWrap: 'break-word', color: PXBColors.black[500] }}
                        id="primarycomments"
                        data-testid='primarycommentsId'
                        variant={'filled'}
                        label={t('LOGBOOK_DETAILS.COMMENTS')}
                        rows={2}
                        multiline
                        fullWidth
                        onChange={(evt: ChangeEvent<HTMLInputElement>): void => setPrimaryComments(evt.target.value)}
                        value={primaryComments}
                        inputProps={{ 'data-testid': 'primary-comments', maxLength: ORG_COMMENTS_LIMIT }}
                    />
                    <div
                        style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                        <small data-testid='primaryCommmentsHelperText' className="form-text" style={{ width: '86%', float: 'left', paddingLeft: '2%', marginBottom: 24 }}>
                            {t('ORGANIZATION_MANAGE.COMMENTS_HELPER')}</small>
                        <Typography data-testid='primaryCommentsLimit' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                            {primaryComments.length}/{ORG_COMMENTS_LIMIT}
                        </Typography>
                    </div>
                    <Typography style={{ padding: '12px 16% 12px 0px', marginTop: 24, fontWeight: 400, fontSize: 16, color: PXBColors.blue[500] }} data-testid="secondary-info-header">{t('CUSTOMER_DETAILS.SECONDARY_CONTACT_INFO')}</Typography>
                    <FormControl variant="filled" sx={{ width: '100%' }}> 
                        <InputLabel id="contactType-select-filled-label" data-testid='secondaryContactTypeId'>{t('CUSTOMER_DETAILS.CONTACT_TYPE')}</InputLabel>
                        <Select
                            labelId="contactType-simple-select-filled-label"
                            id="contactType-simple-select-filled"
                            value={secondaryContactType}
                            onChange={handleSecondaryContactTypeChange}
                            inputProps={{ 'data-testid': 'secondary-contact-type' }}
                        >
                            {contactUpdateList.map((option) => (
                                <MenuItem sx={{ textAlign: 'center' }}
                                    key={option.key} value={option.value} data-testid={option.value} >
                                    {t(option.value)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        style={{ marginTop: 24 }}
                        data-testid='secondaryEmailTestId'
                        id="secondaryemail"
                        variant={'filled'}
                        label={t('ORGANIZATION_DETAILS.SECONDARY_CONTACT_EMAIL_ADDRESS')}
                        error={secondaryEmail.length > 0 && !validEmail(secondaryEmail)}
                        helperText={secondaryEmail.length > 0 && !validEmail(secondaryEmail) ? (t('FORMS.INVALID_EMAIL_ERROR')) : ''}
                        inputProps={{ 'data-testid': 'secondary-email', maxLength: ORG_EMAIL_MAX_LENGTH }}
                        fullWidth
                        value={secondaryEmail}
                        onChange={(e): void => setSecondaryEmail(e.target.value)}
                    />
                    <TextField
                        style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                        id="secondaryFirstname"
                        data-testid='secondaryFirstnameLabelId'
                        variant={'filled'}
                        label={t('FORMS.FIRST_NAME')}
                        fullWidth
                        value={secondaryFirstName}
                        onChange={(e): void => onSecondaryFirstNameChange(e)}
                        inputProps={{ 'data-testid': 'secondaryFirstnameLabelId2', maxLength: FIRST_NAME_MAX_LENGTH }}
                    />
                    <TextField
                        style={{ marginTop: 24, width: '100%', fontSize: 16, color: PXBColors.black[500] }}
                        id="secondarylastname"
                        data-testid='secondarylastNameLabelId'
                        variant={'filled'}
                        label={t('FORMS.LAST_NAME')}
                        fullWidth
                        value={secondaryLastName}
                        onChange={(e): void => onSecondaryLastNameChange(e)}
                        inputProps={{ 'data-testid': 'secondarylastNameLabelId2', maxLength: LAST_NAME_MAX_LENGTH }}
                    />
                    <Typography style={{ padding: '12px 16px 12px 0px' }} data-testid="secondary-phone-no-header">{t('CUSTOMER_DETAILS.PHONE_NUMBERS_HEADER')}</Typography>
                    <div>
                        <Select
                            style={{ marginTop: 24, width: md ? '23%' : '33%', marginRight: '2%', textAlign: 'center' }}
                            variant={'filled'}
                            labelId="secondary-countrycode-label"
                            inputProps={{ 'data-testid': 'secondary-country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                            id="secondary-countrycode-testid"
                            defaultValue={secondarycountryCode}
                            value={secondarycountryCode}
                            onChange={(e) => onChangeSecondaryCountryCode(e)}
                        >
                            {countryList?.map((e, code) => {
                                return (
                                    <MenuItem id="country-code-list" key={code} value={e.code}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'left', fontSize: 14,
                                            lineHeight: 1.5, fontWeight: 400,
                                            overflow: 'hidden',
                                            boxSizing: 'border-box',
                                            whiteSpace: 'nowrap',
                                            minHeight: 48,
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            width: 'auto',
                                            paddingLeft: 16,
                                            paddingRight: 16
                                        }}>
                                        {'+' + e.code}
                                    </MenuItem>
                                );
                            })}
                        </Select>

                        <TextField
                            data-testid='secondaryMobileNumberTestId'
                            style={{ marginTop: 24, width: md ? '75%' : '65%' }}
                            variant={'filled'}
                            label={t('CUSTOMER_DETAILS.MOBILE_NUMBER')}
                            error={secondaryPhoneNumber.length > 0 && !validPhoneNumberLength(secondaryPhoneNumber)}
                            helperText={secondaryPhoneNumber.length > 0 && !validPhoneNumberLength(secondaryPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                            inputProps={{ 'data-testid': 'secondary-mobile-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                            fullWidth
                            value={secondaryPhoneNumber}
                            onChange={(e): void => onChangeSecondaryPhoneNumber(e)}
                        />
                    </div>
                    <div>
                        <Select
                            style={{ marginTop: 24, width: md ? '23%' : '33%', marginRight: '2%', textAlign: 'center' }}
                            variant={'filled'}
                            labelId="countrycode-label"
                            inputProps={{ 'data-testid': 'secondary-backup-country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                            id="countrycode-testid"
                            defaultValue={secondaryBackUpcountryCode}
                            value={secondaryBackUpcountryCode}
                            onChange={(e) => onChangeSecondaryBackUpCountryCode(e)}
                        >
                            {countryList?.map((e, code) => {
                                return (
                                    <MenuItem id="country-code-list" key={code} value={e.code}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'left', fontSize: 14,
                                            lineHeight: 1.5, fontWeight: 400,
                                            overflow: 'hidden',
                                            boxSizing: 'border-box',
                                            whiteSpace: 'nowrap',
                                            minHeight: 48,
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            width: 'auto',
                                            paddingLeft: 16,
                                            paddingRight: 16
                                        }}>
                                        {'+' + e.code}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <TextField
                            data-testid='secondaryOfficeNumberTestId'
                            style={{ marginTop: 24, width: md ? '75%' : '65%' }}
                            variant={'filled'}
                            label={t('CUSTOMER_DETAILS.OFFICE_NUMBER')}
                            error={secondaryBackUpPhoneNumber.length > 0 && !validPhoneNumberLength(secondaryBackUpPhoneNumber)}
                            helperText={secondaryBackUpPhoneNumber.length > 0 && !validPhoneNumberLength(secondaryBackUpPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                            inputProps={{ 'data-testid': 'secondary-office-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                            fullWidth
                            value={secondaryBackUpPhoneNumber}
                            onChange={(e): void => onChangeSecondaryBackUpPhoneNumber(e)}
                        />
                    </div>
                    <TextField
                        style={{ marginTop: 24, width: '100%', float: 'left', wordWrap: 'break-word', color: PXBColors.black[500] }}
                        id="secondarycomments"
                        data-testid='secondarycommentsId'
                        variant={'filled'}
                        label={t('LOGBOOK_DETAILS.COMMENTS')}
                        rows={2}
                        multiline
                        fullWidth
                        onChange={(evt: ChangeEvent<HTMLInputElement>): void => setSecondaryComments(evt.target.value)}
                        value={secondaryComments}
                        inputProps={{ 'data-testid': 'secondary-comments', maxLength: ORG_COMMENTS_LIMIT }}
                    />
                    <div data-testid='helperTextIdTechnician'
                        style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                        <small className="form-text" style={{ width: '86%', float: 'left', paddingLeft: '2%' }}>
                            {t('ORGANIZATION_MANAGE.COMMENTS_HELPER')}</small>
                        <Typography data-testid='secondaryCommentsLimit' style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                            {secondaryComments.length}/{ORG_COMMENTS_LIMIT}
                        </Typography>
                    </div>
                </div>
            </div>}
            {
              alertFailureDisplayState &&
               <Snackbar
                   open={true}
                   onClick={() => setAlertFailureDisplayState(false)}
                   autoHideDuration={20}
                   title={''}
                   message={alertErrorMessage}
                   action={
                       <Button color="inherit" size="small" onClick={() => setAlertFailureDisplayState(false)}>
                           {t('ACTIONS.OKAY')}
                       </Button>
                   }
                   ContentProps={{ sx: { background: PXBColors.red[500] } }}
                   anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                   sx={{ bottom: { xs: 90, sm: 0 } }}
                   style={{ height: 200, width: 200, flexGrow: 0 }}
               />
            }
        </div >
    );
};
