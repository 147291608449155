import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_SERVICEMANAGERS_LIST } from './url-constants';

type ServiceManagersListEndpointSpecifications = ApiEndpointSpecification;
export const ServiceManagersListEndpointSpecification: ServiceManagersListEndpointSpecifications = {
    url: GET_SERVICEMANAGERS_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'ServiceManagersListRequestParams',
    requestBodySchemaName: 'ServiceManagersListRequestBody',
    okResponseSchemaName: 'ServiceManagersListRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeServiceManagersList = Helpers.makeApiRequestFn<
    Types.ServiceManagersListRequestParams,
    Types.ServiceManagersListRequestBody,
    Types.ServiceManagersListOkResponse
>(ServiceManagersListEndpointSpecification);
