import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_SET_SETTING } from './url-constants';

type UserSetSettingsEndpointSpecifications = ApiEndpointSpecification;
export const UserSetSettingsEndpointSpecification: UserSetSettingsEndpointSpecifications = {
    url: USER_SET_SETTING,
    method: HttpMethod.PUT,
    requestParamsSchemaName: 'UserSetSettingsRequestParams',
    requestBodySchemaName: 'UserSetSettingsRequestBody',
    okResponseSchemaName: 'UserSetSettingsOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeUserSetSettings = Helpers.makeApiRequestFn<
    Types.UserSetSettingsRequestParams,
    Types.UserSetSettingsRequestBody,
    Types.UserSetSettingsOkResponse
>(UserSetSettingsEndpointSpecification);
