import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_ADMIN_INVITATION_APPROVAL_HISTORY } from './url-constants';

type GetAdminApprovalHistoryEndpointSpecification = ApiEndpointSpecification;
export const GetAdminApprovalHistoryEndpointSpecifications: GetAdminApprovalHistoryEndpointSpecification = {
    url: GET_ADMIN_INVITATION_APPROVAL_HISTORY,
    method: HttpMethod.GET,
    requestParamsSchemaName: 'AdminApprovalHistoryRequestParams',
    requestBodySchemaName: 'AdminApprovalHistoryRequestBody',
    okResponseSchemaName: 'AdminApprovalHistoryOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeGetAdminApprovalHistory = Helpers.makeApiRequestFn<
    Types.AdminApprovalHistoryRequestParams,
    Types.AdminApprovalHistoryRequestBody,
    Types.AdminApprovalHistoryOkResponse
>(GetAdminApprovalHistoryEndpointSpecifications);
