// eslint-disable-next-line
import React from 'react';
import { Data } from '../../types/data-providers';
import { useCustomerList } from './CustomerListProvider';
import { useAppCustomerData } from '../../components/AppDataProvider';
import { SMCustomerData } from '../../types/customer-detail';

type ContextState = Data<SMCustomerData>;

const Context = React.createContext<ContextState | null>(null);

function useCustomerData(): ContextState {
    const contextState = React.useContext(Context);
    if (contextState === null) {
        throw new Error('useCustomerData must be used within a CustomerDataProvider');
    }

    return contextState;
}

const CustomerDataProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = (props) => {
    const customerList = useCustomerList();
    const customerState = useAppCustomerData(customerList.selectedCustomer);
    return <Context.Provider value={customerState}>{props.children}</Context.Provider>;
};

const TestCustomerDataProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ value: ContextState }>>> = (props) => (
    <Context.Provider value={props.value}>{props.children}</Context.Provider>
);

export { CustomerDataProvider, useCustomerData, TestCustomerDataProvider };
