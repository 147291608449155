import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_SYSTEM_STATISTIC } from './url-constants';

type StatisticSummaryRequestEndpointSpecifications = ApiEndpointSpecification;
export const StatisticSummaryRequestEndpointSpecification: StatisticSummaryRequestEndpointSpecifications = {
    url: GET_SYSTEM_STATISTIC,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'StatisticSummaryRequestParams',
    requestBodySchemaName: 'StatisticSummaryRequestBody',
    okResponseSchemaName: 'StatisticSummaryRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeStatisticSummaryRequest = Helpers.makeApiRequestFn<
    Types.StatisticSummaryRequestParams,
    Types.StatisticSummaryRequestBody,
    Types.StatisticSummaryRequestOkResponse
>(StatisticSummaryRequestEndpointSpecification);
