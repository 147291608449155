import React, { useEffect } from "react";
import { Card, CardContent, Divider, IconButton, ListItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as PXBColors from '@brightlayer-ui/colors';
import * as MuiCore from '@mui/material';
import { EmptyState, InfoListItem } from "@brightlayer-ui/react-components";
import { Contacts, ReceiptLong, Group, ChevronRight, Person } from "@mui/icons-material";
import { OrgDetailListItem } from "../../components/OverviewList/OrgDetailListItem";
import { COLOR_GRAY, COLOR_GRAY_ICON } from "../../constants/color-codes";
import { useLanguageLocale } from "../../hooks/language-locale-hooks";
import { ActionType, SEPERATOR_COMMA, SEPERATOR_SPACE, SYMBOL_PLUS, TAB_CUSTOMER, TAB_ORG } from "../../constants";
import EditIcon from '@mui/icons-material/Edit';
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import * as MuiIcons from '@mui/icons-material';
import { AppRoute } from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import { Selectors } from "../../selectors";
import { CustomerDashboardList, CustomerListData } from "../../types/customer-detail";
import { SMOrganization, ServiceMangerOrgInfoList, ServiceOrganizationDetailData, ServiceOrganizationDetailList } from "../../types/organization";
import { useCustomerList } from "../../data/DataProviders/CustomerListProvider";
import { TooltipItem } from "../../components/TooltipItem/TooltipItem";
import { getControlledText } from "../enum/GenericFormat";


type Props = {
    userId?: string;
    onUserClick: Function;
    selectedId?: string;
    name?: string;
    onCustomerListClick: Function;
    onSLAClick: Function;
    onCustomerDetailsClick: Function;
    onOrganizationListClick: Function;
    onOrganizationDetailClick: Function;
    onEditOrganizationClicked: Function;
    onCustomerEditClick: Function;
};

const defaultCustomerDashboardList: CustomerDashboardList = {
    customerDetailList: [],
    totalCustomerCount: 0,
    totalAdminCount: 0,
    totalViewerCount: 0,
    totalSlaCount: 0
}
const defaultOrgDashboardList: ServiceOrganizationDetailList = {
    serviceOrganizationDetailList: [],
    totalCustomerCount: 0,
    adminCount: 0,
    viewerCount: 0,
    systemCount: 0,
    sLACount: 0,
    totalOrganizationCount: 0
}


export const OverviewCardDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    const { t } = useLanguageLocale();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [users, setUsers] = React.useState(t('MESSAGES.LOADING'));
    const [customerListState, setCustomerListState] = React.useState(false);
    const [orgListState, setOrgListState] = React.useState(false);
    const useCustomer = useCustomerList();
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const organization = useSelector(Selectors.Organization.organization);
    const [organizationService, setServiceOrganization] = React.useState<ServiceMangerOrgInfoList>();

    const [selectedSMOrganization, setSelectedSMOrganization] = React.useState<ServiceOrganizationDetailData>();
    const [orgList, setOrgList] = React.useState<ServiceOrganizationDetailList>();
    const [orgListData, setOrgListData] = React.useState<ServiceOrganizationDetailData>();

    const customer = useSelector(Selectors.Customer.customer);
    const [customerList, setCustomerList] = React.useState<CustomerDashboardList>();
    const [selectedCustomer, setSelectedCustomer] = React.useState<CustomerListData>();

    const updateAdminUserTitle = (adminCount: number, viewerCount: number): void => {
        const adminsText = (adminCount > 1 ? adminCount + ' ' + t('DASHBOARD.USERS_ADMIN_S') : adminCount + ' ' + t('DASHBOARD.USERS_ADMIN'));
        const seperatorText = SEPERATOR_COMMA;
        const viewersText = (viewerCount > 1 ? viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER_S') : viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER'));
        setUsers(adminsText + seperatorText + viewersText);
    }

    const goToUserDetails = (): void => {
        props.onUserClick();
    };


    const goToCustomerList = (): void => {
        props.onCustomerListClick();
    };
    const goToSLAList = (): void => {
        props.onSLAClick();
    }

    const goToMyOrganization = (): void => {
        props.onOrganizationListClick();
    };

    const goToOrganizationDetail = (): void => {
        props.onOrganizationDetailClick();
    };

    const goToCustomerDetails = (): void => {
        props.onCustomerDetailsClick();
    };

    const editCustomerProfile = (actionType: ActionType): void => {
        //ToDO- customer edit navigation
        props.onCustomerEditClick(actionType);
    }
    const editOrganization = (): void => {
        props.onEditOrganizationClicked();
    }

    const viewAllEventsAppRoute: AppRoute = {
        type: 'CustomerDetails',
    };

    useEffect(() => {
        if (customer.dashboardCustomerListLoading) {
            setCustomerList(defaultCustomerDashboardList);
            setCustomerListState(false);
        }
        else if (!customer.dashboardCustomerListLoading && customer.dashboardCustomerListSuccess) {
            setCustomerList(customer.dashboardCustomerList);
            setCustomerListState(true);

            if (props.selectedId) {
                if (customer.dashboardCustomerList.customerDetailList.length > 0) {
                    let customerFilteredList: CustomerListData[] = customer.dashboardCustomerList.customerDetailList.filter(x => x.id === props.selectedId);
                    if (customerFilteredList.length > 0) {
                        let customerData: CustomerListData = customerFilteredList[0];
                        setSelectedCustomer(customerData);
                        if (dashboard.dashboardTabSelection === TAB_CUSTOMER)
                            updateAdminUserTitle(customerData ? customerData?.adminCount : 0, customerData.viewerCount);
                    }
                }
            }
            else {
                setSelectedCustomer(undefined);
                if (dashboard.dashboardTabSelection === TAB_CUSTOMER)
                    if (customer.dashboardCustomerList?.totalAdminCount || customer.dashboardCustomerList?.totalViewerCount)
                        updateAdminUserTitle(customer.dashboardCustomerList?.totalAdminCount, customer.dashboardCustomerList?.totalViewerCount);
                    else
                        updateAdminUserTitle(0, 0);
            }
        }
        else if (!customer.dashboardCustomerListLoading && customer.dashboardCustomerListFail) {
            setCustomerListState(false);
            setSelectedCustomer(undefined);
            updateAdminUserTitle(0, 0);
            setCustomerList(defaultCustomerDashboardList);
        }
    }, [customer.dashboardCustomerListLoading, customer.dashboardCustomerListSuccess, customer.dashboardCustomerListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    //new code
    useEffect(() => {
        if (organization.serviceManagerOrgListLoading) {
            setOrgList(defaultOrgDashboardList);
            setOrgListState(false);
            setSelectedSMOrganization(undefined);
        }
        else if (!organization.serviceManagerOrgListLoading && organization.serviceManagerOrgListSuccess) {
            if (organization.serviceManagerOrgList.serviceOrganizationDetailList.length > 0) {
                setSelectedSMOrganization(organization.serviceManagerOrgList.serviceOrganizationDetailList[0]);
            }
            setOrgListState(true);
            setOrgList(organization.serviceManagerOrgList);

        }
        else if (!organization.serviceManagerOrgListLoading && organization.serviceManagerOrgListFail) {
            setOrgListState(false);
            setSelectedSMOrganization(undefined);
            setOrgList(defaultOrgDashboardList);
        }
        else {

        }
    }, [organization.serviceManagerOrgListLoading, organization.serviceManagerOrgListSuccess, organization.serviceManagerOrgListFail]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Card style={{ flex: '1 1 0px', overflow: 'auto', zIndex: 5 }} data-testid="customer-detail-container">
            {dashboard.dashboardTabSelection === TAB_CUSTOMER &&
                <>
                    {!customerListState && <EmptyState style={{ flex: 1, backgroundColor: PXBColors.white[50] }}
                        icon={<MuiCore.CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />
                    }

                    {(customerListState) && <div style={{ display: 'absolute', width: '100%', height: 'auto' }}>

                        <ListItem style={{
                            width: '100%',
                        }}
                            secondaryAction={
                                <IconButton id="customer-edit" data-testid="customer-edit"
                                    style={{ top: -20, right: -10, marginLeft: "auto", justifyContent: 'start' }}
                                    onClick={() => editCustomerProfile(ActionType.EDIT)}
                                >
                                    {props.selectedId && <EditIcon />}
                                </IconButton>
                            }>

                            <CardContent style={{ width: '100%' }}>
                                <TooltipItem
                                    title={props.name ? props.name : ""}
                                    isTopPlacement={true}
                                    lenghtLimit={20}
                                    component={<Typography
                                        variant="h5"
                                        id="cust-name" data-testid="cust-name"
                                    >
                                        {getControlledText(props.name ? props.name : t('DASHBOARD.ALL_CUSTOMER'), 20)}
                                    </Typography>}>
                                </TooltipItem>
                                <Typography variant="body2" id="device-name" data-testid="customer-place" >{selectedCustomer ? selectedCustomer?.city + ', ' + selectedCustomer?.country : ''}</Typography>
                            </CardContent>

                        </ListItem>

                        {props.selectedId && <OrgDetailListItem
                            title1={selectedCustomer ? selectedCustomer?.primaryFirstName + ' ' + selectedCustomer?.primaryLastName : ''}
                            title2={selectedCustomer ? '+' + selectedCustomer?.primaryCountryCode + ' ' + selectedCustomer?.primaryPhone : ''}
                            title3={selectedCustomer ? selectedCustomer?.primaryEmail : ''}
                            subtitle={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT')}
                            icon={<Contacts style={{ color: COLOR_GRAY_ICON }} />}
                            iconAlignment={'center'}
                            displayAction={false}
                            dividerDisable={true}
                        />}
                        {props.selectedId && <Divider />}

                        {!props.selectedId && <InfoListItem id='customer-number' data-testid='customer-number' title={customerList ? customerList.totalCustomerCount : ''} subtitle={t('DASHBOARD.CUSTOMERS')}
                            icon={<Person style={{ color: COLOR_GRAY_ICON }} />}
                            iconAlign={'left'}
                            divider={'full'}
                            rightComponent={<IconButton edge="end" id="list-item-next-icon" data-testid="list-item-next-icon" style={{ marginLeft: "auto" }}
                                onClick={() => goToCustomerList()}
                            >
                                <ChevronRight style={{ color: COLOR_GRAY }} />
                            </IconButton>} placeholder={undefined} />}
                        <InfoListItem data-testid='service-number' title={selectedCustomer ? selectedCustomer?.sLADetails.length : customerList?.totalSlaCount} subtitle={t('DASHBOARD.SERVICE_LEVEL_AGREEMENT') + (selectedCustomer ? (selectedCustomer?.sLADetails.length > 1) ? t('COMMON.S') : '' : t('COMMON.S'))}
                            icon={<ReceiptLong style={{ color: COLOR_GRAY_ICON }} />}
                            iconAlign={'left'}
                            divider={'full'}
                            rightComponent={<IconButton edge="end" id="list-item-next-icon" data-testid="list-item-next-icon" style={{ marginLeft: "auto" }}
                                onClick={() => goToSLAList()}
                            >
                                <ChevronRight style={{ color: COLOR_GRAY }} />
                            </IconButton>} placeholder={undefined} />
                        <OrgDetailListItem
                            title1={users}
                            title2={''}
                            title3={''}
                            subtitle={t('ORGANIZATION_DETAILS.USERS')}
                            icon={<Group style={{ color: COLOR_GRAY_ICON }} />}
                            iconAlignment={'center'}
                            displayAction={false}  // TODO- Make it true when naviation is avail for SM for userdetails
                            OnItemSelected={() => { }}
                            dividerDisable={true}
                        />
                        <Divider />
                        {props.selectedId &&
                            // //TODO- later when nav required
                            //  <ActionRow data-testid='view-customer-link' label={t('LABELS.VIEW_DETAILS')} appRoute={viewAllEventsAppRoute} />

                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 16 }}
                                    onClick={() => goToCustomerDetails()}>
                                    <Typography
                                        variant="subtitle2"
                                        data-testid="view-all"
                                        style={{
                                            color: PXBColors.blue[400],
                                        }}
                                        id="project-admin"
                                    >
                                        {t('LABELS.VIEW_DETAILS')}
                                    </Typography>
                                    <MuiCore.IconButton style={{ alignItems: 'right', width: '8%', padding: '0' }} >
                                        {' '}
                                        <MuiIcons.ChevronRight />{' '}
                                    </MuiCore.IconButton>
                                </div>
                            </>
                        }
                    </div>}
                </>
            }

            {dashboard.dashboardTabSelection === TAB_ORG &&
                <>
                    {(!orgListState) && <EmptyState style={{ flex: 1, backgroundColor: PXBColors.white[50] }}
                        icon={<MuiCore.CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />
                    }

                    {orgListState &&
                        <div style={{ display: 'absolute', width: '100%', height: 'auto' }}>

                            <ListItem style={{
                                width: '100%',
                            }}
                                secondaryAction={
                                    <IconButton id="customer-edit" data-testid="customer-edit"
                                        style={{ top: -20, right: -10, marginLeft: "auto", justifyContent: 'start' }}
                                        onClick={(): void => editOrganization()}
                                    >
                                        {selectedSMOrganization?.id && <EditIcon />}
                                    </IconButton>
                                }>

                                <CardContent style={{ width: '100%' }}>
                                    <TooltipItem
                                        title={props?.name ? props.name : ""}
                                        isTopPlacement={true}
                                        lenghtLimit={20}
                                        component={<Typography
                                            variant="h5"
                                            id="dash-sm-org-title" data-testid="dash-sm-org-title"
                                        >
                                            {getControlledText(props?.name ? props.name : t('DASHBOARD.ALL_ORGANIZATION'), 20)}
                                        </Typography>}>
                                    </TooltipItem>
                                    <Typography variant="body2" id="dash-sm-detail" data-testid="dash-sm-detail" >{selectedSMOrganization ? (selectedSMOrganization.city ? selectedSMOrganization.city + SEPERATOR_COMMA + selectedSMOrganization.country : selectedSMOrganization.country) : ''}</Typography>
                                </CardContent>

                            </ListItem>

                            {selectedSMOrganization?.id && <OrgDetailListItem

                                title1={selectedSMOrganization ? (selectedSMOrganization.primaryFirstName ? selectedSMOrganization.primaryFirstName : "") + (selectedSMOrganization.primaryLastName ? SEPERATOR_SPACE + selectedSMOrganization.primaryLastName : '') : ''}
                                title2={selectedSMOrganization ? (selectedSMOrganization.countryCode ? SYMBOL_PLUS + selectedSMOrganization.countryCode : "") + (selectedSMOrganization.phone ? SEPERATOR_SPACE + selectedSMOrganization.phone : '') : ''}
                                title3={selectedSMOrganization ? selectedSMOrganization?.email : ''}
                                subtitle={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT')}
                                icon={<Contacts style={{ color: COLOR_GRAY_ICON }} />}
                                iconAlignment={'center'}
                                displayAction={false}
                                dividerDisable={true}
                            />}
                            {selectedSMOrganization?.id && <Divider />}

                            {!selectedSMOrganization?.id && <InfoListItem data-testid='organization-count' title={orgList ? orgList?.totalOrganizationCount : ''} subtitle={orgList ? (orgList?.totalOrganizationCount > 1 ? t('ENTITY_TYPE.ORGANIZATION') + t('COMMON.S') : t('ENTITY_TYPE.ORGANIZATION')) : t('ENTITY_TYPE.ORGANIZATION')}
                                icon={<CorporateFareIcon style={{ color: COLOR_GRAY_ICON }} />}
                                iconAlign={'left'}
                                divider={'full'}
                                rightComponent={<IconButton edge="end" id="list-item-next-icon" data-testid="list-item-next-icon" style={{ marginLeft: "auto" }}
                                    onClick={() => goToMyOrganization()}
                                >
                                    <ChevronRight style={{ color: COLOR_GRAY }} />
                                </IconButton>} placeholder={undefined} />}

                            {<InfoListItem data-testid='customer-number' title={selectedSMOrganization ? selectedSMOrganization?.customerIdList.length : orgList?.totalCustomerCount} subtitle={t('DASHBOARD.CUSTOMERS')}
                                icon={<Person style={{ color: COLOR_GRAY_ICON }} />}
                                iconAlign={'left'}
                                divider={'full'}
                                rightComponent={<IconButton edge="end" id="list-item-next-icon" data-testid="list-item-next-icon" style={{ marginLeft: "auto" }}
                                    onClick={() => goToCustomerList()}
                                >
                                    <ChevronRight style={{ color: COLOR_GRAY }} />
                                </IconButton>} placeholder={undefined} />}
                            <InfoListItem data-testid='service-number' title={selectedSMOrganization ? selectedSMOrganization?.sLACount : orgList?.sLACount} subtitle={t('DASHBOARD.SERVICE_LEVEL_AGREEMENT') + (selectedSMOrganization ? (selectedSMOrganization?.sLACount > 1) ? t('COMMON.S') : '' : t('COMMON.S'))}
                                icon={<ReceiptLong style={{ color: COLOR_GRAY_ICON }} />}
                                iconAlign={'left'}
                                divider={'full'}
                                rightComponent={<IconButton edge="end" id="list-item-next-icon" data-testid="list-item-next-icon" style={{ marginLeft: "auto" }}
                                    onClick={() => goToSLAList()}
                                >
                                    <ChevronRight style={{ color: COLOR_GRAY }} />
                                </IconButton>} placeholder={undefined} />
                            <OrgDetailListItem
                                title1={selectedSMOrganization ? (selectedSMOrganization.adminCount > 1 ? selectedSMOrganization.adminCount + ' ' + t('DASHBOARD.USERS_ADMIN_S') : selectedSMOrganization.adminCount + ' ' + t('DASHBOARD.USERS_ADMIN'))
                                    + SEPERATOR_COMMA +
                                    (selectedSMOrganization.viewerCount > 1 ? selectedSMOrganization.viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER_S') : selectedSMOrganization.viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER')) :
                                    orgList ? (orgList.adminCount > 1 ? orgList.adminCount + ' ' + t('DASHBOARD.USERS_ADMIN_S') : orgList.adminCount + ' ' + t('DASHBOARD.USERS_ADMIN'))
                                        + SEPERATOR_COMMA +
                                        (orgList.viewerCount > 1 ? orgList.viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER_S') : orgList.viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER')) : ''
                                }
                                title2={''}
                                title3={''}
                                subtitle={t('ORGANIZATION_DETAILS.USERS')}
                                icon={<Group style={{ color: COLOR_GRAY_ICON }} />}
                                iconAlignment={'center'}
                                displayAction={selectedSMOrganization ? true : false} // TODO- Make it true when naviation is avail for SM for userdetails
                                OnItemSelected={() => selectedSMOrganization ? goToUserDetails() : {}}
                                dividerDisable={true}
                            />
                            <Divider />
                            {selectedSMOrganization?.id &&
                                // //TODO- later when nav required
                                //  <ActionRow data-testid='view-customer-link' label={t('LABELS.VIEW_DETAILS')} appRoute={viewAllEventsAppRoute} />

                                <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 16 }}>
                                        <Typography
                                            variant="subtitle2"
                                            data-testid="view-all"
                                            style={{
                                                color: PXBColors.blue[400],
                                            }}
                                            id="project-admin"
                                        >
                                            {t('LABELS.VIEW_DETAILS')}
                                        </Typography>
                                        <MuiCore.IconButton style={{ alignItems: 'right', width: '8%', padding: '0' }}
                                            onClick={(): void => goToOrganizationDetail()}
                                        >
                                            {' '}
                                            <MuiIcons.ChevronRight />{' '}
                                        </MuiCore.IconButton>
                                    </div>
                                </>
                            }
                        </div>
                    }

                </>
            }

        </Card >
    );

};
