import StatusBlock from '../../assets/images/status_block.svg';
import StatusWarning from '../../assets/images/status_warning.svg';
import StatusOffline from '../../assets/images/status_offline.svg';
import StatusReady from '../../assets/images/status_ready.svg';
import StatusError from '../../assets/images/status_error.svg';
import StatusDT from '../../assets/images/status_dt.svg';
import StatusEvent from '../../assets/images/status_event.svg';

import StatusDisableBlock from '../../assets/images/disable-status/status_disable_block.svg';
import StatusDisableWarning from '../../assets/images/disable-status/status_disable_warning.svg';
import StatusDisableOffline from '../../assets/images/disable-status/status_disable_offline.svg';
import StatusDisableReady from '../../assets/images/disable-status/status_disable_ready.svg';
import StatusDisableError from '../../assets/images/disable-status/status_disable_error.svg';
import StatusDisableDT from '../../assets/images/disable-status/status_disable_dt.svg';
import StatusDisableBlank from '../../assets/images/disable-status/status_disable_blank.svg';
import StatusDisableEvent from '../../assets/images/disable-status/status_disable_event.svg';
import StatusDisableErrorGrey from '../../assets/images/disable-status/status_disable_error_grey.svg';
import StatusDisableWarningGrey from '../../assets/images/disable-status/status_disable_warning_grey.svg';
import StatusDisableBlockGrey from '../../assets/images/disable-status/status_disable_block_grey.svg';
import { SelectionData, SelectionList } from '../../components/UserDetails/SelectionListData';
import { ItemList } from "../../types/logbook-type";
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import luminaryReady from '../../assets/images/lumReady.svg';
import luminaryError from '../../assets/images/lumError.svg';

export type DeviceState = {
    statusTitle: string;
    statusColor: string;
    statusImage: any;
}

export type ENUMData = {
    key: string;
    value: string;
};

export type ENUMList = Array<ENUMData>;

// Device Status -------

export enum DEVICE_STATUS {
    STATUS_READY = 0,
    STATUS_WARNING = 1,
    STATUS_ERROR = 2,
    STATUS_BLOCK = 3,
    STATUS_OFFLINE = 4,
    STATUS_DT = 5,
    STATUS_OFFLINE_DISABLE = 6,
    STATUS_EMPTY = 7,//ToDo - Handle as per API availability
}

export enum USER_ACTIVE_STATUS {
    ACTIVE = 0,
    INACTIVE = 1
}

export const getUserStatusState = (status: number, isActive: boolean = true): DeviceState => {
    switch (status) {
        case USER_ACTIVE_STATUS.ACTIVE:
            return {
                statusTitle: 'DEVICE_DETAILS.ACTIVE',
                statusColor: '#39B620',
                statusImage: isActive ? StatusReady : StatusDisableReady,
            };
        case USER_ACTIVE_STATUS.INACTIVE:
            return {
                statusTitle: 'DEVICE_DETAILS.INACTIVE',
                statusColor: '#CA3C3D',
                statusImage: isActive ? StatusOffline : StatusDisableOffline,
            };
        default:
            return {
                statusTitle: 'DEVICE_DETAILS.INACTIVE',
                statusColor: '#983FEF',
                statusImage: isActive ? StatusOffline : StatusDisableBlank,
            };
    }
};

export const getDeviceStatusState = (status: number, isActive: boolean = true): DeviceState => {
    switch (status) {
        case DEVICE_STATUS.STATUS_READY:
            return {
                statusTitle: 'DEVICE_DETAILS.READY',
                statusColor: '#39B620',
                statusImage: isActive ? StatusReady : StatusDisableReady,
            };
        case DEVICE_STATUS.STATUS_WARNING:
            return {
                statusTitle: 'DEVICE_DETAILS.WARNING',
                statusColor: '#F0CB2F',
                statusImage: isActive ? StatusWarning : StatusDisableWarning,
            };
        case DEVICE_STATUS.STATUS_BLOCK:
            return {
                statusTitle: 'DEVICE_DETAILS.BLOCK',
                statusColor: '#F47721',
                statusImage: isActive ? StatusBlock : StatusDisableBlock,
            };
        case DEVICE_STATUS.STATUS_OFFLINE:
            return {
                statusTitle: 'DEVICE_DETAILS.OFFLINE',
                statusColor: '#983FEF',
                statusImage: isActive ? StatusOffline : StatusDisableOffline,
            };
        case DEVICE_STATUS.STATUS_ERROR:
            return {
                statusTitle: 'DEVICE_DETAILS.ERROR',
                statusColor: '#CA3C3D',
                statusImage: isActive ? StatusError : StatusDisableError,
            };
        case DEVICE_STATUS.STATUS_DT:
            return {
                statusTitle: 'STATUS.STATUS_DT',
                statusColor: '#0088F2',
                statusImage: isActive ? StatusDT : StatusDisableDT,
            };
        case DEVICE_STATUS.STATUS_OFFLINE_DISABLE:
            return {
                statusTitle: 'DEVICE_DETAILS.OFFLINE',
                statusColor: '#727E84',
                statusImage: StatusDisableOffline,
            };
        case DEVICE_STATUS.STATUS_EMPTY:
            return {
                statusTitle: 'DASHBOARD.STATUS_EMPTY',
                statusColor: '#424E54',
                statusImage: StatusDisableOffline,
            };
        default:
            return {
                statusTitle: 'DEVICE_DETAILS.OFFLINE',
                statusColor: '#983FEF',
                statusImage: isActive ? StatusOffline : StatusDisableBlank,
            };
    }
};

export const deviceStatusEnum: ItemList = [
    {
        id: '' + DEVICE_STATUS.STATUS_OFFLINE,
        name: "Offline",
    },
    {
        id: '' + DEVICE_STATUS.STATUS_BLOCK,
        name: "Blocked",
    },
    {
        id: '' + DEVICE_STATUS.STATUS_ERROR,
        name: "Error",
    },
    {
        id: '' + DEVICE_STATUS.STATUS_WARNING,
        name: "Warning",
    },
    {
        id: '' + DEVICE_STATUS.STATUS_READY,
        name: "Ready",
    },
    {
        id: '' + DEVICE_STATUS.STATUS_DT,
        name: "In Test",
    },
];

export const buildingStatusEnum: ItemList = [
    {
        id: '' + DEVICE_STATUS.STATUS_OFFLINE,
        name: 'DEVICE_DETAILS.OFFLINE'
    },
    {
        id: '' + DEVICE_STATUS.STATUS_BLOCK,
        name: 'DEVICE_DETAILS.BLOCK',
    },
    {
        id: '' + DEVICE_STATUS.STATUS_ERROR,
        name: 'DEVICE_DETAILS.ERROR'
    },
    {
        id: '' + DEVICE_STATUS.STATUS_WARNING,
        name: 'DEVICE_DETAILS.WARNING',
    },
    {
        id: '' + DEVICE_STATUS.STATUS_READY,
        name: 'DEVICE_DETAILS.READY',
    },
    {
        id: '' + DEVICE_STATUS.STATUS_EMPTY,
        name: 'DASHBOARD.STATUS_EMPTY',
    },

];

// Logbook sevirity

// Device Status -------

export enum LOGBOOK_SEVERITY_STATUS {
    STATUS_EVENT = 0,
    STATUS_WARNING = 1,
    STATUS_ERROR = 2,
    STATUS_BLOCK = 3,
    STATUS_EMPTY = -1,
}

export const logStatusEnum: ItemList = [
    {
        id: '' + LOGBOOK_SEVERITY_STATUS.STATUS_EVENT,
        name: "Event",
        image: StatusDisableEvent
    },
    {
        id: '' + LOGBOOK_SEVERITY_STATUS.STATUS_ERROR,
        name: "Error",
        image: StatusDisableErrorGrey
    },
    {
        id: '' + LOGBOOK_SEVERITY_STATUS.STATUS_WARNING,
        name: "Warning",
        image: StatusDisableWarningGrey
    },
    {
        id: '' + LOGBOOK_SEVERITY_STATUS.STATUS_BLOCK,
        name: "Blocked",
        image: StatusDisableBlockGrey
    },
];

export const getLogbookSeverityStatus = (status: number, isActive: boolean = true): DeviceState => {
    switch (status) {
        case LOGBOOK_SEVERITY_STATUS.STATUS_EVENT:
            return {
                statusTitle: 'DEVICE_DETAILS.EVENT',
                statusColor: '#0088F2',
                statusImage: isActive ? StatusEvent : StatusEvent,
            };
        case LOGBOOK_SEVERITY_STATUS.STATUS_WARNING:
            return {
                statusTitle: 'DEVICE_DETAILS.WARNING',
                statusColor: '#F0CB2F',
                statusImage: isActive ? StatusWarning : StatusDisableWarning,
            };
        case LOGBOOK_SEVERITY_STATUS.STATUS_ERROR:
            return {
                statusTitle: 'DEVICE_DETAILS.ERROR',
                statusColor: '#CA3C3D',
                statusImage: isActive ? StatusError : StatusDisableError,
            };
        case LOGBOOK_SEVERITY_STATUS.STATUS_BLOCK:
            return {
                statusTitle: 'DEVICE_DETAILS.BLOCK',
                statusColor: '#F47721',
                statusImage: isActive ? StatusBlock : StatusDisableBlock,
            };
        case LOGBOOK_SEVERITY_STATUS.STATUS_EMPTY:
            return {
                statusTitle: 'Loading...',
                statusColor: '#F47721',
                statusImage: isActive ? StatusBlock : StatusDisableBlock,
            };
        default:
            return {
                statusTitle: 'DEVICE_DETAILS.EVENT',
                statusColor: '#0088F2',
                statusImage: isActive ? StatusDisableBlank : StatusDisableBlank,
            };
    }
};


export const getLogbookAppBarState = (status: number): ToolbarColor => {
    switch (status) {
        case LOGBOOK_SEVERITY_STATUS.STATUS_WARNING:
            {
                return ToolbarColor.Warning;
            }
        case LOGBOOK_SEVERITY_STATUS.STATUS_ERROR:
            {
                return ToolbarColor.Error;
            }
        case LOGBOOK_SEVERITY_STATUS.STATUS_EVENT:
            {
                return ToolbarColor.Default;
            }
        default:
            {
                return ToolbarColor.Default;
            }
    }
};


// Luminary Device Status

export enum LUMINARY_STATUS {
    LUMINARY_STATUS_READY = 0,
    LUMINARY_STATUS_WARNING = 1,
    LUMINARY_STATUS_ERROR = 2,
    LUMINARY_STATUS_BLOCK = 3,
    LUMINARY_STATUS_OFFLINE = 4,
    LUMINARY_STATUS_DT = 5,
    LUMINARY_STATUS_OFFLINE_DISABLE = 6,
}

export const getLuminaryStatusState = (status: number, isActive: boolean = true): DeviceState => {
    switch (status) {
        case LUMINARY_STATUS.LUMINARY_STATUS_READY:
            return {
                statusTitle: 'DEVICE_DETAILS.READY',
                statusColor: '#39B620',
                statusImage: luminaryReady,
            };
        case LUMINARY_STATUS.LUMINARY_STATUS_WARNING:
            return {
                statusTitle: 'DEVICE_DETAILS.WARNING',
                statusColor: '#F0CB2F',
                statusImage: isActive ? StatusWarning : StatusDisableWarning,
            };
        case LUMINARY_STATUS.LUMINARY_STATUS_BLOCK:
            return {
                statusTitle: 'DEVICE_DETAILS.BLOCK',
                statusColor: '#F47721',
                statusImage: isActive ? StatusBlock : StatusDisableBlock,
            };
        case LUMINARY_STATUS.LUMINARY_STATUS_OFFLINE:
            return {
                statusTitle: 'DEVICE_DETAILS.OFFLINE',
                statusColor: '#983FEF',
                statusImage: isActive ? StatusOffline : StatusDisableOffline,
            };
        case LUMINARY_STATUS.LUMINARY_STATUS_ERROR:
            return {
                statusTitle: 'DEVICE_DETAILS.ERROR',
                statusColor: '#CA3C3D',
                statusImage: luminaryError,
            };
        case LUMINARY_STATUS.LUMINARY_STATUS_DT:
            return {
                statusTitle: 'STATUS.STATUS_DT',
                statusColor: '#0088F2',
                statusImage: isActive ? StatusDT : StatusDisableDT,
            };
        case LUMINARY_STATUS.LUMINARY_STATUS_OFFLINE_DISABLE:
            return {
                statusTitle: 'DEVICE_DETAILS.OFFLINE',
                statusColor: '#727E84',
                statusImage: StatusDisableOffline,
            };
        default:
            return {
                statusTitle: 'DEVICE_DETAILS.OFFLINE',
                statusColor: '#983FEF',
                statusImage: isActive ? StatusOffline : StatusDisableBlank,
            };
    }
};

export const luminaryStatusEnum: ItemList = [
    {
        id: '' + LUMINARY_STATUS.LUMINARY_STATUS_OFFLINE,
        name: "Offline",
    },
    {
        id: '' + LUMINARY_STATUS.LUMINARY_STATUS_BLOCK,
        name: "Blocked",
    },
    {
        id: '' + LUMINARY_STATUS.LUMINARY_STATUS_ERROR,
        name: "Error",
    },
    {
        id: '' + LUMINARY_STATUS.LUMINARY_STATUS_WARNING,
        name: "Warning",
    },
    {
        id: '' + LUMINARY_STATUS.LUMINARY_STATUS_READY,
        name: "Ready",
    },
    {
        id: '' + LUMINARY_STATUS.LUMINARY_STATUS_DT,
        name: "In DT",
    },
];

//Technician status    ------
const TECHNICIAN_ACTIVE_STATUS: number = 0;
const TECHNICIAN_INACTIVE_STATUS: number = 1;

export const getTechnicianStatus = (type: number): string => {
    switch (type) {
        case TECHNICIAN_ACTIVE_STATUS:
            return 'Active';
        case TECHNICIAN_INACTIVE_STATUS:
            return 'InActive';
        default:
            return '';
    }
};

// Device Type -------

const DEVICE_TYPE_DGS: number = 0;
const DEVICE_TYPE_AT: number = 1;
const DEVICE_TYPE_ZBS: number = 2;
const DEVICE_TYPE_CGLINE: number = 3;
const DEVICE_TYPE_WIRELESS: number = 4;
const DEVICE_TYPE_LPSTAR: number = 5;
const DEVICE_TYPE_LPSMART: number = 6;

export const getDeviceType = (type: number): string => {
    switch (type) {
        case DEVICE_TYPE_DGS:
            return 'DG-S';
        case DEVICE_TYPE_AT:
            return 'AT+';
        case DEVICE_TYPE_ZBS:
            return 'ZBS';
        case DEVICE_TYPE_CGLINE:
            return 'CGLINE+';
        case DEVICE_TYPE_WIRELESS:
            return 'WIRELESS';
        case DEVICE_TYPE_LPSTAR:
            return 'LP-STAR';
        case DEVICE_TYPE_LPSMART:
            return 'LP-SMART';
        default:
            return '';
    }
};

export const deviceTypeEnum: ItemList = [
    {
        id: '' + DEVICE_TYPE_DGS,
        name: getDeviceType(DEVICE_TYPE_DGS)
    },
    {
        id: '' + DEVICE_TYPE_AT,
        name: getDeviceType(DEVICE_TYPE_AT)
    },
    {
        id: '' + DEVICE_TYPE_ZBS,
        name: getDeviceType(DEVICE_TYPE_ZBS)
    },
    {
        id: '' + DEVICE_TYPE_CGLINE,
        name: getDeviceType(DEVICE_TYPE_CGLINE)
    },
    {
        id: '' + DEVICE_TYPE_WIRELESS,
        name: getDeviceType(DEVICE_TYPE_WIRELESS)
    },
    {
        id: '' + DEVICE_TYPE_LPSTAR,
        name: getDeviceType(DEVICE_TYPE_LPSTAR)
    },
    {
        id: '' + DEVICE_TYPE_LPSMART,
        name: getDeviceType(DEVICE_TYPE_LPSMART)
    },
];

export const technicianStatusEnum: ItemList = [
    {
        id: '' + TECHNICIAN_ACTIVE_STATUS,
        name: getTechnicianStatus(TECHNICIAN_ACTIVE_STATUS)
    },
    {
        id: '' + TECHNICIAN_INACTIVE_STATUS,
        name: getTechnicianStatus(TECHNICIAN_INACTIVE_STATUS)
    }
];

export enum GATEWAY_CONNECTION_TYPE {
    TYPE_MODBUS = 0,
    TYPE_RS485 = 1,
    TYPE_ETHERNET = 2,
    TYPE_MQTT = 3,
    TYPE_WIREPASS = 4,
    TYPE_BLE = 5,
    TYPE_WIFI = 6,
    TYPE_CELLULAR = 7,
}

export const getGatewayConnectionType = (connectionType: number): string => {
    switch (connectionType) {
        case GATEWAY_CONNECTION_TYPE.TYPE_MODBUS:
            return 'DEVICE_DETAILS.CONNECTION_TYPE_MODBUS';
        case GATEWAY_CONNECTION_TYPE.TYPE_RS485:
            return 'DEVICE_DETAILS.CONNECTION_TYPE_RS485';
        case GATEWAY_CONNECTION_TYPE.TYPE_ETHERNET:
            return 'DEVICE_DETAILS.CONNECTION_TYPE_ETHERNET';
        case GATEWAY_CONNECTION_TYPE.TYPE_MQTT:
            return 'DEVICE_DETAILS.CONNECTION_TYPE_MQTT';
        case GATEWAY_CONNECTION_TYPE.TYPE_WIREPASS:
            return 'DEVICE_DETAILS.CONNECTION_TYPE_WIREPASS';
        case GATEWAY_CONNECTION_TYPE.TYPE_BLE:
            return 'DEVICE_DETAILS.CONNECTION_TYPE_BLE';
        case GATEWAY_CONNECTION_TYPE.TYPE_WIFI:
            return 'DEVICE_DETAILS.CONNECTION_TYPE_WIFI';
        case GATEWAY_CONNECTION_TYPE.TYPE_CELLULAR:
            return 'DEVICE_DETAILS.CONNECTION_TYPE_CELLULAR';
        default: return ''
    }
}

// Entity type

export enum ENTITY_TYPE {
    USER = 1,
    ORGANIZATION = 2,
    BUILDING = 3,
    SYSTEM = 4,
    DEVICE = 5,
    DEVICE_TYPE = 6,
    LUMINARY = 7,
    GATEWAY = 8,
    MASTER_ROLE = 9,
    ORGANIZATION_STATISTIC_DETAIL = 11,
    PLATFORM = 23
}

export const getEntityType = (entityType: number): string => {
    switch (entityType) {
        case ENTITY_TYPE.USER:
            return 'ENTITY_TYPE.USER';
        case ENTITY_TYPE.ORGANIZATION:
            return 'ENTITY_TYPE.ORGANIZATION';
        case ENTITY_TYPE.BUILDING:
            return 'ENTITY_TYPE.BUILDING';
        case ENTITY_TYPE.SYSTEM:
            return 'ENTITY_TYPE.SYSTEM';
        case ENTITY_TYPE.DEVICE:
            return 'ENTITY_TYPE.DEVICE';
        case ENTITY_TYPE.DEVICE_TYPE:
            return 'ENTITY_TYPE.DEVICE_TYPE';
        case ENTITY_TYPE.LUMINARY:
            return 'ENTITY_TYPE.LUMINARY';
        case ENTITY_TYPE.GATEWAY:
            return 'ENTITY_TYPE.GATEWAY';
        case ENTITY_TYPE.MASTER_ROLE:
            return 'ENTITY_TYPE.MASTER_ROLE';
        case ENTITY_TYPE.ORGANIZATION_STATISTIC_DETAIL:
            return 'ENTITY_TYPE.ORGANIZATION_STATISTIC_DETAIL';
        case ENTITY_TYPE.PLATFORM:
            return 'ENTITY_TYPE.PLATFORM';
        default: return ''
    }
}

//  TODO - Remove on next commit User role ENUM old
// export enum ACCESS_ROLE_TYPE {
//     ORGANIZATION_ADMIN = 1,  // EL_ORG_ADMIN
//     ORGANIZATION_VIEWER = 2,  // EL_ORG_VIEWER
//     BUILDING_ADMIN = 3,  // EF_BLDG_ADMIN_ORG_VIEWER
//     BUILDING_VIEWER = 4,  // EL_BLDG_VIEWER
//     SYSTEM_ADMIN = 5, // EF_SYSTEM_ADMIN_GATEWAY_VIEWER
//     SYSTEM_VIEWER = 6, // EF_SYSTEM_VIEWER
//     PROJECT_OWNER = 7, // PROJECT_OWNER
//     LIMITED = 8,  // LIMITED
//     TECHNICIAN = 14, //TECHNICIAN
// }

//New User Role Type for RBAC
export enum USER_ROLE_TYPE {
    PROJECT_OWNER = 0,
    EL_ORG_ADMIN = 1,
    EL_ORG_VIEWER = 2,
    EL_BLDG_VIEWER = 3,
    EF_BLDG_ADMIN_ORG_VIEWER = 4,
    EF_BLDG_ADMIN_ORG_LIMITED = 5,
    EF_GATEWAY_ADMIN_BLDG_LIMITED = 6,
    EF_SYSTEM_ADMIN_GATEWAY_VIEWER = 7,
    EF_SYSTEM_ADMIN_GATEWAY_LIMITED = 8,
    EF_SYSTEM_VIEWER = 9,
    EF_SYSTEM_VIEWER_GATEWAY_LIMITED = 10,
    FREEMIUM_ORG_ADMIN = 11,
    PARTNER_PRO = 12,
    INSTALLER = 13,
    TECHNICIAN = 14,
    BASIC_TECHNICIAN = 15,
    CUSTOMER_ORG_ADMIN = 16,
    CUSTOMER_BLG_ADMIN = 17,
    CUSTOMER_GATEWAY_ADMIN = 18,
    CUSTOMER_SYSTEM_ADMIN = 19,
    CUSTOMER_VIEWER = 20,
    PAAS_SERVICE_MANAGER = 21,
    SERVICE_MANAGER = 22,
    EF_GATEWAY_ADMIN = 23,
    LIMITED = 24,
    PLATFORM_ADMIN = 25
}

export const userRoles: SelectionList = [
    {
        key: USER_ROLE_TYPE.PROJECT_OWNER, //0
        value: "ProjectOwner",
        description: 'Project Owner',
    },
    {
        key: USER_ROLE_TYPE.EL_ORG_ADMIN, //1
        value: "EL Org Admin",
        description: "Organization Admin",
    },
    {
        key: USER_ROLE_TYPE.EL_ORG_VIEWER, //2
        value: "EL Org Viewer",
        description: "Organization Viewer",
    },
    {
        key: USER_ROLE_TYPE.EL_BLDG_VIEWER, //3
        value: "EL Bldg Viewer",
        description: "Building Viewer",
    },
    {
        key: USER_ROLE_TYPE.EF_BLDG_ADMIN_ORG_VIEWER, //4
        value: "EF Bldg Admin Org Viewer",
        description: "Building Admin",
    },
    {
        key: USER_ROLE_TYPE.EF_SYSTEM_ADMIN_GATEWAY_VIEWER, //7
        value: "EF System Admin Gateway Viewer",
        description: "System Admin",
    },
    {
        key: USER_ROLE_TYPE.EF_SYSTEM_VIEWER, //9
        value: "EF System Viewer",
        description: 'System Viewer',
    },
    {
        key: USER_ROLE_TYPE.TECHNICIAN, //14
        value: "Technician",
        description: "Technician",
    },
    {
        key: USER_ROLE_TYPE.SERVICE_MANAGER, //22
        value: "Service Manager",
        description: 'Service Manager',
    },
    {
        key: USER_ROLE_TYPE.LIMITED, //24
        value: "Limited",
        description: 'Limited',
    },
    {
        key: USER_ROLE_TYPE.PLATFORM_ADMIN, //25
        value: "ELD Platform Admin",
        description: 'Platform Admin',
    },

    {
        key: USER_ROLE_TYPE.EF_BLDG_ADMIN_ORG_LIMITED, //5
        value: "EF Bldg Admin Org Limited",
        description: 'Building Admin Limited',
    },
    {
        key: USER_ROLE_TYPE.EF_GATEWAY_ADMIN_BLDG_LIMITED, //6
        value: "EF Gateway Admin Bldg Limited",
        description: 'Gateway Admin Limited',
    },
    {
        key: USER_ROLE_TYPE.EF_SYSTEM_ADMIN_GATEWAY_LIMITED, //8
        value: "EF System Admin Gateway Limited",
        description: 'System Admin Limited',
    },
    {
        key: USER_ROLE_TYPE.EF_SYSTEM_VIEWER_GATEWAY_LIMITED, //10
        value: "EF System Viewer Gateway Limited",
        description: 'System Viewer Limited',
    },
    {
        key: USER_ROLE_TYPE.FREEMIUM_ORG_ADMIN, //11
        value: "Freemium Org Admin",
        description: 'Freemium Org Admin',
    },
    {
        key: USER_ROLE_TYPE.PARTNER_PRO, //12
        value: "Partner Pro",
        description: 'Partner Pro',
    },
    {
        key: USER_ROLE_TYPE.INSTALLER, //13
        value: "Installer",
        description: 'Installer',
    },
    {
        key: USER_ROLE_TYPE.BASIC_TECHNICIAN, //15
        value: "Basic Technician",
        description: 'Basic Technician',
    },
    {
        key: USER_ROLE_TYPE.CUSTOMER_ORG_ADMIN, //16
        value: "Customer Org Admin",
        description: 'Customer Org Admin',
    },
    {
        key: USER_ROLE_TYPE.CUSTOMER_BLG_ADMIN, //17
        value: "Customer Blg Admin",
        description: 'Customer Building Admin',
    },
    {
        key: USER_ROLE_TYPE.CUSTOMER_GATEWAY_ADMIN, //18
        value: "Customer Gateway Admin",
        description: 'Customer Gateway Admin',
    },
    {
        key: USER_ROLE_TYPE.CUSTOMER_SYSTEM_ADMIN, //19
        value: "Customer System Admin",
        description: 'Customer System Admin',
    },
    {
        key: USER_ROLE_TYPE.CUSTOMER_VIEWER, //20
        value: "Customer Viewer",
        description: 'Customer Viewer',
    },
    {
        key: USER_ROLE_TYPE.PAAS_SERVICE_MANAGER, //21
        value: "PAAS Service Manager",
        description: 'Paas Service Manager',
    },
    {
        key: USER_ROLE_TYPE.EF_GATEWAY_ADMIN, //23
        value: "EF Gateway Admin",
        description: 'Gateway Admin',
    },

];

export function getUserRole(userRole: number): SelectionData {
    return userRoles.filter((role) => userRole === role.key)[0]
}

export function getUserRoleByName(userRole: string): SelectionData {
    return userRoles.filter((role) => userRole === role.value)[0]
}

export enum USER_BASE_ROLE_TYPE {
    ADMIN = 1,
    VIEWER = 2,
}

export const userBaseRoles: SelectionList = [
    {
        key: USER_BASE_ROLE_TYPE.ADMIN,
        value: "Admin",
        description: "Admin",
    },
    {
        key: USER_BASE_ROLE_TYPE.VIEWER,
        value: "Viewer",
        description: "Viewer",
    }
];

// Inite User entity Type

export enum INVITE_STATUS {
    INVITED = 1,
    ACCEPTED = 2,
    DENIED = 3,
    REQUESTED = 4,
    EXPIRED = 5,
}

// Invite User Type

export enum INVITE_USER_TYPE {
    TYPE_NEW_USER_SELF_REGISTER = 0,
    TYPE_NEW_USER = 1,
    TYPE_OTHER_ADOPTOR_USER = 2,
    TYPE_SAME_ADOPTOR_USER = 3,
    TYPE_EATON_USER = 4,
    TYPE_NEW_USER_INCOMPLETE_REGISTER = 5, //Internal handling - navigate to account details screen
    TYPE_NEW_OTHER_ADOPTER_USER_INCOMPLETE_REGISTER = 6
}

const inviteUserType: SelectionList = [
    {
        key: INVITE_USER_TYPE.TYPE_NEW_USER_SELF_REGISTER,
        value: "SelfRegistration",
        description: "Self Registration",
    },
    {
        key: INVITE_USER_TYPE.TYPE_NEW_USER,
        value: "NewUser",
        description: "New User",
    },
    {
        key: INVITE_USER_TYPE.TYPE_OTHER_ADOPTOR_USER,
        value: "OtherAdopterUser",
        description: "Other Adopter User",
    },
    {
        key: INVITE_USER_TYPE.TYPE_SAME_ADOPTOR_USER,
        value: "ExistingUserInSameAdopter",
        description: "Existing User In Same Adopter",
    },
    {
        key: INVITE_USER_TYPE.TYPE_EATON_USER,
        value: "OktaUser",
        description: "Existing User In Okta",
    },
    {
        key: INVITE_USER_TYPE.TYPE_NEW_USER_INCOMPLETE_REGISTER,
        value: "IncompleteRegistration",
        description: "Incomplete Registration",
    },
    {
        key: INVITE_USER_TYPE.TYPE_NEW_OTHER_ADOPTER_USER_INCOMPLETE_REGISTER,
        value: "IncompleteRegistrationOtherAdopter",
        description: "Incomplete Registration other adopter",
    }
];

export function getInviteUserModel(userType: number): SelectionData {
    return inviteUserType.filter((entity) => entity.key === userType)[0];
}

//Invite user accept deny invite status enum
export enum SuccessFailureStatus {
    SUCCESS = 1,
    FAILTURE = 2,
}

export enum DOWNLOAD_TYPE {
    PDF = 1,
    EXCEL = 2,
    CSV = 3,
}

export enum ACTION_TYPE {
    ACCEPT = 1,
    DECLINE = 2,
}

export enum LOGBOOK_TYPE_FILTER {
    ACTIVE = '9',
    ALL = '-2',
}

export enum STATUS_TYPE {
    INACTIVE = 0,
    ACTIVE = 1,
    BOTH = 2,
}

export enum LOGBOOK_TIME_RANGE_FILTER {
    SIX_MONTHS = 0,
    TWELVE_MONTHS = 1,
    CURRENT_YEAR = 2,
    CUSTOM = 3
}
export enum CUSTOMER_ROLE_TYPE {
    BUYER = 0,
    PROJECT_LEAD = 1,
    TECHNICAL_PERSON = 2,
    CONTACT_PERSON_ON_SITE = 3,
    SERVICE_MANAGER = 4
}
export type contactTypeSelectionList = Array<
    SelectionData
>;
export const contactUpdateList: contactTypeSelectionList = [
    {
        key: CUSTOMER_ROLE_TYPE.BUYER,
        value: 'CUSTOMER_DETAILS.BUYER',
        description: 'CUSTOMER_DETAILS.BUYER',

    },
    {
        key: CUSTOMER_ROLE_TYPE.PROJECT_LEAD,
        value: 'CUSTOMER_DETAILS.PROJECT_LEAD',
        description: 'CUSTOMER_DETAILS.PROJECT_LEAD',

    },
    {
        key: CUSTOMER_ROLE_TYPE.TECHNICAL_PERSON,
        value: 'CUSTOMER_DETAILS.TECHNICAL_PERSON',
        description: 'CUSTOMER_DETAILS.TECHNICAL_PERSON',

    },
    {
        key: CUSTOMER_ROLE_TYPE.CONTACT_PERSON_ON_SITE,
        value: 'CUSTOMER_DETAILS.CONTACT_PERSON_ON_SITE',
        description: 'CUSTOMER_DETAILS.CONTACT_PERSON_ON_SITE',
    },
    {
        key: CUSTOMER_ROLE_TYPE.SERVICE_MANAGER,
        value: 'CUSTOMER_DETAILS.SERVICE_MANAGER',
        description: 'CUSTOMER_DETAILS.SERVICE_MANAGER',
    },
];

export enum LANGUAGE {
    ENGLISH = 'en',
    GERMAN = 'de',
    FRENCH = 'fr',
}

export function getcontactUpdateList(userType: number): SelectionData {
    return contactUpdateList.filter((entity) => entity.key === userType)[0];
}
export function getContactTypeNumber(userType: string): SelectionData {
    return contactUpdateList.filter((entity) => entity.description === userType)[0];
}
