// eslint-disable-next-line
import React from 'react';
import { Tooltip } from '@mui/material';

type Props = {
    title: string;
    isTopPlacement: boolean;
    lenghtLimit: number;
    component: any;
};
export const TooltipItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const displayTooltip = props.title ? props.title.length > props.lenghtLimit : false;
    return (
        <>
            {displayTooltip &&
                <Tooltip title={props.title} placement={props.isTopPlacement ? "top-start" : "bottom-start"}>
                    {props.component}
                </Tooltip>
            }
            {!displayTooltip &&
                <>
                    {props.component}
                </>
            }
        </>
    );
};
