import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { DELETE_USER, USER_DELETE } from './url-constants';

type DeleteUserEndpointSpecifications = ApiEndpointSpecification;
export const DeleteUserEndpointSpecification: DeleteUserEndpointSpecifications = {
    url: DELETE_USER,
    method: HttpMethod.DELETE,
    requestParamsSchemaName: 'DeleteUserRequestParams',
    requestBodySchemaName: 'DeleteUserRequestBody',
    okResponseSchemaName: 'DeleteUserOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeDeleteUser = Helpers.makeApiRequestFn<
    Types.DeleteUserRequestParams,
    Types.DeleteUserRequestBody,
    Types.DeleteUserOkResponse
>(DeleteUserEndpointSpecification);
