// eslint-disable-next-line
import React from 'react';
import { StatusLabel } from '../StatusLabel';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';

export const AcknowledgedStatusLabel: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const { t } = useLanguageLocale();

    return (
        <StatusLabel
            status={t('DASHBOARD.TIMELINE_DETAILS.ACKNOWLEDGED')}
            foregroundColor={PXBColors.blue[600]}
            backgroundColor={PXBColors.blue[50]}
            testId="acknowledged-status-label"
        />
    );
};
