
import * as ApiRequests from '../../api/requests';
import { AppThunk } from '../actions';
import { LogbookActions } from '../actions/logbook-actions';

export const LogBookThunks = {
    getLatestLogs: (
        projectId: string,
        buildingId: string,
        limit?: string,
        offSet?: string,
        logId?: string,
        systemId?: string,
        fromdate?: string,
        todate?: string,
        logType?: string[],
        isActiveLog?: boolean,
        logSourceId?: string,
        isServiceDashboard?: boolean,
        customerId?: string

    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(LogbookActions.LatestLogListStarted());
        const payload = await ApiRequests.getSystemLogTimeline(
            {
                projectId: projectId,
                buildingId: buildingId,
                limit: limit,
                offSet: offSet,
                logId: logId,
                fromdate: fromdate,
                todate: todate,
                systemId: systemId,
                logType: logType,
                isActiveLog: isActiveLog,
                logSourceId: logSourceId,
                isServiceDashboard: isServiceDashboard,
                customerId: customerId
            },
            { authHelper: undefined }
        );
        if (payload.ok) {
            dispatch(LogbookActions.LatestLogListSucceeded(payload.response.data));
            return;
        }
        dispatch(LogbookActions.LatestLogListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    getEarlierLogs: (
        projectId: string,
        buildingId: string,
        limit?: string,
        offSet?: string,
        logId?: string,
        systemId?: string,
        fromdate?: string,
        todate?: string,
        logType?: string[],
        isActiveLog?: boolean,
        logSourceId?: string,
        isServiceDashboard?: boolean,
        customerId?: string
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(LogbookActions.EarlierLogListStarted());

        const payload = await ApiRequests.getSystemLogTimeline(
            {
                projectId: projectId,
                buildingId: buildingId,
                limit: limit,
                offSet: offSet,
                logId: logId,
                fromdate: fromdate,
                todate: todate,
                systemId: systemId,
                logType: logType,
                isActiveLog: isActiveLog,
                logSourceId: logSourceId,
                isServiceDashboard: isServiceDashboard,
                customerId: customerId
            },
            { authHelper: undefined }
        );
        if (payload.ok) {
            dispatch(LogbookActions.EarlierLogListSucceeded(payload.response.data));
            return;
        }
        dispatch(LogbookActions.EarlierLogListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    getPreviousLogs: (
        logsourceId: string,
        logDateTime: string
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(LogbookActions.PreviousLogListStarted());
        const payload = await ApiRequests.getPreviousLogTimeline(
            {
                logsourceId: logsourceId,
                logDateTime: logDateTime
            },
            { authHelper: undefined }
        );
        if (payload.ok) {
            dispatch(LogbookActions.PreviousLogListSucceeded(payload.response.data));
            return;
        }
        dispatch(LogbookActions.PreviousLogListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    upsertLogComments: (
        logId: string,
        comment: string,
        addToast: Function,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(LogbookActions.upsertLogCommentsStarted());
        const payload = await ApiRequests.upsertLogComments(
            {
                logId: logId,
                comment: comment
            },
            { authHelper: undefined }
        );
        if (payload.ok) {
            dispatch(LogbookActions.upsertLogCommentsSucceeded(payload.response.message));
            return;
        }
        addToast(`${ApiRequests.extractResponseNotOkMessage(payload.response)}`, 'error');
        dispatch(LogbookActions.upsertLogCommentsFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    acknowledgeLog: (
        logId: string,
        isAcknowledge: boolean,
        addToast: Function,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(LogbookActions.acknowledgeLogStarted());
        const payload = await ApiRequests.acknowledgeLog(
            {
                logId: logId,
                Isacknowledge: isAcknowledge
            },
            { authHelper: undefined }
        );
        if (payload.ok) {
            dispatch(LogbookActions.acknowledgeLogSucceeded(payload.response.message));
            return;
        }
        addToast(`${ApiRequests.extractResponseNotOkMessage(payload.response)}`, 'error');
        dispatch(LogbookActions.acknowledgeLogFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    downloadLogbookReport: (
        reportFormatType: number,
        projectId: string,
        buildingId: string,
        systemId?: string,
        fromDate?: string,
        toDate?: string,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(LogbookActions.downloadLogbookReportStarted());
        const payload = await ApiRequests.downloadLogbook(
            {
                reportFormatType: reportFormatType,
                projectId: projectId,
                buildingId: buildingId,
                systemId: systemId,
                fromDate: fromDate,
                toDate: toDate
            },
            { authHelper: undefined }
        );
        if (payload.ok) {
            //Direct donwload from api helper //TODO in case required handling for success
            // dispatch(LogbookActions.downloadLogbookReportSucceeded(payload.response));
            return;
        }
        // addToast(`${ApiRequests.extractResponseNotOkMessage(payload.response)}`, 'error');
        dispatch(LogbookActions.downloadLogbookReportFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    }
};
