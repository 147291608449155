import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_GET_INVITATION_HISTORY } from './url-constants';

type GetUserInvitationHistoryEndpointSpecifications = ApiEndpointSpecification;
export const GetUserInvitationHistoryEndpointSpecification: GetUserInvitationHistoryEndpointSpecifications = {
    url: USER_GET_INVITATION_HISTORY,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'UserInvitationHistoryRequestParams',
    requestBodySchemaName: 'UserInvitationHistoryRequestBody',
    okResponseSchemaName: 'UserInvitationHistoryOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeGetUserInvitationHistory = Helpers.makeApiRequestFn<
    Types.UserInvitationHistoryRequestParams,
    Types.UserInvitationHistoryRequestBody,
    Types.UserInvitationHistoryOkResponse
>(GetUserInvitationHistoryEndpointSpecification);
