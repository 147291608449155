// eslint-disable-next-line
import React from 'react';
import {
    TableRow,
    IconButton,
    Typography,
    TableCell,
} from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ChevronRight, Star, StarBorder } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { DeviceAssetData } from '../../types/device';
import { useDispatch } from 'react-redux';
import { DeviceState, ENTITY_TYPE, getDeviceStatusState, getDeviceType } from '../../app/enum/ENUM';
import { OrganizationThunks } from '../../actions/thunks/organization-thunks';
import { TooltipItem } from '../TooltipItem/TooltipItem';
import { getControlledText } from '../../app/enum/GenericFormat';

type Props = {
    row: DeviceAssetData;
    selectedSystem: string;
    displayClick: Function;
};

export const AssetSystemListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const row: DeviceAssetData = props.row;
    const dispatch = useDispatch();

    const handleDisplay = () => {
        props.displayClick({ deviceData: row });
    };
    const handleMarkAsFav = (): void => {
        dispatch(OrganizationThunks.markAsFav(
            row.systemId,
            ENTITY_TYPE.SYSTEM
        ));
    }

    const handleRemoveMarkAsFav = (): void => {
        dispatch(OrganizationThunks.removeMarkAsFav(
            row.systemId,
            ENTITY_TYPE.SYSTEM
        ));
    }

    const deviceState: DeviceState = getDeviceStatusState(row.systemStatus);

    return (
        <TableRow
            id={`row${row.systemId}`}
            data-testid={`row${row.systemId}`}
            style={{ cursor: 'pointer', width: '100%', backgroundColor: props.selectedSystem === row.systemId ? PXBColors.blue[50] : PXBColors.white[100] }}

        >
            <TableCell style={{ width: '0%', paddingLeft: 10 }}
                id="system-list-item-favorite"
                data-testid="system-list-item-favorite">
                {row.isFavourite ? <Star data-testid='removeMarkAsFaVSystem' style={{ color: '#007BC1' }} onClick={handleRemoveMarkAsFav} /> : <StarBorder style={{ color: '#727E84' }} onClick={handleMarkAsFav} />}
            </TableCell>
            <TooltipItem title={row.systemName} isTopPlacement={true} lenghtLimit={15} component={<TableCell
                id="system-list-item-name"
                data-testid="system-list-item-name"
                align="left"
                padding="checkbox"
                style={{ width: '20%', wordWrap: 'break-word', wordBreak: 'break-all' }}
            >
                {getControlledText(row.systemName, 15)}
            </TableCell>}>
            </TooltipItem>
            <TooltipItem title={row.projectName} isTopPlacement={true} lenghtLimit={15} component={<TableCell align="left" padding="checkbox" style={{ width: '20%' }}
                id="system-list-item-org-name"
                data-testid="system-list-item-org-name">
                {getControlledText(row.projectName, 15)}
            </TableCell>}>
            </TooltipItem>
            <TooltipItem title={row.buildingName} isTopPlacement={true} lenghtLimit={15} component={<TableCell align="left" padding="checkbox" style={{ width: '20%', padding: 0, paddingLeft: 16 }}
                id="system-list-item-building-name"
                data-testid="system-list-item-building-name">
                {getControlledText(row.buildingName, 15)}
            </TableCell>}>
            </TooltipItem>
            <TableCell align="left" padding="checkbox" style={{ width: '10%', padding: 0, paddingLeft: 16 }}
                id="system-list-item-lum-Count"
                data-testid="system-list-item-lum-Count">
                {row.luminaryCount}
            </TableCell>

            <TableCell align="left" padding="checkbox" style={{ width: '10%', padding: 0, paddingLeft: 16 }}
                id="system-list-item-type"
                data-testid="system-list-item-type">
                {getDeviceType(row.type)}
            </TableCell>

            <TableCell align="left" style={{ width: '16%', paddingLeft: 10 }}
                id="system-list-item-status"
                data-testid="system-list-item-status">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <div style={{ minWidth: 25 }}>
                        <img
                            src={deviceState.statusImage}
                            alt="logo"
                            style={{ display: 'flex', alignItems: 'right' }}
                            id="device-status-icon"
                            data-testid="device-status-icon"
                        /></div>
                    <Typography
                        id="device-status-button"
                        data-testid="device-status-button"

                        style={{ color: deviceState.statusColor, textTransform: 'none', marginLeft: 10, fontWeight: 600 }} variant="body2">
                        {t(deviceState.statusTitle)}
                    </Typography>
                </div>
            </TableCell>

            <TableCell style={{ width: '5%' }}
                id="system-list-item-next"
                data-testid="system-list-item-next" onClick={() => handleDisplay()}>
                <IconButton edge="end" id="list-item-next-icon" style={{ marginLeft: "auto" }} >
                    <ChevronRight />
                </IconButton>
            </TableCell>

            <TableCell align="left" padding="checkbox" style={{ width: '0%', padding: 0 }}
                id="system-list-item-empty"
                data-testid="system-list-item-empty">

            </TableCell>

        </TableRow>
    );
};
