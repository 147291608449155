import { LegalType } from '../types';
import { InitialState } from '../state';
import { AppReducer } from './app-reducer';

export const legalReducer: AppReducer<'Legal'> = (state = InitialState.Legal, action): typeof state => {
    switch (action.type) {
        case 'Legal/LoadOpenSource/Started':
            return {
                loading: true,
                success: false,
                content: '',
                type: LegalType.None,
                openSourceList: [],
            };
        case 'Legal/LoadOpenSource/Succeeded':
            return {
                loading: false,
                success: true,
                content: action.payload.data,
                type: action.payload.type,
                openSourceList: action.payload.list,
            };
        case 'Legal/LoadOpenSource/Failed':
            return {
                loading: false,
                success: false,
                content: '',
                type: LegalType.None,
                openSourceList: [],
            };
        default:
            return state;
    }
};
