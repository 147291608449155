// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Close, Search } from '@mui/icons-material';
import { TreeView } from '@mui/x-tree-view';
import { DialogContent, IconButton, DialogTitle, Typography, TextField, InputAdornment, Box, useMediaQuery, useTheme, CircularProgress } from '@mui/material';
import CustomTreeItem from '../../components/CustomTree/CustomTreeContent';
import { COLOR_GRAY } from '../../constants/color-codes';
import { getDeviceType } from '../enum/ENUM';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { UserAdvanceAccessDetail } from '../../types/manage-users';
import { ORGANIZATTION_NAME } from '../../constants';
import * as PXBColors from '@brightlayer-ui/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { EmptyState } from '@brightlayer-ui/react-components';


type Props = {
    emailAdvance: string;
    userAccessDetailData: UserAdvanceAccessDetail;
    onCancelAdvance: Function;
    isAdvanceTreeDataAvailable: boolean;
    OnItemSelected?: Function;
};

export const InviteUserAdvanceDialogSecondPage: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();

    const [isDataAvailable, setDataAvailable] = useState(false);
    const [query, setQuery] = useState('');

    useEffect(() => {
        if ((props.userAccessDetailData.inviteByUserEntityAccessDetail && props.userAccessDetailData.inviteByUserEntityAccessDetail.length > 0) ||
            (props.userAccessDetailData.inviteUserEntityAccessDetail && props.userAccessDetailData.inviteUserEntityAccessDetail.length > 0))
            setDataAvailable(true);
        else
            setDataAvailable(false);
    }, [props.userAccessDetailData])

    const onCancelAdvanceInviteCall = () => {
        props.onCancelAdvance({});
    }

    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setQuery(e.target.value);
    };

    const resetSearch = (): void => {
        setQuery('');
    };


    return (
        <div data-testid="advance-page-container"
            style={{ overflow: 'scroll' }}
        >
            <DialogContent>
                <div>
                    <IconButton style={{
                        textAlign: 'right',
                        float: 'right',
                        margin: '8px 16px'
                    }}
                        data-testid="close-icon"
                        onClick={() => onCancelAdvanceInviteCall()}>
                        <Close style={{ width: 24, height: 24, }} fontSize="small" />
                    </IconButton>
                    <DialogTitle data-testid="invite-user-dialog-header-2" id="invite-user-dialog-header-2" sx={{ padding: '32px 24px 0px 24px', mb: 2, alignSelf: "stretch" }}>{props.emailAdvance}</DialogTitle>
                    <Typography
                        id="invite-user-dialog-header-subtitle-2"
                        data-testid="invite-user-dialog-header-subtitle-2"
                        style={{ alignSelf: 'left', color: COLOR_GRAY, fontSize: 16, paddingLeft: 24, paddingBottom: 8 }} variant="body2">
                        {t('MANAGE_USERS.ADVANCED_INVITE_PLEASE_ASSIGN_ROLES', { replace: { email: props.emailAdvance } })}
                    </Typography>
                    <Typography component="div">
                        <ul>
                            <li >
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography
                                        id="invite-user-dialog-admin"
                                        data-testid="invite-user-dialog-admin"
                                        style={{ alignSelf: 'left', color: COLOR_GRAY, fontWeight: 700, fontSize: 14, paddingLeft: 8, paddingBottom: 16 }} variant="body2">
                                        {t('MANAGE_USERS.ADVANCED_INVITE_ADMIN')}
                                    </Typography>
                                    <Typography
                                        id="invite-user-dialog-admin-subtitle"
                                        data-testid="invite-user-dialog-admin-subtitle"
                                        style={{ alignSelf: 'left', color: COLOR_GRAY, fontSize: 14, paddingLeft: 8, paddingBottom: 16 }} variant="body2">
                                        {t('MANAGE_USERS.ADVANCED_INVITE_ADMIN_DESCRIPTION')}
                                    </Typography>
                                </div>
                            </li>
                            <li> <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    id="invite-user-dialog-viewer"
                                    data-testid="invite-user-dialog-viewer"
                                    style={{ alignSelf: 'left', color: COLOR_GRAY, fontWeight: 700, fontSize: 14, paddingLeft: 8, paddingBottom: 16 }} variant="body2">
                                    {t('MANAGE_USERS.ADVANCED_INVITE_VIEWER')}
                                </Typography>
                                <Typography
                                    id="invite-user-dialog-viewer-subtitle"
                                    data-testid="invite-user-dialog-viewer-subtitle"
                                    style={{ alignSelf: 'left', color: COLOR_GRAY, fontSize: 14, paddingLeft: 8, paddingBottom: 16 }} variant="body2">
                                    {t('MANAGE_USERS.ADVANCED_INVITE_VIEWER_DESCRIPTION')}
                                </Typography>
                            </div></li>
                            <li> <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    id="invite-user-dialog-limited"
                                    data-testid="invite-user-dialog-limited"
                                    style={{ color: COLOR_GRAY, fontWeight: 700, fontSize: 14, paddingLeft: 8, }} variant="body2">
                                    {t('MANAGE_USERS.ADVANCED_INVITE_LIMITED')}
                                </Typography>
                                <Typography
                                    id="invite-user-dialog-limited-subtitle"
                                    data-testid="invite-user-dialog-limited-subtitle"
                                    style={{ alignSelf: 'left', color: COLOR_GRAY, fontSize: 14, paddingLeft: 8, paddingRight: 24 }} variant="body2">
                                    {t('MANAGE_USERS.ADVANCED_INVITE_LIMITED_DESCRIPTION')}
                                </Typography>
                            </div></li>
                        </ul>
                    </Typography>
                    <div style={{ padding: 24, paddingTop: 8 }}>
                        <TextField
                            inputProps={{ 'data-testid': 'search', maxLength: 100 }}
                            value={query}
                            sx={{ height: 40 }}
                            style={{
                                width: '100%',
                                border: '1px solid #727E84',
                                paddingTop: 6,
                                borderRadius: 4,
                                backgroundColor: 'white',
                                outline: 'none',
                                overflow: 'hidden',
                                fontSize: 14,
                                color: '#727E84',
                                fontStyle: 'normal',
                                fontWeight: 400,
                            }}
                            placeholder={t('ORGANIZATION_MANAGE.SEARCH_BUILDINGS')}
                            onChange={(e): void => onChangeSearchText(e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search data-testid="search-icon" style={{ marginLeft: 12, color: PXBColors.gray[500] }} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {query !== '' && (
                                            <IconButton
                                                data-testid="search-click"
                                                onClick={resetSearch}>
                                                <Close fontSize="small" />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        />
                    </div>
                </div>
                <div style={{ padding: 24, paddingTop: 8, paddingBottom: 8, }}>
                    <Box data-testid="organization-hierarchy-container" id="organization-hierarchy-container"
                        sx={{
                            maxHeight: '60%', flexGrow: 1, maxWidth: '100%', overflowY: 'auto',
                            padding: 2, backgroundColor: PXBColors.white[200],
                        }}
                        px={2}>
                        {!props.isAdvanceTreeDataAvailable && isDataAvailable && <Typography
                            id="no-data"
                            data-testid="no-data"
                            style={{ fontSize: 16, alignContent: 'center' }} variant="body2">
                            {t('DASHBOARD.NO_DATA')}
                        </Typography>}
                        {!isDataAvailable && <EmptyState style={{ flex: 1, height: '100%', backgroundColor: PXBColors.white[50] }}
                        icon={<CircularProgress id="progress-spinner" />} title={t('MESSAGES.LOADING')} placeholder={undefined} />}
                        {props.isAdvanceTreeDataAvailable && <TreeView
                            data-testid="advance-tree-view"
                            aria-label="icon expansion"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            sx={{ minHeight: 200, flexGrow: 1, maxWidth: '100%', position: 'relative' }}
                        >
                            {props.userAccessDetailData?.inviteByUserEntityAccessDetail ? props.userAccessDetailData.inviteByUserEntityAccessDetail.map((itemsListOrg) => (
                                <CustomTreeItem data-testid="advance-tree-item-org"
                                    nodeId={itemsListOrg.organizationId} label={itemsListOrg.organizationName + '#' + itemsListOrg.buildingCount + ' ' + ((itemsListOrg.buildingCount > 1) ? t('ASSETS_DETAILS.BUILDINGS') : t('ENTITY_TYPE.BUILDING'))}
                                >
                                    {itemsListOrg.buildings ? itemsListOrg.buildings.map((itemsListBuilding) => (
                                        <CustomTreeItem data-testid="advance-tree-item-building"
                                            nodeId={itemsListBuilding.buildingId} label={itemsListBuilding.buildingName + '#' + itemsListBuilding.systemCount + ' ' + ((itemsListBuilding.systemCount > 1) ? t('DEVICE_DETAILS.SYSTEMS') : t('ENTITY_TYPE.SYSTEM'))}>
                                            {itemsListBuilding.systems ? itemsListBuilding.systems.map((objsystem) => (
                                                <CustomTreeItem data-testid="advance-tree-item-system"
                                                    nodeId={objsystem.systemId}
                                                    label={"\"" + objsystem.systemName + "\", " + getDeviceType(objsystem.systemType) + '#' + objsystem.luminaryCount + ' ' + ((objsystem.luminaryCount > 1) ? t('DEVICE_DETAILS.LUMINAIRES') : t('DEVICE_DETAILS.LUMINAIRE'))} >
                                                </CustomTreeItem>

                                            )) : ''}
                                        </CustomTreeItem>
                                    )) : ''}
                                </CustomTreeItem>
                            )) : ''}
                        </TreeView>}
                    </Box>
                </div>
            </DialogContent>
        </div >

    )
}
