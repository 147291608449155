import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_TECHNICIANS_LIST } from './url-constants';

type TechniciansListEndpointSpecifications = ApiEndpointSpecification;
export const TechniciansListEndpointSpecification: TechniciansListEndpointSpecifications = {
    url: GET_TECHNICIANS_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'TechniciansListRequestParams',
    requestBodySchemaName: 'TechniciansListRequestBody',
    okResponseSchemaName: 'TechniciansListRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeTechniciansList = Helpers.makeApiRequestFn<
    Types.TechniciansListRequestParams,
    Types.TechniciansListRequestBody,
    Types.TechniciansListOkResponse
>(TechniciansListEndpointSpecification);
