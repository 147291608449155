import { OrganizationListData, OrgBuildingList, ServiceOrganizationDetailList } from "../types/organization";

export const orgListData: OrganizationListData = {
    detail: [],
    totalRecordCount: 0,
    totalPage: 0
}
const serviceManagerOrgListData: ServiceOrganizationDetailList = {
    adminCount: 0,
    viewerCount: 0,
    systemCount: 0,
    sLACount: 0,
    totalCustomerCount: 0,
    totalOrganizationCount:0,
    serviceOrganizationDetailList: []
}

export type OrganizationState = {
    orgListLoading: boolean;
    orgListSuccess: boolean;
    orgListFail: boolean;
    orgList: OrganizationListData,
    errorCode: string;
    buildingListLoading: boolean;
    buildingListSuccess: boolean;
    buildingListFail: boolean;
    buildingList: OrgBuildingList,
    buildingErrorCode: string;
    transferBuildingLoading: boolean;
    transferBuildingSuccess: boolean;
    transferBuildingFail: boolean;
    transferBuildingErrorMessage: string;
    updateOrgDetailLoading: boolean;
    updateOrgDetailSuccess: boolean;
    updateOrgDetailFail: boolean;
    updateOrgDetailErrorMessage: string;
    upsertBuildingDetailLoading: boolean;
    upsertBuildingDetailSuccess: boolean;
    upsertBuildingDetailFail: boolean;
    upsertBuildingDetailErrorMessage: string;
    markasFavLoading: boolean;
    markasFavSuccess: boolean;
    markasFavFail: boolean;
    removeMarkAsFavLoading: boolean;
    removeMarkAsFavSuccess: boolean;
    removeMarkAsFavFail: boolean;
    mergeBuildingAssetLoading: boolean;
    mergeBuildingAssetSuccess: boolean;
    mergeBuildingAssetFail: boolean;
    mergeBuildingAssetErrorCode: string;

    serviceManagerOrgListLoading: boolean;
    serviceManagerOrgListSuccess: boolean;
    serviceManagerOrgListFail: boolean;
    serviceManagerOrgList: ServiceOrganizationDetailList;
};

export const initialOrganizationState: OrganizationState = {
    orgListLoading: false,
    orgListSuccess: false,
    orgListFail: false,
    orgList: orgListData,
    errorCode: '',
    buildingListLoading: false,
    buildingListSuccess: false,
    buildingListFail: false,
    buildingList: [],
    buildingErrorCode: '',
    transferBuildingLoading: false,
    transferBuildingSuccess: false,
    transferBuildingFail: false,
    transferBuildingErrorMessage: '',
    updateOrgDetailLoading: false,
    updateOrgDetailSuccess: false,
    updateOrgDetailFail: false,
    updateOrgDetailErrorMessage: '',
    markasFavLoading: false,
    markasFavSuccess: false,
    markasFavFail: false,
    removeMarkAsFavLoading: false,
    removeMarkAsFavSuccess: false,
    removeMarkAsFavFail: false,
    upsertBuildingDetailLoading: false,
    upsertBuildingDetailSuccess: false,
    upsertBuildingDetailFail: false,
    upsertBuildingDetailErrorMessage: '',
    mergeBuildingAssetLoading: false,
    mergeBuildingAssetSuccess: false,
    mergeBuildingAssetFail: false,
    mergeBuildingAssetErrorCode: '',

    serviceManagerOrgListLoading: false,
    serviceManagerOrgListSuccess: false,
    serviceManagerOrgListFail: false,
    serviceManagerOrgList: serviceManagerOrgListData,
};
