import { Language } from '@mui/icons-material';
import { AUTH_TOKEN, AUTH_TOKEN_TYPE, LOCAL_EXPIRATION, LOCAL_USER_DATA, REMEMBER_ME_DATA, SELECTED_CUSTOMER, SELECTED_ORGANIZATION,SELECTED_LANGUAGE } from '../../constants';
import { getLanguageList } from '../UserDetails/AccountPreferencesData';

function readAuthData(): {
    initialExpirationDate: Date | null;
    userId: string | undefined;
    email: string | undefined;
    rememberMeData: { user: string; rememberMe: boolean };
    roleTypeId?: number | undefined;
} {
    const expirationString = sessionStorage.getItem(LOCAL_EXPIRATION);
    const initialExpirationDate = expirationString ? new Date(expirationString) : null;
    const userData = JSON.parse(sessionStorage.getItem(LOCAL_USER_DATA) || '{}') as {
        user?: string;
        userId?: string
        roleTypeId?: number;
    };
    const rememberMeData = JSON.parse(sessionStorage.getItem(REMEMBER_ME_DATA) || '{}') as {
        user: string;
        rememberMe: boolean;
    };
    return {
        initialExpirationDate,
        userId: userData.userId,
        email: userData.user,
        rememberMeData: rememberMeData,
        roleTypeId: userData.roleTypeId
    };
}

function saveAuthToken(token: string): void {
    sessionStorage.setItem(AUTH_TOKEN, AUTH_TOKEN_TYPE + token);
}

function getAuthToken(): string {
    const authToken = sessionStorage.getItem(AUTH_TOKEN);

    return authToken ? authToken : '';
}

function saveTokenExpiration(expiration: Date): void {
    sessionStorage.setItem(LOCAL_EXPIRATION, expiration.toUTCString());
}

function saveAuthCredentials(user: string, userId: string, roleTypeId?: number): void {
    const userData = {
        user: user,
        userId: userId,
        roleTypeId: roleTypeId
    };
    sessionStorage.setItem(LOCAL_USER_DATA, JSON.stringify(userData));
}
function saveRememberMeData(user: string, rememberMe: boolean): void {
    const RememberMeData = {
        user: user,
        rememberMe: rememberMe,
    };
    sessionStorage.setItem(REMEMBER_ME_DATA, JSON.stringify(RememberMeData));
}
function saveLanguage(selectedLanguage: string): void {
  localStorage.setItem(SELECTED_LANGUAGE,selectedLanguage);
    
}

function readLanguage(): string{
   const systemLanguage = navigator.language.split('-')[0]
   const lang = (localStorage.getItem(SELECTED_LANGUAGE)|| getLanguageList(systemLanguage).code || 'en');
   return lang;
}

function clearAuthCredentials(): void {
    sessionStorage.removeItem(LOCAL_USER_DATA);
    sessionStorage.removeItem(LOCAL_EXPIRATION);
}
function clearRememberMeData(): void {
    sessionStorage.removeItem(REMEMBER_ME_DATA);
}
function clearSelectedOrganization(): void {
    sessionStorage.removeItem(SELECTED_ORGANIZATION);
}
function clearSelectedServiceManagerOptions(): void {
    sessionStorage.removeItem(SELECTED_CUSTOMER);
}
export const SessionStorage = {
    readAuthData,
    saveAuthCredentials,
    saveRememberMeData,
    saveLanguage,
    readLanguage,
    clearAuthCredentials,
    saveTokenExpiration,
    clearRememberMeData,
    clearSelectedOrganization,
    clearSelectedServiceManagerOptions,
    saveAuthToken,
    getAuthToken
};
