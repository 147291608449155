import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_SELF_REGISTRATION } from './url-constants';

type UserSelfRegistrationEndpointSpecifications = ApiEndpointSpecification;
export const UserSelfRegistrationEndpointSpecification: UserSelfRegistrationEndpointSpecifications = {
    url: USER_SELF_REGISTRATION,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'UserSelfRegistrationRequestParams',
    requestBodySchemaName: 'UserSelfRegistrationRequestBody',
    okResponseSchemaName: 'UserSelfRegistrationOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeUserSelfRegistration = Helpers.makeApiRequestFn<
    Types.UserSelfRegistrationRequestParams,
    Types.UserSelfRegistrationRequestBody,
    Types.UserSelfRegistrationOkResponse
>(UserSelfRegistrationEndpointSpecification);
