import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_REGISTRATION_VERIFY_CODE_REQUEST } from './url-constants';

type UserRegistrationVerifyCodeRequestEndpointSpecifications = ApiEndpointSpecification;
export const UserRegistrationVerifyCodeRequestEndpointSpecification: UserRegistrationVerifyCodeRequestEndpointSpecifications = {
    url: USER_REGISTRATION_VERIFY_CODE_REQUEST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'UserRegistrationVerifyCodeRequestRequestParams',
    requestBodySchemaName: 'UserRegistrationVerifyCodeRequestRequestBody',
    okResponseSchemaName: 'UserRegistrationVerifyCodeRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeUserRegistrationVerifyCodeRequest = Helpers.makeApiRequestFn<
    Types.UserRegistrationVerifyCodeRequestRequestParams,
    Types.UserRegistrationVerifyCodeRequestRequestBody,
    Types.UserRegistrationVerifyCodeRequestOkResponse
>(UserRegistrationVerifyCodeRequestEndpointSpecification);
