// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Button, Card, CircularProgress, IconButton, InputAdornment, Table, TableBody, TablePagination, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { Close, GetApp, Search } from '@mui/icons-material';
import { ArrowDropDown } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import { vw_15 } from '../../constants/dimentions';
import { AddServiceManagerDialog } from './AddServiceManagerDialog';
import { AddPlatformDialog } from './AddPlatformDialog';
import { DropDownListComponent } from '../../components/DropDownList/DropDownListComponent';
import { CURRENT_PAGE_SESSION, ORGANIZATTION_NAME, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { HeadCell, Order } from '../../types/table';
import { STATUS_TYPE, deviceTypeEnum } from '../enum/ENUM';
import { EnhancedTableHead } from '../../components/Table/EnhancedTableHead';
import { getComparator, stableSort } from '../../lib/table-helpers';
import { PlatformDataList, PlatformListItems } from '../../types/platform';
import { EmptyState } from '@brightlayer-ui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { Thunks } from '../../actions/thunks';
import { PlatformListItem } from '../../components/UserManagementListItem/PlatformListItem';

type Props = {
    //TO-DO
};

export const PAAS: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const dispatch = useDispatch();
    const { t } = useLanguageLocale();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [openServiceManagerDialog, setOpenServiceManagerDialog] = React.useState(false);
    const DEFAULT_ID: string = '';
    const [searchText, setSearchText] = useState(DEFAULT_ID);
    const [filterStatus, setFilteredStatus] = React.useState('');
    const [statusId, setStatusId] = React.useState('');
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [platformList, setPlatformList] = useState<PlatformDataList>([]);
    const [emptyPlatformList, setEmptyPlatformList] = React.useState(false);
    const usermanagement = useSelector(Selectors.Usermanagement.usermanagement);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [offset, setOffset] = React.useState(1);
    const [platformLoaded, setPlatformLoaded] = React.useState(false);
    const [selectedPlatform, setSelectedPlatform] = React.useState(DEFAULT_ID);
    const [platformSave, setPlatformSave] = React.useState(false);


    const headCells: HeadCell[] = [
        { id: 'name', label: t('USERS.PLATFORM_NAME_HEADER'), hideSorting: false },
        { id: 'customerName', label: t('CUSTOMER_DETAILS.CUSTOMER_NAME'), hideSorting: false },
        { id: 'country', label: t('USERS.COUNTRY'), hideSorting: false },
        { id: 'mainServiceManagerName', label: t('USERS.MAIN_SERVICE_MANAGER_HEADER'), hideSorting: false },
        { id: 'activeStatus', label: t('LOGBOOK_DETAILS.STATUS'), hideSorting: false },
    ];

    const getAllPlatformList = (list: PlatformDataList): PlatformDataList => {
        return list.map((data, sla) => {
            return (
                {
                    id: data.id,
                    name: data.name,
                    country: data.country,
                    customerId: data.customerId,
                    customerName: data.customerName,
                    mainServiceManagerId: data.mainServiceManagerId,
                    mainServiceManagerName: data.mainServiceManagerName,
                    activeStatus: data.activeStatus ? 'Active' : 'InActive',
                    imageBase64: data.imageBase64,
                    imageType: data.imageType,
                    starmark: data.isFavorite ? 'true' : 'false',
                }
            );
        })
    };
    useEffect(() => {
        if (filterStatus !== '') {
            dispatch(Thunks.UserManagement.getPlatformList(searchText, STATUS_TYPE.ACTIVE, false, rowsPerPage.toString(), offset.toString()));
            setCurrentPage(0);
        } else {
            dispatch(Thunks.UserManagement.getPlatformList(searchText, STATUS_TYPE.ACTIVE, false, rowsPerPage.toString(), offset.toString()));
            const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
            setCurrentPage(parseInt(currentPageAfterSearchClear));
        }
        platformSave ? setPlatformSave(false) : platformSave;
        // eslint-disable-next-line
    }, [filterStatus, rowsPerPage, offset, platformSave]);

    useEffect(() => {
        if (!usermanagement.platformListLoading && usermanagement.platformListSuccess) {
            setEmptyPlatformList(usermanagement.platformList.detail.length <= 0)
            if (usermanagement.platformList.detail.length > 0) {
                const platformList = getAllPlatformList(usermanagement.platformList.detail);
                setPlatformList(platformList);
                setTotalRecords(usermanagement.platformList.totalRecordCount)
            } else {
                setPlatformList([]);
            }
            setPlatformLoaded(true);
        }
    }, [usermanagement.platformListLoading, usermanagement.platformListSuccess, usermanagement.platformListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (platformLoaded) {
            /* Debouncing to override user typing */
            const delayDebounceFn = setTimeout(() => {
                if (searchText !== '') {
                    dispatch(Thunks.UserManagement.getPlatformList(searchText, STATUS_TYPE.ACTIVE, false, rowsPerPage.toString(), '1'));
                    setCurrentPage(0);
                } else {
                    dispatch(Thunks.UserManagement.getPlatformList(searchText, STATUS_TYPE.ACTIVE, false, rowsPerPage.toString(), offset.toString()));
                    const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
                    setCurrentPage(parseInt(currentPageAfterSearchClear));
                }
            }, 3000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

    //**** Search text ***//
    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setSearchText(e.target.value);
    };

    const resetSearch = (): void => {
        setSearchText(DEFAULT_ID);
    };

    //*** Sorting ***//
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        // const sortedValue = stableSort(servicePartnerList, getComparator(order, orderBy));
        //setservicePartnerList(sortedValue);
    };

    const handleDisplay = (value: {
        platformData: PlatformListItems;
    }): void => {
        setSelectedPlatform(value.platformData.id);
        //setSlaDetailsOpenState(true);
    };

     //*** Pagination ***//
     const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, newPage.toString());
        setOffset(newPage + 1);
        setSelectedPlatform(DEFAULT_ID);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        setCurrentPage(0);
        setOffset(1);
        setSelectedPlatform(DEFAULT_ID);
    };

    return (
        <div id="PAAS-container" data-testid="PAAS-container">
            <div data-testid="PAAS-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    id="PAAS-add-user"
                    data-testid="PAAS-add-user"
                    style={{
                        flexDirection: 'row',
                        backgroundColor: PXBColors.white[50],
                        textTransform: 'none',
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={() => { setOpenServiceManagerDialog(true) }}
                >
                    {md ? t('USERS.ADD_PLATFORM') : <MuiIcons.Add></MuiIcons.Add>}
                </Button>
                <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto' }}>
                    <Typography
                        id="PAAS-favorite-filter-checkbox-lable"
                        data-testid="PAAS-favorite-filter-checkbox-lable"
                        style={{ alignSelf: 'center' }} variant="body2">
                        {t('USERS.SHOW_FAVORITE_PLATFORMS')}
                    </Typography>
                    <Checkbox id="PAAS-favorite-filter-checkbox"
                    />
                </div>
            </div>

            <div data-testid="PAAS-search" style={{ display: 'flex', }}>
                <DropDownListComponent disabled={true} data-testid="PAAS-status-filter" list={deviceTypeEnum} value={t('SEARCH.ALL_PLATFORMS')} handleSelection={setFilteredStatus} selected={statusId} defaultSelection={''}></DropDownListComponent>
                <TextField
                    inputProps={{ 'data-testid': 'search', maxLength: 100 }}
                    value={searchText}
                    style={{
                        width: 'auto',
                        textAlign: 'right',
                        float: 'right',
                        border: '0px solid #727E84',
                        borderRadius: 5,
                        backgroundColor: 'white',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        overflow: 'hidden',
                        marginTop: 28,
                        fontFamily: 'Open sans',
                        fontSize: 14,
                        color: '#727E84',
                        fontStyle: 'normal',
                        fontWeight: 400,
                    }}
                    placeholder={'Search'}
                    onChange={(e): void => onChangeSearchText(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search data-testid="search-icon" style={{ color: PXBColors.gray[500] }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchText !== '' && (
                                    <IconButton onClick={resetSearch}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>
            <Card style={{ flex: '1 1 0px', overflow: 'auto', marginTop: '2%' }} id="PAAS-list-container" data-testid="PAAS-list-container">

                <Table stickyHeader size="small"
                    id="PAAS-list-table"
                    data-testid="PAAS-list-table" style={{ width: '100%' }}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />

                    {!emptyPlatformList && !usermanagement.platformListLoading && <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable'}
                        id="PAAS-list-table-body"
                        data-testid="PAAS-list-table-body">
                        {stableSort(platformList, getComparator(order, orderBy)).map((row: PlatformListItems, index: number) => (
                            <PlatformListItem
                                key={row.id}
                                row={row}
                                selectedPlatform={selectedPlatform}
                                displayClick={handleDisplay}
                            ></PlatformListItem>
                        ))}
                    </TableBody>}
                </Table>
                {usermanagement.platformListLoading && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!usermanagement.platformListLoading && emptyPlatformList && <EmptyState style={{ flex: 1, height: 200 }}
                    title={t('USERS.ERROR_NO_PLATFORM_FOUND')} icon={undefined} placeholder={undefined} />}
            </Card >
            <div style={{ flex: '1 1 0px', backgroundColor: PXBColors.white[50] }}>
                {!emptyPlatformList && <TablePagination
                    component="div"
                    data-testid="pagination-logbook"
                    count={totalRecords}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('LABELS.ITEMS_PER_PAGE')}
                    showFirstButton showLastButton
                />}
            </div>
            <AddPlatformDialog platformSave={platformSave} setPlatformSave={setPlatformSave} openDialogFlag={openServiceManagerDialog} closeClick={() => setOpenServiceManagerDialog(false)} ></AddPlatformDialog>
        </div>
    );
};
