// eslint-disable-next-line
import React from 'react';

export const StatusLabel: React.FC<React.PropsWithChildren<React.PropsWithChildren<{
    status: string;
    backgroundColor: string;
    foregroundColor: string;
    testId?: string;
}>>> = (props) => (
    <div
        style={{
            display: 'inline-block',
            backgroundColor: props.backgroundColor,
            color: props.foregroundColor,
            height: '16px',
            fontWeight: 'bold',
            fontSize: '10px',
            letterSpacing: '1px',
            textAlign: 'center',
            lineHeight: '16px',
            marginRight: '10px',
            paddingLeft: '5px',
            paddingRight: '5px',
            borderRadius: '2px',
        }}
        data-testid={props.testId}
    >
        {props.status}
    </div>
);
