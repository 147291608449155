import { AppThunk } from '../actions';
import * as ApiRequests from '../../api/requests';
import { usermanagementActions } from '../actions/usermanagement-actions';
import { UserInviteEntityModel } from '../../types';
import { AuthHelper } from '../../types/auth-helper-types';

export const UsermanagementThunks = {
    getTechniciansList: (
        country: string,
        status: string,
        offSet?: string,
        Limit?: string,
        searchText?: string): AppThunk => async (dispatch): Promise<void> => {
            dispatch(usermanagementActions.technicianListStarted());

            const payload = await ApiRequests.requestTechniciansList(
                {
                    country: country,
                    status: status,
                    offSet: offSet,
                    Limit: Limit,
                    searchText: searchText
                },
                { authHelper: undefined }
            );

            if (payload.ok) {
                dispatch(usermanagementActions.technicianListSucceeded(payload.response.data));
                return;
            }

            dispatch(usermanagementActions.technicianListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        },

    getServiceManagersList: (
        country: string,
        status: string,
        offSet?: string,
        Limit?: string,
        searchText?: string): AppThunk => async (dispatch): Promise<void> => {
            dispatch(usermanagementActions.serviceManagerListStarted());

            const payload = await ApiRequests.requestServiceManagersList(
                {
                    country: country,
                    status: status,
                    offSet: offSet,
                    Limit: Limit,
                    searchText: searchText
                },
                { authHelper: undefined }
            );

            if (payload.ok) {
                dispatch(usermanagementActions.serviceManagerListSucceeded(payload.response.data));
                return;
            }

            dispatch(usermanagementActions.serviceManagerListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        },

    getPlatformList: (
        searchText: string,
        status: number,
        onlyStarred: boolean,
        limit?: string,
        offset?: string
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(usermanagementActions.platformListStarted());

        const payload = await ApiRequests.requestPlatformList(
            {
                searchText: searchText,
                status: status,
                offset: offset!,
                limit: limit!,
                onlyStarred: onlyStarred
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(usermanagementActions.platformListSucceeded(payload.response.data));
            return;
        }

        dispatch(usermanagementActions.platformListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    getServicePartnerCustomersList: (): AppThunk => async (dispatch): Promise<void> => {
        dispatch(usermanagementActions.servicePartnerCustomersListStarted());

        const payload = await ApiRequests.requestServicePartnerCustomerListList(
            {},
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(usermanagementActions.servicePartnerCustomersListSucceeded(payload.response.data));
            return;
        }

        dispatch(usermanagementActions.servicePartnerCustomersListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    addPlatform: (
        name: string,
        id: string,
        country: string,
        customerId: string,
        serviceManagerEmailId: string,
        authHelper: AuthHelper
    ): AppThunk => async (
        dispatch
    ): Promise<void> => {
            dispatch(usermanagementActions.addPlatformStarted());

            const payload = await ApiRequests.addPlatform(
                {
                    name: name,
                    id: id,
                    country: country,
                    customerId: customerId,
                    serviceManagerEmailId: serviceManagerEmailId
                },
                { authHelper: authHelper }
            );

            if (payload.ok) {
                dispatch(
                    usermanagementActions.addPlatformSucceeded(payload.response.data.id)
                );
            } else {
                dispatch(usermanagementActions.addPlatformFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
            }
        },
    addServiceManager: (
        emailId: string,
        platformId: string,
        authHelper: AuthHelper
    ): AppThunk => async (
        dispatch
    ): Promise<void> => {
            dispatch(usermanagementActions.addServiceManagerStarted());

            const payload = await ApiRequests.addServiceManager(
                {
                    emailId: emailId,
                    platformId: platformId,

                },
                { authHelper: authHelper }
            );

            if (payload.ok) {
                dispatch(
                    usermanagementActions.addServiceManagerSucceeded(payload.response.data.id)
                );
            } else {
                dispatch(usermanagementActions.addServiceManagerFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
            }
        },

    addServicePartnerCustomer: (
        customerId: string,
        companyName: string,
        customerNumber: string,
        companyAddress1: string,
        companyAddress2: string,
        postalCode: string,
        city: string,
        country: string,
        companyCountryCode: string,
        companyPhone: string,
        comments: string,
        serviceManagerEmail: string,
        serviceManagerFirstName: string,
        serviceManagerLastName: string,
        serviceManagerPrimaryCountryCode: string,
        serviceManagerPrimaryPhone: string,
        ServiceManagerCountryCode2: string,
        serviceManagerPhone2: string,
        ServiceManagerComments: string,
        primaryEmail: string,
        primaryFirstName: string,
        primaryLastName: string,
        primaryCountryCode: string,
        primaryPhone: string,
        primaryCountryCode2: string,
        primaryPhone2: string,
        primaryContactType: number,
        primaryContactComments: string,
        secondaryFirstName: string,
        secondaryLastName: string,
        secondaryCountryCode: string,
        secondaryPhone: string,
        secondaryCountryCode2: string,
        secondaryPhone2: string,
        secondaryEmail: string,
        secondaryContactType: number,
        secondaryContactComments: string,
        isInternalTeam: boolean,
        imageBase64: string,
        imageType: string,
        SLANumber: string,
        expiryDate: string
    ): AppThunk => async (
        dispatch
    ): Promise<void> => {
            dispatch(usermanagementActions.addServicePartnerCustomerStarted());

            const payload = await ApiRequests.addServicePartnerCustomer(
                {
                    customerId: customerId,
                    companyName: companyName,
                    customerNumber: customerNumber,
                    companyAddress1: companyAddress1,
                    companyAddress2: companyAddress2,
                    postalCode: postalCode,
                    city: city,
                    country: country,
                    companyCountryCode: companyCountryCode,
                    companyPhone: companyPhone,
                    comments: comments,
                    serviceManagerEmail: serviceManagerEmail,
                    serviceManagerFirstName: serviceManagerFirstName,
                    serviceManagerLastName: serviceManagerLastName,
                    serviceManagerPrimaryCountryCode: serviceManagerPrimaryCountryCode,
                    serviceManagerPrimaryPhone: serviceManagerPrimaryPhone,
                    ServiceManagerCountryCode2: ServiceManagerCountryCode2,
                    serviceManagerPhone2: serviceManagerPhone2,
                    ServiceManagerComments: ServiceManagerComments,
                    primaryEmail: primaryEmail,
                    primaryFirstName: primaryFirstName,
                    primaryLastName: primaryLastName,
                    primaryCountryCode: primaryCountryCode,
                    primaryPhone: primaryPhone,
                    primaryCountryCode2: primaryCountryCode2,
                    primaryPhone2: primaryPhone2,
                    primaryContactType: primaryContactType,
                    primaryContactComments: primaryContactComments,
                    secondaryFirstName: secondaryFirstName,
                    secondaryLastName: secondaryLastName,
                    secondaryCountryCode: secondaryCountryCode,
                    secondaryPhone: secondaryPhone,
                    secondaryCountryCode2: secondaryCountryCode2,
                    secondaryPhone2: secondaryPhone2,
                    secondaryEmail: secondaryEmail,
                    secondaryContactType: secondaryContactType,
                    secondaryContactComments: secondaryContactComments,
                    isInternalTeam: isInternalTeam,
                    imageBase64: imageBase64,
                    imageType: imageType,
                    SLANumber: SLANumber,
                    expiryDate: expiryDate
                },
                { authHelper: undefined }
            );

            if (payload.ok) {
                dispatch(
                    usermanagementActions.addServicePartnerCustomerSucceeded(payload.response.data.id)
                );
            } else {
                dispatch(usermanagementActions.addServicePartnerCustomerFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
            }
        },
    getServicePartnerCustomersDetailList: (
        id: string,
        country: string,
        searchText?: string,
        offSet?: string,
        Limit?: string,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(usermanagementActions.servicePartnerCustomersDetailListStarted());

        const payload = await ApiRequests.requestServicePartnerCustomerDetailList(
            {
                id: id,
                country: country,
                searchText: searchText,
                offset: offSet,
                limit: Limit,

            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(usermanagementActions.servicePartnerCustomersDetailListSucceeded(payload.response.data));
            return;
        }

        dispatch(usermanagementActions.servicePartnerCustomersDetailListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    getServiceManagerCustomersDetailList: (
        id: string,
        country: string,
        projectId?: string,
        searchText?: string,
        offSet?: string,
        Limit?: string
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(usermanagementActions.serviceManagerCustomersDetailListStarted());

        const payload = await ApiRequests.requestServiceManagerCustomerDetailList(
            {
                id: id,
                country: country,
                projectId: projectId,
                searchText: searchText,
                offset: offSet,
                limit: Limit,
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            dispatch(usermanagementActions.serviceManagerCustomersDetailListSucceeded(payload.response.data));
            return;
        }

        dispatch(usermanagementActions.serviceManagerCustomersDetailListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    getServicePartnerDetail: (id: string): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(usermanagementActions.servicePartnerDetailStarted());

        const payload = await ApiRequests.getServicePartnerDetail({ id }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(
                usermanagementActions.servicePartnerDetailSucceeded(payload.response.data)
            );
        } else {
            dispatch(usermanagementActions.servicePartnerDetailFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },
    getPlatformDetail: (platformId: string): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(usermanagementActions.platformDetailStarted());

        const payload = await ApiRequests.getPlatformDetail({ platformId }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(
                usermanagementActions.platformDetailSucceeded(payload.response.data)
            );
        } else {
            dispatch(usermanagementActions.platformDetailFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },
    getPlatformSystemInfo: (platformId: string): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(usermanagementActions.platformSystemInfoStarted());

        const payload = await ApiRequests.getPlatformSystemInfo({ platformId }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(
                usermanagementActions.platformSystemInfoSucceeded(payload.response.data)
            );
        } else {
            dispatch(usermanagementActions.platformSystemInfoFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },
    removeServiceUser: (userId: string, userType: number): AppThunk => async (dispatch): Promise<void> => {
        dispatch(usermanagementActions.removeServiceUserStarted());

        const payload = await ApiRequests.removeServiceUser({ userId, userType }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(usermanagementActions.removeServiceUserSucceeded());

        }
        else {

            dispatch(usermanagementActions.removeServiceUserFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },
};
