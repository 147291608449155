import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { LOGBOOK_DOWNLOAD_REPORT } from './url-constants';

type LogbookDownloadEndpointSpecification = ApiEndpointSpecification;
export const LogbookDownloadEndpointSpecifications: LogbookDownloadEndpointSpecification = {
    url: LOGBOOK_DOWNLOAD_REPORT,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'LogbookDownloadRequestParams',
    requestBodySchemaName: 'LogbookDownloadRequestBody',
    okResponseSchemaName: 'LogbookDownloadRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeLogbookDownloadReport = Helpers.makeApiRequestFn<
    Types.LogbookDownloadRequestParams,
    Types.LogbookDownloadRequestBody,
    Types.LogbookDownloadRequestOkResponse
>(LogbookDownloadEndpointSpecifications);

