// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Add, CheckBox, CheckBoxOutlineBlank, Close, ManageAccountsRounded } from '@mui/icons-material';
import { DialogContent, IconButton, DialogTitle, Typography, TextField, useMediaQuery, useTheme, Checkbox, Divider, Dialog, Button, DialogActions, Autocomplete, Snackbar, Portal } from '@mui/material';
import { COLOR_GRAY } from '../../constants/color-codes';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { ADD_ORGANIZATION_ID, BACK_STATE_SESSION, DEFAULT_STATE, EMPTY_STRING, ORG_EMAIL_MAX_LENGTH } from '../../constants';
import * as PXBColors from '@brightlayer-ui/colors';
import { ItemList } from '../../types/logbook-type';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from 'react-redux';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { useAuthHelper } from '../../components/AuthContextProvider';
import { UsermanagementThunks } from '../../actions/thunks/user-management-thunks';
import { validEmail } from '../../lib/form-validator';
import { Selectors } from '../../selectors';
import { usermanagementActions } from '../../actions/actions/usermanagement-actions';
import { PlatformDataList } from '../../types/platform';
import { Thunks } from '../../actions/thunks';
import { STATUS_TYPE } from '../../app/enum/ENUM';


type Props = {
    closeClick: Function;
    openDialogFlag: boolean;
    onSuccess: Function;
};
const paasManagerList: ItemList =
    [
        { id: '344866ba-486f-469e-9b72-95fe7120b421', name: 'Pass Manager 1' }, { id: '29ef4079-cb0e-4648-ae29-9db11d85eff0', name: 'Paas Manager 2' }
    ]
export const AddServiceManagerDialog: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const authHelper = useAuthHelper();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [emailAdvance, setEmailAdvance] = useState<string>('');
    const [paasManagerData, setPaasManagerData] = React.useState<ItemList>([]);//with label
    const [paasManagerId, setPaasManagerId] = useState<string>('');
    const [saveButtonDisable, setSaveButtonDisable] = useState(true);
    const userManagement = useSelector(Selectors.Usermanagement.usermanagement);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);



    function closeSelectOrgDialog(): void {
        props.closeClick({});
    }

    useEffect(() => {
        dispatch(Thunks.UserManagement.getPlatformList('', STATUS_TYPE.ACTIVE, false));
        // eslint-disable-next-line
    }, []);

    const handleAddServiceManagerResponse = (): void => {
        if (!userManagement.addServiceManagerLoading && userManagement.addServiceManagerFail) {
            if (userManagement.addServiceManagerErrorCode === '9043')
                setAlertErrorMessage(t('USERS.ADD_SERVICE_MANAGER_ERROR_CODE_9043'));
            else if (userManagement.addServiceManagerErrorCode === '3051')
                setAlertErrorMessage(t('USERS.ADD_SERVICE_MANAGER_ERROR_CODE_3051'));
            else if (userManagement.addServiceManagerErrorCode === '9001')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_LINK_CUSTOMER_ERROR_INVAILD_TOKEN'));
            else if (userManagement.addServiceManagerErrorCode === '3050')
                setAlertErrorMessage(t('USERS.ADD_SERVICE_MANAGER_ERROR_CODE_3050'));
            else if (userManagement.addServiceManagerErrorCode === '3052')
                setAlertErrorMessage(t('USERS.ADD_SERVICE_MANAGER_ERROR_CODE_3052'));
            else if (userManagement.addServiceManagerErrorCode === '900')
                setAlertErrorMessage(t('USERS.MANAGE_PLATFORM_ERROR_CODE_900'));
            else
                setAlertErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }

    };

    useEffect(() => {
        if (!userManagement.addServiceManagerLoading && userManagement.addServiceManagerSuccess) {
            setAlertSuccessMessage(t('USERS.ADD_SERVICE_MANAGER_SUCCESS_200'));
            setDisplaySuccessMessage(true);
            props.onSuccess(true);
            onCancel();

        } else if (!userManagement.addServiceManagerLoading && userManagement.addServiceManagerFail) {
            handleAddServiceManagerResponse();
            setDisplayErrorMessage(true);
        }
        dispatch(usermanagementActions.addServiceManagerUnmounted());
    }, [userManagement.addServiceManagerLoading, userManagement.addServiceManagerSuccess, userManagement.addServiceManagerFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!userManagement.platformListLoading && userManagement.platformListSuccess) {
            setupPlatformList(platformListData(userManagement.platformList.detail));
        }
    }, [userManagement.platformListLoading, userManagement.platformListSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSave = () => {
        dispatch(UsermanagementThunks.addServiceManager(emailAdvance, paasManagerId, authHelper));
    };

    useEffect(() => {
        if (validEmail(emailAdvance) && paasManagerId) {
            setSaveButtonDisable(false);
        }
        else {
            setSaveButtonDisable(true);
        }
    }, [emailAdvance, paasManagerId]); // eslint-disable-line react-hooks/exhaustive-deps

    const onCancel = () => {
        closeSelectOrgDialog();
        setPaasManagerId(EMPTY_STRING);
        setEmailAdvance(EMPTY_STRING);
    }

    const platformListData = (list: PlatformDataList): ItemList => {
        if (list.length > 0) {
            return list!?.map((data, servicePartner) => {
                return {
                    id: data.id!,
                    name: data.name!,
                };
            });
        } else
            return [];
    };

    const setupPlatformList = (platformList: ItemList): void => {
        // TO-DO
        // const addPaas: ItemList = [{ id: ADD_ORGANIZATION_ID, name: 'Create New PAAS' }];
        // const list = addPaas.concat(platformList);
        setPaasManagerData(platformList);
    }

    const addNewPAAS = (): void => {
    //TBD
    }

    return (
        <>
            <Dialog id="service-manager-dialog" maxWidth="md" open={props.openDialogFlag} >
                <IconButton style={{
                    position: 'absolute',
                    right: 6,

                }}
                    data-testid="close-service-manager-button"
                    onClick={onCancel}>
                    <Close fontSize="medium" />
                </IconButton>
                <DialogContent style={{ paddingTop: 0, minWidth: 700 }}>
                    <div style={{ display: 'flex', paddingTop: 30, justifyContent: 'center' }}>
                        <ManageAccountsRounded style={{ width: 34, height: 30, marginRight: 10 }} />
                        <DialogTitle id="service-manager-dialog-header" data-testid="service-manager-dialog-header" style={{ paddingLeft: 0, paddingTop: 0 }} >{t('USERS.ADD_SERVICE_MANAGER_DIALOG_HEADER')}</DialogTitle>
                    </div>
                    <Typography
                        id="service-manager-dialog-header-subtitle"
                        data-testid="service-manager-dialog-header-subtitle"
                        style={{ alignSelf: 'left', color: COLOR_GRAY, fontSize: 16, marginBottom: 24, textAlign: 'center' }} variant="body2">
                        {t('USERS.SERVICE_MANAGER_DIALOG_HEADER')}
                    </Typography>
                    <Divider />
                    <Typography
                        id="service-manager-user-select"
                        data-testid="service-manager-user-select"
                        style={{ alignSelf: 'left', marginBottom: 16, marginTop: 24 }} variant="subtitle1">
                        {t('MANAGE_USERS.ADVANCED_INVITE_USER_SELECTION')}
                    </Typography>
                    <div style={{
                        display: 'flex',
                        height: 50,
                    }}>

                        <TextField
                            id="service-manager-email"
                            data-testid="service-manager-label-email"
                            inputProps={{ 'data-testid': 'service-manager-email', maxLength: ORG_EMAIL_MAX_LENGTH }}
                            label={t('MANAGE_USERS.INVITE_USER_EMAIL_NAME')}
                            variant="filled"
                            value={emailAdvance}
                            error={emailAdvance.length > 0 && !validEmail(emailAdvance)}
                            helperText={emailAdvance.length > 0 && !validEmail(emailAdvance) ? (t('FORMS.INVALID_EMAIL_ERROR')) : ''}
                            onChange={(evt: ChangeEvent<HTMLInputElement>): void => setEmailAdvance(evt.target.value)}
                            style={{
                                alignSelf: 'center', width: md ? '65%' : '58%'
                            }} />
                    </div>
                    <Typography
                        id="invite-service-manager-organization-select"
                        data-testid="invite-service-manager-organization-select"
                        style={{ alignSelf: 'left', marginBottom: 16, marginTop: 16 }} variant="subtitle1">
                        {t('USERS.REGION_SELECT_HEADER_SERVICE_MANAGER')}
                    </Typography>

                    <div style={{
                        display: 'flex',
                        marginTop: 20
                    }}>
                        <div style={{ width: '65%', }}>
                            <Autocomplete
                                id="invite-user-select-org"
                                data-testid="autocomplete-org"
                                sx={{
                                    "& .MuiChip-label": { fontSize: 16 }
                                }}
                                style={{ backgroundColor: '#f7f8f8' }}
                                options={paasManagerData}
                                disableCloseOnSelect
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <div data-testid="select-option" style={{ flex: '1 1 0px', overflow: 'auto', fontSize: md ? 16 : 10, display: 'inline' }}>
                                            <Checkbox
                                                data-testid="checkbox-autocomplete"
                                                icon={<CheckBoxOutlineBlank />}
                                                checkedIcon={<CheckBox />}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                                onChange={() => { }}
                                            />
                                            {option.name}
                                            {/* {option.name === 'Create New PAAS' && <Divider style={{ paddingTop: 15 }} />} */}
                                        </div>
                                    </li>
                                )}
                                onChange={(event, newValue, reason) => {
                                    if (
                                        event.type === 'keydown' &&
                                        (event as React.KeyboardEvent).key === 'Backspace' &&
                                        reason === 'removeOption'
                                    ) { return; }
                                    // if (newValue?.name === 'Create New PAAS') {
                                    //     addNewPAAS();
                                    // }
                                    // else {
                                        setPaasManagerId(newValue?.id as unknown as string);
                                    // }
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        data-testid="invite-user-auto-org-text"
                                        {...params}
                                        label={t('USERS.ADD_TO_PLATFORM_LABEL')}
                                        variant="filled"
                                    />
                                )
                                }
                            />
                        </div>
                    </div>
                </DialogContent>
                <Divider style={{ color: 'grey' }} />
                <DialogActions style={{ marginBottom: 15, marginLeft: 16, marginRight: 17 }}>
                    <Button
                        style={{
                            background: PXBColors.blue[500], color: PXBColors.white[100],
                            opacity: saveButtonDisable ? 0.5 : 1,
                            textTransform: 'none'
                        }}
                        startIcon={<SaveIcon />}
                        disabled={saveButtonDisable}
                        id="btnSaveToAddServiceManagerId"
                        data-testid="btnSaveToAddServiceManager"
                        variant="contained"
                        onClick={handleSave}
                    >
                        {t('ACTIONS.SAVE')}
                    </Button>
                </DialogActions>
            </Dialog>
            {
                displaySuccessMessage &&
                <Snackbar
                    open={true}
                    onClick={() => setDisplaySuccessMessage(false)}
                    autoHideDuration={20}
                    title={''}
                    message={alertSuccessMessage}
                    action={
                        <Button color="inherit" size="small" onClick={onCancel}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.green[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

            {
                displayErrorMessage &&
                <Portal>
                <Snackbar
                    open={true}
                    onClick={() => setDisplayErrorMessage(false)}
                    autoHideDuration={20}
                    title={''}
                    message={alertErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.white[100],color: PXBColors.black[500]} }}
                    anchorOrigin={{  vertical: 'top' ,horizontal: "center" }}
                    style={{ height: '100%' ,width: 200, flexGrow: 0}}
                />
                </Portal>
            }

        </>
    )
};
