// eslint-disable-next-line
import React from 'react';
import { Typography } from '@mui/material';
import { ConfirmationPopUp } from '../ConfirmationPopUp/ConfirmationPopUp';

type discardPopupProps = {
    isDirty: boolean;
    onConfirm: Function;
    onCancel: Function;
};

export const UserConfirmation = (props: discardPopupProps): JSX.Element => (
    <ConfirmationPopUp
        title=""
        titleColor="error"
        body={
            <Typography data-testid="prompt-message" variant="subtitle1" style={{ marginBottom: 20 }}>
                Leaving this page will discard your changes. Do you wish to proceed?
            </Typography>
        }
        okButtonText={'Continue'}
        show={props.isDirty}
        handleOk={props.onConfirm}
        closeDialog={props.onCancel}
        cancelButtonText={'Cancel'}
        cancelButtonsShow={true}
        okButtonShow={true}
        width="xs"
    />
);
export default UserConfirmation;
