// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { Button, CardActions, CardContent, CardHeader, CircularProgress, Typography, Divider } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
//@ts-ignore
import { EmptyState } from '@brightlayer-ui/react-components/core';
import * as Colors from '@brightlayer-ui/colors';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import ReportIcon from '@mui/icons-material/Report';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { pushAppRoute } from '../../data/domain/route-manager';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import { SetPasswordControl } from '../../components/SetPasswordControl/SetPasswordControl';
import { BrandedCardContainer } from '../../components/BrandedCardContainer/BrandedCardContainer';


export const ResetPasswordContent: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const { t } = useLanguageLocale();
    const history = useHistory();
    const dispatch = useDispatch();
    // @ts-ignore
    const { code } = useParams();

    const [newPassword, setNewPassword] = useState('');
    const [passwordValid, setPasswordsValid] = useState(false);

    const verificationState = useSelector(Selectors.Authentication.passwordVerification);
    const passwordState = useSelector(Selectors.Authentication.password);

    const pendingContent = (
        <CardContent
            sx={{
                flex: '1 1 0px',
                overflow: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <CircularProgress data-testid={'progress-spinner'} id={'progress-spinner'} />
        </CardContent>
    );

    // Action is Pending
    if (verificationState.pending || passwordState.pending) {
        return pendingContent;
    }

    // Reset Code is Invalid
    if (!verificationState.pending && verificationState.code === code && !verificationState.success) {
        return (
            <>
                <CardContent>
                    <EmptyState
                        title={t('MESSAGES.FAILURE')}
                        description={t('FORGOT_PASSWORD.RESET_CODE_ERROR')}
                        icon={<div style={{ fontSize: 64 }}>
                            <ReportIcon id={'fail-icon'} fontSize={'inherit'} htmlColor={Colors.red[500]} />
                        </div>} placeholder={undefined}                    />
                </CardContent>
                <CardActions style={{ padding: 16, justifyContent: 'flex-end', marginTop: "auto" }}>
                    <Button
                        style={{ minWidth: 120 }}
                        variant={'contained'}
                        color={'primary'}
                        onClick={(): void => {
                            pushAppRoute(history, { type: 'ForgotPassword' });
                        }}
                        id="verify-failed-next-button"
                    >
                        {t('ACTIONS.RESEND')}
                    </Button>
                </CardActions>
            </>
        );
    }

    if (verificationState.code !== code || !verificationState.success) {
        return pendingContent;
    }

    // Reset Code is Valid
    return !passwordState.requested ? (
        <>
            <CardHeader data-testid={'reset-content-header'} id={'reset-content-header'} title={t('FORMS.RESET_PASSWORD')} />
            <CardContent
                data-testid="reset-password-entry-content">
                <Typography variant="body2" component="p" style={{ marginBottom: 50 }}>
                    {t('CHANGE_PASSWORD.PASSWORD_INFO')}
                </Typography>
                <Divider style={{ margin: '30px 0px' }} />

                <SetPasswordControl
                    passwordLabel={t('LABELS.NEW_PASSWORD')}
                    passwordValidate={(password: boolean): void => setPasswordsValid(password)}
                    onPasswordChange={(newPass: string): void => setNewPassword(newPass)}
                />
            </CardContent>
            <CardActions style={{ padding: 16, justifyContent: 'flex-end', marginTop: "auto" }}>
                <Button
                    disabled={!passwordValid}
                    style={{ minWidth: 120 }}
                    variant={'contained'}
                    color={'primary'}
                    onClick={(): Function =>
                        dispatch(Thunks.Authentication.setPassword(verificationState.code, newPassword))
                    }
                    id="reset-password-next-button"
                    data-testid="next-button"
                >
                    {t('ACTIONS.NEXT')}
                </Button>
            </CardActions>
        </>
    ) : (
        <>
            <CardContent
                data-testid="reset-password-success-content">
                <EmptyState
                        title={passwordState.success ? t('MESSAGES.SUCCESS') : t('MESSAGES.FAILURE')}
                        description={passwordState.success
                            ? t('PASSWORD_RESET.SUCCESS_MESSAGE')
                            : t('PASSWORD_RESET.FAILURE_MESSAGE')}
                        icon={<div style={{ fontSize: 64 }}>
                            {passwordState.success && (
                                <CheckIcon id={'success-icon'} data-testid={'success-icon'} fontSize={'inherit'} htmlColor={Colors.blue[500]} />
                            )}
                            {!passwordState.success && (
                                <ReportIcon id={'fail-icon'} data-testid={'fail-icon'} fontSize={'inherit'} htmlColor={Colors.red[500]} />
                            )}
                        </div>} placeholder={undefined}                />
            </CardContent>
            <CardActions style={{ padding: 16, marginTop: "auto" }}>
                <Button
                    id="reset-password-done-button"
                    disabled={!passwordValid}
                    style={{ flex: '1 1 0px' }}
                    variant={'contained'}
                    color={'primary'}
                    onClick={(): void => {
                        pushAppRoute(history, { type: 'Root' });
                    }}
                    data-testid="continue-button"
                >
                    {passwordState.success ? t('ACTIONS.CONTINUE') : t('ACTIONS.DONE')}
                </Button>
            </CardActions>
        </>
    );
};
export const ResetPassword = (): JSX.Element => {
    const dispatch = useDispatch();
    // @ts-ignore
    const { code } = useParams();

    useEffect(() => {
        // Verify the reset code is valid
        dispatch(Thunks.Authentication.verifyResetCode(code || ''));
    }, [code, dispatch]);

    return (
        <BrandedCardContainer>
            <ResetPasswordContent />
        </BrandedCardContainer>
    );
};
