export type DomainResponse<T> =
    | {
          ok: true;
          value: T;
      }
    | {
          ok: false;
          message?: string;
          stack?: string[];
      };

export type DomainResponseType<T> = T extends { ok: true; value: infer U } ? U : T;

export const makeOkDomainResponse = <T>(value: T): DomainResponse<T> => ({
    ok: true,
    value,
});

export const makeNotOkDomainResponse = <T>(message: string, stack?: string[]): DomainResponse<T> => ({
    ok: false,
    message,
    stack,
});
