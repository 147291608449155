// eslint-disable-next-line
import React, { useEffect } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Box, Typography, Paper, Button, useTheme, useMediaQuery } from '@mui/material';
import { GenericToolbar } from '../../components/GenericToolbar';
import * as PXBColors from '@brightlayer-ui/colors';
import SyncButtonImage from '../../assets/images/sync_button.svg';
import { OverviewStatisticSummary } from './OverviewStatisticSummary';
import { OverviewSystemLogs } from './OverviewSystemLogs';
import { Thunks } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PAGE_ONE, RECORD_PER_PAGE_LIMIT, RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM, SEPERATOR_COMMA } from '../../constants';
import OverviewMap from './OverviewMap';
import { OverviewDeviceList } from './OverviewDeviceList';
import { OverviewData, OverviewSelectedData } from '../../types/overview-type';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { pushAppRoute } from '../../data/domain/route-assets';
import { pushAppRouteOverview } from '../../data/domain/route-overview';
import { useHistory } from 'react-router-dom';
import { Selectors } from '../../selectors';
import { BuildingData, BuildingList } from '../../types/building';
import { EmptyState } from '@brightlayer-ui/react-components';
import { Add, Dashboard } from '@mui/icons-material';
import { ENTITY_TYPE, LOGBOOK_SEVERITY_STATUS, USER_ROLE_TYPE } from '../enum/ENUM';
import { rem_10, vh_10, vh_250, vw_10 } from '../../constants/dimentions';
import { TAB_BUILDING_KEY, TAB_SLA_KEY, TAB_SYSTEM_KEY, updateTabSession } from '../assets/Assets';
import { useAuthState, getUserRoleType } from '../../components/AuthContextProvider';
import { OverviewSystemServiceDueLogs } from './OverviewSystemServiceDueLogs';
import { OverviewCustomerStaticalSummary } from './OverviewCustomerStaticalSummary';
import { OverviewBuildingCardDetails } from './OverviewBuildingCardDetails';
import { DashboardActions } from '../../actions/actions/dashboard-actions';

type Props = {
    location: any;
    OnLogSelected: Function;
};
export const OverviewBuildingDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const history = useHistory();
    const dispatch = useDispatch();
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const asset = useSelector(Selectors.Dashboard.asset);
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';

    const { backRoute, buildingId, projectId, customerID } = props.location.state;

    const [systemEmptyState, setSystemEmptyState] = React.useState(false);
    const [searchSystemQuery, setSearchSystemQuery] = React.useState('');
    const DEFAULT_ID: string = '';
    const [projectName, setProjectName] = React.useState(t('DASHBOARD.ALL_ORGANIZATION'));

    const [buildingName, setBuildingName] = React.useState('');
    const [buildingLocation, setBuildingLocation] = React.useState('');
    const [primaryFirstName, setPrimaryFirstName] = React.useState('');
    const [primaryLastName, setPrimaryLastName] = React.useState('');
    const [selectedBuildingData, setSelectedBuildingData] = React.useState<BuildingData>();

    const [customerId, setCustomerId] = React.useState(customerID);

    const [deviceDataLoaded, setDeviceDataLoaded] = React.useState(false);

    const [dashboardHeader, setDashboardHeader] = React.useState(t('DASHBOARD.ALL_ORGANIZATION'));

    useEffect(() => {
        updateDashboard('', buildingId)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateDashboard = (projectId: string, buildingId: string) => {
        dispatch(DashboardActions.buildingListUnmounted());
        dispatch(Thunks.Dashboard.getBuildingList(projectId, buildingId));
        dispatch(Thunks.Asset.getDeviceList(projectId, buildingId, DEFAULT_ID, searchSystemQuery));
        dispatch(DashboardActions.statisticSummaryDashboardUnmounted());
        dispatch(Thunks.Dashboard.getStatisticSummaryDashboard('', projectId, buildingId,'',customerID));
        dispatch(Thunks.Users.getOrgUserList(buildingId));
        if (usertype === USER_ROLE_TYPE.SERVICE_MANAGER) {
            dispatch(Thunks.Logbook.getLatestLogs(projectId, buildingId, RECORD_PER_PAGE_LIMIT_CUSTOMER_ALARM.toString(), DEFAULT_PAGE_ONE.toString(), '', '', '', '', [LOGBOOK_SEVERITY_STATUS.STATUS_WARNING.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_ERROR.toString(), LOGBOOK_SEVERITY_STATUS.STATUS_BLOCK.toString()], true, '', usertype === USER_ROLE_TYPE.SERVICE_MANAGER));
        }
        else {
            dispatch(Thunks.Logbook.getLatestLogs(projectId, buildingId, RECORD_PER_PAGE_LIMIT.toString(), DEFAULT_PAGE_ONE.toString(), '', '', '', '', [], false, '', usertype === USER_ROLE_TYPE.SERVICE_MANAGER));
        }
    };

    const getBuildingData = (list: BuildingList): OverviewData => {
        const overviewList = list?.map((data, building) => {
            return (
                {
                    id: data.buildingId,
                    name: data.name,
                    elStatus: data.elStatus,
                    city: data.address + SEPERATOR_COMMA + data.city,
                    latitude: data.latitude,
                    longitude: data.longitude,
                    projectId: data.parentProjectId,
                    projectName: data.projectName,
                    primaryFirstName: data.primaryFirstName,
                    primaryLastName: data.primaryLastName,
                    systemCount: data.systemCount

                }
            );
        })

        //ToDo - remove filteration on building list API update for building Id filter
        const filteredList = overviewList.filter((building) => building.id === buildingId);

        return filteredList.length > 0 ? filteredList[0] : overviewList[0];
    };

    useEffect(() => {
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            const building: OverviewData = getBuildingData(dashboard.buildingList.detail)
            setBuildingName(building.name);
            setBuildingLocation(building.city);
            setDashboardHeader(building.name + ' < ' + building.projectName);
            setProjectName(building.projectName);
            setDeviceDataLoaded(true);
            setPrimaryFirstName(building.primaryFirstName ? building.primaryFirstName : '');
            setPrimaryLastName(building.primaryLastName ? building.primaryLastName : '');
            if (dashboard.buildingList.detail.length)
                setSelectedBuildingData(dashboard.buildingList.detail[0]);
        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            console.log('Building list loading failed');
        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (asset.deviceListLoading) {
            setSystemEmptyState(false);
        }
        else if (!asset.deviceListLoading && asset.deviceListSuccess) {
            setSystemEmptyState(searchSystemQuery === '' && asset.deviceList.detail.length <= 0)

        } else if (!asset.deviceListLoading && asset.deviceListFail) {
            setSystemEmptyState(true);
        }
    }, [asset.deviceListLoading, asset.deviceListSuccess, asset.deviceListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const NavigateToAssetDetails = (deviceId: string, deviceName: string, gatewayId: string, projectId: string): void => {
        pushAppRoute(
            history,
            { type: 'AssetDetails', deviceId, gatewayId, projectId },
            { backRoute: 'OverviewBuildingDetails', deviceId: deviceId, buildingId: buildingId, sourcePath: 'OverviewBuildingDetails',gatewayId:gatewayId }
        );
    };

    const NavigateToUserDetails = (): void => {
        pushAppRouteOverview(
            history,
            { type: 'ManageAssetUsers', assetId: buildingId },
            { backRoute: 'OverviewBuildingDetails', assetId: buildingId, assetType: ENTITY_TYPE.BUILDING, title: buildingName, orgId: projectId }
        );
    };

    const NavigateToEditBuilding = (): void => {
        pushAppRoute(history,
            { type: 'EditBuilding', orgId: projectId, buildingId: buildingId },
            { backRoute: 'OrgBuildingDetails', assetId: buildingId, assetType: ENTITY_TYPE.BUILDING, title: '', orgId: projectId, buildingId: buildingId });
    };

    const NavigateToBuildingDetails = (): void => {
        pushAppRouteOverview(
            history,
            { type: 'OrgBuildingDetails', buildingId: buildingId },
            { backRoute: 'Dashboard', buildingId: buildingId, projectId: projectId, assetId: buildingId, assetType: ENTITY_TYPE.BUILDING, title: '' }
        );
    };

    const NavigateToLogbookDetails = (logId: string, systemId: string): void => {
        pushAppRouteOverview(
            history,
            { type: 'LogbookDetails', logId: logId },
            { backRoute: 'OverviewBuildingDetails', logId: logId, systemId: systemId, buildingId: buildingId, projectId: projectId }
        );
    };

    const NavigateToSLAList = (): void => {
        pushAppRoute(
            history,
            { type: 'Assets' },
            { backRoute: 'ManagerUsers', customerNumber: customerId }
        );
        updateTabSession(TAB_SLA_KEY);
    };

    useEffect(() => {
        if (deviceDataLoaded) {
            /* Debouncing to override user typing */
            const delayDebounceFn = setTimeout(() => {
                dispatch(Thunks.Asset.getDeviceList(DEFAULT_ID, buildingId, DEFAULT_ID, searchSystemQuery));
            }, 3000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchSystemQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    const viewAllSystemNavigation = (): void => {
        pushAppRoute(
            history,
            { type: 'Assets' },
            { backRoute: 'Dashboard', buildingID: buildingId, projectID: projectId, customerID: customerId }
        );
        updateTabSession(TAB_SYSTEM_KEY);
    };

    const setSelectedElStatus = (value: { elstatus: number }): void => {
        pushAppRoute(
            history,
            { type: 'Assets' },
            { backRoute: 'Dashboard', elstatus: value.elstatus, buildingID: buildingId, projectID: projectId, customerID: customerId }
        );
        updateTabSession(TAB_SYSTEM_KEY);
    };

    const setSelectedBuildingStatus = (value: { buildingStatus: number }): void => {
        pushAppRoute(
            history,
            { type: 'Assets' },
            { backRoute: 'Dashboard', elstatus: value.buildingStatus, projectID: projectId, customerID: customerId }
        );
        updateTabSession(TAB_BUILDING_KEY);
    };
    const viewAllNavigation = (tab: string): void => {
        pushAppRoute(
            history,
            { type: 'Assets' },
            { backRoute: 'Dashboard', projectID: projectId, customerID: customerId }
        )
        updateTabSession(tab)
    };

    return (
        <>
            <GenericToolbar
                title={t('DASHBOARD.DASHBOARD_TITLE')}
                subtitle={dashboardHeader}
                color={ToolbarColor.Default}
                showBackButton={true}//backButtonState
                backButtonOnClick={(): void => {
                    if (backRoute === 'Dashboard') {
                        pushAppRouteOverview(history, {
                            type: backRoute,
                        });
                    } else if (backRoute === 'AssetDetails') {
                        history.go(-2); // todo: quickfix applied for goBack needs to called twice issue.
                    } else {
                        pushAppRoute(history, {
                            type: backRoute,
                        });
                    }
                }}
            />

            {systemEmptyState && <EmptyState style={{ flex: 1, height: md ? 600 : 'auto' }}
                icon={<Dashboard fontSize={'inherit'} />}
                title={t('ORGANIZATION_MANAGE.ERROR_NO_DEVICE_TITLE')}
                description={t('ORGANIZATION_MANAGE.ERROR_NO_DEVICE_DESCRIPTION')}
                actions={<>
                    <Button variant={'contained'} color={'primary'} onClick={() => { }} startIcon={<Add />}>
                        {t('ORGANIZATION_MANAGE.ERROR_ADD_NEW_SYSTEM_ACTION')}
                    </Button>
                    <Button style={{ marginLeft: 20, marginTop: md ? 0 : 20 }} variant={'outlined'} color={'primary'} onClick={() => { }}>
                        {t('ORGANIZATION_MANAGE.ERROR_READ_INSTALLATION_MANUALS_ACTION')}
                    </Button>
                </>} placeholder={undefined} />}

            {!systemEmptyState && <Paper square style={{ backgroundColor: '#F5F5F5' }}>
                <Box
                    display="flex"
                    style={{
                        flexDirection: 'column',
                        height: vh_250,
                        alignContent: 'center',
                    }}
                >
                    <OverviewMap userId={''} projectId={projectId} buildingId={buildingId} onBuildingNameClicked={() => { }} />
                </Box>

                {usertype === USER_ROLE_TYPE.SERVICE_MANAGER && <Box
                    display="flex"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        height: vh_10,
                        marginTop: vh_10,
                        width: '100%',
                        transitionDelay: '0.2s',
                    }}
                >
                    <Typography
                        variant="caption"
                        style={{
                            width: '92%',
                            textAlign: 'right',
                        }}
                    >
                        {t('COMMON.LAST_RETRIEVED')}
                    </Typography>

                    <Button
                        style={{
                            width: 20,
                            backgroundImage: `url(${SyncButtonImage})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'filled',
                            backgroundRepeat: 'no-repeat',
                        }}
                        color="primary"
                        data-testid="refresh-button"
                        onClick={() => updateDashboard(projectId, '')}
                    >
                        {''}
                    </Button>
                </Box>}

                {/* Service Manager View */}
                {usertype === USER_ROLE_TYPE.SERVICE_MANAGER &&

                    <Box
                        display="flex"
                        style={{
                            flexDirection: md ? 'row' : 'column',
                            alignItems: 'left',
                            padding: vh_10,
                            width: '100%',
                            height: 'wrap',
                            display: 'absolute',
                        }}
                    >
                        <Box
                            display="flex"
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignContent: 'flex-start',
                                display: 'flex',
                                flexWrap: 'wrap',
                                height: 'wrap',
                                width: md ? '33%' : '',
                                marginTop: -70,
                                transitionDelay: '0.2s',
                                marginBottom: rem_10
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: vh_10,
                                    alignContent: 'flex-start',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="customer-profile"
                            >
                                <OverviewBuildingCardDetails onUserClick={() => NavigateToUserDetails()} onSLAClick={() => NavigateToSLAList()}
                                    selectedId={buildingId} name={buildingName}
                                    location={buildingLocation}
                                    onBuildingDetailClick={() => NavigateToBuildingDetails()}
                                    OnEditSelectedAction={() => NavigateToEditBuilding()}
                                >
                                </OverviewBuildingCardDetails>
                            </Box>

                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    marginBottom: 'vh_10'
                                }}
                                data-testid="customer-device-profile"
                            >
                                <OverviewCustomerStaticalSummary projectId={projectId} buildingId={buildingId} elStatusClick={setSelectedElStatus} buildingStatusClick={setSelectedBuildingStatus} viewAllClicked={viewAllNavigation}></OverviewCustomerStaticalSummary>
                            </Box>
                        </Box>

                        <Box
                            display="flex"
                            style={{
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'flex-center',
                                marginLeft: vw_10,
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: md ? '66%' : '',
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    marginBottom: vh_10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="device-logs"
                            >
                                <OverviewSystemLogs
                                    OnLogSelected={(logId: string, systemId: string): void => {
                                        NavigateToLogbookDetails(logId, systemId);
                                    }}
                                    userId={''}
                                    buildingId={''}
                                    isDashboard={true}
                                    isLogbook={false}
                                    defaultOpenState={true}
                                    title={t('DASHBOARD.ON_GOING_ALERTS')}
                                    isLatestLogs={true}
                                    isServiceDashboard={usertype === USER_ROLE_TYPE.SERVICE_MANAGER}
                                />
                            </Box>

                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    height: 'wrap',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    alignItems: 'center'
                                }}
                                data-testid="luminaries-list"
                            >
                                <OverviewSystemServiceDueLogs
                                    OnLogSelected={(logId: string, systemId: string): void => {
                                        NavigateToLogbookDetails(logId, systemId);
                                    }}
                                    userId={''}
                                    buildingId={''}
                                    isDashboard={true}
                                    isService={true}
                                    defaultOpenState={true}
                                    title={t('DASHBOARD.UPCOMING_IN_DAYS')}
                                />
                            </Box>
                        </Box>
                    </Box>
                }

                {/* User View */}
                {usertype !== USER_ROLE_TYPE.SERVICE_MANAGER && <>
                    {/* Desktop View */}
                    {md ?
                        <Box
                            display="flex"
                            style={{
                                flexDirection: 'row',
                                alignItems: 'left',
                                padding: 10,
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    backgroundColor: 'transparent',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    height: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    marginTop: -50,
                                    width: '30%',
                                }}
                            >
                                <OverviewDeviceList
                                    userId={''}
                                    buildingId={buildingId}
                                    buildingName={buildingName}
                                    buildingLocation={buildingLocation}
                                    projectName={projectName}
                                    buildingContactName={primaryFirstName + " " + primaryLastName}
                                    OnDeviceSelected={(device: OverviewSelectedData): void => {
                                        NavigateToAssetDetails(device.id, device.name, device.gatewayId ? device.gatewayId : '', projectId);
                                    }}
                                    OnUserSelected={(): void => {
                                        NavigateToUserDetails();
                                    }}
                                    OnEditSelected={(): void => {
                                        NavigateToEditBuilding();
                                    }}
                                    OnSearchTextChanged={(query: string): void => setSearchSystemQuery(query)}
                                />
                            </Box>

                            <Box
                                display="flex"
                                style={{
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                    height: '100%',
                                    marginLeft: 10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '70%',
                                }}
                            >
                                <Box
                                    display="flex"
                                    style={{
                                        alignItems: 'right',
                                        justifyContent: 'flex-start',
                                        height: 15,
                                        marginBottom: 10,
                                        display: 'flex',
                                        width: '100%',
                                    }}
                                >
                                    <Typography
                                        variant="caption"
                                        style={{
                                            width: '92%',
                                            textAlign: 'right',
                                        }}
                                    >
                                        {t('COMMON.LAST_RETRIEVED')}
                                    </Typography>

                                    <Button
                                        style={{
                                            width: 20,
                                            backgroundImage: `url(${SyncButtonImage})`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'filled',
                                            backgroundRepeat: 'no-repeat',
                                        }}
                                        color="primary"
                                        data-testid="refresh-button"
                                        onClick={() => updateDashboard(projectId, buildingId)}
                                    >
                                        {''}
                                    </Button>
                                </Box>

                                <Box
                                    display="flex"
                                    style={{
                                        alignItems: 'center',
                                        backgroundColor: PXBColors.white[400],
                                        flexDirection: 'row',
                                        height: 'auto',
                                        marginBottom: 10,
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        width: '100%',
                                    }}
                                    data-testid="overview-status-summary"
                                >
                                    <OverviewStatisticSummary userId={''} projectId={''} elStatusClick={setSelectedElStatus} viewAllClick={viewAllSystemNavigation}/>
                                </Box>

                                <Box
                                    display="flex"
                                    style={{
                                        backgroundColor: PXBColors.white[400],
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        height: 'auto',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        width: '100%',
                                    }}
                                >
                                    <OverviewSystemLogs
                                        OnLogSelected={(logId: string, systemId: string): void => {
                                            NavigateToLogbookDetails(logId, systemId);
                                        }}
                                        userId={''}
                                        buildingId={''}
                                        isDashboard={true}
                                        isLogbook={false}
                                        defaultOpenState={true}
                                        title={t('DASHBOARD.LATEST_LOGS')}
                                        isLatestLogs={true}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        :
                        //Mobile view
                        <div className="flex-container">
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                    padding: '1%',
                                }}
                            >
                                <Box
                                    display="flex"
                                    style={{
                                        order: 3,
                                        backgroundColor: 'transparent',
                                        flexDirection: 'row',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <OverviewDeviceList
                                        userId={''}
                                        buildingId={buildingId}
                                        buildingName={buildingName}
                                        buildingLocation={buildingLocation}
                                        projectName={projectName}
                                        buildingContactName={primaryFirstName + " " + primaryLastName}
                                        OnDeviceSelected={(device: OverviewSelectedData): void => {
                                            NavigateToAssetDetails(device.id, device.name, device.gatewayId ? device.gatewayId : '', projectId);
                                        }}
                                        OnUserSelected={(): void => {
                                            NavigateToUserDetails();
                                        }}
                                        OnEditSelected={(): void => {
                                            NavigateToEditBuilding();
                                        }}
                                        OnSearchTextChanged={(query: string): void => setSearchSystemQuery(query)}
                                    />
                                </Box>

                                <Box
                                    display="flex"
                                    style={{
                                        alignItems: 'right',
                                        justifyContent: 'flex-start',
                                        height: 15,
                                        marginBottom: 10,
                                        display: 'flex',
                                        width: '100%',
                                        order: 1,
                                    }}
                                >
                                    <Typography
                                        variant="caption"
                                        style={{
                                            width: '92%',
                                            textAlign: 'right',
                                        }}
                                    >
                                        {t('COMMON.LAST_RETRIEVED')}
                                    </Typography>

                                    <Button
                                        style={{
                                            width: 20,
                                            backgroundImage: `url(${SyncButtonImage})`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'filled',
                                            backgroundRepeat: 'no-repeat',
                                        }}
                                        color="primary"
                                        data-testid="refresh-button"
                                        onClick={() => updateDashboard(projectId, buildingId)}
                                    >
                                        {''}
                                    </Button>
                                </Box>

                                <Box
                                    display="flex"
                                    style={{
                                        alignItems: 'center',
                                        backgroundColor: PXBColors.white[400],
                                        flexDirection: 'row',
                                        marginBottom: 10,
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        width: '100%',
                                        order: md ? '' : 2
                                    }}
                                    data-testid="overview-status-summary"
                                >
                                    <OverviewStatisticSummary userId={''} projectId={''} elStatusClick={setSelectedElStatus} buildingId={buildingId} viewAllClick={viewAllSystemNavigation} />
                                </Box>

                                <Box
                                    display="flex"
                                    style={{
                                        backgroundColor: PXBColors.white[400],
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        marginTop: 10,
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        width: '100%',
                                        order: md ? '' : 4
                                    }}
                                >
                                    <OverviewSystemLogs
                                        OnLogSelected={(logId: string, systemId: string): void => {
                                            NavigateToLogbookDetails(logId, systemId);
                                        }}
                                        userId={''}
                                        buildingId={''}
                                        isDashboard={true}
                                        isLogbook={false}
                                        defaultOpenState={true}
                                        title={t('DASHBOARD.LATEST_LOGS')}
                                        isLatestLogs={true}
                                    />
                                </Box>
                            </Box>
                        </div>
                    }
                </>}
            </Paper>}
        </>
    );
};
