import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import {  MERGE_BUILDING_ASSETS} from './url-constants';

type MergeBuildingAssetsEndpointSpecifications = ApiEndpointSpecification;
export const MergeBuildingAssetsEndpointSpecification: MergeBuildingAssetsEndpointSpecifications = {
    url: MERGE_BUILDING_ASSETS,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'MergeBuildingAssetsRequestParams',
    requestBodySchemaName: 'MergeBuildingAssetsRequestBody',
    okResponseSchemaName: 'MergeBuildingAssetsOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeMergeBuildingAssets = Helpers.makeApiRequestFn<
    Types.MergeBuildingAssetsRequestParams,
    Types.MergeBuildingAssetsRequestBody,
    Types.MergeBuildingAssetsOkResponse
>(MergeBuildingAssetsEndpointSpecification);
