// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Button, Card, Checkbox, CircularProgress, IconButton, InputAdornment, MenuItem, Select, SelectChangeEvent, Table, TableBody, TablePagination, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ArrowDropDown, Close, GetApp, Search } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { HeadCell, Order } from '../../types/table';
import { Selectors } from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { BuildingDataList, BuildingList, BuildingListItems } from '../../types/building';
import { EnhancedTableHead } from '../../components/Table/EnhancedTableHead';
import { getComparator, stableSort } from '../../lib/table-helpers';
import { Thunks } from '../../actions';
import { AssetBuildingListItem } from '../../components/AssetListItem/AssetBuildingListItem';
import { ItemList } from '../../types/logbook-type';
import { DropDownListComponent } from '../../components/DropDownList/DropDownListComponent';
import { EmptyState } from '@brightlayer-ui/react-components';
import { DashboardThunks } from '../../actions/thunks/dashboard-thunks';
import { CURRENT_PAGE_SESSION, DEFAULT_PAGE_ONE, ORGANIZATTION_NAME, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { vw_15 } from '../../constants/dimentions';
import * as MuiIcons from '@mui/icons-material';
import { DEVICE_STATUS, USER_ROLE_TYPE, buildingStatusEnum, deviceStatusEnum } from '../enum/ENUM';
import { AssetAddBuildingDialog } from './AssetAddBuildingDialog';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { OrganizationActions } from '../../actions/actions/organization-actions';
import { DashboardActions } from '../../actions/actions/dashboard-actions';
import { customerListV2Data } from '../../types/customer-detail';
import { CustomerActions } from '../../actions/actions/customer-actions';
import { selectStyle } from './selectStyle';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider';

type Props = {
    buildingClick: Function;
    gatewayCountClick: Function;
    systemCountClick: Function;
    orgList: ItemList;
    defaultProject: string;
    elStatus: string;
    customerId?: string;
};

export const AssetsBuildings: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [emptyBuildingList, setEmptyBuildingList] = React.useState(false);
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const organization = useSelector(Selectors.Organization.organization);
    const customer = useSelector(Selectors.Customer.customer);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selectedBuilding, setSelectedBuilding] = React.useState('0');
    const [buildingList, setBuildingList] = useState<BuildingDataList>([]);
    const [buildingDataList, setBuildingDataList] = useState<BuildingDataList>([]);
    const [projects] = useState<ItemList>(props.orgList);
    const [filterProject, setFilteredProject] = React.useState(props.defaultProject !== undefined ? props.defaultProject : '');
    const [filterCustomer, setFilteredCustomer] = React.useState(props.customerId !== undefined ? props.customerId : '');
    const [searchText, setSearchText] = useState('');
    const [isFavourite, setFavourite] = useState(false);
    const [buildingDataLoaded, setBuildingDataLoaded] = React.useState(false);
    const [openOrgSelectDialog, setOpenOrgSelectDialog] = React.useState(false);
    const useOrganizationDataList = useOrganizationList();
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [filterStatus, setFilteredStatus] = React.useState<string[]>(props.elStatus !== undefined ? (props.elStatus !== '' ? [props.elStatus] : []) : []);
    const [offset, setOffset] = React.useState(1);
    const DEFAULT_ID: string = '';
    const [customerList, setCustomerList] = React.useState<ItemList>([]);
    const [focusedProject, setFocusedProject] = React.useState(filterProject ? true : false);
    const [focusedCustomer, setFocusedCustomer] = React.useState(filterCustomer ? true : false);
    const defaultSelect = props.elStatus !== undefined ? props.elStatus : "";
    const [firstCall, setFirstCall] = React.useState(true);
    const [isFav, setIsFav] = React.useState(false);

    const headCells: HeadCell[] = [
        { id: 'name', label: t('DEVICE_DETAILS.NAME'), hideSorting: false },
        { id: 'projectName', label: t('DEVICE_DETAILS.ORGANIZATION'), hideSorting: false },
        { id: 'city', label: t('DEVICE_DETAILS.CITY'), hideSorting: false },
        { id: 'gatewayCount', label: t('DEVICE_DETAILS.GATEWAYS'), hideSorting: false },
        { id: 'systemCount', label: t('DEVICE_DETAILS.SYSTEMS'), hideSorting: false },
        { id: 'elStatusPriority', label: t('DEVICE_DETAILS.STATUS'), hideSorting: false },
    ];

    const getBuildingDataList = (list: BuildingList): BuildingDataList => {
        return list.map((data, building) => {
            return (
                {
                    buildingId: data.buildingId,
                    name: data.name,
                    parentProjectId: data.parentProjectId,
                    projectName: data.projectName,
                    city: data.city,
                    elStatus: data.elStatus,
                    elStatusPriority: getBuildingStatusPriority(data.elStatus),
                    gatewayCount: data.gatewayCount,
                    systemCount: data.systemCount,
                    isFavourite: data.isFavourite ? 1 : 0,
                }
            );
        })
    };

    const getBuildingStatusPriority = (status: number): number => {
        switch (status) {
            case DEVICE_STATUS.STATUS_BLOCK:
                return 1;
            case DEVICE_STATUS.STATUS_ERROR:
                return 2;
            case DEVICE_STATUS.STATUS_OFFLINE:
                return 3;
            case DEVICE_STATUS.STATUS_WARNING:
                return 4;
            case DEVICE_STATUS.STATUS_READY:
                return 5;
            case DEVICE_STATUS.STATUS_OFFLINE_DISABLE:
                return 6;
            case DEVICE_STATUS.STATUS_DT:
                return 7;
            default:
                return 0;
        }
    };

    useEffect(() => {
        if (firstCall) {
            if (!dashboard.buildingListLoading && !dashboard.buildingListSuccess) {
                (usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) ? dispatch(Thunks.Customer.getCustomerListV2(filterProject)) : '';
                dispatch(Thunks.Dashboard.getBuildingList(filterProject, '', searchText, filterCustomer, filterStatus, DEFAULT_PAGE_ONE.toString(), rowsPerPage.toString(), isFav));
            }
            if (dashboard.buildingListSuccess && !dashboard.buildingListLoading) {
                (usertype === USER_ROLE_TYPE.SERVICE_MANAGER || usertype === USER_ROLE_TYPE.TECHNICIAN) ? dispatch(Thunks.Customer.getCustomerListV2(filterProject)) : '';
                dispatch(Thunks.Dashboard.getBuildingList(filterProject, '', searchText, filterCustomer, filterStatus, DEFAULT_PAGE_ONE.toString(), rowsPerPage.toString(), isFav));
            }
            setFirstCall(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            dispatch(DashboardActions.buildingListUnmounted());
            dispatch(CustomerActions.customerListDataUnmounted());
            dispatch(Thunks.Dashboard.getBuildingList(filterProject, '', searchText, filterCustomer, filterStatus, offset.toString(), rowsPerPage.toString(), isFav));
            usertype === USER_ROLE_TYPE.SERVICE_MANAGER ? dispatch(Thunks.Customer.getCustomerListV2(filterProject)) : '';
        }
    }, [filterProject]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            dispatch(DashboardActions.buildingListUnmounted());
            if (filterStatus !== ([])) {
                dispatch(Thunks.Dashboard.getBuildingList(filterProject, '', searchText, filterCustomer, filterStatus, DEFAULT_PAGE_ONE.toString(), rowsPerPage.toString(), isFav));
                setCurrentPage(0);
            }
            else {
                dispatch(Thunks.Dashboard.getBuildingList(filterProject, '', searchText, filterCustomer, filterStatus, DEFAULT_PAGE_ONE.toString(), rowsPerPage.toString(), isFav));
            }
        }
    }, [filterStatus]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            if (buildingDataLoaded) {
                /* Debouncing to override user typing */
                dispatch(DashboardActions.buildingListUnmounted());
                const delayDebounceFn = setTimeout(() => {
                    if (searchText !== '') {
                        dispatch(DashboardThunks.getBuildingList(filterProject, '', searchText, filterCustomer, filterStatus, DEFAULT_PAGE_ONE.toString(), rowsPerPage.toString(), isFav));
                        setCurrentPage(0);
                    } else {
                        dispatch(DashboardThunks.getBuildingList('', '', searchText, '', [], offset.toString(), rowsPerPage.toString(), isFav));
                        const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
                        setCurrentPage(parseInt(currentPageAfterSearchClear));
                    }
                }, 3000)
                return () => clearTimeout(delayDebounceFn)
            }
        }
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            dispatch(DashboardActions.buildingListUnmounted());
            dispatch(Thunks.Dashboard.getBuildingList(filterProject, '', searchText, filterCustomer, filterStatus, offset.toString(), rowsPerPage.toString(), isFav));
            const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
            setCurrentPage(parseInt(currentPageAfterSearchClear));
        }
    }, [rowsPerPage, offset, filterCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            if (!organization.markasFavLoading && organization.markasFavSuccess) {
                addToast(t('TOAST_MSG.MARK_AS_FAV_API_SUCCESS'));
                dispatch(DashboardThunks.getBuildingList(filterProject, '', searchText, filterCustomer, filterStatus, offset.toString(), rowsPerPage.toString(), isFav));
            }
            else if (!organization.markasFavLoading && organization.markasFavFail) {
                if (organization.errorCode === '9183') {
                    addToast(t('ORGANIZATION_MANAGE.MARK_AS_FAV_API_FAILURE_MESSAGE'));
                }
                else if (organization.errorCode === '9001') {
                    addToast(t('TOAST_MSG.REMOVE_ACCESS_ERROR_CODE_9001'));
                }
                else {
                    addToast(t('TOAST_MSG.MARK_AS_FAV_API_FAILED'));
                }
                dispatch(OrganizationActions.markasFavUnmounted());
            }
        }
    }, [organization.markasFavLoading, organization.markasFavSuccess, organization.markasFavFail]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            if (!organization.removeMarkAsFavLoading && organization.removeMarkAsFavSuccess) {
                addToast(t('TOAST_MSG.REMOVE_MARK_AS_FAV_API_SUCCESS'));
                dispatch(DashboardThunks.getBuildingList(filterProject, '', searchText, filterCustomer, filterStatus, offset.toString(), rowsPerPage.toString(), isFav));
            }
            else if (!organization.removeMarkAsFavLoading && organization.removeMarkAsFavFail) {
                if (organization.errorCode === '9001')
                    addToast(t('TOAST_MSG.REMOVE_ACCESS_ERROR_CODE_9001'));
                else
                    addToast(t('TOAST_MSG.REMOVE_MARK_AS_FAV_API_FAILED'));
                dispatch(OrganizationActions.removeMarkAsFavUnmounted());
            }
        }
    }, [organization.removeMarkAsFavLoading, organization.removeMarkAsFavSuccess, organization.removeMarkAsFavFail]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!customer.customerListLoading && customer.customerListSuccess) {
            setCustomerList(customerListData(customer.customerList.customerDetailList));
        }
        else if (!customer.customerListLoading && customer.customerListFail) {
            setCustomerList([]);
            // setCustomerId(DEFAULT_ID);
        }
    }, [customer.customerListLoading, customer.customerListSuccess, customer.customerListFail]); // eslint-disable-line react-hooks/exhaustive-deps


    const customerListData = (list: customerListV2Data): ItemList => {
        return list?.map((data, customer) => {
            return {
                id: data.id,
                name: data.companyName,
                number: data.customerNumber
            };
        });
    };

    useEffect(() => {
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            setEmptyBuildingList(dashboard.buildingList.detail.length <= 0)
            const buildingList = getBuildingDataList(dashboard.buildingList.detail);
            setBuildingList(buildingList);
            setTotalRecords(dashboard.buildingList.totalRecordCount);
            //handle faviourite
            if (isFavourite) {
                setBuildingDataList(buildingList.filter((data, device) => { return (data.isFavourite === 1) }));
            } else {
                setBuildingDataList(buildingList);
            }
            setBuildingDataLoaded(true);

        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            // addToast('Building list loading failed');
            setBuildingDataList([]);
            setEmptyBuildingList(true);
            setBuildingList([]);

        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const sortedValue = stableSort(buildingDataList, getComparator(order, orderBy));
        setBuildingDataList(sortedValue);
    };

    // Navigation for selected building to dashboard
    const handleDisplay = (value: {
        buildingId: string;
        projectId: string;
    }): void => {
        setSelectedBuilding(value.buildingId)
        props.buildingClick({ buildingId: value.buildingId, projectId: value.projectId });
    };

    const gatewayCountClick = (value: {
        buildingId: string;
        projectId: string;
    }): void => {
        props.gatewayCountClick({ buildingId: value.buildingId, projectId: value.projectId });
    };

    const systemCountClick = (value: {
        buildingId: string;
        projectId: string;
    }): void => {
        props.systemCountClick({ buildingId: value.buildingId, projectId: value.projectId });
    };

    function downloadDetails(): void {
        // throw new Error("Function not implemented.");
    }

    const handleFavoriteChange = (
        event: React.ChangeEvent<HTMLElement>,
        checked: boolean,
    ) => {
        if (checked) {
            setIsFav(true);
            dispatch(Thunks.Dashboard.getBuildingList(filterProject, '', searchText, filterCustomer, filterStatus, DEFAULT_PAGE_ONE.toString(), rowsPerPage.toString(), true));
            setCurrentPage(0);
        } else {
            setIsFav(false);
            dispatch(Thunks.Dashboard.getBuildingList(filterProject, '', searchText, filterCustomer, filterStatus, DEFAULT_PAGE_ONE.toString(), rowsPerPage.toString(), false));
        }
    };



    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setSearchText(e.target.value);
    };

    const resetSearch = (): void => {
        setSearchText('');
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, newPage.toString());
        setOffset(newPage + 1);
        setSelectedBuilding(DEFAULT_ID);
    };


    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        setCurrentPage(0);
        setOffset(1);
        setSelectedBuilding(DEFAULT_ID);
    };

    const customerOnChange = (event: SelectChangeEvent) => {
        setFilteredCustomer(event.target.value);
        setFocusedCustomer(event.target.value !== '');

    };

    const projectOnChange = (event: SelectChangeEvent) => {
        setFilteredProject(event.target.value);
        setFilteredCustomer(DEFAULT_ID);
        setFocusedProject(event.target.value !== '');
        setFocusedCustomer(false);
    };

    return (
        <div id="asset-building-container">
            <div style={{ display: 'flex' }}>
                <Button
                    style={{
                        flexDirection: 'row',
                        backgroundColor: PXBColors.white[50],
                        textTransform: 'none',
                    }}
                    id="btnChangePassword"
                    variant="outlined"
                    color="primary"
                    data-testid="building-button"
                    onClick={() => { setOpenOrgSelectDialog(true) }}
                >
                    {md ? t('ASSETS_DETAILS.ADD_BUILDINGS') : <MuiIcons.Add></MuiIcons.Add>}
                </Button>
                <Button
                    disabled={true}
                    data-testid="building-download-as-button"
                    style={{
                        opacity: 0.5,
                        flexDirection: 'row',
                        border: 1,
                        borderColor: 'divider',
                        backgroundColor: PXBColors.blue[700],
                        color: PXBColors.white[50],
                        marginLeft: vw_15,
                        marginRight: vw_15,
                        textTransform: 'none',
                    }}
                    onClick={(): void => downloadDetails()}
                >
                    <GetApp style={{ padding: 3 }} />
                    {md ? t('ASSETS_DETAILS.DOWNLOAD_AS') : ''}
                    <ArrowDropDown style={{ padding: 3 }} />
                </Button>
                <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto' }}>
                    <Typography
                        id="buildings-favorite-filter-checkbox-label"
                        data-testid="buildings-favorite-filter-checkbox-label"
                        style={{ alignSelf: 'center' }} variant="body2">
                        {t('ASSETS_DETAILS.SHOW_FAVORITE_BUILDINGS')}
                    </Typography>
                    <Checkbox style={{ padding: 5 }} id="buildings-favorite-filter-checkbox"
                        onChange={handleFavoriteChange} />
                </div>
            </div>

            <div data-testid="asset-buildings-search-panel" style={{ display: 'flex' }}>
                <Select
                    disabled={!useOrganizationDataList.orgListLoaded || dashboard.buildingListLoading}
                    id="project-list-dropdown"
                    data-testid="asset-buildings-project-filter"
                    style={{
                        backgroundColor: focusedProject ? PXBColors.lightBlue[50] : PXBColors.white[50],
                        marginRight: '1%',
                        width: 'auto',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        borderRadius: 32,
                        overflow: 'hidden',
                        fontSize: 14,
                        color: focusedProject ? PXBColors.blue[500] : PXBColors.black[500],
                        fontStyle: 'normal',
                        fontWeight: 400,
                        marginTop: 28,
                    }}
                    value={filterProject}
                    onChange={projectOnChange}
                    displayEmpty
                >
                    <MenuItem value=""
                        style={selectStyle.dropdown}>{t('SEARCH.ALL_ORGANIZATIONS')}</MenuItem>
                    {projects.map((data, val) => {
                        return (
                            <MenuItem id="system-list" key={data.id} value={data.id}
                                style={
                                    selectStyle.dropdown
                                }>
                                {data.name}
                            </MenuItem>
                        );
                    })}
                </Select>
                {usertype === USER_ROLE_TYPE.SERVICE_MANAGER && <Select
                    disabled={customer.customerListLoading}
                    id="customer-list-dropdown"
                    data-testid="asset-buildings-customer-filter"
                    style={{
                        backgroundColor: focusedCustomer ? PXBColors.lightBlue[50] : PXBColors.white[50],
                        marginRight: '1%',
                        width: 'auto',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        borderRadius: 32,
                        overflow: 'hidden',
                        fontSize: 14,
                        color: focusedCustomer ? PXBColors.blue[500] : PXBColors.black[500],
                        fontStyle: 'normal',
                        fontWeight: 400,
                        marginTop: 28,
                    }}
                    value={filterCustomer}
                    onChange={customerOnChange}
                    displayEmpty
                >
                    <MenuItem value=""
                        style={
                            selectStyle.dropdown
                        }>{t('SEARCH.ALL_CUSTOMER')}</MenuItem>
                    {customerList.map((data, val) => {
                        return (
                            <MenuItem id="system-list" key={data.id} value={data.id}
                                style={selectStyle.dropdown}>
                                {data.name}
                            </MenuItem>
                        );
                    })}
                </Select>}
                <DropDownListComponent disabled={dashboard.buildingListLoading} data-testid="asset-buildings-status-filter" list={buildingStatusEnum} value={t('SEARCH.ALL_STATUSES')} handleSelection={(selectedValue: string) => { if (selectedValue !== '') { setFilteredStatus([selectedValue]) } else { setFilteredStatus([]) } }} selected={''} defaultSelection={defaultSelect}></DropDownListComponent>
                <TextField
                    inputProps={{ 'data-testid': 'search', maxLength: 100 }}
                    value={searchText}
                    style={{
                        width: 'auto',
                        textAlign: 'right',
                        float: 'right',
                        border: '0px solid #727E84',
                        borderRadius: 5,
                        backgroundColor: 'white',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        overflow: 'hidden',
                        marginTop: 28,
                        fontSize: 14,
                        color: '#727E84',
                        fontStyle: 'normal',
                        fontWeight: 400,
                    }}
                    placeholder={t('ORGANIZATION_MANAGE.SEARCH_BUILDINGS')}
                    onChange={(e): void => onChangeSearchText(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search data-testid="search-icon" style={{ color: PXBColors.gray[500] }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchText !== '' && (
                                    <IconButton onClick={resetSearch}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>

            <Card style={{ flex: '1 1 0px', overflow: 'auto', marginTop: '2%' }} id="assets-buildings-list-container" data-testid="assets-buildings-list-container">
                <Table stickyHeader size="small"
                    id="buildings-list-table"
                    data-testid="buildings-list-table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />

                    {!dashboard.buildingListLoading && !emptyBuildingList && dashboard.buildingListSuccess && useOrganizationDataList.orgListLoaded &&
                        <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable'}
                            id="buildings-list-table-body"
                            data-testid="buildings-list-table-body">
                            {stableSort(buildingDataList, getComparator(order, orderBy)).map((row: BuildingListItems, index: number) => (
                                <AssetBuildingListItem
                                    key={row.buildingId}
                                    row={row}
                                    selectedBuilding={selectedBuilding}
                                    displayClick={handleDisplay}
                                    gatewayCountClick={gatewayCountClick}
                                    systemCountClick={systemCountClick}
                                ></AssetBuildingListItem>
                            ))}
                        </TableBody>
                    }
                </Table>
                {dashboard.buildingListLoading && !dashboard.buildingListSuccess && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!dashboard.buildingListLoading && dashboard.buildingListSuccess && !useOrganizationDataList.orgListLoaded && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!dashboard.buildingListLoading && emptyBuildingList && <EmptyState style={{ flex: 1, height: 200 }}
                    title={t('ORGANIZATION_MANAGE.ERROR_NO_BUILDING_FOUND')} icon={undefined} placeholder={undefined} />}
            </Card >
            <AssetAddBuildingDialog openDialogFlag={openOrgSelectDialog} orgList={projects} closeClick={() => setOpenOrgSelectDialog(false)} ></AssetAddBuildingDialog>
            <div style={{ flex: '1 1 0px', backgroundColor: PXBColors.white[50] }}>
                {!emptyBuildingList && <TablePagination
                    component="div"
                    data-testid="pagination-users-service-partner"
                    count={totalRecords}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('LABELS.ITEMS_PER_PAGE')}
                    showFirstButton showLastButton
                />}
            </div>
        </div>
    );
};
