// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { Luminary } from '../../types/luminary';
import { ACTION_TYPE_COPY, ACTION_TYPE_NONE } from '../../constants';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { AssetListItem } from '../../components/AssetListItem/AssetListItem';

type Props = {
    luminary: Luminary;
};

export const LuminaryInfo: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const [luminary, setLuminary] = useState<Luminary>(props.luminary);

    useEffect(() => {
        setLuminary(props.luminary);
    }, [props.luminary]);

    function copyToClipboard(valueToCopy: string) {
        const tempInput = document.createElement('input')
        tempInput.value = valueToCopy
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        document.body.removeChild(tempInput)
    }

    return (
        <div style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="device-luminary-details-info-container">
            <AssetListItem
                data-testid='luminary-info-description'
                key={'luminary-info-description'}
                subtitle={luminary.luminaryDescription ? luminary.luminaryDescription : '--'}
                title={t('DEVICE_DETAILS.LUMINARY_DISCRIPTION')}
                imageType={ACTION_TYPE_NONE}
                divider={false}
                titleBold={false}
            ></AssetListItem>

            <AssetListItem
                data-testid='luminary-info-type'
                key={'luminary-info-type'}
                subtitle={luminary.type ? luminary.type : '--'}
                title={t('DEVICE_DETAILS.TYPE')}
                imageType={ACTION_TYPE_NONE}
                divider={false}
                titleBold={false}
            ></AssetListItem>

            <AssetListItem
                data-testid='luminary-info-model'
                key={'luminary-info-model'}
                subtitle={luminary.model ? luminary.model : '--'}
                title={t('DEVICE_DETAILS.LUMINARY_MODEL')}
                imageType={ACTION_TYPE_COPY}
                OnItemSelected={(): void =>
                    copyToClipboard(luminary.model ? luminary.model : '')
                }
                divider={false}
                titleBold={false}
            ></AssetListItem>

            <AssetListItem
                data-testid='luminary-info-atsd'
                key={'luminary-info-atsd'}
                subtitle={'' + luminary.ATSD}
                title={t('DEVICE_DETAILS.LUMINARY_ATSD')}
                imageType={ACTION_TYPE_NONE}
                divider={false}
                titleBold={false}
            ></AssetListItem>

            <AssetListItem
                data-testid='luminary-info-circuit'
                key={'luminary-info-circuit'}
                subtitle={luminary.circuit ? luminary.circuit : '--'}
                title={t('DEVICE_DETAILS.LUMINARY_CIRCUIT_HASH')}
                imageType={ACTION_TYPE_NONE}
                divider={false}
                titleBold={false}
            ></AssetListItem>

            <AssetListItem
                data-testid='luminary-info-address'
                key={'luminary-info-address'}
                subtitle={'' + (luminary.lmAddress ? luminary.lmAddress : 0)}
                title={t('DEVICE_DETAILS.LUMINARY_ADDRESS')}
                imageType={ACTION_TYPE_NONE}
                divider={false}
                titleBold={false}
            ></AssetListItem>

            <AssetListItem
                data-testid='luminary-info-serial_number'
                key={'luminary-info-serial_number'}
                subtitle={luminary.serialNumber ? luminary.serialNumber : '--'}
                title={t('DEVICE_DETAILS.SERIAL_NUMBER')}
                imageType={ACTION_TYPE_COPY}
                OnItemSelected={(): void =>
                    copyToClipboard(luminary.serialNumber ? luminary.serialNumber : '')
                }
                divider={false}
                titleBold={false}
            ></AssetListItem>

        </div>
    );
};