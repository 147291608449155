import { ActionType } from '../../types';
import { SystemLogData, SystemLogListData } from '../../types/system-log';

export const LogbookActions = {
    LatestLogListStarted: () =>
    ({
        type: 'Logbook/LatestLogList/Started',
    } as const),

    LatestLogListSucceeded: (systemLogListData: SystemLogListData) =>
    ({
        type: 'Logbook/LatestLogList/Succeeded',
        payload: { systemLogListData },
    } as const),

    LatestLogListFailed: (errorCode: string) =>
    ({
        type: 'Logbook/LatestLogList/Failed',
        payload: { errorCode },
    } as const),

    LatestLogListUnmounted: () =>
    ({
        type: 'Logbook/LatestLogList/Unmounted',
    } as const),

    EarlierLogListStarted: () =>
    ({
        type: 'Logbook/EarlierLogList/Started',
    } as const),

    EarlierLogListSucceeded: (earlierLogList: SystemLogListData) =>
    ({
        type: 'Logbook/EarlierLogList/Succeeded',
        payload: { earlierLogList },
    } as const),

    EarlierLogListFailed: (errorCode: string) =>
    ({
        type: 'Logbook/EarlierLogList/Failed',
        payload: { errorCode },
    } as const),

    EarlierLogListUnmounted: () =>
    ({
        type: 'Logbook/EarlierLogList/Unmounted',
    } as const),

    PreviousLogListStarted: () =>
    ({
        type: 'Logbook/PreviousLogList/Started',
    } as const),

    PreviousLogListSucceeded: (previousLogData: SystemLogData) =>
    ({
        type: 'Logbook/PreviousLogData/Succeeded',
        payload: { previousLogData },
    } as const),

    PreviousLogListFailed: (errorCode: string) =>
    ({
        type: 'Logbook/PreviousLogList/Failed',
        payload: { errorCode },
    } as const),

    PreviousLogListUnmounted: () =>
    ({
        type: 'Logbook/PreviousLogList/Unmounted',
    } as const),

    upsertLogCommentsStarted: () =>
    ({
        type: 'Logbook/upsertLogComments/Started',
    } as const),

    upsertLogCommentsSucceeded: (message: string) =>
    ({
        type: 'Logbook/upsertLogComments/Succeeded',
        payload: { message },
    } as const),

    upsertLogCommentsFailed: (errorCode: string) =>
    ({
        type: 'Logbook/upsertLogComments/Failed',
        payload: { errorCode },
    } as const),

    upsertLogCommentsUnmounted: () =>
    ({
        type: 'Logbook/upsertLogComments/Unmounted',
    } as const),

    acknowledgeLogStarted: () =>
    ({
        type: 'Logbook/acknowledgeLog/Started',
    } as const),

    acknowledgeLogSucceeded: (message: string) =>
    ({
        type: 'Logbook/acknowledgeLog/Succeeded',
        payload: { message },
    } as const),

    acknowledgeLogFailed: (errorCode: string) =>
    ({
        type: 'Logbook/acknowledgeLog/Failed',
        payload: { errorCode },
    } as const),

    acknowledgeLogUnmounted: () =>
    ({
        type: 'Logbook/acknowledgeLog/Unmounted',
    } as const),

    downloadLogbookReportStarted: () =>
    ({
        type: 'Logbook/downloadLogbookReport/Started',
    } as const),

    downloadLogbookReportSucceeded: (data: string) =>
    ({
        type: 'Logbook/downloadLogbookReport/Succeeded',
        payload: { data },
    } as const),

    downloadLogbookReportFailed: (errorCode: string) =>
    ({
        type: 'Logbook/downloadLogbookReport/Failed',
        payload: { errorCode },
    } as const),

    downloadLogbookReportUnmounted: () =>
    ({
        type: 'Logbook/downloadLogbookReport/Unmounted',
    } as const),

};

export type LogbookAction = ActionType<typeof LogbookActions>;
