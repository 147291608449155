import { AuthenticationSelectors } from './authentication-selectors';
import { DashboardSelectors } from './dashboard-selectors';
import { LegalSelectors } from './legal-selectors';
import { NavigationSelectors } from './navigation-selectors';
import { RegistrationSelectors } from './registration-selectors';
import { UserSelectors } from './user-selectors';
import { SiganlRConnectionSelectors } from './siganlr-connection-selectors';
import { LogbookSelectors } from './logbook-selectors';
import { AssetSelectors } from './assets-selectors';
import { OrganizationSelectors } from './organization-selectors';
import { UsersSelectors } from './users-selectors';
import { ReportSelectors } from './report-selectors';
import { CustomerSelectors } from './customer-selectors';
import { UsermanagementSelectors } from './usermanagement-selectors';

export const Selectors = {
    Authentication: AuthenticationSelectors,
    Dashboard: DashboardSelectors,
    Organization: OrganizationSelectors,
    Asset: AssetSelectors,
    Legal: LegalSelectors,
    Navigation: NavigationSelectors,
    Logbook: LogbookSelectors,
    Registration: RegistrationSelectors,
    User: UserSelectors,
    Users: UsersSelectors,
    Customer: CustomerSelectors,
    SignalRConnection: SiganlRConnectionSelectors,
    Report: ReportSelectors,
    Usermanagement: UsermanagementSelectors
};
