// eslint-disable-next-line
import React from 'react';

export const CenteredContent: React.FC<React.PropsWithChildren<React.PropsWithChildren<React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>>>> = (props) => (
    <div
        {...props}
        style={{
            flex: '1 1 100%',
            justifyContent: 'center',
            height: '80vh',
            ...(props.style || {}),
        }}
    >
        {props.children}
    </div>
);
