// eslint-disable-next-line
import React from 'react';
import {
    TableRow,
    IconButton,
    Typography,
    TableCell,
} from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ChevronRight, StarBorder } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { PlatformListItems } from '../../types/platform';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { TAB_CUSTOMERS_KEY, TAB_SERVICE_MANAGERS_KEY, updateUserTabSession } from '../../app/user-management/ManageUser';

type Props = {
    row: PlatformListItems;
    selectedPlatform: string;
    displayClick: Function;
};

export const PlatformListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const history = useHistory();
    const row: PlatformListItems = props.row;
    const handleDisplay = () => {
        props.displayClick({ platformData: row });
    };

    const NavigateToPassDetails = (): void => {
        pushAppRoute(
            history,
            { type: 'PassDetails' },
            { backRoute: 'ManageUsers', platformId: row.id }

        );
        updateUserTabSession(TAB_SERVICE_MANAGERS_KEY);
    };


    return (
        <TableRow
            id={`row${row.id}`}
            data-testid={`row${row.id}`}
            style={{ cursor: 'pointer', width: 'auto', backgroundColor: props.selectedPlatform === row.id ? PXBColors.blue[50] : PXBColors.white[100] }}
            onClick={(): void => handleDisplay()}
        >
            <TableCell style={{ width: '0%', paddingLeft: 10 }}
                id="PAAS-list-item-favorite"
                data-testid="PAAS-list-item-favorite"
                padding="checkbox">
                {<StarBorder style={{ color: '#727E84' }} />}
            </TableCell>
            <TableCell
                id="PAAS-list-name"
                data-testid="PAAS-list-name"
                align="left"
                style={{ width: 'auto' }}
                padding="checkbox"
            >
                {row.name}
            </TableCell>

            <TableCell align="left" style={{ width: 'auto' }}
                id="PAAS-list-customer-name"
                data-testid="PAAS-list-customer-name"
                padding="checkbox">
                {row.customerName}
            </TableCell>

            <TableCell align="left" style={{ width: 'auto' }}
                id="PAAS-list-country"
                data-testid="PAAS-list-item-country"
                padding="checkbox">
                {row.country}
            </TableCell>
            <TableCell align="left" style={{ width: 'auto' }}
                id="PAAS-list-item-manager-name"
                data-testid="PAAS-list-item-manager-name"
                padding="checkbox">
                {row.mainServiceManagerName}
            </TableCell>
            <TableCell align="left"
                id="PAAS-list-item-status"
                data-testid="PAAS-list-item-status"
                padding="checkbox">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <div style={{ minWidth: 25 }}>
                        <CheckCircleIcon color="success" fontSize="small" /></div>

                    <Typography
                        id="PAAS-status"
                        data-testid="PAAS-status"
                        style={{ textTransform: 'none', marginLeft: 10, fontWeight: 600 }} variant="body2">
                        {row.activeStatus}
                    </Typography>
                </div>
            </TableCell>
            <TableCell style={{ width: '0%' }}
                id="PAAS-list-item-next"
                data-testid="PAAS-list-item-next" onClick={() => NavigateToPassDetails()}>
                <IconButton edge="end" id="list-item-next-icon" style={{ marginLeft: "auto" }} >
                    <ChevronRight />
                </IconButton>
            </TableCell>
            <TableCell align="left" padding="checkbox" style={{ width: '0%', padding: 0 }}
                id="PAAS-list-item-empty"
                data-testid="PAAS-list-item-empty">
            </TableCell>
        </TableRow>
    );
};
