// eslint-disable-next-line
import React from 'react';
import { AppBar, Menu, MenuItem, Typography, IconButton, Toolbar, Button } from '@mui/material';
import { Icon } from '../../lib/icons';
import * as PXBColors from '@brightlayer-ui/colors';
import { assertUnreachable } from '../../lib';
import { UserMenuToolbar } from '../UserMenuToolbar/UserMenuToolbar';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export enum ToolbarColor {
    Error = 'Error',
    Primary = 'Primary',
    Default = 'Default',
    Warning = 'Warning',
    Offline = 'Offline',
    Ready = 'Ready',
    Block = 'Block',
    DT = 'Dt',
    Other = 'Other'
}

type materialColor = 'inherit' | 'primary' | 'secondary' | 'default';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getStyleColors = (
    color: ToolbarColor
): { themeColor: materialColor; foregroundColor: string; backgroundColor: string } => {
    switch (color) {
        case ToolbarColor.Error:
            return {
                themeColor: 'primary',
                foregroundColor: PXBColors.white[50],
                backgroundColor: PXBColors.red[500],
            };
        case ToolbarColor.Block:
            return {
                themeColor: 'primary',
                foregroundColor: PXBColors.white[50],
                backgroundColor: PXBColors.orange[500],
            };
        case ToolbarColor.Ready:
            return {
                themeColor: 'primary',
                foregroundColor: PXBColors.white[50],
                backgroundColor: PXBColors.green[500],
            };
        case ToolbarColor.DT:
            return {
                themeColor: 'primary',
                foregroundColor: PXBColors.white[50],
                backgroundColor: PXBColors.blue[500],
            };
        case ToolbarColor.Primary:
            return {
                themeColor: 'primary',
                foregroundColor: PXBColors.white[50],
                backgroundColor: PXBColors.blue[500],
            };
        case ToolbarColor.Offline:
            return {
                themeColor: 'primary',
                foregroundColor: PXBColors.white[50],
                backgroundColor: PXBColors.purple[500],
            };
        case ToolbarColor.Warning:
            return {
                themeColor: 'default',
                foregroundColor: PXBColors.black[500],
                backgroundColor: PXBColors.yellow[500],
            };
        case ToolbarColor.Other:
            return {
                themeColor: 'default',
                foregroundColor: PXBColors.white[50],
                backgroundColor: PXBColors.black[500],
            };
        case ToolbarColor.Default:
            return {
                themeColor: 'default',
                foregroundColor: PXBColors.black[500],
                backgroundColor: PXBColors.white[50],
            };
        default:
            return assertUnreachable(color);
    }
};

export const GenericToolbar: React.FC<React.PropsWithChildren<React.PropsWithChildren<{
    page?: string;
    title?: string;
    showBackButton?: boolean;
    showCloseButton?: boolean;
    actionUIState?: boolean;
    isButtonClicked?: boolean;
    showBackButtonoptional?: boolean;
    disableButton?: boolean;

    backButtonOnClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
    saveOnClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
    cancelOnClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
    color?: ToolbarColor;
    subtitle?: string;
    menuItems?: Array<{ label: string; onClick: () => void }>;
    customIcon?: JSX.Element;

}>>> = (props) => {
    const [menuAnchor, setMenuAnchor]: [EventTarget | null, Function] = React.useState(null);

    const colors = getStyleColors(props.color || ToolbarColor.Primary);
    const { t } = useLanguageLocale();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <>
            <AppBar position="sticky" color={colors.themeColor} style={{ background: colors.backgroundColor, wordBreak: 'break-word' }}>
                <Toolbar style={{ paddingRight: 16 }}>
                    {props.showBackButton && (
                        <IconButton onClick={props.backButtonOnClick} data-testid="app-bar-back-arrow">
                            <Icon.ArrowBack style={{ color: colors.foregroundColor }} />
                        </IconButton>
                    )}
                    {props.showCloseButton && (
                        <IconButton onClick={props.backButtonOnClick} data-testid="app-bar-close">
                            <Icon.Close style={{ color: colors.foregroundColor }} />
                        </IconButton>
                    )}
                    {props.showBackButtonoptional && (
                        <IconButton disabled={!props.disableButton} onClick={props.backButtonOnClick} data-testid="app-bar-back-arrow">
                            <Icon.ArrowBack  style={{ color: props.disableButton? colors.foregroundColor :colors.foregroundColor[50]}} />
                        </IconButton>
                    )}
                    {props.actionUIState! || (
                        <>
                            <div style={{ flex: '1' }} data-testid="app-bar">
                                <Typography variant="h6" data-testid="generic-header-text" sx={{ color: colors.foregroundColor  }} style={{ fontWeight: 600, lineHeight: 1 }}>
                                    {props.title}
                                </Typography>
                                <Typography data-testid="generic-header-sub-text" sx={{ color: colors.foregroundColor  }}>
                                    {props.subtitle}
                                </Typography>
                            </div><div>
                                {props.menuItems && props.menuItems.length > 0 && (
                                    <IconButton onClick={(evt): void => setMenuAnchor(evt.target)}>
                                        <Icon.MoreVert style={{ color: colors.foregroundColor }} />
                                    </IconButton>
                                )}
                            </div>
                            {props.customIcon}
                            <UserMenuToolbar theme={colors.themeColor} />
                        </>
                    )}
                    {props.actionUIState && (
                        <>
                            <div style={{ flex: '1', color: colors.foregroundColor }} data-testid="appBar">
                                <Typography variant="h6" style={{ fontWeight: 600, lineHeight: 1 }}>
                                    {props.title}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Button onClick={props.cancelOnClick} data-testid="app-bar-cancel" style={{
                                    flexDirection: 'row',
                                    border: '1px solid',
                                    borderColor: PXBColors.white[500],
                                    color: PXBColors.white[500],
                                    marginRight: md ? 20 : 5,
                                    width: md ? '' : '26%'
                                }}>
                                    {t('ACTIONS.CANCEL')}
                                </Button>
                                <Button onClick={props.saveOnClick} data-testid="app-bar-save"
                                    disabled={props.isButtonClicked ? true : false}
                                    style={{
                                        flexDirection: 'row',
                                        backgroundColor: PXBColors.blue[700],
                                        color: PXBColors.white[50],
                                    }}>
                                    {t('ACTIONS.SAVE')}
                                </Button>
                            </div>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            {props.menuItems && props.menuItems.length > 0 && Boolean(menuAnchor) && (
                <Menu open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={(): void => setMenuAnchor(null)}>
                    {props.menuItems.map((menuItem, index) => (
                        <MenuItem
                            key={index}
                            onClick={(): void => {
                                setMenuAnchor(null);
                                menuItem.onClick();
                            }}
                            style={{
                                display: 'flex',
                                justifyContent: 'left', fontSize: 14,
                                lineHeight: 1.5, fontWeight: 400,
                                overflow: 'hidden',
                                boxSizing: 'border-box',
                                whiteSpace: 'nowrap',
                                minHeight: 48,
                                paddingTop: 6,
                                paddingBottom: 6,
                                width: 'auto',
                                paddingLeft: 16,
                                paddingRight: 16
                            }}
                        >
                            {menuItem.label}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </>
    );
};
