import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { FEATURE_FLAG } from './url-constants';

type FeatureFlagEndpointSpecifications = ApiEndpointSpecification;
export const FeatureFlagEndpointSpecification: FeatureFlagEndpointSpecifications = {
    url: FEATURE_FLAG,
    method: HttpMethod.GET,
    requestParamsSchemaName: 'FeatureFlagRequestParams',
    requestBodySchemaName: 'FeatureFlagRequestBody',
    okResponseSchemaName: 'FeatureFlagOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeFeatureFlag = Helpers.makeApiRequestFn<
    Types.FeatureFlagRequestParams,
    Types.FeatureFlagRequestBody,
    Types.FeatureFlagtOkResponse
>(FeatureFlagEndpointSpecification);
