import { BuildingList, BuildingListData } from '../../types/building';
import { ActionType } from '../../types/general';
import { StaticalDashboardList, StatisitcData } from '../../types/statistic-summary';

export const DashboardActions = {
    autoRedirectBannerShown: () =>
    ({
        type: 'Dashboard/AutoRedirectBannerShown',
        payload: {},
    } as const),

    autoRedirectBannerDismissed: () =>
    ({
        type: 'Dashboard/AutoRedirectBannerDismissed',
        payload: {},
    } as const),

    trendCardDaysChanged: (days: number) =>
    ({
        type: 'Dashboard/TrendCardDaysChanged',
        payload: {
            days,
        },
    } as const),

    trendListItemCheckboxClicked: (channelId: number, deviceId: string) =>
    ({
        type: 'Dashboard/TrendListItemCheckboxClicked',
        payload: {
            channelId,
            deviceId,
        },
    } as const),

    trendListItemEntered: (channelId: number, deviceId: string) =>
    ({
        type: 'Dashboard/TrendListItemEntered',
        payload: {
            channelId,
            deviceId,
        },
    } as const),

    trendListItemLeft: () =>
    ({
        type: 'Dashboard/TrendListItemLeft',
    } as const),

    inActiveLogOut: (inActiveLogOut: boolean) =>
    ({
        type: 'Dashboard/inActiveLogOut',
        payload: { inActiveLogOut },
    } as const),

    locationRefreshNeeded: (isRefresh: boolean) =>
    ({
        type: 'Dashboard/LocationRefresh',
        payload: { isRefresh },
    } as const),

    locationRefreshDone: (isRefresh: boolean) =>
    ({
        type: 'Dashboard/locationRefresh',
        payload: { isRefresh },
    } as const),

    buildingListStarted: () =>
    ({
        type: 'Dashboard/buildingList/Started',
    } as const),

    buildingListSucceeded: (buildingList: BuildingListData) =>
    ({
        type: 'Dashboard/buildingList/Succeeded',
        payload: { buildingList }
    } as const),

    buildingListFailed: (errorCode: string) =>
    ({
        type: 'Dashboard/buildingList/Failed',
        payload: { errorCode },
    } as const),

    buildingListUnmounted: () =>
    ({
        type: 'Dashboard/buildingList/Unmounted',
    } as const),

    statisticSummaryDashboardStarted: () =>
    ({
        type: 'Dashboard/statisticSummaryDashboard/Started',
    } as const),

    statisticSummaryDashboardSucceeded: (statisticSummaryDashboard: StaticalDashboardList) =>
    ({
        type: 'Dashboard/statisticSummaryDashboard/Succeeded',
        payload: { statisticSummaryDashboard }
    } as const),

    statisticSummaryDashboardFailed: (errorCode: string) =>
    ({
        type: 'Dashboard/statisticSummaryDashboard/Failed',
        payload: { errorCode },
    } as const),
    statisticSummaryDashboardUnmounted: () =>
    ({
        type: 'Dashboard/statisticSummaryDashboard/Unmounted',
    } as const),
    dashboardTabChange: (dashboardTabSelection: string) =>
    ({
        type: 'Dashboard/selectedDashboard/dashboardChange',
        payload: { dashboardTabSelection },
    } as const),

} as const;
// eslint-disable-next-line
export type DashboardAction = ActionType<typeof DashboardActions>;
