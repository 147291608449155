// eslint-disable-next-line
import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import UserConfirmation from '../UserConfirmation/UserConfirmation';

export const NavigationPromptModal: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ isDirty: boolean }>>> = (props) => (
    <NavigationPrompt
        when={(crntLocation, nextLocation): boolean => {
            if (props.isDirty && crntLocation && nextLocation && crntLocation.pathname !== nextLocation.pathname) {
                window.history.forward();
            }
            return props.isDirty;
        }}
    >
        {({ onConfirm, onCancel }): JSX.Element => (
            <UserConfirmation isDirty={props.isDirty} onConfirm={onConfirm} onCancel={onCancel} />
        )}
    </NavigationPrompt>
);
