import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { UPDATE_CUSTOMER_DETAIL } from './url-constants';

type CustomerUpdateEndpointSpecifications = ApiEndpointSpecification;
export const CustomerUpdateEndpointSpecification: CustomerUpdateEndpointSpecifications = {
    url: UPDATE_CUSTOMER_DETAIL,
    method: HttpMethod.PUT,
    requestParamsSchemaName: 'CustomerUpdateRequestParams',
    requestBodySchemaName: 'CustomerUpdateRequestBody',
    okResponseSchemaName: 'CustomerUpdateRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeCustomerUpdateRequest = Helpers.makeApiRequestFn<
    Types.CustomerUpdateRequestParams,
    Types.CustomerUpdateRequestBody,
    Types.CustomerUpdateRequestOkResponse
>(CustomerUpdateEndpointSpecification);
