// eslint-disable-next-line
import React, { useEffect } from 'react';
import {
    TableRow,
    IconButton,
    Typography,
    TableCell,
    Icon,
} from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ChevronRight, Star, StarBorder } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { DeviceAssetData } from '../../types/device';
import { useDispatch } from 'react-redux';
import { DeviceState, ENTITY_TYPE, getDeviceStatusState, getDeviceType, getUserStatusState } from '../../app/enum/ENUM';
import { OrganizationThunks } from '../../actions/thunks/organization-thunks';
import { SLADataList, SLAListItems } from '../../types/sla';
import { wrap } from 'module';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { TechnicianListItems } from '../../types/technicians';

type Props = {
    row: TechnicianListItems;
    selectedTechnician: string;
    displayClick: Function;
    onSuccess: Function;
};

export const TechnicianListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const row: TechnicianListItems = props.row;
    const dispatch = useDispatch();

    const handleDisplay = () => {
        props.displayClick({ TechnicianData: row });
    };

    const handleClick = (): void => {
        dispatch(OrganizationThunks.markAsFav(
            row.id,
            ENTITY_TYPE.SYSTEM,
        ));
    }

    const technicianState: DeviceState = getUserStatusState(row.userStatus);

    return (
        <TableRow
            id={`row${row.id}`}
            data-testid={`row${row.id}`}
            style={{ cursor: 'pointer', width: 'auto', backgroundColor: props.selectedTechnician === row.id ? PXBColors.blue[50] : PXBColors.white[100] }}
            onClick={(): void => handleDisplay()}
        >
            <TableCell style={{ width: '0%', paddingLeft: 10 }}>
            </TableCell>
            <TableCell
                id="technician-list-name"
                data-testid="technician-list-name"
                align="left"
                style={{ width: 'auto'}}
                padding="checkbox"
            >
                {row.userName}
            </TableCell>

            <TableCell align="left" style={{ width: 'auto' }}
                id="technician-list-cmpny-name"
                data-testid="technician-list-cmpny-name"
                padding="checkbox">
                {row.company_name}
            </TableCell>

            <TableCell align="left" style={{ width: 'auto' }}
                id="technician-list-customer-country"
                data-testid="technician-list-item-customer-country"
                padding="checkbox">
                {row.company_country}
            </TableCell>
            <TableCell align="left" style={{ width: 'auto', paddingLeft: 10 }}
                id="technician-list-item-status"
                data-testid="technician-list-item-status">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <div style={{ minWidth: 25 }}>
                        <img
                            src={technicianState.statusImage}
                            alt="logo"
                            style={{ display: 'flex', alignItems: 'right' }}
                            id="technician-status-icon"
                            data-testid="technician-status-icon"
                        /></div>
                    <Typography
                        id="technician-status-button"
                        data-testid="technician-status-button"
                        style={{ textTransform: 'none', marginLeft: 10, fontWeight: 600 }}
                        variant="body2">
                        {t(technicianState.statusTitle)}
                    </Typography>
                </div>
            </TableCell>


            <TableCell style={{ width: '0%' }}
                id="technician-list-item-next"
                data-testid="technician-list-item-next" onClick={() => props.onSuccess(true)}>
                <IconButton edge="end" id="technician-list-item-next-icon" style={{ marginLeft: "auto" }}>
                    <ChevronRight />
                </IconButton>
            </TableCell>
            <TableCell align="left" padding="checkbox" style={{ width: '0%', padding: 0 }}
                id="technician-list-item-empty"
                data-testid="technician-list-item-empty">
            </TableCell>
        </TableRow>
    );
};
