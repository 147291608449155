import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { ADD_PLATFORM } from './url-constants';

type AddPlatformEndpointSpecifications = ApiEndpointSpecification;
export const AddPlatformEndpointSpecification: AddPlatformEndpointSpecifications = {
    url: ADD_PLATFORM,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'AddPlatformRequestParams',
    requestBodySchemaName: 'AddPlatformRequestBody',
    okResponseSchemaName: 'AddPlatformRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeAddPlatform = Helpers.makeApiRequestFn<
    Types.AddPlatformRequestParams,
    Types.AddPlatformRequestBody,
    Types.AddPlatformOkResponse
>(AddPlatformEndpointSpecification);
