// eslint-disable-next-line
import React from 'react';
import * as MuiCore from '@mui/material';
import './StatisticSummary.css';
import { DeviceState, getDeviceStatusState } from '../enum/ENUM';

type Props = {
    name: string;
    count: number;
    elstatus: number;
    testid?: string;
    displayClick: Function;
};

export const StatisticSummaryItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    const deviceState: DeviceState = getDeviceStatusState(props.elstatus, props.count > 0);

    const handleDisplay = (): void => {
        props.displayClick({ elstatus: props.elstatus });
    };

    return (
        <>
            <div className="Hero" data-testid="status-summary">
                <img onClick={handleDisplay} src={deviceState.statusImage} style={{ width: 36, height: 36 }} alt="logo" id="list-item-status-icon" className="Image" />
                <MuiCore.Typography data-testid={props.testid} className="Count" variant="body1" style={{ fontWeight: 600, fontSize: 20 }}>
                    {props?.count}
                </MuiCore.Typography>
                <MuiCore.Typography data-testid="status-summary-name" className="Label" variant="subtitle1" style={{ fontWeight: 600 }}>
                    {props?.name}
                </MuiCore.Typography>
            </div>
        </>
    );
};
