// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { Typography, ListSubheader, Button, CircularProgress } from '@mui/material';
import { BuildingList } from '../../types/building';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { CardItemList } from '../../types/overview-type';
import { Thunks } from '../../actions';
import { useDispatch } from 'react-redux';
import List from '@mui/material/List';
import { CardListItem } from '../../components/OverviewList/CardListItem';
import * as PXBColors from '@brightlayer-ui/colors';
import Box from '@mui/material/Box';
import { EmptyState } from '@brightlayer-ui/react-components';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { OrganizationData } from '../../types/organization';

const orgDetailsDefault: OrganizationData = {
    projectId: "",
    name: "",
    description: '',
    country: "",
    address: "",
    postalCode: "",
    city: "",
    countryCode: "",
    phone: "",
    email: "",
    systemCount: 0,
    buildingCount: 0,
    gatewayCount: 0,
    adminCount: 0,
    viewerCount: 0,
    isAdmin: false,
    isFavourite: false,
    address2: '',
    primaryFirstName: null,
    primaryLastName: null,
    secondaryFirstName: null,
    secondaryLastName: null,
    secondaryEmail: null,
    secondaryPhone: null,
    secondaryCountryCode: null
};

type Props = {
    orgId: string;
    onClick: Function;
};

export const OrgBuildings: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const history = useHistory();
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const [buildingList, setBuildingList] = useState<CardItemList>([]);
    const organization = useSelector(Selectors.Organization.organization);
    const [organizationDetails, setOrganizationDetails] = useState<OrganizationData>(orgDetailsDefault);

    const getBuildingList = (list: BuildingList): CardItemList => {
        return list.map((data, building) => {
            return (
                {
                    id: data.buildingId,
                    title: data.name,
                    subtitle: getSystemsCountText(data.systemCount) + ' | ' + data.city
                }
            );
        })
    };

    const getSystemsCountText = (systemCount: number): string => {
        return systemCount > 1 ? systemCount + t('ORGANIZATION_MANAGE.SYSTEM_COUNT_S') : systemCount + t('ORGANIZATION_MANAGE.SYSTEM_COUNT');
    };

    const goToCreateBuilding = (): void => {
        pushAppRoute(history,
            { type: 'UpsertBuilding', orgId: props.orgId },
            { backRoute: 'OrganizationDetails', orgId: props.orgId });
    };

    useEffect(() => {
        dispatch(Thunks.Dashboard.getBuildingList(props.orgId));
    }, [props.orgId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            setBuildingList(getBuildingList(dashboard.buildingList.detail));
        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            setBuildingList([]);
        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const goToBuildingDetails = (buildingId: string, buildingName: string): void => {
        props.onClick({ buildingId: buildingId, buildingName: buildingName });
    };
    useEffect(() => {
        if (!organization.orgListLoading && organization.orgListSuccess) {
            setOrganizationDetails(organization.orgList.detail[0]);
        } else if (!organization.orgListLoading && organization.orgListFail) {
            setOrganizationDetails(orgDetailsDefault);
        }
    }, [organization.orgListLoading, organization.orgListSuccess, organization.orgListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{ flex: '1 1 0px' }}
            id="org-detail-buildings-container"
            data-testid="org-detail-buildings-container">
            <ListSubheader
                style={{
                    marginLeft: -15,
                    marginRight: -15,
                    display: 'flex',
                    height: 45,
                    background: 'none',
                    flexFlow: 'row-wrap'
                }}
            >
                <Typography
                    variant="h5"
                    style={{
                        color: PXBColors.blue[400],
                        width: '75%',
                    }}
                    id="building-list-title"
                    data-testid="building-list-title"
                >
                    {t('ORGANIZATION_MANAGE.BUILDINGS_TITLE') + ' (' + buildingList.length + ')'}
                </Typography>

                <Box sx={{ typography: 'body1' }}>
                    <Button
                        id="button-add-building"
                        data-testid="button-add-building"
                        style={{
                            flexDirection: 'row',
                            backgroundColor: PXBColors.blue[500],
                            color: PXBColors.white[50],
                            opacity: organizationDetails.isAdmin ? 1 : 0.5,
                            textTransform: 'none'
                        }}
                        disabled={!organizationDetails.isAdmin}
                        onClick={(): void => goToCreateBuilding()}
                    >
                        {t('ORGANIZATION_MANAGE.ADD_NEW_BUILDING')}
                    </Button>
                </Box>
            </ListSubheader>

            {dashboard.buildingListLoading && <EmptyState style={{ flex: 1, minHeight: 400 }}
            icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

            {!dashboard.buildingListLoading && <List component="div" disablePadding id="building-list" style={{ paddingTop: 10 }}>
                {buildingList?.map((data, building) => {
                    return (
                        <CardListItem
                            key={'building-key-' + data.id}
                            id={data.id}
                            title={data.title}
                            subtitle={data.subtitle}
                            OnItemSelected={(id: string, name: string): void =>
                                goToBuildingDetails(id, name)
                            }
                        ></CardListItem>
                    );
                })}
            </List>}

        </div>
    );
};
