// eslint-disable-next-line
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerNavGroup,
    DrawerSubheader,
    NavItem,
} from '@brightlayer-ui/react-components/core/Drawer';
import * as colors from '@brightlayer-ui/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import eatonlogogrey from '../../assets/images/Eaton_LogoGrey.svg';
import headerBackgroundImageBlur from '../../assets/images/background_topology.png';
import { Icon } from '../../lib/icons';
import { AppRoute, addLocationId } from '../../routes';
import { pushAppRoute } from '../../data/domain/route-manager';
import { Actions } from '../../actions';
import { Selectors } from '../../selectors';
import { useCurrentAppRoute } from '../../hooks/app-route-hooks';
import { InputLabel, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { config } from '../../app-config';
import { isRecordFound, useSelectedOrganizationData } from '../../hooks/data-hooks';
import { OrganizationDropdownToolbar } from '../OrganizationDropdownToolbar/OrganizationDropdownToolbar';
import NavReport from '../../assets/images/nav_reports.svg';
import moment, { now } from 'moment';
import { Menu } from '@mui/icons-material';
import { Box } from '@mui/system';
import { ListItemTag } from '@brightlayer-ui/react-components';
import { useTheme } from '@mui/material/styles';
import { useAuthState } from '../AuthContextProvider';
import { getUserRoleType } from '../AuthContextProvider/component';
import { TAB_CUSTOMER, TAB_ORG } from '../../constants';
import { CustomerDropdownToolbar } from '../CustomerDropDownToolbar/CustomerDropDownToolbar';
import { USER_ROLE_TYPE } from '../../app/enum/ENUM';
import { DashboardActions } from '../../actions/actions/dashboard-actions';
import { useCustomerList } from '../../data/DataProviders/CustomerListProvider';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';


type AppRouteType = Pick<AppRoute, 'type'>;
const isActive = (requestedAppRoutes: Array<Pick<AppRoute, 'type'>>, currentAppRoute: AppRoute): boolean =>
    requestedAppRoutes.some((requestedAppRoute) => currentAppRoute.type === requestedAppRoute.type);

const OverviewRoutes: AppRouteType[] = [
    { type: 'Dashboard' },
    { type: 'OverviewOrganization' },
    { type: 'OverviewLocation' },
    { type: 'Logbook' },
    { type: 'TimelineOrganization' },
    { type: 'TimelineLocation' },
    { type: 'Devices' },
    { type: 'DevicesOrganization' },
    { type: 'DevicesLocation' },
    { type: 'Settings' },
    { type: 'MyProfile' },
    { type: 'ManageUser' },
    { type: 'ManageLocation' },
    { type: 'Legal' },
];

export const SideNav: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useLanguageLocale();
    const authState = useAuthState();
    const usertype = getUserRoleType(authState);
    const selectedOrganization = useSelectedOrganizationData();
    const open = useSelector(Selectors.Navigation.isDrawerOpen);
    const currentAppRoute = useCurrentAppRoute();
    const [active, setActive] = useState('');
    const [userFilterType, setUserFilterType] = React.useState(TAB_CUSTOMER);
    const useCustomerDataList = useCustomerList();
    const useOrganizationDataList = useOrganizationList();
    const matches = useMediaQuery('(max-width:1280px)');
    //eslint-disable-next-line
    const [orgId, setOrgId] = useState('');
    const userRoletype = getUserRoleType(authState) || 0;
    //TODO-project list
    // const userRole = useUserRoles();
    // const variant = useSelector(Selectors.Navigation.isDrawerVariant);
    // const [projectId, setprojectId] = useState('');
    // const [projectName, setProjectName] = useState(projectName || '');
    // const [projectName, setProjectName] = React.useState('');

    // const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    //     setProjectName(event.target.value);
    // };

    const setActiveNav = (): void => {
        const routeType = currentAppRoute.type;
        switch (routeType) {
            // case 'Dashboard':
            // case 'OverviewOrganization':
            // case 'OverviewLocation':
            //     setActive(t('DRAWER.OVERVIEW'));
            //     break;
            // case 'Logbook':
            // case 'TimelineLocation':
            // case 'TimelineOrganization':
            //     setActive(t('DRAWER.TIMELINE'));
            //     break;
            // case 'Devices':
            // case 'DevicesOrganization':
            // case 'DevicesLocation':
            //     setActive(t('DRAWER.DEVICES'));
            //     break;
            // case 'Settings':
            //     setActive(t('DRAWER.SETTINGS'));
            //     break;
            // case 'MyProfile':
            //     setActive(t('DRAWER.MYPROFILE'));
            //     break;
            // case 'ManageUser':
            //     setActive(t('DRAWER.MANAGEUSER'));
            //     break;
            // case 'ManageLocation':
            //     setActive(t('DRAWER.MANAGELOCATION'));
            //     break;
            //New active nav items
            case 'Dashboard':
            case 'OverviewBuildingDetails':
            case 'OverviewOrganization':
                setActive(t('DRAWER.DASHBOARD'));
                break;
            case 'Logbook':
                setActive(t('DRAWER.LOGBOOK'));
                break;
            case 'ManageUsers':
                setActive(t('DRAWER.USERS'));
                break;
            case 'Assets':
                setActive(t('DRAWER.ASSETS'));
                break;
            case 'Reports':
                setActive(t('DRAWER.REPORTS'));
                break;
            case 'Settings':
                setActive(t('DRAWER.ACCOUNTPREFERENCES'));
                break;
            case 'Organizations':
                setActive(t('DRAWER.ORGSETTINGS'));
                break;
            case 'Legal':
                setActive(t('DRAWER.LEGAL'));
                break;
            default:
                setActive('');
        }
    };

    useEffect(() => {
        setActiveNav();
        const activeRoute = isActive(OverviewRoutes, currentAppRoute);
        if (matches && active !== '') dispatch(Actions.Navigation.drawerToggled(false, 'persistent'));
        else dispatch(Actions.Navigation.drawerToggled(activeRoute, activeRoute ? 'persistent' : 'temporary'));
    }, [currentAppRoute, matches]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isRecordFound(selectedOrganization)) setOrgId(selectedOrganization.value.id);
    }, [selectedOrganization]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (usertype === USER_ROLE_TYPE.SERVICE_MANAGER)
            setUserFilterType(TAB_CUSTOMER);
        else {
            setUserFilterType(TAB_ORG)
        }
    }, [usertype]);// eslint-disable-line react-hooks/exhaustive-deps

    //TODO- old code for user role
    // const hasNoOperation = isRecordFound(userRole) ? userRole.value.every((x) => x.operations.length === 0) : false;
    // const hasUserManagementAccess = isRecordFound(userRole)
    //     ? userRole.value.some((x) => x.operations.some((operation) => operation === UserOperations.UserManagement))
    //     : false;

    // const hasSiteManagementAccess = isRecordFound(userRole)
    //     ? userRole.value.some((x) => x.operations.some((operation) => operation === UserOperations.SiteManagement))
    //     : false;

    const routeTo = useCallback(
        (destinationAppRoute: AppRoute) => {
            if ('locationId' in currentAppRoute) {
                pushAppRoute(history, addLocationId(destinationAppRoute, currentAppRoute.locationId));
            } else {
                pushAppRoute(history, destinationAppRoute);
            }
        },
        [history, currentAppRoute]
    );

    const toggleDrawer = (): void => {
        dispatch(Actions.Navigation.drawerToggled(!open, 'persistent'));
    };

    const handleUserFilterType = (
        event: React.MouseEvent<HTMLElement>,
        selection: string | null,
    ) => {
        if (selection !== null) {
            setUserFilterType(selection);
            dispatch(DashboardActions.dashboardTabChange(selection));
        }
        if (useCustomerDataList)
            useCustomerDataList.setTabClicked(true);
        if(useOrganizationDataList)
        useOrganizationDataList.setTabClicked(true);

    };

    const servicemanagerNavlist: NavItem[] = [
        {
            itemID: t('DRAWER.DASHBOARD'),
            title: t('DRAWER.DASHBOARD'),
            icon: <Icon.Dashboard />,
            onClick: (): void => {
                routeTo({ type: 'Dashboard' });
            },
            divider: false,
        },
        {
            itemID: t('DRAWER.LOGBOOK'),
            title: t('DRAWER.LOGBOOK'),
            icon: <Icon.Logbook />,
            onClick: (): void => {
                routeTo({ type: 'Logbook' });
            },
            divider: false,
        },
        {
            itemID: t('DRAWER.ASSETS'),
            title: t('DRAWER.ASSETS'),
            icon: <Icon.Device />,
            onClick: (): void => {
                routeTo({ type: 'Assets' });
            },
            divider: false,
        },
        {
            itemID: t('DRAWER.REPORTS'),
            title: t('DRAWER.REPORTS'),
            icon: <img
                style={{ paddingLeft: 2 }}
                src={NavReport}
                alt="nav_report"
            />,
            onClick: (): void => {
                routeTo({ type: 'Reports' });
            },
            divider: false,
        },
        {
            itemID: t('DRAWER.USERS'),
            title: t('DRAWER.USERS'),
            icon: <Icon.Users />,
            onClick: (): void => {
                routeTo({ type: 'ManageUsers' });
            },
            divider: false,
        },
    ];

    const userNavlist: NavItem[] = [
        {
            itemID: t('DRAWER.DASHBOARD'),
            title: t('DRAWER.DASHBOARD'),
            icon: <Icon.Dashboard />,
            onClick: (): void => {
                routeTo({ type: 'Dashboard' });
            },
            divider: false,
        },
        {
            itemID: t('DRAWER.LOGBOOK'),
            title: t('DRAWER.LOGBOOK'),
            icon: <Icon.Logbook />,
            onClick: (): void => {
                routeTo({ type: 'Logbook' });
            },
            divider: false,
        },
        {
            itemID: t('DRAWER.ASSETS'),
            title: t('DRAWER.ASSETS'),
            icon: <Icon.Device />,
            onClick: (): void => {
                routeTo({ type: 'Assets' });
            },
            divider: false,
        },
        {
            itemID: t('DRAWER.REPORTS'),
            title: t('DRAWER.REPORTS'),
            icon: <img
                style={{ paddingLeft: 2 }}
                src={NavReport}
                alt="nav_report"
            />,
            onClick: (): void => {
                routeTo({ type: 'Reports' });
            },
            divider: false,
        }
    ];

    const paasManagerNavList: NavItem[] = [
        {
            itemID: t('DRAWER.USERS'),
            title: t('DRAWER.USERS'),
            icon: <Icon.Users />,
            onClick: (): void => {
                routeTo({ type: 'ManageUsers' });
            },
            divider: false,
        },
    ];

    const navListForPassManager: NavItem[] = [
        {
            itemID: t('DRAWER.ACCOUNTPREFERENCES'),
            title: t('DRAWER.ACCOUNTPREFERENCES'),
            icon: <Icon.AccountSettings />,
            onClick: (): void => {
                routeTo({ type: 'Settings', changeId: '0' });
            },
            divider: false,
        },
        {
            itemID: t('DRAWER.HELPANDFEEDBACK'),
            title: t('DRAWER.HELPANDFEEDBACK'),
            icon: <Icon.HelpandFeedback />,
            divider: false,
        },
        {
            itemID: t('DRAWER.LEGAL'),
            title: t('DRAWER.LEGAL'),
            icon: <Icon.Gavel />,
            onClick: (): void => {
                routeTo({ type: 'Legal' });
            },
            divider: false,
        },
    ];


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const navListForSiteRole: NavItem[] = [
        {
            itemID: t('DRAWER.ACCOUNTPREFERENCES'),
            title: t('DRAWER.ACCOUNTPREFERENCES'),
            icon: <Icon.AccountSettings />,
            onClick: (): void => {
                routeTo({ type: 'Settings', changeId: '0' });
            },
            divider: false,
        },
        {
            itemID: t('DRAWER.ORGSETTINGS'),
            title: t('DRAWER.ORGSETTINGS'),
            icon: <Icon.Settings />,
            onClick: (): void => {
                routeTo({ type: 'Organizations' });
            },
            divider: false,
        },
        {
            itemID: t('DRAWER.HELPANDFEEDBACK'),
            title: t('DRAWER.HELPANDFEEDBACK'),
            icon: <Icon.HelpandFeedback />,
            divider: false,
        },
        {
            itemID: t('DRAWER.LEGAL'),
            title: t('DRAWER.LEGAL'),
            icon: <Icon.Gavel />,
            onClick: (): void => {
                routeTo({ type: 'Legal' });
            },
            divider: false,
        },
    ];
    //To-do- Old code commented for setting
    // const secondNavList: NavItem[] = [
    //     {
    //         itemID: t('DRAWER.SETTINGS'),
    //         title: t('DRAWER.SETTINGS'),
    //         icon: <Icon.Settings />,

    //         expandIcon: <ExpandMoreIcon />,
    //         collapseIcon: <ExpandLessIcon />,
    //         divider: false,
    //         items: [
    //             {
    //                 itemID: t('DRAWER.MYPROFILE'),
    //                 title: t('DRAWER.MYPROFILE'),
    //                 onClick: (): void => {
    //                     routeTo({ type: 'MyProfile' });
    //                 },
    //             },
    //             {
    //                 itemID: t('DRAWER.MANAGEUSER'),
    //                 title: t('DRAWER.MANAGEUSER'),
    //                 onClick: (): void => {
    //                     routeTo({ type: 'ManageUser', orgId: orgId });
    //                 },
    //                 hidden: !hasUserManagementAccess,
    //             },
    //             {
    //                 itemID: t('DRAWER.MANAGELOCATION'),
    //                 title: t('DRAWER.MANAGELOCATION'),
    //                 onClick: (): void => {
    //                     routeTo({ type: 'ManageLocation', orgId: orgId });
    //                 },
    //                 hidden: !hasSiteManagementAccess,
    //             },
    //         ],
    //     },
    //     {
    //         itemID: t('DRAWER.LEGAL'),
    //         title: t('DRAWER.LEGAL'),
    //         icon: <Icon.Gavel />,
    //         onClick: (): void => {
    //             routeTo({ type: 'Legal' });
    //         },

    //         divider: false,
    //     },
    // ];
    return (
        <>
            <Drawer
                placeholder={undefined}
                variant={'persistent'}
                data-testid="side-nav-drawer"
                activeItem={active}
                width={'18.75rem'}
                open={open}
                ModalProps={{
                    onClose: (): void => { },
                }}
            >
                <DrawerHeader
                    placeholder={undefined}
                    translate="no"
                    style={{ backgroundColor: PXBColors.blue[500] }}
                    backgroundImage={headerBackgroundImageBlur}
                    backgroundOpacity={0}
                    icon={<Menu />}
                    onIconClick={(): void => toggleDrawer()}
                    titleContent={
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <Typography variant="subtitle1" style={{ marginLeft: 3 }}>
                                {t('DRAWER.ELDIGITAL')}
                            </Typography>
                            <ListItemTag
                                placeholder={undefined}
                                variant='subtitle2'
                                style={{ fontWeight: 400 }}
                                label={t('DRAWER.EMERGENCY_LIGHT')}
                                backgroundColor={colors.blue[500]}
                            />
                        </Box>
                    }
                />
                <DrawerSubheader data-testid="service-drawer-sub-header">
                    {usertype === USER_ROLE_TYPE.SERVICE_MANAGER &&
                        <>
                            <div data-testid="service-manager-selection-filter">
                                <InputLabel id="filter-label-service" > <Typography variant="overline" style={{ paddingLeft: 12 }}>{t('LABELS.FILTER_BY').toUpperCase()}</Typography></InputLabel>
                                <ToggleButtonGroup
                                    data-testid="toggle-group"
                                    color="info"
                                    sx={{ height: 32, marginButtom: 20, width: '100%' }}
                                    value={userFilterType}
                                    exclusive
                                    onChange={handleUserFilterType}
                                    aria-label="medium outlined"
                                >
                                    <ToggleButton data-testid="toggle-org" style={{ width: '50%', textTransform: 'none' }} value={TAB_ORG}>{t('DASHBOARD.ORGANIZATION_TITLE')}</ToggleButton>
                                    <ToggleButton data-testid="toggle-cus" style={{ width: '50%', textTransform: 'none' }} value={TAB_CUSTOMER}>{t('CUSTOMER_DETAILS.CUSTOMER_HEADER_SUBTITLE').toUpperCase()}</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            <div
                                style={{
                                    backgroundColor: 'white',
                                    backgroundBlendMode: 'soft-light',
                                    backgroundSize: 'cover',
                                }}
                            >
                                {userFilterType === TAB_ORG &&<OrganizationDropdownToolbar />}
                                {userFilterType === TAB_CUSTOMER && <CustomerDropdownToolbar />}
                            </div>
                        </>
                    }
                    {usertype !== USER_ROLE_TYPE.SERVICE_MANAGER && usertype !== USER_ROLE_TYPE.PAAS_SERVICE_MANAGER &&
                        <div
                            style={{
                                backgroundColor: 'white',
                                backgroundBlendMode: 'soft-light',
                                backgroundSize: 'cover',
                            }}
                        >
                            <OrganizationDropdownToolbar />
                        </div>
                    }
                </DrawerSubheader>
                <DrawerBody placeholder={undefined} itemFontColor={PXBColors.gray[500]} itemIconColor={PXBColors.gray[500]}>
                    {(userRoletype === USER_ROLE_TYPE.CUSTOMER_VIEWER || userRoletype === USER_ROLE_TYPE.TECHNICIAN) ? (
                        <DrawerNavGroup placeholder={undefined}
                            activeItemBackgroundColor={PXBColors.blue[50]}
                            translate="no"
                            activeItemIconColor={PXBColors.blue[500]}
                            activeItemFontColor={PXBColors.blue[500]}
                            activeItemBackgroundShape="round"
                            items={userNavlist}
                        />)
                        :
                        (userRoletype === USER_ROLE_TYPE.PAAS_SERVICE_MANAGER) ?
                            <DrawerNavGroup placeholder={undefined}
                                activeItemBackgroundColor={PXBColors.blue[50]}
                                translate="no"
                                activeItemIconColor={PXBColors.blue[500]}
                                activeItemFontColor={PXBColors.blue[500]}
                                activeItemBackgroundShape="round"
                                items={paasManagerNavList}
                            /> :
                            (userRoletype === USER_ROLE_TYPE.SERVICE_MANAGER) ?
                                <DrawerNavGroup placeholder={undefined}
                                    activeItemBackgroundColor={PXBColors.blue[50]}
                                    translate="no"
                                    activeItemIconColor={PXBColors.blue[500]}
                                    activeItemFontColor={PXBColors.blue[500]}
                                    activeItemBackgroundShape="round"
                                    items={servicemanagerNavlist}
                                /> :
                                <DrawerNavGroup placeholder={undefined}
                                    activeItemBackgroundColor={PXBColors.blue[50]}
                                    translate="no"
                                    activeItemIconColor={PXBColors.blue[500]}
                                    activeItemFontColor={PXBColors.blue[500]}
                                    activeItemBackgroundShape="round"
                                    items={userNavlist}
                                />
                    }

                    {
                        <>
                            <div style={{ flex: '1 0 0px' }} />
                            {usertype === USER_ROLE_TYPE.PAAS_SERVICE_MANAGER ?
                                <DrawerNavGroup placeholder={undefined} itemFontColor={PXBColors.gray[500]} itemIconColor={PXBColors.gray[500]}
                                    activeItemBackgroundColor={PXBColors.blue[50]}
                                    translate="no"
                                    activeItemIconColor={PXBColors.blue[500]}
                                    activeItemFontColor={PXBColors.blue[500]}
                                    activeItemBackgroundShape="round"
                                    items={navListForPassManager}
                                />
                                :
                                <DrawerNavGroup placeholder={undefined} itemFontColor={PXBColors.gray[500]} itemIconColor={PXBColors.gray[500]}
                                    activeItemBackgroundColor={PXBColors.blue[50]}
                                    translate="no"
                                    activeItemIconColor={PXBColors.blue[500]}
                                    activeItemFontColor={PXBColors.blue[500]}
                                    activeItemBackgroundShape="round"
                                    items={navListForSiteRole}
                                />}
                        </>
                    }
                </DrawerBody>

                <DrawerFooter placeholder={undefined}>
                    <div style={{ paddingLeft: 16, paddingTop: 16, color: PXBColors.gray[500] }}>
                        <Typography variant="caption">v {config.version}</Typography><br />
                        <Typography variant="caption">{getFormattedDateTime()}</Typography>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: 16 }}>
                        <div style={{ alignSelf: 'center', color: PXBColors.gray[500] }}>
                            {' '}
                            <img src={eatonlogogrey} alt={'Eaton Logo'} />
                        </div>
                        <div>
                            <Typography variant="caption" style={{ color: PXBColors.gray[500] }}>
                                {t('DRAWER.COPYRIGHT')}
                                <br />
                                {t('DRAWER.RIGHTS_RESERVED')}
                            </Typography>
                        </div>
                    </div>
                </DrawerFooter>
            </Drawer>
        </>
    );
};

const getFormattedDateTime = (): string => {
    return moment(now()).format('LT') + ' ' + moment(now()).format('DD/MM/YY')
};
