import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_DASHBOARD_CUSTOMER_LIST } from './url-constants';

type CustomerListEndpointSpecifications = ApiEndpointSpecification;
export const CustomerListEndpointSpecification: CustomerListEndpointSpecifications = {
    url: GET_DASHBOARD_CUSTOMER_LIST,
    method: HttpMethod.GET,
    requestParamsSchemaName: 'CustomerListRequestParams',
    requestBodySchemaName: 'CustomerListRequestBody',
    okResponseSchemaName: 'CustomerListOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeCustomerList = Helpers.makeApiRequestFn<
    Types.CustomerListRequestParams,
    Types.CustomerListRequestBody,
    Types.CustomerListOkResponse
>(CustomerListEndpointSpecification);
