// eslint-disable-next-line
import React from 'react';
//import { isRecordFound, useUserRoles } from '../../hooks/data-hooks';
import StyledMenu from './StyledMenu';
import { USER_THEME } from '../../constants';


type Props = {
    theme: string;
};

export const UserMenuToolbar: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    // const userRole = useUserRoles();
    // const hasNoOperation = isRecordFound(userRole) ? userRole.value.every((x) => x.operations.length === 0) : false;

   sessionStorage.setItem(USER_THEME, props.theme);

    return (
        <div data-testid="user-menu" >
            <StyledMenu />
        </div>
    );
};
