import { History } from 'history';
import { pushAppRoute } from '../../data/domain/route-manager';
import { AppRoute } from '../../routes';
import { AppThunk } from '../actions';
import { DashboardActions } from '../actions/dashboard-actions';
import * as ApiRequests from '../../api/requests';
import { StatisitcData } from '../../types/statistic-summary';

export const DashboardThunks = {
    singleLocationFound: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        history: History<any>,
        currentRoute: AppRoute,
        args: { locationId: string }
        // eslint-disable-next-line require-await, @typescript-eslint/require-await
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch({
            type: 'Dashboard/AutoRedirectBannerShown',
        });

        const getDestinationRoute = (): AppRoute | undefined => {
            if (currentRoute.type === 'OverviewOrganization') {
                return {
                    type: 'OverviewLocation',
                    locationId: args.locationId,
                };
            }

            if (currentRoute.type === 'DevicesOrganization') {
                return {
                    type: 'DevicesLocation',
                    locationId: args.locationId,
                };
            }

            if (currentRoute.type === 'TimelineOrganization') {
                return {
                    type: 'TimelineLocation',
                    locationId: args.locationId,
                };
            }

            return undefined;
        };

        const destinationAppRoute = getDestinationRoute();
        if (!destinationAppRoute) {
            return;
        }

        pushAppRoute(history, destinationAppRoute);
    },

    locationRefresh: (isRefresh: boolean): AppThunk => (dispatch): void => {
        dispatch(DashboardActions.locationRefreshNeeded(isRefresh));
    },

    getBuildingList: (
        projectId: string,
        buildingId?: string,
        searchtext?: string,
        customerId?: string,
        status?: string[],
        offset?: string,
        limit?: string,
        isFavorite?: boolean
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(DashboardActions.buildingListStarted());

        const payload = await ApiRequests.requestBuilingList({
            projectId: projectId, buildingId: buildingId, searchtext: searchtext,
            customerId: customerId, status: status, offset: offset, limit: limit, isFavorite: isFavorite
        }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(DashboardActions.buildingListSucceeded(payload.response.data));
            return;
        }

        dispatch(DashboardActions.buildingListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    getStatisticSummaryDashboard: (
        type: string,
        projectId: string,
        buildingId: string,
        systemId?: string,
        customerId?: string
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(DashboardActions.statisticSummaryDashboardStarted());

        const payload = await ApiRequests.getStatisticSummary(
            {
                type: type,
                projectId: projectId,
                buildingId: buildingId,
                systemId: systemId ? systemId : '',
                customerId: customerId
            },
            { authHelper: undefined }
        );

        if (payload.ok) {
            const statisticSummaryData: StatisitcData = {
                dt: 0,
                error: 0,
                ready: 0,
                blocked: 0,
                offLine: 0,
                warning: 0,
                totalCount: 0,
            };
            dispatch(
                DashboardActions.statisticSummaryDashboardSucceeded(
                    payload.response.data
                )
            );
            return;
        }

        dispatch(
            DashboardActions.statisticSummaryDashboardFailed(
                `${ApiRequests.extractResponseNotOkCode(payload.response)}`
            )
        );
    },

};
