// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, CardActions, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Radio, RadioGroup, Snackbar, Typography } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Selectors } from '../../selectors';
import { EMPTY_STRING, EULA_FILE_PATH } from '../../constants';
import { PDFRender } from '../../components/PDFRender/PDFRender';
import { useTranslation } from 'react-i18next';
import { SessionStorage } from '../../components/AuthContextProvider/session-storage';
import { languageList } from '../../components/UserDetails/AccountPreferencesData';

type Props = {
    agree: boolean;
    initialEULA?: string;
    onEulaAccept?: Function;
    scrolled: boolean;
    onEulaScrolled?: Function;
    changeNextEnabled?: Function;
    changeNextLabel?: Function;
    languageSelection?: Function;
    onLoad?: Function;
};

export const EULA: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const registration = useSelector(Selectors.Registration.registration);
    const [openlanguageSelectorDialog, setOpenlanguageSelectorDialog] = React.useState(true);
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = React.useState<string>('');
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const eulaAgree = props.agree;
    const eulaScrolled = props.scrolled;

    const { initialEULA, onEulaAccept, onEulaScrolled, onLoad, languageSelection, changeNextEnabled, changeNextLabel } = props;
    
    if (changeNextEnabled) {
        changeNextEnabled(eulaAgree);
    }
    if (changeNextLabel) {
        changeNextLabel(t('ACTIONS.NEXT'));
    }

    const eulaContent = React.createRef<HTMLDivElement>();
    useEffect(() => {
        if (!registration.eula && !registration.eulaLoading && onLoad) {
            onLoad();
        }
    });

    const handleScroll = (): void => {
        if (!registration.eula) {
            return;
        }
        const element = eulaContent.current;
        if (!element) {
            return;
        }
        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 20) {
            if (onEulaScrolled) {
                onEulaScrolled();
            }
        }
    };

    useEffect(() => {
        handleScroll();
    });

    useEffect(() => {
        setAlertErrorMessage(t('REGISTRATION.EULA.LANGUAGE_SUCCESS'));
        // eslint-disable-next-line
    }, [selectedLanguage]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (onEulaAccept) {
            onEulaAccept(e.target.checked);
        }
        if (changeNextEnabled) {
            changeNextEnabled(e.target.checked);
        }

    };

    const handleCloselanguageSelectorDialog = () => {
        setOpenlanguageSelectorDialog(false);

    };

    const getSelectedLang = (value: number): string => {
        let userLang = languageList.filter((lang) => {
            if (lang.key === value) {
                return lang.code;
            }
        });
        return userLang[0]?.code || "en";

    };

    const setSelectedLangNumber = (langSelection: string): number => {
        let userLang = languageList.filter((lang) => {
            if (lang.code === langSelection) {
                return lang.key;
            }
        });
        return userLang[0]?.key || languageList[0].key;

    };

    const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        let selectedLang = getSelectedLang(parseInt(event.target.value));
        let selectedLangNumber = parseInt(event.target.value);
        setSelectedLanguage(selectedLang);
        i18n.changeLanguage(selectedLang);
        SessionStorage.saveLanguage(selectedLang);
        setDisplaySuccessMessage(true);
        if (languageSelection) {
            languageSelection(selectedLangNumber);
        }
    };
    
    return (
        <>
            <Dialog data-testid='languageSelectDialog' id="language-select-dialog"
                open={openlanguageSelectorDialog} >
                <DialogTitle data-testid='dialogHeader' style={{
                    width: 300,
                    height: 86,
                }}>{t('REGISTRATION.EULA.LANGUAGE')}</DialogTitle>
                <Divider></Divider>
                <DialogContent style={{
                    width: 300,
                    height: 150,
                    overflow: 'hidden'
                }}>
                    <RadioGroup
                        aria-labelledby="language-selection-radio-buttons-group-label"
                        defaultValue = {(setSelectedLangNumber(SessionStorage.readLanguage())).toString()}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel control={<Radio data-testid='handlelangEnglish' onChange={handleSelection} value="1" />} label= {t('REGISTRATION.EULA.ENGLISH')} />
                        <FormControlLabel control={<Radio data-testid='handlelangDeutch' onChange={handleSelection} value="3" />} label= {t('REGISTRATION.EULA.DEUTSCH')} />
                        <FormControlLabel control={<Radio data-testid='handlelangFrench' onChange={handleSelection} value="2" />} label= {t('REGISTRATION.EULA.FRENCH')} />
                    </RadioGroup>

                    {displaySuccessMessage && <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        open={true}
                        autoHideDuration={20}
                        title={''}
                        message={alertErrorMessage}
                    ></Snackbar>
                    }
                </DialogContent>
                <Divider></Divider>

                <DialogActions style={{ justifyContent: 'right', height: 50 }}>
                    <Button style={{ textTransform: 'none' }} id="btnOkay" data-testid="btnOkay" color="primary" onClick={handleCloselanguageSelectorDialog}>
                        {t('ACTIONS.OKAY')}
                    </Button>
                </DialogActions>

            </Dialog>
            <CardContent
                style={{ flex: '1 1 0px', overflow: 'auto' }}
                onScroll={registration.eula ? handleScroll : undefined}
                ref={eulaContent}
                data-testid={'eula-content'}
            >
                {process.env.NODE_ENV !== 'test' ? (
                    <PDFRender key={'pdf'} file={EULA_FILE_PATH} pageCount={21} width={400} />
                ) : (
                    <Typography>{registration.eula || initialEULA || t('REGISTRATION.EULA.LOADING')}</Typography>
                )}
            </CardContent>

            <CardActions style={{ padding: '8px 0' }}>
                <FormControlLabel
                    style={{ marginLeft: 0 }}
                    control={
                        <Checkbox
                            checked={eulaAgree}
                            disabled={!eulaScrolled}
                            onChange={handleChange}
                            data-testid="eula-checkbox"
                        />
                    }
                    label={t('REGISTRATION.EULA.AGREE_TERMS')}
                />
            </CardActions>
        </>
    );
};
