const resources = {
  translation: {
    DASHBOARD: {
      LOCATION_CARD: {
        HEADER: {
          SUBTITLE: {
            NO_DATA: "0",
            ALARM: "{{count}} Alarme",
            ALARM_plural: "{{count}} Alarmes",
            ALARM_noData: "0 Alarme",
            NORMAL: "Normal",
},
          INFO: {
            SEPARATOR : ",",
            DEVICE: "{{count}} appareil",
            DEVICE_plural: "{{count}} appareils",
            SITE: "{{count}} Site",
            SITE_plural: "{{count}} Sites",
            DEVICE_noData: "0 appareil",
            SITE_noData  : "0 Site",
},
},
        BODY: {
          ALARMS: "{{count}} Alarme",
          ALARMS_plural: "{{count}} Alarmes",
          EVENTS: "{{count}} événement",
          EVENTS_plural: "{{count}} événements",
          ALARMS_noData: "0 Alarme",
          EVENTS_noData: "0 événement",
},
        FOOTER: {
          VIEW_LOCATION: "Voir l'emplacement",
},
},
      SITE_CARD: {
        HEADER: {
          SUBTITLE: {
            NO_DATA: "--",
},
          INFO: {
            DEVICE: "{{count}} appareil",
            DEVICE_plural: "{{count}} appareils",
            DEVICE_noData: "0 appareil",
},
},
        BODY: {
          ONLINE_noData: "'--",
          ALARMS: "{{count}} Alarme",
          ALARMS_plural: "{{count}} Alarmes",
          EVENTS: "{{count}} événement",
          EVENTS_plural: "{{count}} événements",
          ALARMS_noData: "0 Alarme",
          EVENTS_noData: "0 événement",
          OFFLINE: "Hors ligne",
          ONLINE: "En ligne",
},
        FOOTER: {
          VIEW_LOCATION: "Voir le site",
},
},
      TIMELINE_DETAILS: {
        ACKNOWLEDGED: "RECONNU",
        OPEN: "ACTIF",
        CLOSED: "FERMÉ",
        EARLIER: "Plus tôt",
        FUTURE: "Futur",
        INACTIVE: "Inactif",
        LAST_WEEK: "La semaine dernière ",
        THIS_WEEK: "Cette semaine",
        TIMELINE: "Calendrier",
        TODAY: "Aujourd'hui",
        UNACKNOWLEDGED: "Non-acquitté",
        YESTERDAY: "Hier",
},
      ADD_BUILDING: "Ajouter un Bâtiment à",
      AUTO_REDIRECT_BANNER: {
        ADD_LOCATION_BUTTON: "Ajouter un emplacement",
        DISMISS: "Rejeter",
        MESSAGE: "Vous avez été automatiquement redirigé vers {{location}} puisque vous n'avez aucun autre emplacement de premier niveau.",
},
      USERS_ADMIN: "Administrateur",
      USERS_ADMIN_S: "Administrateurs",
      ADMINS_TITLE: "Administrateurs",
      SITE_DETAILS: {
        READING_CARDS: {
          ALARM: "Alarme",
          BATTERY: "Batterie",
          FULL: "Complet",
          OFFLINE: "Hors ligne",
          ONLINE: "En ligne",
          PENDING: "en attente",
          UNKNOWN: "Inconnu",
          VIEW_ALL: "Tout voir",
},
        ALL_DETAILS: "Tous les détails",
        ALL_DEVICES: "Tous les appareils",
        TRENDS_CARD: {
          CHANNELS: "Canaux",
          DAY: "Jour",
          DAYS: "Jours",
          TITLE: "Tendances",
          TRENDVIEWER: "Visionneuse de tendances",
          VIEW_ALL: "Voir l’intégralité de Trendviewer",
},
        DEVICES_CARD: {
          TITLE: "appareils",
          VIEW_ALL: "Voir tous les appareils",
},
        TIMELINE_CARD: {
          TITLE: "Calendrier",
          VIEW_ALL_EVENTS: "Voir tous les événements",
},
},
      ALL_BUILDINGS_COUNT: "Tous les bâtiments  ({{count}})",
      ALL_LOCATIONS: "Tous les emplacements",
      ALL_ORGANIZATION: "Toutes les Organisations",
      ALL_DEVICES_COUNT: "Tous les systèmes  ({{count}})",
      ADMINS_AND: "et",
      DASHBOARD_ASSETS_TITLE: "Systèmes",
      STATUS_COUNT_BLOCKED: "Bloqué  ({{count}})",
      BUILDING_CONTACT: "Contact du Bâtiment",
      BUILDING_CONTACT_DETAILS_LABEL: "Contacts du Bâtiment",
      CHOOSE_LOCATION: "Choisir l'emplacement",
      CONTACT_MANAGER: "Contacter le gestionnaire de votre compte",
      ADD_ORGANIZATION: "Créer une Organisation",
      OVERVIEW_TITLE: "Tableau de bord",
      DASHBOARD_TITLE: "Page d'accueil",
      DEVICES_TITLE: "appareils",
      EARLIER: "Plus tôt",
      EDIT_CONTACT_BUTTON: "Modifier les informations du contact",
      BUILDING_DETAIL_EMAIL: "E-mail",
      ERROR_LOADING_CHANNELS: "Erreur lors du chargement des canaux",
      ERROR_LOADING_DATA: "Erreur lors du chargement des données",
      ERROR_LOADING_DEVICES: "Erreur lors du chargement des appareils",
      ERROR_LOADING_EVENT: "Erreur lors du chargement de l'événement",
      ERROR_LOADING_EVENTS: "Erreur lors du chargement des événements",
      ERROR_LOADING_LOCATION: "Erreur lors du chargement de l'emplacement",
      ERROR_LOADING_LOCATIONS: "Erreur lors du chargement des emplacements",
      ERROR_LOADING_ORGANIZATION: "Erreur lors du chargement de l'organisation",
      ERROR_LOADING_SITE: "Erreur lors du chargement du site",
      ERROR_LOADING_SITES: "Erreur lors du chargement des sites",
      ERROR_LOADING_TRENDS: "Tendances de chargement des erreurs",
      EVENT_NOT_FOUND: "Aucun événement trouvé",
      STATUS_COUNT_ANOMALY: "En erreur ({{count}})",
      LAST_90_DAYS: "Les 90 derniers jours",
      LATEST_LOGS: "Derniers événements",
      LOCATION_NOT_FOUND: "Aucun emplacement trouvé",
      LOCATIONS_TITLE: "Emplacements",
      LOCATION: "Emplacements",
      DASHBOARD_LOGBOOK_TITLE: "Journal",
      MAIN_CONTACT_LABEL: "Contact principal du Bâtiment",
      ADMINS_MORE: "plus",
      DASHBOARD_MANAGE_ORGANIZATION_TITLE: "Mes organisations",
      NO_FOUND: "Aucune {{entity}} trouvé",
      NO_EVENTS: "Aucune alarme",
      NO_CHANNELS: "Aucun canal",
      NO_CHANNELS_ENABLED: "Aucun canal activé",
      NO_DATA: "Aucune donnée",
      NO_DATA_POINTS: "Aucune donnée pour la plage spécifiée",
      NO_DEVICES: "Aucun appareil",
      NO_LOCATION_SELECTED: "Aucun emplacement sélectionné",
      NO_LOCATIONS: "Aucun emplacement",
      NO_ORGANIZATION_SELECTED: "Aucune organisation sélectionnée",
      NO_SITES: "Aucun site",
      NO_TRENDS: "Aucune tendance",
      NO_USER_DATA: "Aucune donnée utilisateur",
      STATUS_COUNT_OFFLINE: "Hors ligne  ({{count}})",
      ORGANIZATION_TITLE: "ORGANISATION",
      ORGANIZATION_NOT_FOUND: "Aucune organisation trouvée",
      ORGANIZATION: "Organisations",
      BUILDING_DETAIL_PHONE: "Téléphone",
      PROJECT: "PROJET",
      STATUS_COUNT_READY: "Prêt ({{count}})",
      DASHBOARD_REPORTS_TITLE: "Rapports",
      SAVE_USER_DETAILS: "Enregistrer les informations utilisateur",
      SECONDARY_CONTACT_LABEL: "Second contact du Bâtiment",
      SITE_NOT_FOUND: "Aucun site trouvé",
      SITE: "Sites",
      TIMELINE_TITLE: "Calendrier",
      NEW_SITE: "rejoindre ou créer {{entity}}.",
      UNKNOWN_DEVICE_TYPE: "Inconnu",
      EDIT_USER_DETAILS: "Détails de l'utilisateur",
      USERS_TITLE: "Utilisateurs",
      VIEW_LOGBOOK: "Voir les événements",
      USERS_VIEWER: "Utilisateur",
      USERS_VIEWER_S: "Utilisateurs",
      NOT_ACCESS: "Vous n'avez pas encore accès à {{entity}} ",
},
    EVENT_DETAILS: {
      UNKNOWN: "'--",
      ACKNOWLEDGE: "Acquitter",
      ACKNOWLEDGED: "Acquitté",
      ACKNOWLEDGE_BY: "Accusée de réception par {{user}} à",
      ACKNOWLWEGED_ERROR: "L'acquittement n'a pas pu être communiqué au périphérique final à {{errorDate}}. Si ce problème persiste, veuillez appeler notre équipe d'assistance au {{phoneno}} ou par e-mail",
      DEVICE_MODEL: "Modèle",
      DEVICE_SERIAL: "Numéro de série",
      ERROR_LOADING_DEVICE_DETAILS: "Erreur de chargement des détails des appareils",
      ERROR_LOADING_EVENT_DETAILS: "Echec du chargement d'information sur l'événement ",
      TITLE: "Détails de l'événement",
      ALARM_STATUS: "État initial de l’alarme",
      LAST_DATA_RECEIVED: "Dernière mise à jour d'alarme reçue",
      NO_DEVICE_DETAILS: "Aucune information sur l'appareil",
      NO_EVENT_DETAILS: "Aucune information sur l'événement",
      PLEASE_RETRY: "Recommencer s'il vous plaît",
      SENDING: "Envoi...",
      SET_POINT: "Régler (le seuil)",
      THRESHOLD: "Seuil",
      TIME_ALARM_SENT: "Heure de l’alarme initiale envoyée",
      EMPTY_VALUE: " ",
},
    DEVICE_DETAILS: {
      EMPTY_VALUE: "'--",
      USERS_ADMINS: "administrateur",
      USERS_VIEWER: "spectatrice",
      FIRMWARE_AVAILABLE_GUIDE_LINK: "(Guide)",
      USERS_COUNTS: " {{adminCount}} admin, {{viewerCount}} viewer",
      FIRMWARE_AVAILABLE_GUIDE: "{{availableVersion}} Disponible",
      LUMINARY_ATSD: "ATSD #",
      CONNECTION_TYPE_BLE: "BLE",
      BLOCK: "Bloqué",
      BUILDING: "Bâtiment",
      CONNECTION_TYPE_CELLULAR: "Cellulaire",
      LUMINARY_CIRCUIT: "Circuit",
      LUMINARY_CIRCUIT_HASH: "Circuit #",
      CITY: "Ville",
      CONNECTED: "Connecté",
      CONNECTION_TYPE: "Type de connexion",
      DATE_COMMISSIONED: "Date de mise en service",
      DEVICE_MODEL: "Modèle",
      DEVICE_SERIAL: "Numéro de série",
      DURATION_TEST: "Test d'autonomie",
      DURATION_TEST_DURATION: "Durée du test d'autonomie",
      ERROR: "Erreur",
      CHANNEL_DATA_ERROR: "Erreur de chargement des données data",
      DEVICE_CONNECTION_ERROR: "Erreur de chargement des données de connexion",
      DEVICE_DETAILS_ERROR: "Erreur de chargement des détails des appareils",
      CONNECTION_TYPE_ETHERNET: "Ethernet",
      EVENT: "Evénement",
      FIRMWARE_VERSION: "Version du logiciel",
      FUNCTIONAL_TEST: "Test fonctionnel",
      DELAY_FUNCTIONAL_TEST: "Retard du test fonctionnel",
      GATEWAY_ADDRESS: "Adresse de la gateway",
      GATEWAY_NAME: "Nom de la gateway",
      GATEWAY_COUNT: "Gateway de {{systemCount}} système",
      GATEWAY_COUNT_SYSTEMS: "Gateway de {{systemCount}} systèmes",
      GATEWAYS: "Gateways",
      HOSTNAME: "Nom de domaine",
      LUMINARY_DETAIL_INFO: "Info",
      IPADDRESS: "Adresse IP",
      LAST_DATA: "Dernières données",
      LUMINAIRE: "Luminaire",
      LUMINARY_ADDRESS: "Adresse du luminaire",
      LUMINAIRES: "Luminaires",
      LUMINARIES_COUNTS: "Luminaires",
      MAC_ADDRESS: "Mac Address",
      CONNECTION_TYPE_MODBUS: "Modbus",
      LUMINARY_MODEL: "Modèle",
      CONNECTION_TYPE_MQTT: "MQTT",
      LUMINARY_NAME: "Nom",
      NAME: "Nom",
      NETWORK_DETAILS: "Informations réseau",
      NEXT: "Suivant",
      LUMINARY_DISCRIPTION: "Prochain test d'autonomie : ",
      NEXT_DURATION_TEST: "Prochain test d'autonomie : ",
      NEXT_FUNCTIONAL_TEST: "Prochain test d'autonomie :",
      NO_CHANNEL_DATA: "Aucune donnée de canal",
      NO_DEVICE_CONNECTION: "Aucune donnée de la connexion du système",
      NO_DEVICE_DETAILS: "Aucune donnée du système",
      OFFLINE: "Hors ligne",
      ONLINE: "En ligne",
      ORGANIZATION: "Organisation",
      PART_NUMBER: "Référence",
      PREVIOUS: "Précédent",
      PREVIOUS_DURATION_TEST: "Dernier test d'autonomie : ",
      PREVIOUS_FUNCTIONAL_TEST: "Dernier test fonctionnel",
      READY: "Prêt",
      CONNECTION_TYPE_RS485: "RS485",
      SERIAL_NUMBER: "Numéro de série",
      GATEWAY_SHOW_ALL: "Voir tout",
      STATUS: "Etats",
      LUMINARY_STATUS: "Etats",
      SUBNET_MASK: "Subnet Mask",
      SYSTEM: "SYSTÈME",
      SYSTEMS: "Système",
      LUMINARY_DETAIL_TEST: "Tests",
      TYPE: "Type",
      LUMINARY_TYPE: "Type",
      UPDATE_REQUIRED: "Mise à jour nécessaire",
      USERS: "Utilisateurs",
      WARNING: "Attention",
      CONNECTION_TYPE_WIFI: "Wi-Fi",
      CONNECTION_TYPE_WIREPASS: "Wirepas",
},
    FORMS: {
      OPTIONAL: "Optionnel",
      BUILDING_COMPANY_ADDRESS: "Adresse - Ligne 1",
      BUILDING_COMPANY_ADDRESS2: "Ligne d’adresse 2 (facultatif)",
      BUILDING_DETAILS: "Information du bâtiment ",
      CITY: "Ville",
      USER_ADDRESS_1: "Adresse de la société - Ligne 1",
      USER_ADDRESS_2: "Adresse de la société - Ligne 2",
      COMPANY_NAME: "Société",
      CONFIRM_PASSWORD: "Confirmer nouveau mot de passe",
      COUNTRY: "Pays*",
      HELPING_TEXT: "Les champs (*) sont obligatoires",
      FIRST_NAME: "Prénom",
      LAST_NAME: "Nom",
      LOCAL_PRIMARY_EMAIL: "Courriel du principal contact local",
      LOCAL_PRIMARY_CONTACT_INFO: "Informations du principal contact local ",
      LOCAL_SECONDARY_EMAIL: "Courriel d'un second contact local",
      LOCAL_SECONDARY_CONTACT_INFO: "Information d'un second contact local ",
      ORGANIZATION_ADDRESS_HEADER: "Adresse de l'organisation ",
      COMPANY_ADDRESS: "Adresse de l'organisation - ligne 1",
      ADDRESS_LINE_2: "Adresse de l'organisation - ligne 2",
      ORGANIZATION_DETAILS_HEADER: "Détails de l'organisation",
      PASSWORD: "Mot de passe",
      PASS_MATCH_ERROR: "Les mots de passe doivent être identiques",
      PHONE_NUMBER: "Numéro de téléphone",
      INVALID_EMAIL_ERROR: "Entrer un courriel valide",
      INVALID_PHONE_NUMBER: "Entrer un numéro de téléphone valide",
      INVALID_POSTAL_CODE: "Entrer un code postal valide",
      POSTAL_CODE: "Code postal",
      PRIMARY_CONTACT_INFORMATION: "Information du principal contact",
      RESET_PASSWORD: "réinitialisation du mot de passe",
      TOGGLE_PASSWORD_VISIBILITY: "Activer/désactiver la visibilité des mots de passe",
      VERIFICATION_CODE: "Code de vérification",
},
    LOGBOOK_DETAILS: {
      LABEL_EVENT_AGO: "depuis",
      PART_NUMBER: " PN: ",
      SERIAL_NUMBER: " SN: ",
      ADD_COMMENTS: "+Ajouter des commentaires",
      REQUIRES_FIRMWARE_UPDATE: "1 Mise à jour du logiciel recommandée",
      ACKNOWLEDGE: "Acquitter",
      ACKNOWLEDGED: "Acquitté",
      ACKNOWLEDGED_BY: "Acquitté par",
      PREVIOUS_ACKNOWLEDGED_BY: "Acquitté par:",
      ACTIVE: "Actif",
      ACTIVE_ACKNOWLEDGED: "Actif. Acquitté",
      AT: "à",
      LABEL_CLOSED: "Fermer",
      CLOSED: "Fermer",
      COMMENTS: "Commentaires",
      DESCRIPTION: "Description",
      DEVICE: "Elément",
      EDIT: "Editer",
      EVENT: "Evénement",
      LABEL_EVENT_BEGAN : "Début de l'événement",
      LABEL_EVENT_CLOSE: "Fin de l'événement",
      LOGBOOKDETAIL: "Détail du journal de bord",
      PARTNO: "Réf: ",
      PREVIOUS_LOG_ITEM: "Evènement précédent du système",
      PREVIOUS_LOG_TITLE: "Evènement précédent du système",
      SERIALNO: "SN: ",
      STATUS: "Etats",
      SYSTEM: "Système",
      TIMESTAMP: "Horodatage",
      SYSTEM_TYPE : "Type: ",
},
    ORGANIZATION_MANAGE: {
      BUILDING_COUNT: " Bâtiment",
      BUILDING_COUNT_S: "Bâtiments",
      BUILDINGS_TITLE: "Bâtiments",
      ADMIN_COUNT: "Administrateur de l'organisation",
      ADMIN_COUNT_S: "Administrateurs de l'organisation",
      VIEWER_COUNT: "Utilisateur de l'organisation",
      VIEWER_COUNT_S: "Utilisateurs de l'organisation",
      SYSTEM_COUNT: "Système",
      SYSTEM_COUNT_S: "Systèmes",
      ADD_NEW_BUILDING: "+ Ajouter un nouveau Bâtiment",
      CREATE_ORGANIZATION: "+ Créer une organisation",
      ERROR_ADD_NEW_SYSTEM_ACTION: "Ajouter un nouveau Système",
      ALL: "Tous",
      SORT_TYPE_NAME: "Alphabets",
      SORT_TYPE_CITY: "Ville",
      COMMENTS_HELPER: "Commentaires pour aider le technicien de maintenance à localiser le site",
      ORGANIZATION_NAME_HELPER: "Lettres, chiffres et tirets uniquement",
      MARK_AS_FAV_API_FAILURE_MESSAGE: "Marquer comme échec",
      ERROR_NO_BUILDING_FOUND: "Aucun bâtiment trouvé",
      ERROR_NO_GATEWAY_FOUND: "Aucune Gateway trouvée ",
      ERROR_NO_LOGS_TITLE: "Pas d'événement sur cette période",
      ERROR_NO_LUMINARY_FOUND: "Aucun luminaire trouvé",
      ERROR_NO_ORG_FOUND: "Aucune organisation trouvée",
      ERROR_NO_REPORT_FOUND: "Aucun rapport trouvé",
      ERROR_NO_SYSTEM_FOUND: "Aucun système trouvé",
      ERROR_NO_USER_FOUND: "Aucun utilisateur trouvé",
      SORT_TYPE_NUMBER_OF_SYSTEMS: "Nombre de Systèmes",
      QUIT_EDIT_USER_DETAILS: "Quitter la modification",
      QUIT_TITLE: "Quitter la modification “{{orgName}}”?",
      ERROR_READ_INSTALLATION_MANUALS_ACTION: "Lire le manuel d'installation",
      SEARCH: "Rechercher",
      SEARCH_BUILDINGS: "Rechercher",
      SEARCH_BUILDINGS_DASHBOARD: "Rechercher des bâtiments",
      SEARCH_SYSTEM_DASHBOARD: "Rechercher des systèmes",
      SORT_BY_HEADER: "TRIER PAR",
      STARRED_ORGANIZATIONS: "Organisations favorites",
      VIEW_ORGANIZATION: "Afficher l'organisation",
      ERROR_NO_DEVICE_TITLE: "Vous n'avez pas encore de systèmes dans cette organisation.",
      ERROR_NO_DEVICE_DESCRIPTION: "Vous pourrez superviser l'état de vos éclairages de sécurité une fois que vous aurez commencé à ajouter des systèmes à cette organisation.",
      QUIT_MESSAGE: "Les modifications en cours seront perdues",
},
    ADD_BUILDING: {
      BLDG_ORG_CONTACT: "Contacts",
      BLDG_ORG_CONTACTS: "’s Contacts",
      BLDG_ADDITIONAL_DESC: "Vous pouvez ajouter des commentaires supplémentaires (facultatif) pour aider votre équipe de maintenance",
      BLDG_ADD_DESCRIPTION: "Descriptions supplémentaires",
      BLDG_ADDRESS: "adresse",
      BLDG_CREATTION: "Création d’un bâtiment",
      BLDG_HEADER: "Créer un nouveau bâtiment pour ",
      BLDG_LOCATION_MESSAGE: "Faites glisser la carte pour placer l'épingle sur le bâtiment. Cela permettra aux techniciens de maintenance d'arriver plus rapidement sur site à l'avenir.",
      BLDG_EDIT_BTN: "Modifier les informations du contact",
      BLDG_NAMELENGTH: "Ex : Bureau principal, Entrepôt, …",
      BLDG_INITILIZING_NETWORK: "Initialisation du réseau…",
      BLDG_INSIDE: "Intérieur",
      BLDG_LOCAL_CONTACT: "Contact local du bâtiment",
      BLDG_CONTACT_TITLE: "Détails du contact local du bâtiment",
      BLDG_LOCATE_ON_MAP: "Localiser sur la carte au lieu de ",
      BLDG_LOCATION: "Emplacement",
      BLDG_ADDTIONAL_DETAILS: "Vous pouvez ajouter quelques détails supplémentaires pour terminer la configuration de ",
      ORG_CREATE_SUCESS: "Organisation créée avec succès",
      BLDG_PRIMARYCONTACT: "Contact principal",
      BLDG_PRIMARY_LOCAL_CONTACT: "Principal contact local",
      BLDG_SECONDARYCONTACT: "Contact secondaire (facultatif)",
      BLDG_SECONDARY_LOCAL_CONTACT: "Second contact local",
      BLDG_NO_POSTAL_ADDRESS: "Choisir cette option si l'adresse postale n'est pas connue",
      BLDG_CREATED_MESSAGE: "Création réussie",
      BLDG_ORGANIZATION_FOR: "Utiliser l'organisation",
      BLDG_ADD_CONTACT: "À qui les électriciens/mainteneurs doivent-ils s'adresser à propos du « Bureau principal » ?",
      SAVE_CHANGES_MSG: "Vos changements ont été enregistrés",
},
    USER_SETTINGS: {
       AUTOMATIC_LOGOUT_SUBTITLE: "Désactivez l'expiration automatique de la session après 15 minutes",
      ALARMS: "Alertes",
      PHONE_NO_TEXT: "Un numéro de téléphone est nécessaire pour recevoir les notifications",
      ACCOUNT: "Compte",
      ACCOUNT_CREATED_DATE: "Compte créé",
      ADDRESS: "Adresse",
      ALARMS_TITLE: "Alertes",
      APPLICATION_THEME_COLOR: "Couleur de thème GUI",
      AUTOMATIC_LOGOUT: "Déconnexion automatique",
      UNITS_DESCRIPTION: "British Imperial Units",
      BUILDING_NAME: "Nom du bâtiment",
      TIMEZONE_DESCRIPTION: "Heure d'Europe centrale (GMT-1:00)",
      CHANGE_PASSWORD: "Changement du mot de passe",
      COMPANY_ADDRESS: "Adresse de la société",
      COMPANY_NAME: "Société",
      COMPANY_PHONE_NUMBER: "Numéro de téléphone de la société ",
      DATE_FORMAT: "Format de la date",
      DATEFORMAT_DESCRIPTION: "JJ/MM/AAAA",
      DELETE_ACCOUNT: "Supprimer le compte",
      STATUS_UPDATES: "Le système est en mode repos, en mode secouru, une maintenance est nécessaire  ",
      AUTOMATIC_LOGOUT_TITLE: "Désactiver la déconnexion automatique",
      EMAIL: "Courriel",
      EMAIL_NOTIFICATION: "notification par courriel",
      TEXT_NOTIFICATION_TITLE: "Activer les notifications par SMS",
      ENABLED: "Activé",
      TEXT_NOTIFICATION_BODY: "L'activation des notifications par SMS peut entraîner des frais supplémentaires auprès du fournisseur de services cellulaires. Ces frais ne sont pas inclus dans le contrat de service Eaton.",
      LANGUAGE_DESCRIPTION: "Anglais - UK",
      HEALTH_REPORT_TITLE: "Rapport de l'état",
      LANGUAGE: "Langue",
      HEALTH_REPORT: "Mensuel",
      PRODUCT_UPDATES: "Mises à jour logiciel",
      NOTIFICATIONS_HEADER: "Notifications",
      APP_THEME_DESCRIPTION: "Thème de l'interface graphique",
      ORGANIZATION: "Organisation",
      ORGANIZATION_NAME: "Nom de l'organisation",
      PASSWORD: "Mot de passe",
      PHONE_NUMBER: "Numéro de téléphone",
      PREFERENCES_HEADER: "Préférences",
      PRODUCT_UPDATES_TITLE: "Mise à jour des produits",
      STATUS_UPDATES_TITLE: "Changement d'état",
      TEXT_NOTIFICATION: "Notifications par SMS",
      AUTOMATIC_LOGOUT_BODY: "Cela désactivera une fonctionnalité de sécurité standard incluse dans l'application pour empêcher l'accès à une session abandonnée. Veuillez noter que l'utilisateur sera responsable de la protection des accès non autorisés à ce système et ne devra jamais laisser cette application ouverte sans surveillance.",
      TIME_ZONE: "Fuseau horaire",
      UNITS: "Unités",
      ORG_ADDRESS: "Utiliser l'adresse de l'organisation",
      ORG_CONTACT_DETAILS: "Utiliser les contacts de l'organisation",
      EDIT_USER_DETAILS: "Détails de l'utilisateur",
      NAME: "Nom d'utilisateur",
      USER_NAME: "Nom d'utilisateur",
      WARNING: "Attention",
},
    EDITUSER: {
       PROMOTE_CONFIRMATION: "Si tel était votre intention, veuillez valider votre modification.",
      DEMOTE_CONFIRMATION: "Si tel était votre intention, veuillez apporter les modifications dans la section Rôle/Emplacement de ce formulaire.",
      DEMOTE: "Vous avez modifié l'accès/rôle de cet administrateur d'organisation. Si ce n'est pas votre intention, sélectionnez Annuler.",
      PROMOTE: "Vous avez promu cet utilisateur comme administrateur de l'organisation. Si ce n'est pas votre intention, sélectionnez Annuler.",
},
    ADDUSER: {
      INVITEUSER: "Inviter un utilisateur",
      TITLE: "Ajouter un utilisateur",
      ASTERIKSINFO: "Les champs marqués (*) sont des champs obligatoires et nécessaires pour inviter un utilisateur.",
      INVITESUCCESS: "Invitation réussie !",
      SELECTROLE: "Sélectionnez un rôle pour les emplacements",
      ACCOUNTCREATED: "L'invitation à créer un compte a été lancée avec succès.",
      EMAILRECEIVE: "L'utilisateur recevra une invitation par e-mail pour créer son compte d'ici 5 à 10 minutes. Vous ne pourrez pas voir leur statut tant qu'ils n'auront pas accepté l'invitation.",
      ADDUSERINFORMATION: "Informations sur le compte de l'utilisateur",
},
    GATEWAY: {
      MANAGE_CELLULAR_CONNECTION: "Gérer la connexion cellulaire",
      ENTRY_PASSWORD_TITLE: "Accès à",
      ADD_GATEWAYS: "Ajouter un équipement",
      BLE: {
        DEVICE_STATUS: {
          ALARM: "Alarme",
          BLOCKED: "Bloqué",
          NOT_PROVISIONED: "Non fourni",
          OFFLINE: "Hors ligne",
          ONLINE: "En ligne",
          PROVISIONED: "Fourni",
          READY: "Prêt",
          READY_FOR_COMMISSION: "Prête pour la mise en service",
          WARNING: "Attention",
},
        ERROR: {
          CHANGE_FAILURE_MESSAGE: "Une erreur s'est produite lors de la modification des paramètres",
          BUILDING_ALREADY_EXISTS: "Nom de bâtiment déjà existant",
          INVALID_CONNECTION_STRING: "Chaîne de connexion non valide",
          INVALID_UUID: "UUID non valide",
          ORGANIZATION_ALREADY_EXISTS: "Nom d'organisation déjà existant",
          REQUEST_FAILED_TITLE: "Echec de la demande",
          REQUEST_FAILED_GATEWAY_ADD: "Echec de l'ajout d'une gateway",
          REQUEST_FAILED_MESSAGE: "Echec de la demande. Veuillez attendre quelques minutes avant de réessayer.",
          UNAUTHORIZED_ACCESS: "Accès non autorisé. Veuillez vous connecter à nouveau",
},
        DEVICE_TYPE: {
          ATPLUS_CONTROLLER: "Télécommande TLU AT+",
          COMMON_GATEWAY: "Gateway commune",
          DGS: "DualGuard-S",
          LPSMART: "DualSmart-S",
          WIRELESS_GATEWAY: "Gateway sans fil",
},
        SET_PASSWORD_STATUS: {
          INVALID_LENGTH: "Longueur incorrecte",
          INVALID_PASSWORD: "Mauvais mot de passe",
          PASSWORD_CHANGE_FAILURE: "Echec du changement de mot de passe",
          PASSWORD_CHANGE_SUCCESS: "Mot de passe changé avec succès",
          PASSWORD_COMPARISON_FAIL: "Échec de la comparaison des mots de passe",
},
        DEFAULT_LOGIN_STATUS: {
          LOGIN_DEFAULT_SUCCESS: "Connexion par défaut réussie",
          LOGIN_FAILURE: "Echec de connexion",
          LOGIN_MAX_ATTEMPTS_EXCEEDED: "nombre de tentative de connexion dépassé",
          LOGIN_SUCCESS_ADMIN: "Connecté en tant que Admin",
          LOGIN_SUCCESS_SERVICE: "Connecté en tant que Service",
          LOGIN_SUCCESS_USER: "Connecté en tant que Utilisateur",
          LOGOUT: "Déconnexion",
},
        NULL_VALUE: "Pas de réponse",
        TURN_ON_BLE_MSG: "Activez le Bluetooth pour permettre à « Building Safety Manager » d'accéder aux systèmes locaux.",
},
      GATEWAY_DISCONNECT_ALERT: "Êtes-vous sûr de vouloir vous déconnecter ?",
      DISCARD_CHANGES_ALERT: "Etes-vous sûr de vouloir ignorer les modifications ?",
      UNLINKEXISTING_SYSTEM_ALERT: "Êtes-vous sûr de vouloir déconnecter le système existant ?",
      GATEWAY_UNREGISTER_ALERT: "Êtes-vous sûr de vouloir désinscrire la gateway ?",
      BACKUP_CONNECTION: "Connexion de secours",
      TIMELINE_VIEW: {
        BUILDING: "Bâtiment",
        OPTINAL: "Optionnel",
        ORGANIZATION: "Organisation",
        SELECT_BUILDING: "Sélectionner un bâtiment",
        SELECT_ORGANIZATION: "Sélectionner une organisation",
        SUB_LOCATION: "Sous-emplacement",
},
      BLUETOOTH_SCAN_ERROR: "Vous ne trouvez pas votre gateway ? Vérifier",
      CARRIER_NAME: "Nom du support",
      CELLULAR: "Cellulaire",
      CHANGE_CONNECTION_METHOD: "Changer le mode de connexion",
      CHECK_FOR_UPDATES: "Vérifier les mises à jour",
      CONFIGURE_WI_FI_CONNECTION: "Configurer la connexion Ethernet",
      CONFIGURE_CELLEULAR_CONNECTION: "Configurer la connexion cellulaire",
      COMMISSION_NEW_GATEWAY: "Connecter à système d'éclairage de sécurité Eaton.",
      CONNECTED: "Connecté",
      CONNECTION_METHOD: "Méthode de connexion",
      GATEWAY_CONNECTION_PRIORITY: "Priorité de connexion",
      CONNECTIONS: "Connexions",
      GATEWAY_ADD_BUILDING_VALUE: "Créer un nouveau bâtiment",
      GATEWAY_ADD_ORGANIZATION_VALUE: "Créer une nouvelle organisation",
      SET_DATE_TIME_SUCCESS: "La date et l'heure ont été enregistré avec succès",
      DEFAULT_PASSWORD_ADMIN_CHANGED: "DEFAULT_PASSWORD_ADMIN_CHANGED",
      DEFAULT_PASSWORD_USER_CHANGED: "DEFAULT_PASSWORD_ADMIN_CHANGED",
      DISCONNECTED: "L'appareil est déconnecté ou ne parvient pas à se connecter, veuillez réessayer",
      DISCONNECTED_SUCCESS: "Appareil déconnecté avec succès",
      GATEWAY_UNREGISTER_SUCCESS: "Système effacé avec succès",
      GATEWAY_UNREGISTER_FAILURE: "Système effacé avec succès",
      DGS_NOT_COMMUNICATING: "DG-S ne communiquant pas",
      DGS_UNREACHABLE: "DG-S inaccessible",
      DHCP: "DHCP",
      DNS: "DNS",
      WIFI_CREDENTIALS_MESSAGE: "Le mot de passe doit être valide",
      ENTER_WIFI_CREDENTIALS: "Entrer les identifiants Wi-Fi",
      ETHERNET: "Ethernet",
      ETHERNET_CONNECTION: "Connexion Ethernet",
      FINISH: "Terminer",
      CHANGE_PASSWORD_INFO: "Pour des raisons de sécurité, merci de changer le mot de passe",
      GATEWAY_ACTIVATED: "Gateway activée",
      GATEWAY_ADDED: "Gateway ajoutée",
      GATEWAY_COMMISSIONING: "Mise en service de la gateway",
      GATEWAY_CONNECTED: "Gateway connectée",
      GATEWAY_DETAILS: "Informations de la gateway",
      GATEWAY_NAME: "Nom de la gateway*",
      GATEWAY_PASSWORD: "Mot de passe de la gateway",
      GATEWAY_PASSWORD_INCORRECT: "Mot de passe de la gateway incorrect",
      GATEWAY_NAME_DESCRIPTION: "Personnaliser le nom et la description de la gateway",
      SUCCESSFULLY_ADDED: "a été ajouté avec succès.",
      LAST_CONNECTED: "Dernière connexion : 12:34:56 12 May 2024",
      LINK_NEW_DEVICE: "Connecter un nouvel équipement",
      LINK_NEW_SYSTEM: "Connecter un nouveau système",
      LINK_SYSTEM: "Connecter un système",
      LINK_ANOTHER_SYSTEM: "Connecter un autre système ",
      LINK_SYSTEMS: "Connecter les systèmes",
      LOCATION: "Emplacement",
      DENIED_LOCATION_PERMISSION: "Localisation refusée, activez-la pour rechercher les équipements Bluetooth à proximité",
      CONNECTION_TYPE_VALUE: "LTE/WCDMA/GSM",
      MANAGE_ETHERNET_CONNECTION: "Gérer la connexion Ethernet",
      MANAGE_WIFI_CONNECTION: "Gérer la connexion Wi-Fi",
      SET_NAME_DESCRIPTION_SUCCESS: "Nom et description créés avec succès",
      NO_DEVICE_FOUND: "Aucun équipement trouvé",
      UNLINK_SYSTEM_ERROR: "Aucune gateway pour se désinscrire ",
      NO_SYSTEM_YET: "Aucun système pour l'instant",
      DHCP_OFF: "Off",
      DHCP_ON: "On",
      NO_SYSTEM_YET_DESCRIPTION: "Une fois que vous aurez connecté vos systèmes à cette gateway, vous verrez une liste de systèmes ici. Vous pouvez compléter le descriptif.",
      ONLINE_TEXT: "en ligne.",
      OTHER_NETWORK: "Autre réseau…",
      BLUETOOTH_INSTRUCTIONS: "nos instructions",
      ENTER_ADMIN_CHANGE_PASSWORD: "Merci de saisir le nouveau mot de passe administrateur ",
      ENTRY_PASSWORD: "Merci de saisir le mot de passe de la gateway pour",
      PLEASE_ENTER_NEW_PASSWORD_AGAIN_RECONNECT: "Saisir le nouveau mot de passe pour vous reconnecter à la gateway",
      PRIMARY_CONNECTION: "Connexion principale",
      GATEWAY_PRIORITY: "Priorité",
      PULL_REFRESH_TEXT: "Déroulez pour actualiser",
      REMOVE_CONNECTION: "Supprimer la connexion",
      REMOVE_PRIMARY_CONNECTION: "Supprimer la connexion principale",
      REQUIRE_GATEWAY_CONNECTED_TO_INTERNET: "Nécessite une gateway connectée à Internet",
      SAVE_WI_FI_CREDENTIALS: "Sauvegarder les identifiants Wi-Fi pour d'autres appareils",
      SCAN_QR_CODE: "Scanner un QR code",
      SCANNING_FOR_NEAR_BY_BLE: "Recherche des Bluetooth à proximité",
      SCANNING_FOR_NEAR_BY_WIFI: "Recherche des Wi-Fi à proximité",
      SEARCH_VIA_BLUETOOTH: "Rechercher avec le Bluetooth",
      SEARCHING_FOR_NEARBY_WIFI: "Recherche des Wi-Fi",
      SEARCH_VIA_BLUETOOTH_EllIPSE: "Recherche via Bluetooth",
      SECONDARY_CONNECTION: "Connexion secondaire",
      SELECT_PRIMARY_CONNECTION: "Sélectionner la méthode de connexion principale",
      SET_DATE_TIME_CONFIG: "Réglage de la date et de l'heure incorrect ",
      SET_DATE_TIME_FAILURE: "Echec du réglage de la date et de l'heure",
      SET_NAME_DESCRIPTION_INCORRECT_CONFIG: "Nom et description incorrects",
      SET_NAME_DESCRIPTION_FAILURE: "Echec de la création du nom et de la description",
      STATUS: "Etats",
      SYNC_DATE_TIME: "Synchroniser la date et l'heure",
      SYSTEM_UNLINK_SUCCESS: "Système déconnecté avec succès",
      SYSTEM_UNLINKED: "Système déconnecté ",
      SYSTEMS: "Système",
      UNLINK_SYSTEM_SUCCESS: "Les systèmes ont été désenregistrés avec succès.",
      TERTIARY_CONNECTION: "Connexion tertiaire",
      UNLINK_ANOTHER_SYSTEM: "Déconnecter un autre système ",
      UNLINK_EXISTING_SYSTEM: "Déconnecter un système existant",
      UNLINK_EXIST_SYSTEM: "Désinscrire la gateway",
      USE_WI_FI_PASSWORD: "Utiliser le mot de passe Wi-Fi",
      USE_WPA2_KEY: "Utiliser la clé WPA2",
      CARRIER_NAME_VALUE: "Vodafone NL 3G",
      WI_FI: "Wi-Fi",
      GATEWAY_WIFI_CONFIG: "Configuration Wi-Fi",
      WI_FI_NETWORK: "Réseau Wi-Fi",
      WI_FI_NETWORK_NAME: "Nom du Wi-Fi",
      WI_FI_PASSWORD: "Mot de passe Wi-Fi",
},
    ERROR_MESSAGES: {
      GENERIC_ERROR_CONTACT_DETAILS: "ou appeler le ",
      GENERIC_ERROR: "Une erreur inconnue s'est produite. Si ce problème persiste, veuillez contacter le support Eaton au",
      9003: "L'opération demandée ne peut pas être réalisée, veuillez contacter votre administrateur",
      2002: "Le lien d’inscription de l’utilisateur est expiré ou déjà utilisé.",
      2018: "Nous ne sommes pas en mesure de finaliser votre inscription. Votre organisation a dépassé le nombre d'utilisateurs autorisés à votre abonnement. Pour étendre vos droits, veuillez contacter votre support CST par e-mail à l'adresse suivante ",
},
    FORGOT_PASSWORD: {
      INSTRUCTIONS: "Veuillez saisir l'e-mail associé à ce compte. <br/><br/>' +Si cet e-mail possède un compte auprès de Building Safety Management System, vous recevrez une réponse dans un délai de <3> jour ouvrable</3>.<br/><br/>' + 'Pour toutes urgences, veuillez appeler votre support'",
      ERROR: "Impossible de réinitialiser votre mot de passe pour le moment.",
      RESET_CODE_ERROR: "Votre code de réinitialisation est erroné.",
},
    ACCOUNT_SETTING: {
      UPDATES_AVAILABLE: "Mises à jour disponibles",
      ACCOUNT_CREATED: "Compte créé",
      ACCOUNT_PREFERENCES: "Préférences d'utilisateur",
      ADDRESS_LINE2: "Ligne d’adresse 2 (facultatif)",
      ALARAM: "Alertes",
      ALL_SYSTEM_FIRMWARE: "Toutes les mises à jours des systèmes",
      APPLICATION_THEME_COLOR: "Couleur de thème GUI",
      AUTOMATIC_LOG_OUT: "Déconnexion automatique",
      AUTOMATIC_UPDATES: "Mises à jour automatique",
      AUTOMATIC_UPDATES_INFO: "Mise à jour automatique du logiciel du système",
      CHANGE_PASSWORD: "Changement du mot de passe",
      STORAGE_FIRMWAREFILE_INFO: "Choisissez comment stocker les fichiers des mises à jour sur votre appareil",
      CITY: "Ville",
      COMPANY_ADDRESS: "Adresse de la société",
      COMP_ADDRESS: "Adresse de la société*",
      COMPANY_DETAILS: "Détails de la société",
      COMPANY_NAME: "Société",
      COMP_NAME: "Société",
      COMPANY_PHONE_NUMBER: "Numéro de téléphone de la société ",
      CODE: "Code pays",
      DATE_FORMAT: "Format de la date",
      DELETE_ACCOUNT: "Supprimer le compte",
      DEVICE_BLOCKING: "Le système est en mode repos, en mode secouru, une maintenance est nécessaire  ",
      EMAIL: "E-mail",
      EMAIL_ADDRESS: "Courriel",
      REQUIRED_FIELD: "Les champs marqués (*) sont obligatoires pour continuer.",
      FIRMWARE_FILE: "Fichiers des mise à jour",
      FIRMWARE_UPDATES: "Mise a jour du logiciel.",
      FIRST_NAME: "Prénom",
      FT_DT_FAIL: "Echec des tests, déconnexion, panne batterie, panne de lampe, etc.",
      HEALTH_REPORT: "Rapport de l'état",
      LANGUAGE: "Langue",
      LAST_NAME: "Nom",
      MONTHLY_REPORTING: "Mensuel",
      NEW_RELEASE_FIRMWARE_UPDATE: "Mises à jour logiciel",
      NOTIFICATIONS: "Notifications",
      ORGAN_UPDATED_SUCCESSFULLY: "Organization updated successfully",
      PASSWORD: "Mot de passe",
      PERSONAL_DETAILS: "Détails personnels",
      PHONE_NUMBER: "Numéro de téléphone",
      VALID_FIRSTNAME_ERROR: "Saisir un prénom",
      POSTAL_CODE: "Code postal",
      PREFERENCES: "Préférences",
      PRIMARY_CONTACT: "Contact principal",
      PRIMARY_CONTACT_EMAIL_ADDRESS: "e-mail du principal contact",
      PRIMARY_CONTACT_PHONE_NUMBER: "Téléphone du principal contact",
      PRODUCT_UPDATE: "Mise à jour des produits",
      SAVE_CHANGES: "Sauvegarder les changements",
      SECONDARY_CONTACT: "Second contact ",
      SECONDARY_CONTACT_EMAIL_ADDRESS: "Adresse e-mail du contact secondaire",
      SECONDARY_CONTACT_PHONE_NUMBER: "e-mail du second contact",
      STATUS_UPDATE: "Changement d'état",
      STORAGE: "Mémorisation",
      TIME_ZONE: "Fuseau horaire",
      UNITS: "Unités",
      USER_NAME: "Nom d'utilisateur",
      USER_UPDATED_SUCCESSFULLY: "Utilisateur mis à jour avec succès",
      VERSION: "Version",
},
    CHANGE_PASSWORD: {
      EMAIL_CONFIRM_MESSAGE: "Un e-mail de confirmation a été envoyé à {{email}} ",
      PROBLEM_OCCURRED: "Un problème est survenu :",
      PASSWORD: "Changement du mot de passe",
      OLD_PASSWORD: "Ancien mot de passe",
      PASSWORD_CHANGED: "Mot de passe réinitialisé",
      PASSWORD_INFO: "Le mot de passe doit contenir au moins 8 caractères, Une majuscule, une minuscule, un chiffre et un caractère spécial ",
      ERROR_MESSAGR: "Vos informations ne correspondent pas à nos données. Veuillez saisir à nouveau vos informations pour réessayer.",
      SUCCESS_MESSAGE: "Mot de passe modifié avec succès. Reconnexion svp.",
},
    USER_INVITATION_MANAGEMENT: {
      YOU: "Vous",
      INVITATION_MESSAGE_UPDATE_COUNT: "{{updatedInvitationCount}} invitations étaient {{inviteStatus}} avec succès, {{rejectedInvitationCount}} invitations n’étaient pas {{inviteStatus}}.",
      SUCCESS_COUNT: "{{updatedInvitationCount}} invitations étaient {{inviteStatus}} avec succès, {{rejectedInvitationCount}} invitations n’étaient pas {{inviteStatus}}.",
      INVITE_ACCEPT: "Accepter",
      INVITE_ACCEPT_INVITATION: "Accepter l'invitation",
      INVITE_ACCEPTED: "accepté",
      INVITE_ACCEPT_INVITATION_DESCRIPTION_1: "Accepter cette invitation ajoutera les systèmes suivant à votre compte",
      ADMIN_APPROVE: "Approuver la demande",
      COMPANY: "Société",
      DECLINE_INVITATION: "Décliner l'invitation",
      TABLE_HEADER_DENIED_TITLE: "Déclinées  ({{count}})",
      INVITE_DENIED: "décliné",
      INVITE_DENY: "Refuser",
      ADMIN_DECLINE_INVITATION: "Décliner la demande",
      TABLE_HEADER_EXPIRED_AT: "Expirées",
      TABLE_HEADER_EXPIRED_TITLE: "Invitations expirées ({{count}})",
      INVITE_HISTORY: "Historique des invitations",
      INVITATION_SUCCESS_MESSAGE: "Invitation réussie !",
      JOIN: "Rejoindre",
      JOINALL: "Rejoindre tous",
      LEAVE_ORGANIZATION: "Quitter cette organisation",
      TABLE_HEADER_NAME: "Nom",
      INVITE_PENDING: "en attente",
      INVITE_REQUEST_ACCESS_DESCRIPTION_2: "Merci de demander l'accès à l'administrateur pour l'ajouter à votre compte",
      TABLE_HEADER_INVITE_DATE: "Reçu",
      REMOVE_USER: "Supprimer l'utilisateur",
      INVITE_REQUEST: "demande",
      INVITE_REQUEST_ACCESS: "Demande d'accès",
      REQUESTEDBY: "Demandé par",
      INSTRUCTIONS: "Veuillez accepter ou décliner l'invitation à ajouter des systèmes à votre compte. ",
      ADMIN_INSTRUCTIONS: "Approuver ou décliner la demande d'accès",
      SENTBY: "Envoyé par",
      TABLE_HEADER_INVITE_BY: "Envoyé par",
      TABLE_HEADER_DENIED_NO_DATA_FOUND: "Pas d'invitation déclinée pour le moment",
      TABLE_HEADER_EXPIRED_NO_DATA_FOUND: "Pas d'invitation expirée pour le moment",
      INVITE_REQUEST_ACCESS_DESCRIPTION_1: "L'invitation a expiré et ne peut être ajoutée à votre compte",
      TABLE_HEADER_TOTAL_ACCEPTED_COUNT: "Invitations Acceptées",
      SUCCESS_MESSAGE: "Invitation de l'utilisateur réussi {{count}}",
      ERROR_MESSAGE: "La mise à jour de l'invitation a échoué",
      VIEW_ALL_ACCESS_RIGHTS: "Voir tous les droits d'accès ",
      TITLE: "Bienvenue, {{username}}",
      SUBTITLE: "Vous avez été invité a rejoindre les organisations suivantes :",
      ADMIN_SUBTITLE: "Vous avez demandé l'accès à des invitations expirées",
},
    INACTIVITY: {
      LOGOUT: ", vous allez être déconnecté",
      INACTIVE_LOGOUT: "Déconnecté pour cause d’inactivité",
      TITLE: "Session expirée",
      WARNING : "Cette fenêtre est inactive depuis 15 minutes. Si aucune mesure n’est prise",
},
    USERLIST: {
      DELETECONFIRMATION: "Le compte {{email}} et toutes ses données seront définitivement supprimés. La suppression de ce compte n'affectera pas l'organisation, les équipements ou toute donnée associée.",
      ACCOUNT_DELETE: "compte effacé",
      ALL_LOCATION: "Tous les emplacements",
      DELETEACCOUNT: "Voulez-vous vraiment supprimer?",
      COL_EMAIL: "E-mail",
      COL_LOCATION: "Emplacement",
      COL_ROLE: "Rôle",
      COL_STATUS: "Etats",
      DELETE_SUCCESS: "Le compte utilisateur pour {{email}} a été effacé avec succès",
      COL_USERNAME: "Nom d'utilisateur",
},
    REPORTS: {
      ISSUES: "{{issueCount}} problème",
      MONTHLY_REPORT: "Rapport mensuel",
      NO_ISSUES: "Aucun problème sur cette période",
      REPORT_DOWNLOAD_FAILED: "Le téléchargement du rapport a échoué",
      REPORT_DOWNLOAD_SUCCESSFULLY: "Le rapport a été téléchargé avec succès",
},
    MAP: {
      MAP_COORDINATES: "{{latitude}} N, {{longitude}} E",
      BLOCKED: "BLOQUÉ",
      MAP_COORDINATES_LABEL: "Coordonnées",
       MAP_ADDRESS_DISPLAY: "Afficher l'adresse sur la carte",
      MAP_DRAG_MARKER: "Faites glisser sur la carte pour placer l'épingle",
      ERROR: "ERREUR",
      OFFLINE: "HORS-LIGNE",
      READY: "PRÊT",
      MAP_ENABLE_ON_COUNTRY: "Sélectionner un pays pour activer la carte",
      SYSTEM: "Système",
      SYSTEMS: "Système",
      WARNING: "Attention",
},
    ASSETS_DETAILS: {
      ADD_BUILDINGS: "+ Ajouter un Bâtiment",
      ADD_SYSTEMS: "+ Ajouter un Système",
      BUILDINGS: "Bâtiments",
      CUSTOMER_INFO_HEADER: "Details du client",
      DOWNLOAD_AS: "Télécharger comme …",
      GATEWAYS: "Gateways",
      LINK_TO_CUSTOMER_BUTTON: "Associé à un client",
      MAIN_CONTACT: "Contact principal",
      ADD_BUILDING_ASSET: "Sélectionner une organisation pour créer un bâtiment",
      SELECT_ORGANIZATION_LABEL: "Sélectionner une organisation",
      SHOW_FAVORITE_BUILDINGS: "Afficher uniquement les bâtiments favoris",
      SHOW_FAVORITE_GATEWAYS: "Afficher uniquement les gateways favorites",
      SHOW_FAVORITE_SYSTEMS: "Afficher uniquement les systèmes favoris",
      SLA_SUBTITLE: "SLA#",
      SYSTEMS: "Système",
      NO_CUSTOMER_TEXT: "Ce système n'est pas associé à un client",
},
    ATPLUS: {
      ATPLUS_DATETIME_TWENTYFOUR_HOUR: "24 heures",
      ATPLUS_ACTIVATION: "Activation",
      ADMIN: "Administrateur",
      ADMINVIEWER: "Administrateur & Utilisateur",
      ATPLUS_ADMIN_ACCESS: "Accès Admin",
      ATPLUS_ALERT_LEVEL: "Seuil d'alerte",
      ATPLUS_ALERTSETTING_HEADER: "Paramètres du niveau d’alerte",
      ATPLUS_SEARCHLUMINAIRE_WARNING: "Tous les luminaires se verront attribuer une nouvelle adresse et une nouvelle configuration sera créée. Si vous avez uniquement besoin de trouver de nouveaux luminaires ou remplacés, veuillez utiliser l'option 'Rechercher les nouveaux luminaires'.",
      ATPLUS_LOGIN_FAILURE: "Une erreur s'est produite lors de la modification des paramètres",
      ATPLUS_DATETIME_AUTO_DATE_TIME_HEADER: "Date et heure automatiques",
      ATPLUS_AUTOMATIC_RESET: "Mode repos automatique",
      ATPLUS_BATTERYFAILURE: "Défaillance de la batterie",
      ATPLUS_CHANGE_PASSWORD: "Changer le mot de passe du système",
      SYSTEMPASSWORD: "Changer le mot de passe du système",
      ATPLUS_INFO_TAG_CLOSED: "FERMÉ",
      ATPLUS_COMISSIONING: "Mise en service",
      ATPLUS_COMMANDS: "Commandes",
      ATPLUS_COMMSFAILURE: "Échec des communications",
      ATPLUS_DATETIME_CURRENT_TIME: "Date et heure actuelles",
      ATPLUS_DRYCONTACT_DEFAULT_SETTINGS: "Paramètres par défaut",
      ATPLUS_DETAILS: "Informations",
      ATPLUS_DRY_CONTACT: "Contact sec",
      ATPLUS_DRYCONTACT_INPUT: "entrée à contact sec",
      ATPLUS_ALERTSETTING_DRY_CONTACT_INPUT: "Entée contact sec activée",
      ATPLUS_DRYCONTACT_OUTPUT: "Sortie à contact sec",
      ATPLUS_ALERTSETTING_DRY_CONTACT_OUTPUT: "Sortie contact sec activée",
      ATPLUS_DRYCONTACT_HEADER: "Réglages du contact sec",
      ATPLUS_DRYCONTACT_STATUS: "État du contact sec",
      ATPLUS_DTSUCCESS: "Test d'autonomie effectué",
      ATPLUS_DTFAILEDTITLE: "Défaut test d'autonomie",
      ATPLUS_DTINPROGRESS: "Test d'autonomie en cours",
      ATPLUS_EMERGENCY: "Urgence",
      ATPLUS_EXPORT_CONFIG: "Exporter une configuration",
      ATPLUS_FAILED_LUMINAIRE: "Luminaires défaillants",
      ATPLUS_DRYCONTACT_FAILURE_THRESHOLD: "Défauts consécutifs au-delà du seuil",
      ATPLUS_FAILURETYPE: "Type de défaut",
      ATPLUS_FIRMWARE_UPDATE: "Mise à jour du logiciel",
      ATPLUS_FTSUCCESS: "Test fonctionnel effectué",
      ATPLUS_FTINPROGRESS: "Test fonctionnel en cours",
      CHANGEPASSWORDFOR: "Vous devez modifier votre mot de passe",
      ATPLUS_IMPORT_CONFIG: "Importer une configuration",
      ATPLUS_INEMERGENCYMODE: "En mode d’urgence",
      ATPLUS_INFAILURE: "En défaut",
      ATPLUS_INRESTMODE: "En mode repos",
      ATPLUS_INTESTMODE: "En mode test",
      ATPLUS_DRYCONTACT_STATUS_INPUT: "Entrée",
      GWCDATPLUSHEADER: "S'agit-il du système d'éclairage de sécurité que vous mettez en service ?",
      ATPLUS_LAMPFAILURE: "Défaillance de la lampe",
      ATPLUS_ALERTSETTING_LOCAL_MAIN_FAILURE: "Perte du secteur",
      ATPLUS_ALERTSETTING_LUMINAIRE_ALERTS: "Alertes de luminaire",
      ATPLUS_DTFAILEDDESCRIPTION: "Défaut luminaire",
      ATPLUS_LUMINAIRE: "Luminaires",
      ATPLUS_FOUNDLUMINAIRE: "Luminaires trouvés...",
      ATPLUS_FOUNDWERELUMINAIRE: "luminaires ont été trouvés.",
      ATPLUS_MAIN_OFFICE: "Contact principal",
      ATPLUS_DRYCONTACT_SUBHEADER: "Contact principal",
      ATPLUS_MANAGE_CONFIG: "Gérer les configurations",
      ATPLUS_LOGIN_MAX_ATTEMPTS_EXCEEDED: "Le nombre maximum de tentatives a été atteint",
      ATPLUS_MINIMIZE: "Réduire",
      ATPLUS_FOUNDNEWLUMINAIRE: "nouveaux luminaires ont été trouvés.",
      ATPLUS_DATETIME_SELECTED_TZ_PLACEHOLDER: "New York",
      ATPLUS_NO_LUMINAIRES_YET: "Pas encore de luminaires",
      ATPLUS_DRYCONTACT_RADIO_CLOSED: "Normalement Fermé - NF",
      ATPLUS_DRYCONTACT_RADIO_OPEN: "Normalement Ouvert - NO",
      ATPLUS_NEWLUMINAIRES_HEADLINE: "Recherchez uniquement les luminaires récemment ajoutés ou remplacés dans le « Bureau principal » pour leur attribuer automatiquement des adresses.",
      ATPLUS_OPENSNACK: "Ouvrir",
      ATPLUS_OPERATIONS: "Opérations",
      ATPLUS_ALERTSETTING_OTHER_ALERTS: "Autres alertes",
      ATPLUS_DRYCONTACT_STATUS_OUTPUT: "Sortie",
      ATPLUS_ALERTSETTING_CHOOSE_NUMBER: "Veuillez choisir entre 1 et 10 luminaires",
      ATPLUS_ADDITIONALPASSWORDINSTRUCTION: "Merci de créer les nouveaux mots de passe des Utilisateurs, Administrateurs et Services pour se connecter à la télécommande",
      ATPLUS_DONT_MOVE: "S’il vous plaît, ne vous éloignez pas",
      ATPLUS_RELOGINPASSWORDINSTRUCTION: "Merci de saisir le mot de pour vous reconnecter au",
      ATPLUS_RELOGIN_MESSAGE: "Veuillez saisir à nouveau le nouveau mot de passe pour vous reconnecter à la passerelle.",
      ATPLUS_ENTRY_MESSAGE: "Veuillez entrer vos identifiants pour changer les mots de passe",
      ATPLUS_ALERTSETTING_NOTIFY: "Veuillez utiliser les paramètres suivants pour personnaliser vos préférences d’alerte.",
      ATPLUS_REFRESHLUMINAIRE_TEST: "Rafraichir l'état des luminaires",
      ATPLUS_USE_RELIGHT: "Le rallumage n’est disponible que pour les luminaires actuellement en mode repos.",
      ATPLUS_DRYCONTACT_INPUT_RELIGHT: "Rallumer JOUR",
      ATPLUS_RELIGHT_SUCCESS: "L'allumage a été activé avec succès",
      ATPLUS_RESTMODE: "Se reposer",
      ATPLUS_REST_SUCCESS: "Le mode repos a été activé avec succès",
      ATPLUS_DURATION_TEST: "Démarrer le test d'autonomie",
      ATPLUS_FUNCTIONAL_TEST: "Démarrer le test fonctionnel",
      ATPLUS_SEARCHALL_LUMINAIRE: "Rechercher tous les luminaires",
      ATPLUS_SEARCHLUMINAIRE_HEADLINE: "Recherchez tous les luminaires « Bureau principal » pour leur attribuer automatiquement des adresses.Cette action modifiera définitivement l'adresse des luminaires précédemment installés et des nouveaux luminaires pour créer une nouvelle configuration. Cette action ne peut pas être annulée.",
      ATPLUS_SEARCH_ALL_LUMINAIRES: "Rechercher tous les luminaires",
      ATPLUS_SEARCH_NEWLUMINAIRES: "Rechercher les nouveaux luminaires",
      ATPLUS_SEARCHNEW_LUMINAIRE: "Rechercher les nouveaux luminaires",
      ATPLUS_SERVICE_ACCESS: "Accès Service",
      ATPLUS_SERVICEPARTNERPASSWORD: "Partenaire de maintenance",
      ATPLUS_CHANGEPASSWORD_INSTRUCTION: "Créer des mots de passe pour les utilisateurs avec",
      ATPLUS_ADDITIONALPASSWORD: "Créer des mots de passe supplémentaires",
      ATPLUS_DATE_TIME: "Régler la date et l’heure",
      ATPLUS_DATETIME_HEADER: "Régler la date et l’heure",
      ATPLUS_ALERTSETTING_LUMINAIRE_ALERTS_INFO: "Définissez le nombre de luminaires défaillants qu’un système doit atteindre pour qu’une alerte soit activée.",
      ATPLUS_SHOW_CONNECTED_LUMINAIRE: "Afficher les luminaires connectés",
      ATPLUS_SHOW_REGISTERED_LUMINAIRE: "Afficher les luminaires enregistrés",
      SIGN_IN: "Se connecter",
      SIGN_IN_AS: "Se connecter en tant que ",
      ATPLUS_RELIGHT: "Allumage générale",
      ATPLUS_REST: "Extinction générale- Repos",
      ATPLUS_STARTSEARCH: "Démarrer la recherche",
      ATPLUS_START_TEST: "Démarrer le test ",
      ATPLUS_VISIBILITY_TEST: "Démarrer Visibilité +",
      ATPLUS_DRYCONTACT_INPUT_VISIBILITY: "Démarrer Visibilité +",
      ATPLUS_STARTDT: "lancement de test d'autonomie",
      ATPLUS_STARTFT: "lancement de test fonctionnel",
      ATPLUS_STARTDTSNACK: "arrêter le test ",
      ATPLUS_SYNCH_TEST: "Synchronisation du calendrier des tests",
      TELECOMMANDPASSWORD: "Mot de passe de la télécommande",
      ATPLUS_TEST: "Test",
      ATPLUS_DRYCONTACT_INPUT_READONLY: "L’entrée à contact sec est en lecture seule, mais peut être configurée manuellement sur le micro commutateur.",
      ATPLUS_DRYCONTACT_STATUS_READONLY: "L’état est lecture seule, mais peut être configurée manuellement sur le micro commutateur.",
      ATPLUS_LUMINAIRE_INSTALL: "Cette action fera clignoter tous les luminaires de l' installation.",
      ATPLUS_SHOW_CONNECTED_LUMINAIRE_INFO: "Cette action fera clignoter tout luminaire correctement câblé et connecté à la télécommande.",
      ATPLUS_SHOW_REGISTERED_LUMINAIRE_INFO: "Cette action ne fera clignoter que les luminaires inclus dans la configuration.",
      ATPLUS_TOMANYLUMINAIREHEADER: "Ce système ne peut prendre en charge que 64 luminaires. Seuls les 64 premiers appareils détectés feront partie de votre configuration.Veuillez utiliser la fonction « Rechercher des luminaires enregistrés » dans la fenêtre de mise en service pour rechercher les luminaires non inclus dans votre configuration.",
      ATPLUS_TIMEREMAINS: "temps estimé,",
      ATPLUS_TIMEREMAINING: "temps restant",
      ATPLUS_DATETIME_TIMEZONE: "Fuseau horaire",
      ATPLUS_TIMEZONE_HEADER: "Fuseau horaire",
      ATPLUS_TOMANYLUMINAIRE: "Trop de luminaires ont été trouvés",
      ATPLUS_USE_REST: "Utilisez le mode repos pour les luminaires qui ne sont plus alimentatés par le secteur.",
      ATPLUS_FINISHSEARCH: "Utilisez ces résultats pour créer et partager une nouvelle configuration à l’aide de l’option « Exporter ».",
      ATPLUS_DATETIME_AUTO_DATE_TIME_SUBTEXT: "Utilisez la date, l’heure et le fuseau horaire de votre appareil mobile pour vous synchroniser automatiquement avec le système TLAT+. Désactivez cette fonction pour régler manuellement la date, l’heure et le fuseau horaire.",
      ATPLUS_VIEWLOGBOOK: "Voir les événements",
      VIEWER: "Utilisateur",
      ATPLUS_VIEWER_ACCESS: "Accès Utilisateur",
      ATPLUS_LOGIN_MAX_ATTEMPTS_EXCEEDED_BODY: "Vous pouvez réessayer de vous connecter à ceci dans ",
      ATPLUS_DT_DIALOG: "Vous risquez de perdre des données si vous vous éloignez trop de la télécommande AT+ pendant le test d'autonomie.Temps restant estimé, 01 :00 :00",
      ATPLUS_FT_DIALOG: "Ne pas s'éloigner de la télécommande durant le test fonctionnel Temps estimé : 00:02:00",
      ATPLUS_NO_LUMINAIRES_YET_DESCRIPTION: "Vous devrez utiliser la fonction « Rechercher tous les luminaires » et activer votre TLAT+ pour voir vos luminaires ici.",
      ATPLUS_RELOGIN_SUCCESS_MESSAGE: "Vos nouveaux mots de passe ont été enregistrés.",
},
    SYSTEM_DETAIL: {
      USERS_DATA: "5 administrateurs, 1 spectateur",
      ACTIVATE_CONNECTIONS: "Activer la connexion au cloud",
      ADD_ANOTHER_CELLULAR: "Ajouter une connexion cellulaire de secours",
      BOTH_PORTS_CANNOT_BE_ON_SAME_SUBNET: "Les 2 ports ne peuvent avoir le même masque Subnet",
      BUILDING: "Bâtiment",
      CONNECTION_TYPE: "Type de connexion",
      DATE_COMMISSIONED: "Date de mise en service",
      DURATION_TEST: "Test d'autonomie",
      ENABLED: "Activé",
      ENTER_VALID_GATEWAY_ADDRESS: "Entrer une adresse de gateway valide",
      ENTER_VALID_IP_ADDRESS: "Entrer une adresse IP valide",
      ENTER_VALID_SUBNET_MASK: "Entrer un subnet valide",
      FIRMWARE_VERSION: "Version du logiciel",
      FUNCTIONAL_TEST: "Test fonctionnel",
      GATEWAY: "Gateway",
      GATEWAY_ADDRESS: "Adresse de la gateway",
      GATEWAY_ADDRESS_REQUIRED: "Adresse de la gateway",
      SYSTEM_UNLINKED_GATEWAY: "a été déconnecté depuis",
      SYSTEM_LINKED_GATEWAY: "a été connecté à",
      HOST_NAME: "Nom de domaine",
      INTIALIZE_CONNECTION: "Initialiser la connexion",
      INTIALIZE_NETWORK: "Initialisation du réseau…",
      IP_ADDRESS: "Adresse IP",
      IP_ADDRESS_REQUIRED: "Adresse IP*",
      IP_GATEWAY_NOT_IN_SAME_SUBNET: "IP et gateway ne sont pas dans le même masque Subnet",
      ETHERNET_CONNECTED: "est maintenant connecté à Internet",
      SYSTEM_LINKING_TITLE: "Est-ce le système que vous souhaitez connecter ?",
      SYSTEM_UNLINKING_TITLE: "Est-ce le système que vous souhaitez déconnecter ?",
      SYSTEM_LINK_TITLE: "Connecter un système à ",
      LUMINAIRES: "Luminaires",
      MAC_ADDRESS: "Mac Address",
      NETWORK_INFORMATION: "Informations sur le réseau",
      ORGANIZATION: "Organisation",
      OTHER_STEPS: "Autres étapes, si nécessaire",
      PART_NUMBER: "Référence",
      SETPORT_SYSTEM_DETAILS_DESCRIPTION: "Veuillez terminer la configuration Ethernet.",
      SYSTEM_DETAILS_DESCRIPTION: "Veuillez trouver ces détails sur l'écran HMI de votre système d'éclairage de sécurité.",
      PORT_NUMBER: "Port Number*",
      PREV: "Précédent",
      REGISTER_ACTIVATE_GATEWAY: "Enregistrer et activer la gateway",
      SERIALNO_VALUE: "S3ri4l-Num63r",
      SERIAL_NUMBER: "Numéro de série",
      SETPORT_SYSTEM_LINK_TITLE: "Configurer le port pour connecter un système à",
      SLAVE_NUMBER: "Slave Number*",
      SUBNET_MASK: "Subnet Mask",
      SUBNET_MASK_REQUIRED: "Subnet Mask*",
      SYN_DATE_TIME: "Synchroniser la date et l'heure",
      SYSTEM: "Système",
      SYSTEM_NAME: "Nom du système",
      SYSTEM_TYPE: "Type de système",
      STATUS_DESCRIPTION: "Description longue. Vous pouvez décrire l'emplacement de l'appareil et à qui parler.",
      SYSTEM_UNLINK_TITLE: "Déconnecter le système depuis ",
      UPDATE_REQUIRED: "Mise à jour nécessaire",
      UPLOAD_DETAILS: "Télécharger les détails via Bluetooth",
      FIRMWARE_VERSION_VALUE: "v12.34-56",
      VERSION_AVAILABLE: "v123.457 disponible",
      PARTNO_VALUE: "xx xx xx xx",
      ETHERNET_SUBTITLE: "Vous pouvez désormais accéder à votre passerelle via le Web.",
},
    PASSWORD_REQUIREMENTS: {
      LENGTH: "8-16 caractères",
      LOWER: "Une minuscule",
      NUMBERS: "Un chiffre",
      SPECIAL: "un caractère spécial",
      UPPER: "Une majuscule",
},
    AUTH_TRANSLATION: {
      FORGOT_PASSWORD: {
        LINK_SENT_ALT: "Un lien pour réinitialiser votre mot de passe a été envoyé à  <b>{{email}}</b>.",
        LINK_SENT: "Un lien pour réinitialiser votre mot de passe a été envoyé à  <b>{{email}}</b>.",
        ERROR: "Impossible de réinitialiser votre mot de passe pour le moment.",
        INSTRUCTIONS_ALT: "Saisir l'email associé à votre compte",
        INSTRUCTIONS: "Saisir l'email associé à votre compte. Si ce courriel est déjà associé à un compte Eaton, vous recevrez une réponse sous  <b>one business day</b>.",
        RESET_CODE_ERROR: "Votre code de réinitialisation est erroné.",
},
      MESSAGES: {
        USER_PHONE_NUMBER_INVALID: "Le numéro de téléphone n'est pas valide",
        CONGRATS: "Félicitations!",
        CONTACT: "Contacter le support Eaton",
        EMAIL_SENT: "Courriel envoyé",
        ERROR: "Erreur!",
        FAILURE: "Défaut",
        INVALID_VERIFICATION_CODE: "Le code de vérification n'est pas valide ou sa longueur doit être inférieure à 6 caractères.",
        LOADING: "Chargement …",
        GENERIC_ERROR_MESSAGE: "Merci de contacter votre administrateur si la requête ne peut aboutir.",
        EMAIL_ENTRY_ERROR: "Entrer un courriel valide",
        USER_EMAIL_INVALID: "Entrer un courriel valide",
        REQUEST_ERROR: "Désolé, votre demande n'a pas abouti.",
        SUCCESS: "Succès",
        WELCOME: "Bienvenue !",
        LOGIN_MESSAGE: "Vous êtes connecté ",
},
      CHANGE_PASSWORD: {
        PROBLEM_OCCURRED: "Un problème est survenu :",
        CANCEL: "Annuler",
        PASSWORD: "Changement du mot de passe",
        CONFIRM_NEW_PASSWORD: "Confirmer nouveau mot de passe",
        OLD_PASSWORD: "Ancien mot de passe",
        PASSWORD_CHANGED: "Mot de passe réinitialisé",
        PASSWORD_INFO: "Le mot de passe doit contenir au moins 8 caractères, Une majuscule, une minuscule, un chiffre et un caractère spécial ",
        UPDATE: "Mettre à jour",
        EMAIL_CONFIRM_MESSAGE: "Un email de confirmation a été envoyé à  <b>{{email}}</b>.",
        ERROR_MESSAGE: "Vos informations ne correspondent pas à nos données. Veuillez saisir à nouveau vos informations pour réessayer.",
        SUCCESS_MESSAGE: "Mot de passe modifié avec succès. Reconnexion svp.",
},
      SELF_REGISTRATION: {
        VERIFY_EMAIL: {
          MESSAGE: "Un code de vérification a été envoyé sur votre boite email. Cliquer sur le lien ou saisir le code. Validité de 30mn.",
          VERIFICATION_CODE_PROMPT: "Code non-reçu ?",
          RESEND: "Renvoyer le courriel de vérification",
          VERIFICATION: "Code de vérification",
},
        ALREADY_REGISTERED_USER: "Utilisateur déjà enregistré",
        INSTRUCTIONS: "Pour créer un compte Eaton, Veuillez entrer les informations requises ci-dessous. Vous devrez vérifier votre adresse e-mail pour continuer.",
},
      REGISTRATION: {
        STEPS: {
          COMPLETE: "Compte créé !",
          ACCOUNT_DETAILS: "Détails de l'utilisateur",
          COMPANY_ADDRESS: "Adresse de la société",
          COMPANY_DETAILS: "Détails de la société",
          CREATE_ACCOUNT: "Créer un compte",
          PASSWORD: "Créer un mot de passe",
          LICENSE: "Accord de licence",
          VERIFY_EMAIL: "Vérifier le courriel",
},
        INSTRUCTIONS: {
          ACCOUNT_DETAILS: "Saisir vos informations pour finaliser la création de votre compte",
          COMPANY_DETAILS: "Ces données sont utilisées pour aider Eaton à vous fournir le support pour votre installation d'éclairage de sécurité. Si vous êtes à l'initiative de la création de l'organisation, merci d'entrer les informations relatives à votre société et non celle de votre client. ",
          COMPANY_ADDRESS: "Ces données sont utilisées pour aider Eaton à vous fournir le support pour votre installation d'éclairage de sécurité. Si vous êtes à l'initiative de la création de l'organisation, merci d'entrer les informations relatives à votre société et non celle de votre client. ",
},
        EULA: {
          AGREE_TERMS: "J'ai lu et j'accepte les termes et conditions",
          LOADING: "Téléchargement de l'accord de licence pour l'utilisateur",
},
        UNKNOWN_EMAIL: "Courriel inconnu",
        UNKNOWN_ORGANIZATION: "Organisation inconnue",
        FAILURE_MESSAGE: "Nous n'avons pas pu finaliser votre inscription. Appuyez sur Continuer ci-dessous pour terminer.",
        SUCCESS_MESSAGE_ALT: "Votre compte a été créé avec succès avec l'email <1>{{email}}</1>. Vous avez été ajouté à l'organisation  <3>{{organization}}</3>.Merci de continuer ci-dessous pour finir.  ",
        SUCCESS_MESSAGE: "Votre compte a été créé avec succès avec l'email <1>{{email}}</1>. Vous avez été ajouté à l'organisation  <3>{{organization}}</3>.Merci de cliquer sur terminer pour accéder à votre compte.",
        SUCCESS_EXISTING: "Votre compte a été créé avec succès, merci de vous reconnecter avec votre courriel et votre mot de passe  ",
        SUCCESS_SYNCED_MESSAGE: "Votre compte BSMS a été synchronisé avec succès avec les informations BrightLayer Cloud associées à l'e-mail <b>{{email}}</b>.",
},
      ACTIONS: {
        BACK: "Retour",
        CANCEL: "Annuler",
        CHANGE_LANGUAGE: "Modifier la langue",
        CLICK_BUTTON: "Cliquer sur le bouton",
        UPDATE_REDUX: "Cliquez sur le bouton pour mettre à jour la valeur de la boutique redux !",
        CONTINUE: "continuer",
        DONE: "Terminée",
        FINISH: "Terminer",
        GO_HOME: "Aller à la page d’accueil",
        GO_DASHBOARD: "Aller à la page d'accueil",
        GO_TEST: "Aller à la page de test",
        LOG_IN: "connexion",
        LOG_OUT: "Déconnexion",
        NEXT: "Suivant",
        OKAY: "OK",
        REMEMBER: "Se souvenir de moi",
        RESEND: "Renvoyer",
        SEND_AGAIN: "Renvoyer",
        SEND_EMAIL: "Envoyer l'email",
        CREATE_ACCOUNT: "S'enregistrer",
        UPDATE: "Mettre à jour",
},
      FORMS: {
        COMPANY_NAME: "Société",
        CONFIRM_PASSWORD: "Confirmer nouveau mot de passe",
        COUNTRY: "Pays",
        CURRENT_PASSWORD_ERROR: "Le mot de passe doit être valide",
        FIRST_NAME: "Prénom",
        RESET_PASSWORD: "Mot de passe oublié",
        LAST_NAME: "Nom",
        ORGANAIZATION_ADRESS: "Adresse de l'organisation - ligne 1*",
        PASSWORD: "Mot de passe",
        PASS_MATCH_ERROR: "Les mots de passe doivent être identiques",
        PHONE_NUMBER: "Numéro de téléphone",
        TOGGLE_PASSWORD_VISIBILITY: "Activer/désactiver la visibilité des mots de passe",
},
      LABELS: {
        CURRENT_PASSWORD: "Ancien mot de passe",
        EMAIL: "Courriel",
        FORGOT_PASSWORD: "Mot de passe oublié ?",
        NEED_ACCOUNT: "besoin d'un compte ?",
        NEW_PASSWORD: "Nouveau mot de passe",
        OPTIONAL: "Optionnel",
        PASSWORD: "Mot de passe",
        USERNAME: "Nom d'utilisateur",
},
      LOGIN: {
        INCORRECT_CREDENTIALS: "Courriel utilisateur ou mot de passe incorrect",
        GENERIC_ERROR: "Votre demande ne peut être traitée pour le moment.",
        INVALID_CREDENTIALS: "Le nom d'utilisateur ou le mot de passe est incorrect",
},
},
    REGISTRATION: {
      STEPS_ERROR_MESSAGE: {
        USER_PHONE_NUMBER_INVALID: "Le numéro de téléphone n'est pas valide",
        USER_ALREADY_EXIST_MESSAGE: "Utilisateur déjà enregistré",
        USER_INVALID_ENTRY: "Ces valeurs ne peuvent être nulles ou vides ",
        GENERIC_ERROR_MESSAGE: "Merci de contacter votre administrateur si la requête ne peut aboutir.",
        USER_EMAIL_INVALID: "Entrer un courriel valide",
        VERIFICATION_CODE_EXPIRED_MESSAGE: "Le code a expiré. Cliquer sur le bouton pour en recevoir un nouveau.",
},
      STEPS_MESSAGE: {
        CREATE_ACCOUNT_VERIFY_EMAIL: "Un code de vérification a été envoyé sur votre boite email. Cliquer sur le lien ou saisir le code. Validité de 30mn.",
        CREATE_ACCOUNT_USER_DETAILS: "Saisir vos informations pour finaliser la création de votre compte",
        CREATE_ACCOUNT_COMPANY_DETAILS: "Ces données sont utilisées pour aider Eaton à vous fournir le support pour votre installation d'éclairage de sécurité. Si vous êtes à l'initiative de la création de l'organisation, merci d'entrer les informations relatives à votre société et non celle de votre client. ",
        CREATE_ACCOUNT_ENTER_EMAIL: "Pour créer votre compte pour Building Safety Management, veuillez saisir les informations nécessaires. Une vérification de votre adresse email est nécessaire.  ",
},
      STEPS: {
        COMPLETE_OTHER_ADOPTER: "Compte confirmé !",
        COMPLETE: "Compte créé !",
        ACCOUNT_DETAILS: "Détails de l'utilisateur",
        COMPANY_ADDRESS: "Adresse de la société",
        COMPANY_DETAILS: "Détails de la société",
        CREATE_ACCOUNT: "Créer un compte",
        PASSWORD: "Créer un mot de passe",
        FORGOT_PASSWORD: "Mot de passe oublié",
        LICENSE: "Accord de licence",
        VERIFY_EMAIL: "Vérifier le courriel",
},
      EULA: {
        AGREE_TERMS: "J'ai lu et j'accepte les termes et conditions",
        LOADING: "Téléchargement de l'accord de licence pour l'utilisateur",
},
      FAILURE_MESSAGE: "Nous n'avons pas pu finaliser votre inscription. Appuyez sur Continuer ci-dessous pour continuer vers la suite Eaton Building Safety Management System ",
      SUCCESS_MESSAGE: "Votre compte a été créé avec succès avec l'email <2>{{email}}</2>.",
      SUCCESS_MESSAGE_OTHER_ADOPTER: "Votre compte a été synchronisé avec succès à Brightlayer avec l'email <2>{{email}}</2>.",
},
    VERIFY_EMAIL: {
      EMAIL_INFO: "Un code de vérification a été envoyé sur votre boite email. Cliquer sur le lien ou saisir le code. Validité de 30mn.",
},
    USER_INVITATION_REGISTRATION: {
      ACCEPT: "Accepter",
      DENY: "Refuser",
      INSTRUCTIONS: "Si cet e-mail est associé à un compte chez Eaton, vous recevrez une réponse dans un délai de <3> jour ouvrable</3>.<br/><br/>Pour des problèmes urgents liés au compte, veuillez appeler",
      TITLE: "Safety Management System - Invitation",
      INVITATION_VALIDATION_ERROR: "Votre code d'invitation est erroné",
},
    DASHBORAD: {
      ACCEPT_BUTTON: "Accepter l'invitation",
      ACCEPT_HEADER: "Accepter l'invitation ?",
      ACCEPT_TITLE: "Accepter l'invitation ajoutera l'élément suivant à votre compte :",
      ADD_NEW_ORGANIZATION: "Ajouter une nouvelle Organisation",
      ALL_BUILDINGS: "Tous les Bâtiments",
      ALL_GATEWAYS: "Toutes les Gateways",
      ALL_ORGANIZATIONS: "Toutes les Organisations",
      ALL_STATUSES: "Tous les états",
      ALL_TYPES: "Tous les types",
      BLOCKED: "Bloqué",
      BUILD_DETAILS: "Information du bâtiment ",
      BUILDINGS: "Bâtiments",
      DECLINE: "Décliner",
      DECLINED_INVITATION: "Invitations déclinées",
      MAP_DRAG_TITLE: "Faites glisser sur la carte pour placer l'épingle",
      REQUEST_ACCESS_SENT: "Demande d'accès envoyée à l'utilisateur par e-mail",
      ERROR: "Erreur",
      EVENT: "Evénement",
      EXPIRED_INVITATION: "Invitations expirées",
      EXPORT_REPORT: "Exporter le Rapport",
      GATEWAY: "Gateways",
      IN_ANOMALY: "En anomalie",
      INFORMATION: "Information",
      INVITATION_HISTORY: "Historique des invitations",
      ISSUES: "Problèmes",
      JOIN: "Rejoindre",
      LIST_VIEW: "Liste",
      MAP_VIEW: "Carte",
      MONTHLY_REPORT: "Rapport mensuel",
      NO_ISSUES_DURING_THIS_PERIOD: "Aucun problème sur cette période",
      ORG_DETAILS: "Organisation",
      PENDING_INVITATION: "Invitations en attente",
      CHOOSE_REPORT_YOU_WOULD_LIKE_TO_SAVE_SHARE: "Sélectionner le rapport à partager ou sauvegarder",
      PLEASE_MAKE_A_SELECTIONS: "Veuillez faire une sélection",
      REQUEST_SUBTITLE: "Veuillez demander l'accès à la personne qui a envoyé l'invitation pour ajouter cet élément à votre compte.",
      INVITATION_BODY: "Veuillez sélectionner les éléments à ajouter à votre compte ou décliner une invitation.",
      READY: "Prêt",
      NO_CONTENT: "Remplacer cette page par votre propre contenu",
      REPORTS: "Rapports",
      REQUEST_BUTTON: "Demande d'accès",
      REQUEST_HEADER: "Demande d'accès?",
      CHECK_INVITATIONS: "Recherche des invitations…",
      SELECT_ALL: "Sélectionner tout",
      SELECTIONS_CONFIRMED: "Sélection confirmée",
      SYSTEMS: "Système",
      EMPTY_DECLINED_INVITATION: "Il n’y a aucune invitation déclinée pour le moment",
      EMPTY_EXPIRED_INVITATION: "Il n’y a aucune invitation expirée pour le moment",
      EMPTY_REQUESTED_INVITATION: "Il n'y a aucune invitation demandée pour le moment",
      REQUEST_TITLE: "L'invitation a expiré et ne peut être ajoutée à votre compte",
      TOTAL_ACCEPTED_INVITATIONS: "Total des invitations acceptées",
      TOTAL_LUMINAIRES: "Nombre de luminaires",
      TOTAL_SYSTEMS: "Nombre de systèmes",
      UPDATING_PROFILE: "Mise à jour de votre compte…",
      VIEW_ALL: "Tout voir",
      VIEW_ALL_BUIDINGS: "Voir tous les Bâtiments",
      VIEW_ALL_SYSTEM: "Afficher tous les systèmes",
      WARNING: "Attention",
      INVITATION_SUB_HEADING: "Vous avez été invité a rejoindre les organisations suivantes :",
      ACCEPT_SUBTITLE: " ",
},
    COMMON: {
      ACCESS: "Accès",
      ACTIVE: "Actif",
      ALERT: "Alerte !",
      DOWNLOAD_PERMISSION: "Autoriser la sauvegarde du fichier localement sur votre appareil ",
      ASSETS: "Systèmes",
      BACK: "Retour",
      BLOCKED: "BLOQUÉ",
      CANCEL: "Annuler",
      CANNOT_REFRESH_TOKEN: "Impossible d'actualiser le token",
      CLOSE: "Fermer",
      SET_ETHERNET: "Configurer le port",
      CONFIRM: "Confirmer",
      CONNECT: "Connecter",
      CSV: "CSV",
      DASHBOARD: "Page d'accueil",
      DESCRIPTION: "Description",
      DONE: "Terminée",
      COMPANY: "Eaton",
      EDIT: "Editer",
      APP_NAME: "Building Safety Manager",
      EMPTY: "Vide",
      INTERVAL_SEVENDAYS: "Tous les 7 jours",
      INTERVAL_90DAYS: "Tous les 90 jours",
      EXCEL: "Excel File",
      EXIT: "Sortir",
      EXTEND: "Etendre",
      DOWNLOAD_SUCCESS: "Le fichier a été téléchargé avec succès",
      ANOMALY: "En anomalie",
      INACTIVE: "Inactif",
      INFO: "Info",
      INTERVAL: "Intervalle",
      LAST_RETREIVED: "Dernière récupération :",
      LAST_RETRIEVED: "Dernière tentative, il y a -- min ",
      LINK: "connecter",
      LOGBOOK: "Journal",
      NEXT: "Suivant",
      NODATA: "Aucune donnée",
      OFFLINE: "Hors ligne",
      OK: "Ok",
      PASSWORD: "Mot de passe",
      PDF: "PDF",
      READY: "PRÊT",
      RECOONECTTOGATWAY: "Se reconnecter à la gateway",
      RETURNTODASHBOARD: "Retour à la page d'accueil",
      NAVIGATION_HEADER: "Safety Management System",
      SAVE: "Sauvegarder",
      REFRESH_TOKEN_ERROR: "Le token de sécurité ne peut être nul ou vide",
      SESSION_ALERT_TITLE: "Session expirée",
      SET: "Configurer",
      SETTINGS: "Réglages",
      VIEW_ALL_SYSTEM: "Voir tout",
      SKIP: "Ignorer",
      STOP: "Arrêter",
      PERMISSION_ERROR: "La sauvegarde n'a pas été autorisée",
      SYNC: "Sync",
      SYSTEM: "Système",
      SESSION_ALERT_SUBTITLE: "Cette fenêtre est inactive depuis 15mn, vous allez être déconnecté dans {{extendTime}}",
      TRYAGAIN: "Réessayer",
      DOWNLOAD_FAIL: "Echec du téléchargement, recommencer",
      UNAUTHORIZED_ACCESS: "Accès non-autorisé",
      UNLINK: "Déconnecter",
      USERS: "Utilisateurs",
      WELCOME: "Bienvenue !",
      YES: "Oui",
      EMPTY_SYSTEM_TITLE: "Vous n'avez pas encore de systèmes dans cette organisation.",
      EMPTY_SYSTEM_SUBTITLE: "Vous pourrez superviser l'état de vos éclairages de sécurité une fois que vous aurez commencé à ajouter des systèmes à cette organisation.",
},
    TRANSFER_BUILDINGS: {
      PASSWORD: "Mot de passe du compte",
      HEADER: "Transférer définitivement tous les bâtiments {{buildingCount}}, y compris toutes les  X gateways et les {{systemCount}} systèmes rattachés, vers une autre organisation. Vous devez être administrateur des deux organisations pour effectuer l’action.",
      ACTION: "Merci de saisir votre mot de passe pour confirmer l'action ",
      TRANSFER_TO: "Transférer vers...",
},
    DRAWER: {
      ACCOUNTPREFERENCES: "Préférences d'utilisateur",
      RIGHTS_RESERVED: "All Rights Reserved",
      ASSETS: "Systèmes",
      COPYRIGHT: "Copyright © Eaton",
      DASHBOARD: "Page d'accueil",
      OVERVIEW: "Page d'accueil",
      DEVICES: "appareils",
      HELP: "Aide",
      HELPANDFEEDBACK: "Aide et commentaires",
      LEGAL: "Légal",
      LOCATIONS: "Emplacements",
      LOGBOOK: "Journal",
      MANAGELOCATION: "Gérer les emplacements et sites ",
      MANAGEUSER: "Gérer les utilisateurs",
      ORGSETTINGS: "Mes organisations",
      MYPROFILE: "Mon profil",
      EMERGENCY_LIGHT: "Powered by Brightlayer",
      REPORTS: "Rapports",
      ELDIGITAL: "Safety Management System",
      SELECT_ACCOUNT: "Sélectionner un compte",
      SETTINGS: "Réglages",
      TIMELINE: "Calendrier",
      USERS: "Utilisateurs",
},
    SETTINGS: {
      TITLE: "Préférences d'utilisateur",
      SUBTITLE: "niveau Administrateur",
},
    NAVIGATION_DRAWER: {
      ACCOUNT_PREFERENCES: "Préférences d'utilisateur",
      ALL_RIGHTS_RESERVED: "All Rights Reserved",
      CHANGE_PASSWORD: "Changement du mot de passe",
      COPYRIGHT: "Copyright",
      HELP_AND_FEEDBACK: "Aide et commentaires",
      LEGAL: "Légal",
      LOGOUT: "Déconnexion",
      PROJECT_SETTINGS: "Mes organisations",
      REPORTS: "Rapports",
      USERS: "Utilisateurs",
},
    USER_MENU: {
      ACCOUNT_SETTING: "Réglage du compte",
      CONTACT_US: "Nous contacter",
      LOG_OUT: "Déconnexion",
},
    USER_DETAIL: {
      ACTIVE: "ACTIF",
      COMPANY_ADRESS: "Adresse de la société",
      COMPANY_DETAIL: "Détails de la société",
      COMPANY_NAME: "Société",
      COMPANY_PHONE_NUMBER: "Numéro de téléphone de la société ",
      EMAIL_ID: "Courriel",
      INACTIVE: "Inactif",
      PERSONAL_ADRESS: "Fonction",
      LEAVE_ORGANISATION: "Quitter cette organisation",
      JOB_TITLE: "Adresse personnelle",
      PERSONAL_PHONE_NUMBER: "Numéro de téléphone personnel",
      PHONE_NUMBER: "Numéro de téléphone",
      REMOVE_USER: "Supprimer l'utilisateur",
      USER_NAME: "Nom d'utilisateur",
},
    FILTER: {
      ACTIVE_LOGS_ONLY: "Evénements actifs uniquement",
      ALL: "Tous",
      ALL_LOG_TYPES: "Tous les événements ",
      CUSTOM: "Personnaliser",
      PAST_TWELVE_MONTHS: "Les 12 derniers mois",
      PAST_SIX_MONTHS: "Les 6 derniers mois",
      SINCE_THE_BEGINNING_OF_YEAR: "Depuis le {{currentyear}}",
},
    LOGBOOK: {
      LOGBOOK_ACTIVELOG: "Evénements actifs uniquement",
      LOGBOOK_ALL: "Tous",
      LOGBOOK_ALLLOG: "Tous les événements ",
      CUSTOM_LOGBOOK_RANGE: "Plage de journal personnalisée",
      CUSTOM: "Personnaliser",
      END_DATE: "Date de fin",
      LOGBOOK_GROUPBY: "Grouper par :",
      PAST_TWELVE_MONTHS: "Les 12 derniers mois",
      PAST_SIX_MONTHS: "Les 6 derniers mois",
      LOGBOOK_SHOW: "Afficher :",
      SINCE_THE_BEGINNING_OF_YEAR: "Depuis le {{currentyear}}",
      START_DATE: "Date du début",
      LOGBOOK_TIME_RANGE: "Période :",
},
    BUILDING_DETAILS: {
      ADD_BUILDING: "Ajouter un Bâtiment à",
      ALL_SYSTEMS: "Tous les Systèmes",
      VIEW_ALL_GATEWAYS: "Voir toutes les gateways",
},
    ADD_ORGANIZATION: {
      ORG_ADD_DESCRIPTION: "Ajoutez des commentaires supplémentaires (facultatifs) pour aider votre équipe de maintenance.",
      ADDITIONAL_DESC: "Description supplémentaire",
      ORG_ADD_BLDG_BELONGS: "appartient.",
      CREATE_ORGANIZATION_TITLE: "Créer une Organisation",
      ORG_SUBTITLE: "Créer une organisation permet de regrouper des bâtiments et de coordonner la supervision des systèmes d'éclairage de sécurité",
      DETAILS_FOR: "Informations pour ",
      ORG_FOR: "pour",
      INVALID_CHARACTER: "Caractère non valide",
      ORG_NAMELENGTH: "Lettres, chiffres et tirets uniquement",
      ORG_ADD_BLDG: "Vous pouvez ajouter quelques détails supplémentaires pour terminer la configuration de ",
      ORGANIZATION_CREATED: "Adresse de l'organisation - ligne 2*",
      VALID_EMAIL_ERROR: "Entrer un courriel valide",
      ORG_PRIMARYCONTACT: "Contact principal",
      ORG_PROVIDED_DETAILS: "Fournissez des détails au cas où xxx serait nécessaire.",
      ORG_SECONDARYCONTACT: "Contact secondaire (facultatif)",
      ORG_SKIPBUTTON: "Ignorer pour l'instant",
      ORG_SKIPMESSAGE: "Ignorer et saisir des coordonnées pour chaque bâtiment plus tard.",
      ORG_SUCCESS: "Organisation créée avec succès",
      ALPHABET_INPUT: "Prend l’alphabet en entrée",
      ALPHANUMERIC_INPUT: "Prend l’alphanumérique en entrée",
      ALPHANUMERIC_DASH_UNDERSCORE_INPUT: "Prend l’alphanumérique, le tiret, le trait de soulignement en entrée",
      ORG_HELPTEXT: "Cela permet aux services d'Eaton ou votre mainteneur, une intervention rapide.",
      ORGANIZATION_DETAILS: "À qui les électriciens/mainteneur doivent-ils s'adresser pour",
},
    MANAGE_USERS: {
      ADVANCED_INVITE_USER_ORGANIZATION_SELECTION: "Les ajouter aux Organisations :",
      INVITE_USER_ORGANIZATION_LABEL: "Ajouter aux Organisations :",
      USER_ROLE_ADMIN: "Administrateur",
      ADVANCED_INVITE_ADMIN: "Administrateur :",
      ADVANCED_INVITE: "Invitation avancée",
      INVITE_USER_ADVANCE: "Avancé",
      INVITE_USER_AS: "Comme",
      ADVANCED_INVITE_USER_SELECTION: "Sélectionner un utilisateur existant ou nouveau : ",
      INVITE_USER_EMAIL_NAME: "E-mail*",
      INVITE_USER_EMAIL: "E-mail*",
      INVITE_USER_EMAIL_USER_NAME: "e-mail ou nom de l'utilisateur",
      INVITE_USER_SUBTITLE: "Accorder un accès à un utilisateur existant ou nouveau",
      ADVANCED_INVITE_SUBTITLE: "Accorder l’accès à des organisations, des bâtiments et des systèmes spécifiques.",
      ADVANCED_INVITE_ADMIN_DESCRIPTION: "Accorder l'accès sélectionné pour tous les systèmes.",
      ADVANCED_INVITE_LIMITED_DESCRIPTION: "Accorder des accès restreints afin qu'un utilisateur ne voit que les informations de base relatives à ce qui est essentiel à son rôle. Ceci est utile lorsque qu'une personne n'a besoin de surveiller qu'un seul système dans un bâtiment.",
      ADVANCED_INVITE_VIEWER_DESCRIPTION: "Accorder des droits restreints d'utilisateur au niveau choisi.",
      INVITATION_FAIL_HEADER: "Invitation expirée",
      INVITE_USER_AS_COLLEAGUE: "Inviter comme collègue",
      INVITE_USER: "Inviter un utilisateur",
      INVITE_SUCCESS_MESSAGE_SINGLE: "Invité {{email}} à {{organization}}.",
      INVITE_SUCCESS_MESSAGE_MULTIPLE: "Invité {{email}} à {{organizationCount}} organisations.",
      USER_ROLE_LIMITED: "Restreint",
      ADVANCED_INVITE_LIMITED: "Limité :",
      MY_DETAILS_HEADER: "Mes informations",
      OTHER_USER_LIST_HEADER: "Autres utilisateurs",
      ADVANCED_INVITE_PLEASE_ASSIGN_ROLES: "Merci d'assigner le niveau d'accès pour {{email}} aux organisations, bâtiments et systèmes. ",
      INVITATION_REQUEST_ACTION: "Demander une nouvelle invitation",
      INVITE_USER_ORGANIZATION_SELECT_ALL: "Sélectionner tout",
      INVITE_USER_SEND_INVITE: "Inviter",
      INVITATION_FAIL_BODY: "Ce lien a expiré. Veuillez contacter l'administrateur de ce système pour recevoir une nouvelle invitation par e-mail.",
      USERS_IN_HEADER: "Utilisateurs dans ",
      USER_ROLE_VIEWER: "Utilisateur",
      ADVANCED_INVITE_VIEWER: "Utilisateur:",
},
    ORGANIZATION_DETAILS: {
      ADDRESS: "Adresse",
      BUILDING_DETAILS: "Information du bâtiment ",
      DELETE_FOREVER: "Supprimer définitivement",
      EDIT_BUILDING: "Editer le bâtiment",
      EDIT_ORGANIZATION: "Editer l'organisation",
      EMAIL_ADDRESS: "Courriel",
      MERGE_ASSETS: "Transférer les systèmes dans un autre bâtiment",
      ORGANIZATION: "Organisation",
      ORGANIZATION_DETAILS: "Détails de l'organisation",
      PHONE_NUMBER: "Numéro de téléphone",
      PRIMARY_CONTACT: "Contact principal",
      PRIMARY_CONTACT_EMAIL_ADDRESS: "e-mail du principal contact",
      PRIMARY_CONTACT_PHONE_NUMBER: "Téléphone du principal contact",
      SECONDARY_CONTACT: "Second contact ",
      SECONDARY_CONTACT_EMAIL_ADDRESS: "Adresse e-mail du contact secondaire",
      SECONDARY_CONTACT_PHONE_NUMBER: "e-mail du second contact",
      TRANSFER_BUILDINGS: "Transférer les bâtiments dans une autre organisation ",
      USERS: "Utilisateurs",
},
    COMMON_INPUT: {
      COMMON_ADDRESS: "Adresse",
      COMMON_ADDRESSLINE2: "Ligne d’adresse 2 (facultatif)",
      COMMON_BUILDINGNAME: "Nom du bâtiment",
      COMMON_CITY: "Ville",
      COMMON_COUNTRY: "Pays",
      COMMON_OPTIONALDESC: "Description (Optionnelle)",
      COMMON_EMAIL_ADDRESS: "Courriel",
      COMMON_NAME: "Nom de l'organisation",
      COMMON_PHONENUMBER: "Numéro de téléphone",
      VALID_PHONE_ERROR: "Veuillez entrer un numéro de téléphone valide",
      VALID_POSTAL_CODE: "Veuillez entrer un code postal valide",
      COMMON_POSTALCODE: "Code postal",
},
    MY_ORGANIZATION_DETAILS: {
      ADDRESS_Line1: "Adresse - Ligne 1",
      ADMINS: "Administrateurs",
      DELETE_FOREVER: "Supprimer définitivement",
      DESCRIPTION: "Description (Optionnelle)",
      EDIT_BUILDING: "Editer le bâtiment",
      EDIT_ORGANIZATION: "Editer l'organisation",
      NAME_DESCRIPTION: "Nom et description",
      SAVE_ORGANIZATION: "Enregistrer l'organisation",
      TRANSFER_BULDING_ANOTHER_ORGANIZATION: "Transférer les bâtiments dans une autre organisation ",
      VIEWER: "Utilisateurs",
},
    INVITE_USER: {
      ADMIN: "Administrateur",
      ADVANCE_INVITATION: "Invitation avancée",
      ADVANCE_INVITE: "Avancé…",
      AS_ADMIN: "Comme administrateur",
      AS_VIEWER: "Comme utilisateur",
      ENTER_USER_EMAIL: "Adresse e-mail ou nom d’utilisateur",
      GRANT_ACCESS_HEADER: "Accordez l’accès aux utilisateurs nouveaux ou existants.",
      SEND_INVITE_FAILURE: "Echec de l'envoi de l'invitation",
      SEND_INVITE_SUCCESS: "Invitation envoyée avec succès",
      INVITATION_ACCEPTED: "Invitation acceptée avec succès",
      INVITATION_DENIED: "Invitation déclinée avec succès",
      INVITE_AS_COLLEGUE: "Inviter comme collègue",
      INVITE_USER: "Inviter un utilisateur",
      RECEIVED: "Reçu",
      SEND_INVITE: "Inviter",
      SENT_BY: "Envoyé par",
      VIEWER: "Utilisateur",
      ERROR_MESSAGE: "Votre demande a échouée",
},
    DISCLAMIER: {
      OVERVIEW: "Le nombre d'alarmes représente toutes les alarmes actives",
      TIMELINE: "Les alarmes affichées représentent toutes les alarmes des 90 derniers jours",
},
    SEARCH: {
      ALL_BUILDINGS: "Tous les Bâtiments",
      ALL_GATEWAYS: "Toutes les Gateways",
      ALL_ORGANIZATIONS: "Toutes les Organisations",
      ALL_STATUSES: "Tous les états",
      ALL_SYSTEMS: "Tous les Systèmes",
      ALL_TYPES: "Tous les types",
},
    CUSTOMER_DETAILS: {
      ALL_USERS: "Tous les utilisateurs",
      BACK_UP_PHONE: "Sauvegarde du téléphone",
      BUYER_HEADER: "Acheteur",
      BUYER: "Acheteur",
      CONTACT_TYPE: "Type de contact",
      CONTACT_PERSON_ON_SITE: "Contact local",
      CUSTOMER_HEADER_SUBTITLE: "Client",
      CUSTOMER_NAME: "Nom du client",
      CUSTOMER_DETAILS_HEADER: "Nom et adresse du client",
      CUSTOMER_NUMBER: "Numéro client",
      DELETE_CUSTOMER: "Supprimer le client",
      LUMINARY_INFO: "Est-ce que le contrat inclus les luminaires ?",
      EDIT_CUSTOMER_INFO: "Modifier les informations du client",
      CREATE_CUSTOMER_HEADER: "Modifier les informations du client",
      IMAGES: "Images",
      SLA_DETAILS_TEXT: "Luminaires inclus",
      CALENDAR_HELPER_TEXT: "mm/jj/aaaa",
      MOBILE_NUMBER: "Téléphone mobile",
      LUMINARY_MONITORING_NO: "Non",
      CUSTOMER_NUMBER_HELPER_TEXT: "Chiffres uniquement",
      OFFICE_NUMBER: "Téléphone du bureau",
      IMAGE_REQUIREMENTS: "PNG, JPG (100x100px), 2 MB Max.",
      PHONE_NUMBERS_HEADER: "Téléphone primaire et secondaire",
      PRIMARY_CONTACT_INFO: "Informations du principal contact",
      PROJECT_LEAD: "Chef de projet",
      REMOVE_IMAGE: "Effacer l'image",
      SECONDARY_CONTACT_INFO: "Informations du second contact",
      FILE_SIZE_ERROR: "le fichier sélectionné excède maxFileSize",
      SLA_CUSTOMER_NUMBER: "Numéro du contrat",
      SLA_DETAILS_HEADER: "Détail du contrat",
      SLA_EXPIRATION_DATE: "Expiration du contrat",
      TECH_LEAD_HEADER: "Technicien",
      TECHNICAL_PERSON: "responsable technique",
      UPLOAD_AVATAR_LOGO: "Charger le logo",
      LUMINARY_MONITORING_YES: "Oui",
},
    USER_MANAGEMENT: {
      ALL_USERS: "Tous les utilisateurs",
      INVITE_USER: "Inviter un utilisateur",
      YOUR_DETAILS: "Vos informations",
},
    MY_ORGANIZATION: {
      ALPHABET: "Alphabets",
      CITY: "Ville",
      NUMBER_SYSTEM: "Nombre de Systèmes",
},
    FIRMWARE_UPDATE: {
      FIRMWARE_UPDATE_ALERT_TITILE: "Êtes-vous sûr de vouloir quitter ?",
      CURRENT_VERSION: "Version actuelle",
      FIRMWARE_UPDATE_FAILED_TITLE: "La mise à jour a échoué",
      FIRMWARE_PROGRESS_MSG: "Mise à jour du logiciel en cours…",
      FIRMWARE_UPDATE_ALERT_SUB_TITILE: "La mise à jour du logiciel est en cours. Voulez vous aller à la page d'accueil",
      FIRMWARE_UPDATE_FAILED: "La mise à jour a échoué. Merci de recommencer",
      FIRMWARE_VERSION_UPDATED: "La mise à jour du firmware a réussi",
      LATEST_VERSION: "Version la plus récente",
      LOST_BLUETOOTH_CONNECTION: "Perte de la connexion Bluetooth",
      ENTRY_PASSWORD: "Merci de saisir le mot de passe du système pour ",
      RELEASED_ON: "Sortie le",
      SELECT_FIRMWARE_VERSION: "Sélectionner une version du logiciel",
      SELECT_A_VERSION: "Sélectionner une version",
      UPDATE_TO_LATEST: "Mettre à jour pour la version la plus récente",
      UPLOAD_FILE: "Télécharger le fichier",
      FIRMWARE_UPDATE_TITILE: "Nous recommandons une mise à jour du système",
      FIRMWARE_UPDATEPOPUP_MSG: "Vous n’êtes plus connecté à ",
},
    ASSETS: {
      ASSETS_DETAILS: "Détails des Systèmes",
},
    SYSTEM_TYPE: {
      ATPLUS: "AT+",
      CGLINE: "CGLine+",
      DGS: "DualGuard-S",
      LPSMART: "DualSmart-S",
      LPSTAR: "LPStar",
      WIRELESS: "Wireless",
      ZBS: "ZB-S",
},
    DEVICE_TYPE_VALUE: {
      AT: "AT+",
      ATS: "AT-S+",
      CGLINE: "CGLINE",
      DGS: "DualGuard-S",
      LPSMART: "DualSmart-S",
      LPSTAR: "LP-STAR",
      WIRELESS: "WIRELESS",
      ZBS: "ZB-S",
},
    CONTROLLER_TYPE_VALUE: {
      AT: "AT+",
      CGLINE_PLUS: "CGLine+",
      DGS: "DualGuard-S",
      LP_SMART: "DUALSMART-S",
      LP_STAR: "LP-STAR",
      WIRELESS: "WIRELESS",
      ZBS: "ZB-S",
},
    LUMINARY_DETAILS: {
      ATSD: "ATSD #",
      CIRCUIT: "Circuit",
      LUMINAIRE_ADDRESS: "Adresse du luminaire",
      MODEL: "Modèle",
      TYPE: "Type",
},
    ACTIONS: {
      BACK: "Retour",
      CANCEL: "Annuler",
      CHANGE_LANGUAGE: "Modifier la langue",
      CLICK_BUTTON: "Cliquer sur le bouton",
      UPDATE_REDUX: "Cliquez sur le bouton pour mettre à jour la valeur de la boutique redux !",
      CONFIRM: "Confirmer",
      CONTINUE: "continuer",
      DELETE: "Supprimer",
      RESEND_VERIFICATION_EMAIL_LABEL: "Courriel non reçu ?",
      DISABLE: "Désactiver",
      DONE: "Terminée",
      DOWNLOAD_DATA: "Daten herunterladen",
      EDIT: "Editer",
      ENABLE: "Activer",
      EXTEND: "Etendre",
      FINISH: "Terminer",
      GO_HOME: "Aller à la page d’accueil",
      GO_TO_DASHBOARD: "Aller à la page d'accueil",
      GO_TEST: "Aller à la page de test",
      LOG_IN: "connexion",
      LOG_OUT: "Déconnexion",
      NEXT: "Suivant",
      OKAY: "Ok",
      QUIT: "Quitter",
      REMEMBER: "Se souvenir de moi",
      RETRY: "Recommencer",
      SAVE: "Sauvegarder",
      SEND: "Envoyer",
      RESEND: "Renvoyer",
      RESEND_VERIFICATION_EMAIL: "Renvoyer",
      SUBMIT: "Envoyer",
      SUBMITCHANGES: "Soumettre des modifications",
      TRANSFER: "Transférer",
      UPDATE: "Mettre à jour",
},
    NETWORK: {
      BAD_REQUEST_MESSAGE: "Mauvaise demande",
      INTERNAL_SERVER_ERROR_MESSAGE: "Erreur interne du serveur",
      NO_DATA_FOUND: "Donnée introuvable",
      REQUEST_NOT_FOUND_MESSAGE: "Introuvable",
      ERROR_INTERNET_CONNECTION: "Il semble que vous n'ayez plus de connexion Internet et veuillez réessayer.",
      SERVICE_UNAVAILABLE: "Service introuvable",
      ERROR_NO_RESPONSE: "Une erreur s'est produite",
      ERROR_MESSAGE_SENDING_REQUEST: "Désolé, votre demande n'a pas abouti.",
      ERROR_INVALID_REQUEST: "Désolé, votre demande n'est pas valide.",
      NETWORK_ALERT_SUBTITILE: "page d'accueil indisponible car vous n'êtes plus connecté à Internet",
      ERROR_NOT_FOUND: "La ressource est introuvable.",
      ERROR_ALERT_TITLTE: "connexion à la page d'accueil impossible",
      UNAUTHORISED_REQUEST_MESSAGE: "Non autorisé : l'autorisation a été refusée pour cette demande",
      ERROR_RESTRICTED_USER_ACCESS: "L'utilisateur n'a pas accès à cette ressource.",
},
    CONNECTION_TYPE_VALUE: {
      BLE: "BLE",
      BLUETOOTH: "Bluetooth",
      CELLULAR: "Cellulaire",
      ETHERNET: "Ethernet",
      MODBUS: "Modbus",
      MQTT: "MQTT",
      RS485: "RS485",
      WIFI: "Wi-Fi",
      WIREPAS: "Wirepas",
},
    STATUS: {
      STATUS_BLOCK: "Bloqué",
      BLOCKED: "Bloqué",
      STATUS_ERROR: "Erreur",
      ERROR: "Erreur",
      EVENT: "Evénement",
      DT: "in DT",
      STATUS_DT: "Test en cours",
      INTEST: "Test en cours",
      STATUS_OFFLINE: "Hors ligne",
      STATUS_READY: "Prêt",
      READY: "Prêt",
      TOTAL_LUMINAIRES: "Nombre de luminaires",
      TOTAL_SYSTEM: "Nombre de systèmes",
      STATUS_WARNING: "Attention",
      WARNING: "Attention",
},
    UNSUPPORTED_BROWSER: {
      NOT_SUPPORTED: "Navigateur non pris en charge",
      INCOMPATIBLE_BROWSER: "Cette application Web n'est pas compatible avec votre navigateur actuel.",
      RECOMMENDED_BROWSER: "Nous vous recommandons de mettre à jour votre navigateur internet pour utiliser cet outil ",
},
    ENTITY_TYPE: {
      BUILDING: "Bâtiment",
      DEVICE: "Elément",
      DEVICE_TYPE: "Type d'élément",
      GATEWAY: "Gateway",
      LUMINARY: "Luminaire",
      MASTER_ROLE: "Rôle principal",
      ORGANIZATION: "Organisation",
      ORGANIZATION_STATISTIC_DETAIL: "Organization Statistical Detail",
      SYSTEM: "Système",
      USER: "Utilisateur",
},
    LOGSOURSE_DEVICE_TYPE: {
      BUILDING: "Bâtiment",
      DEVICE: "Elément",
      DEVICETYPE: "Type d'équipement",
      GATEWAY: "Gateway",
      LUMINARY: "Luminaire",
      MASTERROLE: "Rôle principal",
      ORGANIZATION: "Organisation",
      ORGANIZATIONSTATISTICALDETAIL: "OrganizationStatisticalDetail",
      SYSTEM: "Système",
      USER: "Utilisateur",
},
    TOAST_MSG: {
      TOAST_SUCCESS_BUILDING_CREATE: "Bâtiment créé avec succès",
      BUILDING_LIST_API_FAILURE: "Echec de l'entrée du bâtiment",
      BUILDING_TRANSFER_FAILURE: "Erreur lors du transfert du bâtiment",
      BUILDING_TRANSFER_SUCCESS: "Bâtiment transféré avec succès",
      TOAST_SUCCESS_BUILDING_UPDATE: "Bâtiment mis à jour avec succès",
      CUSTOMER_DETAIL_SUCCESS: "Données client extraite avec succès ",
      TOAST_ERROR_GET_CUSTOMER_INFO: "Echec de la récupération des données client",
      DEVICE_LIST_API_FAILURE: "Echec de l'entrée du système",
      ACKNOWLEDGE_LOG_SUCCESS: "Evénement acquitté avec succès",
      TOAST_ERROR_BUILDING_UPDATE: "erreur de la mise à jour du bâtiment",
      UPDATE_LOG_COMMENTS_SUCCESS: "Commentaire mis à jour avec succès",
      ACKNOWLEDGE_LOG_API_FAILURE: "Echec de l'acquittement du défaut ",
      TOAST_SUCCESS_ORG_CREATE: "Organisation créée avec succès",
      TOAST_ERROR_ORG_UPDATE: "erreur de la mise à jour de l'organisation",
      TOAST_SUCCESS_ORG_UPDATE: "Organisation mise à jour avec succès",
      TOAST_ERROR_MESSAGE: "Merci de saisir tous les informations correctement avant d'envoyer",
      UPDATE_LOG_COMMENTS_API_FAILURE: "erreur de la mise à jour du commentaire",
      TOAST_ERROR_UPDATE_USER_DETAILS: "erreur de la mise à jour de l'utilisateur",
      TOAST_SUCCESS_UPDATE_USER_DETAILS: "Utilisateur mis à jour avec succès",
},
    CHANGE_PASSWORD_SCREEN: {
      CHANGE_PASSWORD: "Changement du mot de passe",
      CONFIRM_PASSWORD: "Confirmer nouveau mot de passe",
      NEW_PASSWORD: "Nouveau mot de passe",
},
    MESSAGES: {
      CONGRATS: "Félicitations!",
      CONTACT: "Contacter le support Eaton",
      EMAIL_SENT: "Courriel envoyé",
      ERROR: "Erreur!",
      FAILURE: "Défaut",
      LOADING: "Chargement …",
      EMAIL_ENTRY_ERROR: "Entrer un courriel valide",
      SUCCESS: "Succès",
      WELCOME: "Bienvenue !",
      LOGIN_MESSAGE: "Vous êtes connecté",
},
    LANDING_SCREEN: {
      CONNECT_NOW: "Connecter maintenant",
      CONNECT_TO_SYSTEM: "Connecter aux Systèmes",
      CONTINUE_TO_DASHBOARD: "Continuer vers la page d'accueil",
      DASHBOARD_UNAVAILABLE: "page d'accueil indisponible",
      NO_INTERNET_CONNECTION: "Pas de connexion Internet",
      VIEW_DASHBOARD: "Afficher le tableau de bord",
      IN_PROGRESS: "En cours…",
},
    USERDETAILS: {
      RESET_PASSWORD_ERROR: "Impossible de réinitialiser votre mot de passe pour le moment.",
      RESET_PASSWORD_SUCCESS: "email de réinitialisation du compte envoyé avec succès ",
      EDIT_SUCCESS: "Utilisateur mis à jour avec succès",
},
    LABELS: {
      CSV: "CSV",
      EMAIL: "Courriel",
      EXCEL: "Excel File",
      FORGOT_PASSWORD: "Mot de passe oublié ?",
      GROUP_BY: "Grouper par :",
      ITEMS_PER_PAGE: "Entrées par page:",
      LOGGING_IN: "Se connecter à",
      NEED_ACCOUNT: "besoin d'un compte ?",
      NEW_PASSWORD: "Nouveau mot de passe",
      PASSWORD: "Mot de passe",
      PDF: "PDF",
      SHOW: "Voir:",
      SIGN_UP: "S'enregistrer",
      TIME_RANGE: "Période :",
      TO_BE_IMPLEMENTED: "À mettre en œuvre",
      VIEW_ALL_EVENTS: "Voir tous les {{count}} événements",
},
    TIMELINE_EXPORT: {
      DOWNLOAD_TITLE: "Télécharger le calendrier des données",
      DOWNLOAD_SUBTITLE_SITE: "Sélectionner la période de l'historique des alarmes que vous souhaitez exporter.",
      DOWNLOAD_SUBTITLE_TIMELINE: "Sélectionner l'emplacement et la période de l'historique des alarmes que vous souhaitez exporter.",
},
    LEGAL: {
      EULA: "Contrat de licence de l'utilisateur final",
      TITLE: "Légal",
      OPENSOURCELICENSES: "Open Source Licences",
      TERMSANDCONDITIONS: "Termes et Conditions",
},
    EVENT_TIMELINE: {
      ERROR_LOADING_MORE_EVENTS: "Echec de téléchargement d'événements supplémentaires",
      LOAD_MORE_EVENTS: "Télécharger {{count}} d'événements supplémentaires",
},
    ADDLOCATION: {
      ASTERIKSINFO: "Les champs marqués (*) sont des champs obligatoires {{entity}}.",
      ADDLOCATIONINFORMATION: "Information sur l'emplacement",
},
    HEADER: {
      FORGOT_PASSWORD: "Mot de passe oublié",
},
    LOGIN: {
      INVALID_CREDENTIALS: "Le nom d'utilisateur ou le mot de passe est incorrect",
      GENERIC_ERROR: "Votre demande ne peut être traitée pour le moment.",
},
    NAVIGATION_TABS: {
      LOGBOOK: "Journal",
      STATUS: "STATUT",
},
    ASSETS_SCREEN: {
      NOBUILDINGFOUND: "Aucun bâtiment trouvé",
      NOGATEWAYFOUND: "Aucune gateway trouvée",
      NOORGANIZATIONFOUND: "Aucune organisation trouvée",
      NOREPORTFOUND: "Aucun rapport trouvé",
      NOSYSTEMS: "Aucun système trouvé",
      OTHERS: "Autres",
      STARRED: "Favoris",
},
    EULA: {
      OTHER_LANGUAGE: "Autres langues",
},
    LOCATIONS: {
      SITES: "Sites",
},
    COUNTER: "La valeur est : {{count}}",
    RESPONSE: {
      USER_REGISTERED: "L'utilisateur peut être enregistré",
},
    PASSWORD_RESET: {
      SUCCESS_MESSAGE: "Mot de passe modifié avec succès",
      FAILURE_MESSAGE: "Réinitialisation du mot de passe impossible. Veuillez réessayer ultérieurement",
},
},
};
 export default resources;
