// eslint-disable-next-line
import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Selectors } from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Thunks } from '../../actions';
import { EmptyState } from '@brightlayer-ui/react-components';
import * as PXBColors from '@brightlayer-ui/colors';

type Props = {
    closeClick: Function;
    openDialogFlag: boolean;
    username: string;
    platformName: string;
    userType: number;
    userId: string;
};
export const RemoveServiceUserDialog: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const userManagementState = useSelector(Selectors.Usermanagement.usermanagement);
    const [loader, setLoader] = useState(false);

    function closeRemoveServiceUserDialog(): void {
        props.closeClick({});
    }
    const onCancel = () => {
        closeRemoveServiceUserDialog();
    }

    const onConfirmRemoveServiceUser = (): void => {
        setLoader(true);
        dispatch(Thunks.UserManagement.removeServiceUser(props.userId, props.userType));
    }

    React.useEffect(() => {
        if (!userManagementState.removeServiceUserLoading && userManagementState.removeServiceUserSuccess) {
            closeRemoveServiceUserDialog();
        }
        else if (!userManagementState.removeServiceUserLoading && userManagementState.removeServiceUserFail) {
            closeRemoveServiceUserDialog();
        }

    }, [userManagementState.removeServiceUserLoading, userManagementState.removeServiceUserSuccess, userManagementState.removeServiceUserFail]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Dialog data-testid='removeServiceUserDialog' id="removeService-user-dialog" maxWidth="xs" open={props.openDialogFlag} onClose={closeRemoveServiceUserDialog}>
                {
                    loader && <EmptyState style={{ backgroundColor: PXBColors.gray[100], minHeight: 250, minWidth: 259 }}
                        icon={<CircularProgress id="progress-spinner" />} title={t('USERS.LOADER_HELPER_TEXT')} placeholder={undefined} />
                }
                {!loader && <>
                    <DialogTitle data-testid='dialogHeader'>{t('USER_INVITATION_MANAGEMENT.REMOVE_SERVICE_USER_TITLE', { replace: { userName: props.username, companyName: props.platformName } })}</DialogTitle>
                    <DialogContent>
                        <DialogContentText data-testid='dialog-subheader'>{t('USER_INVITATION_MANAGEMENT.INVITATION_TEXT')}</DialogContentText>
                    </DialogContent>
                    <Button
                        style={{ alignSelf: 'end', marginTop: 10, marginBottom: 10, marginRight: 10 }}
                        id="removeServiceUserAccount"
                        data-testid="button-removeServiceUser-account"
                        variant="text"
                        color="error"
                        onClick={onConfirmRemoveServiceUser}
                    >
                        {t('USER_INVITATION_MANAGEMENT.REMOVE_USER')}
                    </Button>

                    <Button style={{ alignSelf: 'end', marginBottom: 10, marginRight: 10 }} id="btnCancel" data-testid="btnCancel" variant="text" color="primary" onClick={(): void => onCancel()}>
                        {t('ACTIONS.CANCEL')}
                    </Button>
                </>}



            </Dialog>

        </>
    );
};

