// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { GenericToolbar } from '../../components/GenericToolbar';
import { pushAppRoute } from '../../data/domain/route-assets';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import * as PXBColors from '@brightlayer-ui/colors';
import { AssetsBuildings } from './AssetsBuildings';
import { AssetsGateways } from './AssetsGateways';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AssetsSystems } from './AssetsSystems';
import { DeviceAssetData } from '../../types/device';
import { pushAppRouteOverview } from '../../data/domain/route-overview';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { ItemList } from '../../types/logbook-type';
import { AssetsSLA } from './AssetsSLA';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider/component';
import { USER_ROLE_TYPE } from '../enum/ENUM';

type Props = {
    location?: any;
};
export const TAB_STATE_KEY = 'backState';
export const TAB_BUILDING = 0;
export const TAB_BUILDING_KEY = 'Building';
export const TAB_GATEWAY = 1;
export const TAB_GATEWAY_KEY = 'Gateway';
export const TAB_SYSTEM = 2;
export const TAB_SYSTEM_KEY = 'System';
export const TAB_SLA = 3;
export const TAB_SLA_KEY = 'SLA';
export const getTabState = (): number => {
    const tabState = sessionStorage.getItem(TAB_STATE_KEY);
    switch (tabState) {
        case TAB_SYSTEM_KEY:
            return TAB_SYSTEM;
        case TAB_GATEWAY_KEY:
            return TAB_GATEWAY;
        case TAB_BUILDING_KEY:
            return TAB_BUILDING;
        case TAB_SLA_KEY:
            return TAB_SLA;
        default:
            return TAB_BUILDING;
    }
};
export const updateTabSession = (tab: string): void => {
    sessionStorage.setItem(TAB_STATE_KEY, tab);
};


export const Assets: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const authState = useAuthState();
    const history = useHistory();
    const { elstatus, buildingID, customerID, projectID, customerNo } = props.location.state !== undefined ? props.location.state : '';
    const [projectId, setProjectId] = React.useState(projectID);
    const [elStatus, setElStatus] = useState(elstatus);
    const [buildingId, setBuildingId] = useState(buildingID);
    const [customerId, setCustomerId] = React.useState(customerID);
    const [customerNumber, setCustomerNumber] = React.useState(customerNo);
    const [gatewayId, setGatewayId] = useState('');
    const usertype = getUserRoleType(authState) || '';
    const useOrganizationDataList = useOrganizationList();
    const [organizationListData, setOrganizationListData] = React.useState<ItemList>([]);


    function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
        const { children, value, index, ...other } = props;
        return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
    }

    const [value, setValue] = React.useState(getTabState());

    updateTabSession(TAB_BUILDING_KEY);

    const handleTabChange = (_event: any, newValue: React.SetStateAction<number>) => {
        setProjectId('');
        setBuildingId('');
        setGatewayId('');
        setElStatus('');
        setCustomerId('');
        setCustomerNumber('');
        setValue(newValue);
    };


    useEffect(() => {
        setOrganizationListData(useOrganizationDataList.organizationList);
    }, [useOrganizationDataList, useOrganizationDataList.organizationList]); //eslint-disable-line react-hooks/exhaustive-deps

    const setSelectedBuildingId = (value: { buildingId: string; projectId: string }): void => {
        pushAppRouteOverview(
            history,
            { type: 'OverviewBuildingDetails' },
            { backRoute: 'Assets', buildingId: value.buildingId, projectId: value.projectId }
        );
    };

    const setGatewayList = (value: { buildingId: string; projectId: string }): void => {
        setProjectId(value.projectId);
        setBuildingId(value.buildingId);
        setValue(TAB_GATEWAY);
    };

    const setSystemList = (value: { buildingId: string; projectId: string; gatewayId: string }): void => {
        setProjectId(value.projectId);
        setBuildingId(value.buildingId);
        setGatewayId(value.gatewayId);
        setValue(TAB_SYSTEM);
    };

    const setSelectedGatewayId = (value: { gatewayID: string; }): void => {
        //todo selection
    };

    const setSelectedSystem = (value: { deviceData: DeviceAssetData; }): void => {
        updateTabSession(TAB_SYSTEM_KEY);

        const device: DeviceAssetData = value.deviceData;
        pushAppRoute(history,
            { type: 'AssetDetails', deviceId: device.systemId, gatewayId: device.gateWayId, projectId: device.projectId },
            { backRoute: 'Assets', deviceId: device.systemId, buildingId: device.buildingId, projectId: device.projectId, gatewayId: device.gateWayId, sourcePath: 'Assets' });
    };

    const setSelected = (value: {
        projectId: string, buildingId: string, gatewayId: string
    }): void => {
        setValue(TAB_SYSTEM);
        setProjectId(value.projectId);
        setBuildingId(value.buildingId);
        setGatewayId(value.gatewayId);
    };

    return (
        <>
            <div data-testid="assets-container">
                <GenericToolbar title={t('DASHBOARD.DASHBOARD_ASSETS_TITLE')} />
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <AppBar position="static" style={{ backgroundColor: PXBColors.blue[700] }}>
                        <Tabs indicatorColor='secondary' variant="scrollable"
                            scrollButtons={false} textColor="inherit" value={value} onChange={handleTabChange} sx={{
                                backgroundColor: PXBColors.blue[700],
                                height: 2,
                            }} >
                            <Tab data-testid="tabbuildings" label={t('ASSETS_DETAILS.BUILDINGS')} style={{ textTransform: 'none', marginLeft: 35, color: PXBColors.white[500] }} />
                            <Tab data-testid="tabgateways" label={t('ASSETS_DETAILS.GATEWAYS')} style={{ textTransform: 'none', marginLeft: 35, color: PXBColors.white[500] }} />
                            <Tab data-testid="tabsystems" label={t('ASSETS_DETAILS.SYSTEMS')} style={{ textTransform: 'none', marginLeft: 35, color: PXBColors.white[500] }} />
                            {usertype === USER_ROLE_TYPE.SERVICE_MANAGER &&
                                <Tab data-testid="tabsla" label={t('ASSETS_DETAILS.SLA')} style={{ textTransform: 'none', marginLeft: 35, color: PXBColors.white[500] }} />
                            }
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={TAB_BUILDING} data-testid="tabbuildings-container">
                        <AssetsBuildings buildingClick={setSelectedBuildingId} gatewayCountClick={setGatewayList} systemCountClick={setSystemList} orgList={organizationListData} defaultProject={projectId} elStatus={elStatus} customerId={customerID} />
                    </TabPanel>
                    <TabPanel data-testid="tabgateways-container" value={value} index={TAB_GATEWAY}>
                        <AssetsGateways onClick={setSelectedGatewayId} orgList={organizationListData} defaultProject={projectId} defaultBuilding={buildingId} defaultGateway={gatewayId} systemCountClick={setSystemList} showAllClick={setSelected} />
                    </TabPanel>
                    <TabPanel data-testid="tabsystems-container" value={value} index={TAB_SYSTEM}>
                        <AssetsSystems onClick={setSelectedSystem} orgList={organizationListData} defaultProject={projectId} defaultBuilding={buildingId} elStatus={elStatus} gatewayId={gatewayId} customerId={customerID}/>
                    </TabPanel>
                    <TabPanel data-testid="tabsla-container" value={value} index={TAB_SLA}>
                        <AssetsSLA defaultProject={projectId} defaultBuilding={buildingId} defaultCustomer={customerNumber} />
                    </TabPanel>
                </Box>
            </div >
        </>
    );
};
