import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { REMOVE_MARK_AS_FAV } from './url-constants';

type RemoveMarkAsFavEndpointSpecifications = ApiEndpointSpecification;
export const RemoveMarkAsFavEndpointSpecification: RemoveMarkAsFavEndpointSpecifications = {
    url: REMOVE_MARK_AS_FAV,
    method: HttpMethod.DELETE,
    requestParamsSchemaName: 'RemoveMarkAsFavRequestParams',
    requestBodySchemaName: 'RemoveMarkAsFavRequestBody',
    okResponseSchemaName: 'RemoveMarkAsFavRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeRemoveMarkAsFav = Helpers.makeApiRequestFn<
    Types.RemoveMarkAsFavRequestParams,
    Types.RemoveMarkAsFavRequestBody,
    Types.RemoveMarkAsFavRequestOkResponse
>(RemoveMarkAsFavEndpointSpecification);
