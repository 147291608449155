import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTranslations from './english';
import frenchTranslations from './french';
import germanTranslations from './German';
import { frCA, enUS } from 'date-fns/locale';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: englishTranslations,
            fr: frenchTranslations,
            de: germanTranslations,
        },
        lng: 'en',
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false,
        },
    });

const getDateLocale = (): Locale => {
    switch (i18n.language) {
        case 'fr':
            return frCA;
        default:
            return enUS;
    }
};


// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getChartsTooltipDateTimeLabelFormats = () => {
    switch (i18n.language) {
        case 'fr': // default to built-in locale until a native French speaker can specify
            return undefined;

        case 'en': {
            // assume US English
            const format = '%m/%d/%Y %l:%M:%S %P';

            return {
                day: format,
                hour: format,
                millisecond: format,
                second: format,
                minute: format,
                month: format,
                year: format,
                week: format,
            };
        }

        default:
            return undefined;
    }
};

export const dateLocale = getDateLocale();

export default i18n;
