export type UserDetailsData = {
    firstname: string,
    lastname: string,
    display_name: string,
    phone: string,
    email_id: string,
    job_title: null,
    department: null,
    countryCode: string,
    user_phone_number: string,
    company_name: string,
    company_phoneNumner: string | null,
    company_address: string,
    company_country: string,
    company_countrycode: string | null,
    company_complete_phone_number: string | null,
    company_city: string,
    company_postalcode: string,
    accountCreated: string,
    language: number,
    timeZone: number,
    dateFormat: number,
    unitSystem: number,
    appTheme: number,
    isAutologoutEnabled: string,
    reportTypePreference: number,
    reportFrequencyNotificationType: number,
    productUpdateNotificationType: number,
    alarmNotificationType: number,
    statusUpdateNotificationType: number,
    lastSeen: string,
    userStatus: number,
    roleTypeId: number,
    platformName: string,
};
export type userDetail = Array<UserDetailsData>;

export type UserInfo = {
    user: UserDetailsData;
    notification: { emailenabled: boolean; smsenabled: boolean };
    session: { sessionEnabled: boolean };
};
export type UserBasicInfo = {
    fName: string | null;
    lName: string | null;
    email: string;
    phone: string | null;
    countryCode?: string | null;
};

export enum ChangePasswordDialogProgress {
    Initial,
    Failed,
    Succeeded,
}
export enum UserRole {
    SiteUser = 'Site User',
    OrgAdmin = 'Organization Admin',
    LocationAdmin = 'Location Admin',
    EatonAdmin = 'Eaton Admin',
    Unassigned = 'Unassigned',
}
export enum UserOperations {
    UserManagement = 'WAS_UserManagement',
    SiteManagement = 'WAS_SiteManagement',
    DeleteUser = 'WAS_DeleteUser',
    AddSite = 'WAS_AddSite',
    DeleteLocation = 'WAS_DeleteLocation',
}
export type Role = Array<{
    roleId: string;
    siteId: string[];
    roleName: string;
    roleDescription: string;
    operations?: string[] | undefined;
}>;

export type SiteRoleData = {
    siteId: string;
    siteName: string;
    siteAddress: string | null;
    roleName: string;
    roleId: string;
    operations: string[];
};

export type RoleLocationList = Array<{
    index: number;
    roleId: string;
    disabledLocations: Array<{ siteId: string; siteName: string }>;
    roleList: Array<{ roleId: string; roleName: string }>;
    selectedLocation: Array<{ siteId: string; siteName: string }>;
}>;

export type SiteRole = {
    roleId: string;
    siteIds: string[];
};
export type AssociatedSiteRole = {
    roleId: string;
    siteIds: Array<{ siteId: string; siteName: string; disabled: boolean }>;
    disabled: boolean;
};

export type UserInviteEntityModel = {
    entityId: string,
    entityType: string,
    grantAccessToBuilding: boolean,
    roleType: string,
}

export type UserInviteRequest = {
    emailId: string,
    inviteAsColleague: boolean,
    inviteEntities: Array<UserInviteEntityModel>
}

export type UserInvitationDetailModel = {
    emailId: string,
    userType: number,
}
