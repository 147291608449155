// eslint-disable-next-line
import React, { useCallback, useEffect } from 'react';
import * as PXBColors from '@brightlayer-ui/colors';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { SELECTED_CUSTOMER, ALL_CUSTOMER_ID, ADD_CUSTOMER_ID, TAB_CUSTOMER, ActionType } from '../../constants';
import { CircularProgress, InputLabel } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import Checkbox from "@mui/material/Checkbox";
import { Done, Add } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { EmptyState } from '@brightlayer-ui/react-components';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useHistory } from 'react-router-dom';
import { pushAppRoute } from '../../data/domain/route-manager';
import { rem_16 } from '../../constants/dimentions';
import { useCustomerList } from '../../data/DataProviders/CustomerListProvider';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { getControlledText } from '../../app/enum/GenericFormat';

type Customer = { id: string; name: string };
type CustomerList = {
    customerList: Customer[];
    selectedCustomer: string;
    updateSelectedCustomer: (customerID: string) => void;
};
const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export const CustomerDropdownToolbar: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ TestCustomerList?: CustomerList }>>> = (props) => {
    const { t } = useLanguageLocale();
    const history = useHistory();
    const DRAWER_WIDTH = 360;
    const useCustomerDataList = useCustomerList();
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    //eslint-disable-next-line
    const [customerList, setCustomerList] = React.useState<CustomerList>(useCustomerDataList);

    const [customerListData, setCustomerListData] = React.useState<Customer[]>([])
    const [selectedCustomer, setSelectedCustomer] = React.useState<string>('');
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [displayCheck, setDisplayCheck] = React.useState(false);

    const createCustomerList = () => {
        const allCustomer: Customer[] = [{ id: ALL_CUSTOMER_ID, name: t('DASHBOARD.ALL_CUSTOMER') }];
        const addCustomer: Customer[] = [{ id: ADD_CUSTOMER_ID, name: t('DASHBOARD.ADD_CUSTOMER') }];
        const list = allCustomer.concat(useCustomerDataList.customerList).concat(addCustomer);
        setCustomerListData(list);
    };

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            formControl: {
                color: PXBColors.white[400],
                margin: theme.spacing(1),
                marginLeft: 0,
                width: 270,
                background: 'none',
            },
            dropdown: {
                color: PXBColors.black[400],
            },
            subheaderClass: {
                color: PXBColors.white[400],
                display: 'flex',
                alignItems: 'center',
                marginLeft: '15px',
            },
            PaperStyle: {
                borderRadius: 0,
            },
            select: {
                '&:focus': { background: 'none' },
            },
        })
    );
    const classes = useStyles();

    useEffect(() => {
        setCustomerList(useCustomerDataList);
        setup();
    }, [useCustomerDataList, useCustomerDataList.customerList]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.TestCustomerList) {
            setCustomerList(props.TestCustomerList);
            setup();
        }
    }, [props.TestCustomerList]); //eslint-disable-line react-hooks/exhaustive-deps

    const setup = (): void => {
        createCustomerList();
        if (selectedCustomer === '') {
            sessionStorage.setItem(SELECTED_CUSTOMER, ALL_CUSTOMER_ID);
            setSelectedCustomer(ALL_CUSTOMER_ID);
            customerList.updateSelectedCustomer(ALL_CUSTOMER_ID);
        }
    }

    const onDropdownCloseHandler = useCallback((): void => {
        setIsDropdownOpen(false);
        setDisplayCheck(false);
    }, []);

    const onDropdownOpenHandler = useCallback((): void => {
        setIsDropdownOpen(true);
        setDisplayCheck(true);
    }, []);

    const onSelectedCustomerChangeHandler = (event: SelectChangeEvent) => {
        if (useCustomerDataList)
            useCustomerDataList.setTabClicked(false);
        setSelectedCustomer(event.target.value as string);
        if (event.target.value !== '0') {
            if (event.target.value === ADD_CUSTOMER_ID) {
                const custId = sessionStorage.getItem(SELECTED_CUSTOMER);
                if (custId) {
                    setSelectedCustomer(custId);
                }
                addNewCustomer();
            } else {
                const custId = event.target.value as string
                sessionStorage.setItem(SELECTED_CUSTOMER, custId);
                setSelectedCustomer(custId);
                customerList.updateSelectedCustomer(custId);
            }
        }
    };

    const addNewCustomer = (): void => {
        pushAppRoute(history,
            { type: 'AddCustomerDetails', actionType: ActionType.ADD },
            { backRoute: 'Dashboard' }
        );
    }

    const isDividerDisplay = (id: string): boolean => {
        return id === ALL_CUSTOMER_ID || id === customerListData[customerListData.length - 2].id
    }

    return (
        <>
            {(!useCustomerDataList.custListLoaded) && <EmptyState style={{ flex: 1, marginTop: 10, backgroundColor: PXBColors.white[50] }}
                icon={<CircularProgress id="progress-spinner-service-customer" />} title="" placeholder={undefined} />}

            {useCustomerDataList.custListLoaded &&
                <div data-testid="customer-dd-container"
                    style={{ color: PXBColors.white[100] }}>
                    <FormControl variant={'filled'} style={{ display: 'flex', minWidth: 50 }}>
                        <InputLabel id="customer-name-label"> <Typography variant="overline">{t('DASHBOARD.CUSTOMER_TITLE')}</Typography></InputLabel>
                        <Select
                            disableUnderline
                            variant="filled"
                            label={t('DASHBOARD.CUSTOMER_TITLE')}
                            labelId="customer-dd-control-label"
                            id="customer-dd-control"
                            data-testid="customer-dd-control"
                            open={isDropdownOpen}
                            onClose={onDropdownCloseHandler}
                            onOpen={onDropdownOpenHandler}
                            value={selectedCustomer}
                            onChange={onSelectedCustomerChangeHandler}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                MenuListProps: {
                                    disablePadding: true,
                                },
                                PaperProps: {
                                    style: { width: DRAWER_WIDTH },
                                },
                                classes: { paper: classes.PaperStyle },

                            }}
                            className={classes.dropdown}
                        >
                            {customerListData?.map((item) => (
                                <StyledMenuItem
                                    data-testid="customer-dropdown-item"
                                    key={item.id}
                                    value={item.id}
                                    divider={isDividerDisplay(item.id)}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'left', fontSize: rem_16,
                                        lineHeight: 1.5, fontWeight: 400,
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                        whiteSpace: 'nowrap',
                                        minHeight: 48,
                                        paddingTop: 6,
                                        paddingBottom: 6,
                                        width: 'auto',
                                        paddingLeft: 16,
                                        paddingRight: 16
                                    }}
                                >
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        {displayCheck && <Checkbox
                                            icon={item.id === ADD_CUSTOMER_ID ? <Add style={{ width: 20, height: 20, color: PXBColors.gray[500] }} /> : <Done style={{ color: '#00000000', width: 20, height: 20 }} />} checkedIcon={<Done style={{ width: 20, height: 20, color: PXBColors.gray[500] }} />} checked={(item.id === ADD_CUSTOMER_ID) ? false : (selectedCustomer.indexOf(item.id) > -1)}
                                        />}

                                        <Typography variant="subtitle1" style={{ fontWeight: isDropdownOpen ? 400 : 600, fontSize: rem_16, color: item.id === ADD_CUSTOMER_ID ? PXBColors.gray[500] : PXBColors.black[500] }}>
                                            {getControlledText(item.name, 35)}
                                        </Typography>
                                    </div>
                                </StyledMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            }
        </>
    );
};
