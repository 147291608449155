// eslint-disable-next-line
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ReportsDetails } from '../../components/ReportsDetails/ReportsDetails';
import { AppRoutes } from '../../routes';
import { Reports } from './Reports';

export const ReportsManagement: React.FC = () => (
    <Switch>
        <Route exact path={AppRoutes.Reports} component={Reports} />
        <Route exact path={AppRoutes.ReportsDetails} component={ReportsDetails} />
    </Switch>
);
