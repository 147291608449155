// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Button, Card, CircularProgress, IconButton, InputAdornment, Table, TableBody, TablePagination, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { Close, Search } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import { HeadCell, Order } from '../../types/table';
import { useDispatch, useSelector } from 'react-redux';
import { Thunks } from '../../actions/thunks';
import { ItemList } from '../../types/logbook-type';
import { Selectors } from '../../selectors';
import { CountryList } from '../../state/registration-state';
import { ActionType, CURRENT_PAGE_SESSION, ORGANIZATTION_NAME, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { getComparator, stableSort } from '../../lib/table-helpers';
import { DropDownListComponent } from '../../components/DropDownList/DropDownListComponent';
import { EnhancedTableHead } from '../../components/Table/EnhancedTableHead';
import { EmptyState } from '@brightlayer-ui/react-components';
import { ServicePartnerCustomerDataList, ServicePartnerCustomerDetailDataList, ServicePartnerCustomerDetailListItems } from '../../types/servicePartnerCustomerList';
import { ServicePartnerListItem } from '../../components/UserManagementListItem/ServicePartnerListItem';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { TAB_CUSTOMERS_KEY, updateUserTabSession } from './ManageUser';
import { servicePartnerDetail } from '../../types/add-ServicePartnerCustomer';

type Props = {
    //TO-DO
};

export const ServicePartners: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const DEFAULT_ID: string = '';
    const [country, setCountry] = useState<ItemList>([]);
    const countryListLoading = useSelector(Selectors.Registration.countryListLoading);
    const countryListSuccess = useSelector(Selectors.Registration.countryListSuccess);
    const userManagement = useSelector(Selectors.Usermanagement.usermanagement);
    const countryListFail = useSelector(Selectors.Registration.countryListFail);
    const [countryLoaded, setCountryLoaded] = React.useState(false);
    const registration = useSelector(Selectors.Registration.registration);
    const [countryId, setCountryId] = React.useState(DEFAULT_ID);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('companyName');
    const [companyId, setCompanyId] = React.useState(DEFAULT_ID);
    const [filterCompany, setFilteredCompany] = React.useState(DEFAULT_ID);
    const [filterCountry, setFilteredCountry] = React.useState(DEFAULT_ID);
    const [searchText, setSearchText] = useState(DEFAULT_ID);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [offset, setOffset] = React.useState(1);
    const [serviceManagersList, setServiceManagersList] = useState<ServicePartnerCustomerDetailDataList>([]);
    const [emptyServicePartnerDetailList, setEmptyServicePartnerDetailList] = React.useState(false);
    const [servicePartnerDetailDataLoaded, setServicePartnerDetailDataLoaded] = React.useState(false);
    const [selectedservicePartner, setSelectedservicePartner] = React.useState(DEFAULT_ID);


    const [companyList, setCompanyList] = React.useState<ItemList>([]);

    const headCells: HeadCell[] = [
        { id: 'companyName', label: t('USERS.CUSTOMER'), hideSorting: false },
        { id: 'country', label: t('USERLIST.COL_LOCATION'), hideSorting: false },
        { id: 'city', label: t('USERS.CITY'), hideSorting: false },
        { id: 'customerNumber', label: t('USERS.CUSTOMER_ID'), hideSorting: false },
        { id: 'userStatus', label: t('USERS.STATUS'), hideSorting: false }
    ];

    const addNewCustomer = (): void => {
        pushAppRoute(history,
            { type: 'CreateServicePartner',actionType: ActionType.ADD },
            { backRoute: 'ManageUsers' });
            updateUserTabSession(TAB_CUSTOMERS_KEY);
    }

    const getServicePartnerDetailList = (list: ServicePartnerCustomerDetailDataList): ServicePartnerCustomerDetailDataList => {
        return list.map((data, index) => {
            return (
                {

                    companyName: data.companyName,
                    id: data.id,
                    customerNumber: data.customerNumber,
                    country: data.country,
                    city: data.city,
                    serviceManagerEmail: data.serviceManagerEmail,
                    serviceManagerFirstName: data.serviceManagerFirstName,
                    serviceManagerLastName: data.serviceManagerLastName,
                    serviceManagerDisplayName: data.serviceManagerDisplayName,
                    imageBase64: data.imageBase64,
                    imageType: data.imageType
                }

            );
        })
    };

    const countryListData = (list: CountryList): ItemList => {
        return list?.map((data, country) => {
            return {
                id: data.name,
                name: data.name,
            };
        });
    };

    //**** Calling filter APIs ****//
    useEffect(() => {
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        dispatch(Thunks.Registration.countryCodeRequest());
        dispatch(Thunks.UserManagement.getServicePartnerCustomersList());
        // eslint-disable-next-line
    }, []);

    //***** Populating country filter data ***//
    useEffect(() => {
        if (!countryListLoading && countryListSuccess) {
            setCountry(countryListData(registration.countryList));
            setCountryLoaded(true);
        } else if (!countryListLoading && countryListFail) {
            setCountry([]);
            setCountryId(DEFAULT_ID);
        }
    }, [countryListLoading, countryListSuccess, countryListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (filterCountry !== '' || filterCompany !== '') {
            dispatch(Thunks.UserManagement.getServicePartnerCustomersDetailList(filterCompany, filterCountry, searchText, '1', rowsPerPage.toString()));
            setCurrentPage(0);
        } else {
            dispatch(Thunks.UserManagement.getServicePartnerCustomersDetailList('', '', searchText, offset.toString(), rowsPerPage.toString()));
            const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
            setCurrentPage(parseInt(currentPageAfterSearchClear));
        }
        // eslint-disable-next-line
    }, [filterCountry, rowsPerPage, filterCompany]);

    useEffect(() => {
        if (filterCountry !== '' || filterCompany !== '') {
            dispatch(Thunks.UserManagement.getServicePartnerCustomersDetailList(filterCompany, filterCountry, searchText, '1', rowsPerPage.toString()));
        } else {
            dispatch(Thunks.UserManagement.getServicePartnerCustomersDetailList('', '', searchText, offset.toString(), rowsPerPage.toString()));
            const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
            setCurrentPage(parseInt(currentPageAfterSearchClear));
        }
        // eslint-disable-next-line
    }, [offset]);

    useEffect(() => {
        if (!userManagement.servicePartnerCustomersDetailListLoading && userManagement.servicePartnerCustomersDetailListSuccess) {
            setEmptyServicePartnerDetailList(userManagement.servicePartnerCustomerDetailList.detail.length <= 0)
            if (userManagement.servicePartnerCustomerDetailList.detail.length > 0) {
                const serviceManagersList = getServicePartnerDetailList(userManagement.servicePartnerCustomerDetailList.detail);
                setServiceManagersList(serviceManagersList);
                setTotalRecords(userManagement.servicePartnerCustomerDetailList.totalRecordCount)
            } else {
                setServiceManagersList([]);
            }
            setServicePartnerDetailDataLoaded(true);
        }
    }, [userManagement.servicePartnerCustomersDetailListLoading, userManagement.servicePartnerCustomersDetailListSuccess, userManagement.servicePartnerCustomersDetailListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const servicePartnerCustomerListData = (list: ServicePartnerCustomerDataList): ItemList => {
        return list?.map((data, servicePartner) => {
            return {
                id: data.id,
                name: data.companyName,
            };
        });
    };
    useEffect(() => {
        if (!userManagement.servicePartnerCustomersListLoading && userManagement.servicePartnerCustomersListSuccess) {
            setCompanyList(servicePartnerCustomerListData(userManagement.servicePartnerCustomerList));
        } else if (!userManagement.servicePartnerCustomersListLoading && userManagement.servicePartnerCustomersListFail) {
            setCompanyId(DEFAULT_ID);
        }
    }, [userManagement.servicePartnerCustomersListLoading, userManagement.servicePartnerCustomersListSuccess]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (servicePartnerDetailDataLoaded) {
            /* Debouncing to override user typing */
            const delayDebounceFn = setTimeout(() => {
                if (searchText !== '') {
                    dispatch(Thunks.UserManagement.getServicePartnerCustomersDetailList('', filterCountry, searchText, '1', rowsPerPage.toString()));
                    setCurrentPage(0);
                } else {
                    dispatch(Thunks.UserManagement.getServicePartnerCustomersDetailList('', filterCountry, searchText, offset.toString(), rowsPerPage.toString()));
                    const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
                    setCurrentPage(parseInt(currentPageAfterSearchClear));
                }
            }, 3000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

    //**** Search text ***//
    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setSearchText(e.target.value);
    };

    const resetSearch = (): void => {
        setSearchText(DEFAULT_ID);
        setSelectedservicePartner(DEFAULT_ID);
    };

    //*** Sorting ***//
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const sortedValue = stableSort(serviceManagersList, getComparator(order, orderBy));
        setServiceManagersList(sortedValue);
    };

    //*** Pagination ***//
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, newPage.toString());
        setOffset(newPage + 1);
        setSelectedservicePartner(DEFAULT_ID);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        setCurrentPage(0);
        setOffset(1);
        setSelectedservicePartner(DEFAULT_ID);
    };

    const handleDisplay = (value: {
        ServicePartnerDetailData: servicePartnerDetail;
    }): void => {
        setSelectedservicePartner(value.ServicePartnerDetailData?.id);
    };

    function downloadReport(): void {
        //ToDo - Download customer report
    }

    return (
        <div id="users-service-partner-container" data-testid="users-service-partner-container">
            <div data-testid="users-service-partner-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    id="users-service-partner-add-user"
                    data-testid="users-service-partner-add-user"
                    style={{
                        flexDirection: 'row',
                        backgroundColor: PXBColors.white[50],
                        textTransform: 'none',
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={addNewCustomer}
                >
                    {md ? t('USERS.ADD_USER') : <MuiIcons.Add></MuiIcons.Add>}
                </Button>
                <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto' }}>
                    <Typography
                        id="users-service-partner-favorite-filter-checkbox-lable"
                        data-testid="users-service-partner-favorite-filter-checkbox-lable"
                        style={{ alignSelf: 'center' }} variant="body2">
                        {t('USERS.SHOW_FAVORITE_SERVICE_PARTNER')}
                    </Typography>
                    <Checkbox id="users-service-partner-favorite-filter-checkbox"
                    />
                </div>
            </div>
            <div data-testid="users-service-partner-search" style={{ display: 'flex', }}>
                <DropDownListComponent disabled={false} data-testid="users-service-partner-company-filter" list={companyList} value={t('SEARCH.ALL_COMPANY')} handleSelection={setFilteredCompany} selected={companyId} defaultSelection={''}></DropDownListComponent>
                <DropDownListComponent disabled={false} data-testid="users-service-partner-country-filter" list={country} value={t('SEARCH.ALL_COUNTRY')} handleSelection={setFilteredCountry} selected={countryId} defaultSelection={''}></DropDownListComponent>
                <TextField
                    inputProps={{ 'data-testid': 'search', maxLength: 100 }}
                    value={searchText}
                    style={{
                        width: 'auto',
                        textAlign: 'right',
                        float: 'right',
                        border: '0px solid #727E84',
                        borderRadius: 5,
                        backgroundColor: 'white',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        overflow: 'hidden',
                        marginTop: 28,
                        fontFamily: 'Open sans',
                        fontSize: 14,
                        color: '#727E84',
                        fontStyle: 'normal',
                        fontWeight: 400,
                    }}
                    placeholder={t('ORGANIZATION_MANAGE.SEARCH_BUILDINGS')}
                    onChange={(e): void => onChangeSearchText(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search data-testid="search-icon" style={{ color: PXBColors.gray[500] }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchText !== '' && (
                                    <IconButton onClick={resetSearch}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>

            <Card style={{ flex: '1 1 0px', overflow: 'auto', marginTop: '2%' }} id="users-service-partner-list-container" data-testid="users-service-partner-list-container">
                <Table stickyHeader size="small"
                    id="users-service-partner-list-table"
                    data-testid="users-service-partner-list-table" style={{ width: '100%' }}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />
                    {!emptyServicePartnerDetailList && !userManagement.servicePartnerCustomersDetailListLoading && <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable'}
                        id="users-service-partner-list-table-body"
                        data-testid="users-service-partner-list-table-body">
                        {stableSort(serviceManagersList, getComparator(order, orderBy)).map((row: servicePartnerDetail, index: number) => (
                            <ServicePartnerListItem
                                key={row.id}
                                row={row}
                                selectedservicePartner={selectedservicePartner}
                                displayClick={handleDisplay}
                            ></ServicePartnerListItem>
                        ))}
                    </TableBody>}
                </Table>

                {userManagement.servicePartnerCustomersDetailListLoading && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!userManagement.servicePartnerCustomersDetailListLoading && emptyServicePartnerDetailList && <EmptyState style={{ flex: 1, height: 200 }}
                    title={t('USERS.ERROR_NO_SERVICE_PARTNER_FOUND')} icon={undefined} placeholder={undefined} />}
            </Card >

            <div style={{ flex: '1 1 0px', backgroundColor: PXBColors.white[50] }}>
                {!emptyServicePartnerDetailList && <TablePagination
                    component="div"
                    data-testid="pagination-users-service-partner"
                    count={totalRecords}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('LABELS.ITEMS_PER_PAGE')}
                    showFirstButton showLastButton
                />}
            </div>
        </div>
    );
};
