import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { LINK_CUSTOMER, TRANSFER_BUILDING } from './url-constants';

type LinkCustomerEndpointSpecifications = ApiEndpointSpecification;
export const LinkCustomerEndpointSpecification: LinkCustomerEndpointSpecifications = {
    url: LINK_CUSTOMER,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'LinkCustomerRequestParams',
    requestBodySchemaName: 'LinkCustomerRequestBody',
    okResponseSchemaName: 'LinkCustomerRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeLinkCustomerRequest = Helpers.makeApiRequestFn<
    Types.LinkCustomerRequestParams,
    Types.LinkCustomerRequestBody,
    Types.LinkCustomerRequestOkResponse
>(LinkCustomerEndpointSpecification);
