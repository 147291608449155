import { InitialState } from '../state';
import { AppReducer } from './app-reducer';

export const usersReducer: AppReducer<'Users'> = (state = InitialState.Users, action): typeof state => {
    switch (action.type) {
        case 'Users/userList/Started':
            return {
                ...state,
                userListLoading: true,
                userListSuccess: false,
                userListFail: false,
                userListerrorCode: '',
            };
        case 'Users/userList/Succeeded':
            return {
                ...state,
                userListLoading: false,
                userListSuccess: true,
                userListFail: false,
                userListerrorCode: '',
                userList: action.payload.userList,
            };
        case 'Users/userList/Failed':
            return {
                ...state,
                userListLoading: false,
                userListSuccess: false,
                userListFail: true,
                userListerrorCode: action.payload.errorCode,
            };

        case 'Users/inviteUser/Started':
            return {
                ...state,
                inviteUserLoading: true,
                inviteUserSuccess: false,
                inviteUserFail: false,
                inviteUsererrorCode: '',
            };
        case 'Users/inviteUser/Succeeded':
            return {
                ...state,
                inviteUserLoading: false,
                inviteUserSuccess: true,
                inviteUserFail: false,
                inviteUsererrorCode: '',
                inviteUseData: action.payload.sentInviteResult,
            };
        case 'Users/inviteUser/Failed':
            return {
                ...state,
                inviteUserLoading: false,
                inviteUserSuccess: false,
                inviteUserFail: true,
                inviteUsererrorCode: action.payload.errorCode
            };
        case 'Users/inviteUser/Unmount':
            return {
                ...state,
                inviteUserLoading: false,
                inviteUserSuccess: false,
                inviteUserFail: false,
                inviteUsererrorCode: '',
            };

        case 'Users/invitationHistory/Started':
            return {
                ...state,
                inviteUserHistoryLoading: true,
                inviteUserHistorySuccess: false,
                inviteUserHistoryFail: false,
                inviteUserHistoryerrorCode: '',
            };
        case 'Users/invitationHistory/Succeeded':
            return {
                ...state,
                inviteUserHistoryLoading: false,
                inviteUserHistorySuccess: true,
                inviteUserHistoryFail: false,
                inviteUserHistoryerrorCode: '',
                inviteUserHistory: action.payload.userInvitationHistory,
            };
        case 'Users/invitationHistory/Failed':
            return {
                ...state,
                inviteUserHistoryLoading: false,
                inviteUserHistorySuccess: false,
                inviteUserHistoryFail: true,
                inviteUserHistoryerrorCode: action.payload.errorCode,
            };
        case 'Users/invitationHistory/Unmounted':
            return {
                ...state,
                inviteUserHistoryLoading: false,
                inviteUserHistorySuccess: false,
                inviteUserHistoryFail: false,
                inviteUserHistoryerrorCode: '',
                inviteUserHistory: {
                    invited: [],
                    expired: [],
                    denied: [],
                    requested: [],
                    accepted: [],
                    acceptedInvitationsCount: 0
                }
            };

        case 'Users/adminInvitationHistory/Started':
            return {
                ...state,
                adminApporvalInviteHistoryLoading: true,
                adminApporvalInviteHistorySuccess: false,
                adminApporvalInviteHistoryFail: false,
                adminApporvalInviteHistoryerrorCode: '',
            };
        case 'Users/adminInvitationHistory/Succeeded':
            return {
                ...state,
                adminApporvalInviteHistoryLoading: false,
                adminApporvalInviteHistorySuccess: true,
                adminApporvalInviteHistoryFail: false,
                adminApporvalInviteHistoryerrorCode: '',
                adminApporvalInviteHistory: action.payload.invitationRequestList,
            };
        case 'Users/adminInvitationHistory/Failed':
            return {
                ...state,
                adminApporvalInviteHistoryLoading: false,
                adminApporvalInviteHistorySuccess: false,
                adminApporvalInviteHistoryFail: true,
                adminApporvalInviteHistoryerrorCode: action.payload.errorCode,
            };
        case 'Users/adminInvitationHistory/Unmounted':
            return {
                ...state,
                adminApporvalInviteHistoryLoading: false,
                adminApporvalInviteHistorySuccess: false,
                adminApporvalInviteHistoryFail: false,
                adminApporvalInviteHistoryerrorCode: '',
                adminApporvalInviteHistory: [],
            };

        case 'Users/invitationAccessRequest/Started':
            return {
                ...state,
                inviteAccessRequestLoading: true,
                inviteAccessRequestSuccess: false,
                inviteAccessRequestFail: false,
                inviteAccessRequesterrorCode: '',
            };
        case 'Users/invitationAccessRequest/Succeeded':
            return {
                ...state,
                inviteAccessRequestLoading: false,
                inviteAccessRequestSuccess: true,
                inviteAccessRequestFail: false,
                inviteAccessRequesterrorCode: '',
            };
        case 'Users/invitationAccessRequest/Failed':
            return {
                ...state,
                inviteAccessRequestLoading: false,
                inviteAccessRequestSuccess: false,
                inviteAccessRequestFail: true,
                inviteAccessRequesterrorCode: action.payload.errorCode,
            };

        case 'Users/updateUserInvitation/Started':
            return {
                ...state,
                updateUserInvitationLoading: true,
                updateUserInvitationSuccess: false,
                updateUserInvitationFail: false,
                updateUserInvitationerrorCode: '',
            };
        case 'Users/updateUserInvitation/Succeeded':
            return {
                ...state,
                updateUserInvitationLoading: false,
                updateUserInvitationSuccess: true,
                updateUserInvitationFail: false,
                updateUserInvitationerrorCode: '',
                updateUserInvitationResult: action.payload.updateInvitationList
            };
        case 'Users/updateUserInvitation/Failed':
            return {
                ...state,
                updateUserInvitationLoading: false,
                updateUserInvitationSuccess: false,
                updateUserInvitationFail: true,
                updateUserInvitationerrorCode: action.payload.errorCode,
            };

        case 'Users/updateInvitationRequest/Started':
            return {
                ...state,
                updateInvitationRequestLoading: true,
                updateInvitationRequestSuccess: false,
                updateInvitationRequestFail: false,
                updateInvitationRequesterrorCode: '',
            };
        case 'Users/updateInvitationRequest/Succeeded':
            return {
                ...state,
                updateInvitationRequestLoading: false,
                updateInvitationRequestSuccess: true,
                updateInvitationRequestFail: false,
                updateInvitationRequesterrorCode: '',
                updateInvitationRequestResult: action.payload.updateInvitationList
            };
        case 'Users/updateInvitationRequest/Failed':
            return {
                ...state,
                updateInvitationRequestLoading: false,
                updateInvitationRequestSuccess: false,
                updateInvitationRequestFail: true,
                updateInvitationRequesterrorCode: action.payload.errorCode,
            };
        case 'Users/entityUserDetail/Started':
            return {
                ...state,
                getEntityUserDetailLoading: true,
                getEntityUserDetailSuccess: false,
                getEntityUserDetailFail: false,
                getEntityUserDetailErrorMessage: '',
            };
        case 'Users/entityUserDetail/Succeeded':
            return {
                ...state,
                getEntityUserDetailLoading: false,
                getEntityUserDetailSuccess: true,
                getEntityUserDetailFail: false,
                getEntityUserDetailErrorMessage: '',
                entityUserDetail: action.payload.entityUserDetail,
            };
        case 'Users/entityUserDetail/Failed':
            return {
                ...state,
                getEntityUserDetailLoading: false,
                getEntityUserDetailSuccess: false,
                getEntityUserDetailFail: true,
                getEntityUserDetailErrorMessage: action.payload.errorCode,
            };
        case 'Users/entityUserDetail/Unmounted':
            return {
                ...state,
                getEntityUserDetailLoading: false,
                getEntityUserDetailSuccess: false,
                getEntityUserDetailFail: false,
                getEntityUserDetailErrorMessage: '',
            };

        case 'Users/userAdvanceAccess/Started':
            return {
                ...state,
                getuserAdvanceAccessLoading: true,
                getuserAdvanceAccessSuccess: false,
                getuserAdvanceAccessFail: false,
                getuserAdvanceAccessErrorMessage: '',
            };
        case 'Users/userAdvanceAccess/Succeeded':
            return {
                ...state,
                getuserAdvanceAccessLoading: false,
                getuserAdvanceAccessSuccess: true,
                getuserAdvanceAccessFail: false,
                getuserAdvanceAccessErrorMessage: '',
                getuserAdvanceAccess: action.payload.userAdvanceAccessDetail,
            };
        case 'Users/userAdvanceAccess/Failed':
            return {
                ...state,
                getuserAdvanceAccessLoading: false,
                getuserAdvanceAccessSuccess: false,
                getuserAdvanceAccessFail: true,
                getuserAdvanceAccessErrorMessage: action.payload.errorCode,
            };
        case 'Users/userAdvanceAccess/Unmounted':
            return {
                ...state,
                getuserAdvanceAccessLoading: false,
                getuserAdvanceAccessSuccess: false,
                getuserAdvanceAccessFail: false,
                getuserAdvanceAccessErrorMessage: '',
            };

        case 'Users/removeAccess/Started':
            return {
                    ...state,
                    removeAccessLoading: true,
                    removeAccessSuccess: false,
                    removeAccessFail: false,
                    removeAccessErrorMessage: '',
                };
        case 'Users/removeAccess/Succeeded':
            return {
                    ...state,
                    removeAccessLoading: false,
                    removeAccessSuccess: true,
                    removeAccessFail: false,
                    removeAccessErrorMessage: '',
                    removeAccess: action.payload.removeAccess,
                };
        case 'Users/removeAccess/Failed':
            return {
                    ...state,
                    removeAccessLoading: false,
                    removeAccessSuccess: false,
                    removeAccessFail: true,
                    removeAccessErrorMessage: action.payload.errorCode,
                };
        case 'Users/removeAccess/Unmounted':
            return {
                    ...state,
                    removeAccessLoading: false,
                    removeAccessSuccess: false,
                    removeAccessFail: false,
                    removeAccessErrorMessage: '',
                };
    

        default:
            return state;
    }
};