// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { Card, Typography, Divider, useTheme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { StatisticSummaryItem } from './StatisticSummaryItem';
import * as PXBColors from '@brightlayer-ui/colors';
import * as MuiCore from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import './StatisticSummary.css';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { StatisitcData, SummaryData, SummaryList } from '../../types/statistic-summary';
import { DEVICE_STATUS } from '../enum/ENUM';
import { EmptyState } from '@brightlayer-ui/react-components';

type Props = {
    userId?: string;
    projectId: string;
    buildingId?: string;
    elStatusClick?: Function;
    viewAllClick?: Function;
};

export const OverviewStatisticSummary: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const TAB_SYSTEMS: string = 'tabSystems';
    const TAB_LUMANARIES: string = 'tabLumanaries';
    const { t } = useLanguageLocale();
    //const [projectId, setProjectId] = React.useState(props.projectId || '');
    const [activeTab, setActiveTab] = useState(TAB_SYSTEMS);
    const [systemTextColor, setSystemTextColor] = React.useState('');
    const [luminariesTextColor, setLumanariesTextColor] = React.useState('');
    const [systemBackColor, setSystemBackColor] = React.useState('');
    const [luminariesBackColor, setLumanariesBackColor] = React.useState('');
    const [statusList, setStatusList] = useState<SummaryList>([]);
    const [systemList, setSystemList] = useState<SummaryList>([]);
    const [lumanariesList, setLumanariesList] = useState<SummaryList>([]);
    const [totalSystemCount, setTotalSystemCount] = React.useState(0);
    const [totalLumanariesCount, setTotalLumanariesCount] = React.useState(0);
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));


    const getStatusIcon = (status: number, count: number): SummaryData => {
        switch (status) {
            case DEVICE_STATUS.STATUS_READY:
                return {
                    name: t('STATUS.STATUS_READY'),
                    elstatus: DEVICE_STATUS.STATUS_READY,
                    count: count,
                };
            case DEVICE_STATUS.STATUS_BLOCK:
                return {
                    name: t('STATUS.STATUS_BLOCK'),
                    elstatus: DEVICE_STATUS.STATUS_BLOCK,
                    count: count,
                };
            case DEVICE_STATUS.STATUS_ERROR:
                return {
                    name: t('STATUS.STATUS_ERROR'),
                    elstatus: DEVICE_STATUS.STATUS_ERROR,
                    count: count,
                };
            case DEVICE_STATUS.STATUS_WARNING:
                return {
                    name: t('STATUS.STATUS_WARNING'),
                    elstatus: DEVICE_STATUS.STATUS_WARNING,
                    count: count,
                };

            case DEVICE_STATUS.STATUS_OFFLINE:
                return {
                    name: t('STATUS.STATUS_OFFLINE'),
                    elstatus: DEVICE_STATUS.STATUS_OFFLINE,
                    count: count,
                };
            case DEVICE_STATUS.STATUS_DT:
                return {
                    name: t('STATUS.STATUS_DT'),
                    elstatus: DEVICE_STATUS.STATUS_DT,
                    count: count,
                };
            default:
                return {
                    name: t('STATUS.STATUS_OFFLINE'),
                    elstatus: DEVICE_STATUS.STATUS_OFFLINE,
                    count: count,
                };
        }
    };

    const defaultData: StatisitcData = {
        dt: 0,
        error: 0,
        ready: 0,
        blocked: 0,
        offLine: 0,
        warning: 0,
        totalCount: 0,
    };

    useEffect(() => {
        updateSystemStatisticData(defaultData);
        updateLumanariesStatisticData(defaultData);

        setStatusList(systemList);
    }, [props.projectId]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateSystemStatisticData = (system: StatisitcData): SummaryList => {
        const systemListDefault: SummaryList = [];
        systemListDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_READY, system.ready));
        systemListDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_WARNING, system.warning));
        systemListDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_ERROR, system.error));
        systemListDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_BLOCK, system.blocked));
        systemListDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_DT, system.dt ? system.dt : 0));
        systemListDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_OFFLINE, system.offLine));

        setSystemList(systemListDefault);
        setTotalSystemCount(system.totalCount);
        return systemListDefault;
    };

    const updateLumanariesStatisticData = (lumanarieis: StatisitcData): SummaryList => {
        const lumanarieistDefault: SummaryList = [];
        lumanarieistDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_READY, lumanarieis.ready));
        lumanarieistDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_WARNING, lumanarieis.warning));
        lumanarieistDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_ERROR, lumanarieis.error));
        lumanarieistDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_BLOCK, lumanarieis.blocked));
        lumanarieistDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_DT, lumanarieis.dt ? lumanarieis.dt : 0));
        lumanarieistDefault.push(getStatusIcon(DEVICE_STATUS.STATUS_OFFLINE, lumanarieis.offLine));

        setLumanariesList(lumanarieistDefault);
        setTotalLumanariesCount(lumanarieis.totalCount);
        return lumanarieistDefault;
    };

    useEffect(() => {
        if (!dashboard.statisticSummaryDashboardLoading && dashboard.statisticSummaryDashboardSuccess) {
            if (dashboard.statisticSummaryDashboard.statisticalSystemData) {
                const systemListData: SummaryList = updateSystemStatisticData(dashboard.statisticSummaryDashboard?.statisticalSystemData)
                if (activeTab === TAB_SYSTEMS) {
                    selectList(TAB_SYSTEMS);
                    setStatusList(systemListData);
                }
            }
            if (dashboard.statisticSummaryDashboard.statisticalLuminariesData) {
                const lumanariesList: SummaryList = updateLumanariesStatisticData(dashboard.statisticSummaryDashboard?.statisticalLuminariesData);
                if (activeTab === TAB_LUMANARIES) {
                    selectList(TAB_LUMANARIES);
                    setStatusList(lumanariesList);
                }
            }
        } else if (!dashboard.statisticSummaryDashboardLoading && dashboard.statisticSummaryDashboardFail) {
            const systemListData: SummaryList = updateSystemStatisticData(defaultData);
            if (activeTab === TAB_SYSTEMS) {
                selectList(TAB_SYSTEMS);
                setStatusList(systemListData);
            }
            const lumanariesListData: SummaryList = updateLumanariesStatisticData(defaultData);
            if (activeTab === TAB_LUMANARIES) {
                selectList(TAB_LUMANARIES);
                setStatusList(lumanariesListData);
            }
        }
    }, [dashboard.statisticSummaryDashboardLoading, dashboard.statisticSummaryDashboardSuccess, dashboard.statisticSummaryDashboardFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const selectList = (type: string) => {
        if (type === TAB_SYSTEMS) {
            setActiveTab(TAB_SYSTEMS);
            setStatusList(systemList);
            setSystemTextColor(PXBColors.blue[500]);
            setLumanariesTextColor(PXBColors.black[500]);
            setSystemBackColor(PXBColors.lightBlue[50]);
            setLumanariesBackColor(PXBColors.white[50]);
        } else {
            setActiveTab(TAB_LUMANARIES);
            setStatusList(lumanariesList);
            setSystemTextColor(PXBColors.black[500]);
            setLumanariesTextColor(PXBColors.blue[500]);
            setSystemBackColor(PXBColors.white[50]);
            setLumanariesBackColor(PXBColors.lightBlue[50]);
        }
    };
    const handleDisplayELStatus = (value: {
        elstatus: any
    }): void => {
        if (props.elStatusClick)
            props.elStatusClick({ elstatus: value.elstatus });
    };

    const handleViewAllClick = () => {
        if (props.viewAllClick)
            props.viewAllClick();
    }

    return (
        <>
            <Card
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    paddingLeft: 10,
                    paddingRight: 10,
                }}
                data-testid="status-list"
            >
                <ul className="nav" style={{ width: '100%' }} data-testid="status-selector">
                    <li
                        role="tab"
                        className={activeTab === TAB_SYSTEMS ? 'active' : ''}
                        data-testid="total-system-tabs"
                        value="Total System"
                        style={{ cursor: 'pointer', color: systemTextColor, backgroundColor: systemBackColor, fontWeight: activeTab === TAB_SYSTEMS ? 600 : 400 }}
                        onClick={() => selectList(TAB_SYSTEMS)}
                    >
                        <Typography
                            variant="subtitle2"
                            data-testid="total-system-tab"
                        >
                            {t('STATUS.TOTAL_SYSTEM')} ({totalSystemCount})
                        </Typography>
                    </li>
                    <li
                        role="tab"
                        className={activeTab === TAB_LUMANARIES ? 'active' : ''}
                        data-testid="total-luminary-tabs"
                        value="Total Lumanaries"
                        style={{ cursor: 'pointer', color: luminariesTextColor, backgroundColor: luminariesBackColor, fontWeight: activeTab === TAB_LUMANARIES ? 600 : 400 }}
                        onClick={() => selectList(TAB_LUMANARIES)}
                    >
                        <Typography
                            variant="subtitle2"
                            data-testid="total-luminary-tab"
                        >
                            {t('STATUS.TOTAL_LUMINAIRES')} ({totalLumanariesCount})
                        </Typography>
                    </li>
                </ul>

                {dashboard.statisticSummaryDashboardLoading && <EmptyState style={{ flex: 1, backgroundColor: PXBColors.white[50] }}
                    icon={<MuiCore.CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

                {!dashboard.statisticSummaryDashboardLoading && <div style={{ width: '100%' }}>
                    <Grid sx={{ flexGrow: 1 }} style={{ minHeight: 110 }}>
                        <Grid item xs={'auto'}>
                            <Grid container justifyContent="space-evenly" spacing={1} columns={{ xs: 6, sm: 6 }}>
                                {statusList.map((value, building) => {
                                    return (
                                        <Grid key={value.elstatus} item xs={1} sm={1} >
                                            <Box>
                                                <StatisticSummaryItem
                                                    displayClick={activeTab === TAB_SYSTEMS ? handleDisplayELStatus : () => { }}
                                                    name={value.name}
                                                    count={value.count}
                                                    elstatus={value.elstatus}
                                                    testid={value.name}
                                                ></StatisticSummaryItem>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>}
                {activeTab === TAB_SYSTEMS &&
                    <>
                        <Divider style={{ width: '120%' }} />
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                                marginLeft: 16,
                                width: '100%',
                                height: '100%',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography
                                variant="body2"
                                data-testid="view-all"
                                style={{
                                    paddingLeft: 16,
                                    fontSize: 14,
                                    fontWeight: 600,
                                    width: md ? '50%' : '30%',
                                    color: PXBColors.blue[400],
                                }}
                            >
                                {t('LABELS.VIEW_ALL')}
                            </Typography>
                            {/* Hide as static text -- Sonal comments */}
                            {/* <Typography
                        variant="body2"
                        data-testid="firmware-update"
                        style={{
                            fontSize: 9,
                            width: md ? '41%' : '62%',
                            marginLeft: md ? '30%' : 0,
                            textAlign: 'center',
                            backgroundColor: '#FBEFC1',
                            alignContent: 'right',
                            fontWeight: 700
                        }}
                        id="project-admin"
                    >
                        {t('LOGBOOK_DETAILS.REQUIRES_FIRMWARE_UPDATE')}
                    </Typography> */}
                            <MuiCore.IconButton style={{ alignItems: 'right', width: '8%', paddingRight: 16, color: PXBColors.blue[500] }} onClick={activeTab === TAB_SYSTEMS ? handleViewAllClick : () => { }}>
                                <MuiIcons.ChevronRight />
                            </MuiCore.IconButton>
                        </div>
                    </>}
            </Card>
        </>
    );
};
