import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { ORGANIZATION_UPDATE } from './url-constants';

type OrgUpdateEndpointSpecifications = ApiEndpointSpecification;
export const OrgUpdateEndpointSpecification: OrgUpdateEndpointSpecifications = {
    url: ORGANIZATION_UPDATE,
    method: HttpMethod.PUT,
    requestParamsSchemaName: 'OrgUpdateRequestParams',
    requestBodySchemaName: 'OrgUpdateRequestBody',
    okResponseSchemaName: 'OrgUpdateRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeOrgUpdateRequest = Helpers.makeApiRequestFn<
    Types.OrgUpdateRequestParams,
    Types.OrgUpdateRequestBody,
    Types.OrgUpdateRequestOkResponse
>(OrgUpdateEndpointSpecification);
