// eslint-disable-next-line
import React, { useEffect } from 'react';
import {
    Typography,
    Card,
    IconButton,
    Button,
    Divider,
} from '@material-ui/core';
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { CheckBox, Close } from '@mui/icons-material';
import { ACTION_TYPE_COPY, ACTION_TYPE_NONE } from '../../constants';
import * as PXBColors from '@brightlayer-ui/colors';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { AssetListItem } from '../../components/AssetListItem/AssetListItem';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Report from '@mui/icons-material/Report';
import { Icon } from '../../lib/icons';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';


type Props = {
    anchorEl: null | HTMLElement;
    setAnchorEl: (anchorEl: null | HTMLElement) => void;
    isCalendarOpen: boolean;
    setIsCalendarOpen: (isCalendarOpen: boolean) => void;
};

export const AssetsSLACalendar: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const asset = useSelector(Selectors.Dashboard.asset);
    const [isCustomDatepickerOpen, setCustomDatepickerOpen] = React.useState(false);
    const [toDate, setToDate] = React.useState<Date | null>(new Date());
    const [startDate, setStartDate] = React.useState<Date | null>(new Date());
    const [endDate, setEndDate] = React.useState<Date | null>(new Date());


    const handleClose = (): any => {
        props.setAnchorEl(null);
        props.setIsCalendarOpen(false);
    };

    const openCustomDatepicker = (): any => {
        props.setAnchorEl(null);
        props.setIsCalendarOpen(false);
        setCustomDatepickerOpen(true);
    }

    const cancelCustomDate = (): any  => {
        setCustomDatepickerOpen(false);
    }

    const confirmCustomDate = (): any => {
        setCustomDatepickerOpen(false);
    }

    return (
        <>
            <div>
                <Menu
                    elevation={2}
                    id="sla-calendar-menu"
                    data-testid="sla-calendar-menu"
                    anchorEl={props.anchorEl}
                    open={props.isCalendarOpen}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{ vertical: "center", horizontal: "right" }}
                    transformOrigin={{ vertical: 100, horizontal: 340 }}
                >
                    <MenuItem data-testid="sla-calendar-sort-by-oldest"  id="sla-calendar-sort-by-oldest">
                        {t('DEVICE_DETAILS.SORT_BY_OLDEST')}
                        <div style={{ paddingLeft: '5%' }}>
                            <Checkbox id="sla-sort-by-oldest-checkbox" data-testid="sla-sort-by-oldest-checkbox"/>
                        </div>
                    </MenuItem>
                    <MenuItem data-testid="sla-calendar-sort-by-latest" id="sla-calendar-sort-by-latest">
                        {t('DEVICE_DETAILS.SORT_BY_LATEST')}
                        <div style={{ paddingLeft: '7%' }}>
                            <Checkbox data-testid="sla-sort-by-latest-checkbox" id="sla-sort-by-oldest-checkbox"></Checkbox>
                        </div>
                    </MenuItem>
                    <Divider />
                    <Typography style={{ paddingLeft: '7%' }} data-testid="sla-calendar-show" id="sla-calendar-show">
                        {t('DEVICE_DETAILS.SHOW')}
                    </Typography>
                    <MenuItem data-testid="sla-calendar-periodic-service" id="sla-calendar-periodic-service">
                        {t('DEVICE_DETAILS.PERIODIC_SERVICE')}
                        <div style={{ paddingLeft: '26%' }}>
                            <Checkbox data-testid="sla-periodic-service-checkbox" id="sla-periodic-service-checkbox"></Checkbox>
                        </div>
                    </MenuItem>
                    <MenuItem data-testid="sla-calendar-requested-service" id="sla-calendar-requested-service">
                        {t('DEVICE_DETAILS.REQUESTED_SERVICE')}
                        <div style={{ paddingLeft: '16%' }}>
                            <Checkbox data-testid="sla-requested-service-checkbox" id="sla-requested-service-checkbox"></Checkbox>
                        </div>
                    </MenuItem>
                    <MenuItem data-testid="sla-calendar-unassigned-service" id="sla-calendar-unassigned-service">
                        {t('DEVICE_DETAILS.UNASSIGNED_SERVICE')}
                        <div style={{ paddingLeft: '12%' }}>
                            <Checkbox data-testid="sla-unassigned-service-checkbox" id="sla-unassigned-service-checkbox"></Checkbox>
                        </div>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={openCustomDatepicker} data-testid="sla-calendar-custom" id="sla-calendar-custom">
                        {t('DEVICE_DETAILS.CUSTOM')}
                    </MenuItem>
                </Menu>
            </div>
            <Dialog id="custom-date-dialog" maxWidth="lg" open={isCustomDatepickerOpen} >
                <DialogTitle id="custom-date-dialog-header">{t('LABELS.CUSTOM_LOGBOOK_RANGE')}</DialogTitle>
                <DialogContent style={{ padding: 20 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={t('LABELS.START_DATE')}
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            maxDate={new Date()}          //this works but showing error to find workaround
                        />
                        <DatePicker
                            sx={{ marginLeft: 2 }}
                            label={t('LABELS.END_DATE')}
                            value={endDate}
                            onChange={(date) => setEndDate(date)}
                            minDate={startDate}
                            maxDate={new Date()}
                        />
                    </LocalizationProvider>

                </DialogContent>
                <DialogActions style={{ justifyContent: 'right', marginLeft: 15 }}>
                    <Button
                        style={{ textTransform: 'none', fontSize: 16 }}
                        id="custom-date-dialog-btnCancel"
                        data-testid="custom-date-dialog-btnCancel"
                        variant="outlined" color="primary"
                         onClick={(): void => cancelCustomDate()}
                    >
                        <Icon.Close style={{ color: PXBColors.blue[500], marginRight: 12 }} />
                        {t('ACTIONS.CANCEL')}
                    </Button>
                    <Button
                        id="custom-date-dialog-btnConfirm"
                        data-testid="custom-date-dialog-btnConfirm"
                        style={{
                            paddingLeft: 16, paddingRight: 16, paddingTop: 8.5, paddingBottom: 8.5,
                            fontSize: 16,
                            flexDirection: 'row',
                            backgroundColor: endDate && startDate && endDate >= startDate ? PXBColors.blue[500] : PXBColors.blue[50],
                            color: endDate && startDate && endDate >= startDate ? PXBColors.white[50] : PXBColors.blue[200],
                            marginLeft: 16,
                            textTransform: 'none'
                        }}
                        type="submit"
                        color="primary"
                        disabled={endDate && startDate ? !(endDate >= startDate) : true}
                         onClick={(): void => confirmCustomDate()}
                    >
                        <Icon.Check style={{ color: endDate && startDate && endDate >= startDate ? PXBColors.white[50] : PXBColors.blue[200], marginRight: 8 }} />
                        {t('ACTIONS.CONFIRM')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};