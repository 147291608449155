// eslint-disable-next-line
import React from 'react';
import * as PXBColors from '@brightlayer-ui/colors';
import { ListItemTag } from '@brightlayer-ui/react-components';

type Props = {
    label: string
}

export const GenericStatusBorderLabel: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    return (
        <ListItemTag
            data-testid="generic-status-border-label"
            label={props.label}
            fontColor={PXBColors.black[500]}
            backgroundColor={PXBColors.white[50]}
            borderRadius={1}
            borderColor={PXBColors.black[50]}
            border={1} placeholder={undefined}>
        </ListItemTag>
    );
};
