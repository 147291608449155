// eslint-disable-next-line
import React from 'react';
import { createStyles, withStyles } from '@mui/styles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import { HeadCell, Order } from '../../types/table';
import { CalendarMonth } from '@mui/icons-material';
import { EmptyState } from '@brightlayer-ui/react-components';
import { getIcon } from '../../lib/icons';

const StyledTableCell = withStyles(() => createStyles({}))(TableCell);

const StyledTableRow = withStyles(() => createStyles({}))(TableRow);

type EnhancedTableProps = {
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string | number | symbol;
    headCells: HeadCell[];
    iconClick?: Function;
};
export const EnhancedTableHead = (props: EnhancedTableProps): JSX.Element => {
    const { order, orderBy, onRequestSort, iconClick } = props;

    const createSortHandler = (headcell: HeadCell) => (event: React.MouseEvent<unknown>): void => {
        if (!headcell.hideSorting) onRequestSort(event, headcell.id);
    };

    const calendarClick = (): void => {
        iconClick!({ openCalendar: true });
    };

    return (
        <TableHead data-testid='TableHead'>
            <StyledTableRow style={{ width: '100%' }}>
                <StyledTableCell style={{ backgroundColor: '#ffffff' }} padding="normal">
                    &nbsp;
                </StyledTableCell>
                {props.headCells.map((headCell) => (
                    <StyledTableCell
                        style={{ backgroundColor: '#ffffff' }}
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell)}
                            hideSortIcon={headCell.hideSorting}
                            style={{ minWidth: headCell.minWidth ? headCell.minWidth : 'auto' }}
                        >
                            {headCell.label}
                            {
                                headCell.iconRequired ? <IconButton onClick={calendarClick}>
                                    <CalendarMonth fontSize="small" />
                                </IconButton> : null
                            }

                            {orderBy === headCell.id ? (

                                <span style={{
                                    border: 0,
                                    clip: 'rect(0 0 0 0)',
                                    height: 1,
                                    margin: -1,
                                    overflow: 'hidden',
                                    padding: 0,
                                    position: 'absolute',
                                    top: 20,
                                    width: 1,
                                }}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>

                    </StyledTableCell>
                ))}
                <StyledTableCell style={{ backgroundColor: '#ffffff' }} padding="checkbox"></StyledTableCell>
                <StyledTableCell style={{ backgroundColor: '#ffffff' }} padding="checkbox"></StyledTableCell>
            </StyledTableRow>
        </TableHead>
    );
};
