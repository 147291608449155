// eslint-disable-next-line
import React from 'react';
import { DataState, Data, makeLoadingData } from '../../types/data-providers';
import useStateWhileMounted from '../../hooks/use-state-while-mounted';
import { useAuthHelper, useAuthState, getUserId, getUserRoleType } from '../AuthContextProvider';
import { AppDataLoader, AppData } from '../../lib/app-data-loader';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';

type ContextState = Data<AppData>;

const Context = React.createContext<ContextState | null>(null);

function useAppData(): ContextState {
    const contextState = React.useContext(Context);
    if (contextState === null) {
        throw new Error('useAppData must be used within a AppDataProvider');
    }
    return contextState;
}
const initialState: ContextState = { state: DataState.Loading };

const AppDataProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = (props) => {
    const [state, setState] = useStateWhileMounted<ContextState>(initialState);
    const organization = useSelector(Selectors.Organization.organization);
    const customer = useSelector(Selectors.Customer.customer);
    const serviceOrganization = useSelector(Selectors.Organization.organization);
    const authState = useAuthState();
    const userId = getUserId(authState);
    const userType = getUserRoleType(authState);
    const authHelper = useAuthHelper();

    React.useEffect(() => {
        if (!userId) {
            return;
        }

        (async (): Promise<void> => {
            const result = await AppDataLoader.loadDataForUser(authHelper, userId, userType);
            if (result.ok) {
                setState({
                    state: DataState.Loaded,
                    value: result.value,
                });
            } else {
                setState({
                    state: DataState.Error,
                    errorMessage: result.message || 'Unable to load application data',
                });
            }
        })();
        // eslint-disable-next-line
    }, [userId, setState, authHelper, organization.updateOrgDetailSuccess, customer.dashboardCustomerListSuccess, serviceOrganization.serviceManagerOrgListSuccess]);

    return <Context.Provider value={state}>{props.children}</Context.Provider>;
};

const TestAppDataProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ value?: ContextState }>>> = (props) => (
    <Context.Provider value={props.value || makeLoadingData()}>{props.children}</Context.Provider>
);

export { useAppData, AppDataProvider, TestAppDataProvider };
