// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, IconButton, InputAdornment, Snackbar, Table, TableBody, TablePagination, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { Close, GetApp, Search } from '@mui/icons-material';
import { ArrowDropDown } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import { vw_15 } from '../../constants/dimentions';
import { AddServiceManagerDialog } from './AddServiceManagerDialog';
import { HeadCell, Order } from '../../types/table';
import { useDispatch, useSelector } from 'react-redux';
import { Thunks } from '../../actions/thunks';
import { ItemList } from '../../types/logbook-type';
import { Selectors } from '../../selectors';
import { CountryList } from '../../state/registration-state';
import { CURRENT_PAGE_SESSION, EMPTY_STRING, ORGANIZATTION_NAME, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { ServiceManagerDataList, ServiceManagerListItems } from '../../types/serviceManagers';
import { getComparator, stableSort } from '../../lib/table-helpers';
import { DropDownListComponent } from '../../components/DropDownList/DropDownListComponent';
import { USER_ROLE_TYPE, deviceTypeEnum, technicianStatusEnum } from '../enum/ENUM';
import { EnhancedTableHead } from '../../components/Table/EnhancedTableHead';
import { TechnicianListItems } from '../../types/technicians';
import { EmptyState } from '@brightlayer-ui/react-components';
import { ServiceManagerListItem } from '../../components/UserManagementListItem/ServiceManagerListItem';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider/component';
import { UserDetailsCard } from '../../components/UserManagementListItem/UserDetailsCard';
import { usermanagementActions } from '../../actions/actions/usermanagement-actions';

type Props = {
    //TO-DO
};

export const ServiceManagers: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const theme = useTheme();
    const dispatch = useDispatch();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const DEFAULT_ID: string = '';
    const [openServiceManagerDialog, setOpenServiceManagerDialog] = React.useState(false);
    const [country, setCountry] = useState<ItemList>([]);
    const countryListLoading = useSelector(Selectors.Registration.countryListLoading);
    const countryListSuccess = useSelector(Selectors.Registration.countryListSuccess);
    const countryListFail = useSelector(Selectors.Registration.countryListFail);
    const [countryLoaded, setCountryLoaded] = React.useState(false);
    const registration = useSelector(Selectors.Registration.registration);
    const [countryId, setCountryId] = React.useState(DEFAULT_ID);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('userName');
    const [compnayId, setCompanyId] = React.useState(DEFAULT_ID);
    const [roleId, setRoleId] = React.useState(DEFAULT_ID);
    const [slaNoId, setSLAId] = React.useState(DEFAULT_ID);
    const [statusId, setStatusId] = React.useState(DEFAULT_ID);
    const [filterCompany, setFilteredCompnay] = React.useState(DEFAULT_ID);
    const [filterCountry, setFilteredCountry] = React.useState(DEFAULT_ID);
    const [filterRole, setFilteredRole] = React.useState(DEFAULT_ID);
    const [filterSLANo, setFilteredSLANo] = React.useState(DEFAULT_ID);
    const [filterStatus, setFilteredStatus] = React.useState(DEFAULT_ID);
    const [searchText, setSearchText] = useState(DEFAULT_ID);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [offset, setOffset] = React.useState(1);
    const userManagement = useSelector(Selectors.Usermanagement.usermanagement);
    const [serviceManagersList, setServiceManagersList] = useState<ServiceManagerDataList>([]);
    const [emptyServiceManagersList, setEmptyServiceManagersList] = React.useState(false);
    const [serviceManagersDataLoaded, setServiceManagersDataLoaded] = React.useState(false);
    const [selectedserviceManager, setSelectedserviceManager] = React.useState(DEFAULT_ID);
    const [isInviteAsColleague, setInviteAsColleague] = useState(false);
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [openServiceManagerDetailSelectDialog, setOpenServiceManagerDetailSelectDialog] = React.useState(false);
    const userManagementState = useSelector(Selectors.Usermanagement.usermanagement);
    const [removeServiceManagerErrorMessage, setRemoveServiceManagerErrorMessage] = useState<string>('');
    const [removeServiceManagerSuccessMessage, setRemoveServiceManagerSuccessMessage] = useState<string>('');
    const [displayRemoveServiceManagerSuccessMessage, setDisplayRemoveServiceManagerSuccessMessage] = useState(false);
    const [displayRemoveServiceManagerErrorMessage, setDisplayRemoveServiceManagerErrorMessage] = useState(false);

    const headCells: HeadCell[] = [
        { id: 'userName', label: t('USERS.NAME'), hideSorting: false },
        { id: 'company_name', label: t('USERS.COMPANY'), hideSorting: false },
        { id: 'company_country', label: t('USERS.COUNTRY'), hideSorting: false },
        { id: 'userStatus', label: t('USERS.STATUS'), hideSorting: false }
    ];

    const getServiceManagersList = (list: ServiceManagerDataList): ServiceManagerDataList => {
        return list.map((data, index) => {
            return (
                {
                    userName: data.userName,
                    id: data.id,
                    email: data.email,
                    company_name: data.company_name,
                    company_country: data.company_country,
                    userStatus: data.userStatus
                }
            );
        })
    };

    const countryListData = (list: CountryList): ItemList => {
        return list?.map((data, country) => {
            return {
                id: data.name,
                name: data.name,
            };
        });
    };

    const openServiceManagerSideCard = (open: boolean): void => {
        setOpenServiceManagerDetailSelectDialog(open);
    };

    //**** Calling filter APIs ****//
    useEffect(() => {
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        dispatch(Thunks.Registration.countryCodeRequest());
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        dispatch(Thunks.UserManagement.getServiceManagersList(filterCountry, filterStatus, offset.toString(), rowsPerPage.toString(), searchText));
        // eslint-disable-next-line
    }, [userManagement.addServiceManagerSuccess]);

    //***** Populating country filter data ***//
    useEffect(() => {
        if (!countryListLoading && countryListSuccess) {
            setCountry(countryListData(registration.countryList));
            setCountryLoaded(true);
        } else if (!countryListLoading && countryListFail) {
            setCountry([]);
            setCountryId(DEFAULT_ID);
        }
    }, [countryListLoading, countryListSuccess, countryListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (filterStatus !== '' || filterCountry !== '') {
            dispatch(Thunks.UserManagement.getServiceManagersList(filterCountry, filterStatus, '1', rowsPerPage.toString(), searchText));
            setCurrentPage(0);
        } else {
            dispatch(Thunks.UserManagement.getServiceManagersList(filterCountry, filterStatus, offset.toString(), rowsPerPage.toString(), searchText));
            const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
            setCurrentPage(parseInt(currentPageAfterSearchClear));
        }
        // eslint-disable-next-line
    }, [filterCountry, filterStatus, rowsPerPage, offset]);

    useEffect(() => {
        if (!userManagement.serviceManagerListLoading && userManagement.serviceManagerListSuccess) {
            setEmptyServiceManagersList(userManagement.serviceManagerList.detail.length <= 0)
            if (userManagement.serviceManagerList.detail.length > 0) {
                const serviceManagersList = getServiceManagersList(userManagement.serviceManagerList.detail);
                setServiceManagersList(serviceManagersList);
                setTotalRecords(userManagement.serviceManagerList.totalRecordCount)
            } else {
                setServiceManagersList([]);
            }
            setServiceManagersDataLoaded(true);
        }
    }, [userManagement.serviceManagerListLoading, userManagement.serviceManagerListSuccess, userManagement.serviceManagerListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const onOkay = (): void => {
        setDisplayRemoveServiceManagerSuccessMessage(false);

    }

    React.useEffect(() => {
        if (!userManagementState.removeServiceUserLoading && userManagementState.removeServiceUserSuccess) {
            setDisplayRemoveServiceManagerSuccessMessage(true);
            setRemoveServiceManagerSuccessMessage(t('TOAST_MSG.REMOVE_SERVICE_MANAGER_SUCCESS'));
            setOpenServiceManagerDetailSelectDialog(false);
            dispatch(Thunks.UserManagement.getServiceManagersList(filterCountry, filterStatus, '1', rowsPerPage.toString(), searchText));
        }
        else if (!userManagementState.removeServiceUserLoading && userManagementState.removeServiceUserFail) {
            handleRemoveUserResponse();
            setDisplayRemoveServiceManagerErrorMessage(true);
        }
        dispatch(usermanagementActions.removeServiceUserUnmounted());

    }, [userManagementState.removeServiceUserLoading, userManagementState.removeServiceUserSuccess, userManagementState.removeServiceUserFail]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleRemoveUserResponse = (): void => {
        if (!userManagementState.removeServiceUserLoading && userManagementState.removeServiceUserFail) {
            if (userManagementState.removeServiceUserErrorCode === '2007')
                setRemoveServiceManagerErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_2007'));
            else if (userManagementState.removeServiceUserErrorCode === '9131')
                setRemoveServiceManagerErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9131'));
            else if (userManagementState.removeServiceUserErrorCode === '9001')
                setRemoveServiceManagerErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9001'));
            else if (userManagementState.removeServiceUserErrorCode === '3060')
                setRemoveServiceManagerErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_3060'));
            else if (userManagementState.removeServiceUserErrorCode === '9238')
                setRemoveServiceManagerErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9238'));
            else if (userManagementState.removeServiceUserErrorCode === '9235')
                setRemoveServiceManagerErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9235'));
            else if (userManagementState.removeServiceUserErrorCode === '9239')
                setRemoveServiceManagerErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9239'));
            else if (userManagementState.removeServiceUserErrorCode === '9236')
                setRemoveServiceManagerErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9236'));
            else if (userManagementState.removeServiceUserErrorCode === '9237')
                setRemoveServiceManagerErrorMessage(t('TOAST_MSG.REMOVE_SERVICE_USER_ERROR_CODE_9237'));
            else
                setRemoveServiceManagerErrorMessage(t('REGISTRATION.STEPS_ERROR_MESSAGE.GENERIC_ERROR_MESSAGE'));
        }

    };


    useEffect(() => {
        if (serviceManagersDataLoaded) {
            /* Debouncing to override user typing */
            const delayDebounceFn = setTimeout(() => {
                if (searchText !== '') {
                    dispatch(Thunks.UserManagement.getServiceManagersList(filterCountry, filterStatus, '1', rowsPerPage.toString(), searchText));
                    setCurrentPage(0);
                } else {
                    dispatch(Thunks.UserManagement.getServiceManagersList(filterCountry, filterStatus, offset.toString(), rowsPerPage.toString(), searchText));
                    const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
                    setCurrentPage(parseInt(currentPageAfterSearchClear));
                }
            }, 3000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        openServiceManagerDetailSelectDialog === false ? setSelectedserviceManager('') : ''
    }, [openServiceManagerDetailSelectDialog]);

    //**** Search text ***//
    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setSearchText(e.target.value);
    };

    const resetSearch = (): void => {
        setSearchText(DEFAULT_ID);
        setSelectedserviceManager(DEFAULT_ID);
    };

    //*** Sorting ***//
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const sortedValue = stableSort(serviceManagersList, getComparator(order, orderBy));
        setServiceManagersList(sortedValue);
    };

    //*** Pagination ***//
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, newPage.toString());
        setOffset(newPage + 1);
        setSelectedserviceManager(DEFAULT_ID);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        setCurrentPage(0);
        setOffset(1);
        setSelectedserviceManager(DEFAULT_ID);
        setOpenServiceManagerDetailSelectDialog(false);
    };

    const handleDisplay = (value: {
        ServiceManagerData: ServiceManagerListItems;
    }): void => {
        setSelectedserviceManager(value.ServiceManagerData.id);
    };

    function onSuccess(state: boolean): void {
        setAlertSuccessMessage(t('USERS.ADD_SERVICE_MANAGER_SUCCESS_200'));
        setDisplaySuccessMessage(true);
        setOpenServiceManagerDialog(false);
    }
    const onCancel = () => {
        setOpenServiceManagerDialog(false);
    }

    return (
        <div id="users-service-manager-container" data-testid="users-service-manager-container">
            <div data-testid="users-service-manager-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    id="users-service-manager-add-user"
                    data-testid="users-service-manager-add-user"
                    style={{
                        flexDirection: 'row',
                        backgroundColor: PXBColors.white[50],
                        textTransform: 'none',
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={() => { setOpenServiceManagerDialog(true) }}
                >
                    {md ? t('USERS.ADD_USER') : <MuiIcons.Add></MuiIcons.Add>}
                </Button>
                <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto', marginRight: (openServiceManagerDetailSelectDialog) ? '57%' : '' }}>
                </div>
            </div>
            <div data-testid="users-service-manager-search" style={{ display: 'flex', }}>
                <DropDownListComponent disabled={true} data-testid="users-service-manager-company-filter" list={deviceTypeEnum} value={t('SEARCH.ALL_COMPANY')} handleSelection={setFilteredCompnay} selected={compnayId} defaultSelection={''}></DropDownListComponent>
                <DropDownListComponent disabled={false} data-testid="users-service-manager-country-filter" list={country} value={t('SEARCH.ALL_COUNTRY')} handleSelection={setFilteredCountry} selected={countryId} defaultSelection={''}></DropDownListComponent>
                <DropDownListComponent disabled={false} data-testid="users-service-manager-status-filter" list={technicianStatusEnum} value={t('SEARCH.ALL_STATUSES')} handleSelection={setFilteredStatus} selected={statusId} defaultSelection={''}></DropDownListComponent>
                <TextField
                    inputProps={{ 'data-testid': 'search', maxLength: 100 }}
                    value={searchText}
                    style={{
                        width: 'auto',
                        textAlign: 'right',
                        float: 'right',
                        border: '0px solid #727E84',
                        borderRadius: 5,
                        backgroundColor: 'white',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        overflow: 'hidden',
                        marginTop: 28,
                        fontFamily: 'Open sans',
                        fontSize: 14,
                        color: '#727E84',
                        fontStyle: 'normal',
                        fontWeight: 400,
                    }}
                    placeholder={t('ORGANIZATION_MANAGE.SEARCH_BUILDINGS')}
                    onChange={(e): void => onChangeSearchText(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search data-testid="search-icon" style={{ color: PXBColors.gray[500] }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchText !== '' && (
                                    <IconButton onClick={resetSearch}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>

            <Card style={{ flex: '1 1 0px', overflow: 'auto', marginTop: '2%', width: (openServiceManagerDetailSelectDialog) ? '77.5%' : '100%' }} id="users-service-manager-list-container" data-testid="users-service-manager-list-container">
                <Table stickyHeader size="small"
                    id="users-service-manager-list-table"
                    data-testid="users-service-manager-list-table" style={{ width: '100%' }}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />
                    {!emptyServiceManagersList && !userManagement.serviceManagerListLoading && <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable'}
                        id="users-service-manager-list-table-body"
                        data-testid="users-service-manager-list-table-body">
                        {stableSort(serviceManagersList, getComparator(order, orderBy)).map((row: TechnicianListItems, index: number) => (
                            <ServiceManagerListItem
                                key={row.id}
                                row={row}
                                selectedTechnician={selectedserviceManager}
                                displayClick={handleDisplay}
                                onSuccess={(state: boolean) => setOpenServiceManagerDetailSelectDialog(state)}
                            ></ServiceManagerListItem>
                        ))}
                    </TableBody>}
                </Table>

                {userManagement.serviceManagerListLoading && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!userManagement.serviceManagerListLoading && emptyServiceManagersList && <EmptyState style={{ flex: 1, height: 200 }}
                    title={t('USERS.ERROR_NO_SERVICE_MANAGER_FOUND')} icon={undefined} placeholder={undefined} />}

                <div style={{ flex: '1 1 0px', backgroundColor: PXBColors.white[50] }}>
                    {!emptyServiceManagersList && <TablePagination
                        component="div"
                        data-testid="pagination-users-service-manager"
                        count={totalRecords}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={t('LABELS.ITEMS_PER_PAGE')}
                        showFirstButton showLastButton
                    />}
                </div>
            </Card>
            {openServiceManagerDialog &&
                <AddServiceManagerDialog openDialogFlag={openServiceManagerDialog} closeClick={() => setOpenServiceManagerDialog(false)} onSuccess={(state: boolean) => onSuccess(state)} ></AddServiceManagerDialog>
            }
            {openServiceManagerDetailSelectDialog && <Box
                display="flex"
                style={{
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    top: 112,
                    right: 0,
                    zIndex: 100,
                    minHeight: '100%',
                    backgroundColor: PXBColors.white[500],
                    position: 'absolute',
                    visibility: openServiceManagerDetailSelectDialog ? 'visible' : 'hidden',
                }}
                data-testid="gateway-details"
                sx={{
                    maxWidth: '30%'
                }}
            >
                <UserDetailsCard userType={USER_ROLE_TYPE.SERVICE_MANAGER} selectedId={selectedserviceManager} open={openServiceManagerDialog} onSuccess={(state: boolean) => setOpenServiceManagerDetailSelectDialog(state)} ></UserDetailsCard>
            </Box>}

            {
                displaySuccessMessage &&
                <Snackbar
                    open={true}
                    onClick={() => setDisplaySuccessMessage(false)}
                    autoHideDuration={20}
                    title={''}
                    message={alertSuccessMessage}
                    action={
                        <Button color="inherit" size="small" onClick={onCancel}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.black[900] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }
            {
                displayRemoveServiceManagerSuccessMessage &&
                <Snackbar
                    open={true}
                    onClick={onOkay}
                    autoHideDuration={20}
                    title={''}
                    message={removeServiceManagerSuccessMessage}
                    action={
                        <Button color="inherit" size="small" onClick={onOkay}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.black[900] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

            {
                displayRemoveServiceManagerErrorMessage &&
                <Snackbar
                    open={true}
                    onClick={() => setDisplayRemoveServiceManagerErrorMessage(false)}
                    autoHideDuration={20}
                    title={''}
                    message={removeServiceManagerErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayRemoveServiceManagerErrorMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}

                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }
        </div>
    );
};
