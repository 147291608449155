/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line
import React, { useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { IDLE_TIMER_INACTIVE, IDLE_TIMER_LOGOUT } from '../../constants';
import { Selectors } from '../../selectors';
import { Thunks, Actions } from '../../actions';
import { ConfirmationPopUp } from '../ConfirmationPopUp/ConfirmationPopUp';
import { Typography } from '@mui/material';
import { TimerLeft } from '../TimerLeft/TimerLeft';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useAuthHelper, useAuthState, getUserId } from '../AuthContextProvider';
import { useHistory } from 'react-router';
import * as PXBColors from '@brightlayer-ui/colors';

export const IdleTimerContainer: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const authHelper = useAuthHelper();
    const history = useHistory();
    const authState = useAuthState();
    const userId = getUserId(authState);
    const idleTimerSetting = useSelector(Selectors.User.session);
    const [timeout, settimeout] = useState(IDLE_TIMER_INACTIVE);
    const [showModal, setshowModal] = useState(false);
    const isTimeout = useState(false);
    const setIsTimeOut = isTimeout[1];
    let idleTimer = null;
    const idleTimerFlag = idleTimerSetting === 'TRUE' ? true : false;

    const logoutSession = React.useCallback(() => {
        setshowModal(false);
        dispatch(Actions.Dashboard.inActiveLogOut(true));
        dispatch(Thunks.Authentication.logout(userId, history, authHelper.onUserNotAuthenticated, authHelper));
    }, [dispatch, history, userId, authHelper.onUserNotAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

    const extend = React.useCallback(() => {
        setshowModal(false);
        setIsTimeOut(false);
        settimeout(IDLE_TIMER_INACTIVE);
    }, [setIsTimeOut]);

    const onAction = (): void => {
        setIsTimeOut(false);
    };

    const onActive = (): void => {
        setIsTimeOut(false);
    };
    const onIdle = (): void => {
        if (!idleTimerFlag) {
            setshowModal(false);
            settimeout(IDLE_TIMER_INACTIVE);
            setIsTimeOut(false);
        } else {
            setshowModal(true);
            settimeout(IDLE_TIMER_INACTIVE);
            setIsTimeOut(true);
        }
    };
    return (
        <>
            {idleTimerFlag && (
                <IdleTimer
                    // @ts-ignore
                    ref={(ref): any => {
                        idleTimer = ref;
                    }}
                    element={document}
                    onActive={onActive}
                    onIdle={onIdle}
                    onAction={onAction}
                    debounce={250}
                    timeout={timeout}
                />
            )}
            {idleTimerFlag && (
                <ConfirmationPopUp
                    icon={<AccessTimeIcon
                        sx={{
                            width: 60,
                            height: 60,
                            color: PXBColors.gray[500]
                        }} />}
                    title={t('INACTIVITY.TITLE')}
                    body={
                        <>
                            <Typography variant="subtitle1" style={{ marginBottom: 50 }}>
                                {t('INACTIVITY.WARNING')}
                                <TimerLeft
                                    minutes={IDLE_TIMER_LOGOUT}
                                    timeout={(): void => {
                                        logoutSession();
                                    }}
                                />
                                {t('INACTIVITY.LOGOUT')}
                            </Typography>
                        </>
                    }
                    cancelButtonText={t('ACTIONS.LOG_OUT')}
                    okButtonText={t('ACTIONS.EXTEND')}
                    show={showModal}
                    closeDialog={(): void => {
                        logoutSession();
                    }}
                    handleOk={(): void => {
                        extend();
                    }}
                    okButtonShow={true}
                    cancelButtonsShow={true}
                    width="xs"
                ></ConfirmationPopUp>
            )}
        </>
    );
};
