// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { LuminaryList, LuminaryDataList, LuminaryData } from '../../types/luminary';
import { EnhancedTableHead } from '../../components/Table/EnhancedTableHead';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { HeadCell, Order } from '../../types/table';
import { getComparator, stableSort } from '../../lib/table-helpers';
import * as PXBColors from '@brightlayer-ui/colors';
import * as MuiIcons from '@mui/icons-material';
import * as MuiCore from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import { LuminaryListItem } from '../../components/LuminaryList/LuminaryListItem';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { EmptyState } from '@brightlayer-ui/react-components';
import { ALL, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { Thunks } from '../../actions';
import {
    Typography,
    Divider,
    Card,
    TableBody,
    ListSubheader,
    Table,
    TablePagination,
    Checkbox,
    SelectChangeEvent,
    Box,
} from '@mui/material';

const ATSDFilterDropdownItems = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31', '32', '33', '34', '35', '36', '37', '38', '39',
    '40'

];

const CircuitFilterDropdownItems = ['1',
    '2',
    '3',
    '4',]
type Props = {
    luminaryId: string;
    luminaryClick: Function;
    deviceId: string;
};

export const DeviceLuminariesList: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('luminaryName');
    const [selectedLuminary, setSelectedLuminary] = React.useState('0');
    const [emptyLuminaryList, setEmptyLuminaryList] = React.useState(false);
    const [openATSDFilter, setOpenATSDFilter] = React.useState(false);
    const [openCircuitFilter, setOpenCircuitFilter] = React.useState(false);


    const asset = useSelector(Selectors.Dashboard.asset);

    const [luminaryList, setLuminaryList] = useState<LuminaryList>([]);
    const [luminaryDataList, setLuminaryDataList] = useState<LuminaryDataList>([]);

    const [totalRecords, setTotalRecords] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 100,
            },
        },
    };

    const [ATSDFilter, setATSDFilter] = React.useState<string[]>([]);
    const [circuitFilter, setCircuitFilter] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (asset.luminaryListLoading) {
            setOpenATSDFilter(false);
            setOpenCircuitFilter(false);
        }
    }, [asset.luminaryListLoading]);


    useEffect(() => {
        if (asset.luminaryListSuccess && !asset.luminaryListLoading && (ATSDFilter.length !== 0 || circuitFilter.length !== 0)) {
            /* Debouncing to override user typing */
            const delayDebounceFn = setTimeout(() => {
                setCurrentPage(0);
                dispatch(Thunks.Asset.getLuminaryList(props.deviceId, '', rowsPerPage.toString(), "1", circuitFilter, ATSDFilter));
            }, 3000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [ATSDFilter, circuitFilter]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleATSDFilterChange = (event: SelectChangeEvent<typeof ATSDFilter>) => {
        const { target: { value }, } = event;
        if (value.includes(t('FILTER.ALL'))) {
            if (ATSDFilter.length === ATSDFilterDropdownItems.length) {
                // If all options are already selected, unselect all.
                setATSDFilter([]);
            } else {
                // If not all options are selected, select all.
                setATSDFilter(ATSDFilterDropdownItems);
            }
        }

        else {
            // On autofill we get a stringified value.
            setATSDFilter(typeof value === 'string' ? value.split(',') : value,);
        }
    };

    const handleCircuitFilterChange = (event: SelectChangeEvent<typeof circuitFilter>) => {
        const { target: { value }, } = event;
        if (value.includes(t('FILTER.ALL'))) {
            if (circuitFilter.length === CircuitFilterDropdownItems.length) {
                // If all options are already selected, unselect all.
                setCircuitFilter([]);
            } else {
                // If not all options are selected, select all.
                setCircuitFilter(CircuitFilterDropdownItems);
            }
        }

        else {
            // On autofill we get a stringified value.
            setCircuitFilter(typeof value === 'string' ? value.split(',') : value,);
        }
    };


    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
        dispatch(Thunks.Asset.getLuminaryList(props.deviceId, '', rowsPerPage.toString(), (newPage + 1).toString(), circuitFilter, ATSDFilter));
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value));
        setCurrentPage(0);
        dispatch(Thunks.Asset.getLuminaryList(props.deviceId, '', parseInt(event.target.value, 10).toString(), "1", circuitFilter, ATSDFilter));
    };


    const headCells: HeadCell[] = [
        { id: 'luminaryName', label: t('DEVICE_DETAILS.LUMINARY_NAME'), hideSorting: false },
        { id: 'shortAddress', label: t('DEVICE_DETAILS.LUMINARY_SHORT_ADDRESS'), hideSorting: false },
        {
            id: 'ATSD',
            label: (
                <>
                    {t('DEVICE_DETAILS.LUMINARY_ATSD')}
                    <MuiCore.FormControl sx={{ m: 1, maxWidth: 100 }}>
                        <MuiCore.Select
                            disabled={asset.luminaryListLoading}
                            open={openATSDFilter}
                            onOpen={() => setOpenATSDFilter(true)}
                            onClose={() => setOpenATSDFilter(false)}
                            disableUnderline

                            variant='standard'
                            labelId="atsd-multiple-checkbox-label"
                            id="atsd-multiple-checkbox"
                            multiple
                            value={ATSDFilter}
                            onChange={handleATSDFilterChange}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 'calc(4 * 50px)', // 4 items * item height
                                        overflow: 'auto',
                                    },
                                },
                            }}
                            onMouseDown={(event) => {
                                if (asset.luminaryListLoading) {
                                    event.preventDefault();
                                } else {
                                    setOpenATSDFilter(true);
                                }
                            }}
                        >
                            <MuiCore.MenuItem key={t('FILTER.ALL')} value={t('FILTER.ALL')} style={{
                                maxWidth: 199,
                                height: 46,
                                paddingLeft: 16,
                                paddingRight: 16,
                                alignItems: 'center',
                            }} >
                                <div style={{ display: 'flex', gap: 24, alignItems: 'center' }}>
                                    <Checkbox checked={ATSDFilter.length === ATSDFilterDropdownItems.length} />
                                    <MuiCore.ListItemText style={{ fontWeight: 400, fontSize: 16 }} primary={t('FILTER.ALL')} />
                                </div>
                            </MuiCore.MenuItem>
                            <Divider />
                            {ATSDFilterDropdownItems.map((name) => (
                                <MuiCore.MenuItem key={name} value={name} style={{
                                    display: 'flex',
                                    maxWidth: 199,
                                    height: 46,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    alignItems: 'center',
                                }}>
                                    <div style={{ display: 'flex', gap: 24, alignItems: 'center' }}>
                                        <Checkbox checked={ATSDFilter.indexOf(name) > -1} />
                                        <MuiCore.ListItemText primary={name} style={{ width: 100, fontWeight: 400, fontSize: 16 }} />
                                    </div>
                                </MuiCore.MenuItem>
                            ))}
                        </MuiCore.Select>
                    </MuiCore.FormControl>
                </>
            ),
            hideSorting: false
        },
        {
            id: 'circuit',
            label: (
                <>
                    {t('DEVICE_DETAILS.LUMINARY_CIRCUIT')}
                    <MuiCore.FormControl sx={{ m: 1, maxWidth: 100 }}>
                        <MuiCore.Select
                            disabled={asset.luminaryListLoading}
                            open={openCircuitFilter}
                            onOpen={() => setOpenCircuitFilter(true)}
                            onClose={() => setOpenCircuitFilter(false)}
                            disableUnderline
                            variant='standard'
                            labelId="circuit-multiple-checkbox-label"
                            id="circuit-multiple-checkbox"
                            multiple
                            value={circuitFilter}
                            onChange={handleCircuitFilterChange}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 'calc(4 * 50px)', // 4 items * item height
                                        overflow: 'auto',
                                    },
                                },
                            }}
                            onMouseDown={(event) => {
                                if (!asset.luminaryListLoading) {
                                    setOpenCircuitFilter(true);
                                }
                                event.stopPropagation();
                            }}

                        >
                            <MuiCore.MenuItem key={t('FILTER.ALL')} value={t('FILTER.ALL')}
                                style={{
                                    maxWidth: 199,
                                    height: 46,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{ display: 'flex', gap: 24, alignItems: 'center' }}>
                                    <Checkbox checked={circuitFilter.length === CircuitFilterDropdownItems.length} />
                                    <MuiCore.ListItemText primary={t('FILTER.ALL')} />
                                </div>
                            </MuiCore.MenuItem>
                            <Divider />
                            {CircuitFilterDropdownItems.map((name) => (
                                <MuiCore.MenuItem key={name} value={name} style={{
                                    display: 'flex',
                                    maxWidth: 199,
                                    height: 46,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    alignItems: 'center',
                                }}>
                                    <div style={{ display: 'flex', gap: 24, alignItems: 'center' }}>
                                        <Checkbox checked={circuitFilter.indexOf(name) > -1} />
                                        <MuiCore.ListItemText primary={name}style={{ width: 100, fontWeight: 400, fontSize: 16 }} />
                                    </div>
                                </MuiCore.MenuItem>
                            ))}
                        </MuiCore.Select>
                    </MuiCore.FormControl>
                </>
            ),

            hideSorting: false
        },
        { id: 'lmStatus', label: t('DEVICE_DETAILS.LUMINARY_STATUS'), hideSorting: false },
    ];

    const getLuminaryDataList = (list: LuminaryList): LuminaryDataList => {
        return list.map((data, luminary) => {
            return (
                {
                    luminaryId: data.luminaryId,
                    luminaryName: data.luminaryName,
                    circuit: data.circuit ? data.circuit : '--',
                    ATSD: data.ATSD,
                    lmStatus: data.lmStatus,
                    shortAddress: data.shortAddress ? data.shortAddress : '--'
                }
            );
        })
    };

    useEffect(() => {
        setSelectedLuminary(props.luminaryId)
    }, [props.luminaryId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.luminaryListLoading && asset.luminaryListSuccess) {
            if (asset.luminaryList.detail) {
                setEmptyLuminaryList(asset.luminaryList.detail.length <= 0)
                setLuminaryList(asset.luminaryList.detail.slice(0, rowsPerPage));
                setLuminaryDataList(getLuminaryDataList(asset.luminaryList.detail.slice(0, rowsPerPage)));
                if (asset.luminaryList.totalRecordCount) {
                    setTotalRecords(asset.luminaryList.totalRecordCount);
                }
            }
        } else if (!asset.luminaryListLoading && asset.luminaryListFail) {
            setEmptyLuminaryList(true);
        }
    }, [asset.luminaryListLoading, asset.luminaryListSuccess, asset.luminaryListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any): void => {
        const isAsc = orderBy === property && order === 'asc';

        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const sortedValue = stableSort(luminaryDataList, getComparator(order, orderBy));
        setLuminaryDataList(sortedValue);
    };

    const handleDisplay = (value: {
        luminaryId: string;
    }): void => {
        setSelectedLuminary(value.luminaryId)
        //*** Might need in furure ***//
        // props.luminaryClick({ luminaryId: value.luminaryId });
    };

    const setSearchText = (searchText: string): void => {
    };

    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }} id="device-luminaries-list-container" data-testid="device-luminaries-list-container">

                <ListSubheader
                    color="primary"
                    component="div"
                    data-testid="luminaries-list-header"
                    id="luminaries-list-header"
                    style={{
                        display: 'flex',
                        fontWeight: 600,
                        fontSize: 14,
                        alignItems: 'center',
                        overflow: 'auto',
                        whiteSpace: 'nowrap',
                        marginLeft: 10,
                        height: 50,
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        style={{
                            alignItems: 'left',
                            color: PXBColors.blue[400],
                            width: '92.3%',
                            paddingLeft: '10',
                        }}
                        id="luminaries-title"
                        data-testid="luminaries-title"
                    >
                        {t('DEVICE_DETAILS.LUMINARIES_COUNTS') + ' (' + totalRecords + ')'}
                    </Typography>

                    <ListItemButton
                        id="luminaries-list-header-search"
                        data-testid="luminaries-list-header-search"
                        style={{
                            width: '7.41%',
                            height: 50,
                            display: 'none'  //TODO- hide serach till API ready
                        }}
                    >
                        <MuiCore.IconButton style={{ alignItems: 'right', width: '10%' }} id="Luminary-search" onClick={(): void => setSearchText('Test')}> <MuiIcons.Search style={{ color: PXBColors.blue[400] }} /> </MuiCore.IconButton>
                    </ListItemButton>
                </ListSubheader>

                <Divider />

                <Table stickyHeader size="small"
                    id="luminaries-list-table"
                    data-testid="luminaries-list-table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />

                    {!asset.luminaryListLoading && !emptyLuminaryList && <TableBody className="tableHover" style={{ minHeight: 400 }} key={'mainTable'}
                        id="luminaries-list-table-body"
                        data-testid="luminaries-list-table-body">
                        {stableSort(luminaryDataList, getComparator(order, orderBy)).map((row: LuminaryData, index: number) => (
                            <LuminaryListItem
                                key={row.luminaryId}
                                row={row}
                                selectedLuminary={selectedLuminary}
                                displayClick={handleDisplay}
                            ></LuminaryListItem>
                        ))}
                    </TableBody>}
                </Table>
                {!emptyLuminaryList && !asset.luminaryListLoading && <TablePagination
                    component="div"
                    data-testid="pagination-lum"
                    SelectProps={{
                        MenuProps: {
                            sx: {
                                '.MuiTablePagination-menuItem': {
                                    display: 'flex',
                                    flexDirection: 'column'
                                },
                            },
                        },
                    }}
                    count={totalRecords}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("LABELS.ITEMS_PER_PAGE")}
                    showFirstButton showLastButton
                />}

                {asset.luminaryListLoading && <EmptyState style={{ flex: 1, minHeight: 150, backgroundColor: PXBColors.white[50] }}
                    icon={<MuiCore.CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!asset.luminaryListLoading && emptyLuminaryList && <EmptyState style={{ flex: 1, height: 200 }}
                    title={t('ORGANIZATION_MANAGE.ERROR_NO_LUMINARY_FOUND')} icon={undefined} placeholder={undefined} />}

            </Card >
        </>
    );
};
