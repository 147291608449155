// eslint-disable-next-line
import React from 'react';
import * as DataProviders from '../../types/data-providers';
import useMounted from '../../hooks/use-mounted';
import _ from 'lodash';

const log = _.noop; //console.log;

export type FetchMoreFunction = () => void;
export type RefreshDataFunction = () => void;
// Outer obj is keyed by a cache-key, inner obj keyed by unique
// identifier within that key. For example,
// { location-data: { '1234': [{ 'name': 'Gary Steelworks' }] } }
type CachedDataState = { [k: string]: { [k: string]: DataProviders.Data[] } };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Paginations = { [k: string]: { [k: string]: NonNullable<any> } };

type ContextState = {
    cacheName: string | undefined;
    cache: CachedDataState;
    pagination: Paginations;
    timers: Array<ReturnType<typeof setInterval>>;
};

type DispatchContextState = {
    requestData: <T, PaginationCursor = unknown>(
        cacheKey: string,
        id: string,
        dataFn: (args?: { paginationCursor?: PaginationCursor }) => Promise<T>,
        opts?: {
            poll?: number;
            pagination?: {
                cursor: PaginationCursor;
                extractNextCursorFromResult: (result: T, prevCursor: PaginationCursor) => PaginationCursor;
            };
        }
    ) => Promise<void>;
    dataLoading: (args: { cacheKey: string; id: string }) => void;
};

const Context = React.createContext<ContextState | null>(null);
const DispatchContext = React.createContext<DispatchContextState | null>(null);

const initialState: ContextState = { cacheName: undefined, cache: {}, pagination: {}, timers: [] };

type Actions =
    | { type: 'DataLoaded'; payload: { cacheKey: string; id: string; value: unknown } }
    | { type: 'MoreDataLoaded'; payload: { cacheKey: string; id: string; value: unknown } }
    | { type: 'DataError'; payload: { cacheKey: string; id: string; errorMessage: string } }
    | { type: 'DataLoading'; payload: { cacheKey: string; id: string } }
    | { type: 'PollingStarted'; payload: { timer: ReturnType<typeof setInterval> } }
    | { type: 'CursorUpdated'; payload: { cacheKey: string; id: string; cursor: NonNullable<unknown> } }
    | { type: 'DataUpdated'; payload: { cacheKey: string; id: string; value: unknown } };

const updateCachedData = (
    state: ContextState,
    cacheKey: string,
    id: string,
    newDataset: DataProviders.Data[]
): ContextState => ({
    ...state,
    cache: {
        ...state.cache,
        [cacheKey]: {
            ...state.cache[cacheKey],
            [id]: newDataset,
        },
    },
});

const updatePagination = (
    state: ContextState,
    cacheKey: string,
    id: string,
    cursor: NonNullable<unknown>
): ContextState => ({
    ...state,
    pagination: {
        ...state.pagination,
        [cacheKey]: {
            ...state.pagination[cacheKey],
            [id]: cursor,
        },
    },
});

const isLastEntryLoading = (entries: DataProviders.Data[]): boolean =>
    entries.length === 0 || DataProviders.isDataLoading(entries[entries.length - 1]);

const replaceLastEntry = (currentDataset: DataProviders.Data[], newData: DataProviders.Data): DataProviders.Data[] => {
    const sliceIndex = isLastEntryLoading(currentDataset) ? currentDataset.length - 1 : currentDataset.length;
    return [...currentDataset.slice(0, sliceIndex), newData];
};

const handleDataChange = (
    state: ContextState,
    cacheKey: string,
    id: string,
    data: DataProviders.Data
): ContextState => {
    const currentDataset = (state.cache[cacheKey] && state.cache[cacheKey][id]) || [];

    return updateCachedData(state, cacheKey, id, replaceLastEntry(currentDataset, data));
};

const reducer = (state: ContextState, action: Actions): ContextState => {
    switch (action.type) {
        case 'DataLoaded': {
            return handleDataChange(
                state,
                action.payload.cacheKey,
                action.payload.id,
                DataProviders.makeLoadedData(action.payload.value)
            );
        }
        case 'DataUpdated': {
            return handleDataChange(
                state,
                action.payload.cacheKey,
                action.payload.id,
                DataProviders.makeLoadedData(action.payload.value)
            );
        }
        case 'DataError': {
            return handleDataChange(
                state,
                action.payload.cacheKey,
                action.payload.id,
                DataProviders.makeErrorData(action.payload.errorMessage)
            );
        }
        case 'DataLoading': {
            return handleDataChange(state, action.payload.cacheKey, action.payload.id, DataProviders.makeLoadingData());
        }
        case 'PollingStarted':
            return {
                ...state,
                timers: [...state.timers, action.payload.timer],
            };
        case 'CursorUpdated':
            return updatePagination(state, action.payload.cacheKey, action.payload.id, action.payload.cursor);
        default:
            return state;
    }
};

const LOADING_DATA: Extract<DataProviders.Data, { state: DataProviders.DataState.Loading }> = {
    state: DataProviders.DataState.Loading,
};
const INITIAL_LOADING_DATASET = [LOADING_DATA];

function useData<T, PaginationCursor = unknown>(args: {
    cacheKey: string;
    id: string;
    dataFn: (args?: { paginationCursor?: PaginationCursor }) => Promise<T>;
    opts?: {
        poll?: number;
        pagination?: {
            cursor: PaginationCursor;
            extractNextCursorFromResult: (result: T, prevCursor: PaginationCursor) => PaginationCursor;
        };
    };
}): {
    dataset: Array<DataProviders.Data<T>>;
    fetchMore: FetchMoreFunction;
    refreshData: RefreshDataFunction;
} {
    const context = React.useContext(Context);

    const dispatchContext = React.useContext(DispatchContext);
    if (dispatchContext === null || context === null) {
        throw new Error('useData must be used within a DataProviderCache');
    }

    const pollingFrequency = args.opts && args.opts.poll ? args.opts.poll : undefined;
    const cacheName = context.cacheName;
    // const fetchMoreLimit = args.opts && args.opts.limit ? args.opts.limit : 50;
    const [initialCursor] = React.useState(args.opts && args.opts.pagination ? args.opts.pagination.cursor : undefined);
    const extractNextCursorFromResult =
        args.opts && args.opts.pagination ? args.opts.pagination.extractNextCursorFromResult : undefined;

    const fetchMore = React.useCallback(() => {
        const nextCursor: PaginationCursor =
            context.pagination[args.cacheKey] && context.pagination[args.cacheKey][args.id];

        if (!nextCursor) {
            console.error('Unable to fetch more data without a pagination cursor.');
            return;
        }

        if (!extractNextCursorFromResult) {
            console.error('Unable to fetch more data without cursor extraction function.');
            return;
        }

        dispatchContext.dataLoading({ cacheKey: args.cacheKey, id: args.id });

        // NOTE: Polling is not handled for fetchMore-able data
        dispatchContext.requestData(args.cacheKey, args.id, args.dataFn, {
            pagination: { cursor: nextCursor, extractNextCursorFromResult },
        }); // No need to await the promise
    }, [dispatchContext, args.cacheKey, args.id, args.dataFn, context.pagination, extractNextCursorFromResult]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (context.cache[args.cacheKey] && context.cache[args.cacheKey][args.id]) {
            return;
        }

        dispatchContext.dataLoading({ cacheKey: args.cacheKey, id: args.id });

        const pagination =
            initialCursor && extractNextCursorFromResult
                ? { cursor: initialCursor, extractNextCursorFromResult }
                : undefined;

        log(`DataProviderCache: [${cacheName}] Request [${args.cacheKey}, ${args.id}]`);
        dispatchContext.requestData(args.cacheKey, args.id, args.dataFn, {
            poll: pollingFrequency,
            pagination,
        }); // No need to await the promise
    }, [
        dispatchContext,
        context.cache,
        args.cacheKey,
        args.id,
        args.dataFn,
        pollingFrequency,
        cacheName,
        initialCursor,
        extractNextCursorFromResult,
    ]); // eslint-disable-line react-hooks/exhaustive-deps
    const refreshData = React.useCallback(() => {
        dispatchContext.dataLoading({ cacheKey: args.cacheKey, id: args.id });

        log(`DataProviderCache: [${cacheName}] Request [${args.cacheKey}, ${args.id}]`);
        dispatchContext.requestData(args.cacheKey, args.id, args.dataFn); // No need to await the promise
    }, [dispatchContext, args.cacheKey, args.id, args.dataFn, cacheName]);

    if (context.cache[args.cacheKey] && context.cache[args.cacheKey][args.id]) {
        log(`DataProviderCache: [${context.cacheName}] Cache-Hit [${args.cacheKey}, ${args.id}]`);

        return {
            dataset: context.cache[args.cacheKey][args.id] as Array<DataProviders.Data<T>>,
            fetchMore,
            refreshData,
        };
    }

    log(`DataProviderCache: [${context.cacheName}] Cache-Miss [${args.cacheKey}, ${args.id}]`);

    // Should only get down here before the request has returned data
    return {
        dataset: INITIAL_LOADING_DATASET,
        fetchMore,
        refreshData,
    };
}

const DataProviderCache: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ for: string }>>> = (props) => {
    const initial = React.useMemo(() => ({ ...initialState, cacheName: props.for }), [props.for]);
    const [state, dispatch] = React.useReducer(reducer, initial);
    const mounted = useMounted();

    log(`DataProviderCache: [${props.for}] Render`);

    React.useEffect(() => {
        log(`DataProviderCache: [${props.for}] Mount`);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(
        () => (): void => {
            // This "cleanup" function will be called whenever the dependencies
            // change or when the component is finally unmounted. We only want
            // to clear the intervals when it's unmounted.

            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (!mounted.current) {
                log(`DataProviderCache: [${props.for}] Unmount`);
                state.timers.forEach((t) => clearInterval(t));
            }
        },
        [state.timers, mounted, props.for]
    );

    const dispatchObj = React.useMemo(() => {
        async function requestDataFn<T, PaginationCursor = unknown>(
            cacheKey: string,
            id: string,
            dataFn: (args?: { paginationCursor?: PaginationCursor }) => Promise<T>,
            opts?: {
                poll?: number;
                pagination?: {
                    cursor: PaginationCursor;
                    extractNextCursorFromResult: (result: T, prevCursor: PaginationCursor) => PaginationCursor;
                };
            }
        ): Promise<void> {
            const paginationCursor = opts && opts.pagination ? opts.pagination.cursor : undefined;

            const requestIt = async (): Promise<void> => {
                try {
                    const value = await dataFn({ paginationCursor });

                    if (mounted.current) {
                        log(`DataProviderCache: [${props.for}] Store [${cacheKey}]`);
                        dispatch({
                            type: 'DataLoaded',
                            payload: {
                                cacheKey,
                                id,
                                value,
                            },
                        });
                        if (paginationCursor) {
                            const nextCursor =
                                opts && opts.pagination
                                    ? opts.pagination.extractNextCursorFromResult(value, paginationCursor)
                                    : undefined;
                            dispatch({
                                type: 'CursorUpdated',
                                payload: {
                                    cacheKey,
                                    id,
                                     // @ts-ignore
                                    cursor: nextCursor,
                                },
                            });
                        }
                    }
                } catch (err) {
                    if (mounted.current) {
                        dispatch({
                            type: 'DataError',
                            payload: {
                                cacheKey,
                                id,
                                 // @ts-ignore
                                errorMessage: err.message,
                            },
                        });
                    }
                }
            };

            await requestIt();

            if (opts && opts.poll && opts.poll > 0) {
                const timer = setInterval(
                    () => {
                        requestIt(); // Not awaiting promise, but it's ok
                    },
                    opts.poll < 1000 ? 1000 : opts.poll
                ); // Anything less than once per second is not OK

                if (mounted.current) {
                    dispatch({
                        type: 'PollingStarted',
                        payload: {
                            timer,
                        },
                    });
                }
            }
        }

        function dataLoadingFn(args: { cacheKey: string; id: string }): void {
            dispatch({
                type: 'DataLoading',
                payload: {
                    cacheKey: args.cacheKey,
                    id: args.id,
                },
            });
        }
        return { requestData: requestDataFn, dataLoading: dataLoadingFn };
    }, [props.for, dispatch, mounted]);

    return (
        <DispatchContext.Provider value={dispatchObj}>
            <Context.Provider value={state}>{props.children}</Context.Provider>
        </DispatchContext.Provider>
    );
};

function dataFromDataset<T>(dataset: Array<DataProviders.Data<T>>): DataProviders.Data<T> {
    const data = dataset[0];
    if (!data) {
        throw new Error('Expected there to be data');
    }
    return data;
}

export { DataProviderCache, useData, dataFromDataset };
