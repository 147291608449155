// eslint-disable-next-line
import React from 'react';
import { OverviewSystemLogs } from '../overview/OverviewSystemLogs';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';


type Props = {
    OnLogSelected: Function;
};
export const DeviceLogsCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();


    const OnLogSelected = (logId: string, logSourceId: string): void => {
        props.OnLogSelected(logId, logSourceId);
    };

    return (
        <div style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid='device-logs-card-container'>
            <OverviewSystemLogs
                OnLogSelected={(logId: string, logSourceId: string): void => {
                    OnLogSelected(logId, logSourceId);
                }}
                userId={''}
                buildingId={''}
                isDashboard={true}
                isLogbook={true}
                defaultOpenState={true}
                title={t('DASHBOARD.LATEST_LOGS')}
                isLatestLogs={true}
            />
        </div>
    );
};
