// eslint-disable-next-line
import * as React from 'react';
import * as PXBColors from '@brightlayer-ui/colors';
import { Typography, IconButton, Divider } from '@mui/material';
import * as MuiIcons from '@material-ui/icons';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { TooltipItem } from '../../components/TooltipItem/TooltipItem';
import { BuildingListItems } from '../../types/building';
import { ListItem, ListItemText } from '@mui/material';
import { DeviceAssetData } from '../../types/device';
import { getDeviceType } from '../../app/enum/ENUM';
import { getControlledText } from '../../app/enum/GenericFormat';

type Props = {
    selectedItem?: string;
    displayClick: Function;
    buildingItem?: BuildingListItems;
    systemItem?: DeviceAssetData;
};


export const ReportsListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();

    const countTitle = props.buildingItem ? (props.buildingItem.gatewayCount + ' ' + t('ASSETS_DETAILS.GATEWAYS') + ', ' + props.buildingItem.systemCount + ' ' + t('ASSETS_DETAILS.SYSTEMS')) :
        (props.systemItem ? (props.systemItem.luminaryCount + ' ' + t('DEVICE_DETAILS.LUMINAIRES')) : '');

    const secondRow = props.systemItem ? (props.systemItem?.buildingName + '/ ' + props.systemItem?.projectName) : props.buildingItem?.projectName;

    const onReportSelected = () => {
        //TO-DO download report details
        if (props.buildingItem)
            props.displayClick(props.buildingItem.name, props.buildingItem.buildingId);
        else if (props.systemItem)
            props.displayClick(props.systemItem.systemName, props.systemItem.systemId, getDeviceType(props.systemItem ? props.systemItem.type : 0));
        else { }
    };

    return (
        <div data-testid="report-id" style={{ backgroundColor: PXBColors.white[50] }} >
            <ListItem
                id="report-list-item"
                style={{ display: 'flex', justifyContent: 'end', height: 72 }} >
                <ListItemText
                    style={{ marginLeft: 10 }} >
                    <Typography data-testid="reports-name"
                        variant="body1"
                        display="inline"
                        id="report-list-item-title"
                        style={{
                            fontWeight: 600,
                            lineHeight: 1.25,
                            fontSize: 16,
                        }}
                    >
                        {props.buildingItem ? props.buildingItem.name : (props.systemItem?.systemName + ', ' + getDeviceType(props.systemItem ? props.systemItem.type : 0))}
                    </Typography>
                    <Typography
                        data-testid="report-list-item-description"
                        id="report-list-item-description"
                        variant="body2"
                        display="block"
                        style={{
                            fontWeight: 400,
                            fontSize: 14,
                            wordWrap: 'break-word'
                        }}
                        sx={{
                            lineHeight: 1.25,
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                        }}
                    >
                        {getControlledText(secondRow ? secondRow : '', 50)}
                    </Typography>
                    <TooltipItem
                        title={countTitle}
                        isTopPlacement={false}
                        lenghtLimit={100}
                        component={
                            <Typography
                                data-testid="report-list-item-count"
                                id="report-list-item-count"
                                variant="body2"
                                display="block"
                                style={{
                                    fontWeight: 300,
                                    fontSize: 14,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    wordWrap: 'break-word'
                                }}
                                sx={{
                                    lineHeight: 1.25,
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                }}
                            >
                                {getControlledText(countTitle, 100)}
                            </Typography>
                        }>
                    </TooltipItem>
                </ListItemText>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: 'auto'
                    }}
                >
                    <IconButton
                        data-testid="report-details"
                        onClick={onReportSelected}
                    >
                        <MuiIcons.ChevronRight id="report-list-item-next-icon" />
                    </IconButton>
                </div>
            </ListItem>
            <Divider style={{ marginLeft: 0 }} />
        </div>
    );
};
