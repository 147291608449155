import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import {  REMOVE_ACCESS } from './url-constants';

type RemoveAccessEndpointSpecifications = ApiEndpointSpecification;
export const RemoveAccessEndpointSpecification: RemoveAccessEndpointSpecifications = {
    url: REMOVE_ACCESS,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'RemoveAccessRequestParams',
    requestBodySchemaName: 'RemoveAccessRequestBody',
    okResponseSchemaName: 'RemoveAccessOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeRemoveAccess = Helpers.makeApiRequestFn<
    Types.RemoveAccessRequestParams,
    Types.RemoveAccessRequestBody,
    Types.RemoveAccessOkResponse
>(RemoveAccessEndpointSpecification);
