import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_COUNTRY_LIST } from './url-constants';

type UserRegistrationRequestCountryListEndpointSpecifications = ApiEndpointSpecification;
export const UserRegistrationRequestCountryListEndpointSpecification: UserRegistrationRequestCountryListEndpointSpecifications = {
    url: GET_COUNTRY_LIST,
    method: HttpMethod.GET,
    requestParamsSchemaName: 'UserRegistrationCountryListRequestParams',
    requestBodySchemaName: 'UserRegistrationCountryListRequestBody',
    okResponseSchemaName: 'UserRegistrationCountryListOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeUserRegistrationRequestCountryList = Helpers.makeApiRequestFn<
    Types.UserRegistrationCountryListRequestParams,
    Types.UserRegistrationCountryListRequestBody,
    Types.UserRegistrationCountryListOkResponse
>(UserRegistrationRequestCountryListEndpointSpecification);
