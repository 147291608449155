import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_CUSTOMER_LIST } from './url-constants';

type CustomerListV2EndpointSpecifications = ApiEndpointSpecification;
export const CustomerListV2EndpointSpecification: CustomerListV2EndpointSpecifications = {
    url: GET_CUSTOMER_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'CustomerListV2RequestParams',
    requestBodySchemaName: 'CustomerListV2RequestBody',
    okResponseSchemaName: 'CustomerListV2OkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeCustomerListV2 = Helpers.makeApiRequestFn<
    Types.CustomerListV2RequestParams,
    Types.CustomerListV2RequestBody,
    Types.CustomerListV2OkResponse
>(CustomerListV2EndpointSpecification);
