import { ActionType } from '../../types';
import { CustomerDashboardList, CustomerV2List } from '../../types/customer-detail';


export const CustomerActions = {

    dashboardCustomerListDataStarted: () =>
    ({
        type: 'Customer/dashboardCustomerListData/Started',
    } as const),

    dashboardCustomerListDataSucceeded: (customerList: CustomerDashboardList) =>
    ({
        type: 'Customer/dashboardCustomerListData/Succeeded',
        payload: { customerList }
    } as const),

    dashboardCustomerListDataFailed: (errorCode: string) =>
    ({
        type: 'Customer/dashboardCustomerListData/Failed',
        payload: { errorCode },
    } as const),

    dashboardCustomerListDataUnmounted: () =>
    ({
        type: 'Customer/dashboardCustomerListData/Unmounted',
    } as const),

    customerListDataStarted: () =>
    ({
        type: 'Customer/customerListData/Started',
    } as const),

    customerListDataSucceeded: (customerList: CustomerV2List) =>
    ({
        type: 'Customer/customerListData/Succeeded',
        payload: { customerList }
    } as const),

    customerListDataFailed: (errorCode: string) =>
    ({
        type: 'Customer/customerListData/Failed',
        payload: { errorCode },
    } as const),

    customerListDataUnmounted: () =>
    ({
        type: 'Customer/customerListData/Unmounted',
    } as const),

    linkCustomerStarted: () =>
    ({
        type: 'Customer/linkCustomer/Started',
    } as const),

    linkCustomerSucceeded: (customerNumber: string) =>
    ({
        type: 'Customer/linkCustomer/Succeeded',
        payload: { customerNumber }
    } as const),

    linkCustomerFailed: (errorCode: string) =>
    ({
        type: 'Customer/linkCustomer/Failed',
        payload: { errorCode },
    } as const),

    linkCustomerUnmounted: () =>
    ({
        type: 'Customer/linkCustomer/Unmounted',
    } as const),
};


export type CustomerAction = ActionType<typeof CustomerActions>;
