import { ActionType } from '../../types/general';
import { OrganizationListData, OrgBuildingList, ServiceOrganizationDetailList } from '../../types/organization';

export const OrganizationActions = {

    orgListStarted: () =>
    ({
        type: 'Organization/orgList/Started',
    } as const),

    orgListSucceeded: (orgList: OrganizationListData) =>
    ({
        type: 'Organization/orgList/Succeeded',
        payload: { orgList }
    } as const),

    orgListFailed: (errorCode: string) =>
    ({
        type: 'Organization/orgList/Failed',
        payload: { errorCode },
    } as const),

    orgListUnmounted: () =>
    ({
        type: 'Organization/orgList/Unmounted',
    } as const),

    buildingListStarted: () =>
    ({
        type: 'Organization/buildingList/Started',
    } as const),

    buildingListSucceeded: (buildingList: OrgBuildingList) =>
    ({
        type: 'Organization/buildingList/Succeeded',
        payload: { buildingList }
    } as const),

    buildingListFailed: (errorCode: string) =>
    ({
        type: 'Organization/buildingList/Failed',
        payload: { errorCode },
    } as const),

    buildingListUnmounted: () =>
    ({
        type: 'Organization/buildingList/Unmounted',
    } as const),


    transferBuildingStarted: () =>
    ({
        type: 'Organization/transferBuilding/Started',
    } as const),

    transferBuildingSucceeded: (message: string) =>
    ({
        type: 'Organization/transferBuilding/Succeeded',
        payload: { message },
    } as const),

    transferBuildingFailed: (errorCode: string) =>
    ({
        type: 'Organization/transferBuilding/Failed',
        payload: { errorCode },
    } as const),

    transferBuildingUnmounted: () =>
    ({
        type: 'Organization/transferBuilding/Unmounted',
    } as const),
    updateOrgStarted: () =>
    ({
        type: 'Organization/updateOrgDetail/Started',
    } as const),

    updateOrgSucceeded: (message: string) =>
    ({
        type: 'Organization/updateOrgDetail/Succeeded',
        payload: { message },
    } as const),

    updateOrgFailed: (errorCode: string) =>
    ({
        type: 'Organization/updateOrgDetail/Failed',
        payload: { errorCode },
    } as const),
    updateOrgUnmounted: () =>
    ({
        type: 'Organization/updateOrgDetail/Unmounted',
    } as const),
    upsertBuildingStarted: () =>
    ({
        type: 'Organization/upsertBuildingDetail/Started',
    } as const),

    upsertBuildingSucceeded: (message: string) =>
    ({
        type: 'Organization/upsertBuildingDetail/Succeeded',
        payload: { message },
    } as const),

    upsertBuildingFailed: (errorCode: string) =>
    ({
        type: 'Organization/upsertBuildingDetail/Failed',
        payload: { errorCode },
    } as const),
    upsertBuildingUnmounted: () =>
    ({
        type: 'Organization/upsertBuildingDetail/Unmounted',
    } as const),

    markasFavStarted: () =>
    ({
        type: 'Organization/markAsFav/Started',
    } as const),

    markasFavSucceeded: (code: boolean) =>
    ({
        type: 'Organization/markAsFav/Succeeded',
        payload: { code },
    } as const),

    markasFavFailed: (errorCode: string) =>
    ({
        type: 'Organization/markAsFav/Failed',
        payload: { errorCode },
    } as const),

    markasFavUnmounted: () =>
    ({
        type: 'Organization/markAsFav/Unmounted',
    } as const),

    removeMarkAsFavStarted: () =>
    ({
        type: 'Organization/removeMarkAsFav/Started',
    } as const),

    removeMarkAsFavSucceeded: (code: boolean) =>
    ({
        type: 'Organization/removeMarkAsFav/Succeeded',
        payload: { code },
    } as const),

    removeMarkAsFavFailed: (errorCode: string) =>
    ({
        type: 'Organization/removeMarkAsFav/Failed',
        payload: { errorCode },
    } as const),

    removeMarkAsFavUnmounted: () =>
    ({
        type: 'Organization/removeMarkAsFav/Unmounted',
    } as const),

    mergeBuildingAssetStarted: () =>
    ({
        type: 'Organization/mergeBuildingAsset/Started',
    } as const),

    mergeBuildingAssetSucceeded: (message: string) =>
    ({
        type: 'Organization/mergeBuildingAsset/Succeeded',
        payload: { message },
    } as const),

    mergeBuildingAssetFailed: (errorCode: string) =>
    ({
        type: 'Organization/mergeBuildingAsset/Failed',
        payload: { errorCode },
    } as const),

    mergeBuildingAssetUnmounted: () =>
    ({
        type: 'Organization/mergeBuildingAsset/Unmounted',
    } as const),

    //SM
    serviceManagerOrgListStarted: () =>
    ({
        type: 'Organization/serviceManagerOrgList/Started',
    } as const),

    serviceManagerOrgListSucceeded: (serviceManagerOrgList: ServiceOrganizationDetailList) =>
    ({
        type: 'Organization/serviceManagerOrgList/Succeeded',
        payload: { serviceManagerOrgList }
    } as const),

    serviceManagerOrgListFailed: (errorCode: string) =>
    ({
        type: 'Organization/serviceManagerOrgList/Failed',
        payload: { errorCode },
    } as const),

    serviceManagerOrgListUnmounted: () =>
    ({
        type: 'Organization/serviceManagerOrgList/Unmounted',
    } as const),


} as const;
// eslint-disable-next-line
export type OrganizationAction = ActionType<typeof OrganizationActions>;
