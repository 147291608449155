// eslint-disable-next-line
import React from 'react';
import { Chip as MuiChip, ChipProps as MuiChipProps, Typography, useTheme } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';


type ChipProps = MuiChipProps & {
    foregroundColor: string;
    backgroundColor: string;
    label: string;
    rightIcon: React.ReactElement;
    highlight: boolean;
};

export const Chip = (props: ChipProps): JSX.Element => {
    const { foregroundColor, backgroundColor, label, rightIcon, highlight, ...chipProps } = props;
    const theme = useTheme();
    
    return (
        <MuiChip
            {...chipProps}
            sx={{
                root: {
                    userMenuChip: {
                        height: theme.spacing(4),
                        cursor: 'pointer',
                    }
                },
                icon: {
                    chipIcon: {
                        height: theme.spacing(3),
                        width: theme.spacing(3),
                    },
                },
                outlined: highlight ? { backgroundColor: PXBColors.white[500] } : { backgroundColor: PXBColors.white[50] },
            }}
            label={
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: theme.spacing(-1),

                    }}>
                    <Typography style={{ color: foregroundColor }} variant={'body2'}
                        sx={{
                            marginRight: theme.spacing(1)
                        }}
                    >
                        {label}
                    </Typography>
                    <div style={{ color: 'white' }}>{rightIcon}</div>
                </div>
            }
            clickable={true}
        />
    );
};
