import { Order } from '../types/table';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): any {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
// eslint-disable-next-line
export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key     // eslint-disable-next-line
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b): 1 | -1 | 0 => descendingComparator(a, b, orderBy)
        : (a, b): any => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): any {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
