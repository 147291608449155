import { REMOVE_MARK_AS_FAV } from "../../api/endpoints/url-constants";

const resources = {
    translation: {
        COMMON: {
            LAST_RETRIEVED: 'Last retrieved: -- min ago',
            S: 's',
        },
        ACTIONS: {
            FINISH: 'Finish',
            NEXT: 'Next',
            BACK: 'Back',
            OKAY: 'Okay',
            SEND: 'Send',
            DONE: 'Done',
            QUIT: 'Quit',
            TRANSFER: 'Transfer',
            CONTINUE: 'Continue',
            LOG_IN: 'Log In',
            LOG_OUT: 'Log Out',
            CLICK_BUTTON: 'Click the button',
            UPDATE_REDUX: 'Click the button to update the redux store value!',
            CHANGE_LANGUAGE: 'Change Language here!',
            GO_HOME: 'Go to Home',
            GO_TO_DASHBOARD: 'Go To My Dashboard',
            GO_TEST: 'Go to Test Page',
            RESEND: 'Resend',
            UPDATE: 'Update',
            REMEMBER: 'Remember Me',
            ENABLE: 'Enable',
            DISABLE: 'Disable',
            CANCEL: 'Cancel',
            DOWNLOAD_DATA: 'Download Data',
            EXTEND: 'Extend',
            DELETE: 'Delete',
            SUBMIT: 'Submit',
            SAVE: 'Save',
            LINK_SYSTEM: 'Link System',
            RETRY: 'Retry',
            EDIT: 'Edit',
            SUBMITCHANGES: 'Submit Changes',
            RESEND_VERIFICATION_EMAIL_LABEL: 'Didn’t receive email?',
            RESEND_VERIFICATION_EMAIL: 'Send Again',
            CONFIRM: 'Confirm',
            SAVE_ADD_ANOTHER: 'Save and add another'
        },
        TOAST_MSG: {
            UPDATE_LOG_COMMENTS_SUCCESS: 'Log comments updated successfully',
            UPDATE_LOG_COMMENTS_API_FAILURE: 'Update log comments api failed',
            ACKNOWLEDGE_LOG_API_FAILURE: 'Acknowlege logs api failed',
            ACKNOWLEDGE_LOG_SUCCESS: 'Device log details acknowledged successfully',
            CUSTOMER_DETAIL_SUCCESS: 'Customer detail retrieved successfully',
            TOAST_ERROR_GET_CUSTOMER_INFO: 'Get customer detail api failed',
            TOAST_ERROR_GET_PASS_DETAILS: 'Get PAAS details api failed',
            TOAST_ERROR_MESSAGE: 'Please fill all the details properly before submission',
            TOAST_SUCCESS_UPDATE_USER_DETAILS: 'User updated successfully',
            TOAST_SUCCESS_ORG_UPDATE: 'Organization updated successfully',
            TOAST_SUCCESS_BUILDING_UPDATE: 'Building updated successfully',
            TOAST_SUCCESS_BUILDING_CREATE: 'Building created successfully',
            TOAST_SUCCESS_ORG_CREATE: 'Organization created successfully',
            TOAST_ERROR_BUILDING_UPDATE: 'Building update api failed',
            TOAST_ERROR_UPDATE_USER_DETAILS: 'Update user detail api failed',
            TOAST_ERROR_ORG_UPDATE: 'Organization API failed',
            BUILDING_TRANSFER_SUCCESS: 'Building transfer successfully',
            BUILDING_TRANSFER_FAILURE: 'Transfer building api failed',
            BUILDING_LIST_API_FAILURE: 'Building List api failed',
            DEVICE_LIST_API_FAILURE: 'Device List api failed',
            TOAST_SUCCESS_UPDATE_CUSTOMER_DETAILS: 'Customer updated successfully',
            TOAST_SUCCESS_CUSTOMER_CREATED: 'Customer created successfully',
            TOAST_ERROR_UPDATE_CUSTOMER_DETAILS: 'Update customer detail api failed',
            TOAST_ERROR_UPDATE_PAAS_DETAILS: 'Create PAAS api failed',
            TOAST_ERROR_LINK_CUSTOMER_ERROR_INVALID_SLA: 'SLA Number is not valid or length should be between 6 and 12 characters',
            TOAST_ERROR_LINK_CUSTOMER_ERROR_INVALID_EXPIRY_DATE: 'Expiry date is not in valid format or should be greater than current date',
            TOAST_ERROR_LINK_CUSTOMER_ERROR_INVAILD_TOKEN: 'Unauthorized access',
            TOAST_ERROR_LINK_CUSTOMER_SYSTEM_DETAIL_NOT_AVAILABLE: 'System details not found',
            TOAST_ERROR_LINK_CUSTOMER_INVALID_SYSTEM_ID: 'Please provide valid system Id',
            TOAST_ERROR_LINK_CUSTOMER_INVALID_CUSTOMER_NUMBER: 'Customer Number is not valid or length should be between 8 and 16 characters',
            TOAST_ERROR_LINK_CUSTOMER_CUSTOMER_DETAIL_NOT_AVAILABLE: 'Customer details not found',
            TOAST_ERROR_LINK_CUSTOMER_SLA_ALREADY_AVAILABLE: 'SLA Number already available',
            TOAST_SUCCESS_LINK_CUSTOMER_DIALOG: 'Customer information linked to system successfully',
            TOAST_BUILDING_NAME_ALREADY_EXISTS: 'Building name already exists',
            TOAST_MSG_ERROR_CODE_9122: 'Please provide valid name or length should be less than or equal to 32 characters',
            TOAST_MSG_ERROR_CODE_9069: 'Address  is not valid or length should be less than or equal to 250 characters',
            TOAST_MSG_ERROR_CODE_9070: 'Address 2 is not valid or length should be less than or equal to 250 characters',
            TOAST_MSG_ERROR_CODE_9071: 'Postal Code is not valid or length should be between 4 and 10 characters',
            TOAST_MSG_ERROR_CODE_9072: 'City is not valid or length should  be less than or equal to 50 characters',
            TOAST_MSG_ERROR_CODE_9043: 'Email is not valid or length should  be less than or equal to 75 characters',
            TOAST_MSG_ERROR_CODE_9073: 'Country Code is not valid or length should  be less than or equal to 4 characters',
            TOAST_MSG_ERROR_CODE_9074: 'Phone number is not valid or length should be between 8 and 10 characters',
            TOAST_MSG_ERROR_CODE_9079: 'Contact is not valid or length should  be less than or equal to 75 characters',
            TOAST_MSG_ERROR_CODE_9149: 'Secondary Contact is not valid or length should  be less than or equal to 75 characters',
            TOAST_MSG_ERROR_CODE_9077: 'Secondary Email is not valid or length should  be less than or equal to 75 characters',
            TOAST_MSG_ERROR_CODE_9075: 'Secondary Phone number is not valid or length should be between 8 and 10 characters',
            TOAST_MSG_ERROR_CODE_9076: 'Secondary Country Code is not valid or length should  be less than or equal to 4 characters',
            TOAST_MSG_ERROR_CODE_9078: 'Description is not valid or length should  be less than or equal to 500 characters',
            TOAST_MSG_ERROR_CODE_9086: 'Please provide address, postalcode and city Or latitude and longitude coordinates',
            TOAST_MSG_ERROR_CODE_9082: 'Please provide valid latitude coordinate',
            TOAST_MSG_ERROR_CODE_9083: 'Please provide valid longitude coordinate',
            TOAST_MSG_ERROR_CODE_9068: 'Country is not valid or length should be less than or equal to 50 characters.',
            TOAST_MSG_ERROR_CODE_9150: 'Primary first name is not valid or length should be between 1 and 50 characters',
            TOAST_MSG_ERROR_CODE_9151: 'Primary last name is not valid or length should be between 1 and 50 characters',
            TOAST_MSG_ERROR_CODE_9152: 'Secondary first name is not valid or length should be between 1 and 50 characters',
            TOAST_MSG_ERROR_CODE_9153: 'Secondary last name is not valid or length should be between 1 and 50 characters',
            TOAST_MSG_ERROR_CODE_9121: 'Please provide valid name or length should be less than or equal to 24 characters',
            TOAST_MSG_ERROR_CODE_9100: 'Organization name already exists',
            TOAST_MSG_ERROR_CODE_9146: 'Phone number is invalid or length should be between 8 and 10',
            TOAST_MSG_ERROR_CODE_ORG_9071: 'Postal Code is not valid or length should be between 4 and 10 characters',
            TOAST_MSG_ERROR_CODE_ORG_9075: 'Secondary Phone number is not valid or length should be between 1 and 10 characters',
            TOAST_MSG_ERROR_CODE_ORG_9078: 'Description is not valid or length should  be less than or equal to 255 characters',
            TOAST_MSG_ERROR_CODE_ORG_9147: 'Secondary phone number is invalid or length should be between 8 and 10',
            MARK_AS_FAV_API_SUCCESS:'Added to favorite successfully',
            REMOVE_MARK_AS_FAV_API_SUCCESS:'Removed from favorite successfully',
            MARK_AS_FAV_API_FAILED: 'Mark as fav API failed',
            REMOVE_MARK_AS_FAV_API_FAILED: 'Remove mark as fav API failed',
            INVITE_TECHNICIAN_SUCCESS: 'User Invite Send successfully',
            INVITE_TECHNICIAN_ERROR_CODE_9127: 'EntityId is duplicate or not valid input',
            INVITE_TECHNICIAN_ERROR_CODE_9043: 'Email is not valid or length should be less than or equal to 75 characters',
            INVITE_TECHNICIAN_ERROR_CODE_9136: 'Entity or role type is not valid input or mismatch in entity type and role type',
            INVITE_TECHNICIAN_ERROR_CODE_9130: 'Admin rights required to invite user',
            INVITE_TECHNICIAN_ERROR_CODE_9158: 'Some entities have limited role from invitedby roles',
            INVITE_TECHNICIAN_ERROR_CODE_9159: 'Invite and invited by user should not be same',
            INVITE_TECHNICIAN_ERROR_CODE_9160: 'Some entities have limited role in invite request',
            INVITE_TECHNICIAN_ERROR_CODE_9190: 'User role type is not valid input',
            INVITE_TECHNICIAN_ERROR_CODE_9233: 'Invite user is already technician.',
            INVITE_TECHNICIAN_ERROR_CODE_9224: 'The current cannot send invite to Service manager and PAAS Maanger.',
            INVITE_TECHNICIAN_ERROR_CODE_9223: 'The current user can only send invite to Normal user.',
            INVITE_TECHNICIAN_ERROR_CODE_9234: 'Invite user is already mapped to another platform.',
            INVITE_TECHNICIAN_ERROR_CODE_9142: 'Invite request failed.',
            INVITE_TECHNICIAN_ERROR_CODE_9143: 'Invite request failed.',
            REMOVE_ACCESS_SUCCESS: 'Remove user completed successfully',
            REMOVE_ACCESS_ERROR_CODE_2007: 'Invalid user id, please provide valid GUID',
            REMOVE_ACCESS_ERROR_CODE_3057: 'Invalid entity Id. Please provide valid entity Id',
            REMOVE_ACCESS_ERROR_CODE_9001: 'Unauthorized access',
            REMOVE_ACCESS_ERROR_CODE_3059: 'Invalid entity type. Please provide valid entity type',
            REMOVE_ACCESS_ERROR_CODE_3060: 'User cannot remove self access',
            TOAST_ERROR_LINK_CUSTOMER_9203: 'Invalid SLA TYPE Enum.',
            TOAST_ERROR_LINK_CUSTOMER_9204: 'System already linked to valid SLA',
            TOAST_ERROR_MERGE_ASSET_9048: 'Please provide valid building id.',
            TOAST_ERROR_MERGE_ASSET_9210: 'Please provide valid destination building id.',
            TOAST_ERROR_MERGE_ASSET_9004: 'Requested data not found',
            TOAST_ERROR_MERGE_ASSET_9212: 'Source building and Destination building are not under same Organization',
            TOAST_ERROR_MERGE_ASSET_9211: 'Source and Destination building Ids are same',
            TOAST_ERROR_MERGE_ASSET_9213: 'Admin Rights required to merge assets',
            TOAST_ERROR_MERGE_ASSET_9044: 'User password is not valid or length should be between 8 and 16 characters',
            TOAST_ERROR_MERGE_ASSET_9214: 'No assets to merge',

            CREATE_CUSTOMER_ERROR_CODE_9163: 'Please provide valid name or length should be less than or equal to 24 characters',
            CREATE_CUSTOMER_ERROR_CODE_9068: 'Country is not valid or length should be less than or equal to 50 characters',
            CREATE_CUSTOMER_ERROR_CODE_9069: 'Address  is not valid or length should be less than or equal to 250 characters',
            CREATE_CUSTOMER_ERROR_CODE_9070: 'Address2 is not valid or length should be less than or equal to 250 characters',
            CREATE_CUSTOMER_ERROR_CODE_9071: 'Postal code is not valid or length should be between 4 and 10 characters',
            CREATE_CUSTOMER_ERROR_CODE_9072: 'City is not valid or length should  be less than or equal to 50 characters',
            CREATE_CUSTOMER_ERROR_CODE_9164: 'Company comment is not valid or length should be less than or equal to 255 characters.',
            CREATE_CUSTOMER_ERROR_CODE_9043: 'Email is not valid or length should  be less than or equal to 75 characters',
            CREATE_CUSTOMER_ERROR_CODE_9073: 'Country code is not valid or length should  be less than or equal to 4 characters',
            CREATE_CUSTOMER_ERROR_CODE_9074: 'Phone number is invalid or length should be between 8 and 10.',
            CREATE_CUSTOMER_ERROR_CODE_9167: 'Please provide valid contact type.',
            CREATE_CUSTOMER_ERROR_CODE_9077: 'Secondary email is not valid or length should  be less than or equal to 75 characters',
            CREATE_CUSTOMER_ERROR_CODE_9147: 'Secondary phone number is invalid or length should be between 8 and 10.',
            CREATE_CUSTOMER_ERROR_CODE_9076: 'Secondary country code is not valid or length should  be less than or equal to 4 characters',
            CREATE_CUSTOMER_ERROR_CODE_9001: 'Unauthorized access',
            CREATE_CUSTOMER_ERROR_CODE_9170: 'Company name already available.',
            CREATE_CUSTOMER_ERROR_CODE_9150: 'Primary first name is not valid or length should be between 1 and 50 characters',
            CREATE_CUSTOMER_ERROR_CODE_9151: 'Primary last name is not valid or length should be between 1 and 50 characters',
            CREATE_CUSTOMER_ERROR_CODE_9152: 'Secondary first name is not valid or length should be between 1 and 50 characters',
            CREATE_CUSTOMER_ERROR_CODE_9153: 'Secondary last name is not valid or length should be between 1 and 50 characters',
            CREATE_CUSTOMER_ERROR_CODE_9165: 'Primary contact comment is not valid or length should be less than or equal to 255 characters.',
            CREATE_CUSTOMER_ERROR_CODE_9166: 'Secondary contact comment is not valid or length should be less than or equal to 255 characters.',
            CREATE_CUSTOMER_ERROR_CODE_9172: 'Customer Number is not valid or length should be between 8 and 16 characters.',
            CREATE_CUSTOMER_ERROR_CODE_9173: 'Primary phone2 is invalid or length should be between 8 and 10.',
            CREATE_CUSTOMER_ERROR_CODE_9174: 'Secondary phone2 number is invalid or length should be between 8 and 10.',
            CREATE_CUSTOMER_ERROR_CODE_9175: 'Primary countrycode2 is not valid or length should be less than or equal to 4 characters',
            CREATE_CUSTOMER_ERROR_CODE_9176: 'Secondary countrycode2 is not valid or length should be less than or equal to 4 characters',
            CREATE_CUSTOMER_ERROR_CODE_9040: 'Company country code is not valid',
            CREATE_CUSTOMER_ERROR_CODE_9023: 'Company phone number is not valid or length shoud be between 8 and 10',
            CREATE_CUSTOMER_ERROR_CODE_9000: 'Unauthorized feature access',
            CREATE_CUSTOMER_ERROR_CODE_9225: 'Customer Number already available.',
            INVITE_USER_ERROR_CODE_9127: 'EntityId is duplicate or not valid input',
            INVITE_USER_ERROR_CODE_9043: 'Email is not valid or length should be less than or equal to 75 characters',
            INVITE_USER_ERROR_CODE_9136: 'Entity or role type is not valid input or mismatch in entity type and role type.',
            INVITE_USER_ERROR_CODE_9130: 'Admin rights required to invite user.',
            INVITE_USER_ERROR_CODE_9158: 'Some entities have limited role from invitedby roles.',
            INVITE_USER_ERROR_CODE_9159: 'Invite and invited by user should not be same',
            INVITE_USER_ERROR_CODE_9160: 'Some entities have limited role in invite request.',
            INVITE_USER_ERROR_CODE_9190: 'User role type is not valid input.',
            INVITE_USER_ERROR_CODE_9142: 'User has been invited for the same role.',
            INVITE_USER_ERROR_CODE_9143: 'Cannot degrade the user role.',
            INVITE_USER_ERROR_CODE_9224: 'The current user cannot send invite to Service manager and PAAS Maanger.',
            INVITE_USER_ERROR_CODE_9223: 'The current user can only send invite to Normal user.',
            REMOVE_SERVICE_USER_ERROR_CODE_2007: 'Invalid user id, please provide valid GUID',
            REMOVE_SERVICE_USER_ERROR_CODE_9131: 'UserType is not valid input.',
            REMOVE_SERVICE_USER_ERROR_CODE_9001: 'Unauthorized access',
            REMOVE_SERVICE_USER_ERROR_CODE_3060: 'User cannot remove self access',
            REMOVE_SERVICE_USER_ERROR_CODE_9238: 'Cannot delete, as the deleting user is the last user with this role under platform',
            REMOVE_SERVICE_USER_ERROR_CODE_9235: 'User type is mismatch with user.',
            REMOVE_SERVICE_USER_ERROR_CODE_9239: 'Cannot delete the main service manager',
            REMOVE_SERVICE_USER_ERROR_CODE_9236: 'Cannot delete the user as only the current user have admin access for some entities',
            REMOVE_SERVICE_USER_ERROR_CODE_9237: 'Cannot delete, as the current and deleting user are not in same platform',
            REMOVE_SERVICE_MANAGER_SUCCESS:'Service Manager removed successfully',
            REMOVE_TECHNICIAN_SUCCESS: 'Technician removed successfully',

        },
        LABELS: {
            EMAIL: 'Email Address',
            PASSWORD: 'Password',
            NEW_PASSWORD: 'New Password',
            FORGOT_PASSWORD: 'Forgot your password?',
            LOGGING_IN: 'Logging in',
            SIGN_UP: 'Sign Up',
            NEED_ACCOUNT: 'Need an account?',
            VIEW_ALL_EVENTS: 'View All {{count}} Events',
            TO_BE_IMPLEMENTED: 'To be implemented',
            SHOW: 'Show:',
            GROUP_BY: 'Group by:',
            TIME_RANGE: 'Time Range:',
            PDF: 'PDF',
            EXCEL: 'Excel File',
            CSV: 'CSV',
            ITEMS_PER_PAGE: 'Items per page',
            FILTER_BY: 'Filter By',
            VIEW_DETAILS: "View Details",
            VIEW_BUILDING_DETAILS: "View Building Details",
            VIEW_ALL: 'View All',
            REQUESTED: 'Requested',
            SCHEDULED: 'Scheduled',
            CUSTOM_LOGBOOK_RANGE: 'Custom Logbook Range',
            START_DATE: 'Start Date',
            END_DATE: 'End Date',
        },
        TABS: {
            SUMMARY_VIEW: 'Summary View',
            LIST_VIEW: 'List View',
        },
        FILTER: {
            ALL: 'All',
            ALL_LOG_TYPES: 'All Log Types',
            ACTIVE_LOGS_ONLY: 'Active Logs Only',
            PAST_SIX_MONTHS: 'Past 6 months',
            PAST_TWELVE_MONTHS: 'Past 12 months',
            SINCE_THE_BEGINNING_OF_YEAR: 'Since Beginning of {{currentyear}}',
            CUSTOM: 'Custom...',
        },
        STATUS: {
            TOTAL_SYSTEM: 'Total Systems',
            TOTAL_LUMINAIRES: 'Total Luminaires',

            STATUS_BLOCK: 'Blocked',
            STATUS_READY: 'Ready',
            STATUS_WARNING: 'Warning',
            STATUS_ERROR: 'Error',
            STATUS_OFFLINE: 'Offline',
            STATUS_DT: 'In Test',
            STATUS_EMPTY: 'Empty',
        },
        LOCATIONS: {
            SITES: 'Sites',
        },
        MESSAGES: {
            EMAIL_SENT: 'Email Sent',
            WELCOME: 'Welcome!',
            LOGIN_MESSAGE: "You are 'logged' in",
            CONGRATS: 'Congratulations!',
            CONTACT: 'Contact an Eaton Support Representative',
            ERROR: 'Error!',
            EMAIL_ENTRY_ERROR: 'Please enter a valid email',
            SUCCESS: 'Success',
            FAILURE: 'Failure',
            LOADING: 'Loading...',
        },
        REGISTRATION: {
            EULA: {
                LOADING: 'Loading End User License Agreement...',
                AGREE_TERMS: 'I have read and agree to the Terms & Conditions',
                LANGUAGE: 'Please Select Your Preferred Language',
                LANGUAGE_SUCCESS: 'Language Updated Successfully',
                ENGLISH: 'English',
                DEUTSCH: 'Deutsch',
                FRENCH: 'Français',
            },
            STEPS: {
                CREATE_ACCOUNT: 'Create an Account',
                LICENSE: 'License Agreement',
                VERIFY_EMAIL: 'Verify Email',
                PASSWORD: 'Create Password',
                ACCOUNT_DETAILS: 'Account Details',
                COMPANY_DETAILS: 'Company Details',
                COMPANY_ADDRESS: 'Company Address',
                COMPLETE: 'Account Created!',
                COMPLETE_OTHER_ADOPTER: 'Account Confirmed!',
                FORGOT_PASSWORD: 'Forgot Password',
            },
            STEPS_MESSAGE: {
                CREATE_ACCOUNT_ENTER_EMAIL:
                    'To register for an account for Safety Management Software, enter the required information below. You will need to verify your email address to continue.',
                CREATE_ACCOUNT_VERIFY_EMAIL:
                    'A verification code has been sent to the email address you provided. Click the link or enter the code below to continue. This code is valid for 30 minutes.',
                CREATE_ACCOUNT_USER_DETAILS: 'Enter your details below to complete account creation.',
                CREATE_ACCOUNT_COMPANY_DETAILS:
                    'This helps Eaton provide the service support for your emergency light solution. If you are initiating a project for your client, please put in the information for your own company, not your client’s.',
            },
            STEPS_ERROR_MESSAGE: {
                GENERIC_ERROR_MESSAGE:
                    'Please contact your administrator if the requested operation cannot be completed.',
                USER_ALREADY_EXIST_MESSAGE: 'Already registered user',
                USER_PHONE_NUMBER_INVALID: 'A phone number is invalid',
                USER_EMAIL_INVALID: 'Please provide a valid email address.',
                USER_INVALID_ENTRY: 'Input parameters cannot be null or empty.',
                VERIFICATION_CODE_EXPIRED_MESSAGE:
                    'The code has expired. Please click the button below to request for another verification email.',
            },
            SUCCESS_MESSAGE: 'Your account has been successfully created with the email <2>{{email}}</2>.',
            SUCCESS_MESSAGE_OTHER_ADOPTER: 'Your EL Digital account has successfully synced with the Bright Layer Cloud information associated with the email <2>{{email}}</2>.',
            FAILURE_MESSAGE:
                'We were unable to complete your registration. Press continue below to continue to Eaton ELDigital suite',
        },
        FORGOT_PASSWORD: {
            ERROR: 'Could not reset your password at this time.',
            INSTRUCTIONS:
                'Please enter the account email associated with the account. <br/><br/>' +
                'If this email has an account with Safety Management Software, you will receive a response within <3>one business day</3>.<br/><br/>' +
                'For urgent account issues, please call ',
            RESET_CODE_ERROR: 'There was an error with your reset code. ',
        },
        USER_INVITATION_REGISTRATION: {
            TITLE: 'EL Digital - User Invitation',
            INSTRUCTIONS:
                'If this email has an account with Eaton, you will receive a response within <3>one business day</3>.<br/><br/>' +
                'For urgent account issues, please call ',
            INVITATION_VALIDATION_ERROR: 'There was an error with your invitation code. ',
            ACCEPT: 'Accept',
            DENY: 'Deny',
        },
        USER_INVITATION_MANAGEMENT: {
            TITLE: 'Welcome, {{username}}',
            SUBTITLE: 'You have been invited to the following projects:',
            INSTRUCTIONS: 'Select to either add items to your profile or to decline the invitation to add it to your profile.',
            JOIN: 'Join',
            JOINALL: 'Join All',
            DECLINE_INVITATION: 'Decline Invitation',
            SENTBY: 'Sent by',
            ERROR_MESSAGE: 'User request to update invitation failed',
            SUCCESS_MESSAGE: 'User invitation success {{count}}',
            INVITATION_SUCCESS_MESSAGE: 'Invitation successfully {{inviteStatus}}.',
            SUCCESS_COUNT: '{{updatedInvitationCount}} invitations were {{inviteStatus}} successfully.',
            INVITATION_MESSAGE_UPDATE_COUNT: '{{updatedInvitationCount}} invitations were {{inviteStatus}} successfully, {{rejectedInvitationCount}} invitations were not {{inviteStatus}}.',
            TABLE_HEADER_NAME: 'Name',
            TABLE_HEADER_INVITE_DATE: 'Received',
            TABLE_HEADER_INVITE_BY: 'Sent By',
            TABLE_HEADER_EXPIRED_AT: 'Expiration',
            TABLE_HEADER_DENIED_TITLE: 'Declined by Me ({{count}})',
            TABLE_HEADER_EXPIRED_TITLE: 'Expired Invitations ({{count}})',
            TABLE_HEADER_DENIED_NO_DATA_FOUND: 'There are no denied invitations at this time.',
            TABLE_HEADER_EXPIRED_NO_DATA_FOUND: 'There are no expired invitations at this time.',
            TABLE_HEADER_TOTAL_ACCEPTED_NO_DATA_FOUND: 'There are no accepted invitations at this time.',
            TABLE_HEADER_TOTAL_ACCEPTED_COUNT: 'Total Accepted Invitations ({{count}})',
            INVITE_ACCEPT: 'Accept',
            INVITE_DENY: 'Deny',
            INVITE_ACCEPTED: 'accepted',
            INVITE_ACCEPTED_BUTTON: 'Accepted',
            INVITE_DENIED: 'denied',
            INVITE_REQUEST: 'Request',
            INVITE_PENDING: 'Pending',
            INVITE_HISTORY: 'Invitation History',

            INVITE_ACCEPT_INVITATION: 'Accept invitation',
            INVITE_ACCEPT_INVITATION_DESCRIPTION_1: 'Accepting this invite will add the following item to your profile:',
            INVITE_REQUEST_ACCESS: 'Request Access',
            INVITE_REQUEST_ACCESS_DESCRIPTION_1: 'This invitation has expired and can no longer be added to your profile.',
            INVITE_REQUEST_ACCESS_DESCRIPTION_2: 'Please request access from the admin to add this item to your profile',

            ADMIN_SUBTITLE: 'You have requests to access expired invitations:',
            ADMIN_INSTRUCTIONS: 'Select to either approve or deny their request for access:',
            ADMIN_APPROVE: 'Approve Request',
            ADMIN_DECLINE_INVITATION: 'Deny Request',
            REQUESTEDBY: 'Requested by',
            COMPANY: 'Company',
            YOU: '(You)',
            LEAVE_ORGANIZATION: 'Leave Organization',
            REMOVE_USER: 'Remove User',
            VIEW_ALL_ACCESS_RIGHTS: 'View All Access Rights',
            INVITATION_TEXT: 'They will need an invitation to rejoin.',
            REMOVE_SERVICE_USER_TITLE: 'Remove "{{userName}}" from "{{companyName}}"?'


        },
        LOGIN: {
            INVALID_CREDENTIALS: 'Incorrect Email or Password',
            GENERIC_ERROR: 'Your request could not be processed at this time.',
        },
        FORMS: {
            HELPING_TEXT: 'Fields with an asterisk (*) are required',
            ORGANIZATION_DETAILS_HEADER: 'Organization Details',
            ORGANIZATION_ADDRESS_HEADER: 'Organization Address',
            LOCAL_PRIMARY_CONTACT_INFO: 'Local Primary Contact Information ',
            LOCAL_SECONDARY_CONTACT_INFO: 'Local Secondary Contact Information ',
            LOCAL_PRIMARY_EMAIL: 'Local Primary Contact Email Address',
            LOCAL_SECONDARY_EMAIL: 'Local Secondary Contact Email Address',
            FIRST_NAME: 'First Name',
            LAST_NAME: 'Last Name',
            COMPANY_NAME: 'Company Name',
            COMPANY_ADDRESS: 'Organization Address Line 1',
            USER_ADDRESS_1: 'Company Address Line 1',
            USER_ADDRESS_2: 'Company Address Line 2',
            BUILDING_COMPANY_ADDRESS: 'Address Line 1',
            BUILDING_COMPANY_ADDRESS2: 'Address Line 2(Optional)',
            POSTAL_CODE: 'Postal Code',
            CITY: 'City',
            COUNTRY: 'Country *',
            PHONE_NUMBER: 'Phone Number',
            PASSWORD: 'Password',
            CONFIRM_PASSWORD: 'Confirm Password',
            PASS_MATCH_ERROR: 'Passwords do not match',
            TOGGLE_PASSWORD_VISIBILITY: 'Toggle password visibility',
            RESET_PASSWORD: 'Reset Password',
            VERIFICATION_CODE: 'Verification Code',
            ADDRESS_LINE_2: 'Organization Address Line 2',
            OPTIONAL: ' (Optional)',
            BUILDING_DETAILS: 'Building Details',
            BUILDING_ADDRESS: 'Building Address',
            PRIMARY_CONTACT_INFORMATION: 'Primary Contact Information',
            INVALID_EMAIL_ERROR: 'Please provide valid email address',
            INVALID_PHONE_NUMBER: 'Please provide valid phone number',
            INVALID_POSTAL_CODE: 'Please provide valid postal code',
            INVALID_SLA_NUMBER: 'Please provide valid SLA number',
            INVALID_CUSTOMER_NUMBER: 'Please provide valid customer number',
            INVALID_DETAILS: 'Please provide valid input parameters',
        },
        PASSWORD_REQUIREMENTS: {
            LENGTH: '8-16 Characters',
            NUMBERS: 'One number',
            UPPER: 'One uppercase letter',
            LOWER: 'One lowercase letter',
            SPECIAL: 'One special character',
        },
        PASSWORD_RESET: {
            SUCCESS_MESSAGE: 'Your password was successfully reset.',
            FAILURE_MESSAGE: 'Your password was unable to be reset. Please try again later.',
        },
        USER_SETTINGS: {
            NAME: 'User Name',
            EMAIL: 'Email Address',
            COMPANY_NAME: 'Company Name',
            PHONE_NUMBER: 'Phone Number',
            COMPANY_ADDRESS: 'Company Address',
            COMPANY_PHONE_NUMBER: 'Company Phone Number',
            PHONE_NO_TEXT: 'A mobile number is required in order to receive sms notifications',
            PASSWORD: 'Password',
            ACCOUNT_CREATED_DATE: 'Account Created',
            HEALTH_REPORT_TITLE: 'Health Report',
            HEALTH_REPORT: 'Monthly reporting',
            PRODUCT_UPDATES_TITLE: 'Product Updates',
            ALARMS_TITLE: 'Alarms',
            STATUS_UPDATES_TITLE: 'Status Updates',
            PRODUCT_UPDATES: 'New releases, firmware update available',
            ALARMS: 'FT failed, DT failed, Disconnected, Battery failure, Lamp failure, ATSD failure',
            STATUS_UPDATES: 'Device is in blocking mode, emergency mode, maintenance requested',
            LANGUAGE_DESCRIPTION: 'English - Great Britain',
            TIMEZONE_DESCRIPTION: 'Central European Time (GMT-1:00)',
            DATEFORMAT_DESCRIPTION: 'DD/MM/YYYY',
            UNITS_DESCRIPTION: 'British Imperial Units',
            APP_THEME_DESCRIPTION: 'Operating System Theme',
            EMAIL_NOTIFICATION: 'Email Notifications',
            ENABLED: 'Enabled',
            USER_NAME: 'User Name',
            EDIT_USER_DETAILS: 'User Details',
            ORGANIZATION: 'Organization',
            ORG_ADDRESS: 'Use Organization’s Address',
            ORG_CONTACT_DETAILS: 'Use Organization’s Contact Details',
            ORGANIZATION_NAME: 'Organization Name',
            BUILDING_NAME: 'Building Name',
            ADDRESS: 'Address',
            CHANGE_PASSWORD: 'Change Password',
            DELETE_ACCOUNT: 'Delete Account',
            PREFERENCES_HEADER: 'Preferences',
            NOTIFICATIONS_HEADER: 'Notifications',
            ACCOUNT: 'Account',
            TEXT_NOTIFICATION: 'Text Notifications (SMS)',
            TEXT_NOTIFICATION_BODY:
                'Enabling SMS notifications may incur additional text messaging fees with your cellular service provider. These fees are not included in the Eaton Service Agreement.',
            TEXT_NOTIFICATION_TITLE: 'Enable Text Notifications (SMS)',
            WARNING: 'WARNING',
            LANGUAGE: 'Language',
            TIME_ZONE: 'Time Zone',
            DATE_FORMAT: 'Date Format',
            UNITS: 'Units',
            APPLICATION_THEME_COLOR: 'Application Theme Color',
            AUTOMATIC_LOGOUT: 'Automatic Log Out',
            AUTOMATIC_LOGOUT_TITLE: 'Disable automatic log out',
            AUTOMATIC_LOGOUT_SUBTITLE: ' Disable the automatic session expiration of 15 minutes',
            AUTOMATIC_LOGOUT_BODY:
                'This will override a standard security feature included in the application to prevent access to an abandoned session. Please be aware that user will be responsible for protecting the unauthorized access to this system and should never leave this application open when unattended.',
        },
        COUNTER: 'The value is: {{count}}',
        HEADER: {
            FORGOT_PASSWORD: 'Forgot Password',
        },
        ERROR_MESSAGES: {
            '2002': 'User Registration link is Expired or Already Redeemed.',
            '9003': 'Requested operation cannot be performed, please contact your administrator',
            '9044': 'User password is not valid or length should be between 8 and 16 characters',
            '2018':
                'We are unable to complete your registration. Your Organization has exceeded the number of allotted Users for your subscription. If your Organization requires additional Users, please contact CST Support via email at ',
            GENERIC_ERROR:
                'An unspecified error has occurred. If this issue persists, please contact Eaton Support at  ',
            GENERIC_ERROR_CONTACT_DETAILS: ' or call at ',
        },
        SEARCH: {
            ALL_ORGANIZATIONS: 'All Organizations',
            ALL_BUILDINGS: 'All Buildings',
            ALL_SYSTEMS: 'All Systems',
            ALL_GATEWAYS: 'All Gateways',
            ALL_STATUSES: 'All Statuses',
            ALL_TYPES: 'All Types',
            ALL_SLA: 'All SLAs',
            ALL_PLATFORMS: 'All Platforms',
            ALL_CUSTOMER: 'All Customers',
            ALL_CITY: 'All Cities',
            ALL_ZIPCODE: 'All Zip Codes',
            ALL_COMPANY: 'All Companies',
            ALL_COUNTRY: 'All Countries',
            ALL_ROLES: 'All Roles',
            ALL_SLANUMBERS: 'All SLA Numbers',
        },
        LOGBOOK: {

        },
        LOGBOOK_DETAILS: {
            EDIT: 'Edit',
            ACKNOWLEDGED: 'Acknowledged',
            ACKNOWLEDGE: 'Acknowledge',
            STATUS: 'Status',
            TIMESTAMP: 'Timestamp',
            SYSTEM: 'System',
            DEVICE: 'Device',
            DESCRIPTION: 'Description',
            PREVIOUS_LOG_ITEM: 'Previous Log Item on This System',
            COMMENTS: 'Comments',
            ACKNOWLEDGED_BY: 'Acknowledged by',
            PREVIOUS_ACKNOWLEDGED_BY: 'Acknowledged by: ',
            AT: 'at',
            ACTIVE: 'Active',
            ACTIVE_ACKNOWLEDGED: 'Active . Acknowledged',
            PART_NUMBER: ' PN: ',
            SERIAL_NUMBER: ' SN: ',
            SYSTEM_TYPE: 'Type: ',
            ADD_COMMENTS: '+ Add Comments...',
            LABEL_CLOSED: 'closed',
            EVENT: 'Event',
            LABEL_EVENT_AGO: ' ago',
            LABEL_EVENT_CLOSE: 'Event Closed: ',
            LABEL_EVENT_BEGAN: 'Event Began: ',
            REQUIRES_FIRMWARE_UPDATE: '1 REQUIRES FIRMWARE UPDATE',
            NO_PREVIOUS_LOG: 'No Previous Log',
        },
        CUSTOMER_DETAILS: {
            EDIT_CUSTOMER_INFO: 'Edit Customer Info',
            EDIT_PLATFORM_INFO: 'Edit Platform Info',
            CREATE_CUSTOMER: 'Create Customer',
            ADD_CUSTOMER: 'Add A Customer',
            DELETE_CUSTOMER: 'Delete Customer',
            SLA_DETAILS_HEADER: 'SLA Details',
            SLA_MANAGEMENT_HEADER: 'SLA Management',
            SLA_CUSTOMER_NUMBER: 'SLA Customer Number',
            SLA_NUMBER: 'SLA Number',
            SLA_EXPIRATION_DATE: 'SLA Expiration Date',
            SLA_DETAILS_TEXT: 'Luminaire monitoring included',
            SLA_DETAILS_TEXT_NO: 'No Luminaire monitoring included',
            BUYER_HEADER: 'Buyer',
            TECH_LEAD_HEADER: 'Technical Lead',
            IMAGE_REQUIREMENTS: 'PNG, JPG (100x100px), 2 MB Max.',
            IMAGES: 'Images',
            UPLOAD_AVATAR_LOGO: 'Upload Avatar Logo',
            REMOVE_IMAGE: 'Remove Image',
            CUSTOMER_DETAILS_HEADER: 'Customer Name & Address',
            LUMINARY_INFO: 'Does this contract include luminaire monitoring?',
            PRIMARY_CONTACT_INFO: 'Primary Contact Info',
            SECONDARY_CONTACT_INFO: 'Secondary Contact Info',
            PHONE_NUMBERS_HEADER: 'Primary & Backup Phone Numbers',
            CUSTOMER_NAME: 'Customer Name',
            CUSTOMER_NUMBER: 'Customer Number',
            CUSTOMER_NUMBER_HELPER_TEXT: 'Numbers Only',
            CALENDAR_HELPER_TEXT: 'mm/dd/yyyy',
            LUMINARY_MONITORING_YES: 'Yes',
            LUMINARY_MONITORING_NO: 'No',
            CONTACT_TYPE: 'Contact Type',
            MOBILE_NUMBER: 'Mobile Number',
            OFFICE_NUMBER: 'Office Number',
            CREATE_CUSTOMER_HEADER: 'Edit Customer Info',
            FILE_RESOLUTION_ERROR: 'Selected file does not match our desired resolution',
            FILE_SIZE_ERROR: 'Selected file size exceeds maximum allowed file size',
            BUYER: 'Buyer',
            PROJECT_LEAD: 'Project Lead',
            TECHNICAL_PERSON: 'Technical Person',
            CONTACT_PERSON_ON_SITE: 'Contact Person Onsite',
            SERVICE_MANAGER: 'Service Manager',
            BACK_UP_PHONE: 'Backup Phone',
            CUSTOMER_HEADER_SUBTITLE: 'Customer',
            EDIT_SLA_DETAILS: 'Edit SLA Details',
            INCLUDE_LUM_MONITORING: 'Include luminaire monitoring',
            DELETE_SLA: 'Are you sure do you want to delete SLA',
            CUSTOMER_NAME_LABEL: 'Customer name or number',
            EXPIRES: 'Expires: ',
        },
        ENTITY_TYPE: {
            USER: 'User',
            ORGANIZATION: 'Organization',
            BUILDING: 'Building',
            SYSTEM: 'System',
            DEVICE: 'Device',
            DEVICE_TYPE: 'Device Type',
            LUMINARY: 'Luminary',
            GATEWAY: 'Gateway',
            MASTER_ROLE: 'Master Role',
            ORGANIZATION_STATISTIC_DETAIL: 'Organization Statistical Detail',
            PLATFORM: 'Platform'
        },
        ORGANIZATION_DETAILS: {
            EMAIL_ADDRESS: 'Email Address',
            PHONE_NUMBER: 'Phone Number',
            USERS: 'Users',
            ORGANIZATION: 'Organization',
            ADDRESS: 'Address',
            DELETE_FOREVER: 'Delete Forever',
            EDIT_ORGANIZATION: 'Edit Organization',
            TRANSFER_BUILDINGS: 'Transfer Buildings to Another Organization',
            EDIT_BUILDING: 'Edit Building',
            MERGE_ASSETS: 'Merge Assets To Another Building',
            ORGANIZATION_DETAILS: 'Organization Details',
            PRIMARY_CONTACT: 'Primary Contact',
            SECONDARY_CONTACT: 'Secondary Contact',
            BUILDING_DETAILS: 'Building Details',
            PRIMARY_CONTACT_PHONE_NUMBER: 'Primary Contact Phone Number',
            PRIMARY_CONTACT_EMAIL_ADDRESS: 'Primary Contact Email Address',
            SECONDARY_CONTACT_PHONE_NUMBER: 'Secondary Contact Phone Number',
            SECONDARY_CONTACT_EMAIL_ADDRESS: 'Secondary Contact Email Address',
        },
        ORGANIZATION_MANAGE: {
            MARK_AS_FAV_API_FAILURE_MESSAGE: 'Please remove some of your existing favorites before adding new ones as maximum limit is 10',
            ALL: 'All',
            STARRED_ORGANIZATIONS: 'Starred Organizations',
            SEARCH: 'Search',
            SEARCH_BUILDINGS: 'Search...',
            SEARCH_BUILDINGS_DASHBOARD: 'Search buildings...',
            SEARCH_SYSTEM_DASHBOARD: 'Search systems...',
            CREATE_ORGANIZATION: '+ Create Organization',
            BUILDING_COUNT: ' Building',
            SYSTEM_COUNT: ' System',
            ADMIN_COUNT: ' Organization Admin',
            VIEWER_COUNT: ' Organization Viewer',
            BUILDING_COUNT_S: ' Buildings',
            SYSTEM_COUNT_S: ' Systems',
            ADMIN_COUNT_S: ' Organization Admins',
            VIEWER_COUNT_S: ' Organization Viewers',
            VIEW_ORGANIZATION: 'View Organization',
            ADD_NEW_BUILDING: '+ Add New Building',
            ORGANIZATION_NAME_HELPER: 'Letters, numbers, underscores and dashes only',
            COMMENTS_HELPER: 'Comments to help technicians arrive on site.',
            BUILDINGS_TITLE: 'Buildings',
            SORT_BY_HEADER: 'SORT BY...',
            SORT_TYPE_NAME: 'Alphabet',
            SORT_TYPE_NUMBER_OF_SYSTEMS: 'Number of Systems',
            SORT_TYPE_CITY: 'City',
            QUIT_TITLE: 'Quit editing “{{orgName}}”?',
            QUIT_EDIT_USER_DETAILS: 'Quit editing',
            QUIT_MESSAGE: 'Your unsaved changes would be lost.',
            ERROR_NO_DEVICE_TITLE: 'You have no assets in this organization yet.',
            ERROR_NO_LOGS_TITLE: 'No logs during this period',
            ERROR_NO_LUMINARY_FOUND: 'No luminary found',
            ERROR_NO_BUILDING_FOUND: 'No building found',
            ERROR_NO_REPORT_FOUND: 'No reports found',
            ERROR_NO_SYSTEM_FOUND: 'No system found',
            ERROR_NO_GATEWAY_FOUND: 'No gateway found',
            ERROR_NO_ORG_FOUND: 'No organization found',
            ERROR_NO_USER_FOUND: 'No user found',
            ERROR_NO_DEVICE_DESCRIPTION: 'You will see status information about your emergency lights once you start adding assets (systems) to this organization.',
            ERROR_ADD_NEW_SYSTEM_ACTION: 'Add New Systems',
            ERROR_READ_INSTALLATION_MANUALS_ACTION: 'Read Installation Manuals',
        },
        DASHBOARD: {
            ALL_LOCATIONS: 'All Locations',
            BUILDING_CONTACT_DETAILS_LABEL: 'Building Contact Details',
            MAIN_CONTACT_LABEL: 'Main Building Contact',
            SECONDARY_CONTACT_LABEL: 'Secondary Building Contact',
            EDIT_CONTACT_BUTTON: 'Edit',
            BUILDING_DETAIL_EMAIL: 'Email',
            BUILDING_DETAIL_PHONE: 'Phone',
            CHOOSE_LOCATION: 'Choose Location',
            DEVICES_TITLE: 'Devices',
            ERROR_LOADING_CHANNELS: 'Error Loading Channels',
            ERROR_LOADING_DATA: 'Error Loading Data',
            ERROR_LOADING_DEVICES: 'Error Loading Devices',
            ERROR_LOADING_EVENT: 'Error Loading Event',
            ERROR_LOADING_EVENTS: 'Error Loading Events',
            ERROR_LOADING_LOCATION: 'Error Loading Location',
            ERROR_LOADING_LOCATIONS: 'Error Loading Locations',
            ERROR_LOADING_ORGANIZATION: 'Error Loading Organization',
            ERROR_LOADING_SITE: 'Error Loading Site',
            ERROR_LOADING_SITES: 'Error Loading Sites',
            ERROR_LOADING_TRENDS: 'Error Loading Trends',
            EVENT_NOT_FOUND: 'Event Not Found',
            LOCATION_NOT_FOUND: 'Location Not Found',
            LOCATIONS_TITLE: 'Locations',
            NO_CHANNELS: 'No Channels',
            NO_DATA: 'No Data',
            NO_DEVICES: 'No Devices',
            NO_EVENTS: 'No Alarms',
            NO_LOCATION_SELECTED: 'No Location Selected',
            NO_LOCATIONS: 'No Locations',
            NO_ORGANIZATION_SELECTED: 'No Organization Selected',
            NO_SITES: 'No Sites',
            NO_TRENDS: 'No Trends',
            NO_DATA_POINTS: 'No Data for Date Range Specified',
            NO_CHANNELS_ENABLED: 'No Channels Enabled',
            ORGANIZATION_NOT_FOUND: 'Organization Not Found',
            OVERVIEW_TITLE: 'Dashboard',
            DASHBOARD_TITLE: 'Dashboard',
            DASHBOARD_LOGBOOK_TITLE: 'Logbook',
            DASHBOARD_ASSETS_TITLE: 'Assets',
            DASHBOARD_MANAGE_ORGANIZATION_TITLE: 'My Organizations',
            DASHBOARD_REPORTS_TITLE: 'Reports',

            SITE_NOT_FOUND: 'Site Not Found',
            TIMELINE_TITLE: 'Timeline',
            UNKNOWN_DEVICE_TYPE: 'Unknown',
            ORGANIZATION: 'organizations',
            ORGANIZATION_TITLE: 'ORGANIZATION',
            ALL_ORGANIZATION: 'All Organizations',
            ADD_ORGANIZATION: 'Create Organization',
            CUSTOMER_TITLE: "Customer",
            ALL_CUSTOMER: 'All Customers',
            ADD_CUSTOMER: 'Add Customer',
            EDIT_USER_DETAILS: 'User Details',
            SAVE_USER_DETAILS: 'Save User Details',
            ADD_BUILDING: 'Add Building to ',
            LOCATION: 'locations',
            SITE: 'sites',
            NO_FOUND: 'No {{entity}} found',
            NOT_ACCESS: 'You don’t have access to any {{entity}} yet.',
            CONTACT_MANAGER: 'Contact your account manager or ',
            NEW_SITE: 'to join or create new {{entity}}.',
            NO_USER_DATA: 'No User Data',
            PROJECT: 'PROJECT',
            ALL_BUILDINGS_COUNT: 'All Buildings ({{count}})',
            ALL_DEVICES_COUNT: 'All Systems ({{count}})',
            STATUS_COUNT_READY: 'Ready ({{count}})',
            STATUS_READY: 'Ready',
            STATUS_COUNT_EMPTY: 'Empty ({{count}})',
            STATUS_EMPTY: 'Empty',
            STATUS_COUNT_BLOCKED: 'Blocked ({{count}})',
            STATUS_BLOCKED: 'Blocked',
            STATUS_COUNT_ANOMALY: 'In Anomaly ({{count}})',
            STATUS_ANOMALY: 'In Anomaly',
            STATUS_COUNT_OFFLINE: 'OFFLINE ({{count}})',
            VIEW_LOGBOOK: 'View Logbook',
            LATEST_LOGS: 'Latest Logs',
            USERS_TITLE: 'Users',
            BUILDING_CONTACT: 'Building Contact',
            USERS_ADMIN: 'Admin',
            USERS_VIEWER: 'Viewer',
            USERS_ADMIN_S: 'Admins',
            USERS_VIEWER_S: 'Viewers',
            LAST_90_DAYS: 'Last 90 Days',
            EARLIER: 'Earlier',
            ADMINS_TITLE: 'Admins',
            ADMINS_AND: 'and',
            ADMINS_MORE: 'more',
            ON_GOING_ALERTS: 'On Going Alerts',
            UPCOMING_IN_DAYS: 'Upcoming in 30 days',
            CUSTOMERS: "Customers",
            SERVICE_LEVEL_AGREEMENT: "# of Service Level Agreement",


            AUTO_REDIRECT_BANNER: {
                MESSAGE:
                    "You've been automatically redirected to {{location}} since you have no other Top-level Locations.",
                ADD_LOCATION_BUTTON: 'Add Location',
                DISMISS: 'Dismiss',
            },
            LOCATION_CARD: {
                HEADER: {
                    SUBTITLE: {
                        ALARM: '{{count}} Alarm',
                        ALARM_plural: '{{count}} Alarms',
                        ALARM_noData: '0 Alarms',
                        NORMAL: 'Normal',
                        NO_DATA: '0',
                    },
                    INFO: {
                        SITE: '{{count}} Site',
                        SITE_plural: '{{count}} Sites',
                        SITE_noData: '0 Sites',
                        SEPARATOR: ', ',
                        DEVICE: '{{count}} Device',
                        DEVICE_plural: '{{count}} Devices',
                        DEVICE_noData: '0 Devices',
                    },
                },
                BODY: {
                    ALARMS: '{{count}} Alarm',
                    ALARMS_plural: '{{count}} Alarms',
                    ALARMS_noData: '0 Alarms',

                    EVENTS: '{{count}} Event',
                    EVENTS_plural: '{{count}} Events',
                    EVENTS_noData: '0 Events',
                },
                FOOTER: {
                    VIEW_LOCATION: 'View Location',
                },
            },

            SITE_CARD: {
                HEADER: {
                    SUBTITLE: {
                        NO_DATA: '--',
                    },
                    INFO: {
                        DEVICE: '{{count}} Device',
                        DEVICE_plural: '{{count}} Devices',
                        DEVICE_noData: '0 Devices',
                    },
                },
                BODY: {
                    ALARMS: '{{count}} Alarm',
                    ALARMS_plural: '{{count}} Alarms',
                    ALARMS_noData: '0 Alarms',

                    EVENTS: '{{count}} Event',
                    EVENTS_plural: '{{count}} Events',
                    EVENTS_noData: '0 Events',

                    ONLINE: 'Online',
                    ONLINE_noData: '--',
                    OFFLINE: 'Offline',
                },
                FOOTER: {
                    VIEW_LOCATION: 'View Site',
                },
            },

            SITE_DETAILS: {
                ALL_DETAILS: 'All Details',
                ALL_DEVICES: 'All Devices',
                READING_CARDS: {
                    VIEW_ALL: 'View All',
                    UNKNOWN: 'Unknown',
                    PENDING: 'Pending',
                    ONLINE: 'Online',
                    OFFLINE: 'Offline',
                    FULL: 'Full',
                    ALARM: 'Alarm',
                    BATTERY: 'Battery',
                },
                TRENDS_CARD: {
                    TITLE: 'Trends',
                    TRENDVIEWER: 'Trendviewer',
                    VIEW_ALL: 'View Full Trendviewer',
                    CHANNELS: 'Channels',
                    DAYS: 'Days',
                    DAY: 'Day',
                },
                DEVICES_CARD: {
                    TITLE: 'Devices',
                    VIEW_ALL: 'View All Devices',
                },
                TIMELINE_CARD: {
                    TITLE: 'Timeline',
                    VIEW_ALL_EVENTS: 'View All Events',
                },
            },

            TIMELINE_DETAILS: {
                TIMELINE: 'Timeline',
                FUTURE: 'Future',
                TODAY: 'Today',
                YESTERDAY: 'Yesterday',
                THIS_WEEK: 'This Week',
                LAST_WEEK: 'Last Week',
                EARLIER: 'Earlier',
                ACKNOWLEDGED: 'ACKNOWLEDGED',
                CLOSED: 'CLOSED',
                OPEN: 'ACTIVE',
                INACTIVE: "INACTIVE",
                UNACKNOWLEDGED: 'UNACKNOWLEDGED',
            },
        },
        MAP: {
            SYSTEMS: 'Systems',
            SYSTEM: 'System',
            WARNING: 'WARNING',
            BLOCKED: 'BLOCKED',
            READY: 'READY',
            OFFLINE: 'OFFLINE',
            ERROR: 'ERROR',
            MAP_ENABLE_ON_COUNTRY: 'Select country to enable map',
            MAP_DRAG_MARKER: 'Drag on the map to place the pin',
            MAP_COORDINATES: '{{latitude}} N, {{longitude}} E',
            MAP_COORDINATES_LABEL: 'Coordinates',
            MAP_ADDRESS_DISPLAY: 'Display address on map',
        },
        DEVICE_DETAILS: {
            CUSTOM_LOGBOOK_RANGE: 'Custom Logbook Range',
            START_DATE: 'Start Date',
            END_DATE: 'End Date',
            SORT_BY_OLDEST: 'Sort by Oldest on top',
            SORT_BY_LATEST: 'Sort by Latest on top',
            SHOW: 'Show...',
            PERIODIC_SERVICE: 'Periodic Service',
            REQUESTED_SERVICE: 'Requested Service',
            UNASSIGNED_SERVICE: 'Unassigned Service',
            CUSTOM: ' Custom...',
            SLA_Number: 'SLA Number',
            CUSTOMER: 'Customer',
            NEXT_SERVICE_DUE: 'Next Service Due',
            TECHNICIAN: 'Technician',
            CONNECTED: 'Connected',
            GATEWAY_COUNT: 'Gateway of {{systemCount}} system',
            GATEWAY_COUNT_SYSTEMS: 'Gateway of {{systemCount}} systems',
            GATEWAY_SHOW_ALL: 'Show All',
            NO_CHANNEL_DATA: 'No Channel Data',
            NO_DEVICE_DETAILS: 'No Device Details Data',
            NO_DEVICE_CONNECTION: 'No Device Connection Data',
            DEVICE_DETAILS_ERROR: 'Error Loading Device Details',
            CHANNEL_DATA_ERROR: 'Error Loading Channel Data',
            DEVICE_CONNECTION_ERROR: 'Error Loading Connection Data',
            ERROR_NO_SLA_FOUND: 'No SLA found',
            EMPTY_VALUE: '--',
            STATUS: 'Status',
            DEVICE_MODEL: 'Device Model',
            DEVICE_SERIAL: 'Device Serial',
            LAST_DATA: 'Last Data',
            ONLINE: 'Online',
            OFFLINE: 'Offline',
            READY: 'Ready',
            INACTIVE: "InActive",
            ACTIVE: "Active",
            WARNING: 'Warning',
            BLOCK: 'Blocked',
            ERROR: 'Error',
            EVENT: 'Event',
            CONNECTION_TYPE_MODBUS: 'Modbus',
            CONNECTION_TYPE_RS485: 'RS485',
            CONNECTION_TYPE_ETHERNET: 'Ethernet',
            CONNECTION_TYPE_MQTT: 'MQTT',
            CONNECTION_TYPE_WIREPASS: 'Wirepass',
            CONNECTION_TYPE_BLE: 'BLE',
            CONNECTION_TYPE_WIFI: 'WiFi',
            CONNECTION_TYPE_CELLULAR: 'Cellular',
            NEXT: 'Next',
            PREVIOUS: 'Prev',
            SYSTEM: 'SYSTEM',
            DURATION_TEST: 'Duration Test ',
            FUNCTIONAL_TEST: 'Functional Test',
            DURATION_TEST_PASSED: 'Duration Test Passed',
            DURATION_TEST_FAILED: 'Duration Test Failed',
            FUNCTIONAL_TEST_PASSED: 'Functional Test Passed',
            FUNCTIONAL_TEST_FAILED: 'Functional Test Failed',
            FIRMWARE_VERSION: 'Firmware Version',
            CONNECTION_TYPE: 'Connection Type',
            UPDATE_REQUIRED: 'UPDATE REQUIRED',
            FIRMWARE_AVAILABLE_GUIDE: '{{availableVersion}} Available',
            FIRMWARE_AVAILABLE_GUIDE_LINK: '(Guide)',
            USERS: 'Users',
            USERS_ADMINS: ' admin',
            USERS_VIEWER: ' viewer',
            USERS_COUNTS: '{{adminCount}} admin, {{viewerCount}} viewer',
            TYPE: 'Type',
            PART_NUMBER: 'Part Number',
            SERIAL_NUMBER: 'Serial Number',
            MAC_ADDRESS: 'Mac Address',
            IPADDRESS: 'IP Address',
            SUBNET_MASK: 'Subnet Mask',
            HOSTNAME: 'Host Name',
            DATE_COMMISSIONED: 'Date Commissioned',
            LUMINARY_NAME: 'Name',
            LUMINARY_TYPE: 'Type',
            LUMINARY_SHORT_ADDRESS: 'Address',
            LUMINARY_ATSD: 'ATSD #',
            LUMINARY_CIRCUIT: 'Circuit',
            LUMINARY_CIRCUIT_HASH: 'Circuit #',
            LUMINARY_ADDRESS: 'Luminaire Address',
            LUMINARY_STATUS: 'Status',
            LUMINARY_MODEL: 'Model',
            LUMINARY_DISCRIPTION: 'Description',
            LUMINARIES_COUNTS: 'Luminaires ',
            LUMINARY_DETAIL_INFO: 'Info',
            LUMINARY_DETAIL_TEST: 'Tests',
            GATEWAY_ADDRESS: 'Gateway Address',
            GATEWAY_NAME: 'Gateway Name',
            DURATION_TEST_DURATION: 'Duration Test Duration',
            NEXT_DURATION_TEST: 'Next Duration Test Starts',
            PREVIOUS_DURATION_TEST: 'Previous Duration Test Started',
            NEXT_FUNCTIONAL_TEST: 'Next Functional Test Starts',
            PREVIOUS_FUNCTIONAL_TEST: 'Previous Functional Test Started',
            DELAY_FUNCTIONAL_TEST: 'Functional Test Delay',
            NETWORK_DETAILS: 'Network Details',
            NAME: 'Name',
            ORGANIZATION: 'Organization',
            BUILDING: 'Building',
            SYSTEMS: 'Systems',
            GATEWAYS: 'Gateways',
            LUMINAIRES: 'Luminaires',
            LUMINAIRE: 'Luminaire',
            CITY: 'City',

        },
        ASSETS_DETAILS: {
            BUILDINGS: 'Buildings',
            SYSTEMS: 'Systems',
            SLA: `SLA's`,
            ADD_SLAS: 'Add SLA',
            GATEWAYS: 'Gateways',
            ADD_BUILDINGS: '+ Add Building',
            ADD_SYSTEMS: '+ Add System',
            ADD_SLA: '+ New SLA',
            DOWNLOAD_AS: 'Download As...',
            SCHEDULE_MAINTENANCE: 'Schedule Maintenance',
            SHOW_FAVORITE_BUILDINGS: 'Show Starred Buildings Only',
            SHOW_FAVORITE_GATEWAYS: 'Show Starred Gateways Only',
            SHOW_FAVORITE_SYSTEMS: 'Show Starred Systems Only',
            SHOW_FAVORITE_SLAS: 'Show Starred SLAs Only',
            ADD_BUILDING_ASSET: 'Select a organization to create a building',
            SELECT_ORGANIZATION_LABEL: 'Select organization',
            CUSTOMER_INFO_HEADER: 'Customer Info',
            SLA_SUBTITLE: 'SLA#',
            EXPIRY_DATE: 'Expiry Date',
            MAIN_CONTACT: 'Main Contact',
            NO_CUSTOMER_TEXT: 'This system has not been linked to a customer',
            LINK_TO_CUSTOMER_BUTTON: 'Link to Customer',
            LINK_CUSTOMER_DIALOG_SEARCH: 'Search Customer by Name',
            LINK_TO_CUSTOMER_DIALOG_HEADER: 'Whose system is this?',
            LINK_TO_CUSTOMER_DIALOG_SECOND_HEADER: 'What are the SLA details for this system?',
            CREATE_NEW_CUSTOMER_BUTTON: '+ Create New Customer',
            BUILDING_INFO_HEADER: 'Building Info',
            BUILDING_ADDRESS: 'Address',
            BUILDING_TYPE: 'Building Type',
            ALL_SYSTEMS_IN_BUILDING: 'All Systems in this Building',
            ALL_CONTACTS_IN_BUILDING: 'All People in this Building',
            DOWNLOAD_TOOLTIP: 'Please select building or system',
            TOTAL_SLA: 'Total SLA',
            FOR_SYSTEM: 'For Systems',
            FOR_BUILDING: 'For Buildings'
        },
        MANAGE_USERS: {
            INVITE_USER: 'Invite User',
            ADVANCED_INVITE: 'Advanced Invite',
            ADVANCED_INVITE_SUBTITLE: 'Grant access to specific organizations, buildings, and systems.',
            ADVANCED_INVITE_USER_SELECTION: 'Choose a new or existing user:',
            ADVANCED_INVITE_USER_ORGANIZATION_SELECTION: 'Add them to organizations:',
            ADVANCED_INVITE_PLEASE_ASSIGN_ROLES: 'Please assign roles for {{email}} access to organizations, buildings and systems.',
            ADVANCED_INVITE_ADMIN: 'Admin: ',
            ADVANCED_INVITE_VIEWER: 'Viewer: ',

            ADVANCED_INVITE_LIMITED: 'Limited: ',
            ADVANCED_INVITE_ADMIN_DESCRIPTION: 'grant full access to everything within the assigned level.',
            ADVANCED_INVITE_VIEWER_DESCRIPTION: 'grant viewing privileges to everything within an assigned level.',
            ADVANCED_INVITE_LIMITED_DESCRIPTION: 'grant minimum viewing privileges so that a user only sees basic information for what is critical to their role. This is useful when someone only needs to monitor one system within a building.',

            INVITE_USER_SUBTITLE: 'Grant access to new or existing users',
            INVITE_USER_ADVANCE: 'Advanced...',
            USER_ROLE_ADMIN: 'Admin',
            USER_ROLE_VIEWER: 'Viewer',
            USER_ROLE_LIMITED: 'Limited',
            USERS_IN_HEADER: 'Users in ',
            INVITE_USER_EMAIL_NAME: 'Email *',
            INVITE_USER_EMAIL_USER_NAME: 'Email or user name *',
            INVITE_USER_EMAIL: 'Email *',
            INVITE_USER_SEND_INVITE: 'Send Invite',
            MY_DETAILS_HEADER: 'My Details',
            OTHER_USER_LIST_HEADER: 'Other Users ',
            INVITE_USER_AS_COLLEAGUE: 'Invite as colleague',
            INVITE_USER_AS: 'As',
            INVITE_USER_ORGANIZATION_LABEL: 'Add to organization... *',
            INVITE_USER_ORGANIZATION_SELECT_ALL: 'Select All',
            INVITE_SUCCESS_MESSAGE_SINGLE: 'Invited {{email}} to {{organization}}.',
            INVITE_SUCCESS_MESSAGE_MULTIPLE: 'Invited {{email}} to {{organizationCount}} organizations.',
            INVITATION_FAIL_HEADER: 'Invitation Expired',
            INVITATION_FAIL_BODY: 'This email invitation link has expired. Please contact your system admin to get another email invitation.',
            INVITATION_REQUEST_ACTION: 'Request Another Invitation',
            NOT_APPLICABLE: 'NA',
            REMOVE_USER: 'Remove {{name}} from "{{title}}" ?',
            INVITE_USER_DO_NOT_ASSIGN: 'Do not assign at this time',
        },
        VERIFY_EMAIL: {
            EMAIL_INFO:
                'A verification code has been sent to the email address you provided. Click the link or enter the code below to continue. This code is valid for 30 minutes.',
        },
        CHANGE_PASSWORD: {
            PASSWORD_CHANGED: 'Password Changed',
            PASSWORD: 'Change Password',
            SUCCESS_MESSAGE:
                "Your password was successfully updated! To ensure your account's security, you will need to log in to the application with your updated credentials.",
            EMAIL_CONFIRM_MESSAGE: ' We have sent a confirmation email to {{email}}',
            PASSWORD_INFO:
                'Password must be at least 8 characters long, contain at least one uppercase character, one lowercase character, one number, and one special character.',
            OLD_PASSWORD: 'Old Password',
            ERROR_MESSAGR:
                'Your information did not match our records. Please re- enter your information to try again.',
            PROBLEM_OCCURRED: 'A problem occurred:',
        },
        DRAWER: {
            ELDIGITAL: 'Building Safety Manager',
            SAFETY_MANAGEMENT_SYSTEM: 'Safety Management System',
            EMERGENCY_LIGHT: 'Powered by Brightlayer',
            DASHBOARD: 'Dashboard',
            LOGBOOK: 'Logbook',
            USERS: 'Users',
            ASSETS: 'Assets',
            REPORTS: 'Reports',
            ACCOUNTPREFERENCES: 'Account Preferences',
            ORGSETTINGS: 'My Organizations',
            HELPANDFEEDBACK: 'Help and Feedback',
            OVERVIEW: 'Dashboard',
            TIMELINE: 'Timeline',
            LOCATIONS: 'Locations',
            DEVICES: 'Devices',
            SETTINGS: 'Settings',
            MYPROFILE: 'My Profile',
            MANAGEUSER: 'Manage Users',
            MANAGELOCATION: 'Manage Locations and Sites',
            LEGAL: 'Legal',
            HELP: 'Help',
            COPYRIGHT: 'Copyright © Eaton',
            RIGHTS_RESERVED: 'All Rights Reserved',
            SELECT_ACCOUNT: 'Select Account',
        },
        SETTINGS: {
            TITLE: 'Account Preferences',
            SUBTITLE: 'Admin Level - Users',
        },
        TRANSFER_BUILDINGS: {
            PASSWORD: 'Account Password',
            TRANSFER_TO: 'Transfer To...',
            ACTION: 'Please type your account password to confirm the action.',
            HEADER: 'Permanently transfer all {{buildingCount}} buildings, including all the {{gatewayCount}} gateways and {{systemCount}} systems under it, to another organization. You must be organization admins on both organization to perform the action.',
            ERRORMESSAGE: 'Login limits exceeded. please try again after sometime',
        },
        MERGE_ASSETS: {
            PASSWORD: 'Account Password',
            TRANSFER_TO: 'Transfer To...',
            ACTION: 'Please type your account password to confirm the action.',
            HEADER: 'Transfer all {{gatewayCount}} gateways and {{systemCount}} systems under it, to another building and permanently delete the current one.',
            MERGE_DELETE: 'Merge and Delete',
            SUCCESS: 'Merged building assets  Successfully',
            MERGE_ASSETS_TITLE: 'Merge assets to another building'
        },
        LEGAL: {
            TITLE: 'Legal',
            EULA: 'End User License Agreement',
            TERMSANDCONDITIONS: 'Terms and Conditions',
            OPENSOURCELICENSES: 'Open Source Licenses',
        },
        EVENT_TIMELINE: {
            LOAD_MORE_EVENTS: 'Load {{count}} More Events',
            ERROR_LOADING_MORE_EVENTS: 'Error Loading More Events',
        },
        EVENT_DETAILS: {
            TITLE: 'Event Details',
            DEVICE_MODEL: 'Device Model',
            DEVICE_SERIAL: 'Device Serial',
            UNKNOWN: '--',
            EMPTY_VALUE: '',

            NO_DEVICE_DETAILS: 'No Device Details',
            ERROR_LOADING_DEVICE_DETAILS: 'Error Loading Device Details',

            NO_EVENT_DETAILS: 'No Event Details',
            ERROR_LOADING_EVENT_DETAILS: 'Error Loading Event Details',

            THRESHOLD: 'Threshold',

            TIME_ALARM_SENT: 'Time Initial Alarm Sent',
            ALARM_STATUS: 'Initial Alarm Status',
            SET_POINT: 'Setpoint (Threshold)',
            LAST_DATA_RECEIVED: 'Last Alarm Update Received',

            ACKNOWLEDGE: 'Acknowledge',
            ACKNOWLEDGED: 'Acknowledged',
            SENDING: 'Sending...',
            ACKNOWLEDGE_BY: 'Acknowledged by {{user}} at ',
            PLEASE_RETRY: 'Please retry: ',
            ACKNOWLWEGED_ERROR:
                'Acknowledgment failed to communicate to end device at {{errorDate}}. If this problem continues, please call our support team at {{phoneno}} or email ',
        },
        USER_MENU: {
            LOG_OUT: 'Log Out',
            CONTACT_US: 'Contact Us',
            ACCOUNT_SETTING: 'Account Settings',
        },
        TIMELINE_EXPORT: {
            DOWNLOAD_TITLE: 'Download Timeline Data',
            DOWNLOAD_SUBTITLE_TIMELINE: 'Select the location and date range of alarm history you would like to export.',
            DOWNLOAD_SUBTITLE_SITE: 'Select date range of alarm history you would like to export.',
        },
        INACTIVITY: {
            TITLE: 'Session Timeout',
            WARNING: 'This window has been inactive for 15 minutes. If no action is taken in ',
            LOGOUT: ', you will be logged out.',
            INACTIVE_LOGOUT: 'Logged out due to inactivity',
        },
        UNSUPPORTED_BROWSER: {
            NOT_SUPPORTED: 'Browser not supported',
            INCOMPATIBLE_BROWSER: 'This web application is incompatible with your current browser.',
            RECOMMENDED_BROWSER:
                'We recommended upgarding to the latest version of Microsoft Edge, Safari, Firefox, or Chrome to use this tool.',
        },
        DISCLAMIER: {
            OVERVIEW: 'Alarm Count represents all active alarms',
            TIMELINE: 'Alarms displayed represent all alarms during the past 90 days',
        },
        ADDUSER: {
            TITLE: 'Add User',
            INVITEUSER: ' Invite User',
            ADDUSERINFORMATION: "User's Account Profile Information",
            ASTERIKSINFO: 'Fields marked with asterisk(*) are requried fields necessary to invite a user.',
            SELECTROLE: 'Select a Role for Location(s)',
            INVITESUCCESS: 'Invitation Successful!',
            ACCOUNTCREATED: 'The invitation to create an account has been successfully initiated.',
            EMAILRECEIVE:
                'The user will receive an email invite to create their account in 5-10 minutes. You will not be able to see their status until they have accepted the invite.',
        },
        ADDLOCATION: {
            ADDLOCATIONINFORMATION: 'Location Profile Information',
            ASTERIKSINFO: 'Fields marked with an asterisk(*) are required fields {{entity}}.',
        },
        USERLIST: {
            ALL_LOCATION: 'All Locations',
            COL_USERNAME: 'User Name',
            COL_ROLE: 'Role',
            COL_LOCATION: 'Location',
            COL_EMAIL: 'Email',
            COL_STATUS: 'Status',
            DELETEACCOUNT: 'Delete this Account?',
            DELETECONFIRMATION:
                '{{email}} Account and all its data will be permanently removed. Deleting this account will not affect the Organization, Devices, or any related historical data.',
            DELETE_SUCCESS: 'The User Account for {{email}} has been successfully deleted.',
            ACCOUNT_DELETE: 'Account Deleted',
        },
        USERDETAILS: {
            EDIT_SUCCESS: 'User updated successfully',
            DELETE_USER_POP_UP_HEADER: 'Permanently delete account “{{email}}”?',
            DELETE_USER_SUBHEADER: 'All your user data will be permanently lost.',
            DELETE_USER_BUTTON_TEXT: 'Delete Account Forever',
            RESET_PASSWORD_SUCCESS: 'Reset password email sent successfully.',
            RESET_PASSWORD_ERROR: 'Could not reset your password at this time.',
            DELETE_USER_SUCCESS_MSG: 'Your account has been successfully deleted',
            DELETE_USER_FAILURE_MSG: 'Failed to delete, Please try after some time'

        },
        EDITUSER: {
            PROMOTE: 'You have promoted this User to an Org Admin. If you did not mean to do this - select cancel.',
            PROMOTE_CONFIRMATION: ' If this was intended, please submit your change.',
            DEMOTE: "You have changed this Org Admin's role. If you did not mean to do this - select cancel.",
            DEMOTE_CONFIRMATION:
                'If this was intended, please make modifications below in the Role/Location section of this form.',
        },
        ASSETS: {
            ASSETS_DETAILS: "Assets Details",
        },
        USERS: {
            LOADER_HELPER_TEXT: 'Please wait',
            USERS_TITLE: "Users",
            TECHNICIANS: "Technicians",
            SERVICE_MANAGERS: "Service Managers",
            CUSTOMERS: "Customers",
            SERVICE_PARTNERS: 'Service Partners',
            PAAS: 'PAAS',
            ADD_SLA: "+ Add SLA",
            ADD_CUSTOMER: "+ Add Customer",
            ADD_USER: "+ Add User",
            ADD_PLATFORM: "+ Add Platform",
            ADD_PLATFORM_DIALOG_HEADER: 'Add A Platform',
            EDIT_PLATFORM_HEADER: 'Edit Platform',
            SHOW_FAVORITE_CUSTOMERS: "Show Starred Customers Only",
            SHOW_FAVORITE_TECHNICIANS: "Show Starred Technicians Only",
            SHOW_FAVORITE_SERVICE_MANAGERS: 'Show Starred Service Managers Only',
            SHOW_FAVORITE_SERVICE_PARTNER: 'Show Starred Service Partners Only',
            SHOW_FAVORITE_PLATFORMS: 'Show Starred Platforms Only',
            CUSTOMER: "Customer",
            LOCATION: "Location",
            CREATE_NEW_CUSTOMER: 'Create New Customer',
            CITY: "City",
            CUSTOMER_ID: "Customer ID",
            STATUS: "Status",
            ADD_TECHNICIAN_TITLE: "Add A Technician",
            STEP1: "Step 1",
            STEP1_TEXT: "Choose or add a user and assign them to specific organizations",
            STEP2: "Step 2",
            DEFINE_ACCESS_LEVEL: "Define access levels for",
            DEFINE_ACCESS_LEVEL_NEXT: "organizations, buildings and systems.",
            ADD_TO_PAAS: 'Add to PAAS... *',
            SERVICE_MANAGER_DIALOG_HEADER: ' Choose or add a user and assign them to a region and organizations.',
            REGION_SELECT_HEADER_SERVICE_MANAGER: 'Which PAAS will this person be assigned to?',
            ADD_SERVICE_MANAGER_DIALOG_HEADER: 'Add A Service Manager',
            NAME: 'Name',
            COMPANY: 'Company',
            COUNTRY: 'Country',
            ROLE: 'Role',
            PLATFORM_NAME_HEADER: 'Platform Name',
            SERVICE_PARTNER_HEADER: 'Service Partner',
            SERVICE_PARTNER_LABEL: 'Service Partner Name ',
            PLATFORM_NAME_SUBHEADER: 'What is the name for the region/area for this platform?',
            PLATFORM_HELPER_TEXT: 'Ex., French CSU Team',
            CUSTOMER_NAME_HEADER: 'Customer Name',
            CUSTOMER_NAME_SUBHEADER: 'What is the name of this service partner?',
            COUNTRY_SUBHEADER: 'What is the country for this platform?',
            MAIN_SERVICE_MANAGER_HEADER: 'Main Service Manager',
            MAIN_SERVICE_MANAGER_SUBHEADER: 'Who is overseeing the customers & technicians in this platform?',
            SERVICE_MANAGER_LABEL: 'Service Manager',
            ADD_TO_PLATFORM_LABEL: 'Add to Platform... *',
            ERROR_NO_TECHNICIAN_FOUND: 'No technician found',
            ERROR_NO_SERVICE_MANAGER_FOUND: 'No service manager found',
            ERROR_NO_SERVICE_PARTNER_FOUND: 'No service partner found',
            MANAGE_PLATFORM_ERROR_CODE_3048: 'Please provide valid name or length should be less than or equal to 32 characters',
            MANAGE_PLATFORM_ERROR_CODE_3049: 'Platform name already exists',
            MANAGE_PLATFORM_ERROR_CODE_3045: 'Platform country name is not valid or length should be less than or equal to 50 characters',
            MANAGE_PLATFORM_ERROR_CODE_3046: 'Please provide valid customer id',
            MANAGE_PLATFORM_ERROR_CODE_3047: 'Please provide valid service manager id',
            MANAGE_PLATFORM_ERROR_CODE_900: 'Unauthorized feature access',
            MANAGE_PLATFORM_SUCCESS_200: 'Platform Created Successfully',
            EDIT_PLATFORM_SUCCESS_200: 'Platform Updated Successfully',
            ADD_SERVICE_MANAGER_SUCCESS_200: 'Service Manager added successfully',
            ADD_SERVICE_MANAGER_ERROR_CODE_9043: 'Email is not valid or length should be less than or equal to 75 characters',
            ADD_SERVICE_MANAGER_ERROR_CODE_3051: 'The user with the specified email address is not registered in the system',
            ADD_SERVICE_MANAGER_ERROR_CODE_3050: 'Please provide valid platform id',
            ADD_SERVICE_MANAGER_ERROR_CODE_9000: 'Unauthorized feature access',
            ADD_SERVICE_MANAGER_ERROR_CODE_3052: 'The user with the specified email address is already registered in the system',
            ADD_PAAS_MANAGER_ERROR_CODE_9162: 'SLA Number is not valid or length should be between 6 and 12 characters.',
            ADD_PAAS_MANAGER_ERROR_CODE_9169: 'Expiry date is not in valid format or should be greater than current date.',
            ADD_PAAS_MANAGER_ERROR_CODE_9163: 'Please provide valid name or length should be less than or equal to 24 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9068: 'Country is not valid or length should be less than or equal to 50 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9069: 'Address is not valid or length should be less than or equal to 250 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9070: 'Address2 is not valid or length should be less than or equal to 250 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9071: 'Postal code is not valid or length should be between 4 and 10 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9072: 'City is not valid or length should  be less than or equal to 50 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9164: 'Company comment is not valid or length should be less than or equal to 255 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9043: 'Email is not valid or length should  be less than or equal to 75 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9073: 'Country code is not valid or length should  be less than or equal to 4 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9074: 'Phone number is not valid or length should be between 8 and 10 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9167: 'Please provide valid contact type.',
            ADD_PAAS_MANAGER_ERROR_CODE_9077: 'Secondary email is not valid or length should  be less than or equal to 75 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9147: 'Secondary phone number is invalid or length should be between 8 and 10.',
            ADD_PAAS_MANAGER_ERROR_CODE_9076: 'Secondary country code is not valid or length should  be less than or equal to 4 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9001: 'Unauthorized access',
            ADD_PAAS_MANAGER_ERROR_CODE_9170: 'Company name already available.',
            ADD_PAAS_MANAGER_ERROR_CODE_9171: 'SLA Number already available.',
            ADD_PAAS_MANAGER_ERROR_CODE_9150: 'Primary first name is not valid or length should be between 1 and 50 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9151: 'Primary last name is not valid or length should be between 1 and 50 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9152: 'Secondary first name is not valid or length should be between 1 and 50 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9153: 'Secondary last name is not valid or length should be between 1 and 50 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9165: 'Primary contact comment is not valid or length should be less than or equal to 255 characters.',
            ADD_PAAS_MANAGER_ERROR_CODE_9166: 'Secondary contact comment is not valid or length should be less than or equal to 255 characters.',
            ADD_PAAS_MANAGER_ERROR_CODE_9004: 'System details not found.',
            ADD_PAAS_MANAGER_ERROR_CODE_9050: 'Please provide valid system Id.',
            ADD_PAAS_MANAGER_ERROR_CODE_9172: 'Customer Number is not valid or length should be between 8 and 16 characters.',
            ADD_PAAS_MANAGER_ERROR_CODE_9173: 'Primary phone2 is invalid or length should be between 8 and 10.',
            ADD_PAAS_MANAGER_ERROR_CODE_9174: 'Secondary phone2 number is invalid or length should be between 8 and 10.',
            ADD_PAAS_MANAGER_ERROR_CODE_9175: 'Primary countrycode2 is not valid or length should be less than or equal to 4 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9176: 'Secondary countrycode2 is not valid or length should be less than or equal to 4 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9040: 'Company country code is not valid',
            ADD_PAAS_MANAGER_ERROR_CODE_9023: 'Company phone number is not valid or length shoud be between 8 and 10',
            ADD_PAAS_MANAGER_ERROR_CODE_9193: 'Service manager email is not valid or length should be less than or equal to 75 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9194: 'Service manager first name is not valid or length should be between 1 and 50 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9195: 'Service manager last name is not valid or length should be between 1 and 50 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9196: 'Service Manger primary countrycode is not valid or length should be less than or equal to 4 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9197: 'Service manager primary phone number is not valid or length shoud be between 8 and 10',
            ADD_PAAS_MANAGER_ERROR_CODE_9198: 'Service Manager secondary country code is not valid or length should be less than or equal to 4 characters',
            ADD_PAAS_MANAGER_ERROR_CODE_9199: 'Service manager secondary phone number is not valid or length shoud be between 8 and 10',
            ADD_PAAS_MANAGER_ERROR_CODE_9201: 'Service Manger comment is not valid or length should be less than or equal to 255 characters.',
            ADD_PAAS_MANAGER_ERROR_CODE_9000: 'Unauthorized feature access',
            MANAGE_PLATFORM_LIST_FAILED: 'Failed to load platform list',
            CUSTOMER_DETAILS_HEADER: 'Customer Details',
            SERVICE_MANAGER: "Service Manager",
            SLA_DETAILS: 'SLA(Optional)',
            SLA_NUMBER: 'SLA Number',
            CUSTOMER_NUMBER: 'Customer Number',
            PLEASE_SELECT: 'Please Select...',
            SECONDARY_CONTACT_INFO_OPTIONAL: 'Secondary Contact Info (Optional)',
            SLA_OPTIONAL_HEADER: 'SLA (Optional)',
            SLA_MANDATORY_HEADER: 'SLA',
            INTERNAL_EATON_SERVICE_TEAM_CHECKBOX_TEXT: 'This is an internal Eaton service team',
            ERROR_NO_PLATFORM_FOUND: 'No platform found',
            ERROR_NO_CUSTOMER_FOUND: 'No customer found',
        },
        REPORTS: {
            NO_ISSUES: "No issues during this period",
            ISSUES: "{{issueCount}} issues",
            MONTHLY_REPORT: "Monthly Report",
            REPORT_DOWNLOAD_SUCCESSFULLY: "Report file downloaded successfully",
            REPORT_DOWNLOAD_FAILED: "Report file download failed",
        }
    },
};
export default resources;
