// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { GenericToolbar } from '../../components/GenericToolbar';
import { Box, Paper } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { vh_10, vh_20, rem_10, vh_1000, vw_10, vh_400 } from '../../constants/dimentions';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { useHistory } from 'react-router-dom';
import { CustomerContactInfo } from '../assets/CustomerContactInfo';
import { defaultPlatformDetail } from '../../state/usermanagement-state';
import { CUSTOMER_ROLE_TYPE } from '../enum/ENUM';
import { platformDetail } from '../../types/platform';
import { PlatformSlaDetails } from './passSLaDetails';
import { PassInfoCard } from './PassInfoCard';

type Props = {
    location: any
};

export const PassDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const user = useSelector(Selectors.Usermanagement.usermanagement);
    const [platformDetail, setPlatformDetail] = useState<platformDetail>(defaultPlatformDetail);
    const theme = useTheme();
    const { t } = useLanguageLocale();
    const { backRoute, platformId } = props.location.state;
    const history = useHistory();
    const md = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        if (!user.platformDetailLoading && user.platformDetailSuccess) {
            setPlatformDetail(user.platformDetail);
        } else if (!user.platformDetailLoading && user.platformDetailFail) {
            addToast(t('TOAST_MSG.TOAST_ERROR_GET_PASS_DETAILS'), 'error');
        }
    }, [user.platformDetailLoading, user.platformDetailSuccess, user.platformDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(Thunks.UserManagement.getPlatformDetail(platformId));
        dispatch(Thunks.UserManagement.getPlatformSystemInfo(platformId));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div data-testid="platform-details-container">
            <GenericToolbar
                title={platformDetail.name}
                subtitle={'PAAS'}
                showBackButton={true}
                backButtonOnClick={(): void => {
                    history.goBack();
                }}
                color={ToolbarColor.Default}
            />
            <div style={{ display: 'absolute', width: '100%' }}>
                <Paper square style={{ backgroundColor: PXBColors.white[400], width: '100%' }}>
                    <Box
                        display="flex"
                        style={{
                            flexDirection: md ? 'row' : 'column',
                            alignItems: 'left',
                            padding: vh_20,
                            width: '100%',
                            height: 'wrap',
                            display: 'absolute',
                        }}
                    >
                        <Box
                            display="flex"
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                minHeight: vh_400,
                                display: 'flex',
                                flexWrap: 'wrap',
                                height: 'wrap',
                                marginLeft: 0,
                                transitionDelay: '0.2s',
                                marginBottom: rem_10,
                                width: '40%'
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    minHeight: vh_400,
                                    marginBottom: vh_10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="platformInfo-card"
                            >
                                <PassInfoCard platformInfo={platformDetail} />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            style={{
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'flex-center',
                                minHeight: vh_1000,
                                marginLeft: vw_10,
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: md ? '65%' : '',
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    marginBottom: vh_10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="platform-sla-details"
                            >
                                <PlatformSlaDetails platformInfo={platformDetail}></PlatformSlaDetails>
                            </Box>
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    height: 'wrap',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    alignItems: 'center',
                                    marginBottom: vh_10,
                                }}
                                data-testid="platformContactInfo"
                            >
                                <CustomerContactInfo
                                    customerInfo={{
                                        firstName: platformDetail.serviceManagerFirstName,
                                        lastName: platformDetail.serviceManagerLastName,
                                        phone: platformDetail.serviceManagerPrimaryPhone,
                                        countryCode: platformDetail.serviceManagerPrimaryCountryCode,
                                        countryCode2: platformDetail.serviceManagerCountryCode2,
                                        phone2: platformDetail.serviceManagerPhone2,
                                        Email: platformDetail.serviceManagerEmail,
                                        ContactType: CUSTOMER_ROLE_TYPE.SERVICE_MANAGER,
                                        Comments: platformDetail.serviceManagerComments ? platformDetail.serviceManagerComments : ''
                                    }}
                                    header={t('CUSTOMER_DETAILS.SERVICE_MANAGER')} contactPersonHeader={t('CUSTOMER_DETAILS.BUYER_HEADER')}
                                />
                            </Box>
                            {!platformDetail.isInternalTeam && <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    height: 'wrap',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    alignItems: 'center',
                                    marginBottom: vh_10,
                                }}
                                data-testid="platformPrimaryContactInfo"
                            >
                                <CustomerContactInfo
                                    customerInfo={{
                                        firstName: platformDetail.primaryFirstName,
                                        lastName: platformDetail.primaryLastName,
                                        phone: platformDetail.primaryPhone,
                                        countryCode: platformDetail.primaryCountryCode,
                                        countryCode2: platformDetail.primaryCountryCode2,
                                        phone2: platformDetail.primaryPhone2,
                                        Email: platformDetail.primaryEmail,
                                        ContactType: platformDetail.primaryContactType,
                                        Comments: platformDetail.primaryContactComments ? platformDetail.primaryContactComments : ''
                                    }}
                                    header={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT')} contactPersonHeader={t('CUSTOMER_DETAILS.BUYER_HEADER')}
                                />
                            </Box>}
                            {!platformDetail.isInternalTeam && <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    height: 'wrap',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    alignItems: 'center'
                                }}
                                data-testid="platformSecondaryContactInfo"
                            >
                                <CustomerContactInfo
                                    header={t('ORGANIZATION_DETAILS.SECONDARY_CONTACT')} contactPersonHeader={t('CUSTOMER_DETAILS.TECH_LEAD_HEADER')}
                                    customerInfo={{
                                        firstName: platformDetail.secondaryFirstName ? platformDetail.secondaryFirstName : '',
                                        lastName: platformDetail.secondaryLastName ? platformDetail.secondaryLastName : '',
                                        phone: platformDetail.secondaryPhone,
                                        countryCode: platformDetail.secondaryCountryCode,
                                        countryCode2: platformDetail.secondaryCountryCode2,
                                        phone2: platformDetail.secondaryPhone2 ? platformDetail.secondaryPhone2 : '',
                                        Email: platformDetail.secondaryEmail ? platformDetail.secondaryEmail : '',
                                        ContactType: platformDetail.secondaryContactType,
                                        Comments: platformDetail.secondaryContactComments ? platformDetail.secondaryContactComments : ''
                                    }} />
                            </Box>}
                        </Box>
                    </Box>
                </Paper>
            </div>
        </div>
    );
};
