import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_REGISTRATION_EMAIL_VALIDATION } from './url-constants';

type UserRegistrationRequestEmailValidationEndpointSpecifications = ApiEndpointSpecification;
export const UserRegistrationRequestEmailValidationEndpointSpecification: UserRegistrationRequestEmailValidationEndpointSpecifications = {
    url: USER_REGISTRATION_EMAIL_VALIDATION,
    method: HttpMethod.GET,
    requestParamsSchemaName: 'UserRegistrationRequestEmailValidationRequestParams',
    requestBodySchemaName: 'UserRegistrationRequestEmailValidationRequestBody',
    okResponseSchemaName: 'UserRegistrationRequestEmailValidationOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeUserRegistrationRequestEmailValidation = Helpers.makeApiRequestFn<
    Types.UserRegistrationRequestEmailValidationRequestParams,
    Types.UserRegistrationRequestEmailValidationRequestBody,
    Types.UserRegistrationRequestEmailValidationOkResponse
>(UserRegistrationRequestEmailValidationEndpointSpecification);
