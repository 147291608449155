import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { UPSERT_LOG_COMMENTS } from './url-constants';

type UpsertLogCommentsEndpointSpecifications = ApiEndpointSpecification;
export const UpsertLogCommentsEndpointSpecification: UpsertLogCommentsEndpointSpecifications = {
    url: UPSERT_LOG_COMMENTS,
    method: HttpMethod.PUT,
    requestParamsSchemaName: 'UpsertLogCommentsRequestParams',
    requestBodySchemaName: 'UpsertLogCommentsRequestBody',
    okResponseSchemaName: 'UpsertLogCommentsRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeUpsertLogCommentsRequest = Helpers.makeApiRequestFn<
    Types.UpsertLogCommentsRequestParams,
    Types.UpsertLogCommentsRequestBody,
    Types.UpsertLogCommentsRequestOkResponse
>(UpsertLogCommentsEndpointSpecification);
