import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_DASHBOARD_ORGANIZATION_LIST } from './url-constants';

type ServiceOrganizationListEndpointSpecifications = ApiEndpointSpecification;
export const ServiceOrganizationListEndpointSpecification: ServiceOrganizationListEndpointSpecifications = {
    url: GET_DASHBOARD_ORGANIZATION_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'ServiceOrganizationListRequestParams',
    requestBodySchemaName: 'ServiceOrganizationListRequestBody',
    okResponseSchemaName: 'ServiceOrganizationListOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeServiceOrganizationList = Helpers.makeApiRequestFn<
    Types.ServiceOrganizationListRequestParams,
    Types.ServiceOrganizationListRequestBody,
    Types.ServiceOrganizationListOkResponse
>(ServiceOrganizationListEndpointSpecification);
