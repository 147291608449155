// eslint-disable-next-line
import React from 'react';
import { Data, DataState } from '../../types/data-providers';
import { EmptyState } from '@brightlayer-ui/react-components';
import { Icon, getIcon } from '../../lib/icons';
import { CenteredContent } from '../CenteredContent';
import { Typography, Link, Box, Paper } from '@mui/material';
import { CONTACT_EMAIL } from '../../constants';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';

export const DataEmptyIndicator: React.FC<React.PropsWithChildren<React.PropsWithChildren<{
    icon: Icon;
    message: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Data;
    isEmpty: boolean;
    inline?: boolean;
    isDetailedInfo?: boolean;
    style?: React.CSSProperties;
    withAction?: boolean;
    action?: JSX.Element;
    description?: string;
}>>> = (props) => {
    const { t } = useLanguageLocale();
    if (props.data.state !== DataState.Loaded) {
        return null;
    }

    if (!props.isEmpty) {
        return null;
    }

    const TheIcon = getIcon(props.icon);

    if (props.inline) {
        return (
            <div style={{ marginTop: '20px', marginBottom: '20px', ...props.style }}>
                <EmptyState
                    icon={<div style={{ fontSize: 30 }}>{TheIcon && <TheIcon id="fail-icon" fontSize="inherit" />}</div>}
                    title={props.message} placeholder={undefined}                />
            </div>
        );
    }
    if (props.isDetailedInfo) {
        return (
            <>
                <CenteredContent style={{ height: '30vh', ...props.style }}>
                    <EmptyState
                        icon={<ErrorOutlineIcon id="fail-icon" style={{ fontSize: '64px' }} />}
                        title={t('DASHBOARD.NO_FOUND', { replace: { entity: props.message } })}
                        actions={<>
                            <Typography variant="subtitle1" color="primary" style={{ textAlign: 'center' }}>
                                {t('DASHBOARD.NOT_ACCESS', { replace: { entity: props.message } })}
                            </Typography>
                            <Typography variant="subtitle1" color="primary" style={{ textAlign: 'center' }}>
                                {t('DASHBOARD.CONTACT_MANAGER')}{' '}
                                <Link underline="always" href={`mailto:${CONTACT_EMAIL}`}>
                                    {CONTACT_EMAIL}
                                </Link>{' '}
                                {t('DASHBOARD.NEW_SITE', { replace: { entity: props.message } })}
                            </Typography>
                        </>} placeholder={undefined}                    />
                </CenteredContent>
            </>
        );
    }
    if (props.withAction) {
        return (
            <Paper style={{ padding: 16 }}>
                <EmptyState
                    icon={<div style={{ fontSize: 64 }}>{TheIcon && <TheIcon id="fail-icon" fontSize="inherit" />}</div>}
                    title={props.message}
                    actions={props.action}
                    description={<Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography style={{ width: '60%' }} variant="subtitle2">
                            {props.description}
                        </Typography>
                    </Box>} placeholder={undefined}                />
            </Paper>
        );
    }
    return (
        <CenteredContent style={props.style}>
            <EmptyState
                icon={<div style={{ fontSize: 64 }}>{TheIcon && <TheIcon id="fail-icon" fontSize="inherit" />}</div>}
                title={props.message} placeholder={undefined}            />
        </CenteredContent>
    );
};
