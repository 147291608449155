import { BuildingList, BuildingListData } from "../types/building";
import { StatisitcData, StaticalDashboardList } from "../types/statistic-summary";

export type DashboardState = {
    autoRedirectBannerShown: boolean;
    trendCardDays: number;
    trendLinesEnabled: { [channelIdDeviceId: string]: boolean };
    hoveredTrendline: { channelId: number; deviceId: string } | undefined;
    inActiveLogOut: boolean;
    isLocationRefresh: boolean;
    buildingListLoading: boolean;
    buildingListSuccess: boolean;
    buildingListFail: boolean;
    buildingList: BuildingListData,
    errorCode: string;
    statisticSummaryDashboardLoading: boolean;
    statisticSummaryDashboardSuccess: boolean;
    statisticSummaryDashboardFail: boolean;
    statisticSummaryDashboard: StaticalDashboardList,
    statisticSummaryDashboardErrorCode: string;
    dashboardTabSelection: string;
};

const buildingListData: BuildingListData = {
    detail: [],
    totalRecordCount: 0,
    totalPage: 0
}

const statisticSummaryData: StatisitcData = {
    dt: 0,
    error: 0,
    ready: 0,
    blocked: 0,
    offLine: 0,
    warning: 0,
    totalCount: 0,
};

export const initialDashboardState: DashboardState = {
    autoRedirectBannerShown: false,
    trendCardDays: 1,
    trendLinesEnabled: {},
    hoveredTrendline: undefined,
    inActiveLogOut: false,
    isLocationRefresh: false,
    buildingListLoading: false,
    buildingListSuccess: false,
    buildingListFail: false,
    buildingList: buildingListData,
    errorCode: '',
    statisticSummaryDashboardLoading: false,
    statisticSummaryDashboardSuccess: false,
    statisticSummaryDashboardFail: false,
    statisticSummaryDashboard: {},
    statisticSummaryDashboardErrorCode: '',
    dashboardTabSelection: '9',
};

export const isTrendlineEnabled = (
    trendlinesEnabled: { [channelIdDeviceId: string]: boolean },
    channelId: number,
    deviceId: string
): boolean => {
    const channelIdDeviceId = `${channelId}-${deviceId}`;

    return channelIdDeviceId in trendlinesEnabled ? trendlinesEnabled[channelIdDeviceId] : true;
};
