import * as HttpStatus from 'http-status-codes';
import shallowEqual from 'shallowequal';

const ERROR_SECURITY_TOKEN_MISSING = 9009;

/**
 * If a request is already in-flight the caller will get back a Promise that will
 * yield whatever comes back from the first request. It does a shallow equal comparison
 * of the first argument to see if it can be considered the same request.
 *
 * This isn't very sophisticated. It only has a "memory" of the previous request. If a
 * request is made with arguments that are not shallow equal to those of the in-flight
 * request then the new request will go out and the previously in-flight request will
 * be "forgotten". This is all that's needed right now, so good enough.
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const combineDuplicateRequests = <T extends (...args: any[]) => any>(
    fn: T
): ((...args: Parameters<T>) => ReturnType<T>) => {
    const requestCounter = [0];
    let inFlightPromise: ReturnType<T> | undefined;
    let inFlightRequestArgs: Parameters<T> | undefined;

    return (...fnArgs: Parameters<T>): ReturnType<T> => {
        // If there is not an inflight request, or the first argument is not shallowEqual,
        // then kick off a new request
        if (!inFlightPromise || !inFlightRequestArgs || !shallowEqual(inFlightRequestArgs[0], fnArgs[0])) {
            requestCounter[0] = requestCounter[0] + 1;

            inFlightRequestArgs = fnArgs;
            const requestNum = requestCounter[0];
            inFlightPromise = fn(...fnArgs).then((result: unknown) => {
                // Clean up state, but only if this was the most recent request
                if (requestNum === requestCounter[0]) {
                    inFlightPromise = undefined;
                    inFlightRequestArgs = undefined;
                }
                return result;
            });
        }

        if (inFlightPromise === undefined) {
            // This shouldn't be possible
            throw new Error('Problem making request');
        }

        return inFlightPromise;
    };
};

/**
 * Unfortunately a missing token does not result in a 401. Rather, a 400 with
 * an "errorCode" of 9009 is returned for "Security token must not be null or empty".
 * But for all intents and purposes, it's the same as a 401.
 */
export const isUnauthorized = (
    response:
        | { ok: true }
        | {
              ok: false;
              httpStatusCode: number;
              response: { errorCode: number } | { statusCode: number };
          }
        | { status: number; response: any } // eslint-disable-line @typescript-eslint/no-explicit-any
): boolean => {
    if ('status' in response) {
        if (response.status === HttpStatus.UNAUTHORIZED) {
            return true;
        }

        if (
            response.response &&
            response.response.errorCode &&
            response.response.errorCode === ERROR_SECURITY_TOKEN_MISSING
        ) {
            return true;
        }

        return false;
    }

    if (response.ok) {
        return false;
    }

    if (response.httpStatusCode === HttpStatus.UNAUTHORIZED) {
        return true;
    }

    const extractErrorCode = (): number =>
        'errorCode' in response.response ? response.response.errorCode : response.response.statusCode;

    const errorCode = extractErrorCode();

    if (errorCode === ERROR_SECURITY_TOKEN_MISSING) {
        return true;
    }

    return false;
};
