import { InitialState } from '../state';
import { AppReducer } from './app-reducer';

export const customerReducer: AppReducer<'Customer'> = (state = InitialState.Customer, action): typeof state => {
    switch (action.type) {
        case 'Customer/dashboardCustomerListData/Started':
            return {
                ...state,
                dashboardCustomerListLoading: true,
                dashboardCustomerListSuccess: false,
                dashboardCustomerListFail: false,
                dashboardCustomerListErrorCode: '',
            };
        case 'Customer/dashboardCustomerListData/Succeeded':
            return {
                ...state,
                dashboardCustomerListLoading: false,
                dashboardCustomerListSuccess: true,
                dashboardCustomerListFail: false,
                dashboardCustomerListErrorCode: '',
                dashboardCustomerList: action.payload.customerList,
            };
        case 'Customer/dashboardCustomerListData/Failed':
            return {
                ...state,
                dashboardCustomerListLoading: false,
                dashboardCustomerListSuccess: false,
                dashboardCustomerListFail: true,
                dashboardCustomerListErrorCode: action.payload.errorCode,
            };

        case 'Customer/dashboardCustomerListData/Unmounted':
            return {
                ...state,
                dashboardCustomerListLoading: false,
                dashboardCustomerListSuccess: false,
                dashboardCustomerListFail: false,
                dashboardCustomerListErrorCode: '',
            };
        case 'Customer/linkCustomer/Started':
            return {
                ...state,
                linkCustomerLoading: true,
                linkCustomerSuccess: false,
                linkCustomerFail: false,
                linkCustomerErrorCode: '',
            };
        case 'Customer/linkCustomer/Succeeded':
            return {
                ...state,
                linkCustomerLoading: false,
                linkCustomerSuccess: true,
                linkCustomerFail: false,
                linkCustomerErrorCode: '',
            };
        case 'Customer/linkCustomer/Failed':
            return {
                ...state,
                linkCustomerLoading: false,
                linkCustomerSuccess: false,
                linkCustomerFail: true,
                linkCustomerErrorCode: action.payload.errorCode,
            };

        case 'Customer/linkCustomer/Unmounted':
            return {
                ...state,
                linkCustomerLoading: false,
                linkCustomerSuccess: false,
                linkCustomerFail: false,
                linkCustomerErrorCode: '',
            };
        case 'Customer/customerListData/Started':
            return {
                ...state,
                customerListLoading: true,
                customerListSuccess: false,
                customerListFail: false,
                customerListErrorCode: '',
            };
        case 'Customer/customerListData/Succeeded':
            return {
                ...state,
                customerListLoading: false,
                customerListSuccess: true,
                customerListFail: false,
                customerListErrorCode: '',
                customerList: action.payload.customerList,
            };
        case 'Customer/customerListData/Failed':
            return {
                ...state,
                customerListLoading: false,
                customerListSuccess: false,
                customerListFail: true,
                customerListErrorCode: action.payload.errorCode,
            };

        case 'Customer/customerListData/Unmounted':
            return {
                ...state,
                customerListLoading: false,
                customerListSuccess: false,
                customerListFail: false,
                customerListErrorCode: '',
            };
        default:
            return state;
    }
};
