// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { CardContent, FormControl, InputLabel, SelectChangeEvent, TextField } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Typography, Divider, MenuItem, Select } from '@mui/material';
import { CountryList } from '../../state/registration-state';
import { COMPANY_NAME, COMPANY_NAME_MAX_LENGTH, COUNTRY_CODE_MAX_LENGTH, PHONE_MAX_LENGTH, PHONE_MIN_LENGTH, PHONE_NUMBER } from '../../constants';
import { validPhoneNumberLength } from '../../lib/form-validator';

type Props = {
    companyname?: string;
    countryname?: string;
    phone?: string;
    countryCode?: string;
    countryList: CountryList;
    onCompanyNameChanged?: Function;
    onCountryNameChanged?: Function;
    onCountryCodeChanged?: Function;
    onPhoneChanged?: Function;
    changeNextLabel?: Function;
    changeNextEnabled?: Function;
};

const fieldsComplete = (fields: string[]): boolean => {
    for (let i = 0; i < fields.length; i++) {
        if (fields[i].length < 1) {
            return false;
        }
    }
    return true;
};

export const CompanyDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const {
        companyname,
        countryname,
        phone,
        countryCode,
        countryList,
        onCompanyNameChanged,
        onCountryNameChanged,
        onCountryCodeChanged,
        onPhoneChanged,
        changeNextLabel,
        changeNextEnabled,
    } = props;
    const [companyName, setCompanyName] = useState(companyname || '');
    const [countryName, setCountryName] = useState(countryname || '');
    const [phoneNumber, setPhoneNumber] = useState(phone || '');
    const [country, setCountryCode] = useState(countryCode || '');

    if (changeNextLabel) {
        changeNextLabel(t('ACTIONS.NEXT'));
    }

    if (changeNextEnabled) {
        changeNextEnabled(fieldsComplete([companyName, countryName]) && validPhoneNumberLength(phoneNumber));
    }

    const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setPhoneNumber(e.target.value);
            if (onPhoneChanged) onPhoneChanged(e.target.value);
        }
    };

    const onChangeCompanyName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_NAME.test(e.target.value)) {
            setCompanyName(e.target.value);
            if (onCompanyNameChanged) onCompanyNameChanged(e.target.value);
        }
    };

    const onChangeCountryName = (event: SelectChangeEvent) => {
        const countryNameData = event.target.value as string
        setCountryName(countryNameData);
        if (onCountryNameChanged) onCountryNameChanged(countryNameData);

        // eslint-disable-next-line
        const countryCodeData = countryList.filter(function (countryData) {
            if (countryData.name === countryNameData)
                return countryData
        });
        setCountryCode(countryCodeData?.[0].code);
        if (onCountryCodeChanged) onCountryCodeChanged(countryCodeData?.[0].code);
    };

    const onChangeCountryCode = (event: SelectChangeEvent) => {
        setCountryCode(event.target.value as string);
        if (onCountryCodeChanged) onCountryCodeChanged(event.target.value);
    };

    useEffect(() => {
        if (countryList !== undefined) {
            if (countryName === '') {
                const countryName = countryList.length > 0 ? countryList[0].name : '';
                setCountryName(countryName);
                if (onCountryNameChanged) onCountryNameChanged(countryName);
            } else if (country === '') {
                const countryCode = countryList.length > 0 ? countryList[0].code : ''
                setCountryCode(countryCode);
                if (onCountryCodeChanged) onCountryCodeChanged(countryCode);
            }
        }
    }, [countryList, countryName, country, onCountryNameChanged, onCountryCodeChanged]);


    return (
        <>
            <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }}>
                <Typography variant="body1" data-testid='headerTextCheck'>
                    {t('REGISTRATION.STEPS_MESSAGE.CREATE_ACCOUNT_COMPANY_DETAILS')}
                </Typography>

                <Divider style={{ marginTop: 24 }} />

                <TextField
                    style={{ marginTop: 24 }}
                    variant={'filled'}
                    label={t('FORMS.COMPANY_NAME')}
                    inputProps={{ 'data-testid': 'company-name', maxLength: COMPANY_NAME_MAX_LENGTH }}
                    value={companyName}
                    required
                    fullWidth
                    onChange={(e): void => { onChangeCompanyName(e) }}
                />
                <FormControl variant={'filled'} style={{ display: 'flex', marginTop: 24 }}>
                    <InputLabel data-testid='country-name-label' id="country-name-label">{t('FORMS.COUNTRY')}</InputLabel>
                    <Select
                        labelId="country-name-label"
                        id="country-name-label"
                        inputProps={{ 'data-testid': 'country-code', maxLength: 100 }}
                        fullWidth
                        required
                        defaultValue={countryName}
                        value={countryName}
                        onChange={onChangeCountryName}
                    >
                        {countryList?.map((e, code) => {
                            return (
                                <MenuItem id="country-name-list" key={code} value={e.name} style={{
                                    display: 'flex',
                                    justifyContent: 'left', fontSize: 14,
                                    lineHeight: 1.5, fontWeight: 400,
                                    overflow: 'hidden',
                                    boxSizing: 'border-box',
                                    whiteSpace: 'nowrap',
                                    minHeight: 48,
                                    paddingTop: 6,
                                    paddingBottom: 6,
                                    width: 'auto',
                                    paddingLeft: 16,
                                    paddingRight: 16
                                }}>
                                    {e.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <FormControl variant={'filled'} style={{ marginTop: 24, marginRight: '2%' }}>
                    <InputLabel data-testid='countryCodeRequiredId' id="country-name-label">{'*'}</InputLabel>
                    <Select
                        style={{ width: 91.5, textAlign: 'center' }}
                        variant={'filled'}
                        labelId="countrycode-label"
                        inputProps={{ 'data-testid': 'country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                        id="countrycode-testid"
                        data-testid='countrycode-testid'
                        defaultValue={country}
                        value={country}
                        onChange={onChangeCountryCode}
                    >
                        {countryList?.map((e, code) => {
                            return (
                                <MenuItem id="country-code-list-id" key={code} value={e.code}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'left', fontSize: 14,
                                        lineHeight: 1.5, fontWeight: 400,
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                        whiteSpace: 'nowrap',
                                        minHeight: 48,
                                        paddingTop: 6,
                                        paddingBottom: 6,
                                        width: 'auto',
                                        paddingLeft: 16,
                                        paddingRight: 16
                                    }}    >
                                    {'+' + e.code}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <TextField
                    required
                    style={{ marginTop: 24, width: '76%' }}
                    variant={'filled'}
                    label={t('FORMS.PHONE_NUMBER')}
                    inputProps={{ 'data-testid': 'phone-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                    value={phoneNumber}
                    fullWidth
                    onChange={(e): void => onChangePhoneNumber(e)}
                />
            </CardContent>
        </>
    );
};
