// eslint-disable-next-line
import React from 'react';
import { SideNav } from '../SideNav/SideNav';
import useStateWhileMounted from '../../hooks/use-state-while-mounted';
import { DrawerLayout } from '@brightlayer-ui/react-components/core/DrawerLayout';

type ConfigurePageContainerContexts = {
    displaySideNav: boolean;
    setDisplaySideNav: Function; // TODO: type this
};

const ConfigurePageContainerContext = React.createContext<ConfigurePageContainerContexts | null>(null);

export const useConfigurePageContainer = (args: { displaySideNav: boolean; appBar?: React.ReactElement }): void => {
    const context = React.useContext(ConfigurePageContainerContext);
    if (!context) {
        throw new Error('useConfigurePageContainer must be a child of a PageContainer');
    }

    React.useLayoutEffect(() => {
        if (context.displaySideNav !== args.displaySideNav) {
            context.setDisplaySideNav(args.displaySideNav);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const PageContainer: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = (props) => {
    //TOdo- reference for export
    // const Dispatch = useDispatch();
    // const timelineCsvUrl = useSelector(Selectors.TimelineExport.timelineExportUrl);
    // React.useEffect(() => {
    //     if (timelineCsvUrl !== '') window.open(timelineCsvUrl, '_self');
    //     return (): void => {
    //         Dispatch(Actions.TimelineExport.timelineExportUnmounted());
    //     };
    // }, [timelineCsvUrl]); // eslint-disable-line react-hooks/exhaustive-deps
    const [displaySideNav, setDisplaySideNav] = useStateWhileMounted(false);

    return (
        <ConfigurePageContainerContext.Provider value={{ displaySideNav, setDisplaySideNav }}>
            <DrawerLayout drawer={<SideNav />} placeholder={undefined}>{props.children}</DrawerLayout>
        </ConfigurePageContainerContext.Provider>
    );
};
