// eslint-disable-next-line
import React from 'react';
import { BrandedCardContainer } from '../../components/BrandedCardContainer/BrandedCardContainer';
import { CardHeader, Typography, CardContent, CardActions } from '@mui/material';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import * as PXBColors from '@brightlayer-ui/colors';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import BrandLogo from '../../assets/images/Logo_Blue_Screen_Image.png';

const UnsupportedBrowser = (): JSX.Element => {
    const { t } = useLanguageLocale();
    return (
        <>
            <BrandedCardContainer>
                <div style={{ margin: 20 }}>
                    <CardHeader
                        style={{ height: '50%', textAlign: 'center' }}
                        data-testid="icon"
                        title={
                            <WebAssetIcon
                                style={{ height: 60, width: 60, color: PXBColors.gray[500], justifyContent: 'center' }}
                            ></WebAssetIcon>
                        }
                    />
                    <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }}>
                        <div style={{ height: '30%' }}>
                            <Typography variant={'h6'} style={{ fontWeight: 600, marginBottom: 20 }}>
                                {t('UNSUPPORTED_BROWSER.NOT_SUPPORTED')}
                            </Typography>
                            <Typography variant={'subtitle2'} style={{ marginBottom: 20 }}>
                                {t('UNSUPPORTED_BROWSER.INCOMPATIBLE_BROWSER')}
                            </Typography>
                            <Typography variant={'subtitle2'}>
                                {t('UNSUPPORTED_BROWSER.RECOMMENDED_BROWSER')}
                            </Typography>
                        </div>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center', marginTop: 150 }}>
                        <img style={{ maxWidth: 220 }} src={BrandLogo} alt="logo" />
                    </CardActions>
                </div>
            </BrandedCardContainer>
        </>
    );
};
export default UnsupportedBrowser;
