// eslint-disable-next-line
import React from 'react';
import {
    TableRow,
    IconButton,
    Typography,
    TableCell
} from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ChevronRight, Star, StarBorder } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { BuildingListItems } from '../../types/building';
import { Link } from '@mui/material';
import { DEVICE_STATUS, DeviceState, ENTITY_TYPE, getDeviceStatusState } from '../../app/enum/ENUM';
import { useDispatch } from 'react-redux';
import { OrganizationThunks } from '../../actions/thunks/organization-thunks';
import { TooltipItem } from '../TooltipItem/TooltipItem';
import { getControlledText } from '../../app/enum/GenericFormat';

type Props = {
    row: BuildingListItems;
    selectedBuilding: string;
    displayClick: Function;
    gatewayCountClick: Function;
    systemCountClick: Function;
};

export const AssetBuildingListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const dispatch = useDispatch();
    const { t } = useLanguageLocale();
    const row: BuildingListItems = props.row;

    const handleDisplay = (): void => {
        props.displayClick({ buildingId: row.buildingId, projectId: row.parentProjectId });
    };

    const handleGatewayCount = (): void => {
        props.gatewayCountClick({ buildingId: row.buildingId, projectId: row.parentProjectId });
    };

    const handleSystemCount = (): void => {
        props.systemCountClick({ buildingId: row.buildingId, projectId: row.parentProjectId });
    };
    const handleMarkAsFav = (): void => {
        dispatch(OrganizationThunks.markAsFav(
            row.buildingId,
            ENTITY_TYPE.BUILDING
        ));
    }

    const handleRemoveMarkAsFav = (): void => {
        dispatch(OrganizationThunks.removeMarkAsFav(
            row.buildingId,
            ENTITY_TYPE.BUILDING
        ));
    }

    const systemState: DeviceState = getDeviceStatusState(row.elStatus);

    return (
        <TableRow
            id={`row${row.buildingId}`}
            data-testid={`row${row.buildingId}`}
            style={{ cursor: 'pointer', width: '100%', backgroundColor: props.selectedBuilding === row.buildingId ? PXBColors.blue[50] : PXBColors.white[100] }}
        //onClick={(): void => handleDisplay()} // To Do - Handle seperate click to display building details
        >
            <TableCell style={{ width: '0%', paddingLeft: 10 }}
                id="building-list-item-favorite"
                data-testid="building-list-item-favorite">
                {row.isFavourite ? <Star data-testid='removeMarkAsFaVBuilding' onClick={handleRemoveMarkAsFav} style={{ color: '#007BC1' }} /> : <StarBorder data-testid='MarkAsFaVBuilding' onClick={handleMarkAsFav} style={{ color: '#727E84' }} />}
            </TableCell>
            <TooltipItem title={row.name} isTopPlacement={true} lenghtLimit={15} component={<TableCell
                id="building-list-item-name"
                data-testid="building-list-item-name"
                //id={labelId} // ToDo - for selection view handling
                align="left"
                padding="checkbox"
                style={{ width: '24%' }}
            >
                {getControlledText(row.name, 15)}
            </TableCell>}>

            </TooltipItem>
            <TooltipItem title={row.projectName} isTopPlacement={true} lenghtLimit={15} component={<TableCell align="left" padding="checkbox" style={{ width: '24%' }}
                id="building-list-item-projectname"
                data-testid="building-list-item-projectname">
                {getControlledText(row.projectName, 15)}
            </TableCell>}>

            </TooltipItem>
            <TooltipItem title={row.city} isTopPlacement={true} lenghtLimit={15} component={<TableCell align="left" padding="checkbox" style={{ width: '16%', padding: 0, paddingLeft: 16 }}
                id="building-list-item-city"
                data-testid="building-list-item-city">
                {getControlledText(row.city, 15)}
            </TableCell>}>

            </TooltipItem>
            <TableCell align="left" padding="checkbox" style={{ width: '10%', padding: 0, paddingLeft: 16, color: PXBColors.blue[500] }}
                id="building-list-item-gatewaycount"
                data-testid="building-list-item-gatewaycount"
                onClick={(): void => handleGatewayCount()}>
                <Link href="#" underline="hover">{row.gatewayCount}</Link>
            </TableCell>
            <TableCell align="left" padding="checkbox" style={{ width: '10%', padding: 0, paddingLeft: 16, color: PXBColors.blue[500] }}
                id="building-list-item-systemcount"
                data-testid="building-list-item-systemcount"
                onClick={(): void => handleSystemCount()}>
                <Link href="#" underline="hover">{row.systemCount}</Link>
            </TableCell>

            <TableCell align="left" style={{ width: '15%', paddingLeft: 10 }}
                id="building-list-item-status"
                data-testid="building-list-item-status">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                   { DEVICE_STATUS.STATUS_EMPTY?<div style={{ minWidth: 25 }}>
                        <img
                            src={systemState.statusImage}
                            alt="logo"
                            style={{ display: 'flex', alignItems: 'right' }}
                            id="building-status-icon"
                            data-testid="building-status-icon"
                        /></div>:''}
                    <Typography
                        id="building-status-button"
                        data-testid="building-status-button"

                        style={{ color: systemState.statusColor, textTransform: 'none', marginLeft: 10, fontWeight: 600 }} variant="body2">
                        {t(systemState.statusTitle)}
                    </Typography>
                </div>
            </TableCell>

            <TableCell style={{ width: '5%' }}
                id="building-list-item-next"
                data-testid="building-list-item-next"
                onClick={(): void => handleDisplay()}>
                <IconButton edge="end" id="list-item-next-icon" data-testid="list-item-next-icon" style={{ marginLeft: "auto" }} >
                    <ChevronRight />
                </IconButton>
            </TableCell>

            <TableCell align="left" padding="checkbox" style={{ width: '0%', padding: 0 }}
                id="building-list-item-empty"
                data-testid="building-list-item-empty">
            </TableCell>

        </TableRow>
    );
};
