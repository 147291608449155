// eslint-disable-next-line
import React from 'react';
import ListItem from '@mui/material/ListItem';
import { Typography, IconButton, ListItemText, Divider } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { OverviewData, OverviewSelectedData } from '../../types/overview-type';
import { ALL_ORGANIZATION_API_ID, SEPERATOR_COMMA } from '../../constants';
import { TooltipItem } from '../TooltipItem/TooltipItem';
import { getDeviceStatusState } from '../../app/enum/ENUM';
import { getControlledText } from '../../app/enum/GenericFormat';


type Props = {
    value: OverviewData;
    OnItemSelected?: Function;
    isLast: boolean;
};

export const OverviewListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { OnItemSelected } = props;

    var systemStatsIcon = getDeviceStatusState(props.value.elStatus).statusImage;

    const setSelected = (selectedData: OverviewSelectedData): void => {
        if (OnItemSelected) OnItemSelected(selectedData);
    };

    const subTitleProjectName = props.value.projectId === ALL_ORGANIZATION_API_ID ? SEPERATOR_COMMA + props.value.projectName : '';

    return (
        <>
            <ListItem style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-end' }}
                onClick={(): void => setSelected(
                    {
                        id: props.value.id,
                        name: props.value.name,
                        city: props.value.city,
                        projectId: props.value.projectId,
                        projectName: props.value.projectName,
                        gatewayId: props.value.gatewayId,
                        systemCount: props.value.systemCount,
                    })}
                secondaryAction={
                    <IconButton edge="end" id="list-item-next-icon" >
                        <ChevronRight />
                    </IconButton>
                }>

                <ListItemText data-testid="list-item-text">
                    <TooltipItem
                        title={props.value.name}
                        isTopPlacement={true}
                        lenghtLimit={30}
                        component={
                            <Typography
                                data-testid='buildingNameTestId'
                                id="list-item-title"
                                variant='subtitle1'
                            >
                                {getControlledText(props.value.name, 30)}
                            </Typography>
                        }>
                    </TooltipItem>

                    <TooltipItem
                        title={props.value.city}
                        isTopPlacement={false}
                        lenghtLimit={30}
                        component={
                            <Typography
                                data-testid='cityNameTestId'
                                id="list-item-subtitle" variant="caption"
                                sx={{
                                    lineHeight: 1.25,
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    wordWrap: 'break-word'
                                }}
                            >
                                {getControlledText(props.value.city, 30) + subTitleProjectName}
                            </Typography>
                        }>
                    </TooltipItem>
                </ListItemText>

                {props.value.systemCount !== 0 && <img
                    src={systemStatsIcon}
                    alt="logo"
                    style={{ display: 'flex', alignItems: 'right' }}
                    id="list-item-status-icon"
                />}
            </ListItem>
            {props.isLast ? '' : <Divider style={{ marginLeft: 16 }} />}
        </>
    );
};
