import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { MARK_ENTITY_AS_FAV } from './url-constants';

type MarkAsFavRequestEndpointSpecification = ApiEndpointSpecification;
export const MarkAsFavRequestEndpointSpecifications: MarkAsFavRequestEndpointSpecification = {
    url: MARK_ENTITY_AS_FAV,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'MarkAsFavRequestParams',
    requestBodySchemaName: 'MarkAsFavRequestBody',
    okResponseSchemaName: 'MarkAsFavRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeMarkAsFavRequest = Helpers.makeApiRequestFn<
    Types.MarkAsFavRequestParams,
    Types.MarkAsFavRequestBody,
    Types.MarkAsFavRequestOkResponse
>(MarkAsFavRequestEndpointSpecifications);
