// eslint-disable-next-line
import React, { useEffect } from 'react';
import {
    TableRow,
    IconButton,
    Typography,
    TableCell,
    Icon,
} from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ChevronRight, Star, StarBorder } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useDispatch } from 'react-redux';
import { DeviceState, ENTITY_TYPE, getDeviceStatusState, getDeviceType, getUserStatusState } from '../../app/enum/ENUM';
import { OrganizationThunks } from '../../actions/thunks/organization-thunks';
import { ServiceManagerListItems } from '../../types/serviceManagers';

type Props = {
    row: ServiceManagerListItems;
    selectedTechnician: string;
    displayClick: Function;
    onSuccess: Function;
};

export const ServiceManagerListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const row: ServiceManagerListItems = props.row;
    const dispatch = useDispatch();

    const handleDisplay = () => {
        props.displayClick({ ServiceManagerData: row });
    };

    const handleClick = (): void => {
        dispatch(OrganizationThunks.markAsFav(
            row.id,
            ENTITY_TYPE.SYSTEM
        ));
    }

    const serviceManagerState: DeviceState = getUserStatusState(row.userStatus);

    return (
        <TableRow
            id={`row${row.id}`}
            data-testid={`row${row.id}`}
            style={{ cursor: 'pointer', width: 'auto', backgroundColor: props.selectedTechnician === row.id ? PXBColors.blue[50] : PXBColors.white[100] }}
            onClick={(): void => handleDisplay()}
        >
            <TableCell style={{ width: '0%', paddingLeft: 10 }}>
            </TableCell>
            <TableCell
                id="users-serviceManager-list-name"
                data-testid="users-serviceManager-list-name"
                align="left"
                style={{ width: 'auto' }}
                padding="checkbox"
            >
                {row.userName}
            </TableCell>

            <TableCell align="left" style={{ width: 'auto' }}
                id="users-serviceManager-list-cmpny-name"
                data-testid="users-serviceManager-list-cmpny-name"
                padding="checkbox">
                {row.company_name}
            </TableCell>

            <TableCell align="left" style={{ width: 'auto' }}
                id="users-serviceManager-list-customer-country"
                data-testid="users-serviceManager-list-item-customer-country"
                padding="checkbox">
                {row.company_country}
            </TableCell>

            <TableCell align="left" style={{ width: 'auto', paddingLeft: 10 }}
                id="users-serviceManager-list-item-status"
                data-testid="users-serviceManager-list-item-status">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <div style={{ minWidth: 25 }}>
                        <img
                            src={serviceManagerState.statusImage}
                            alt="logo"
                            style={{ display: 'flex', alignItems: 'right' }}
                            id="users-serviceManager-status-icon"
                            data-testid="users-serviceManager-status-icon"
                        /></div>
                    <Typography
                        id="users-serviceManager-status-button"
                        data-testid="users-serviceManager-status-button"
                        style={{ textTransform: 'none', marginLeft: 10, fontWeight: 600 }}
                        variant="body2">
                        {t(serviceManagerState.statusTitle)}
                    </Typography>
                </div>
            </TableCell>

            <TableCell style={{ width: '0%' }}
                id="users-serviceManager-list-item-next"
                data-testid="users-serviceManager-list-item-next" onClick={() => props.onSuccess(true)}>
                <IconButton edge="end" id="users-serviceManager-list-item-next-icon" style={{ marginLeft: "auto" }}>
                    <ChevronRight />
                </IconButton>
            </TableCell>
            <TableCell align="left" padding="checkbox" style={{ width: '0%', padding: 0 }}
                id="users-serviceManager-list-item-empty"
                data-testid="users-serviceManager-list-item-empty">
            </TableCell>
        </TableRow>
    );
};
