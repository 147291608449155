import * as ApiRequests from '../../api/requests';
import { History } from 'history';
import { pushAppRoute } from '../../data/domain/route-manager';
import { AuthenticationActions } from '../actions/authentication-actions';
import { AuthHelper } from '../../types';
import { AppThunk } from '../actions';
import { SessionStorage } from '../../components/AuthContextProvider/session-storage';

export const AuthenticationThunks = {
    login: (
        user: string,
        password: string,
        rememberMe: boolean,
        onAuthenticated: (args: { email: string; userId: string; expirationDate: Date; rememberMe: boolean, roleTypeId?: number },
        ) => void,
        platform: number,
        pnsHandle?: string,
        language?: number,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(AuthenticationActions.loginStarted());

        const payload = await ApiRequests.login(
            {
                user, // email address
                password,
                pnsHandle,
                platform,
                language
            },
            { authHelper: undefined }
        );

        if (payload.ok) {

            SessionStorage.saveAuthToken(payload.response.data.token);

            onAuthenticated({
                email: payload.response.data.email,
                userId: payload.response.data.id,
                expirationDate: new Date(payload.response.data.expiration_date),
                rememberMe: rememberMe,
                roleTypeId: payload.response.data.roleTypeId
            });
            dispatch(AuthenticationActions.loginSucceeded());
            return;
        }

        console.error(
            'Error Code',
            ApiRequests.extractResponseNotOkCode(payload.response),
            'Description',
            ApiRequests.extractResponseNotOkMessage(payload.response)
        );

        const errorCode = ApiRequests.extractResponseNotOkCode(payload.response);
        const errorMessage =
            payload && (payload.httpStatusCode === 401 || payload.httpStatusCode === 404)
                ? 'LOGIN.INVALID_CREDENTIALS'
                : 'LOGIN.GENERIC_ERROR';
        dispatch(AuthenticationActions.loginFailed(errorCode, errorMessage));

    },

    logout: (
        userId: string | undefined,
        history: History<unknown>,
        onLoggedOut: () => void,
        authHelper: AuthHelper
    ): AppThunk => async (): Promise<void> => {
        // Important to route to login screen first otherwise user will be
        // redirected back to current page after they log back in, which is jarring,
        // and unexpected.
        pushAppRoute(history, { type: 'Login' });
        onLoggedOut();

        // Ideally the API call works and the user is "officially" logged out. But definitely
        // don't need to wait for this before letting the user think they're logged out. And
        // if this doesn't work, the reason is because the session has most likely already
        // expired.
        if (userId) {
            await ApiRequests.logoff({ userId }, { authHelper: authHelper });
            SessionStorage.saveAuthToken('');
        }
    },

    setPassword: (code: string, password: string, authHelper?: AuthHelper): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(AuthenticationActions.setPasswordStarted());

        const payload = await ApiRequests.resetPassword({ resetcode: code, newpassword: password }, { authHelper });

        if (payload.ok) {
            dispatch(AuthenticationActions.setPasswordSucceeded());

            return;
        }
        const errorCode = ApiRequests.extractResponseNotOkCode(payload.response);

        dispatch(AuthenticationActions.setPasswordFailed(errorCode.toString()));
    },

    forgotPassword: (email: string): AppThunk => async (dispatch): Promise<void> => {
        dispatch(AuthenticationActions.resetPasswordStarted());
        const payload = await ApiRequests.resetPasswordRequest({ email }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(AuthenticationActions.resetPasswordSucceeded());

            return;
        }

        dispatch(AuthenticationActions.resetPasswordFailed('Could not reset your password at this time.'));
    },

    verifyResetCode: (code: string): AppThunk => async (dispatch): Promise<void> => {
        dispatch(AuthenticationActions.verifyResetCodeStarted(code));

        const response = await ApiRequests.validateResetPasswordRequest({ resetcode: code }, { authHelper: undefined });

        if (response.ok) {
            dispatch(AuthenticationActions.verifyResetCodeSucceeded(code));

            return;
        }

        dispatch(AuthenticationActions.verifyResetCodeFailed(code));
    },
};
