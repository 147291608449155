// eslint-disable-next-line
import React, { ChangeEvent, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as PXBColors from '@brightlayer-ui/colors';

// Material-UI components
import { Button, CardActions, CardContent, CardHeader, CircularProgress, Divider, TextField, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

//@ts-ignore
import { EmptyState } from '@brightlayer-ui/react-components';

import { BrandedCardContainer } from '../../components/BrandedCardContainer/BrandedCardContainer';
import { CONTACT_PHONENO } from '../../constants';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import { AuthenticationActions } from '../../actions/actions/authentication-actions';
import { validEmail } from '../../lib/form-validator';
import Grid from '@mui/material/Grid';

type Props = {
    email: string;
    changeNextEnabled: Function;
};

export const RegisterForgotPassword: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();

    const forgetPassword = useSelector(Selectors.Authentication.forgotPassword);

    const [email, setEmail] = useState(props.email);

    const dispatch = useDispatch();

    const canContinue = (): boolean => validEmail(email) && !forgetPassword.loading;

    const goToRegistration = (state: boolean): void => {
        //Go to registration login flow
        if (props.changeNextEnabled) {
            props.changeNextEnabled(state);
        }
    };

    const onContinue = (): void => {
        if (forgetPassword.success) {
            dispatch(AuthenticationActions.resetPasswordReset());
            goToRegistration(true);
        } else {
            dispatch(Thunks.Authentication.forgotPassword(email));
        }
    };

    const onBack = (): void => {
        goToRegistration(true);
    };

    let body: JSX.Element;
    if (forgetPassword.loading) {
        body = (
            <EmptyState style={{ flex: 1, backgroundColor: PXBColors.white[50] }}
            icon={<CircularProgress id="progress-spinner" />} title={''} placeholder={undefined} />
        );
    } else if (forgetPassword.success) {
        body = (
            <div
                style={{ display: 'flex', flex: '1 1 0%', justifyContent: 'center', height: '100%' }}
                data-testid="forgot-password-confirmation-content"
            >
                <EmptyState
                    icon={<CheckCircle style={{ fontSize: 100, marginBottom: 16 }} />}
                    title={t('MESSAGES.EMAIL_SENT')} placeholder={undefined}                />
            </div>
        );
    } else {
        body = (
            <div data-testid="forgot-password-entry-content">
                <Typography>
                    <Trans i18nKey={'FORGOT_PASSWORD.INSTRUCTIONS'}></Trans>
                    <a href={`tel:${CONTACT_PHONENO}`} style={{ textDecoration: 'none' }}>
                        {CONTACT_PHONENO} .
                    </a>
                </Typography>

                <Divider style={{ margin: '32px 0' }} />

                <TextField
                    label={t('LABELS.EMAIL')}
                    fullWidth
                    id="email"
                    value={email}
                    onChange={(evt: ChangeEvent<HTMLInputElement>): void => setEmail(email)}
                    variant="filled"
                    error={Boolean(forgetPassword.errorMessage)}
                    helperText={forgetPassword.errorMessage ? t('FORGOT_PASSWORD.ERROR') : ''}
                    inputProps={{
                        'data-testid': 'email-input',
                    }}
                />
            </div>
        );
    }

    return (
        <BrandedCardContainer>
            <>
                <CardHeader
                    data-testid="title"
                    title={
                        <Typography variant={'h6'} style={{ fontWeight: 600 }}>
                            {t('HEADER.FORGOT_PASSWORD')}
                        </Typography>
                    }
                />
                <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }}>{body}</CardContent>
                <Divider />
                <CardActions style={{ padding: 16 }}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ width: '100%' }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={forgetPassword.success}
                            onClick={onBack}
                            style={{ width: 100 }}
                            data-testid="back-button"
                        >
                            {t('ACTIONS.BACK')}
                        </Button>
                        <Button
                            variant="contained"
                            disabled={!canContinue()}
                            color="primary"
                            onClick={onContinue}
                            style={{ width: 100 }}
                            data-testid="next-button"
                        >
                            {forgetPassword.success ? t('ACTIONS.DONE') : t('ACTIONS.OKAY')}
                        </Button>
                    </Grid>
                </CardActions>
            </>
        </BrandedCardContainer>
    );
};
