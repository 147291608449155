// eslint-disable-next-line
import React from 'react';
import { Grid } from '@mui/material';
import { InvitationRequestList, UserInvitationData } from '../../types/manage-users';
import { ApprovalInviteCard } from './ApprovalInviteCard';

type Props = {
    datalist: InvitationRequestList;
    OnItemSelected: Function;
    isSelectedAll: boolean;
};

export const ApprovalInviteList: React.FC<Props> = (props: Props) => {

    const setSelectedData = (userSelectedData: UserInvitationData): void => {
        props.OnItemSelected(userSelectedData);
    };

    return (
        <>
            <div>
                <Grid data-testid="requested-invite-container" container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {props.datalist.map((invitationRequest, index) => (
                        <Grid item xs={4} sm={6} md={6} key={index} data-testid="requested-invite-list">
                            <ApprovalInviteCard userInvitationData={invitationRequest} onCheckboxClick={setSelectedData} isSelectedAll={props.isSelectedAll} />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </>
    )

}