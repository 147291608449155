// eslint-disable-next-line
import React from 'react';
import { Card, Theme } from '@mui/material';

import backgroundImage from '../../assets/images/Login_Tile_Background.png';
import { createStyles, StyleRules, withStyles } from '@mui/styles';
import { WithStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme): StyleRules =>
    createStyles({
        card: {
            width: '100%',
            height: '100%',
            maxWidth: '450px',
            maxHeight: '710px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
        },
        brandingPanel: {
            height: '100%',
            width: '100%',
            backgroundColor: '#007bc1',
            backgroundImage: `url(${backgroundImage}), linear-gradient(180deg, rgba(3, 98, 167, 100) 0%, rgba(3, 98, 167, 0) 100%)`,
            backgroundSize: ' 600px 600px, auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundRepeat: 'repeat, no-repeat;',
        },
    });

type BannerProps = {
    children: JSX.Element;
} & WithStyles

const Banner = ({ classes, ...props }: BannerProps): JSX.Element => (
    <div className={classes.brandingPanel} data-testid="brand-card">
        <Card className={classes.card}>{props.children}</Card>
    </div>
);

export const BrandedCardContainer = withStyles(styles)(Banner);
