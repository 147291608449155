// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { GenericToolbar } from '../../components/GenericToolbar';
import * as PXBColors from '@brightlayer-ui/colors';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ReportsBuildings } from './ReportsBuildings';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { useHistory } from 'react-router';
import { ItemList } from '../../types/logbook-type';
import { ReportsSystems } from './ReportsSystems';
import { pushAppRoute } from '../../data/domain/route-manager';
import { ENTITY_TYPE } from '../enum/ENUM';


export const Reports: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const { t } = useLanguageLocale();
    const history = useHistory();
    const [projectId, setProjectId] = useState('');
    const [buildingId, setBuildingId] = useState('');
    const TAB_STATE_KEY = 'backState';
    const TAB_BUILDING = 0;
    const TAB_BUILDING_KEY = 'Building';
    const TAB_SYSTEM = 1;
    const TAB_SYSTEM_KEY = 'System';

    function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
        const { children, value, index, ...other } = props;

        return <div {...other}>{value === index && <Box p={2}>{children}</Box>}</div>;
    }

    const getTabState = (): number => {
        const tabState = sessionStorage.getItem(TAB_STATE_KEY);
        switch (tabState) {
            case TAB_SYSTEM_KEY:
                return TAB_SYSTEM;
            case TAB_BUILDING_KEY:
                return TAB_BUILDING;
            default:
                return TAB_BUILDING;
        }
    };

    const updateTabSession = (tab: string): void => {
        sessionStorage.setItem(TAB_STATE_KEY, tab);
    };

    const [value, setValue] = React.useState(getTabState());

    updateTabSession(TAB_BUILDING_KEY);

    const handleChange = (_event: any, newValue: React.SetStateAction<number>) => {
        setProjectId('');
        setBuildingId('');
        setValue(newValue);
    };
    const useOrganizationDataList = useOrganizationList();
    const [organizationListData, setOrganizationListData] = React.useState<ItemList>([]);

    useEffect(() => {
        setOrganizationListData(useOrganizationDataList.organizationList);
    }, [useOrganizationDataList, useOrganizationDataList.organizationList]); //eslint-disable-line react-hooks/exhaustive-deps

    const setSelectedBuildingId = (selectedName: string, buildingId: string): void => {
        //TOdo- handle systemId when report list is available
        pushAppRoute(
            history,
            { type: 'ReportsDetails', entityId: buildingId, entityType: ENTITY_TYPE.BUILDING },
            { backRoute: 'Reports', buildingId: buildingId, name: selectedName }
        );
    };
    const setSelectedSystem = (selectedName: string, systemId: string, systemType: string): void => {
        updateTabSession(TAB_SYSTEM_KEY);
        pushAppRoute(history,
            { type: 'ReportsDetails', entityId: systemId, entityType: ENTITY_TYPE.SYSTEM },
            { backRoute: 'Reports', systemId: systemId, name: selectedName, systemType: systemType });
    };

    return (
        <>
            <div data-testid="reports-container">
                <GenericToolbar title={t('DASHBOARD.DASHBOARD_REPORTS_TITLE')} />
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <AppBar position="static" style={{ backgroundColor: PXBColors.blue[700] }}>
                        <Tabs value={value} onChange={handleChange}
                            style={{ marginLeft: 52 }}
                            sx={{
                                "& .MuiTabs-indicator": {
                                    backgroundColor: "White",
                                    height: 2,
                                }
                            }}
                        >
                            <Tab data-testid='tab-building' label={t('ASSETS_DETAILS.BUILDINGS')} style={{ textTransform: 'none' }} />
                            <Tab data-testid='tab-systems' label={t('ASSETS_DETAILS.SYSTEMS')} style={{ textTransform: 'none' }} />
                        </Tabs>
                    </AppBar>
                    <TabPanel style={{ marginLeft: '2%', marginRight: '2%' }} value={value} index={TAB_BUILDING}>
                        <ReportsBuildings onClick={setSelectedBuildingId} orgList={organizationListData} />
                    </TabPanel>
                    <TabPanel style={{ marginLeft: '2%', marginRight: '2%' }} value={value} index={TAB_SYSTEM}>
                        <ReportsSystems onClick={setSelectedSystem} orgList={organizationListData} defaultProject={projectId} defaultBuilding={buildingId} />
                    </TabPanel>
                </Box>
            </div>
        </>
    );
};
