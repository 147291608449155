const resources = {
    translation: {
      DASHBOARD: {
        LOCATION_CARD: {
          HEADER: {
            SUBTITLE: {
              NO_DATA: "0",
              ALARM: "{{count}} Alarm",
              ALARM_plural: "{{count}} Alarme",
              ALARM_noData: "0 Alarme",
              NORMAL: "Normal",
  },
            INFO: {
              SEPARATOR : ",",
              DEVICE: "{{count}} Gerät",
              DEVICE_plural: "{{count}} Geräte",
              SITE: "{{count}} Ort",
              SITE_plural: "{{count}} Orte",
              DEVICE_noData: "0 Geräte",
              SITE_noData  : "0 Websiten",
  },
  },
          BODY: {
            ALARMS: "{{count}} Alarm",
            ALARMS_plural: "{{count}} Alarme",
            EVENTS: "{{count}} Ereignis",
            EVENTS_plural: "{{count}} Ereignisse",
            ALARMS_noData: "0 Alarme",
            EVENTS_noData: "0 Ereignisse",
  },
          FOOTER: {
            VIEW_LOCATION: "Ort anzeigen",
  },
  },
        SITE_CARD: {
          HEADER: {
            SUBTITLE: {
              NO_DATA: "--",
  },
            INFO: {
              DEVICE: "{{count}} Gerät",
              DEVICE_plural: "{{count}} Geräte",
              DEVICE_noData: "0 Geräte",
  },
  },
          BODY: {
            ONLINE_noData: "--'",
            ALARMS: "{{count}} Alarm",
            ALARMS_plural: "{{count}} Alarme",
            EVENTS: "{{count}} Ereignis",
            EVENTS_plural: "{{count}} Ereignisse",
            ALARMS_noData: "0 Alarme",
            EVENTS_noData: "0 Ereignisse",
            OFFLINE: "Offline",
            ONLINE: "Online",
  },
          FOOTER: {
            VIEW_LOCATION: "Website ansehen",
  },
  },
        TIMELINE_DETAILS: {
          ACKNOWLEDGED: "BESTÄTIGT",
          OPEN: "AKTIV",
          CLOSED: "GESCHLOSSEN",
          EARLIER: "Früher",
          FUTURE: "Zukunft",
          INACTIVE: "INAKTIV",
          LAST_WEEK: "Letzte Woche",
          THIS_WEEK: "Diese Woche",
          TIMELINE: "Zeitleiste",
          TODAY: "Heute",
          UNACKNOWLEDGED: "Unbestätigt",
          YESTERDAY: "Gestern",
  },
        ADD_BUILDING: "Gebäude hinzufügen",
        AUTO_REDIRECT_BANNER: {
          ADD_LOCATION_BUTTON: "Ort hinzufügen",
          DISMISS: "Verwerfen",
          MESSAGE: "Sie wurden automatisch zu {{location}} weitergeleitet, da Sie keine anderen Orte der obersten Ebene haben.",
  },
        USERS_ADMIN: "Administrator",
        USERS_ADMIN_S: "Administratoren",
        ADMINS_TITLE: "Administratoren",
        SITE_DETAILS: {
          READING_CARDS: {
            ALARM: "Alarm",
            BATTERY: "Batterie",
            FULL: "Voll",
            OFFLINE: "Offline",
            ONLINE: "Online",
            PENDING: "Ausstehend",
            UNKNOWN: "Unbekannt",
            VIEW_ALL: "Alle anzeigen",
  },
          ALL_DETAILS: "Alle Details",
          ALL_DEVICES: "Alle Geräte",
          TRENDS_CARD: {
            CHANNELS: "Kanäle",
            DAY: "Tag",
            DAYS: "Tage",
            TITLE: "Trends",
            TRENDVIEWER: "Trendviewer",
            VIEW_ALL: "Vollständigen Trendviewer anzeigen",
  },
          DEVICES_CARD: {
            TITLE: "Geräte",
            VIEW_ALL: "Alle Geräte anzeigen",
  },
          TIMELINE_CARD: {
            TITLE: "Zeitleiste",
            VIEW_ALL_EVENTS: "Alle Ereignisse anzeigen",
  },
  },
        ALL_BUILDINGS_COUNT: "Alle Gebäude ({{count}})",
        ALL_LOCATIONS: "Alle Orte",
        ALL_ORGANIZATION: "Alle Organisationen",
        ALL_DEVICES_COUNT: "Alle Systeme ({{count}})",
        ADMINS_AND: "und",
        DASHBOARD_ASSETS_TITLE: "Systeme",
        STATUS_COUNT_BLOCKED: "BLOCKIERT ({{count}})",
        BUILDING_CONTACT: "Gebäudekontakt",
        BUILDING_CONTACT_DETAILS_LABEL: "Kontaktdaten Gebäude",
        CHOOSE_LOCATION: "Ort wählen",
        CONTACT_MANAGER: " Kontaktieren Sie Ihren Account Manager oder",
        ADD_ORGANIZATION: "Organisation erstellen",
        OVERVIEW_TITLE: "Dashboard",
        DASHBOARD_TITLE: "Dashboard",
        DEVICES_TITLE: "Geräte",
        EARLIER: "Früher",
        EDIT_CONTACT_BUTTON: "Kontaktdaten bearbeiten",
        BUILDING_DETAIL_EMAIL: "E-Mail",
        ERROR_LOADING_CHANNELS: "Fehler beim Laden der Kanäle",
        ERROR_LOADING_DATA: "Fehler beim Laden der Daten",
        ERROR_LOADING_DEVICES: "Fehler beim Laden der Geräte",
        ERROR_LOADING_EVENT: "Fehler beim Laden des Ereignisses",
        ERROR_LOADING_EVENTS: "Fehler beim Laden von Ereignissen",
        ERROR_LOADING_LOCATION: "Fehler beim Laden des Standorts",
        ERROR_LOADING_LOCATIONS: "Fehler beim Laden der Standorte",
        ERROR_LOADING_ORGANIZATION: "Fehler beim Laden der Organisation",
        ERROR_LOADING_SITE: "Fehler beim Laden der Seite",
        ERROR_LOADING_SITES: "Fehler beim Laden der Seiten",
        ERROR_LOADING_TRENDS: "Fehler beim Laden der Trends",
        EVENT_NOT_FOUND: "Ereignis nicht gefunden",
        STATUS_COUNT_ANOMALY: "IN STÖRUNG ({{count}})",
        LAST_90_DAYS: "Letzte 90 Tage",
        LATEST_LOGS: "Neueste Ereignisse",
        LOCATION_NOT_FOUND: "Standort nicht gefunden",
        LOCATIONS_TITLE: "Standorte",
        LOCATION: "Standorte",
        DASHBOARD_LOGBOOK_TITLE: "Prüfbuch ",
        MAIN_CONTACT_LABEL: "Hauptkontakt Gebäude",
        ADMINS_MORE: "mehr",
        DASHBOARD_MANAGE_ORGANIZATION_TITLE: "Meine Organisationen",
        NO_FOUND: "Kein {{entity}} gefunden",
        NO_EVENTS: "Keine Alarme",
        NO_CHANNELS: "Keine Kanäle",
        NO_CHANNELS_ENABLED: "Keine Kanäle aktiviert",
        NO_DATA: "Keine Daten",
        NO_DATA_POINTS: "Keine Daten für den angegebenen Bereich",
        NO_DEVICES: "Keine Geräte",
        NO_LOCATION_SELECTED: "Kein Standort ausgewählt",
        NO_LOCATIONS: "Keine Standorte",
        NO_ORGANIZATION_SELECTED: "Keine Organisation ausgewählt",
        NO_SITES: "Keine Seiten",
        NO_TRENDS: "Keine Trends",
        NO_USER_DATA: "Keine Benutzerdaten",
        STATUS_COUNT_OFFLINE: "OFFLINE ({{count}})",
        ORGANIZATION_TITLE: "ORGANISATION",
        ORGANIZATION_NOT_FOUND: "Organisation nicht gefunden",
        ORGANIZATION: "Organisationen",
        BUILDING_DETAIL_PHONE: "Telefon",
        PROJECT: "Projekt",
        STATUS_COUNT_READY: "BEREIT ({{count}})",
        DASHBOARD_REPORTS_TITLE: "Berichte",
        SAVE_USER_DETAILS: "Benutzerdetails speichern",
        SECONDARY_CONTACT_LABEL: "Alternativkontakt Gebäude",
        SITE_NOT_FOUND: "Seite nicht gefunden",
        SITE: "Seite",
        TIMELINE_TITLE: "Zeitleiste",
        NEW_SITE: "um beizutreten oder eine neue {{entity}} zu erstellen.",
        UNKNOWN_DEVICE_TYPE: "Unbekannt",
        EDIT_USER_DETAILS: "Benutzerdetails",
        USERS_TITLE: "Benutzer",
        VIEW_LOGBOOK: "Prüfbuch anzeigen",
        USERS_VIEWER: "Zuschauer",
        USERS_VIEWER_S: "Zuschauer",
        NOT_ACCESS: "Sie haben noch keinen Zugriff auf {{entity}}.",
  },
      EVENT_DETAILS: {
        UNKNOWN: "--",
        ACKNOWLEDGE: "Bestätigen",
        ACKNOWLEDGED: "Bestätigt",
        ACKNOWLEDGE_BY: "Bestätigt von {{user}} am",
        ACKNOWLWEGED_ERROR: "Bestätigung konnte am {{errorDate}} nicht an das Endgerät übermittelt werden. Wenn dieses Problem weiterhin besteht, rufen Sie bitte unser Support-Team unter {{phoneno}} an oder senden Sie eine E-Mail ",
        DEVICE_MODEL: "Gerätemodell",
        DEVICE_SERIAL: "Geräteseriennummer",
        ERROR_LOADING_DEVICE_DETAILS: "Fehler beim Laden der Gerätedetails",
        ERROR_LOADING_EVENT_DETAILS: "Fehler beim Laden der Ereignisdetails",
        TITLE: "Ereignisdetails",
        ALARM_STATUS: "Anfänglicher Alarmstatus",
        LAST_DATA_RECEIVED: "Letztes empfangenes Alarm-Update",
        NO_DEVICE_DETAILS: "Keine Gerätedetails",
        NO_EVENT_DETAILS: "Keine Ereignisdetails",
        PLEASE_RETRY: "Bitte erneut versuchen:",
        SENDING: "Senden...",
        SET_POINT: "Sollwert (Schwelle)",
        THRESHOLD: "Schwelle",
        TIME_ALARM_SENT: "Zeitpunkt der Alarmsendung",
        EMPTY_VALUE: "nan",
  },
      DEVICE_DETAILS: {
        EMPTY_VALUE: "--'",
        USERS_ADMINS: " Administrator",
        USERS_VIEWER: " Zuschauer",
        FIRMWARE_AVAILABLE_GUIDE_LINK: "(Guide)",
        USERS_COUNTS: "{{adminCount}} Administrator, {{viewerCount}} Zuschauer",
        FIRMWARE_AVAILABLE_GUIDE: "{{availableVersion}} verfügbar",
        LUMINARY_ATSD: "SKU #",
        CONNECTION_TYPE_BLE: "BLE",
        BLOCK: "Blockiert",
        BUILDING: "Gebäude",
        CONNECTION_TYPE_CELLULAR: "Mobilfunk",
        LUMINARY_CIRCUIT: "Stromkreis ",
        LUMINARY_CIRCUIT_HASH: "Stromkreis #",
        CITY: "Stadt",
        CONNECTED: "Verbunden",
        CONNECTION_TYPE: "Verbindungstyp",
        DATE_COMMISSIONED: "Inbetriebnahmedatum",
        DEVICE_MODEL: "Gerätemodell",
        DEVICE_SERIAL: "Geräteseriennummer",
        DURATION_TEST: "Betriebsdauertest",
        DURATION_TEST_DURATION: "Betriebsdauertest Testzeit",
        ERROR: "Störung",
        CHANNEL_DATA_ERROR: "Fehler beim Laden der Kanaldaten",
        DEVICE_CONNECTION_ERROR: "Fehler beim Laden der Verbindungsdaten",
        DEVICE_DETAILS_ERROR: "Fehler beim Laden der Gerätedetails",
        CONNECTION_TYPE_ETHERNET: "Ethernet",
        EVENT: "Ereignis ",
        FIRMWARE_VERSION: "Firmware Version",
        FUNCTIONAL_TEST: "Funktionstest",
        DELAY_FUNCTIONAL_TEST: "Funktionstestverzögerung",
        GATEWAY_ADDRESS: "Gateway-Adresse",
        GATEWAY_NAME: "Gateway-Name",
        GATEWAY_COUNT: "Gateway von {{systemCount}} System",
        GATEWAY_COUNT_SYSTEMS: "Gateway von {{systemCount}} Systemen",
        GATEWAYS: "Gateways",
        HOSTNAME: "Hostname",
        LUMINARY_DETAIL_INFO: "Info",
        IPADDRESS: "IP-Adresse ",
        LAST_DATA: "Letzte Daten",
        LUMINAIRE: "Leuchte",
        LUMINARY_ADDRESS: "Leuchtenadresse",
        LUMINAIRES: "Leuchten ",
        LUMINARIES_COUNTS: "Leuchten ",
        MAC_ADDRESS: "MAC-Adresse",
        CONNECTION_TYPE_MODBUS: "Modbus",
        LUMINARY_MODEL: "Modell",
        CONNECTION_TYPE_MQTT: "MQTT",
        LUMINARY_NAME: "Name",
        NAME: "Name",
        NETWORK_DETAILS: "Netzwerkdetails",
        NEXT: "Nächste ",
        LUMINARY_DISCRIPTION: "Nächster Betriebsdauertest beginnt",
        NEXT_DURATION_TEST: "Nächster Betriebsdauertest beginnt",
        NEXT_FUNCTIONAL_TEST: "Nächster Funktionstest beginnt",
        NO_CHANNEL_DATA: "Keine Kanaldaten",
        NO_DEVICE_CONNECTION: "Keine Geräteverbindungsdaten",
        NO_DEVICE_DETAILS: "Keine Gerätedetaildaten",
        OFFLINE: "Offline",
        ONLINE: "Online",
        ORGANIZATION: "Organisation",
        PART_NUMBER: "Materialnummer",
        PREVIOUS: "Vorherige",
        PREVIOUS_DURATION_TEST: "Vorheriger Betriebsdauertest begann",
        PREVIOUS_FUNCTIONAL_TEST: "Vorheriger Funktionstest begann",
        READY: "Bereit",
        CONNECTION_TYPE_RS485: "RS485",
        SERIAL_NUMBER: "Seriennummer",
        GATEWAY_SHOW_ALL: "Alles anzeigen",
        STATUS: "Status",
        LUMINARY_STATUS: "Status",
        SUBNET_MASK: "Subnetzmaske ",
        SYSTEM: "SYSTEM",
        SYSTEMS: "Systeme",
        LUMINARY_DETAIL_TEST: "Tests",
        TYPE: "Typ ",
        LUMINARY_TYPE: "Typ",
        UPDATE_REQUIRED: "UPDATE ERFORDERLICH",
        USERS: "Benutzer",
        WARNING: "Warnung",
        CONNECTION_TYPE_WIFI: "W-LAN",
        CONNECTION_TYPE_WIREPASS: "Wirepass",
  },
      FORMS: {
        OPTIONAL: " (Optional)",
        BUILDING_COMPANY_ADDRESS: "Adresszeile 1",
        BUILDING_COMPANY_ADDRESS2: "Adresszeile 2 (Optional)",
        BUILDING_DETAILS: "Gebäudedetails",
        CITY: "Stadt",
        USER_ADDRESS_1: "Unternehmensadresse Zeile 1",
        USER_ADDRESS_2: "Unternehmensadresse Zeile 2",
        COMPANY_NAME: "Unternehmensname",
        CONFIRM_PASSWORD: "Passwort bestätigen",
        COUNTRY: "Land *",
        HELPING_TEXT: "Felder mit einem Sternchen (*) sind Pflichtfelder",
        FIRST_NAME: "Vorname",
        LAST_NAME: "Nachname",
        LOCAL_PRIMARY_EMAIL: "Hauptkontakt E-Mail-Adresse",
        LOCAL_PRIMARY_CONTACT_INFO: "Hauptkontakt Informationen",
        LOCAL_SECONDARY_EMAIL: "Alternativkontakt E-Mail-Adresse",
        LOCAL_SECONDARY_CONTACT_INFO: "Alternativkontakt Informationen",
        ORGANIZATION_ADDRESS_HEADER: "Organisationsadresse",
        COMPANY_ADDRESS: "Organisationsadresse Zeile 1",
        ADDRESS_LINE_2: "Organisationsadresse Zeile 2",
        ORGANIZATION_DETAILS_HEADER: "Organisationsdetails",
        PASSWORD: "Passwort ",
        PASS_MATCH_ERROR: "Passwörter stimmen nicht überein",
        PHONE_NUMBER: "Telefonnummer",
        INVALID_EMAIL_ERROR: "Bitte gültige Email-Adresse eingeben",
        INVALID_PHONE_NUMBER: "Bitte gültige Email-Adresse eingeben",
        INVALID_POSTAL_CODE: "Bitte gültige Postleitzahl eingbeen",
        POSTAL_CODE: "Postleitzahl",
        PRIMARY_CONTACT_INFORMATION: "Hauptkontakt Informationen",
        RESET_PASSWORD: "Passwort zurücksetzen",
        TOGGLE_PASSWORD_VISIBILITY: "Passwortsichtbarkeit umschalten",
        VERIFICATION_CODE: "Verifizierungscode",
  },
      LOGBOOK_DETAILS: {
        LABEL_EVENT_AGO: " vor",
        PART_NUMBER: "PN:",
        SERIAL_NUMBER: "SN:",
        ADD_COMMENTS: "+Kommentare hinzufügen",
        REQUIRES_FIRMWARE_UPDATE: "1 FIRMWARE-UPDATE ERFORDERLICH",
        ACKNOWLEDGE: "Bestätigen",
        ACKNOWLEDGED: "Bestätigt",
        ACKNOWLEDGED_BY: "Bestätigt durch",
        PREVIOUS_ACKNOWLEDGED_BY: "Bestätigt durch:",
        ACTIVE: "Aktiv",
        ACTIVE_ACKNOWLEDGED: "Aktiv . Bestätigt",
        AT: "bei",
        LABEL_CLOSED: "geschlossen",
        CLOSED: "Geschlossen",
        COMMENTS: "Kommentare",
        DESCRIPTION: "Beschreibung ",
        DEVICE: "Gerät ",
        EDIT: "Bearbeiten",
        EVENT: "Ereignis ",
        LABEL_EVENT_BEGAN : "Ereignis begann:",
        LABEL_EVENT_CLOSE: "Ereignis geschlossen:",
        LOGBOOKDETAIL: "Prüfbuch Detail",
        PARTNO: " PN:",
        PREVIOUS_LOG_ITEM: "Vorheriges Ereignis in diesem System",
        PREVIOUS_LOG_TITLE: "Vorheriges Ereignis in diesem System",
        SERIALNO: " SN:",
        STATUS: "Status",
        SYSTEM: "System ",
        TIMESTAMP: "Zeitstempel ",
        SYSTEM_TYPE : "Typ:",
  },
      ORGANIZATION_MANAGE: {
        BUILDING_COUNT: " Gebäude",
        BUILDING_COUNT_S: " Gebäude",
        BUILDINGS_TITLE: " Gebäude",
        ADMIN_COUNT: " Organisationsadministrator",
        ADMIN_COUNT_S: " Organisationsadministratoren",
        VIEWER_COUNT: " Organisationszuschauer",
        VIEWER_COUNT_S: " Organisationszuschauer",
        SYSTEM_COUNT: " System",
        SYSTEM_COUNT_S: " Systeme",
        ADD_NEW_BUILDING: "+ Neues Gebäude hinzufügen",
        CREATE_ORGANIZATION: "+ Organisation erstellen",
        ERROR_ADD_NEW_SYSTEM_ACTION: "Neue Systeme hinzufügen",
        ALL: "Alle ",
        SORT_TYPE_NAME: "Alphabet",
        SORT_TYPE_CITY: "Stadt",
        COMMENTS_HELPER: "Kommentare die Techniker vor Ort helfen",
        ORGANIZATION_NAME_HELPER: "Nur Buchstaben, Zahlen, Unterstriche und Bindestriche",
        MARK_AS_FAV_API_FAILURE_MESSAGE: "Als fehlgeschlagen markieren",
        ERROR_NO_BUILDING_FOUND: "Kein Gebäude gefunden",
        ERROR_NO_GATEWAY_FOUND: "Kein Gateway gefunden",
        ERROR_NO_LOGS_TITLE: "Keine Protokolle in diesem Zeitraum",
        ERROR_NO_LUMINARY_FOUND: "Keine Leuchten gefunden",
        ERROR_NO_ORG_FOUND: "Keine Organisation gefunden",
        ERROR_NO_REPORT_FOUND: "Keine Berichte gefunden",
        ERROR_NO_SYSTEM_FOUND: "Kein System gefunden",
        ERROR_NO_USER_FOUND: "Kein Benutzer gefunden",
        SORT_TYPE_NUMBER_OF_SYSTEMS: "Anzahl der Systeme",
        QUIT_EDIT_USER_DETAILS: "Bearbeitung beenden",
        QUIT_TITLE: "Bearbeiten von „{{orgName}}“ beenden?",
        ERROR_READ_INSTALLATION_MANUALS_ACTION: "Lesen Sie die Installationshandbücher",
        SEARCH: "Suchen",
        SEARCH_BUILDINGS: "Suchen",
        SEARCH_BUILDINGS_DASHBOARD: "Gebäude durchsuchen",
        SEARCH_SYSTEM_DASHBOARD: "Systeme durchsuchen",
        SORT_BY_HEADER: "SORTIERE NACH",
        STARRED_ORGANIZATIONS: "Favorisierte Organisationen",
        VIEW_ORGANIZATION: "Organisation anzeigen",
        ERROR_NO_DEVICE_TITLE: "Sie haben noch keine Anlagen in dieser Organisation.",
        ERROR_NO_DEVICE_DESCRIPTION: "Sie erhalten Statusinformationen über Ihre Notleuchten, sobald Sie dieser Organisation Anlagen (Systeme) hinzufügen.",
        QUIT_MESSAGE: "Ihre nicht gespeicherten Änderungen gehen verloren.",
  },
      ADD_BUILDING: {
        BLDG_ORG_CONTACT: " Kontakte",
        BLDG_ORG_CONTACTS: "s Kontakte",
        BLDG_ADDITIONAL_DESC: "Fügen Sie optional zusätzliche Kommentare hinzu, um Ihr Serviceteam zu unterstützen.",
        BLDG_ADD_DESCRIPTION: "Zusätzliche Beschreibungen",
        BLDG_ADDRESS: "Adresse",
        BLDG_CREATTION: "Gebäude erstellt",
        BLDG_HEADER: "Neues Gebäude erstellen für",
        BLDG_LOCATION_MESSAGE: "Ziehen Sie die Karte, um die Markierung in der Nähe des Gebäudestandorts zu platzieren. Dadurch können Außendiensttechniker zukünftig schnell vor Ort sein.",
        BLDG_EDIT_BTN: "Kontaktdaten bearbeiten",
        BLDG_NAMELENGTH: "Bsp. Hauptbüro, Lager",
        BLDG_INITILIZING_NETWORK: "Netzwerk initialisieren...",
        BLDG_INSIDE: "innen",
        BLDG_LOCAL_CONTACT: "Gebäude Hauptkontakt",
        BLDG_CONTACT_TITLE: "Gebäude Kontaktdetails",
        BLDG_LOCATE_ON_MAP: "Suchen Sie stattdessen auf einer Karte",
        BLDG_LOCATION: "Standort ",
        BLDG_ADDTIONAL_DETAILS: "Fügen wir nun einige zusätzliche Details hinzu, um die Einrichtung abzuschließen",
        ORG_CREATE_SUCESS: "Organisation erfolgreich erstellt",
        BLDG_PRIMARYCONTACT: "Hauptkontakt",
        BLDG_PRIMARY_LOCAL_CONTACT: "Lokaler Hauptkontakt",
        BLDG_SECONDARYCONTACT: "Alternativkontakt (optional)",
        BLDG_SECONDARY_LOCAL_CONTACT: "Lokaler Alternativkontakt",
        BLDG_NO_POSTAL_ADDRESS: "Wählen Sie diese Option, wenn keine Postanschrift verfügbar ist",
        BLDG_CREATED_MESSAGE: "Erfolgreich erstellt",
        BLDG_ORGANIZATION_FOR: "Organisation benutzen",
        BLDG_ADD_CONTACT: "An wen sollen sich Elektriker im „Hauptbüro“ wenden?",
        SAVE_CHANGES_MSG: "Ihre Änderungen wurden gespeichert",
  },
      USER_SETTINGS: {
         AUTOMATIC_LOGOUT_SUBTITLE: " Den automatischen Ablauf der Sitzung nach 15 Minuten deaktivieren",
        ALARMS: ".",
        PHONE_NO_TEXT: "Für den Empfang von SMS-Benachrichtigungen ist eine Handynummer erforderlich.",
        ACCOUNT: "Konto",
        ACCOUNT_CREATED_DATE: "Konto erstellt",
        ADDRESS: "Adresse",
        ALARMS_TITLE: "Alarme",
        APPLICATION_THEME_COLOR: "App-Farbschema",
        AUTOMATIC_LOGOUT: "Automatisches ausloggen",
        UNITS_DESCRIPTION: "Britische imperiale Einheiten",
        BUILDING_NAME: "Gebäudename",
        TIMEZONE_DESCRIPTION: "Mitteleuropäische Zeit (GMT-1:00)",
        CHANGE_PASSWORD: "Passwort ändern ",
        COMPANY_ADDRESS: "Firmenanschrift",
        COMPANY_NAME: "Unternehmensname",
        COMPANY_PHONE_NUMBER: "Telefonnummer des Unternehmens",
        DATE_FORMAT: "Datumsformat",
        DATEFORMAT_DESCRIPTION: "TT/MM/YYYY",
        DELETE_ACCOUNT: "Konto löschen",
        STATUS_UPDATES: "Gerät befindet sich im blockierten Modus, Notbetrieb, Wartung erforderlich",
        AUTOMATIC_LOGOUT_TITLE: "Automatisches ausloggen deaktivieren",
        EMAIL: "E-Mail-Adresse",
        EMAIL_NOTIFICATION: "E-Mail Benachrichtigungen",
        TEXT_NOTIFICATION_TITLE: "Textbenachrichtigungen (SMS) aktivieren",
        ENABLED: "Aktiviert ",
        TEXT_NOTIFICATION_BODY: "Bei Aktivierung von SMS-Benachrichtigungen können zusätzliche Gebühren für Textnachrichten bei Ihrem Mobilfunkanbieter anfallen. Diese Gebühren sind nicht in der Eaton-Servicevereinbarung enthalten.",
        LANGUAGE_DESCRIPTION: "Englisch – Großbritannien",
        HEALTH_REPORT_TITLE: "Statusbericht",
        LANGUAGE: "Sprache ",
        HEALTH_REPORT: "Monatsbericht",
        PRODUCT_UPDATES: "Neue Versionen, Firmware-Updates verfügbar",
        NOTIFICATIONS_HEADER: "Benachrichtigungen",
        APP_THEME_DESCRIPTION: "Betriebssystem-Thema",
        ORGANIZATION: "Unternehmen",
        ORGANIZATION_NAME: "Organisationsname",
        PASSWORD: "Passwort ",
        PHONE_NUMBER: "Telefonnummer",
        PREFERENCES_HEADER: "Einstellungen",
        PRODUCT_UPDATES_TITLE: "Produkt-Updates",
        STATUS_UPDATES_TITLE: "Statusupdates",
        TEXT_NOTIFICATION: "Textbenachrichtigungen (SMS)",
        AUTOMATIC_LOGOUT_BODY: "Dadurch wird eine in der Anwendung enthaltene Standardsicherheitsfunktion außer Kraft gesetzt, die den Zugriff auf eine abgebrochene Sitzung verhindert. Bitte beachten Sie, dass der Benutzer für den Schutz des unbefugten Zugriffs auf dieses System verantwortlich ist und diese Anwendung niemals unbeaufsichtigt geöffnet lassen sollte.",
        TIME_ZONE: "Zeitzone",
        UNITS: "Einheiten",
        ORG_ADDRESS: "Verwenden der Organisationsadresse",
        ORG_CONTACT_DETAILS: "Verwenden der Organisationskontaktinformationen",
        EDIT_USER_DETAILS: "Nutzerdetails",
        NAME: "Benutzername ",
        USER_NAME: "Benutzername",
        WARNING: "WARNUNG",
  },
      EDITUSER: {
         PROMOTE_CONFIRMATION: " Wenn dies beabsichtigt war, übermitteln Sie die Änderung.",
        DEMOTE_CONFIRMATION: "Wenn dies beabsichtigt war, nehmen Sie bitte unten im Abschnitt „Rolle/Standort“ dieses Formulars Änderungen vor.",
        DEMOTE: "Sie haben die Rolle dieses Organisationsadministrators geändert. Wenn Sie dies nicht beabsichtigen, wählen Sie „Abbrechen“.",
        PROMOTE: "Sie haben diesen Benutzer zum Organisationsadministrator befördert. Wenn Sie dies nicht beabsichtigen, wählen Sie „Abbrechen“.",
  },
      ADDUSER: {
        INVITEUSER: " Benutzer einladen",
        TITLE: "Benutzer hinzufügen",
        ASTERIKSINFO: "Mit einem Sternchen (*) gekennzeichnete Felder sind Pflichtfelder, die zum Einladen eines Benutzers erforderlich sind.",
        INVITESUCCESS: "Einladung erfolgreich!",
        SELECTROLE: "Wählen Sie eine Rolle für Standort(e)",
        ACCOUNTCREATED: "Die Einladung zur Kontoerstellung war erfolgreich.",
        EMAILRECEIVE: "Der Benutzer erhält innerhalb 5–10 Minuten eine E-Mail-Einladung zur Erstellung eines Kontos. Sie können ihren Status nicht sehen, bis die Einladung angenommen wurde.",
        ADDUSERINFORMATION: "Informationen zum Benutzerkonto",
  },
      GATEWAY: {
        MANAGE_CELLULAR_CONNECTION: " Mobilfunkverbindung verwalten",
        ENTRY_PASSWORD_TITLE: "Zugriff für",
        ADD_GATEWAYS: "Gerät hinzufügen",
        BLE: {
          DEVICE_STATUS: {
            ALARM: "Alarm",
            BLOCKED: "Blockiert ",
            NOT_PROVISIONED: "Nicht bereitgestellt",
            OFFLINE: "Offline",
            ONLINE: "Online",
            PROVISIONED: "Bereitgestellt",
            READY: "Bereit",
            READY_FOR_COMMISSION: "Bereit zur Inbetriebnahme",
            WARNING: "Warnung ",
  },
          ERROR: {
            CHANGE_FAILURE_MESSAGE: "Beim Ändern der Parameter ist ein Fehler aufgetreten",
            BUILDING_ALREADY_EXISTS: "Gebäudename existiert bereits",
            INVALID_CONNECTION_STRING: "Ungültige Verbindungs-Zeichenfolge",
            INVALID_UUID: "Ungültige UUID",
            ORGANIZATION_ALREADY_EXISTS: "Organisationsname exisiert bereits",
            REQUEST_FAILED_TITLE: "Anfrage fehlgeschlagen",
            REQUEST_FAILED_GATEWAY_ADD: "Anfrage konnte kein Gateway hinzufügen.",
            REQUEST_FAILED_MESSAGE: "Der Vorgang konnte nicht abgeschlossen werden. Bitte warten Sie ein paar Minuten und versuchen Sie es erneut.",
            UNAUTHORIZED_ACCESS: "Unbefugter Zugriff Bitte melden Sie sich erneut an",
  },
          DEVICE_TYPE: {
            ATPLUS_CONTROLLER: "AT+-Controller",
            COMMON_GATEWAY: "Allgemeines Gateway",
            DGS: "DualGuard-S",
            LPSMART: "DualSmart-S",
            WIRELESS_GATEWAY: "Drahtloses Gateway",
  },
          SET_PASSWORD_STATUS: {
            INVALID_LENGTH: "Ungültige Länge",
            INVALID_PASSWORD: "Falsches Passwort",
            PASSWORD_CHANGE_FAILURE: "Fehler bei der Passwortänderung",
            PASSWORD_CHANGE_SUCCESS: "Erfolgreiche Passwortänderung",
            PASSWORD_COMPARISON_FAIL: "Der Passwortvergleich ist fehlgeschlagen",
  },
          DEFAULT_LOGIN_STATUS: {
            LOGIN_DEFAULT_SUCCESS: "Standardanmeldung erfolgreich",
            LOGIN_FAILURE: "Anmeldefehler",
            LOGIN_MAX_ATTEMPTS_EXCEEDED: "Maximale Anmeldeversuche überschritten",
            LOGIN_SUCCESS_ADMIN: "Eingeloggt als Admin",
            LOGIN_SUCCESS_SERVICE: "Eingeloggt als Service",
            LOGIN_SUCCESS_USER: "Eingeloggt als User",
            LOGOUT: "Logout ",
  },
          NULL_VALUE: "Keine Antwort",
          TURN_ON_BLE_MSG: "Schalten Sie Bluetooth ein, um „Building Safety Manager“ den Zugriff auf lokale Systeme zu ermöglichen.",
  },
        GATEWAY_DISCONNECT_ALERT: "Sind Sie sicher, dass Sie die Verbindung trennen möchten?",
        DISCARD_CHANGES_ALERT: "Sind Sie sicher, dass Sie die Änderungen verwerfen möchten?",
        UNLINKEXISTING_SYSTEM_ALERT: "Sind Sie sicher, dass Sie die Verknüpfung zum vorhandenen System aufheben möchten?",
        GATEWAY_UNREGISTER_ALERT: "Sind Sie sicher, dass Sie die Gateway-Registrierung aufheben möchten?",
        BACKUP_CONNECTION: "Backup-Verbindung",
        TIMELINE_VIEW: {
          BUILDING: "Gebäude",
          OPTINAL: "Optional",
          ORGANIZATION: "Organisation",
          SELECT_BUILDING: "Bitte Gebäude auswählen",
          SELECT_ORGANIZATION: "Bitte Organisation auswählen",
          SUB_LOCATION: "Unterpunkt",
  },
        BLUETOOTH_SCAN_ERROR: " Sie können Ihr Gateway nicht finden? Überprüfen",
        CARRIER_NAME: "Anbietername",
        CELLULAR: "Mobilfunk",
        CHANGE_CONNECTION_METHOD: "Verbindungsmethode ändern",
        CHECK_FOR_UPDATES: "Auf Updates prüfen",
        CONFIGURE_WI_FI_CONNECTION: "Konfigurieren Sie die Ethernet-Einstellungen",
        CONFIGURE_CELLEULAR_CONNECTION: "Konfigurieren Sie diese Mobilfunkverbindung",
        COMMISSION_NEW_GATEWAY: "Verbindung zu einem Notbeleuchtungsgerät von Eaton herstellen.",
        CONNECTED: "VERBUNDEN",
        CONNECTION_METHOD: "Verbindungsmethode",
        GATEWAY_CONNECTION_PRIORITY: "Verbindungspriorität",
        CONNECTIONS: "Verbindungen",
        GATEWAY_ADD_BUILDING_VALUE: "Neues Gebäude erstellen",
        GATEWAY_ADD_ORGANIZATION_VALUE: "Neue Organisation erstellen",
        SET_DATE_TIME_SUCCESS: "Datum & Uhrzeit erfolgreich eingestellt",
        DEFAULT_PASSWORD_ADMIN_CHANGED: "nan",
        DEFAULT_PASSWORD_USER_CHANGED: "nan",
        DISCONNECTED: "Gerät ist nicht verbunden oder Verbindung nicht herstellbar, bitte erneut versuchen",
        DISCONNECTED_SUCCESS: "Gerät erfolgreich getrennt",
        GATEWAY_UNREGISTER_SUCCESS: "Geräteregister-Informationen erfolgreich gelöscht",
        GATEWAY_UNREGISTER_FAILURE: "Geräteregister-Informationen erfolgreich gelöscht",
        DGS_NOT_COMMUNICATING: "DualGuard-S kommuniziert nicht",
        DGS_UNREACHABLE: "DualGuard-S nicht erreichbar",
        DHCP: "DHCP",
        DNS: "DNS",
        WIFI_CREDENTIALS_MESSAGE: "richtiges Passwort eingeben",
        ENTER_WIFI_CREDENTIALS: "WLAN-Anmeldeinformationen eingeben",
        ETHERNET: "Ethernet",
        ETHERNET_CONNECTION: "Ethernet-Verbindung",
        FINISH: "Fertig",
        CHANGE_PASSWORD_INFO: "Bitte ändern Sie aus Sicherheitsgründen das Standardpasswort.",
        GATEWAY_ACTIVATED: "Gateway aktiviert",
        GATEWAY_ADDED: "Gateway hinzugefügt",
        GATEWAY_COMMISSIONING: "Gateway-Inbetriebnahme",
        GATEWAY_CONNECTED: "Gateway verbunden",
        GATEWAY_DETAILS: "Gateway-Details",
        GATEWAY_NAME: "Gateway-Name *",
        GATEWAY_PASSWORD: "Gateway-Passwort",
        GATEWAY_PASSWORD_INCORRECT: "Gateway-Passwort falsch",
        GATEWAY_NAME_DESCRIPTION: "Geben Sie diesem Gateway einen besseren Namen und eine bessere Beschreibung.",
        SUCCESSFULLY_ADDED: "wurde erfolgreich hinzugefügt.",
        LAST_CONNECTED: "Zuletzt verbunden: 12:34:56 12. Mai 2024",
        LINK_NEW_DEVICE: "Verknüpfe ein neues Gerät",
        LINK_NEW_SYSTEM: "Verknüpfe ein neues System",
        LINK_SYSTEM: "VERKNÜPFE EIN SYSTEM",
        LINK_ANOTHER_SYSTEM: "Verknüpfe ein anderes System",
        LINK_SYSTEMS: "Systeme verknüpfen",
        LOCATION: "Standort ",
        DENIED_LOCATION_PERMISSION: "Standortberechtigung verweigert. Aktivieren Sie sie, um eine Bluetooth-Suche nach einem Gerät in der Nähe durchzuführen",
        CONNECTION_TYPE_VALUE: "LTE/WCDMA/GSM",
        MANAGE_ETHERNET_CONNECTION: "Ethernet-Verbindung verwalten",
        MANAGE_WIFI_CONNECTION: "WLAN-Verbindung verwalten",
        SET_NAME_DESCRIPTION_SUCCESS: "Name und Beschreibung erfolgreich festgelegt",
        NO_DEVICE_FOUND: "Keine Geräte gefunden",
        UNLINK_SYSTEM_ERROR: "Kein Gateway zum Abmelden",
        NO_SYSTEM_YET: "Noch keine Systeme",
        DHCP_OFF: "AUS",
        DHCP_ON: "Ein",
        NO_SYSTEM_YET_DESCRIPTION: "Sobald Sie Ihre Systeme mit diesem Gateway verbunden haben, sehen Sie hier eine Liste der Systeme. Sie können die Beschreibung ändern.",
        ONLINE_TEXT: "online.",
        OTHER_NETWORK: "Anderes Netzwerk..",
        BLUETOOTH_INSTRUCTIONS: " unsere Anweisungen",
        ENTER_ADMIN_CHANGE_PASSWORD: "Bitte das vom Administrator geänderte Passwort eingeben",
        ENTRY_PASSWORD: "Bitte das Gateway-Passwort eingeben für",
        PLEASE_ENTER_NEW_PASSWORD_AGAIN_RECONNECT: "Bitte das neue Passwort erneut eingeben, um sich erneut mit dem Gateway zu verbinden.",
        PRIMARY_CONNECTION: "Primäre Verbindung",
        GATEWAY_PRIORITY: "Priorität",
        PULL_REFRESH_TEXT: "Zum Aktualisieren nach unten ziehen",
        REMOVE_CONNECTION: "Verbindung entfernen",
        REMOVE_PRIMARY_CONNECTION: "Primäre Verbindung entfernen",
        REQUIRE_GATEWAY_CONNECTED_TO_INTERNET: "Erfordert ein mit dem Internet verbundenes Gateway",
        SAVE_WI_FI_CREDENTIALS: "Speichern Sie WLAN-Anmeldeinformationen für zusätzliche Geräte",
        SCAN_QR_CODE: "QR-Code scannen",
        SCANNING_FOR_NEAR_BY_BLE: "Nach BLE-Geräten in der Nähe suchen.",
        SCANNING_FOR_NEAR_BY_WIFI: "Suche nach WLAN in der Nähe.",
        SEARCH_VIA_BLUETOOTH: "Suche über Bluetooth",
        SEARCHING_FOR_NEARBY_WIFI: "Suche nach WLAN in der Nähe...",
        SEARCH_VIA_BLUETOOTH_EllIPSE: "Suche über Bluetooth...",
        SECONDARY_CONNECTION: "Alternative Verbindung",
        SELECT_PRIMARY_CONNECTION: "Wählen Sie eine primäre Verbindungsmethode",
        SET_DATE_TIME_CONFIG: "Falsche Einstellung von Datum und Uhrzeit ",
        SET_DATE_TIME_FAILURE: "Konfigurationsfehler von Datum und Uhrzeit ",
        SET_NAME_DESCRIPTION_INCORRECT_CONFIG: "Falsche Einstellung von Name und Beschreibung",
        SET_NAME_DESCRIPTION_FAILURE: "Konfigurationsfehler von Name und Beschreibung",
        STATUS: "Status",
        SYNC_DATE_TIME: "Datum und Uhrzeit synchronisieren",
        SYSTEM_UNLINK_SUCCESS: "Systemverknüfung erfolgreich aufgehoben",
        SYSTEM_UNLINKED: "System nicht verknüpft",
        SYSTEMS: "Systeme",
        UNLINK_SYSTEM_SUCCESS: "Systeme wurden erfolgreich abgemeldet.",
        TERTIARY_CONNECTION: "Tertiäre Verbindung",
        UNLINK_ANOTHER_SYSTEM: "Trennen Sie die Verbindung zu einem anderen System",
        UNLINK_EXISTING_SYSTEM: "Vorhandenes System trennen",
        UNLINK_EXIST_SYSTEM: "Gateway abmelden",
        USE_WI_FI_PASSWORD: "Verwenden Sie das WLAN-Passwort",
        USE_WPA2_KEY: "Verwenden Sie den WPA2-Schlüssel",
        CARRIER_NAME_VALUE: "Vodafone NL 3G",
        WI_FI: "W-LAN",
        GATEWAY_WIFI_CONFIG: "W-LAN Konfigurationen",
        WI_FI_NETWORK: "W-LAN Netzwerk",
        WI_FI_NETWORK_NAME: "W-LAN Netzwerkname",
        WI_FI_PASSWORD: "W-LAN Passwort",
  },
      ERROR_MESSAGES: {
        GENERIC_ERROR_CONTACT_DETAILS: " oder rufen Sie an unter",
        GENERIC_ERROR: "Es ist ein nicht spezifizierter Fehler aufgetreten. Wenn dieses Problem weiterhin besteht, wenden Sie sich bitte an den Eaton-Support unter  ",
        9003: "Der gewünschte Vorgang kann nicht ausgeführt werden, bitte wenden Sie sich an Ihren Administrator",
        2002: "Der Link zur Benutzerregistrierung ist abgelaufen oder bereits benutzt worden.",
        2018: "Wir können Ihre Registrierung nicht abschließen. Ihre Organisation hat die Anzahl der zugewiesenen Benutzer für Ihr Abonnement überschritten. Wenn Ihre Organisation zusätzliche Benutzer benötigt, wenden Sie sich bitte per E-Mail an den CST-Support unter ",
  },
      FORGOT_PASSWORD: {
        INSTRUCTIONS: " 'Bitte geben Sie die mit dem Konto verbundene E-Mail-Adresse ein. <br/><br/>' +'Wenn diese E-Mail ein Konto im Safety Management System hat, erhalten Sie eine Antwort innerhalb von <3>einem Werktag</3>.<br/><br/>' + 'Bei dringenden Kontofragen rufen Sie bitte an unter '",
        ERROR: "Ihr Passwort kann zur Zeit nicht zurückgesetzt werden.",
        RESET_CODE_ERROR: "Es gab einen Fehler mit Ihrem Reset-Code. ",
  },
      ACCOUNT_SETTING: {
        UPDATES_AVAILABLE: " UPDATES VERFÜGBAR",
        ACCOUNT_CREATED: "nan",
        ACCOUNT_PREFERENCES: "Kontoeinstellungen",
        ADDRESS_LINE2: "Adresszeile 2 (Optional)",
        ALARAM: "Alarme",
        ALL_SYSTEM_FIRMWARE: "Alle System-Firmwares",
        APPLICATION_THEME_COLOR: "App-Farbschema",
        AUTOMATIC_LOG_OUT: "Automatisches ausloggen",
        AUTOMATIC_UPDATES: "Automatische Updates",
        AUTOMATIC_UPDATES_INFO: "Laden Sie automatisch die neueste Firmware-Version auf Ihr Gerät herunter.",
        CHANGE_PASSWORD: "Passwort ändern ",
        STORAGE_FIRMWAREFILE_INFO: "Wählen Sie aus, wie Firmware-Dateien auf Ihrem Gerät gespeichert werden sollen",
        CITY: "Stadt",
        COMPANY_ADDRESS: "Firmenanschrift",
        COMP_ADDRESS: "Unternehmensadresse*",
        COMPANY_DETAILS: "Unternehmensdetails",
        COMPANY_NAME: "Unternehmensname",
        COMP_NAME: "Unternehmensname*",
        COMPANY_PHONE_NUMBER: "Telefonnummer des Unternehmens",
        CODE: "Landesvorwahl",
        DATE_FORMAT: "Datumsformat",
        DELETE_ACCOUNT: "Konto löschen",
        DEVICE_BLOCKING: "Gerät befindet sich im blockierten Modus, Notbetrieb, Wartung erforderlich",
        EMAIL: "E-Mail ",
        EMAIL_ADDRESS: "E-Mail-Adresse",
        REQUIRED_FIELD: "Felder mit einem Sternchen (*) sind zum Fortsetzen erforderlich.",
        FIRMWARE_FILE: "Firmware-Dateien",
        FIRMWARE_UPDATES: "Firmware-Updates",
        FIRST_NAME: "Vorname",
        FT_DT_FAIL: "FT fehlgeschlagen, BT fehlgeschlagen, getrennt, Batteriefehler, Leuchtenfehler, SKU-Fehler",
        HEALTH_REPORT: "Statusbericht",
        LANGUAGE: "Sprache ",
        LAST_NAME: "Nachname",
        MONTHLY_REPORTING: "Monatsbericht",
        NEW_RELEASE_FIRMWARE_UPDATE: "Neue Versionen, Firmware-Updates verfügbar",
        NOTIFICATIONS: "Benachrichtigungen",
        ORGAN_UPDATED_SUCCESSFULLY: "Organisation erfolgreich aktualisiert",
        PASSWORD: "Passwort ",
        PERSONAL_DETAILS: "Persönliche Daten",
        PHONE_NUMBER: "Telefonnummer",
        VALID_FIRSTNAME_ERROR: "Bitte Vornamen eingeben",
        POSTAL_CODE: "Postleitzahl",
        PREFERENCES: "Einstellungen",
        PRIMARY_CONTACT: "Hauptkontakt",
        PRIMARY_CONTACT_EMAIL_ADDRESS: "Hauptkontakt E-Mail-Adresse",
        PRIMARY_CONTACT_PHONE_NUMBER: "Hauptkontakt Telefonnummer",
        PRODUCT_UPDATE: "Produkt-Updates",
        SAVE_CHANGES: "Änderungen speichern",
        SECONDARY_CONTACT: "Alternativkontakt",
        SECONDARY_CONTACT_EMAIL_ADDRESS: "Alternativkontakt E-Mail-Adresse",
        SECONDARY_CONTACT_PHONE_NUMBER: "Alternativkontakt Telefonnummer",
        STATUS_UPDATE: "Statusupdates",
        STORAGE: "Speicherung",
        TIME_ZONE: "Zeitzone",
        UNITS: "Einheiten",
        USER_NAME: "Benutzername",
        USER_UPDATED_SUCCESSFULLY: "Benutzer erfolgreich aktualisiert",
        VERSION: "Version ",
  },
      CHANGE_PASSWORD: {
        EMAIL_CONFIRM_MESSAGE: " Wir haben eine Bestätigungs-E-Mail an {{email}} gesendet",
        PROBLEM_OCCURRED: "Es ist ein Problem aufgetreten:",
        PASSWORD: "Passwort ändern ",
        OLD_PASSWORD: "Altes Passwort",
        PASSWORD_CHANGED: "Passwort geändert",
        PASSWORD_INFO: "Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
        ERROR_MESSAGR: "Ihre Angaben stimmen nicht mit unseren Daten überein. Bitte geben Sie Ihre Daten erneut ein, um es erneut zu versuchen.",
        SUCCESS_MESSAGE: "Ihr Passwort wurde erfolgreich aktualisiert! Um die Sicherheit Ihres Kontos zu gewährleisten, müssen Sie sich mit Ihren aktualisierten Zugangsdaten bei der Anwendung anmelden.",
  },
      USER_INVITATION_MANAGEMENT: {
        YOU: "(Sie)",
        INVITATION_MESSAGE_UPDATE_COUNT: "{{updatedInvitationCount}} Einladungen waren {{inviteStatus}} erfolgreich, {{rejectedInvitationCount}} Einladungen waren nicht {{inviteStatus}}.",
        SUCCESS_COUNT: "{{updatedInvitationCount}} Einladungen waren {{inviteStatus}} erfolgreich.",
        INVITE_ACCEPT: "Akzeptieren",
        INVITE_ACCEPT_INVITATION: "Einladung annehmen",
        INVITE_ACCEPTED: "akzeptiert",
        INVITE_ACCEPT_INVITATION_DESCRIPTION_1: "Wenn Sie diese Einladung annehmen, wird das folgende Objekt zu Ihrem Profil hinzugefügt:",
        ADMIN_APPROVE: "Anfrage genehmigen",
        COMPANY: "Unternehmen",
        DECLINE_INVITATION: "Einladung ablehnen",
        TABLE_HEADER_DENIED_TITLE: "Von mir abgelehnt ({{count}})",
        INVITE_DENIED: "abgelehnt",
        INVITE_DENY: "Ablehnen",
        ADMIN_DECLINE_INVITATION: "Anfrage ablehnen",
        TABLE_HEADER_EXPIRED_AT: "Ablauf",
        TABLE_HEADER_EXPIRED_TITLE: "Abgelaufene Einladungen ({{count}})",
        INVITE_HISTORY: "Einladungsverlauf",
        INVITATION_SUCCESS_MESSAGE: "Einladung erfolgreich {{inviteStatus}}.",
        JOIN: "Beitreten",
        JOINALL: "Allen beitreten",
        LEAVE_ORGANIZATION: "Organisation verlassen",
        TABLE_HEADER_NAME: "Name ",
        INVITE_PENDING: "Ausstehend",
        INVITE_REQUEST_ACCESS_DESCRIPTION_2: "Bitte beantragen Sie einen Zugang beim Administrator, um dieses Objekt zu Ihrem Profil hinzuzufügen",
        TABLE_HEADER_INVITE_DATE: "Erhalten",
        REMOVE_USER: "Benutzer entfernen",
        INVITE_REQUEST: "Anfrage",
        INVITE_REQUEST_ACCESS: "Zugang anfragen",
        REQUESTEDBY: "Angefordert von",
        INSTRUCTIONS: "Wählen Sie aus, ob Sie Ihrem Profil Objekte hinzufügen möchten oder ob Sie die Einladung zum Hinzufügen zu Ihrem Profil ablehnen möchten.",
        ADMIN_INSTRUCTIONS: "Wählen Sie, ob Sie die Anfrage für den Zugang genehmigen oder ablehnen wollen:",
        SENTBY: "Gesendet von",
        TABLE_HEADER_INVITE_BY: "Gesendet von",
        TABLE_HEADER_DENIED_NO_DATA_FOUND: "Derzeit liegen keine abgelehnten Einladungen vor.",
        TABLE_HEADER_EXPIRED_NO_DATA_FOUND: "Derzeit sind keine Einladungen abgelaufen.",
        INVITE_REQUEST_ACCESS_DESCRIPTION_1: "Diese Einladung ist abgelaufen und kann Ihrem Profil nicht mehr hinzugefügt werden.",
        TABLE_HEADER_TOTAL_ACCEPTED_COUNT: "Insgesamt angenommene Einladungen ({{count}})",
        SUCCESS_MESSAGE: "Erfolgreiche Benutzereinladung {{count}}",
        ERROR_MESSAGE: "Die Aktualisierung der Einladung ist fehlgeschlagen",
        VIEW_ALL_ACCESS_RIGHTS: "Alle Zugriffsrechte anzeigen",
        TITLE: "Willkommen, {{username}}",
        SUBTITLE: "Sie wurden zu folgenden Projekten eingeladen:",
        ADMIN_SUBTITLE: "Sie haben Anfragen zum Zugriff auf abgelaufene Einladungen:",
  },
      INACTIVITY: {
        LOGOUT: ", werden Sie abgemeldet.",
        INACTIVE_LOGOUT: "Wegen Inaktivität abgemeldet",
        TITLE: "Sitzung abgelaufen",
        WARNING : "Dieses Fenster war 15 Minuten lang inaktiv. Wenn keine Maßnahmen ergriffen werden",
  },
      USERLIST: {
        DELETECONFIRMATION: "Das Konto für {{email}} und alle dazugehörigen Daten werden dauerhaft entfernt. Die Löschung dieses Kontos hat keine Auswirkungen auf die Organisation, die Geräte oder die zugehörigen historischen Daten.",
        ACCOUNT_DELETE: "Konto gelöscht",
        ALL_LOCATION: "Alle Orte",
        DELETEACCOUNT: "Sind sie sicher, dass das Konto gelöscht werden soll?",
        COL_EMAIL: "E-Mail",
        COL_LOCATION: "Standort",
        COL_ROLE: "Rolle",
        COL_STATUS: "Status",
        DELETE_SUCCESS: "Das Benutzerkonto für {{email}} wurde erfolgreich gelöscht.",
        COL_USERNAME: "Benutzername",
  },
      REPORTS: {
        ISSUES: "{{issueCount}} Meldungen",
        MONTHLY_REPORT: "Monatlicher Bericht",
        NO_ISSUES: "Keine Probleme während dieses Zeitraums",
        REPORT_DOWNLOAD_FAILED: "Herunterladen der Berichtsdatei fehlgeschlagen",
        REPORT_DOWNLOAD_SUCCESSFULLY: "Herunterladen der Berichtsdatei erfolgreich",
  },
      MAP: {
        MAP_COORDINATES: "{{latitude}} N, {{longitude}} E",
        BLOCKED: "BLOCKIERT",
        MAP_COORDINATES_LABEL: "Koordinaten",
         MAP_ADDRESS_DISPLAY: "Adresse auf der Karte anzeigen",
        MAP_DRAG_MARKER: "Auf der Karte ziehen um die Stecknadel zu platzieren",
        ERROR: "STÖRUNG",
        OFFLINE: "OFFLINE",
        READY: "BEREIT",
        MAP_ENABLE_ON_COUNTRY: "Land wählen, um Karte anzuzeigen",
        SYSTEM: "System ",
        SYSTEMS: "Systeme",
        WARNING: "Warnung ",
  },
      ASSETS_DETAILS: {
        ADD_BUILDINGS: "+ Gebäude hinzufügen",
        ADD_SYSTEMS: "+ System hinzufügen",
        BUILDINGS: "Gebäude",
        CUSTOMER_INFO_HEADER: "Kundeninformation",
        DOWNLOAD_AS: "Herunterladen als…",
        GATEWAYS: "Gateways",
        LINK_TO_CUSTOMER_BUTTON: "Verknüpfung zum Kunden",
        MAIN_CONTACT: "Hauptkontakt",
        ADD_BUILDING_ASSET: "Wählen Sie eine Organisation aus, um ein Gebäude zu erstellen",
        SELECT_ORGANIZATION_LABEL: "Organisation auswählen",
        SHOW_FAVORITE_BUILDINGS: "Nur favorisierte Gebäude anzeigen",
        SHOW_FAVORITE_GATEWAYS: "Nur favorisierte Gateways anzeigen",
        SHOW_FAVORITE_SYSTEMS: "Nur favorisierte Systeme anzeigen",
        SLA_SUBTITLE: "SLA#",
        SYSTEMS: "Systeme",
        NO_CUSTOMER_TEXT: "Dieses System ist nicht mit einem Kunden verknüpft",
  },
      ATPLUS: {
        ATPLUS_DATETIME_TWENTYFOUR_HOUR: "24-Stunden Format",
        ATPLUS_ACTIVATION: "Aktivierung",
        ADMIN: "Administrator",
        ADMINVIEWER: "Administrator & Zuschauer",
        ATPLUS_ADMIN_ACCESS: "Administrator-Zugriff",
        ATPLUS_ALERT_LEVEL: "Alarmlevel",
        ATPLUS_ALERTSETTING_HEADER: "Alarmlevel Einstellungen",
        ATPLUS_SEARCHLUMINAIRE_WARNING: "Allen Leuchten wird eine neue Adresse zugewiesen und eine neue Konfiguration erstellt. Wenn Sie nur neue oder ausgetauschte Leuchten suchen möchten, nutzen Sie bitte die Option „Nach neuen Leuchten suchen“.",
        ATPLUS_LOGIN_FAILURE: "Beim Ändern der Parameter ist ein Fehler aufgetreten",
        ATPLUS_DATETIME_AUTO_DATE_TIME_HEADER: "Automatisches Datum & Uhrzeit",
        ATPLUS_AUTOMATIC_RESET: "Autoamtischer Ruhemodus",
        ATPLUS_BATTERYFAILURE: "Batteriefehler",
        ATPLUS_CHANGE_PASSWORD: "Systempasswort ändern",
        SYSTEMPASSWORD: "Systempasswörter ändern",
        ATPLUS_INFO_TAG_CLOSED: "GESCHLOSSEN",
        ATPLUS_COMISSIONING: "Inbetriebnahme",
        ATPLUS_COMMANDS: "Befehle",
        ATPLUS_COMMSFAILURE: "Kommunikationsfehler",
        ATPLUS_DATETIME_CURRENT_TIME: "Aktuelles Datum & Uhrzeit",
        ATPLUS_DRYCONTACT_DEFAULT_SETTINGS: "Standardeinstellungen",
        ATPLUS_DETAILS: "Details",
        ATPLUS_DRY_CONTACT: "potentialfreier Kontakt",
        ATPLUS_DRYCONTACT_INPUT: "potentialfreier Eingang",
        ATPLUS_ALERTSETTING_DRY_CONTACT_INPUT: "potentialfreier Eingang aktiviert",
        ATPLUS_DRYCONTACT_OUTPUT: "potentialfreier Ausgang",
        ATPLUS_ALERTSETTING_DRY_CONTACT_OUTPUT: "potentialfreier Ausgang aktiviert",
        ATPLUS_DRYCONTACT_HEADER: "Einstellungen potentialfreier Kontakt",
        ATPLUS_DRYCONTACT_STATUS: "Status potentialfreier Kontakt",
        ATPLUS_DTSUCCESS: "Betriebsdauertest Ende",
        ATPLUS_DTFAILEDTITLE: "Fehler Betriebsdauertest",
        ATPLUS_DTINPROGRESS: "Betriebsdauertest läuft",
        ATPLUS_EMERGENCY: "NOTBETRIEB",
        ATPLUS_EXPORT_CONFIG: "Konfiguration exportieren",
        ATPLUS_FAILED_LUMINAIRE: "Ausgefallene Leuchten",
        ATPLUS_DRYCONTACT_FAILURE_THRESHOLD: "Fehlerschwelle überschritten",
        ATPLUS_FAILURETYPE: "Fehlertypen",
        ATPLUS_FIRMWARE_UPDATE: "Firmware Update",
        ATPLUS_FTSUCCESS: "Funktionstest Ende",
        ATPLUS_FTINPROGRESS: "Funktionstest läuft",
        CHANGEPASSWORDFOR: "Das Passwort muss geändert werden",
        ATPLUS_IMPORT_CONFIG: "Konfiguration importieren",
        ATPLUS_INEMERGENCYMODE: "In Notbetrieb",
        ATPLUS_INFAILURE: "In Fehler",
        ATPLUS_INRESTMODE: "Im Ruhemodus",
        ATPLUS_INTESTMODE: "Im Testmodus",
        ATPLUS_DRYCONTACT_STATUS_INPUT: "Eingang ",
        GWCDATPLUSHEADER: "Ist dies das Notlichtgerät, das Sie in Betrieb nehmen?",
        ATPLUS_LAMPFAILURE: "Leuchtenfehler",
        ATPLUS_ALERTSETTING_LOCAL_MAIN_FAILURE: "Lokaler Hauptfehler",
        ATPLUS_ALERTSETTING_LUMINAIRE_ALERTS: "Leuchtenwarnungen",
        ATPLUS_DTFAILEDDESCRIPTION: "Leuchtenstörung ",
        ATPLUS_LUMINAIRE: "Leuchten",
        ATPLUS_FOUNDLUMINAIRE: "Leuchten gefunden...",
        ATPLUS_FOUNDWERELUMINAIRE: "Leuchten wurden gefunden.",
        ATPLUS_MAIN_OFFICE: "Hauptbüro",
        ATPLUS_DRYCONTACT_SUBHEADER: "Hauptbüro",
        ATPLUS_MANAGE_CONFIG: "Konfigurationen verwalten",
        ATPLUS_LOGIN_MAX_ATTEMPTS_EXCEEDED: "Die maximale Anzahl an Versuchen wurde erreicht",
        ATPLUS_MINIMIZE: "Minimieren",
        ATPLUS_FOUNDNEWLUMINAIRE: "Neue Leuchten wurden gefunden.",
        ATPLUS_DATETIME_SELECTED_TZ_PLACEHOLDER: "New York",
        ATPLUS_NO_LUMINAIRES_YET: "Noch keine Leuchten",
        ATPLUS_DRYCONTACT_RADIO_CLOSED: "Öffner",
        ATPLUS_DRYCONTACT_RADIO_OPEN: "Schliesser",
        ATPLUS_NEWLUMINAIRES_HEADLINE: "Suchen Sie nur im „Hauptbüro“ nach neu hinzugefügten oder ausgetauschten Leuchten, um ihnen automatisch Adressen zuzuordnen.",
        ATPLUS_OPENSNACK: "Offen",
        ATPLUS_OPERATIONS: "Vorgänge",
        ATPLUS_ALERTSETTING_OTHER_ALERTS: "Andere Alame",
        ATPLUS_DRYCONTACT_STATUS_OUTPUT: "Ausgang",
        ATPLUS_ALERTSETTING_CHOOSE_NUMBER: "Bitte wählen Sie zwischen 1-10 Leuchten",
        ATPLUS_ADDITIONALPASSWORDINSTRUCTION: "Bitte erstellen Sie neue Passwörter für Benutzer mit Service-, Admin- und Viewer-Zugriff auf dieses Telecommand.",
        ATPLUS_DONT_MOVE: "Bitte nicht weggehen",
        ATPLUS_RELOGINPASSWORDINSTRUCTION: "Bitte das neue Passwort erneut eingeben, um sich wieder zu verbinden mit ",
        ATPLUS_RELOGIN_MESSAGE: "Bitte das neue Passwort erneut eingeben, um sich erneut mit dem Gateway zu verbinden.",
        ATPLUS_ENTRY_MESSAGE: "Bitte Ihre Zugangsdaten eingeben, um die folgenden Passwörter zu ändern.",
        ATPLUS_ALERTSETTING_NOTIFY: "Bitte verwenden Sie die folgenden Einstellungen, um Ihre Alarmvorgaben anzupassen.",
        ATPLUS_REFRESHLUMINAIRE_TEST: "Leuchten aktualisieren",
        ATPLUS_USE_RELIGHT: "Wieder einschalten ist nur für Leuchten verfügbar, die sich derzeit im Ruhemodus befinden.",
        ATPLUS_DRYCONTACT_INPUT_RELIGHT: "JOUR wieder einschalten",
        ATPLUS_RELIGHT_SUCCESS: "Der Relight-Modus wurde erfolgreich aktiviert.",
        ATPLUS_RESTMODE: "Ruhemodus",
        ATPLUS_REST_SUCCESS: "Der Ruhemodus wurde erfolgreich aktiviert.",
        ATPLUS_DURATION_TEST: "Batteriedauertest starten",
        ATPLUS_FUNCTIONAL_TEST: "Funktionstest starten",
        ATPLUS_SEARCHALL_LUMINAIRE: "Alle Leuchten suchen",
        ATPLUS_SEARCHLUMINAIRE_HEADLINE: "Suchen Sie nach allen „Hauptbüro“-Leuchten, um ihnen automatisch Adressen zuzuweisen.Durch diese Aktion wird die Adresse bereits installierter und neuer Leuchten dauerhaft geändert und eine neue Konfiguration erstellt. Diese Aktion kann nicht rückgängig gemacht werden.",
        ATPLUS_SEARCH_ALL_LUMINAIRES: "Alle Leuchten suchen",
        ATPLUS_SEARCH_NEWLUMINAIRES: "Nur neue Leuchten suchen",
        ATPLUS_SEARCHNEW_LUMINAIRE: "neue Leuchten suchen",
        ATPLUS_SERVICE_ACCESS: "Servicezugang",
        ATPLUS_SERVICEPARTNERPASSWORD: "Servicepartner",
        ATPLUS_CHANGEPASSWORD_INSTRUCTION: "Legen Sie ein Passwort für Benutzer fest mit",
        ATPLUS_ADDITIONALPASSWORD: "Legen Sie zusätzliche Passwörter fest",
        ATPLUS_DATE_TIME: "Datum einstellen",
        ATPLUS_DATETIME_HEADER: "Datum einstellen",
        ATPLUS_ALERTSETTING_LUMINAIRE_ALERTS_INFO: "Legen Sie die Anzahl der ausgefallenen Leuchten fest, die ein System erreichen muss, damit ein Alarm aktiviert wird.",
        ATPLUS_SHOW_CONNECTED_LUMINAIRE: "Angeschlossene Leuchten anzeigen",
        ATPLUS_SHOW_REGISTERED_LUMINAIRE: "Angemeldete Leuchten anzeigen",
        SIGN_IN: "Anmelden",
        SIGN_IN_AS: "Melden Sie sich an als...",
        ATPLUS_RELIGHT: "Relight-Modus starten",
        ATPLUS_REST: "Ruhemodus starten",
        ATPLUS_STARTSEARCH: "Suche starten",
        ATPLUS_START_TEST: "Test starten",
        ATPLUS_VISIBILITY_TEST: "Visibility Plus starten",
        ATPLUS_DRYCONTACT_INPUT_VISIBILITY: "Visibility+ starten",
        ATPLUS_STARTDT: "Betriebsdauertest starten...",
        ATPLUS_STARTFT: "Funktionstest starten...",
        ATPLUS_STARTDTSNACK: "Test stoppen",
        ATPLUS_SYNCH_TEST: "Testplan synchronisieren",
        TELECOMMANDPASSWORD: "Telecommand-Passwort",
        ATPLUS_TEST: "Test ",
        ATPLUS_DRYCONTACT_INPUT_READONLY: "Der potentialfreie Eingang ist schreibgeschützt, kann aber manuell am DIP-Schalter konfiguriert werden.",
        ATPLUS_DRYCONTACT_STATUS_READONLY: "Der Status ist schreibgeschützt, kann aber manuell am Dip-Schalter konfiguriert werden.",
        ATPLUS_LUMINAIRE_INSTALL: "Diese Aktion lässt alle Leuchten einer Installation blinken.",
        ATPLUS_SHOW_CONNECTED_LUMINAIRE_INFO: "Diese Aktion lässt jede Leuchte blinken, die ordnungsgemäß verkabelt und mit dem Controller verbunden ist.",
        ATPLUS_SHOW_REGISTERED_LUMINAIRE_INFO: "Bei dieser Aktion blinken nur Leuchten, die in Ihrer Beleuchtungskonfiguration enthalten sind.",
        ATPLUS_TOMANYLUMINAIREHEADER: "Dieses System kann nur bis zu 64 Leuchten unterstützen. Nur die ersten 64 erkannten Geräte werden Teil Ihrer Konfiguration sein.Bitte nutzen Sie die Funktion „Angemeldete Leuchten suchen“ im Inbetriebnahmefenster, um die Leuchten zu finden, die nicht in Ihrer Konfiguration enthalten sind.",
        ATPLUS_TIMEREMAINS: "Zeitschätzung,",
        ATPLUS_TIMEREMAINING: "Verbleibende Zeit,",
        ATPLUS_DATETIME_TIMEZONE: "Zeitzone",
        ATPLUS_TIMEZONE_HEADER: "Zeitzone",
        ATPLUS_TOMANYLUMINAIRE: "Zu viele Leuchten gefunden",
        ATPLUS_USE_REST: "Verwenden Sie „Ruhemodus“ für Leuchten, die nicht mehr an das Stromnetz angeschlossen sind.",
        ATPLUS_FINISHSEARCH: "Verwenden Sie diese Ergebnisse, um eine neue Konfiguration zu erstellen und zu teilen, indem Sie „Exportieren“ verwenden.",
        ATPLUS_DATETIME_AUTO_DATE_TIME_SUBTEXT: "Verwenden Sie Datum, Uhrzeit und Zeitzone Ihres Mobilgeräts zur automatischen Synchronisierung mit dem TLAT+-System. Deaktivieren Sie diese Funktion, um Datum, Uhrzeit und Zeitzone manuell einzustellen.",
        ATPLUS_VIEWLOGBOOK: "Prüfbuch anzeigen",
        VIEWER: "Zuschauer",
        ATPLUS_VIEWER_ACCESS: "Zuschauerzugriff",
        ATPLUS_LOGIN_MAX_ATTEMPTS_EXCEEDED_BODY: "Sie können versuchen, sich anzumelden",
        ATPLUS_DT_DIALOG: "Wenn Sie sich während des Betriebsdauertests zu weit vom AT+-System entfernen, können Daten verloren gehen.Geschätzte verbleibende Zeit: 01:00:00",
        ATPLUS_FT_DIALOG: "Es kann zu Datenverlust kommen, wenn Sie sich während des Funktionstests zu weit vom AT+-System entfernen.Geschätzte verbleibende Zeit: 00:02:00",
        ATPLUS_NO_LUMINAIRES_YET_DESCRIPTION: "Um Ihre Leuchten hier zu sehen, müssen Sie die Funktion „Alle Leuchten suchen“ nutzen und Ihr TLAT+ aktivieren.",
        ATPLUS_RELOGIN_SUCCESS_MESSAGE: "Ihre neuen Passwörter wurden gespeichert.",
  },
      SYSTEM_DETAIL: {
        USERS_DATA: "5 Administratoren, 1 Zuschauer",
        ACTIVATE_CONNECTIONS: "Aktivieren Sie Verbindungen in der Cloud",
        ADD_ANOTHER_CELLULAR: "Fügen Sie eine Backup-Mobilfunkverbindung hinzu",
        BOTH_PORTS_CANNOT_BE_ON_SAME_SUBNET: "Beide Ports dürfen nicht im selben Subnetz sein",
        BUILDING: "Gebäude",
        CONNECTION_TYPE: "Verbindungstyp",
        DATE_COMMISSIONED: "Inbetriebnahmedatum",
        DURATION_TEST: "Betriebsdauertest",
        ENABLED: "Aktiviert ",
        ENTER_VALID_GATEWAY_ADDRESS: "gültige Gateway-Adresse eingeben",
        ENTER_VALID_IP_ADDRESS: "gültige IP-Adresse eingeben",
        ENTER_VALID_SUBNET_MASK: "gültige Subnetzmaske eingeben",
        FIRMWARE_VERSION: "Firmware Version",
        FUNCTIONAL_TEST: "Funktionstest",
        GATEWAY: "Gateway ",
        GATEWAY_ADDRESS: "Gateway Adresse",
        GATEWAY_ADDRESS_REQUIRED: "Gateway Adresse",
        SYSTEM_UNLINKED_GATEWAY: "wurde getrennt von",
        SYSTEM_LINKED_GATEWAY: "wurde verbunden mit",
        HOST_NAME: "Hostname",
        INTIALIZE_CONNECTION: "Verbindung herstellen",
        INTIALIZE_NETWORK: "Netzwerk initialisieren...",
        IP_ADDRESS: "IP-Adresse",
        IP_ADDRESS_REQUIRED: "IP-Adresse",
        IP_GATEWAY_NOT_IN_SAME_SUBNET: "IP und Gateway befinden sich nicht in derselben Subnetzmaske",
        ETHERNET_CONNECTED: "ist jetzt mit dem Internet verbunden",
        SYSTEM_LINKING_TITLE: "Ist das das System, das Sie verbinden?",
        SYSTEM_UNLINKING_TITLE: "Ist das das System, das Sie trennen möchten?",
        SYSTEM_LINK_TITLE: "Verknüpfe ein System mit",
        LUMINAIRES: "Leuchten Leuchten",
        MAC_ADDRESS: "MAC-Adresse ",
        NETWORK_INFORMATION: "Netzwerk-Informationen",
        ORGANIZATION: "Organisation",
        OTHER_STEPS: "Weitere Schritte, falls erforderlich",
        PART_NUMBER: "Materialnummer",
        SETPORT_SYSTEM_DETAILS_DESCRIPTION: "Bitte vervollständigen Sie die Ethernet-Konfigurationsdetails.",
        SYSTEM_DETAILS_DESCRIPTION: "Diese Angaben finden Sie auf dem HMI-Bildschirm Ihres Systemschranks.",
        PORT_NUMBER: "Port-Nummer*",
        PREV: "vorherige",
        REGISTER_ACTIVATE_GATEWAY: "Gateway registrieren und aktivieren",
        SERIALNO_VALUE: "S3ri4l-Num63r",
        SERIAL_NUMBER: "Seriennummer",
        SETPORT_SYSTEM_LINK_TITLE: " Legen Sie den Port fest, mit dem ein System verbunden werden soll",
        SLAVE_NUMBER: "Slave-Nummer*",
        SUBNET_MASK: "Subnetzmaske ",
        SUBNET_MASK_REQUIRED: "Subnetzmaske ",
        SYN_DATE_TIME: "Datum und Uhrzeit synchronisieren",
        SYSTEM: "System",
        SYSTEM_NAME: "Systemname",
        SYSTEM_TYPE: "System type",
        STATUS_DESCRIPTION: "Dies ist eine Langbeschreibung. Sie kann den Standort des Geräts beschreiben und eine Kontaktperson beinhalten.",
        SYSTEM_UNLINK_TITLE: "Systemverknüpfung aufheben",
        UPDATE_REQUIRED: "Update erforderlich",
        UPLOAD_DETAILS: "Laden Sie Details über Bluetooth hoch",
        FIRMWARE_VERSION_VALUE: "v12.34-56",
        VERSION_AVAILABLE: "v123.457 Verfügbar",
        PARTNO_VALUE: "xx xx xx xx",
        ETHERNET_SUBTITLE: "Sie können jetzt über das Internet auf Ihr Gateway zugreifen. Beschreibung anpassbar.",
  },
      PASSWORD_REQUIREMENTS: {
        LENGTH: "8-16 Zeichen",
        LOWER: "Ein Kleinbuchstabe",
        NUMBERS: "Eine Nummer",
        SPECIAL: "Ein Sonderzeichen",
        UPPER: "Ein Großbuchstabe",
  },
      AUTH_TRANSLATION: {
        FORGOT_PASSWORD: {
          LINK_SENT_ALT: "Ein Link zum Zurücksetzen Ihres Passworts wurde an <1>{{email}}</1> gesendet.",
          LINK_SENT: "Ein Link zum Zurücksetzen Ihres Passworts wurde an <b>{{email}}</b> gesendet.",
          ERROR: "Ihr Passwort konnte derzeit nicht zurückgesetzt werden.",
          INSTRUCTIONS_ALT: "Bitte die mit dem Konto verknüpfte Konto-E-Mail-Adresse eingeben.Wenn für diese E-Mail ein Konto bei Eaton vorhanden ist, erhalten Sie innerhalb von <b>einem Werktag</b> eine Antwort.Bei dringenden Kontoproblemen rufen Sie bitte {{phone}} an.",
          INSTRUCTIONS: "Bitte die mit dem Konto verknüpfte Konto-E-Mail-Adresse eingeben.Wenn für diese E-Mail ein Konto bei Eaton vorhanden ist, erhalten Sie innerhalb von <b>einem Werktag</b> eine Antwort.Bei dringenden Kontoproblemen rufen Sie bitte {{phone}} an.",
          RESET_CODE_ERROR: "Es gab einen Fehler mit Ihrem Reset-Code. ",
  },
        MESSAGES: {
          USER_PHONE_NUMBER_INVALID: "Eine Telefonnummer ist ungültig",
          CONGRATS: "Herzlichen Glückwunsch!",
          CONTACT: "Kontaktieren Sie einen Eaton-Supportmitarbeiter",
          EMAIL_SENT: "E-Mail gesendet",
          ERROR: "Störung!",
          FAILURE: "Störung",
          INVALID_VERIFICATION_CODE: "Der Verifizierungscode ist ungültig oder die Länge sollte weniger als 6 Zeichen betragen",
          LOADING: "Wird geladen...",
          GENERIC_ERROR_MESSAGE: "Wenden Sie sich bitte an Ihren Administrator, wenn der angeforderte Vorgang nicht abgeschlossen werden kann.",
          EMAIL_ENTRY_ERROR: "Bitte gültige Email-Adresse eingeben",
          USER_EMAIL_INVALID: "Bitte gültige Email-Adresse eingeben",
          REQUEST_ERROR: "Leider ist beim Senden Ihrer Anfrage ein Problem aufgetreten.",
          SUCCESS: "Erfolg",
          WELCOME: "Willkommen",
          LOGIN_MESSAGE: "Sie sind eingeloggt",
  },
        CHANGE_PASSWORD: {
          PROBLEM_OCCURRED: "Es ist ein Problem aufgetreten:",
          CANCEL: "Abbrechen",
          PASSWORD: "Passwort ändern",
          CONFIRM_NEW_PASSWORD: "Bestätigung neues Passwort",
          OLD_PASSWORD: "Altes Passwort",
          PASSWORD_CHANGED: "Passwort geändert",
          PASSWORD_INFO: "Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
          UPDATE: "Update",
          EMAIL_CONFIRM_MESSAGE: "Wir haben eine Bestätigungs-E-Mail an <b>{{email}}</b> gesendet",
          ERROR_MESSAGE: "Ihre Angaben stimmen nicht mit unseren Daten überein. Bitte geben Sie Ihre Daten erneut ein, um es erneut zu versuchen.",
          SUCCESS_MESSAGE: "Ihr Passwort wurde erfolgreich aktualisiert! Um die Sicherheit Ihres Kontos zu gewährleisten, müssen Sie sich mit Ihren aktualisierten Zugangsdaten bei der Anwendung anmelden.",
  },
        SELF_REGISTRATION: {
          VERIFY_EMAIL: {
            MESSAGE: "Ein Verifizierungscode wurde an die von Ihnen angegebene E-Mail-Adresse gesendet. Klicken Sie auf den Link oder geben Sie den Code unten ein, um fortzufahren. Dieser Code ist 30 Minuten lang gültig.",
            VERIFICATION_CODE_PROMPT: "Keinen Code erhalten?",
            RESEND: "Verifizierungsmail erneut senden",
            VERIFICATION: "Verifizierungscode",
  },
          ALREADY_REGISTERED_USER: "Bereits registrierter Benutzer",
          INSTRUCTIONS: "Um sich für ein Eaton-Konto zu registrieren, geben Sie unten die erforderlichen Informationen ein. Sie müssen Ihre E-Mail-Adresse bestätigen, um fortfahren zu können.",
  },
        REGISTRATION: {
          STEPS: {
            COMPLETE: "nan",
            ACCOUNT_DETAILS: "nan",
            COMPANY_ADDRESS: "Firmenanschrift",
            COMPANY_DETAILS: "Unternehmensdetails",
            CREATE_ACCOUNT: "Ein Konto erstellen",
            PASSWORD: "Passwort erstellen",
            LICENSE: "Lizenzvereinbarung",
            VERIFY_EMAIL: "E-Mail verifizieren",
  },
          INSTRUCTIONS: {
            ACCOUNT_DETAILS: "Geben Sie unten Ihre Daten ein, um die Kontoerstellung abzuschließen.",
            COMPANY_DETAILS: "Dies hilft Eaton, den Service-Support für Ihre Notlichtlösung bereitzustellen. Wenn Sie ein Projekt für Ihren Kunden einrichten, tragen Sie bitte die Informationen für Ihr eigenes Unternehmen ein, nicht für das Ihres Kunden.",
            COMPANY_ADDRESS: "Dies hilft Eaton, den Service-Support für Ihre Notlichtlösung bereitzustellen. Wenn Sie ein Projekt für Ihren Kunden einrichten, tragen Sie bitte die Informationen für Ihr eigenes Unternehmen ein, nicht für das Ihres Kunden.",
  },
          EULA: {
            AGREE_TERMS: "Ich habe die Allgemeinen Geschäftsbedingungen gelesen und stimme ihnen zu",
            LOADING: "Endbenutzer-Lizenzvereinbarung wird geladen...",
  },
          UNKNOWN_EMAIL: "Unbekannte Email",
          UNKNOWN_ORGANIZATION: "Unbekannte Organisation",
          FAILURE_MESSAGE: "Wir konnten Ihre Registrierung nicht abschließen. Klicken Sie unten auf „Weiter“, um den Vorgang abzuschließen.",
          SUCCESS_MESSAGE_ALT: "Ihr Konto wurde erfolgreich mit der E-Mail-Adresse <1>{{email}}</1> erstellt.Ihr Konto wurde bereits zur Organisation <3>{{organization}}</3> hinzugefügt.Klicken Sie unten auf „Weiter“, um den Vorgang abzuschließen.",
          SUCCESS_MESSAGE: "Ihr Konto wurde erfolgreich mit der E-Mail-Adresse <b>{{email}}</b> erstellt.Sie wurden zu <b>{{organization}}</b> hinzugefügt.Bitte klicken Sie auf „Fertig stellen“, um sich anzumelden und auf Ihr Konto zuzugreifen.",
          SUCCESS_EXISTING: "Ihr Konto wurde erfolgreich erstellt. Bitte melden Sie sich mit der E-Mail-Adresse und dem Passwort Ihres Eaton-Kontos an.",
          SUCCESS_SYNCED_MESSAGE: "Ihr Konto wurde erfolgreich mit den Bright Layer Cloud-Informationen synchronisiert, die mit der E-Mail <2>{{E-Mail}}</2> verbunden sind.",
  },
        ACTIONS: {
          BACK: "Zurück",
          CANCEL: "Abbrechen",
          CHANGE_LANGUAGE: "Sprache ändern",
          CLICK_BUTTON: "Knopf drücken",
          UPDATE_REDUX: "Drücken Sie auf den Knopf, um den Redux-Store-Wert zu aktualisieren!",
          CONTINUE: "Fortsetzen",
          DONE: "Erledigt",
          FINISH: "Fertig",
          GO_HOME: "Zur Startseite",
          GO_DASHBOARD: "Zum Dashboard",
          GO_TEST: "Zur Testseite",
          LOG_IN: "Einloggen",
          LOG_OUT: "Ausloggen",
          NEXT: "Nächste ",
          OKAY: "Okay",
          REMEMBER: "Erinnere mich",
          RESEND: "Erneut senden",
          SEND_AGAIN: "Nochmal senden",
          SEND_EMAIL: "Sende E-Mail",
          CREATE_ACCOUNT: "Registrieren",
          UPDATE: "Update",
  },
        FORMS: {
          COMPANY_NAME: "Unternehmensname",
          CONFIRM_PASSWORD: "Passwort bestätigen",
          COUNTRY: "Land",
          CURRENT_PASSWORD_ERROR: "Aktuelles Passwort stimmt nicht überein",
          FIRST_NAME: "Vorname",
          RESET_PASSWORD: "Passwort vergessen",
          LAST_NAME: "Nachname",
          ORGANAIZATION_ADRESS: "Organisationsadresse Zeile 1 *",
          PASSWORD: "Passwort ",
          PASS_MATCH_ERROR: "Passwörter stimmen nicht überein",
          PHONE_NUMBER: "Telefonnummer",
          TOGGLE_PASSWORD_VISIBILITY: "Passwortsichtbarkeit umschalten",
  },
        LABELS: {
          CURRENT_PASSWORD: "Aktuelles Passwort",
          EMAIL: "E-Mail-Adresse",
          FORGOT_PASSWORD: "Passwort vergessen?",
          NEED_ACCOUNT: "Benötigen Sie ein Konto?",
          NEW_PASSWORD: "Neues Passwort",
          OPTIONAL: "Optional",
          PASSWORD: "Passwort ",
          USERNAME: "Benutzername ",
  },
        LOGIN: {
          INCORRECT_CREDENTIALS: "Falsche E-Mail oder falsches Passwort",
          GENERIC_ERROR: "Ihre Anfrage konnte derzeit nicht bearbeitet werden.",
          INVALID_CREDENTIALS: "Benutzername oder Passwort ist falsch",
  },
  },
      REGISTRATION: {
        STEPS_ERROR_MESSAGE: {
          USER_PHONE_NUMBER_INVALID: "Eine Telefonnummer ist ungültig",
          USER_ALREADY_EXIST_MESSAGE: "Bereits registrierter Benutzer",
          USER_INVALID_ENTRY: "Die Eingabeparameter dürfen nicht leer oder ungültig sein.",
          GENERIC_ERROR_MESSAGE: "Wenden Sie sich bitte an Ihren Administrator, wenn der angeforderte Vorgang nicht abgeschlossen werden kann.",
          USER_EMAIL_INVALID: "Bitte geben Sie eine gültige E-Mail Adresse an.",
          VERIFICATION_CODE_EXPIRED_MESSAGE: "Der Code ist abgelaufen. Bitte klicken Sie auf die Schaltfläche unten, um eine weitere Verifizierungs-E-Mail anzufordern.",
  },
        STEPS_MESSAGE: {
          CREATE_ACCOUNT_VERIFY_EMAIL: "Ein Verifizierungscode wurde an die von Ihnen angegebene E-Mail-Adresse gesendet. Klicken Sie auf den Link oder geben Sie den Code unten ein, um fortzufahren. Dieser Code ist 30 Minuten lang gültig.",
          CREATE_ACCOUNT_USER_DETAILS: "Geben Sie unten Ihre Daten ein, um die Kontoerstellung abzuschließen.",
          CREATE_ACCOUNT_COMPANY_DETAILS: "Dies hilft Eaton, den Service-Support für Ihre Notlichtlösung bereitzustellen. Wenn Sie ein Projekt für Ihren Kunden einrichten, tragen Sie bitte die Informationen für Ihr eigenes Unternehmen ein, nicht für das Ihres Kunden.",
          CREATE_ACCOUNT_ENTER_EMAIL: "Um sich für ein Konto für das Safety Management System zu registrieren, geben Sie die erforderlichen Informationen unten ein. Um fortzufahren, müssen Sie Ihre E-Mail-Adresse verifizieren.",
  },
        STEPS: {
          COMPLETE_OTHER_ADOPTER: "Konto bestätigt!",
          COMPLETE: "Konto erstellt!",
          ACCOUNT_DETAILS: "Kontodetails",
          COMPANY_ADDRESS: "Firmenanschrift",
          COMPANY_DETAILS: "Unternehmensdetails",
          CREATE_ACCOUNT: "Ein Konto erstellen",
          PASSWORD: "Passwort erstellen",
          FORGOT_PASSWORD: "Passwort vergessen",
          LICENSE: "Lizenzvereinbarung",
          VERIFY_EMAIL: "E-Mail verifizieren",
  },
        EULA: {
          AGREE_TERMS: "Ich habe die Allgemeinen Geschäftsbedingungen gelesen und stimme ihnen zu",
          LOADING: "Endbenutzer-Lizenzvereinbarung wird geladen...",
  },
        FAILURE_MESSAGE: "Wir konnten Ihre Registrierung nicht abschließen. Drücken Sie unten auf 'Weiter', um zur Eaton Building Safety Management Software zu gelangen.",
        SUCCESS_MESSAGE: "Ihr Konto wurde erfolgreich mit der E-Mail <2>{{email}}</2> erstellt.",
        SUCCESS_MESSAGE_OTHER_ADOPTER: "Ihr Konto wurde erfolgreich mit den Bright Layer Cloud-Informationen synchronisiert, die mit der E-Mail <2>{{E-Mail}}</2> verbunden sind.",
  },
      VERIFY_EMAIL: {
        EMAIL_INFO: "Ein Verifizierungscode wurde an die von Ihnen angegebene E-Mail-Adresse gesendet. Klicken Sie auf den Link oder geben Sie den Code unten ein, um fortzufahren. Dieser Code ist 30 Minuten lang gültig.",
  },
      USER_INVITATION_REGISTRATION: {
        ACCEPT: "Akzeptieren",
        DENY: "Ablehnen",
        INSTRUCTIONS: "Wenn diese E-Mail ein Konto bei Eaton hat, erhalten Sie eine Antwort innerhalb von <3>einem Werktag</3>.<br/><br/>Bei dringenden Kontofragen rufen Sie bitte an unter ",
        TITLE: "Safety Management System - Einladung",
        INVITATION_VALIDATION_ERROR: "Es gab einen Fehler mit Ihrem Einladungscode.",
  },
      DASHBORAD: {
        ACCEPT_BUTTON: "nan",
        ACCEPT_HEADER: "Die Einladung annehmen?",
        ACCEPT_TITLE: "Wenn Sie diese Einladung annehmen, wird das folgende Objekt zu Ihrem Profil hinzugefügt:",
        ADD_NEW_ORGANIZATION: "Eine neue Organisation hinzufügen...",
        ALL_BUILDINGS: "Alle Gebäude",
        ALL_GATEWAYS: "Alle Gateways",
        ALL_ORGANIZATIONS: "Alle Organisationen",
        ALL_STATUSES: "Alle Stati",
        ALL_TYPES: "Alle Typen",
        BLOCKED: "Blockiert ",
        BUILD_DETAILS: "Gebäudedetails",
        BUILDINGS: "Gebäude",
        DECLINE: "Ablehnen",
        DECLINED_INVITATION: "Abgelehnte Einladungen",
        MAP_DRAG_TITLE: "Auf der Karte ziehen um die Stecknadel zu platzieren",
        REQUEST_ACCESS_SENT: "E-Mail-Zugriffseinladung an den Benutzer gesendet",
        ERROR: "Störung",
        EVENT: "Ereignis",
        EXPIRED_INVITATION: "Abgelaufene Einladungen",
        EXPORT_REPORT: "Bericht exportieren",
        GATEWAY: "Gateways",
        IN_ANOMALY: "In Störung",
        INFORMATION: "Information ",
        INVITATION_HISTORY: "Einladungsverlauf",
        ISSUES: "Probleme",
        JOIN: "Beitreten",
        LIST_VIEW: "Listenansicht",
        MAP_VIEW: "Kartenansicht",
        MONTHLY_REPORT: "Monatlicher Bericht",
        NO_ISSUES_DURING_THIS_PERIOD: "Keine Probleme während dieses Zeitraums",
        ORG_DETAILS: "Organisationsdetails",
        PENDING_INVITATION: "Ausstehende Einladungen",
        CHOOSE_REPORT_YOU_WOULD_LIKE_TO_SAVE_SHARE: "Bitte wählen Sie den Bericht aus, den Sie speichern oder teilen möchten.",
        PLEASE_MAKE_A_SELECTIONS: "Bitte Auswahl treffen",
        REQUEST_SUBTITLE: "Bitte beantragen Sie einen Zugang bei der Person die sie eingeladen hat, um dieses Objekt zu Ihrem Profil hinzuzufügen",
        INVITATION_BODY: "Bitte Objekte auswählen die Sie Ihrem Profil hinzufügen oder für die Sie eine Einladung ablehnen möchten.",
        READY: "Bereit",
        NO_CONTENT: "Ersetzen Sie diese Seite mit Ihrem eigenen Inhalt",
        REPORTS: "Berichte",
        REQUEST_BUTTON: "Zugang anfragen",
        REQUEST_HEADER: "Zugang anfragen?",
        CHECK_INVITATIONS: " Nach Einladungen suchen...",
        SELECT_ALL: "Alle auswählen",
        SELECTIONS_CONFIRMED: "Auswahl bestätigt",
        SYSTEMS: "Systeme",
        EMPTY_DECLINED_INVITATION: "Derzeit liegen keine abgelehnten Einladungen vor",
        EMPTY_EXPIRED_INVITATION: "Derzeit sind keine Einladungen abgelaufen.",
        EMPTY_REQUESTED_INVITATION: "Derzeit sind keine Einladungen abgelaufen.",
        REQUEST_TITLE: "Diese Einladung ist abgelaufen und kann Ihrem Profil nicht mehr hinzugefügt werden.",
        TOTAL_ACCEPTED_INVITATIONS: "Gesamtzahl der angenommenen Einladungen",
        TOTAL_LUMINAIRES: "Gesamtzahl Leuchten",
        TOTAL_SYSTEMS: "Gesamtzahl Systeme",
        UPDATING_PROFILE: "Profil aktualisieren...",
        VIEW_ALL: "Alle anzeigen",
        VIEW_ALL_BUIDINGS: "Alle Gebäude anzeigen",
        VIEW_ALL_SYSTEM: "Alle Systeme anzeigen",
        WARNING: "Warnung ",
        INVITATION_SUB_HEADING: "Sie wurden zu folgenden Projekten eingeladen:",
        ACCEPT_SUBTITLE: "nan",
  },
      COMMON: {
        ACCESS: "Zugriff",
        ACTIVE: "Aktiv",
        ALERT: "Alarm!",
        DOWNLOAD_PERMISSION: "Erlauben Sie die Berechtigungen zum Speichern von Dateien auf Ihrem Gerät",
        ASSETS: "Systeme",
        BACK: "Zurück",
        BLOCKED: "BLOCKIERT",
        CANCEL: "Abbrechen",
        CANNOT_REFRESH_TOKEN: "Token kann nicht aktualisiert werden",
        CLOSE: "Schließen",
        SET_ETHERNET: "Porteinstellungen konfigurieren",
        CONFIRM: "Bestätigen",
        CONNECT: "Verbinden",
        CSV: "CSV",
        DASHBOARD: "Dashboard",
        DESCRIPTION: "Beschreibung",
        DONE: "Erledigt",
        COMPANY: "Eaton",
        EDIT: "Bearbeiten",
        APP_NAME: "Building Safety Manager",
        EMPTY: "LEER",
        INTERVAL_SEVENDAYS: "Alle 7 Tage",
        INTERVAL_90DAYS: "Alle 90 Tage",
        EXCEL: "Excel-Datei",
        EXIT: "Beenden",
        EXTEND: "Erweitern",
        DOWNLOAD_SUCCESS: "Datei erfolgreich heruntergeladen.",
        ANOMALY: "IN STÖRUNG",
        INACTIVE: "Inaktiv",
        INFO: "Info",
        INTERVAL: "Intervall",
        LAST_RETREIVED: "Zuletzt abgerufen:",
        LAST_RETRIEVED: "Letzter Abruf vor: -- Minuten",
        LINK: "Verknüpfen",
        LOGBOOK: "Prüfbuch",
        NEXT: "Weiter",
        NODATA: "Keine Daten",
        OFFLINE: "Offline",
        OK: "OK",
        PASSWORD: "Passwort",
        PDF: "PDF",
        READY: "BEREIT",
        RECOONECTTOGATWAY: "Gateway wieder verbinden",
        RETURNTODASHBOARD: "Zurück zum Dashboard",
        NAVIGATION_HEADER: "Safety Management System",
        SAVE: "Speichern",
        REFRESH_TOKEN_ERROR: "Das Sicherheitstoken darf nicht null oder leer sein",
        SESSION_ALERT_TITLE: "Sitzung abgelaufen",
        SET: "Festlegen",
        SETTINGS: "Einstellungen",
        VIEW_ALL_SYSTEM: "Alle anzeigen",
        SKIP: "Überspringen",
        STOP: "Stopp",
        PERMISSION_ERROR: "Speichererlaubnis nicht erteilt",
        SYNC: "Synchronisieren",
        SYSTEM: "System",
        SESSION_ALERT_SUBTITLE: "Dieses Fenster war 15 Minuten lang inaktiv. Wenn innerhalb von {{extendTime}} keine Aktion durchgeführt wird, werden Sie abgemeldet.",
        TRYAGAIN: "Erneut versuchen",
        DOWNLOAD_FAIL: "Download nicht möglich, erneut versuchen.",
        UNAUTHORIZED_ACCESS: "Unautorisierter Zugriff",
        UNLINK: "Trennen",
        USERS: "Benutzer",
        WELCOME: "Willkommen",
        YES: "Ja",
        EMPTY_SYSTEM_TITLE: "Sie haben noch keine Anlagen in dieser Organisation.",
        EMPTY_SYSTEM_SUBTITLE: "Sie erhalten Statusinformationen über Ihre Notleuchten, sobald Sie dieser Organisation Anlagen (Systeme) hinzufügen.",
  },
      TRANSFER_BUILDINGS: {
        PASSWORD: "Konto Passwort",
        HEADER: "Übertragen Sie alle {{buildingCount}} Gebäude, einschließlich aller X Gateways und {{systemCount}} Systeme darunter, dauerhaft an eine andere Organisation. Sie müssen Organisationsadministratoren beider Organisationen sein, um die Aktion ausführen zu können.",
        ACTION: "Bitte geben Sie Ihr Kontopasswort ein, um die Aktion zu bestätigen.",
        TRANSFER_TO: "Übertragen an…",
  },
      DRAWER: {
        ACCOUNTPREFERENCES: "Kontoeinstellungen",
        RIGHTS_RESERVED: "Alle Rechte vorbehalten",
        ASSETS: "Systeme",
        COPYRIGHT: "Copyright © Eaton",
        DASHBOARD: "Dashboard",
        OVERVIEW: "Dashboard",
        DEVICES: "Geräte",
        HELP: "Hilfe",
        HELPANDFEEDBACK: "Hilfe und Feedback",
        LEGAL: "Rechtlich",
        LOCATIONS: "Standorte",
        LOGBOOK: "Prüfbuch",
        MANAGELOCATION: "Standorte verwalten",
        MANAGEUSER: "Benutzer verwalten",
        ORGSETTINGS: "Meine Organisationen",
        MYPROFILE: "Mein Profil",
        EMERGENCY_LIGHT: "Powered by Brightlayer",
        REPORTS: "Berichte",
        ELDIGITAL: "Safety Management System",
        SELECT_ACCOUNT: "Konto auswählen",
        SETTINGS: "Einstellungen",
        TIMELINE: "Zeitleiste",
        USERS: "Benutzer",
  },
      SETTINGS: {
        TITLE: "Kontoeinstellungen",
        SUBTITLE: "Administrator Level - Benutzer",
  },
      NAVIGATION_DRAWER: {
        ACCOUNT_PREFERENCES: "Kontoeinstellungen",
        ALL_RIGHTS_RESERVED: "Alle Rechte vorbehalten",
        CHANGE_PASSWORD: "Passwort ändern ",
        COPYRIGHT: "Copyright",
        HELP_AND_FEEDBACK: "Hilfe und Feedback",
        LEGAL: "Rechtlich",
        LOGOUT: "Logout ",
        PROJECT_SETTINGS: "Meine Organisationen",
        REPORTS: "Berichte",
        USERS: "Benutzer",
  },
      USER_MENU: {
        ACCOUNT_SETTING: "Kontoeinstellungen",
        CONTACT_US: "Kontaktieren Sie uns",
        LOG_OUT: "Ausloggen",
  },
      USER_DETAIL: {
        ACTIVE: "AKTIV",
        COMPANY_ADRESS: "Firmenanschrift",
        COMPANY_DETAIL: "Unternehmensdetails",
        COMPANY_NAME: "Unternehmensname",
        COMPANY_PHONE_NUMBER: "Telefonnummer des Unternehmens",
        EMAIL_ID: "E-Mail-Adresse",
        INACTIVE: "INAKTIV",
        PERSONAL_ADRESS: "Berufsbezeichnung",
        LEAVE_ORGANISATION: "Organisation verlassen",
        JOB_TITLE: "Persönliche Adresse",
        PERSONAL_PHONE_NUMBER: "Persönliche Telefonnummer",
        PHONE_NUMBER: "Telefonnummer",
        REMOVE_USER: "Benutzer entfernen",
        USER_NAME: "Benutzername",
  },
      FILTER: {
        ACTIVE_LOGS_ONLY: "Nur aktive Ereignisse",
        ALL: "Alle ",
        ALL_LOG_TYPES: "Alle Ereignistypen",
        CUSTOM: "Benutzerdefiniert...",
        PAST_TWELVE_MONTHS: "Letzte 12 Monate",
        PAST_SIX_MONTHS: "Letzte 6 Monate",
        SINCE_THE_BEGINNING_OF_YEAR: "Seit Beginn von {{currentyear}}",
  },
      LOGBOOK: {
        LOGBOOK_ACTIVELOG: "Nur aktive Ereignisse",
        LOGBOOK_ALL: "Alle ",
        LOGBOOK_ALLLOG: "Alle Ereignistypen",
        CUSTOM_LOGBOOK_RANGE: "Benutzerdefinierte Prüfbuch-Sortierung",
        CUSTOM: "Benutzerdefiniert...",
        END_DATE: "Enddatum",
        LOGBOOK_GROUPBY: "Gruppiere nach:",
        PAST_TWELVE_MONTHS: "Letzte 12 Monate",
        PAST_SIX_MONTHS: "Letzte 6 Monate",
        LOGBOOK_SHOW: "Anzeigen :",
        SINCE_THE_BEGINNING_OF_YEAR: "Seit Beginn von {{currentyear}}",
        START_DATE: "Startdatum",
        LOGBOOK_TIME_RANGE: "Zeitspanne :",
  },
      BUILDING_DETAILS: {
        ADD_BUILDING: "Gebäude hinzufügen",
        ALL_SYSTEMS: "Alle Systeme",
        VIEW_ALL_GATEWAYS: "Alle Gateways anzeigen",
  },
      ADD_ORGANIZATION: {
        ORG_ADD_DESCRIPTION: "Fügen Sie optional zusätzliche Kommentare hinzu, um Ihr Serviceteam zu unterstützen.",
        ADDITIONAL_DESC: "zusätzliche Beschreibung",
        ORG_ADD_BLDG_BELONGS: "gehört",
        CREATE_ORGANIZATION_TITLE: "Organisation erstellen",
        ORG_SUBTITLE: "Durch die Erstellung einer Organisation können Sie Gebäude gruppieren und die Überwachung von Notlichtsystemen koordinieren.",
        DETAILS_FOR: "Details für",
        ORG_FOR: "für",
        INVALID_CHARACTER: "Ungültiges Zeichen",
        ORG_NAMELENGTH: "Nur Buchstaben, Zahlen, Unterstriche und Bindestriche",
        ORG_ADD_BLDG: " Fügen wir nun das Gebäude hinzu, in dem sich das",
        ORGANIZATION_CREATED: "Organisation erstellt",
        VALID_EMAIL_ERROR: "Bitte gültige Email-Adresse eingeben",
        ORG_PRIMARYCONTACT: "Hauptkontakt",
        ORG_PROVIDED_DETAILS: "Geben Sie Einzelheiten an, falls xxx erforderlich ist.",
        ORG_SECONDARYCONTACT: "Alternativkontakt (optional)",
        ORG_SKIPBUTTON: "Vorerst überspringen",
        ORG_SKIPMESSAGE: "Vorerst überspringen um Kontaktdetails individuell pro Gebäude einzugeben",
        ORG_SUCCESS: "Organisation erfolgreich erstellt",
        ALPHABET_INPUT: "Akzeptiert alphabetische Eingaben",
        ALPHANUMERIC_INPUT: "Akzeptiert alphanumerische Eingaben",
        ALPHANUMERIC_DASH_UNDERSCORE_INPUT: "Akzeptiert alphanumerische Zeichen, Bindestriche und Unterstriche als Eingabe",
        ORG_HELPTEXT: "Dies hilft Elektrikern von Eaton und anderen außerhalb Ihres Unternehmens, schnell vor Ort zu sein.",
        ORGANIZATION_DETAILS: "An wen sollen sich Elektriker wenden?",
  },
      MANAGE_USERS: {
        ADVANCED_INVITE_USER_ORGANIZATION_SELECTION: "Fügen Sie sie zu Organisationen hinzu:",
        INVITE_USER_ORGANIZATION_LABEL: "Zur Organisation hinzufügen... *",
        USER_ROLE_ADMIN: "Administrator",
        ADVANCED_INVITE_ADMIN: "Administrator:",
        ADVANCED_INVITE: "Erweiterte Einladung",
        INVITE_USER_ADVANCE: "Erweitert...",
        INVITE_USER_AS: "Als",
        ADVANCED_INVITE_USER_SELECTION: "Wählen Sie einen neuen oder bestehenden Benutzer:",
        INVITE_USER_EMAIL_NAME: "E-Mail *",
        INVITE_USER_EMAIL: "E-Mail *",
        INVITE_USER_EMAIL_USER_NAME: "E-Mail oder Benutzername *",
        INVITE_USER_SUBTITLE: "Gewähren Sie neuen oder bestehenden Benutzern Zugriff",
        ADVANCED_INVITE_SUBTITLE: "Gewähren Sie Zugriff auf bestimmte Organisationen, Gebäude und Systeme.",
        ADVANCED_INVITE_ADMIN_DESCRIPTION: "Gewähren Sie vollen Zugriff auf alles innerhalb der zugewiesenen Ebene.",
        ADVANCED_INVITE_LIMITED_DESCRIPTION: "Gewähren Sie minimale Anzeigerechte, sodass ein Benutzer nur grundlegende Informationen zu den für seine Rolle wichtigen Informationen sieht. Dies ist nützlich, wenn jemand nur ein System innerhalb eines Gebäudes überwachen muss.",
        ADVANCED_INVITE_VIEWER_DESCRIPTION: "Gewähren Sie Anzeigeprivilegien für alles innerhalb einer zugewiesenen Ebene.",
        INVITATION_FAIL_HEADER: "Einladung abgelaufen",
        INVITE_USER_AS_COLLEAGUE: "Als Kollege einladen",
        INVITE_USER: "Benutzer einladen",
        INVITE_SUCCESS_MESSAGE_SINGLE: "{{email}} zu {{organization}} eingeladen.",
        INVITE_SUCCESS_MESSAGE_MULTIPLE: "{{email}} zu {{organizationCount}} Organisationen eingeladen.",
        USER_ROLE_LIMITED: "Begrenzt",
        ADVANCED_INVITE_LIMITED: "Begrenzt:",
        MY_DETAILS_HEADER: "Meine Details",
        OTHER_USER_LIST_HEADER: "Andere Benutzer",
        ADVANCED_INVITE_PLEASE_ASSIGN_ROLES: "Bitte weisen Sie Rollen für den {{email}}-Zugriff auf Organisationen, Gebäude und Systeme zu.",
        INVITATION_REQUEST_ACTION: "Weitere Einladung anfordern",
        INVITE_USER_ORGANIZATION_SELECT_ALL: "Alle auswählen",
        INVITE_USER_SEND_INVITE: "Einladung senden",
        INVITATION_FAIL_BODY: "Dieser E-Mail-Einladungslink ist abgelaufen. Bitte wenden Sie sich an Ihren Systemadministrator, um eine weitere E-Mail-Einladung zu erhalten.",
        USERS_IN_HEADER: " Benutzer in",
        USER_ROLE_VIEWER: "Zuschauer",
        ADVANCED_INVITE_VIEWER: "Zuschauer:",
  },
      ORGANIZATION_DETAILS: {
        ADDRESS: "Adresse",
        BUILDING_DETAILS: "Gebäudedetails",
        DELETE_FOREVER: "Unwiederuflich löschen",
        EDIT_BUILDING: "Gebäude bearbeiten",
        EDIT_ORGANIZATION: "Organisation bearbeiten",
        EMAIL_ADDRESS: "E-Mail-Adresse",
        MERGE_ASSETS: "Systeme in ein anderes Gebäude versetzen",
        ORGANIZATION: "Organisation",
        ORGANIZATION_DETAILS: "Organisationsdetails",
        PHONE_NUMBER: "Telefonnummer",
        PRIMARY_CONTACT: "Hauptkontakt",
        PRIMARY_CONTACT_EMAIL_ADDRESS: "Hauptkontakt E-Mail-Adresse",
        PRIMARY_CONTACT_PHONE_NUMBER: "Hauptkontakt Telefonnummer",
        SECONDARY_CONTACT: "Alternativkontakt",
        SECONDARY_CONTACT_EMAIL_ADDRESS: "Alternativkontakt E-Mail-Adresse",
        SECONDARY_CONTACT_PHONE_NUMBER: "Alternativkontakt Telefonnummer",
        TRANSFER_BUILDINGS: "Übertragen Sie Gebäude an eine andere Organisation",
        USERS: "Benutzer",
  },
      COMMON_INPUT: {
        COMMON_ADDRESS: "Adresse",
        COMMON_ADDRESSLINE2: "Adresszeile 2 (Optional)",
        COMMON_BUILDINGNAME: "Gebäudename",
        COMMON_CITY: "Stadt",
        COMMON_COUNTRY: "Land",
        COMMON_OPTIONALDESC: "Beschreibung (optional)",
        COMMON_EMAIL_ADDRESS: "E-Mail-Adresse",
        COMMON_NAME: "Organisationsname",
        COMMON_PHONENUMBER: "Telefonnummer",
        VALID_PHONE_ERROR: "Bitte gültige Email-Adresse eingeben",
        VALID_POSTAL_CODE: "Bitte gültige Postleitzahl eingbeen",
        COMMON_POSTALCODE: "Postleitzahl",
  },
      MY_ORGANIZATION_DETAILS: {
        ADDRESS_Line1: "Adresszeile 1",
        ADMINS: "Administratoren",
        DELETE_FOREVER: "Unwiederuflich löschen",
        DESCRIPTION: "Beschreibung (optional)",
        EDIT_BUILDING: "Gebäude bearbeiten",
        EDIT_ORGANIZATION: "Organisation bearbeiten",
        NAME_DESCRIPTION: "Name und Beschreibung",
        SAVE_ORGANIZATION: "Organisation speichern",
        TRANSFER_BULDING_ANOTHER_ORGANIZATION: "Übertragen Sie Gebäude an eine andere Organisation",
        VIEWER: "Zuschauer",
  },
      INVITE_USER: {
        ADMIN: "Administrator",
        ADVANCE_INVITATION: "Erweiterte Einladung",
        ADVANCE_INVITE: "Erweitert...",
        AS_ADMIN: "Als Admin",
        AS_VIEWER: "Als Zuschauer",
        ENTER_USER_EMAIL: "E-Mail oder Benutzername",
        GRANT_ACCESS_HEADER: "Gewähren Sie neuen oder bestehenden Benutzern Zugriff",
        SEND_INVITE_FAILURE: "Senden der Einladung fehlgeschlagen",
        SEND_INVITE_SUCCESS: "Senden der Einladung erfolgreich",
        INVITATION_ACCEPTED: "Einladung angenommen.",
        INVITATION_DENIED: "Einladung abgelehnt.",
        INVITE_AS_COLLEGUE: "Als Kollege einladen",
        INVITE_USER: "Benutzer einladen",
        RECEIVED: "Erhalten",
        SEND_INVITE: "Einladung senden",
        SENT_BY: "Gesendet von",
        VIEWER: "Zuschauer",
        ERROR_MESSAGE: "Ihre Anfrage ist fehlgeschlagen",
  },
      DISCLAMIER: {
        OVERVIEW: "Die Alarmanzahl stellt alle aktiven Alarme dar",
        TIMELINE: "Die angezeigten Alarme stellen alle Alarme der letzten 90 Tage dar",
  },
      SEARCH: {
        ALL_BUILDINGS: "Alle Gebäude",
        ALL_GATEWAYS: "Alle Gateways",
        ALL_ORGANIZATIONS: "Alle Organisationen",
        ALL_STATUSES: "Alle Stati",
        ALL_SYSTEMS: "Alle Systeme",
        ALL_TYPES: "Alle Typen",
  },
      CUSTOMER_DETAILS: {
        ALL_USERS: "Alle Benutzer",
        BACK_UP_PHONE: "Backup-Telefonnummer",
        BUYER_HEADER: "Käufer",
        BUYER: "Käufer",
        CONTACT_TYPE: "Kontaktart",
        CONTACT_PERSON_ON_SITE: "Lokale Kontaktperson",
        CUSTOMER_HEADER_SUBTITLE: "Kunde",
        CUSTOMER_NAME: "Kundenname",
        CUSTOMER_DETAILS_HEADER: "Kundenname & Adresse",
        CUSTOMER_NUMBER: "Kundennummer",
        DELETE_CUSTOMER: "Kunde löschen",
        LUMINARY_INFO: "Beinhaltet dieser Vertrag eine Leuchtenüberwachung?",
        EDIT_CUSTOMER_INFO: "Kundeninformationen bearbeiten",
        CREATE_CUSTOMER_HEADER: "Kundeninformationen bearbeiten",
        IMAGES: "Bilder",
        SLA_DETAILS_TEXT: "Leuchtenüberwachung inklusive",
        CALENDAR_HELPER_TEXT: "MM/TT/JJJJ",
        MOBILE_NUMBER: "Handynummer",
        LUMINARY_MONITORING_NO: "Nein",
        CUSTOMER_NUMBER_HELPER_TEXT: "Nur Zahlen",
        OFFICE_NUMBER: "Büronummer",
        IMAGE_REQUIREMENTS: "PNG, JPG (100x100px), 2 MB Max.",
        PHONE_NUMBERS_HEADER: "Haupt- & Alternativ Backup-Telefonnummern",
        PRIMARY_CONTACT_INFO: "Hauptkontaktinformationen",
        PROJECT_LEAD: "Projektleitung",
        REMOVE_IMAGE: "Bild entfernen",
        SECONDARY_CONTACT_INFO: "Alternativkontaktinformationen",
        FILE_SIZE_ERROR: "Die ausgewählte Dateigröße überschreitet maxFileSize",
        SLA_CUSTOMER_NUMBER: "SLA-Kundennummer",
        SLA_DETAILS_HEADER: "SLA-Details",
        SLA_EXPIRATION_DATE: "SLA-Ablaufdatum",
        TECH_LEAD_HEADER: "Technischer Leiter",
        TECHNICAL_PERSON: "Techniker",
        UPLOAD_AVATAR_LOGO: "Avatar-Logo hochladen",
        LUMINARY_MONITORING_YES: "Ja ",
  },
      USER_MANAGEMENT: {
        ALL_USERS: "Alle Benutzer",
        INVITE_USER: "Benutzer einladen",
        YOUR_DETAILS: "Ihre Details",
  },
      MY_ORGANIZATION: {
        ALPHABET: "Alphabet",
        CITY: "Stadt",
        NUMBER_SYSTEM: "Anzahl der Systeme",
  },
      FIRMWARE_UPDATE: {
        FIRMWARE_UPDATE_ALERT_TITILE: "Sind Sie sicher, dass Sie beenden möchten?",
        CURRENT_VERSION: "Aktuelle Version",
        FIRMWARE_UPDATE_FAILED_TITLE: "Firmware-Update fehlgeschlagen",
        FIRMWARE_PROGRESS_MSG: "Firmware-Update läuft...",
        FIRMWARE_UPDATE_ALERT_SUB_TITILE: "Das Firmware-Update ist noch im Gange. Möchten Sie zum Dashboard gehen?",
        FIRMWARE_UPDATE_FAILED: "Der Firmware-Aktualisierungsprozess ist fehlgeschlagen. Bitte erneut versuchen.",
        FIRMWARE_VERSION_UPDATED: "Das Firmware-Upgrade wurde erfolgreich abgeschlossen",
        LATEST_VERSION: "Neueste Version",
        LOST_BLUETOOTH_CONNECTION: "Bluetooth-Verbindung verloren",
        ENTRY_PASSWORD: "Bitte Systempasswort eingeben für",
        RELEASED_ON: "Veröffentlicht am",
        SELECT_FIRMWARE_VERSION: "Wählen Sie eine Firmware-Version aus",
        SELECT_A_VERSION: "Version auswählen",
        UPDATE_TO_LATEST: "Auf den neuesten Stand aktualisieren",
        UPLOAD_FILE: "Datei hochladen",
        FIRMWARE_UPDATE_TITILE: "Wir empfehlen Ihnen, die Firmware auf die neueste Version zu aktualisieren.",
        FIRMWARE_UPDATEPOPUP_MSG: "Sie sind nicht mehr verbunden mit",
  },
      ASSETS: {
        ASSETS_DETAILS: "Systemdetails",
  },
      SYSTEM_TYPE: {
        ATPLUS: "AT+",
        CGLINE: "CGLine+",
        DGS: "DualGuard-S",
        LPSMART: "DualSmart-S",
        LPSTAR: "LPStar",
        WIRELESS: "Drahtlos",
        ZBS: "ZB-S",
  },
      DEVICE_TYPE_VALUE: {
        AT: "AT+",
        ATS: "AT-S+",
        CGLINE: "CGLINE",
        DGS: "DualGuard-S",
        LPSMART: "DualSmart-S",
        LPSTAR: "LP-STAR",
        WIRELESS: "DRAHTLOS",
        ZBS: "ZB-S",
  },
      CONTROLLER_TYPE_VALUE: {
        AT: "AT+",
        CGLINE_PLUS: "CGLine+",
        DGS: "DualGuard-S",
        LP_SMART: "DUALSMART-S",
        LP_STAR: "LP-STAR",
        WIRELESS: "DRAHTLOS",
        ZBS: "ZB-S",
  },
      LUMINARY_DETAILS: {
        ATSD: "SKU #",
        CIRCUIT: "Stromkreis",
        LUMINAIRE_ADDRESS: "Leuchtenadresse",
        MODEL: "Modell",
        TYPE: "Typ",
  },
      ACTIONS: {
        BACK: "Zurück",
        CANCEL: "Abbrechen",
        CHANGE_LANGUAGE: "Sprache ändern",
        CLICK_BUTTON: "Knopf drücken",
        UPDATE_REDUX: "Drücken Sie auf den Knopf, um den Redux-Store-Wert zu aktualisieren!",
        CONFIRM: "Bestätigen",
        CONTINUE: "Fortsetzen",
        DELETE: "Löschen",
        RESEND_VERIFICATION_EMAIL_LABEL: "Keine E-Mail erhalten?",
        DISABLE: "Deaktivieren",
        DONE: "Erledigt",
        DOWNLOAD_DATA: "Datendownload",
        EDIT: "Bearbeiten",
        ENABLE: "Aktivieren",
        EXTEND: "Erweitern",
        FINISH: "Fertig",
        GO_HOME: "Zur Startseite",
        GO_TO_DASHBOARD: "Zum Dashboard",
        GO_TEST: "Zur Testseite",
        LOG_IN: "Einloggen",
        LOG_OUT: "Ausloggen",
        NEXT: "Weiter",
        OKAY: "OK",
        QUIT: "Beenden",
        REMEMBER: "Erinnere mich",
        RETRY: "Wiederholen",
        SAVE: "Speichern",
        SEND: "Senden",
        RESEND: "Nochmal senden",
        RESEND_VERIFICATION_EMAIL: "Nochmal senden",
        SUBMIT: "Abschicken",
        SUBMITCHANGES: "Änderungen abschicken",
        TRANSFER: "Übertragen",
        UPDATE: "Update",
  },
      NETWORK: {
        BAD_REQUEST_MESSAGE: "Ungültige Anforderung",
        INTERNAL_SERVER_ERROR_MESSAGE: "interner Serverfehler",
        NO_DATA_FOUND: "Keine Daten gefunden",
        REQUEST_NOT_FOUND_MESSAGE: "Nicht gefunden",
        ERROR_INTERNET_CONNECTION: "Anscheinend haben Sie keine Internetverbindung. Versuchen Sie es erneut.",
        SERVICE_UNAVAILABLE: "Dienst nicht verfügbar",
        ERROR_NO_RESPONSE: "Etwas ist schief gelaufen",
        ERROR_MESSAGE_SENDING_REQUEST: "Leider ist beim Senden Ihrer Anfrage ein Problem aufgetreten.",
        ERROR_INVALID_REQUEST: "Leider ist Ihre Anfrage ungültig.",
        NETWORK_ALERT_SUBTITILE: "Das Dashboard ist nicht verfügbar, da Sie nicht mehr mit dem Internet verbunden sind",
        ERROR_NOT_FOUND: "Die Ressource wurde nicht gefunden.",
        ERROR_ALERT_TITLTE: "Verbindung zum Dashboard fehlgeschlagen",
        UNAUTHORISED_REQUEST_MESSAGE: "Nicht autorisiert – Für diese Anfrage wurde die Autorisierung verweigert",
        ERROR_RESTRICTED_USER_ACCESS: "Der Benutzer hat keinen Zugriff auf die Ressource.",
  },
      CONNECTION_TYPE_VALUE: {
        BLE: "BLE",
        BLUETOOTH: "Bluetooth",
        CELLULAR: "Mobilfunk",
        ETHERNET: "Ethernet",
        MODBUS: "Modbus",
        MQTT: "MQTT",
        RS485: "RS485",
        WIFI: "W-LAN",
        WIREPAS: "Wirepas",
  },
      STATUS: {
        STATUS_BLOCK: "Blockiert",
        BLOCKED: "Blockiert",
        STATUS_ERROR: "Störung",
        ERROR: "Störung",
        EVENT: "Ereignis ",
        DT: "Im BT",
        STATUS_DT: "Test läuft",
        INTEST: "Test läuft",
        STATUS_OFFLINE: "Offline",
        STATUS_READY: "Bereit",
        READY: "Bereit",
        TOTAL_LUMINAIRES: "Gesamtzahl Leuchten",
        TOTAL_SYSTEM: "Gesamtzahl Systeme",
        STATUS_WARNING: "Warnung ",
        WARNING: "Warnung ",
  },
      UNSUPPORTED_BROWSER: {
        NOT_SUPPORTED: "Browser wird nicht unterstützt",
        INCOMPATIBLE_BROWSER: "Diese Webanwendung ist mit Ihrem aktuellen Browser nicht kompatibel.",
        RECOMMENDED_BROWSER: "Wir empfehlen, auf die neueste Version Ihres Webbrowsers aufzurüsten, um dieses Tool zu nutzen.",
  },
      ENTITY_TYPE: {
        BUILDING: "Gebäude",
        DEVICE: "Gerät",
        DEVICE_TYPE: "Gerätetyp",
        GATEWAY: "Gateway ",
        LUMINARY: "Leuchte",
        MASTER_ROLE: "Master-Rolle",
        ORGANIZATION: "Organisation",
        ORGANIZATION_STATISTIC_DETAIL: "Organisations-Statistiken",
        SYSTEM: "System ",
        USER: "Benutzer ",
  },
      LOGSOURSE_DEVICE_TYPE: {
        BUILDING: "Gebäude",
        DEVICE: "Gerät ",
        DEVICETYPE: "Gerätetyp",
        GATEWAY: "Gateway",
        LUMINARY: "Leuchte",
        MASTERROLE: "Master-Rolle",
        ORGANIZATION: "Organisation",
        ORGANIZATIONSTATISTICALDETAIL: "OrganizationStatisticalDetail",
        SYSTEM: "System ",
        USER: "Benutzer ",
  },
      TOAST_MSG: {
        TOAST_SUCCESS_BUILDING_CREATE: "Gebäude erfolgreich erstellt",
        BUILDING_LIST_API_FAILURE: "Gebäudeauflistung fehlgeschlagen",
        BUILDING_TRANSFER_FAILURE: "Gebäudeübertragung fehlgeschlagen",
        BUILDING_TRANSFER_SUCCESS: "Gebäudeübertragung erfolgreich",
        TOAST_SUCCESS_BUILDING_UPDATE: "Gebäude erfolgreich aktualisiert",
        CUSTOMER_DETAIL_SUCCESS: "Abruf der Kundendaten erfolgreich",
        TOAST_ERROR_GET_CUSTOMER_INFO: "Abruf der Kundendaten fehlgeschlagen",
        DEVICE_LIST_API_FAILURE: "Geräteauflistung fehlgeschlagen",
        ACKNOWLEDGE_LOG_SUCCESS: "Gerätedetails erfolgreich quittiert",
        TOAST_ERROR_BUILDING_UPDATE: "Gebäudeaktualisierung fehlgeschlagen",
        UPDATE_LOG_COMMENTS_SUCCESS: "Speichern des Kommentars erfolgreich",
        ACKNOWLEDGE_LOG_API_FAILURE: "Bestätigung fehlgeschlagen",
        TOAST_SUCCESS_ORG_CREATE: "Organisation erfolgreich erstellt",
        TOAST_ERROR_ORG_UPDATE: "Organisationsaktualisierung fehlgeschlagen",
        TOAST_SUCCESS_ORG_UPDATE: "Organisation erfolgreich aktualisiert",
        TOAST_ERROR_MESSAGE: "Bitte alle Angaben ordnungsgemäß eingeben",
        UPDATE_LOG_COMMENTS_API_FAILURE: "Speichern des Kommentars fehlgeschlagen",
        TOAST_ERROR_UPDATE_USER_DETAILS: "Aktualisierung der Benutzerdetails fehlgeschlagen",
        TOAST_SUCCESS_UPDATE_USER_DETAILS: "Benutzer erfolgreich aktualisiert",
  },
      CHANGE_PASSWORD_SCREEN: {
        CHANGE_PASSWORD: "Passwort ändern",
        CONFIRM_PASSWORD: "Passwort bestätigen",
        NEW_PASSWORD: "Neues Passwort ",
  },
      MESSAGES: {
        CONGRATS: "Herzlichen Glückwunsch!",
        CONTACT: "Kontaktieren Sie einen Eaton-Supportmitarbeiter",
        EMAIL_SENT: "E-Mail gesendet",
        ERROR: "Störung!",
        FAILURE: "Störung",
        LOADING: "Wird geladen...",
        EMAIL_ENTRY_ERROR: "Bitte gültige Email-Adresse eingeben",
        SUCCESS: "Erfolg",
        WELCOME: "Willkommen!",
        LOGIN_MESSAGE: "Sie sind eingeloggt",
  },
      LANDING_SCREEN: {
        CONNECT_NOW: "Jetzt verbinden",
        CONNECT_TO_SYSTEM: "Mit Systemen verbinden",
        CONTINUE_TO_DASHBOARD: "Weiter zum Dashboard",
        DASHBOARD_UNAVAILABLE: "Dashboard nicht verfügbar",
        NO_INTERNET_CONNECTION: "Keine Internetverbindung",
        VIEW_DASHBOARD: "Dashboard anzeigen",
        IN_PROGRESS: "In Arbeit...",
  },
      USERDETAILS: {
        RESET_PASSWORD_ERROR: "Ihr Passwort konnte derzeit nicht zurückgesetzt werden.",
        RESET_PASSWORD_SUCCESS: "E-Mail zum Zurücksetzen des Passworts erfolgreich gesendet.",
        EDIT_SUCCESS: "Benutzer erfolgreich aktualisiert",
  },
      LABELS: {
        CSV: "CSV",
        EMAIL: "E-Mail-Adresse",
        EXCEL: "Excel-Datei",
        FORGOT_PASSWORD: "Passwort vergessen?",
        GROUP_BY: "Gruppiere nach:",
        ITEMS_PER_PAGE: "Objekte pro Seite:",
        LOGGING_IN: "Einloggen",
        NEED_ACCOUNT: "Benötigen Sie ein Konto?",
        NEW_PASSWORD: "Neues Passwort",
        PASSWORD: "Passwort ",
        PDF: "PDF",
        SHOW: "Anzeigen:",
        SIGN_UP: "Registrieren",
        TIME_RANGE: "Zeitspanne:",
        TO_BE_IMPLEMENTED: "Ausstehend",
        VIEW_ALL_EVENTS: "Alle {{count}} Ereignisse anzeigen",
  },
      TIMELINE_EXPORT: {
        DOWNLOAD_TITLE: "Zeitleisten-Daten herunterladen",
        DOWNLOAD_SUBTITLE_SITE: "Wählen Sie den Datumsbereich des Alarmverlaufs, den Sie exportieren möchten.",
        DOWNLOAD_SUBTITLE_TIMELINE: "Wählen Sie den Ort und den Datumsbereich des Alarmverlaufs, den Sie exportieren möchten.",
  },
      LEGAL: {
        EULA: "Endbenutzer-Lizenzvereinbarung",
        TITLE: "Rechtlich",
        OPENSOURCELICENSES: "Open-Source-Lizenzen",
        TERMSANDCONDITIONS: "Geschäftsbedingungen",
  },
      EVENT_TIMELINE: {
        ERROR_LOADING_MORE_EVENTS: "Fehler beim Laden weiterer Ereignisse",
        LOAD_MORE_EVENTS: "{{count}} weitere Ereignisse laden",
  },
      ADDLOCATION: {
        ASTERIKSINFO: "Mit einem Sternchen (*) gekennzeichnete Felder sind Pflichtfelder {{entity}}.",
        ADDLOCATIONINFORMATION: "Informationen zum Standortprofil",
  },
      HEADER: {
        FORGOT_PASSWORD: "Passwort vergessen",
  },
      LOGIN: {
        INVALID_CREDENTIALS: "Benutzername oder Passwort ist falsch",
        GENERIC_ERROR: "Ihre Anfrage konnte derzeit nicht bearbeitet werden.",
  },
      NAVIGATION_TABS: {
        LOGBOOK: "PRÜFBUCH",
        STATUS: "STATUS",
  },
      ASSETS_SCREEN: {
        NOBUILDINGFOUND: "Keine Gebäude gefunden",
        NOGATEWAYFOUND: "Keine Gateways gefunden",
        NOORGANIZATIONFOUND: "Keine Organisation gefunden",
        NOREPORTFOUND: "Keine Berichte gefunden",
        NOSYSTEMS: "Keine Systeme gefunden",
        OTHERS: "weitere",
        STARRED: "FAVORITEN",
  },
      EULA: {
        OTHER_LANGUAGE: "EULA für andere Sprachen",
  },
      LOCATIONS: {
        SITES: "Seiten",
  },
      COUNTER: "Der Wert ist: {{count}}",
      RESPONSE: {
        USER_REGISTERED: "Benutzer können registriert werden",
  },
      PASSWORD_RESET: {
        SUCCESS_MESSAGE: "Passwort erfolgreich geändert.",
        FAILURE_MESSAGE: "Ihr Passwort konnte nicht zurückgesetzt werden. Bitte versuchen Sie es später noch einmal.",
  },
  },
  };
   export default resources;
