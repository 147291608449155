import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { State } from '../state';

import { AuthenticationAction, AuthenticationActions } from './actions/authentication-actions';
import { DashboardAction, DashboardActions } from './actions/dashboard-actions';
import { LegalAction, LegalActions } from './actions/legal-actions';
import { NavigationAction, NavigationActions } from './actions/navigation-actions';
import { RegistrationAction, RegistrationActions } from './actions/registration-actions';
import { UserSettingsAction, UserSettingsActions } from './actions/user-settings-actions';
import { SignalRConnectionAction, SignalRConnectionActions } from './actions/signalr-connection-actions';

import { LogbookAction, LogbookActions } from './actions/logbook-actions';
import { AssetAction, AssetActions } from './actions/asset-actions';
import { OrganizationAction, OrganizationActions } from './actions/organization-actions';
import { UsersAction, UsersActions } from './actions/users-actions';
import { ReportAction, ReportActions } from './actions/report-action';
import { CustomerAction, CustomerActions } from './actions/customer-actions';
import { UsermanagementAction, usermanagementActions } from './actions/usermanagement-actions';

export const Actions = {
    Authentication: AuthenticationActions,
    Dashboard: DashboardActions,
    Organization: OrganizationActions,
    Legal: LegalActions,
    Navigation: NavigationActions,
    Registration: RegistrationActions,
    UserSettings: UserSettingsActions,
    Logbook: LogbookActions,
    Asset: AssetActions,
    Users: UsersActions,
    SignalRConnection: SignalRConnectionActions,
    Report: ReportActions,
    Customer: CustomerActions,
    UserManagement: usermanagementActions
    
};

// eslint-disable-next-line
export type Actions =
    | AuthenticationAction
    | DashboardAction
    | OrganizationAction
    | LegalAction
    | NavigationAction
    | RegistrationAction
    | UserSettingsAction
    | SignalRConnectionAction
    | LogbookAction
    | AssetAction
    | UsersAction
    | ReportAction
    | CustomerAction
    | UsermanagementAction
 
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, State, null, Action<Actions['type']>>;
