// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import {
    Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import PushNotificationSolidIcon from '@brightlayer-ui/icons-mui/PushNotificationSolid';
import LockIcon from '@mui/icons-material/Lock';
import { ChangePasswordDialog } from '../../components/ChangePasswordDialog/ChangePasswordDialog';
import { GenericToolbar } from '../../components/GenericToolbar';
import { getUserEmail, getUserId, useAuthState } from '../../components/AuthContextProvider';
import { ConfirmationPopUp } from '../../components/ConfirmationPopUp/ConfirmationPopUp';
import * as PXBColors from '@brightlayer-ui/colors';
import { NavigationPromptModal } from '../../components/NavigationPromptModal/NavigationPromptModal';
import { UserDetails } from '../../components/UserDetails/UserDetails';
import { useAppRoute } from '../../hooks/app-route-hooks';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';


export const Settings: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = (props): JSX.Element => {
    const { t } = useLanguageLocale();
    const authState = useAuthState();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const history = useHistory();
    const [showDialog, setShowDialog] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [confirmDiscard, setConfirmDiscard] = useState(false);
    const userId = getUserId(authState) || '';
    const emailId = getUserEmail(authState) || '';
    const [openChangePasswordDialog, setOpenChangePasswordDialog] = React.useState(useAppRoute('Settings').changeId === '1');

    if (openChangePasswordDialog) {
        setOpenChangePasswordDialog(false);
        setShowDialog(true);
    }

    const closePopUp = (): void => {
        setShowDialog(false);
        setShowConfirmation(false);
        setShowDisclaimer(false);
    };
    const openPasswordDialog = (): void => setShowDialog(true);
    const openSmsDialog = (value: boolean): void => setShowConfirmation(value);
    const openDisclaimer = (): void => setShowDisclaimer(true);


    const hasDirty = (value: boolean): void => {
        setIsDirty(value);
        setConfirmDiscard(false);
    };

    useEffect(() => {
        if (history.location.pathname.includes("/1")) {
            setShowDialog(true);
            pushAppRoute(history, { type: 'Settings', changeId: '0' });
        }
    }, [useAppRoute('Settings').changeId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <GenericToolbar title={t('SETTINGS.TITLE')} />
            <div>
                <div data-testid="success-body" style={{ marginLeft: md ? '10%' : '2%', marginRight: md ? '10%' : '2%' }}>
                    <>
                        <UserDetails
                            emailId={emailId}
                            userId={userId}
                            openPassword={openPasswordDialog}
                            openSMSModal={openSmsDialog}
                            openDisclaimer={openDisclaimer}
                            hasDirty={hasDirty}
                            confirmDiscard={confirmDiscard}
                        ></UserDetails>
                    </>

                </div>
            </div>
            {showDialog && <ChangePasswordDialog show={showDialog} closeDialog={closePopUp}></ChangePasswordDialog>}
            {showConfirmation && (
                <ConfirmationPopUp
                    icon={<PushNotificationSolidIcon
                        sx={{
                            width: 60,
                            height: 60,
                            color: PXBColors.gray[500],
                        }}
                    />}
                    title={t('USER_SETTINGS.TEXT_NOTIFICATION_TITLE')}
                    body={<Typography> {t('USER_SETTINGS.TEXT_NOTIFICATION_BODY')}</Typography>}
                    okButtonText={t('ACTIONS.ENABLE')}
                    cancelButtonText={t('ACTIONS.CANCEL')}
                    show={showConfirmation}
                    closeDialog={closePopUp}
                    width="xs"
                    okButtonShow={true}
                    cancelButtonsShow={true}
                ></ConfirmationPopUp>
            )}
            {showDisclaimer && (
                <ConfirmationPopUp
                    icon={<LockIcon
                        sx={{
                            width: 60,
                            height: 60,
                            color: PXBColors.gray[500],
                        }}
                    />}
                    title={t('USER_SETTINGS.AUTOMATIC_LOGOUT_TITLE')}
                    body={
                        <>
                            <Typography variant="subtitle2" style={{ marginBottom: 30 }}>
                                {t('USER_SETTINGS.AUTOMATIC_LOGOUT_SUBTITLE')}
                            </Typography>

                            <Typography variant="subtitle2" component="p" style={{ marginBottom: 40 }}>
                                <b>{t('USER_SETTINGS.WARNING')}</b>: {t('USER_SETTINGS.AUTOMATIC_LOGOUT_BODY')}
                            </Typography>
                        </>
                    }
                    okButtonText={t('ACTIONS.DISABLE')}
                    cancelButtonText={t('ACTIONS.CANCEL')}
                    show={showDisclaimer}
                    closeDialog={closePopUp}
                    handleOk={(): void => {
                    }}
                    width="xs"
                    okButtonShow={true}
                    cancelButtonsShow={true}
                ></ConfirmationPopUp>
            )}
            <NavigationPromptModal isDirty={isDirty} />
        </>
    );
};
export default Settings;

