import { ActionType, SiteRole, UserDetailsData } from '../../types';

export const UserSettingsActions = {

    getUserDetailStarted: () =>
    ({
        type: 'User/userDetail/Started',
    } as const),

    getUserDetailSucceeded: (userDetail: UserDetailsData) =>
    ({
        type: 'User/userDetail/Succeeded',
        payload: { userDetail },
    } as const),

    getUserDetailFailed: (errorCode: string) =>
    ({
        type: 'User/userDetail/Failed',
        payload: { errorCode },
    } as const),

    getUserDetailUnmounted: () =>
    ({
        type: 'User/userDetail/Unmounted',
    } as const),

    updateUserDetailStarted: () =>
    ({
        type: 'User/updateUserDetail/Started',
    } as const),

    updateUserDetailSucceeded: (message: string) =>
    ({
        type: 'User/updateUserDetail/Succeeded',
        payload: { message },
    } as const),

    updateUserDetailFailed: (errorCode: string) =>
    ({
        type: 'User/updateUserDetail/Failed',
        payload: { errorCode },
    } as const),
    updateUserDetailUnmounted: () =>
    ({
        type: 'User/updateUserDetail/Unmounted',
    } as const),

    changePasswordStarted: () =>
    ({
        type: 'UserSettings/ChangePassword/Started',
    } as const),

    changePasswordSucceeded: () =>
    ({
        type: 'UserSettings/ChangePassword/Succeeded',
    } as const),

    changePasswordFailed: () =>
    ({
        type: 'UserSettings/ChangePassword/Failed',
    } as const),

    resetChangePasswordSettings: () =>
    ({
        type: 'UserSettings/ChangePassword/Reset',
    } as const),

    updateEmailUserDataStarted: () =>
    ({
        type: 'UserSettings/email/Update/Started',
    } as const),

    updateSmsUserDataStarted: () =>
    ({
        type: 'UserSettings/sms/Update/Started',
    } as const),

    updateSessionUserDataStarted: () =>
    ({
        type: 'UserSettings/session/Update/Started',
    } as const),

    updateUserDataSucceeded: (notify: boolean, sms: boolean, session: boolean) =>
    ({
        type: 'UserSettings/Update/Succeeded',
        payload: { notify, sms, session },
    } as const),

    updateUserDetailsStarted: () =>
    ({
        type: 'UserSettings/userDetails/Update/Started',
    } as const),

    updateUserDetailsSucceeded: (fName: string, lName: string, phone: string, email: string) =>
    ({
        type: 'UserSettings/userDetails/Succeeded',
        payload: { fName, lName, phone, email },
    } as const),

    updateUserDataFailed: () =>
    ({
        type: 'UserSettings/Update/Failed',
    } as const),

    userSettingsProviderUnmounted: () =>
    ({
        type: 'UserSettings/Unmounted',
    } as const),

    reset: () =>
    ({
        type: 'UserSettings/Reset',
    } as const),

    updateUserRoleStarted: () =>
    ({
        type: 'UserSettings/UserRole/Update/Started',
    } as const),

    updateUserRoleSucceeded: (updatedRoleSite: SiteRole[]) =>
    ({
        type: 'UserSettings/UserRole/Update/Succeeded',
        payload: { updatedRoleSite },
    } as const),

    updateUserRoleFailed: () =>
    ({
        type: 'UserSettings/UserRole/Update/Failed',
    } as const),
    updateUserRoleUnmounted: () =>
    ({
        type: 'UserSettings/UserRole/Update/Unmounted',
    } as const),

    deleteUserStarted: () =>
    ({
        type: 'UserSettings/deleteUser/Started',
    } as const),

    deleteUserSucceeded: () =>
    ({
        type: 'UserSettings/deleteUser/Succeeded',
    } as const),

    deleteUserFailed: (errorCode: string) =>
    ({
        type: 'UserSettings/deleteUser/Failed',
        payload: { errorCode },
    } as const),

    deleteUserUnmounted: () =>
    ({
        type: 'UserSettings/deleteUser/Unmounted',
    } as const),

};
// eslint-disable-next-line
export type UserSettingsAction = ActionType<typeof UserSettingsActions>;
