import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { INVITE_USER } from './url-constants';

type InviteUserRequestEndpointSpecifications = ApiEndpointSpecification;
export const InviteUserRequestEndpointSpecification: InviteUserRequestEndpointSpecifications = {
    url: INVITE_USER,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'InviteUserRequestParams',
    requestBodySchemaName: 'InviteUserRequestBody',
    okResponseSchemaName: 'InviteUserRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeInviteUserRequest = Helpers.makeApiRequestFn<
    Types.InviteUserRequestParams,
    Types.InviteUserRequestBody,
    Types.InviteUserRequestOkResponse
>(InviteUserRequestEndpointSpecification);
