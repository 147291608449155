import * as ReactRouter from 'react-router';
import * as _ from 'lodash';

export type AppRoute =
    | {
        type: 'InvalidRoute';
    }
    | {
        type: 'Dashboard';
    }
    | {
        type: 'OverviewBuildingDetails';
    }
    | {
        type: 'OverviewOrganization';
    }
    | {
        type: 'OverviewLocation';
        locationId: string;
    }
    | {
        type: 'OverviewLocationDevices';
        locationId: string;
    }
    | {
        type: 'OverviewSite';
        locationId: string;
        siteId: string;
    }
    | {
        type: 'OverviewSiteDeviceDetails';
        locationId: string;
        siteId: string;
        deviceId: string;
    }
    | {
        type: 'OverviewSiteReadings';
        locationId: string;
        siteId: string;
    }
    | {
        type: 'OverviewSiteTrends';
        locationId: string;
        siteId: string;
    }
    | {
        type: 'OverviewSiteDevices';
        locationId: string;
        siteId: string;
    }
    | {
        type: 'OverviewSiteDevicesDeviceDetails';
        locationId: string;
        siteId: string;
        deviceId: string;
    }
    | { type: 'OverviewSiteTimeline'; locationId: string; siteId: string }
    | { type: 'OverviewSiteEventDetails'; locationId: string; siteId: string; alarmId: string; deviceId: string }
    | {
        type: 'OverviewSiteTimelineEventDetails';
        locationId: string;
        siteId: string;
        alarmId: string;
        deviceId: string;
    }
    | {
        type: 'Devices';
    }
    | {
        type: 'DevicesOrganization';
    }
    | {
        type: 'DevicesOrganizationDeviceDetails';
        deviceId: string;
    }
    | {
        type: 'DevicesLocation';
        locationId: string;
    }
    | {
        type: 'DevicesLocationDeviceDetails';
        locationId: string;
        deviceId: string;
    }
    | {
        type: 'Assets';
    }
    | {
        type: 'AssetDetails';
        deviceId: string;
        gatewayId: string;
        projectId?: string;
        buildingId?: string;
    }
    | {
        type: 'ManageUsers';
        orgId?: string;
    }
    |
    {
        type: 'CreateServicePartner';
        actionType: number;
    }
    | {
        type: 'ManageAssetUsers';
        assetId: string;
    }
    | {
        type: 'Organizations';
    }
    | {
        type: 'CustomerDetails';
    }
    | {
        type: 'ServicePartnerDetails';
    }
    | {
        type: 'PassDetails';
    }
    | {
        type: 'AddCustomerDetails';
        actionType: number;
    }
    | {
        type: 'ModifyUserDetails';
    }
    | {
        type: 'OrganizationDetails';
        orgId: string;
    }
    | {
        type: 'OrgBuildingDetails';
        buildingId: string;
    }
    | {
        type: 'CreateOrganization';
    }
    | {
        type: 'UpsertBuilding';
        orgId: string;
    }
    | {
        type: 'EditOrganization';
        orgId: string;
    }
    | {
        type: 'EditBuilding';
        orgId: string;
        buildingId: string;
    }
    | {
        type: 'InvitationHistory';
    }
    | {
        type: 'Reports';
    }
    | {
        type: 'ReportsDetails'; entityId: string; entityType: number;
    }
    | {
        type: 'Logbook';
    }
    | {
        type: 'LogbookDetails'; logId: string;
    }
    | {
        type: 'TimelineOrganization';
    }
    | {
        type: 'TimelineOrganizationEventDetails';
        alarmId: string;
        deviceId: string;
    }
    | {
        type: 'TimelineLocation';
        locationId: string;
    }
    | {
        type: 'TimelineLocationEventDetails';
        locationId: string;
        alarmId: string;
        deviceId: string;
    }
    | { type: 'Settings'; changeId: string }
    | { type: 'MyProfile' }
    | { type: 'ManageUser'; orgId: string }
    | { type: 'AddUser'; orgId: string }
    | { type: 'User'; userId: string; orgId: string }
    | { type: 'ManageLocation'; orgId: string }
    | { type: 'AddLocation'; orgId: string }
    | { type: 'LocationProfile'; locationId: string; orgId: string }
    | { type: 'EditLocation'; orgId: string; locationId: string }
    | { type: 'AddSite'; orgId: string; locationId: string }
    | { type: 'SiteProfile'; orgId: string; locationId: string; siteId: string }
    | { type: 'EditSite'; orgId: string; locationId: string; siteId: string }
    | { type: 'AddDevice'; orgId: string; locationId: string; siteId: string }
    | { type: 'DeviceProfile'; orgId: string; locationId: string; siteId: string; deviceId: string }
    | { type: 'EditConnectedDevice'; orgId: string; locationId: string; siteId: string; deviceId: string }
    | { type: 'AddChildDevice'; orgId: string; locationId: string; siteId: string; deviceId: string }
    | {
        type: 'ChildDeviceProfile';
        orgId: string;
        locationId: string;
        siteId: string;
        deviceId: string;
        childDeviceId: string;
    }
    | {
        type: 'EditDeviceProfile';
        orgId: string;
        locationId: string;
        siteId: string;
        deviceId: string;
        childDeviceId: string;
    }
    | { type: 'Legal' }
    | { type: 'EULA' }
    | { type: 'TermsAndConditions' }
    | { type: 'OpenSource' }
    | {
        type: 'OpenSourceContent';
        name: string;
    }
    | { type: 'ForgotPassword' }
    | { type: 'UserInvitation'; invitationId: string }
    | { type: 'Registration'; inviteUserType: string, inviteUserEmail: string }
    | { type: 'Root' }
    | { type: 'Login' };

// eslint-disable-next-line
export const AppRoutes: { [k in AppRoute['type']]: string } = {
    Dashboard: '/dashboard',
    OverviewBuildingDetails: '/dashboard/buildingdetails',
    OverviewOrganization: '/dashboard',

    OverviewLocation: '/dashboard/locations/:locationId',
    OverviewLocationDevices: '/dashboard/locations/:locationId/devices',

    OverviewSite: '/dashboard/locations/:locationId/sites/:siteId',
    OverviewSiteReadings: '/dashboard/locations/:locationId/sites/:siteId/readings',
    OverviewSiteTrends: '/dashboard/locations/:locationId/sites/:siteId/trends',
    OverviewSiteDevices: '/dashboard/locations/:locationId/sites/:siteId/all-devices',
    OverviewSiteDeviceDetails: '/dashboard/locations/:locationId/sites/:siteId/devices/:deviceId',
    OverviewSiteDevicesDeviceDetails: '/dashboard/locations/:locationId/sites/:siteId/all-devices/:deviceId',

    OverviewSiteTimeline: '/dashboard/locations/:locationId/sites/:siteId/timeline',
    OverviewSiteEventDetails: '/dashboard/locations/:locationId/sites/:siteId/events/:alarmId/devices/:deviceId',
    OverviewSiteTimelineEventDetails:
        '/dashboard/locations/:locationId/sites/:siteId/timeline/events/:alarmId/devices/:deviceId',

    Devices: '/devices',
    DevicesOrganization: '/devices',
    DevicesOrganizationDeviceDetails: '/devices/:deviceId',
    DevicesLocation: '/devices/locations/:locationId',
    DevicesLocationDeviceDetails: '/devices/locations/:locationId/devices/:deviceId',
    Logbook: '/logbook',
    LogbookDetails: '/logbook/logbookDetails/:logId',
    TimelineOrganization: '/timelines',
    TimelineOrganizationEventDetails: '/timelines/events/:alarmId/devices/:deviceId',
    TimelineLocation: '/timelines/locations/:locationId',
    TimelineLocationEventDetails: '/timelines/locations/:locationId/events/:alarmId/devices/:deviceId',

    Assets: '/assets',
    CustomerDetails: '/assets/customerDetails',
    AddCustomerDetails: '/assets/customerDetails/AddCustomerDetails/:actionType',
    AssetDetails: '/assets/assetDetails/:deviceId/:gatewayId/:projectId',

    ManageUsers: '/users',
    CreateServicePartner: '/users/createServicePartner/:actionType',
    ServicePartnerDetails: '/users/servicePartnerDetails',
    PassDetails: '/users/passDetails',
    ManageAssetUsers: '/users/manageAssetUsers/:assetId',

    Organizations: '/organizations',
    OrganizationDetails: '/organizations/orgDetails/:orgId',
    OrgBuildingDetails: '/organizations/orgDetails/orgBuildingDetails/:buildingId',
    CreateOrganization: '/organizations/createOrganization',
    UpsertBuilding: '/organizations/createBuilding/:orgId',
    EditOrganization: '/organizations/editOrganization/:orgId',
    EditBuilding: '/organizations/orgDetails/editBuilding/:orgId/:buildingId',
    InvitationHistory: '/organizations/invitationHistory',

    Reports: '/reports',
    ReportsDetails: '/reports/reportsDetails/:entityId/:entityType',

    Settings: '/settings/:changeId',
    ModifyUserDetails: '/settings/:changeId/modifyUserDetails',
    MyProfile: '/settings/myProfile',
    ManageUser: '/manage-user/org/:orgId',
    AddUser: '/manage-user/org/:orgId/add-user',
    User: '/manage-user/org/:orgId/user/:userId',
    ManageLocation: '/manage-location/org/:orgId',
    AddLocation: '/manage-location/org/:orgId/add-location',
    LocationProfile: '/manage-location/org/:orgId/location/:locationId',
    EditLocation: '/manage-location/org/:orgId/location/:locationId/edit-location',
    AddSite: '/manage-location/org/:orgId/location/:locationId/add-site',
    SiteProfile: '/manage-location/org/:orgId/location/:locationId/site/:siteId',
    EditSite: '/manage-location/org/:orgId/location/:locationId/site/:siteId/edit-site',
    AddDevice: '/manage-location/org/:orgId/location/:locationId/sites/:siteId/add-device',
    DeviceProfile: '/manage-location/org/:orgId/location/:locationId/sites/:siteId/device/:deviceId',
    EditConnectedDevice:
        '/manage-location/org/:orgId/location/:locationId/sites/:siteId/device/:deviceId/edit-connected-device',
    AddChildDevice: '/manage-location/org/:orgId/location/:locationId/sites/:siteId/device/:deviceId/add-child-device',
    ChildDeviceProfile:
        '/manage-location/org/:orgId/location/:locationId/sites/:siteId/device/:deviceId/childdevice/:childDeviceId',
    EditDeviceProfile:
        '/manage-location/org/:orgId/location/:locationId/sites/:siteId/device/:deviceId/childdevice/:childDeviceId/edit-device',
    Legal: '/legal',
    EULA: '/legal/eula',
    TermsAndConditions: '/legal/terms',
    OpenSource: '/legal/open-source',
    OpenSourceContent: '/legal/open-source/:name',

    ForgotPassword: '/forgot-password',
    UserInvitation: '/user-invitation/:invitationId',
    Login: '/login',
    Registration: '/register/:inviteUserType/:inviteUserEmail',
    Root: '/',

    InvalidRoute: '/invalid',
};

export const removeLocationId = (appRoute: AppRoute, orgId: string): AppRoute => {
    switch (appRoute.type) {
        case 'Dashboard':
        case 'OverviewOrganization':
        case 'OverviewLocation':
            return { type: 'Dashboard' };
        case 'Logbook':
            return { type: 'Logbook' };
        case 'TimelineOrganization':
        case 'TimelineLocation':
            return { type: 'Logbook' };
        case 'Devices':
        case 'DevicesOrganization':
        case 'DevicesLocation':
            return { type: 'Devices' };
        case 'Settings':
            return { type: 'Settings', changeId: '0' };
        case 'MyProfile':
            return { type: 'MyProfile' };
        case 'ManageUser':
            return { type: 'ManageUser', orgId: orgId };
        case 'ManageLocation':
            return { type: 'ManageLocation', orgId: orgId };
        case 'Legal':
            return { type: 'Legal' };
        default:
            return { type: 'InvalidRoute' };
    }
};

export const addLocationId = (appRoute: AppRoute, locationId: string): AppRoute => {
    switch (appRoute.type) {
        case 'Dashboard':
        case 'OverviewOrganization':
            return { type: 'OverviewLocation', locationId };
        case 'Logbook':
        case 'TimelineOrganization':
            return { type: 'TimelineLocation', locationId };
        case 'Devices':
        case 'DevicesOrganization':
            return { type: 'DevicesLocation', locationId };
        default:
            return appRoute;
    }
};

const routeByUrl = _.invert(AppRoutes);

export const determineCurrentAppRoute = (pathName: string): AppRoute => {
    for (const url of Object.keys(routeByUrl)) {
        const match = ReactRouter.matchPath(pathName, url);
        if (match && match.isExact) {
            const routeName = routeByUrl[url];

            const currentAppRoute = {
                type: routeName,
                ...match.params,
            };

            // TypeScript is upset if I try to use a string as the type above.
            // 'as'-ing it here instead of declaring currentAppRoute to be
            // an AppRoute up-front seems to work. :-)
            return currentAppRoute as AppRoute;
        }
    }

    return {
        type: 'InvalidRoute',
    };
};

export const getAppRouteCacheName = (pathName: string): string => {
    const currentAppRoute = determineCurrentAppRoute(pathName);

    return currentAppRoute.type;
};
