import { AuthenticationThunks } from './thunks/authentication-thunks';
import { DashboardThunks } from './thunks/dashboard-thunks';
import { LegalThunks } from './thunks/legal-thunks';
import { NavigationThunks } from './thunks/navigation-thunks';
import { RegistrationThunks } from './thunks/registration-thunks';
import { UserSettingsThunks } from './thunks/user-settings-thunks';
import { SignalRConnectionThunks } from './thunks/signalr-connection-thunks';
import { LogBookThunks } from './thunks/logbook-thunks';
import { AssetThunks } from './thunks/assets-thunks';
import { OrganizationThunks } from './thunks/organization-thunks';
import { UsersThunks } from './thunks/users-thunks';
import { ReportThunks } from './thunks/report-thunks';
import { CustomerThunks } from './thunks/customer-thunks';
import { UsermanagementThunks } from './thunks/user-management-thunks';

export const Thunks = {
    Authentication: AuthenticationThunks,
    Dashboard: DashboardThunks,
    Organization: OrganizationThunks,
    Legal: LegalThunks,
    Navigation: NavigationThunks,
    Registration: RegistrationThunks,
    UserSettings: UserSettingsThunks,
    Logbook: LogBookThunks,
    Asset: AssetThunks,
    Users: UsersThunks,
    SignalRConnection: SignalRConnectionThunks,
    Report: ReportThunks,
    Customer: CustomerThunks,
    UserManagement: UsermanagementThunks

};
