// eslint-disable-next-line
import React, { } from 'react';
import {
    Typography,
    Card,
    ListSubheader,
    Divider,
} from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { OrgDetailListItem } from '../../components/OverviewList/OrgDetailListItem';
import { Email, ManageAccounts, Notes, Person, Phone, RemoveCircle } from '@mui/icons-material';
import { COLOR_GRAY_ICON } from '../../constants/color-codes';
import { DeviceCustomerContactData } from '../../types/customer-detail';
import { CUSTOMER_ROLE_TYPE, contactTypeSelectionList, getcontactUpdateList } from '../enum/ENUM';

type Props = {
    header: string
    contactPersonHeader: string
    customerInfo: DeviceCustomerContactData;
};

export const CustomerContactInfo: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();

    const contactType: contactTypeSelectionList[0] = getcontactUpdateList(props.customerInfo.ContactType);
    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }} id="customer-contact-details" data-testid="customer-contact-details">

                <ListSubheader
                    color="primary"
                    component="div"
                    data-testid="contactDetails-header"
                    id="contactDetails-header"
                    style={{
                        display: 'flex',
                        fontWeight: 600,
                        fontSize: 14,
                        alignItems: 'center',
                        overflow: 'auto',
                        whiteSpace: 'nowrap',
                        marginLeft: 10,
                        height: 50,
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        style={{
                            alignItems: 'left',
                            color: PXBColors.blue[400],
                            width: '92.3%',
                            paddingLeft: '10',
                        }}
                        id="titlePrimaryContact"
                        data-testid="titlePrimaryContact"
                    >
                        {props.header}
                    </Typography>

                </ListSubheader>
                <Divider />
                <OrgDetailListItem
                    title1={props.customerInfo.firstName + ' ' + props.customerInfo.lastName}
                    title2={''}
                    title3={''}
                    subtitle={t(contactType.value)}
                    subtitle2={''}
                    icon={props.customerInfo.ContactType == CUSTOMER_ROLE_TYPE.SERVICE_MANAGER?<ManageAccounts style={{ color: COLOR_GRAY_ICON }}/>:<Person style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlignment={'center'}
                    displayAction={false}
                    dividerDisable={true}
                />
                <Divider></Divider>
                <OrgDetailListItem
                    title1={(props.customerInfo.countryCode?'+':'') + (props.customerInfo.countryCode ? props.customerInfo.countryCode : '') + ' ' + (props.customerInfo.phone ? props.customerInfo.phone : '')}
                    title2={''}
                    title3={''}
                    subtitle={t('DASHBOARD.BUILDING_DETAIL_PHONE')}
                    subtitle2={''}
                    icon={<Phone style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlignment={'center'}
                    displayAction={false}
                    dividerDisable={true}
                />
                <Divider></Divider>
                <OrgDetailListItem
                    title1={(props.customerInfo.countryCode2?'+':'') + (props.customerInfo.countryCode2 ? props.customerInfo.countryCode2 : '') + ' ' + (props.customerInfo.phone2 ? props.customerInfo.phone2 : '')}
                    title2={''}
                    title3={''}
                    subtitle={t('CUSTOMER_DETAILS.BACK_UP_PHONE')}
                    subtitle2={''}
                    icon={<Phone style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlignment={'center'}
                    displayAction={false}
                    dividerDisable={true}
                />
                <Divider></Divider>
                <OrgDetailListItem
                    title1={props.customerInfo.Email}
                    title2={''}
                    title3={''}
                    subtitle={t('DASHBOARD.BUILDING_DETAIL_EMAIL')}
                    subtitle2={''}
                    icon={<Email style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlignment={'center'}
                    displayAction={false}
                    dividerDisable={true}
                />
                <Divider></Divider>
                <OrgDetailListItem
                    title1={t('LOGBOOK_DETAILS.COMMENTS')}
                    title2={''}
                    title3={''}
                    subtitle={props.customerInfo.Comments}
                    subtitle2={''}
                    icon={<Notes style={{ color: COLOR_GRAY_ICON }} />}
                    iconAlignment={'center'}
                    displayAction={false}
                    dividerDisable={true}
                />
                <Divider></Divider>
            </Card >
        </>
    );
};
