// eslint-disable-next-line
import React from 'react';
import { Page, Document, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Paper } from '@mui/material';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFRender: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ file: string; pageCount: number; width: number }>>> = (props) => (
    <div>
        <Document externalLinkTarget="_blank" file={props.file}>
            {/* eslint-disable-next-line prefer-spread  */}
            {Array.apply(null, Array(props.pageCount))
                .map((x, i) => i + 1)
                .map((page) => (
                    <Paper key={page} variant="elevation" style={{ marginBottom: 20 }} elevation={3}>
                        <Page key={page} width={props.width} pageNumber={page} />
                    </Paper>
                ))}
        </Document>
    </div>
);
