import React from 'react';
import { subSeconds } from 'date-fns';
import { SessionStorage } from './session-storage';
import { isUnauthorized } from './helpers';
import { RefreshTokenFn } from './types';
import { Clock } from '../../lib/clock';

const useRefreshToken = (args: {
    email: string | undefined;
    tokenExpirationDate: Date | null;
    setTokenExpirationDate: (date: Date | null) => void;
    onUserNotAuthenticated: () => void;
    refreshTokenFn: RefreshTokenFn;
    numberOfSecondsBeforeExpirationToRefreshToken: number | undefined;
    checkTokenExpirationFrequencyMs: number | undefined;
}): void => {
    const {
        email,
        tokenExpirationDate,
        numberOfSecondsBeforeExpirationToRefreshToken,
        checkTokenExpirationFrequencyMs,
        refreshTokenFn,
        setTokenExpirationDate,
        onUserNotAuthenticated,
    } = args;

    React.useEffect(() => {
        if (!tokenExpirationDate || !email) {
            return;
        }

        // Write to local storage so it will be there in case of page refresh
        SessionStorage.saveTokenExpiration(tokenExpirationDate);

        let timer: ReturnType<typeof setTimeout> | undefined;

        const attemptRefresh = async (): Promise<void> => {
            const isTokenRefreshable =
                Clock.now() >= subSeconds(tokenExpirationDate, numberOfSecondsBeforeExpirationToRefreshToken || 0);

            if (isTokenRefreshable) {
                const refreshResult = await refreshTokenFn({ user: email || '' }, { authHelper: undefined });
                if (refreshResult.ok) {
                    setTokenExpirationDate(new Date(refreshResult.response.data.expiration_date));
                } else if (isUnauthorized(refreshResult)) {
                    onUserNotAuthenticated();
                }
            }
        };

        attemptRefresh(); // Not awaiting

        timer = setInterval(() => {
            attemptRefresh(); // Not awaiting
        }, checkTokenExpirationFrequencyMs || 1000 * 30); // Check every 30 seconds to see if it's time to refresh (or whatever interval was passed in)

        return (): void => {
            // This is the useEffect cleanup function. If there's a timer
            // clear it during cleanup.
            if (timer) {
                clearInterval(timer);
                timer = undefined; // This tells any in-flight refresh attempts to do nothing when they get back
            }
        };
    }, [
        email,
        onUserNotAuthenticated,
        tokenExpirationDate,
        numberOfSecondsBeforeExpirationToRefreshToken,
        checkTokenExpirationFrequencyMs,
        refreshTokenFn,
        setTokenExpirationDate,
    ]);
};

export { useRefreshToken };
