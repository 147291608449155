// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { ItemList } from '../../types/logbook-type';
import { TextField, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import { Search, Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { DropDownListComponent } from '../../components/DropDownList/DropDownListComponent';
import { EMPTY_STRING, ORGANIZATTION_NAME } from '../../constants';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Selectors } from '../../selectors';
import { DeviceAssetData, DeviceAssetList, DeviceList } from '../../types/device';
import { deviceTypeEnum } from '../enum/ENUM';
import * as PXBColors from '@brightlayer-ui/colors';
import { ReportsListItem } from '../../components/ReportsList/ReportsListItem';
import { EmptyState } from '@brightlayer-ui/react-components';
import { BuildingList } from '../../types/building';
import { Thunks } from '../../actions';

type Props = {
    onClick: Function;
    orgList: ItemList;
    defaultProject: string;
    defaultBuilding: string;
};

export const ReportsSystems: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const [emptyDeviceList, setEmptyDeviceList] = React.useState(false)

    const [searchText, setSearchText] = useState('');

    const [filterType, setFilteredType] = React.useState('');

    const [buildingId, setBuildingId] = React.useState(EMPTY_STRING);

    const [buildings, setBuildings] = useState<ItemList>([]);
    const [filterBuilding, setFilteredBuilding] = React.useState(props.defaultBuilding);

    const [projects] = useState<ItemList>(props.orgList);
    const [filterProject, setFilteredProject] = React.useState(props.defaultProject);

    const [selectedSystem, setSelectedSystem] = React.useState('');

    const asset = useSelector(Selectors.Dashboard.asset);

    const [deviceAssetList, setDeviceAssetList] = useState<DeviceAssetList>([]);
    const [deviceDataLoaded, setDeviceDataLoaded] = React.useState(false);

    const getDeviceAssetList = (list: DeviceList): DeviceAssetList => {
        return list.map((data, device) => {
            return (
                {
                    systemId: data.systemId,
                    systemName: data.systemName,
                    type: data.type,
                    firmwareVersion: data.firmwareVersion,
                    systemStatus: data.systemStatus,
                    gateWayId: data.gateWayId,
                    gateWayName: data.gateWayName,
                    projectId: data.projectId,
                    projectName: data.projectName,
                    buildingName: data.buildingName,
                    buildingId: data.buildingId,
                    luminaryCount: data.luminaryCount,
                    isFavourite: data.isFavourite ? 1 : 0
                }
            );
        })
    };

    useEffect(() => {
        dispatch(Thunks.Dashboard.getBuildingList(filterProject, EMPTY_STRING));
        dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, EMPTY_STRING, EMPTY_STRING));
        dispatch(Thunks.Asset.getDeviceList(filterProject, EMPTY_STRING, EMPTY_STRING, searchText, filterType, EMPTY_STRING));
        setBuildingId(filterProject);
    }, [filterProject]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(Thunks.Dashboard.getBuildingList(filterProject, filterBuilding));
        dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, filterBuilding, EMPTY_STRING));
        dispatch(Thunks.Asset.getDeviceList(filterProject, filterBuilding, EMPTY_STRING, searchText, filterType, EMPTY_STRING));
    }, [filterBuilding]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(Thunks.Asset.getDeviceList(filterProject, filterBuilding, EMPTY_STRING, searchText, filterType, EMPTY_STRING));
    }, [filterType]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            setBuildings(buildingListData(dashboard.buildingList.detail));
        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            setBuildings([]);
            setBuildingId(EMPTY_STRING);
        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.deviceListLoading && asset.deviceListSuccess) {
            setEmptyDeviceList(asset.deviceList.detail.length <= 0)
            const deviceDataList = getDeviceAssetList(asset.deviceList.detail);
            setDeviceAssetList(deviceDataList);
            setDeviceDataLoaded(true);
        } else if (!asset.deviceListLoading && asset.deviceListFail) {
            setDeviceAssetList([]);
            setEmptyDeviceList(true);
        }
    }, [asset.deviceListLoading, asset.deviceListSuccess, asset.deviceListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const buildingListData = (list: BuildingList): ItemList => {
        return list?.map((data, building) => {
            return {
                id: data.buildingId,
                name: data.name,
            };
        });
    };

    const handleDisplay = (
        systemId: string,
        systemName: string,
        systemType: string
    ): void => {
        setSelectedSystem(systemId);
        props.onClick(systemId, systemName, systemType);
    };

    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setSearchText(e.target.value);
    };

    const resetSearch = (): void => {
        setSearchText('');
    };

    useEffect(() => {
        if (deviceDataLoaded) {
            /* Debouncing to override user typing */
            const delayDebounceFn = setTimeout(() => {
                dispatch(Thunks.Asset.getDeviceList(filterProject, filterBuilding, EMPTY_STRING, searchText));
            }, 3000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="report-system-container" data-testid="report-system-container">
            <div data-testid="asset-system-search-panel" style={{ display: 'flex', marginBottom: 20 }}>

                <DropDownListComponent data-testid="asset-buildings-project-filter" list={projects} value={t('SEARCH.ALL_ORGANIZATIONS')} handleSelection={setFilteredProject} selected={''} defaultSelection={props.defaultProject}></DropDownListComponent>
                <DropDownListComponent data-testid="asset-buildings-project-filter" list={buildings} value={t('SEARCH.ALL_BUILDINGS')} handleSelection={setFilteredBuilding} selected={buildingId} defaultSelection={props.defaultBuilding}></DropDownListComponent>
                <DropDownListComponent data-testid="asset-buildings-project-filter" list={deviceTypeEnum} value={t('SEARCH.ALL_TYPES')} handleSelection={setFilteredType} selected={''} defaultSelection={''}></DropDownListComponent>

                <TextField
                    inputProps={{ 'data-testid': 'search', maxLength: 100 }}
                    value={searchText}
                    style={{
                        width: 'auto',
                        textAlign: 'right',
                        float: 'right',
                        border: '0px solid #727E84',
                        borderRadius: 5,
                        backgroundColor: 'white',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        overflow: 'hidden',
                        marginTop: 28,
                        fontFamily: 'Open sans',
                        fontSize: 14,
                        color: '#727E84',
                        fontStyle: 'normal',
                        fontWeight: 400,
                    }}
                    placeholder={t('ORGANIZATION_MANAGE.SEARCH_BUILDINGS')}
                    onChange={(e): void => onChangeSearchText(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search data-testid="search-icon" style={{ color: PXBColors.gray[500] }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchText !== '' && (
                                    <IconButton onClick={resetSearch}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>
            {!emptyDeviceList && !asset.deviceListLoading && (deviceAssetList).map((row: DeviceAssetData, index: number) => (
                <ReportsListItem
                    key={index}
                    selectedItem={selectedSystem}
                    displayClick={handleDisplay} systemItem={row}
                >
                </ReportsListItem>
            ))}
            {asset.deviceListLoading && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
            icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
            {!asset.deviceListLoading && emptyDeviceList && <EmptyState style={{ flex: 1, height: 200 }}
            title={t('ORGANIZATION_MANAGE.ERROR_NO_SYSTEM_FOUND')} icon={undefined} placeholder={undefined} />}
        </div>
    );
};
