import { CustomerDashboardList, CustomerListData, CustomerV2List } from "../types/customer-detail";

export const customerList: CustomerListData = {
    companyName: '',
    customerNumber: '',
    companyAddress1: '',
    companyAddress2: '',
    city: '',
    country: '',
    postalCode: '',
    companyCountryCode: '',
    companyPhone: '',
    comments: '',
    primaryCountryCode: '',
    primaryFirstName: '',
    primaryLastName: '',
    primaryPhone: '',
    primaryCountryCode2: '',
    primaryPhone2: '',
    primaryEmail: '',
    primaryContactType: 0,
    primaryContactComments: '',
    secondaryFirstName: '',
    secondaryLastName: '',
    secondaryCountryCode: '',
    secondaryPhone: '',
    secondaryCountryCode2: '',
    secondaryPhone2: '',
    secondaryContactType: 0,
    secondaryContactComments: '',
    secondaryEmail: '',
    imageBase64: '',
    imageType: '',
    adminCount: 0,
    viewerCount: 0,
    systemStatus: 0,
    systemStatusCount: 0,
    id: "",
    entityType: 0,
    partitionKey: "",
    sLADetails: [],
    organizationDetailList: [],
    buildingIdList: []
};

export const defaultCustomerDashboardList: CustomerDashboardList = {
    customerDetailList: [],
    totalCustomerCount: 0,
    totalAdminCount: 0,
    totalViewerCount: 0,
    totalSlaCount: 0
}
export const defaultCustomerV2List: CustomerV2List = {
    customerDetailList: [],
    totalCustomerCount: 0,
    totalAdminCount: 0,
    totalViewerCount: 0,
    totalSlaCount: 0
}

export type CustomerState = {
    dashboardCustomerListLoading: boolean;
    dashboardCustomerListSuccess: boolean;
    dashboardCustomerListFail: boolean;
    dashboardCustomerList: CustomerDashboardList,
    dashboardCustomerListErrorCode: string;
    customerListLoading: boolean;
    customerListSuccess: boolean;
    customerListFail: boolean;
    customerList: CustomerV2List,
    customerListErrorCode: string;
    linkCustomerLoading: boolean;
    linkCustomerSuccess: boolean;
    linkCustomerFail: boolean;
    linkCustomerErrorCode: string;


};

export const initialCustomerState: CustomerState = {
    dashboardCustomerListLoading: false,
    dashboardCustomerListSuccess: false,
    dashboardCustomerListFail: false,
    dashboardCustomerList: defaultCustomerDashboardList,
    dashboardCustomerListErrorCode: '',
    customerListLoading: false,
    customerListSuccess: false,
    customerListFail: false,
    customerList: defaultCustomerV2List,
    customerListErrorCode: '',
    linkCustomerLoading: false,
    linkCustomerSuccess: false,
    linkCustomerFail: false,
    linkCustomerErrorCode: ''

};
