import { LATITUDE_REGEX, LONGITUDE_REGEX, RESTRICT_SPEC_WITHOUT_SPACE_REGEX } from '../constants';

export const isValidCharWithoutSpace = (value: string): boolean => RESTRICT_SPEC_WITHOUT_SPACE_REGEX.test(value);

export const isLatitude = (value: string | undefined | null): boolean => {
    if (value) return !(Boolean(new RegExp(LATITUDE_REGEX).test(value)) || value === '');
    return false;
};

export const isLongitude = (value: string | undefined | null): boolean => {
    if (value) return !(Boolean(new RegExp(LONGITUDE_REGEX).test(value)) || value === '');
    return false;
};
