import { LANGUAGE } from '../../app/enum/ENUM';
import { SelectionData, SelectionList } from './SelectionListData';

export const appTheme: SelectionList = [
    {
        key: 1,
        value: "Light Theme",
        description: 'USER_SETTINGS.APP_THEME_DESCRIPTION'
    },
    {
        key: 2,
        value: "Dark Theme",
        description: 'USER_SETTINGS.APP_THEME_DESCRIPTION'
    },
    {
        key: 3,
        value: "System Theme",
        description: 'USER_SETTINGS.APP_THEME_DESCRIPTION'
    }
];
export const timeZone: SelectionList = [
    {
        key: 1,
        value: "GMT",
        description: "Greenwich Mean Time"
    },
    {
        key: 2,
        value: "IST",
        description: "Indian Standard Time"
    },
    {
        key: 3,
        value: "UTC",
        description: "Coordinated Universal Time"
    },
    {
        key: 4,
        value: "CST",
        description: "Central Standard Time"
    },
    {
        key: 5,
        value: "MST",
        description: "Mountain Standard Time"
    },
    {
        key: 6,
        value: "EST",
        description: "Eastern Time Zone"
    },
    {
        key: 7,
        value: "CET/CEST",
        description: 'USER_SETTINGS.TIMEZONE_DESCRIPTION'
    },
];
export const dateFormat: SelectionList = [
    {
        key: 1,
        value: "mm/dd/yyyy",
        description: "mm/dd/yyyy"
    },
    {
        key: 2,
        value: "yy/mm/dd",
        description: "yy/mm/dd"
    },
    {
        key: 3,
        value: "DD/MM/YYYY",
        description: 'USER_SETTINGS.DATEFORMAT_DESCRIPTION'
    },
    {
        key: 4,
        value: 'yyyy/mm/dd',
        description: 'yyyy/mm/dd'
    }
];
export const units: SelectionList = [
    {
        key: 1,
        value: "Imperial Units",
        description: 'USER_SETTINGS.UNITS_DESCRIPTION'
    },
    {
        key: 2,
        value: "ISO",
        description: "ISO"
    },
];
export const languageList: SelectionList = [
    {
        key: 1,
        value: "English(GB)",
        description: 'USER_SETTINGS.LANGUAGE_DESCRIPTION',
        code: 'en'
    },
    {
        key: 2,
        value: "Français",
        description: 'USER_SETTINGS.LANGUAGE_DESCRIPTION',
        code: 'fr'
    },
    {
        key: 3,
        value: "Deutsch",
        description: 'USER_SETTINGS.LANGUAGE_DESCRIPTION',
        code: 'de'
    },
    /*{
        key: 4,
        value: "Mandarin",
        description: 'USER_SETTINGS.LANGUAGE_DESCRIPTION',
        code: 'en'
    },
    {
        key: 5,
        value: "Arabic",
        description: 'USER_SETTINGS.LANGUAGE_DESCRIPTION',
        code: 'en'  //ar

    },
    {
        key: 6,
        value: "Russian",
        description: 'USER_SETTINGS.LANGUAGE_DESCRIPTION',
        code: 'en'  //ru
    }*/
];
export const reportTypePreferenceList: SelectionList = [
    {
        key: 1,
        value: "Per System",
        description: 'USER_SETTINGS.HEALTH_REPORT_TITLE'
    },
    {
        key: 2,
        value: "Per Building",
        description: 'USER_SETTINGS.HEALTH_REPORT_TITLE'
    }
];

export const summaryReportTypeList: SelectionList = [
    {
        key: 2,
        value: "Email, Push Notifications",
        description: 'USER_SETTINGS.HEALTH_REPORT_TITLE'
    },
    {
        key: 3,
        value: "Email Only",
        description: 'USER_SETTINGS.HEALTH_REPORT_TITLE'
    },
    {
        key: 4,
        value: "Don’t Notify",
        description: 'USER_SETTINGS.HEALTH_REPORT_TITLE'

    }
];
export const productUpdatesTypeList: SelectionList = [
    {
        key: 2,
        value: "Email, Push Notifications",
        description: 'USER_SETTINGS.PRODUCT_UPDATES_TITLE'
    },
    {
        key: 3,
        value: "Email Only",
        description: 'USER_SETTINGS.PRODUCT_UPDATES_TITLE'

    },
    {
        key: 4,
        value: "Don’t Notify",
        description: 'USER_SETTINGS.PRODUCT_UPDATES_TITLE'
    }
];
export const alarmsTypeList: SelectionList = [
    /*  //ToDo - Hide SMS option as per requirement, as we don't have support added
        {
            key: 1,
            value: "Email + SMS, Push Nofications",
            description: 'USER_SETTINGS.ALARMS_TITLE'
        },*/
    {
        key: 2,
        value: "Email, Push Notifications",
        description: 'USER_SETTINGS.ALARMS_TITLE'
    },
    {
        key: 3,
        value: "Email Only",
        description: 'USER_SETTINGS.ALARMS_TITLE'
    },
    {
        key: 4,
        value: "Don't Notify",
        description: 'USER_SETTINGS.ALARMS_TITLE'
    },
];
export const statusUpdatesTypeList: SelectionList = [
    /*  //ToDo - Hide SMS option as per requirement, as we don't have support added
        {
            key: 1,
            value: "Email + SMS, Push Nofications",
            description: 'USER_SETTINGS.STATUS_UPDATES_TITLE'
        },*/
    {
        key: 2,
        value: "Email, Push Notifications",
        description: 'USER_SETTINGS.STATUS_UPDATES_TITLE'
    },
    {
        key: 3,
        value: "Email Only",
        description: 'USER_SETTINGS.STATUS_UPDATES_TITLE'
    },
    {
        key: 4,
        value: "Don't Notify",
        description: 'USER_SETTINGS.STATUS_UPDATES_TITLE'
    },
];

export function getLanguageList(code: string): SelectionData {
    return languageList.filter((entity) => entity.code === code)[0] || languageList[0];
}
export function getLanguageListvalue(value: string): SelectionData {
    return languageList.filter((entity) => entity.value === value)[0] || languageList[0];
}
