// eslint-disable-next-line
import React, { useEffect } from 'react';
import { GenericToolbar } from '../../components/GenericToolbar';
import { pushAppRoute } from '../../data/domain/route-assets';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Paper, Button } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import SyncButtonImage from '../../assets/images/sync_button.svg';
import {
    rem_10,
    vh_10,
    vh_1000,
    vh_18,
    vh_20,
    vh_700,
    vw_10,
    vw_20,
    vw_25,
    vw_300,
} from '../../constants/dimentions';
import { DeviceProfileCard } from './DeviceProfileCard';
import { DeviceNetworkProfileCard } from './DeviceNetworkProfileCard';
import { DeviceLogsCard } from './DeviceLogsCard';
import { DeviceLuminariesList } from './DeviceLuminariesList';
import { DeviceLuminaryDetails } from './DeviceLuminaryDetails';
import { DeviceGatewayDetails } from './DeviceGatewayDetails';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { Thunks } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { pushAppRouteOverview } from '../../data/domain/route-overview';
import { Selectors } from '../../selectors';
import { DeviceData } from '../../types/device';
import { ENTITY_TYPE, USER_ROLE_TYPE } from '../enum/ENUM';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DeviceCustomerInfoCard } from './DeviceCustomerInfoCard';
import { RECORD_PER_PAGE_ASSET_SYSTEM_DETAIL_LIMIT, DEFAULT_PAGE_ONE } from '../../constants';
import { DeviceBuildingInfoCard } from './DeviceBuildingInfoCard';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider';

type Props = {
    location: any;
    showAllClick?: Function;
};

export const AssetDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const history = useHistory();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useDispatch();
    const { t } = useLanguageLocale();
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const [isOpenGatewayDetail, setOpenGatewayDetailViewState] = React.useState(false);
    const [isOpenLuminaryDetail, setOpenLuminaryDetailViewState] = React.useState(false);
    const [selected, setSelected] = React.useState(false);
    const [luminaryId, setLuminaryId] = React.useState('');
    const [isNewCustomerAdded, setNewCustomerAdded] = React.useState(false);
    const [customerId, setCustomerId] = React.useState('');
    const asset = useSelector(Selectors.Asset.assets);
    const [systemDetail, setSystemDetailState] = React.useState<DeviceData>(systemDetailDefault);
    const logbook = useSelector(Selectors.Logbook.logbook);
    const { backRoute, deviceId, buildingId, projectId, sourcePath, logId, gatewayId } = props.location.state;


    React.useEffect(() => {
        if (gatewayId !== null || gatewayId !== undefined) {
            dispatch(Thunks.Asset.getGatewayDetailsList('', '', gatewayId));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(Thunks.Asset.getDeviceList('', '', deviceId, '', ''));
        dispatch(Thunks.Asset.getLuminaryList(deviceId, ''));
        dispatch(Thunks.Users.getOrgUserList(deviceId));
        dispatch(Thunks.Logbook.getLatestLogs('', '', RECORD_PER_PAGE_ASSET_SYSTEM_DETAIL_LIMIT.toString(), DEFAULT_PAGE_ONE.toString(), '', deviceId, '', '', [], false, '', usertype === USER_ROLE_TYPE.SERVICE_MANAGER, ''));
    }, [deviceId]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (buildingId !== null || buildingId !== undefined) {
            dispatch(Thunks.Organization.getBuildingDetailsList('', buildingId));
        }
        dispatch(Thunks.Asset.getDeviceCustomerData(deviceId));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const refreshAssetDetails = () => {
        dispatch(Thunks.Asset.getDeviceList('', '', deviceId, '', ''));
        dispatch(Thunks.Asset.getLuminaryList(deviceId, ''));
        dispatch(Thunks.Users.getOrgUserList(deviceId));
        dispatch(Thunks.Logbook.getLatestLogs('', '', RECORD_PER_PAGE_ASSET_SYSTEM_DETAIL_LIMIT.toString(), DEFAULT_PAGE_ONE.toString(), '', deviceId, '', '', [], false, '', usertype === USER_ROLE_TYPE.SERVICE_MANAGER, ''));
    };

    const luminaryHandleOpen = (value: {
        luminaryId: string;
    }): void => {
        setLuminaryId(value.luminaryId);
        setOpenLuminaryDetailViewState(true);
        setOpenGatewayDetailViewState(false);
    };

    const luminaryHandleClose = (): void => {
        setLuminaryId('');
        setOpenLuminaryDetailViewState(false)
        setOpenGatewayDetailViewState(false);
    };
    const gatewayHandleOpen = (): void => {
        setSelected(true);
        setOpenGatewayDetailViewState(true);
        setOpenLuminaryDetailViewState(false)
    };
    const gatewayHandleClose = (): void => {
        setSelected(false);
        setOpenGatewayDetailViewState(false);
        setOpenLuminaryDetailViewState(false)
    };

    const goToManageUsers = (): void => {
        pushAppRoute(history,
            { type: 'ManageAssetUsers', assetId: deviceId },
            { backRoute: 'AssetDetails', assetId: deviceId, assetType: ENTITY_TYPE.SYSTEM, title: systemDetail.systemName, orgId: projectId, buildingId: buildingId, gatewayId: gatewayId, sourcePath: sourcePath });
    };

    useEffect(() => {
        if (!asset.deviceListLoading && asset.deviceListSuccess) {
            const deviceDetails = asset.deviceList.detail.filter((device) => device.systemId === deviceId)[0];
            setSystemDetailState(deviceDetails);

        } else if (!asset.deviceListLoading && asset.deviceListFail) {
            console.log('Device list loading failed');
        }
    }, [asset.deviceListLoading, asset.deviceListSuccess, asset.deviceListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (asset.updateCustomerDetailId) {
            setNewCustomerAdded(true);
            setCustomerId(asset.updateCustomerDetailId);
        }
        else {
            setNewCustomerAdded(false);
            setCustomerId('');
        }
    }, [asset.updateCustomerDetailId]);

    const NavigateToLogbookDetails = (logId: string, systemId: string): void => {
        pushAppRoute(
            history,
            { type: 'LogbookDetails', logId: logId },
            { backRoute: 'AssetDetails', logId: logId, systemId: systemId, deviceId: deviceId, gatewayId: gatewayId, buildingId: buildingId, projectId: projectId, sourcePath: sourcePath }//buildingId//projectId
        );
    };

    const goToDashboard = (): void => {
        pushAppRoute(history,
            { type: 'OverviewBuildingDetails' },
            { backRoute: 'AssetDetails', deviceId: deviceId, gatewayId: gatewayId, orgId: projectId, buildingId: buildingId });
    };

    const goToEditBuilding = (projectID: string): void => {
        pushAppRoute(history,
            { type: 'EditBuilding', orgId: projectID, buildingId: buildingId },
            { backRoute: 'AssetDetails', deviceId: deviceId, gatewayId: gatewayId, orgId: projectID, buildingId: buildingId });
    };

    const displayConnectedSystemsToGateway = (value: {
        buildingId: string, gatewayId: string
    }): void => {
        if (props.showAllClick)
            props.showAllClick({ buildingId: value.buildingId, gatewayId: value.gatewayId });
    };
    return (
        <div data-testid="asset-details-container">
            <GenericToolbar
                title={systemDetail.systemName}
                subtitle={systemDetail.buildingName + ' < ' + systemDetail.projectName}
                //showBackButton={true}
                disableButton={!asset.deviceListLoading && !asset.luminaryListLoading && !logbook.latestLogListLoading && !logbook.earlierLogListLoading}
                showBackButtonoptional={true}
                backButtonOnClick={(): void => {
                    if (backRoute === 'OverviewBuildingDetails') {
                        pushAppRouteOverview(
                            history,
                            { type: 'OverviewBuildingDetails' },//, buildingId, projectId },
                            { backRoute: 'Dashboard', buildingId: buildingId, projectId: projectId }
                        );
                    }
                    else if (backRoute === 'LogbookDetails') {
                        pushAppRouteOverview(
                            history,
                            { type: 'LogbookDetails', logId: logId },
                            { backRoute: 'Logbook', logId: logId }
                        );
                    }
                    else {
                        pushAppRoute(history, {
                            type: backRoute,
                        });
                    }
                }}
                color={ToolbarColor.Default}
            />

            <div style={{ display: 'absolute', width: '100%' }}>
                <Paper square style={{ backgroundColor: PXBColors.white[400], width: '100%' }}>
                    <Box
                        display="flex"
                        style={{
                            alignItems: 'right',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            height: vh_10,
                            marginTop: vh_10,
                            marginBottom: vh_10,
                            display: 'flex',
                            width: '100%',
                            paddingRight: isOpenLuminaryDetail ? vw_300 : vw_25,
                            transitionDelay: '0.2s',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', flex: 1, paddingTop: 10 }}>
                            <Typography
                                variant="caption"
                                data-testid="last-retrieved-time"
                                style={{
                                    width: '92%',
                                    textAlign: 'right',
                                }}
                            >
                                {t('COMMON.LAST_RETRIEVED')}
                            </Typography>

                            <Button
                                style={{
                                    width: vw_20,
                                    height: vh_20,
                                    backgroundImage: `url(${SyncButtonImage})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'filled',
                                    backgroundRepeat: 'no-repeat',
                                }}
                                color="primary"
                                data-testid="refresh-button"
                                onClick={() => refreshAssetDetails()}
                            >
                                {''}
                            </Button>
                        </div>
                    </Box>

                    <Box
                        display="flex"
                        style={{
                            flexDirection: md ? 'row' : 'column',
                            alignItems: 'left',
                            padding: vh_10,
                            width: '100%',
                            height: 'wrap',
                            display: 'absolute',
                        }}
                    >
                        <Box
                            display="flex"
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                minHeight: vh_700,
                                display: 'flex',
                                flexWrap: 'wrap',
                                height: 'wrap',
                                width: md ? '30%' : '',
                                marginLeft: isOpenLuminaryDetail ? -250 : 0,
                                transitionDelay: '0.2s',
                                marginBottom: rem_10
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    minHeight: vh_700,
                                    marginBottom: vh_10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="device-profile"
                            >
                                <DeviceProfileCard
                                    onUserClick={goToManageUsers}
                                    deviceId={deviceId} />
                            </Box>

                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    marginBottom: 'vh_10'
                                }}
                                data-testid="device-network-profile"
                            >
                                <DeviceNetworkProfileCard
                                    onSelect={selected}
                                    onGatewaySelect={gatewayHandleOpen}
                                    deviceId={deviceId} />
                            </Box>
                            <Box
                                display="flex"
                                style={{
                                    paddingTop: 20,
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="device-building-info-card"
                            >
                                <DeviceBuildingInfoCard goToDashboard={goToDashboard} goToEditBuilding={goToEditBuilding}></DeviceBuildingInfoCard>
                            </Box>
                            <Box
                                display="flex"
                                style={{
                                    paddingTop: 20,
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="device-customer-info-card"
                            >
                                <DeviceCustomerInfoCard projectId={projectId} deviceId={deviceId} openDialogForCustomerLink={isNewCustomerAdded} customerId={customerId} closeDialogClick={() => setCustomerId('')}></DeviceCustomerInfoCard>
                            </Box>
                        </Box>

                        <Box
                            display="flex"
                            style={{
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'flex-center',
                                minHeight: vh_1000,
                                marginLeft: vw_10,
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: md ? '65%' : '',
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    marginBottom: vh_10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="device-logs"
                            >
                                <DeviceLogsCard
                                    OnLogSelected={(logId: string, systemId: string): void => {
                                        NavigateToLogbookDetails(logId, systemId);
                                    }}></DeviceLogsCard>
                            </Box>

                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    height: 'wrap',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    alignItems: 'center'
                                }}
                                data-testid="luminaries-list"
                            >
                                <DeviceLuminariesList
                                    luminaryId={luminaryId}
                                    luminaryClick={luminaryHandleOpen}
                                    deviceId={deviceId} />
                            </Box>
                        </Box>

                        <Box
                            display="flex"
                            style={{
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                right: vh_18,
                                width: md ? vw_300 : '',
                                zIndex: 100,
                                height: '90%',
                                position: 'absolute',
                                transitionDelay: '0.2s',
                                visibility: isOpenLuminaryDetail ? 'visible' : 'hidden',
                            }}
                            data-testid="luminary-details"
                        >
                            <DeviceLuminaryDetails
                                luminaryId={luminaryId}
                                closeClick={luminaryHandleClose} />
                        </Box>

                        <Box
                            display="flex"
                            style={{
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                right: vh_18,
                                width: md ? vw_300 : '',
                                zIndex: 100,
                                minHeight: '90%',
                                backgroundColor: PXBColors.white[500],
                                position: 'absolute',
                                transitionDelay: '0.2s',
                                visibility: isOpenGatewayDetail ? 'visible' : 'hidden',
                            }}
                            data-testid="gateway-details"
                        >
                            <DeviceGatewayDetails
                                closeClick={gatewayHandleClose} gatewayId={''} isAssetGateway={false} displayClick={displayConnectedSystemsToGateway} />
                        </Box>
                    </Box>
                </Paper>
            </div>
        </div>
    );
};

const systemDetailDefault =
{
    systemId: "",
    systemName: "Loading",
    range: "",
    type: 1,
    firmwareVersion: "",
    partNumber: "",
    epasNumber: "",
    dateCommissioned: "2022-06-01T04:03:58Z",
    systemDescription: "",
    subLocation: "",
    testDuration: "",
    durationTestPrevious: "2022-06-01T04:03:58Z",
    durationTestNext: "2022-07-01T04:03:58Z",
    functionalTestNext: "2022-07-01T04:03:58Z",
    functionalTestPrevious: "2022-06-01T04:03:58Z",
    durationTestStatus: true,
    durationTestDescription: "",
    functionalTestStatus: true,
    functionalTestDescription: "",
    macAddress: "",
    ipAddress: "",
    subnet: "",
    systemStatus: 5,
    gateWayId: "",
    gateWayName: "",
    projectId: "",
    projectName: "",
    buildingName: "",
    buildingId: "",
    luminaryCount: 3,
    isFavourite: true,
    isAdmin: false
};
