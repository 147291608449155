import { ActionType } from '../../types';
import { TechnicianListData } from '../../types/technicians';
import { ServiceManagerCustomerDetailData, ServiceManagerListData } from '../../types/serviceManagers';
import { ServicePartnerCustomerDataList, ServicePartnerCustomerDetailData } from '../../types/servicePartnerCustomerList';
import { PlatformListData, PlatformSystemInfoList, platformDetail } from '../../types/platform';
import { servicePartnerDetail } from '../../types/add-ServicePartnerCustomer';

export const usermanagementActions = {
    technicianListStarted: () =>
    ({
        type: 'Usermanagement/technicianList/Started',
    } as const),

    technicianListSucceeded: (TechnicianList: TechnicianListData) =>
    ({
        type: 'Usermanagement/technicianList/Succeeded',
        payload: { TechnicianList }
    } as const),

    technicianListFailed: (errorCode: string) =>
    ({
        type: 'Usermanagement/technicianList/Failed',
        payload: { errorCode },
    } as const),

    technicianListUnmounted: () =>
    ({
        type: 'Usermanagement/technicianList/Unmounted',
    } as const),

    addPlatformStarted: () =>
    ({
        type: 'Usermanagement/addPlatform/Started',
    } as const),

    addPlatformSucceeded: (id: string) =>
    ({
        type: 'Usermanagement/addPlatform/Succeeded',
        payload: { id }
    } as const),

    addPlatformFailed: (errorCode: string) =>
    ({
        type: 'Usermanagement/addPlatform/Failed',
        payload: { errorCode },
    } as const),

    addPlatformUnmounted: () =>
    ({
        type: 'Usermanagement/addPlatform/Unmounted',
    } as const),

    addServiceManagerStarted: () =>
    ({
        type: 'Usermanagement/addServiceManager/Started',
    } as const),

    addServiceManagerSucceeded: (id: string) =>
    ({
        type: 'Usermanagement/addServiceManager/Succeeded',
        payload: { id }
    } as const),

    addServiceManagerFailed: (errorCode: string) =>
    ({
        type: 'Usermanagement/addServiceManager/Failed',
        payload: { errorCode },
    } as const),

    addServiceManagerUnmounted: () =>
    ({
        type: 'Usermanagement/addServiceManager/Unmounted',
    } as const),

    serviceManagerListStarted: () =>
    ({
        type: 'Usermanagement/serviceManagerList/Started',
    } as const),

    serviceManagerListSucceeded: (ServiceManagerList: ServiceManagerListData) =>
    ({
        type: 'Usermanagement/serviceManagerList/Succeeded',
        payload: { ServiceManagerList }
    } as const),

    serviceManagerListFailed: (errorCode: string) =>
    ({
        type: 'Usermanagement/serviceManagerList/Failed',
        payload: { errorCode },
    } as const),

    serviceManagerListUnmounted: () =>
    ({
        type: 'Usermanagement/serviceManagerList/Unmounted',
    } as const),

    addServicePartnerCustomerStarted: () =>
    ({
        type: 'Usermanagement/addServicePartnerCustomer/Started',
    } as const),

    addServicePartnerCustomerSucceeded: (message: string) =>
    ({
        type: 'Usermanagement/addServicePartnerCustomer/Succeeded',
        payload: { message }
    } as const),

    addServicePartnerCustomerFailed: (errorCode: string) =>
    ({
        type: 'Usermanagement/addServicePartnerCustomer/Failed',
        payload: { errorCode },
    } as const),

    addServicePartnerCustomerUnmounted: () =>
    ({
        type: 'Usermanagement/addServicePartnerCustomer/Unmounted',
    } as const),

    servicePartnerCustomersListStarted: () =>
    ({
        type: 'Usermanagement/servicePartnerCustomersList/Started',
    } as const),

    servicePartnerCustomersListSucceeded: (servicePartnerCustomerList: ServicePartnerCustomerDataList) =>
    ({
        type: 'Usermanagement/servicePartnerCustomersList/Succeeded',
        payload: { servicePartnerCustomerList }
    } as const),

    servicePartnerCustomersListFailed: (errorCode: string) =>
    ({
        type: 'Usermanagement/servicePartnerCustomersList/Failed',
        payload: { errorCode },
    } as const),

    servicePartnerCustomersListUnmounted: () =>
    ({
        type: 'Usermanagement/servicePartnerCustomersList/Unmounted',
    } as const),

    platformListStarted: () =>
    ({
        type: 'Usermanagement/platformList/Started',
    } as const),

    platformListSucceeded: (platformList: PlatformListData) =>
    ({
        type: 'Usermanagement/platformList/Succeeded',
        payload: { platformList }
    } as const),

    platformListFailed: (errorCode: string) =>
    ({
        type: 'Usermanagement/platformList/Failed',
        payload: { errorCode },
    } as const),

    servicePartnerCustomersDetailListStarted: () =>
    ({
        type: 'Usermanagement/servicePartnerCustomersDetailList/Started',
    } as const),

    servicePartnerCustomersDetailListSucceeded: (servicePartnerCustomerDetailList: ServicePartnerCustomerDetailData) =>
    ({
        type: 'Usermanagement/servicePartnerCustomersDetailList/Succeeded',
        payload: { servicePartnerCustomerDetailList }
    } as const),

    servicePartnerCustomersDetailListFailed: (errorCode: string) =>
    ({
        type: 'Usermanagement/servicePartnerCustomersDetailList/Failed',
        payload: { errorCode },
    } as const),

    servicePartnerCustomersDetailListUnmounted: () =>
    ({
        type: 'Usermanagement/servicePartnerCustomersDetailList/Unmounted',
    } as const),

    serviceManagerCustomersDetailListStarted: () =>
    ({
        type: 'Usermanagement/serviceManagerCustomersDetailList/Started',
    } as const),

    serviceManagerCustomersDetailListSucceeded: (serviceManagerCustomerDetailList: ServiceManagerCustomerDetailData) =>
    ({
        type: 'Usermanagement/serviceManagerCustomersDetailList/Succeeded',
        payload: { serviceManagerCustomerDetailList }
    } as const),

    serviceManagerCustomersDetailListFailed: (errorCode: string) =>
    ({
        type: 'Usermanagement/serviceManagerCustomersDetailList/Failed',
        payload: { errorCode },
    } as const),

    serviceManagerCustomersDetailListUnmounted: () =>
    ({
        type: 'Usermanagement/serviceManagerCustomersDetailList/Unmounted',
    } as const),

    servicePartnerDetailStarted: () =>
    ({
        type: 'Usermanagement/servicePartnerDetail/Started',
    } as const),

    servicePartnerDetailSucceeded: (servicePartnerDetail: servicePartnerDetail) =>
    ({
        type: 'Usermanagement/servicePartnerDetail/Succeeded',
        payload: { servicePartnerDetail }
    } as const),

    servicePartnerDetailFailed: (errorCode: string) =>
    ({
        type: 'Usermanagement/servicePartnerDetail/Failed',
        payload: { errorCode },
    } as const),

    servicePartnerDetailUnmounted: () =>
    ({
        type: 'Usermanagement/servicePartnerDetail/Unmounted',
    } as const),
    platformSystemInfoStarted: () =>
    ({
        type: 'Usermanagement/platformSystemInfo/Started',
    } as const),

    platformSystemInfoSucceeded: (PlatformSystemInfo: PlatformSystemInfoList) =>
    ({
        type: 'Usermanagement/platformSystemInfo/Succeeded',
        payload: { PlatformSystemInfo }
    } as const),

    platformSystemInfoFailed: (errorCode: string) =>
    ({
        type: 'Usermanagement/platformSystemInfo/Failed',
        payload: { errorCode },
    } as const),

    platformSystemInfoUnmounted: () =>
    ({
        type: 'Usermanagement/platformSystemInfo/Unmounted',
    } as const),

    platformDetailStarted: () =>
    ({
        type: 'Usermanagement/platformDetail/Started',
    } as const),

    platformDetailSucceeded: (platformDetail: platformDetail) =>
    ({
        type: 'Usermanagement/platformDetail/Succeeded',
        payload: { platformDetail }
    } as const),

    platformDetailFailed: (errorCode: string) =>
    ({
        type: 'Usermanagement/platformDetail/Failed',
        payload: { errorCode },
    } as const),

    platformDetailUnmounted: () =>
    ({
        type: 'Usermanagement/platformDetail/Unmounted',
    } as const),

    removeServiceUserStarted: () =>
    ({
        type: 'Usermanagement/removeServiceUser/Started',
    } as const),

    removeServiceUserSucceeded: () =>
    ({
        type: 'Usermanagement/removeServiceUser/Succeeded',
    } as const),

    removeServiceUserFailed: (errorCode: string) =>
    ({
        type: 'Usermanagement/removeServiceUser/Failed',
        payload: { errorCode },
    } as const),

    removeServiceUserUnmounted: () =>
    ({
        type: 'Usermanagement/removeServiceUser/Unmounted',
    } as const),

};

export type UsermanagementAction = ActionType<typeof usermanagementActions>;
