// eslint-disable-next-line
import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Selectors } from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Thunks } from '../../actions';
import { getUserEmail, getUserId, useAuthHelper, useAuthState } from '../AuthContextProvider';
import { useHistory } from 'react-router';
import * as PXBColors from '@brightlayer-ui/colors';
import { SessionStorage } from '../AuthContextProvider/session-storage';

type Props = {
    closeClick: Function;
    openDialogFlag: boolean
};
export const DeleteUser: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const authState = useAuthState();
    const authHelper = useAuthHelper();
    const emailId = getUserEmail(authState) || '';
    const userId = getUserId(authState);
    const history = useHistory();
    const userState = useSelector(Selectors.User.user);
    // const [confirmDelete, setConfirmDelete] = useState(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

    function closeDeleteUserDialog(): void {
        props.closeClick({});
    }
    const onCancel = () => {
        closeDeleteUserDialog();
    }

    const onConfirmDelete = (): void => {
        dispatch(Thunks.UserSettings.deleteUser(false, authHelper
        ));
    }
    const onOkay = (): void => {
        setDisplaySuccessMessage(false);
        dispatch(Thunks.Authentication.logout(userId, history, authHelper.onUserNotAuthenticated, authHelper));

    }


    React.useEffect(() => {
        if (!userState.deleteUserLoading && userState.deleteUserSuccess) {
            closeDeleteUserDialog();
            setDisplaySuccessMessage(true);
            setAlertSuccessMessage(t('USERDETAILS.DELETE_USER_SUCCESS_MSG'));
            SessionStorage.clearAuthCredentials();

        }
        else if (!userState.deleteUserLoading && userState.deleteUserErrorCode) {
            setDisplayErrorMessage(true);
            setAlertErrorMessage(t('USERDETAILS.DELETE_USER_FAILURE_MSG'));
            closeDeleteUserDialog();
        }

    }, [userState.deleteUserLoading, userState.deleteUserSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Dialog data-testid='deleteUserDialog' id="delete-user-dialog" maxWidth="xs" open={props.openDialogFlag} onClose={closeDeleteUserDialog}>
                <DialogTitle data-testid='dialogHeader'>{t('USERDETAILS.DELETE_USER_POP_UP_HEADER', { replace: { email: emailId } })}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('USERDETAILS.DELETE_USER_SUBHEADER')}</DialogContentText>
                </DialogContent>


                <Button
                    style={{ alignSelf: 'end', marginTop: 10, marginBottom: 10, marginRight: 10 }}
                    id="deleteAccount"
                    data-testid="button-delete-account"
                    variant="text"
                    color="error"
                    onClick={onConfirmDelete}
                >
                    {t('USERDETAILS.DELETE_USER_BUTTON_TEXT')}
                </Button>

                <Button style={{ alignSelf: 'end', marginBottom: 10, marginRight: 10 }} id="btnCancel" data-testid="btnCancel" variant="text" color="primary" onClick={(): void => onCancel()}>
                    {t('ACTIONS.CANCEL')}
                </Button>


            </Dialog>
            {
                displaySuccessMessage &&
                <Snackbar
                    open={true}
                    onClick={onOkay}
                    autoHideDuration={20}
                    title={''}
                    message={alertSuccessMessage}
                    action={
                        <Button color="inherit" size="small" onClick={onOkay}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.black[900] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }

            {
                displayErrorMessage &&
                <Snackbar
                    open={true}
                    onClick={() => setDisplayErrorMessage(false)}
                    autoHideDuration={20}
                    title={''}
                    message={alertErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}

                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }
        </>
    );
};

