import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_SETTING_NOTIFICATION } from './url-constants';

type UpdateUserEndpointSpecifications = ApiEndpointSpecification;
export const UpdateUserEndpointSpecification: UpdateUserEndpointSpecifications = {
    url: USER_SETTING_NOTIFICATION,
    method: HttpMethod.PUT,
    requestParamsSchemaName: 'UpdateUserRequestParams',
    requestBodySchemaName: 'UpdateUserRequestBody',
    okResponseSchemaName: 'UpdateUserOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeUpdateUser = Helpers.makeApiRequestFn<
    Types.UpdateUserRequestParams,
    Types.UpdateUserRequestBody,
    Types.UpdateUserOkResponse
>(UpdateUserEndpointSpecification);
