import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_SERVICEPARTNER_CUSTOMER_LIST } from './url-constants';

type getServicePartnerCustomersListEndpointSpecifications = ApiEndpointSpecification;
export const getServicePartnerCustomersListEndpointSpecification: getServicePartnerCustomersListEndpointSpecifications = {
    url: GET_SERVICEPARTNER_CUSTOMER_LIST,
    method: HttpMethod.GET,
    requestParamsSchemaName: 'GetServicePartnerCustomerListRequestParams',
    requestBodySchemaName: 'GetServicePartnerCustomerListRequestBody',
    okResponseSchemaName: 'GetServicePartnerCustomerListRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeServicePartnerCustomerListRequest = Helpers.makeApiRequestFn<
    Types.GetServicePartnerCustomerRequestParams,
    Types.GetServicePartnerCustomerListRequestBody,
    Types.GetServicePartnerCustomerRequestOkResponse
>(getServicePartnerCustomersListEndpointSpecification);
