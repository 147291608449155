import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_PLATFORM_DETAIL } from './url-constants';

type PlatformDetailDetailEndpointSpecification = ApiEndpointSpecification;
export const PlatformDetailDetailEndpointSpecifications: PlatformDetailDetailEndpointSpecification = {
    url: GET_PLATFORM_DETAIL,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'PlatformDetailRequestParams',
    requestBodySchemaName: 'PlatformDetailRequestBody',
    okResponseSchemaName: 'PlatformDetailRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokePlatformDetail = Helpers.makeApiRequestFn<
    Types.GetPlatformDetailRequestParams,
    Types.GetPlatformDetailRequestBody,
    Types.GetPlatformDetailOkResponse
>(PlatformDetailDetailEndpointSpecifications);
