import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { BUILDING_UPSERT} from './url-constants';

type BuildingUpsertEndpointSpecifications = ApiEndpointSpecification;
export const BuildingUpsertEndpointSpecification: BuildingUpsertEndpointSpecifications = {
    url: BUILDING_UPSERT,
    method: HttpMethod.PUT,
    requestParamsSchemaName: 'BuildingUpsertRequestParams',
    requestBodySchemaName: 'BuildingUpsertRequestBody',
    okResponseSchemaName: 'BuildingUpsertRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeBuildingUpsertRequest = Helpers.makeApiRequestFn<
    Types.BuildingUpsertRequestParams,
    Types.BuildingUpsertRequestBody,
    Types.BuildingUpsertRequestOkResponse
>(BuildingUpsertEndpointSpecification);
