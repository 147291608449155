
import { isDataLoading, isDataError } from '../types/data-providers';
import { useOrganizationData } from '../data/DataProviders/OrganizationDataProvider';
import { useUserRoleOperationData } from '../data/DataProviders/RoleOperationDataProvider';
import { SiteRoleData } from '../types';
import { useCustomerData } from '../data/DataProviders/CustomerDataProvider';
import { SMOrganization } from '../types/organization';
import { SMCustomerData } from '../types/customer-detail';

export enum RecordDataState {
    Loading = 'Loading',
    Found = 'Found',
    NotFound = 'NotFound',
    None = 'None',
    Error = 'Error',
}

export type RecordData<T = unknown> =
    | {
        state: RecordDataState.Loading;
    }
    | {
        state: RecordDataState.Found;
        value: T;
    }
    | {
        state: RecordDataState.NotFound;
    }
    | {
        state: RecordDataState.None;
    }
    | { state: RecordDataState.Error; errorMessage: string };

export const useSelectedOrganizationData = (): RecordData<{ id: string; name: string; address: string }> => {
    const organizationData = useOrganizationData();
    if (isDataLoading(organizationData)) {
        return { state: RecordDataState.Loading };
    }

    if (isDataError(organizationData)) {
        return { state: RecordDataState.Error, errorMessage: organizationData.errorMessage };
    }

    return {
        state: RecordDataState.Found,
        value: {
            id: organizationData.value.id,
            name: organizationData.value.name,
            address: organizationData.value.address,
        },
    };
};

export const useSelectedCustomerData = (): RecordData<SMCustomerData> => {
    const customerData = useCustomerData();
    if (isDataLoading(customerData)) {
        return { state: RecordDataState.Loading };
    }

    if (isDataError(customerData)) {
        return { state: RecordDataState.Error, errorMessage: customerData.errorMessage };
    }

    return {
        state: RecordDataState.Found,
        value: {
            id: customerData.value.id,
            name: customerData.value.name,
            address: customerData.value.address,
            primaryEmail: customerData.value.primaryEmail,
            primaryName: customerData.value.primaryName,
            primaryPhone: customerData.value.primaryPhone,
            viewerCount: customerData.value.viewerCount,
            adminCount: customerData.value.adminCount,
            customerNumber: customerData.value.customerNumber,
        },
    };
};


export const useUserRoles = (): RecordData<SiteRoleData[]> => {
    const roleInfo = useUserRoleOperationData();


    if (isDataError(roleInfo)) {
        return { state: RecordDataState.Error, errorMessage: roleInfo.errorMessage };
    }
    return {
        state: RecordDataState.NotFound,
    };
};
export const isRecordFound = <T>(
    data: RecordData<T>
): data is Extract<RecordData<T>, { state: RecordDataState.Found }> => data.state === RecordDataState.Found;

export const isRecordLoading = <T>(
    data: RecordData<T>
): data is Extract<RecordData<T>, { state: RecordDataState.Loading }> => data.state === RecordDataState.Loading;

export const isRecordError = <T>(
    data: RecordData<T>
): data is Extract<RecordData<T>, { state: RecordDataState.Error }> => data.state === RecordDataState.Error;

export const isRecordNotFound = <T>(
    data: RecordData<T>
): data is Extract<RecordData<T>, { state: RecordDataState.NotFound }> => data.state === RecordDataState.NotFound;

export const isRecordNone = <T>(data: RecordData<T>): data is Extract<RecordData<T>, { state: RecordDataState.None }> =>
    data.state === RecordDataState.None;
