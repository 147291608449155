// eslint-disable-next-line
import React from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './app';
import * as serviceWorker from './serviceWorker';

import CssBaseline from '@mui/material/CssBaseline';
import { ConnectedRouter as Router } from 'connected-react-router';
import { history, configureStore } from './store/store';

import { Provider } from 'react-redux';
import * as ApiRequests from './api/requests';
import { AuthContextProvider, combineDuplicateRequests } from './components/AuthContextProvider';

import './data/translations/i18n';
import '@brightlayer-ui/react-themes/open-sans';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as BLUIThemes from '@brightlayer-ui/react-themes';
import "@brightlayer-ui/react-themes/open-sans";



const refreshTokenFn = combineDuplicateRequests(ApiRequests.refresh);

export const buildRootElement = (opts?: {
    numberOfSecondsBeforeExpirationToRefreshToken?: number;
    checkTokenExpirationFrequencyMs?: number;
}): JSX.Element => {
    const numberOfSecondsBeforeExpirationToRefreshToken =
        (opts && opts.numberOfSecondsBeforeExpirationToRefreshToken) || 60 * 4; // 4 minutes before expiration by default

    const checkTokenExpirationFrequencyMs = (opts && opts.checkTokenExpirationFrequencyMs) || undefined;

    return (
        <ThemeProvider theme={createTheme(BLUIThemes.blue)}>
            <Provider store={configureStore()}>
                <CssBaseline />
                <Router history={history}>
                    <AuthContextProvider
                        refreshTokenFn={refreshTokenFn}
                        numberOfSecondsBeforeExpirationToRefreshToken={numberOfSecondsBeforeExpirationToRefreshToken}
                        checkTokenExpirationFrequencyMs={checkTokenExpirationFrequencyMs}
                    >
                        <App />
                    </AuthContextProvider>
                </Router>
            </Provider>
        </ThemeProvider>
    );
};

if (process.env.NODE_ENV !== 'test') {

    //TodoReact- old method for react 17 - no longer needed
    // ReactDOM.render(buildRootElement(), document.getElementById('root'));

    //New method to render App in react 18- change done by Abhinav
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(buildRootElement());

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}
