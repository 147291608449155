import { organization } from '../../fake-api-server/lib/generate';
import { InitialState } from '../state';
import { AppReducer } from './app-reducer';
import { orgListData } from '../state/organization-state';

export const organizationReducer: AppReducer<'Organization'> = (state = InitialState.Organization, action): typeof state => {
    switch (action.type) {

        case 'Organization/orgList/Started':
            return {
                ...state,
                orgListLoading: true,
                orgListSuccess: false,
                orgListFail: false,
                errorCode: '',
            };
        case 'Organization/orgList/Succeeded':
            return {
                ...state,
                orgListLoading: false,
                orgListSuccess: true,
                orgListFail: false,
                errorCode: '',
                orgList: action.payload.orgList,
            };
        case 'Organization/orgList/Failed':
            return {
                ...state,
                orgListLoading: false,
                orgListSuccess: false,
                orgListFail: true,
                errorCode: action.payload.errorCode,
            };

        case 'Organization/orgList/Unmounted':
            return {
                ...state,
                orgListLoading: false,
                orgListSuccess: false,
                orgListFail: false,
                errorCode: '',
                orgList: orgListData
            };

        case 'Organization/buildingList/Started':
            return {
                ...state,
                buildingListLoading: true,
                buildingListSuccess: false,
                buildingListFail: false,
                buildingErrorCode: '',
            };
        case 'Organization/buildingList/Succeeded':
            return {
                ...state,
                buildingListLoading: false,
                buildingListSuccess: true,
                buildingListFail: false,
                buildingErrorCode: '',
                buildingList: action.payload.buildingList,
            };
        case 'Organization/buildingList/Failed':
            return {
                ...state,
                buildingListLoading: false,
                buildingListSuccess: false,
                buildingListFail: true,
                buildingErrorCode: action.payload.errorCode,
            };

        case 'Organization/buildingList/Unmounted':
            return {
                ...state,
                buildingListLoading: false,
                buildingListSuccess: false,
                buildingListFail: false,
                buildingErrorCode: '',
            };
        case 'Organization/transferBuilding/Started':
            return {
                ...state,
                transferBuildingLoading: true,
                transferBuildingSuccess: false,
                transferBuildingFail: false,
                transferBuildingErrorMessage: '',
            };
        case 'Organization/transferBuilding/Succeeded':
            return {
                ...state,
                transferBuildingLoading: false,
                transferBuildingSuccess: true,
                transferBuildingFail: false,
                transferBuildingErrorMessage: '',
            };
        case 'Organization/transferBuilding/Failed':
            return {
                ...state,
                transferBuildingLoading: false,
                transferBuildingSuccess: false,
                transferBuildingFail: true,
                transferBuildingErrorMessage: action.payload.errorCode,
            };

        case 'Organization/transferBuilding/Unmounted':
            return {
                ...state,
                transferBuildingLoading: false,
                transferBuildingSuccess: false,
                transferBuildingFail: false,
                transferBuildingErrorMessage: '',
            };

        case 'Organization/updateOrgDetail/Started':
            return {
                ...state,
                updateOrgDetailLoading: true,
                updateOrgDetailSuccess: false,
                updateOrgDetailFail: false,
                updateOrgDetailErrorMessage: '',
            };
        case 'Organization/updateOrgDetail/Succeeded':
            return {
                ...state,
                updateOrgDetailLoading: false,
                updateOrgDetailSuccess: true,
                updateOrgDetailFail: false,
                updateOrgDetailErrorMessage: '',
            };
        case 'Organization/updateOrgDetail/Failed':
            return {
                ...state,
                updateOrgDetailLoading: false,
                updateOrgDetailSuccess: false,
                updateOrgDetailFail: true,
                updateOrgDetailErrorMessage: action.payload.errorCode,
            };

        case 'Organization/updateOrgDetail/Unmounted':
            return {
                ...state,
                updateOrgDetailLoading: false,
                updateOrgDetailSuccess: false,
                updateOrgDetailFail: false,
                updateOrgDetailErrorMessage: '',
            };
        case 'Organization/upsertBuildingDetail/Started':
            return {
                ...state,
                upsertBuildingDetailLoading: true,
                upsertBuildingDetailSuccess: false,
                upsertBuildingDetailFail: false,
                upsertBuildingDetailErrorMessage: '',
            };
        case 'Organization/upsertBuildingDetail/Succeeded':
            return {
                ...state,
                upsertBuildingDetailLoading: false,
                upsertBuildingDetailSuccess: true,
                upsertBuildingDetailFail: false,
                upsertBuildingDetailErrorMessage: '',
            };
        case 'Organization/upsertBuildingDetail/Failed':
            return {
                ...state,
                upsertBuildingDetailLoading: false,
                upsertBuildingDetailSuccess: false,
                upsertBuildingDetailFail: true,
                upsertBuildingDetailErrorMessage: action.payload.errorCode,
            };

        case 'Organization/upsertBuildingDetail/Unmounted':
            return {
                ...state,
                upsertBuildingDetailLoading: false,
                upsertBuildingDetailSuccess: false,
                upsertBuildingDetailFail: false,
                upsertBuildingDetailErrorMessage: '',
            };
        case 'Organization/markAsFav/Started':
            return {
                ...state,
                markasFavLoading: true,
                markasFavSuccess: false,
                markasFavFail: false,
                errorCode: '',
            };
        case 'Organization/markAsFav/Succeeded':
            return {
                ...state,
                markasFavLoading: false,
                markasFavSuccess: true,
                markasFavFail: false,
                errorCode: '',
            };
        case 'Organization/markAsFav/Failed':
            return {
                ...state,
                markasFavLoading: false,
                markasFavSuccess: false,
                markasFavFail: true,
                errorCode: action.payload.errorCode,
            };

        case 'Organization/markAsFav/Unmounted':
            return {
                ...state,
                markasFavLoading: false,
                markasFavSuccess: false,
                markasFavFail: false,
                errorCode: '',
            };
            case 'Organization/removeMarkAsFav/Started':
            return {
                ...state,
                removeMarkAsFavLoading: true,
                removeMarkAsFavSuccess: false,
                removeMarkAsFavFail: false,
                errorCode: '',
            };
        case 'Organization/removeMarkAsFav/Succeeded':
            return {
                ...state,
                removeMarkAsFavLoading: false,
                removeMarkAsFavSuccess: true,
                removeMarkAsFavFail: false,
                errorCode: '',
            };
        case 'Organization/removeMarkAsFav/Failed':
            return {
                ...state,
                removeMarkAsFavLoading: false,
                removeMarkAsFavSuccess: false,
                removeMarkAsFavFail: true,
                errorCode: action.payload.errorCode,
            };

        case 'Organization/removeMarkAsFav/Unmounted':
            return {
                ...state,
                removeMarkAsFavLoading: false,
                removeMarkAsFavSuccess: false,
                removeMarkAsFavFail: false,
                errorCode: '',
            };

        case 'Organization/mergeBuildingAsset/Started':
            return {
                ...state,
                mergeBuildingAssetLoading: true,
                mergeBuildingAssetSuccess: false,
                mergeBuildingAssetFail: false,
                mergeBuildingAssetErrorCode: '',
            };

        case 'Organization/mergeBuildingAsset/Succeeded':
            return {
                ...state,
                mergeBuildingAssetLoading: false,
                mergeBuildingAssetSuccess: true,
                mergeBuildingAssetFail: false,
                mergeBuildingAssetErrorCode: '',
            };

        case 'Organization/mergeBuildingAsset/Failed':
            return {
                ...state,
                mergeBuildingAssetLoading: false,
                mergeBuildingAssetSuccess: false,
                mergeBuildingAssetFail: true,
                mergeBuildingAssetErrorCode: action.payload.errorCode,
            };

        case 'Organization/mergeBuildingAsset/Unmounted':
            return {
                ...state,
                mergeBuildingAssetLoading: false,
                mergeBuildingAssetSuccess: false,
                mergeBuildingAssetFail: false,
                mergeBuildingAssetErrorCode: '',
            };
        case 'Organization/serviceManagerOrgList/Started':
            return {
                ...state,
                serviceManagerOrgListLoading: true,
                serviceManagerOrgListSuccess: false,
                serviceManagerOrgListFail: false,
                errorCode: '',
            };
        case 'Organization/serviceManagerOrgList/Succeeded':
            return {
                ...state,
                serviceManagerOrgListLoading: false,
                serviceManagerOrgListSuccess: true,
                serviceManagerOrgListFail: false,
                errorCode: '',
                serviceManagerOrgList: action.payload.serviceManagerOrgList,
            };
        case 'Organization/serviceManagerOrgList/Failed':
            return {
                ...state,
                serviceManagerOrgListLoading: false,
                serviceManagerOrgListSuccess: false,
                serviceManagerOrgListFail: true,
                errorCode: action.payload.errorCode,
            };

        case 'Organization/serviceManagerOrgList/Unmounted':
            return {
                ...state,
                serviceManagerOrgListLoading: false,
                serviceManagerOrgListSuccess: false,
                serviceManagerOrgListFail: false,
                errorCode: '',
            };

        default:
            return state;
    }

};
