import { LegalType } from '../types';

export type LegalState = {
    loading: boolean;
    success: boolean;
    content: string;
    openSourceList: Array<{
        id: LegalType;
        name: string;
    }>;
    type: LegalType;
};

export const initialLegalState: LegalState = {
    loading: false,
    success: false,
    content: '',
    type: LegalType.None,
    openSourceList: [],
};
