// eslint-disable-next-line
import * as React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { HeadCell } from '../../types/table';
import { createStyles, withStyles } from '@mui/styles';

const StyledTableCell = withStyles(() => createStyles({}))(TableCell);

const StyledTableRow = withStyles(() => createStyles({}))(TableRow);

type CustomizedTableProps = {
    headCells: HeadCell[];
    headColor: string;
    headFontSize?: number;
    headFontWeight?: string;
};
export const CustomizedTableHead = (props: CustomizedTableProps): JSX.Element => {
    return (
        <TableHead>
            <StyledTableRow style={{ width: '100%' }}>
                <StyledTableCell style={{ backgroundColor: '#ffffff' }} padding="normal">
                    &nbsp;
                </StyledTableCell>
                {props.headCells.map((headCell) => (
                    <StyledTableCell
                        colSpan={3}
                        style={{ backgroundColor: '#ffffff' }}
                        key={headCell.id}
                    >
                        <TableSortLabel
                            active={false}
                            direction={undefined}
                            hideSortIcon={headCell.hideSorting}
                            style={{ color: props.headColor, fontSize: props.headFontSize , fontWeight: props.headFontWeight}}
                        >
                            {headCell.label}

                        </TableSortLabel>
                    </StyledTableCell>
                ))}
                <StyledTableCell style={{ backgroundColor: '#ffffff' }} padding="checkbox"></StyledTableCell>
                <StyledTableCell style={{ backgroundColor: '#ffffff' }} padding="checkbox"></StyledTableCell>
                <StyledTableCell style={{ backgroundColor: '#ffffff' }} padding="checkbox"></StyledTableCell>
                <StyledTableCell style={{ backgroundColor: '#ffffff' }} padding="checkbox"></StyledTableCell>
            </StyledTableRow>
        </TableHead>
    );
};