// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { ApproveInvitationRequest, InvitationRequestData, InvitationRequestList, RequestInviteEntities, UserInvitationData } from '../../types/manage-users';
import { Selectors } from '../../selectors';
import SelectAllEmpty from '../../assets/images/selectall_blank.svg';
import SelectAllSelected from '../../assets/images/selectall_selected.svg';
import { ApprovalInviteList } from './ApprovalInviteList';
import { ACTION_TYPE, ENTITY_TYPE, INVITE_STATUS } from '../../app/enum/ENUM';
import * as PXBColors from '@brightlayer-ui/colors';
import { Close, Check } from '@material-ui/icons';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { Thunks } from '../../actions';

type DialogProps = {
    show: boolean;
    closeDialog: Function;
    actionType: Function;
};

export const InviteRequestDialog: React.FC<React.PropsWithChildren<React.PropsWithChildren<DialogProps>>> = (props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const usersSelector = useSelector(Selectors.Users.users);
    const [isDataAvailable, setDataAvailable] = useState(false);
    const [invitationCount, setInvitationCount] = useState(0); // eslint-disable-line @typescript-eslint/no-unused-vars
    const [isSelect, setIsSelect] = useState(false); // eslint-disable-line @typescript-eslint/no-unused-vars
    const [isSelectAll, setSelectALL] = useState(false);
    const userDetails = useSelector(Selectors.User.user);
    const userName = userDetails.userDetail.firstname + " " + userDetails.userDetail.lastname;
    const [inviteRequestList, setInviteRequestList] = useState<InvitationRequestList>([]);
    const [updateInviteRequestList, setUpdateInviteRequestList] = useState<InvitationRequestList>([]);

    const getEntityId = (userData: InvitationRequestData) => {
        if (userData.invitationType === ENTITY_TYPE.ORGANIZATION)
            return userData.organiztionId;
        else if (userData.invitationType === ENTITY_TYPE.BUILDING)
            return userData.buidlingId;
        else if (userData.invitationType === ENTITY_TYPE.SYSTEM)
            return userData.systemId;
        else
            return userData.organiztionId;
    }

    function getInvitationRequestBody(invitationStatus: number, inviteRequestList: InvitationRequestList): ApproveInvitationRequest {
        const inviteEntities: RequestInviteEntities[] = inviteRequestList.filter((item) => item.isSelected === true).map((request) => {
            return {
                entityId: getEntityId(request),
                userId: request.requestedById
            }
        });
        return {
            status: invitationStatus.toString(),
            inviteEntities: inviteEntities
        }
    }

    const onClickApprove = (): void => {
        const query: ApproveInvitationRequest = getInvitationRequestBody(INVITE_STATUS.ACCEPTED, inviteRequestList);
        dispatch(Thunks.Users.updateInvitationRequest(INVITE_STATUS.ACCEPTED.toString(), query.inviteEntities, addToast));
        props.closeDialog();
        props.actionType(ACTION_TYPE.ACCEPT);
    };

    const onClickDecline = (): void => {
        const query: ApproveInvitationRequest = getInvitationRequestBody(INVITE_STATUS.DENIED, inviteRequestList);
        dispatch(Thunks.Users.updateInvitationRequest(INVITE_STATUS.DENIED.toString(), query.inviteEntities, addToast));
        props.closeDialog();
        props.actionType(ACTION_TYPE.DECLINE);
    };

    const handleSelectAll = () => {
        if (isSelectAll) {
            const updateInviteList = inviteRequestList.map(obj => ({ ...obj, isSelected: false }))
            setSelectALL(false);
            setIsSelect(false);
            setUpdateInviteRequestList(updateInviteList);
            setDataAvailable(false);
            setInviteRequestList(updateInviteList);
        }
        else {
            const updateInviteList = inviteRequestList.map(obj => ({ ...obj, isSelected: true }))
            setSelectALL(true);
            setUpdateInviteRequestList(updateInviteList);
            setDataAvailable(true);
            setInviteRequestList(updateInviteList);
            setIsSelect(true);
        }
    }

    //This updates the invitation list with flag for checkbox
    const handleSelectedData = (userSelectedData: UserInvitationData) => {
        inviteRequestList.map((item) => {
            if (item.organiztionId === userSelectedData.organiztionId && item.invitationType === userSelectedData.invitationType) {
                return { ...item, userSelectedData }
            }
            else {
                return item;
            }
        })
        if (inviteRequestList.filter((item) => item.isSelected === true).length > 0) {
            setDataAvailable(true);
            setInvitationCount(inviteRequestList.filter((item) => item.isSelected === true).length);
        }
        else {
            setDataAvailable(false);
            setInvitationCount(0);
        }
    }

    useEffect(() => {
        setInviteRequestList(usersSelector.adminApporvalInviteHistory);
    }, [props.show]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Dialog
                disableEscapeKeyDown={true}
                open={props.show}
                aria-labelledby="invite-request-dialog"
                maxWidth="md"
                data-testid="invite-request-dialog"
            >
                <DialogTitle style={{ fontWeight: 600 }} id="invite-request-dialog-title" data-testid="invite-request-dialog-title" >{t('USER_INVITATION_MANAGEMENT.TITLE', { replace: { username: userName } })}</DialogTitle>
                <DialogContent>
                    <>
                        <div>
                            <Typography variant="subtitle1" component="p" style={{ fontWeight: 600 }} >
                                {t('USER_INVITATION_MANAGEMENT.ADMIN_SUBTITLE')}
                            </Typography>
                            <Typography variant="body2" component="p" style={{ marginBottom: 10 }}>
                                {t('USER_INVITATION_MANAGEMENT.ADMIN_INSTRUCTIONS')}
                            </Typography>
                            <div style={{ display: 'flex', padding: 10 }}>
                                <div className="invite-dialog-checkbox" style={{ display: 'left', margin: 20, alignContent: 'center' }}>
                                    <div className="invite-dialog-checkbox-image">
                                        <img
                                            src={SelectAllSelected}
                                            alt="logo"
                                            hidden={!isSelectAll}
                                            style={{ float: 'left', paddingRight: 12 }}
                                            id="select-icon"
                                            onClick={handleSelectAll}
                                        />
                                        <img
                                            src={SelectAllEmpty}
                                            alt="logo"
                                            hidden={isSelectAll}
                                            style={{ float: 'left', paddingRight: 12 }}
                                            id="unselect-icon"
                                            onClick={handleSelectAll}
                                        />
                                    </div>
                                </div>
                                <Typography
                                    id="invite-request-approve-checkbox-all-label"
                                    data-testid="invite-request-approve-checkbox-all-label"
                                    style={{ alignSelf: 'center', fontWeight: 600, fontSize: 16 }} variant="body2">
                                    {t('MANAGE_USERS.INVITE_USER_ORGANIZATION_SELECT_ALL')}
                                </Typography>
                            </div>
                            <div >
                                <ApprovalInviteList datalist={isSelectAll ? updateInviteRequestList : inviteRequestList} OnItemSelected={handleSelectedData} isSelectedAll={isSelectAll}></ApprovalInviteList>
                            </div>
                        </div>
                    </>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'float', marginBottom: 5, marginRight: 20 }}>
                    <Button
                        style={{
                            textTransform: 'none', backgroundColor: isDataAvailable ? PXBColors.blue[500] : PXBColors.blue[50],
                            color: isDataAvailable ? PXBColors.white[50] : PXBColors.blue[200],
                        }}
                        id="btnApprove"
                        disabled={!isDataAvailable}
                        data-testid="btnApprove"
                        variant="contained"
                        color="primary"
                        onClick={onClickApprove}>
                        <Check style={{ paddingRight: 5 }} />
                        {t('USER_INVITATION_MANAGEMENT.ADMIN_APPROVE')}
                    </Button>
                    <Button
                        style={{
                            borderColor: isDataAvailable ? PXBColors.red[500] : PXBColors.gray[500],
                            opacity: isDataAvailable ? 1 : 0.5,
                            backgroundColor: PXBColors.white[50],
                            textTransform: 'none',
                            color: isDataAvailable ? PXBColors.red[500] : PXBColors.gray[500],
                        }}
                        id="btnDecline"
                        data-testid="btnDecline"
                        variant="outlined"
                        disabled={!isDataAvailable}
                        color="primary"
                        onClick={onClickDecline}>
                        <Close style={{ color: isDataAvailable ? PXBColors.red[500] : PXBColors.gray[500], paddingRight: 5 }} />
                        {t('USER_INVITATION_MANAGEMENT.ADMIN_DECLINE_INVITATION')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
