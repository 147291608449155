// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { GenericToolbar } from '../../components/GenericToolbar';
import { Box, Paper } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { vh_10, vh_20, rem_10, vh_1000, vw_10, vh_400 } from '../../constants/dimentions';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { useHistory } from 'react-router-dom';
import { CustomerContactInfo } from '../assets/CustomerContactInfo';
import { servicePartnerDetail } from '../../types/add-ServicePartnerCustomer';
import { defaultServicePartnerDetail } from '../../state/usermanagement-state';
import { ServicePartnerInfoCard } from './ServicePartnerInfoCard';
import { ServicePartnerSlaDetails } from './ServicePartnerSlaDetails';
import { CUSTOMER_ROLE_TYPE } from '../enum/ENUM';

type Props = {
    location: any
};

export const ServicePartnerDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const user = useSelector(Selectors.Usermanagement.usermanagement);
    const [servicePartnerDetail, setServicePartnerDetail] = useState<servicePartnerDetail>(defaultServicePartnerDetail);
    const theme = useTheme();
    const { t } = useLanguageLocale();
    const { backRoute, customerId } = props.location.state;
    const history = useHistory();
    const md = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        if (!user.servicePartnerDetailLoading && user.servicePartnerDetailSuccess) {
            setServicePartnerDetail(user.servicePartnerDetail);
        } else if (!user.servicePartnerDetailLoading && user.servicePartnerDetailFail) {
            addToast(t('TOAST_MSG.TOAST_ERROR_GET_CUSTOMER_INFO'), 'error');
        }
    }, [user.servicePartnerDetailLoading, user.servicePartnerDetailSuccess, user.servicePartnerDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(Thunks.UserManagement.getServicePartnerDetail(customerId));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div data-testid="service-partner-details-container">
            <GenericToolbar
                title={servicePartnerDetail.companyName}
                subtitle={t('CUSTOMER_DETAILS.CUSTOMER_HEADER_SUBTITLE')}
                showBackButton={true}
                backButtonOnClick={(): void => {
                    history.goBack();
                }}
                color={ToolbarColor.Default}
            />
            <div style={{ display: 'absolute', width: '100%' }}>
                <Paper square style={{ backgroundColor: PXBColors.white[400], width: '100%' }}>
                    <Box
                        display="flex"
                        style={{
                            flexDirection: md ? 'row' : 'column',
                            alignItems: 'left',
                            padding: vh_20,
                            width: '100%',
                            height: 'wrap',
                            display: 'absolute',
                        }}
                    >
                        <Box
                            display="flex"
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                minHeight: vh_400,
                                display: 'flex',
                                flexWrap: 'wrap',
                                height: 'wrap',
                                marginLeft: 0,
                                transitionDelay: '0.2s',
                                marginBottom: rem_10,
                                width: '40%'
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    minHeight: vh_400,
                                    marginBottom: vh_10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="servicePartnerInfo-card"
                            >
                                <ServicePartnerInfoCard servicePartnerInfo={servicePartnerDetail} />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            style={{
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'flex-center',
                                minHeight: vh_1000,
                                marginLeft: vw_10,
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: md ? '65%' : '',
                            }}
                        >
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    marginBottom: vh_10,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                }}
                                data-testid="servicePartner-sla-details"
                            >
                                <ServicePartnerSlaDetails servicePartnerInfo={servicePartnerDetail}></ServicePartnerSlaDetails>
                            </Box>
                            <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    height: 'wrap',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    alignItems: 'center',
                                    marginBottom: vh_10,
                                }}
                                data-testid="serviceManagerContactInfo"
                            >
                                <CustomerContactInfo
                                    customerInfo={{
                                        firstName: servicePartnerDetail.serviceManagerFirstName,
                                        lastName: servicePartnerDetail.serviceManagerLastName,
                                        phone: servicePartnerDetail.serviceManagerPrimaryPhone,
                                        countryCode: servicePartnerDetail.serviceManagerPrimaryCountryCode,
                                        countryCode2: servicePartnerDetail.serviceManagerCountryCode2,
                                        phone2: servicePartnerDetail.serviceManagerPhone2,
                                        Email: servicePartnerDetail.serviceManagerEmail,
                                        ContactType: CUSTOMER_ROLE_TYPE.SERVICE_MANAGER,
                                        Comments: servicePartnerDetail.serviceManagerComments ? servicePartnerDetail.serviceManagerComments : ''
                                    }}
                                    header={t('CUSTOMER_DETAILS.SERVICE_MANAGER')} contactPersonHeader={t('CUSTOMER_DETAILS.BUYER_HEADER')}
                                />
                            </Box>
                            {!servicePartnerDetail.isInternalTeam && <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    height: 'wrap',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    alignItems: 'center',
                                    marginBottom: vh_10,
                                }}
                                data-testid="servicePartnerPrimaryContactInfo"
                            >
                                <CustomerContactInfo
                                    customerInfo={{
                                        firstName: servicePartnerDetail.primaryFirstName,
                                        lastName: servicePartnerDetail.primaryLastName,
                                        phone: servicePartnerDetail.primaryPhone,
                                        countryCode: servicePartnerDetail.primaryCountryCode,
                                        countryCode2: servicePartnerDetail.primaryCountryCode2,
                                        phone2: servicePartnerDetail.primaryPhone2,
                                        Email: servicePartnerDetail.primaryEmail,
                                        ContactType: servicePartnerDetail.primaryContactType,
                                        Comments: servicePartnerDetail.primaryContactComments ? servicePartnerDetail.primaryContactComments : ''
                                    }}
                                    header={t('ORGANIZATION_DETAILS.PRIMARY_CONTACT')} contactPersonHeader={t('CUSTOMER_DETAILS.BUYER_HEADER')}
                                />
                            </Box>}
                            {!servicePartnerDetail.isInternalTeam && <Box
                                display="flex"
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    height: 'wrap',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    alignItems: 'center'
                                }}
                                data-testid="servicePartnerSecondaryContactInfo"
                            >
                                <CustomerContactInfo
                                    header={t('ORGANIZATION_DETAILS.SECONDARY_CONTACT')} contactPersonHeader={t('CUSTOMER_DETAILS.TECH_LEAD_HEADER')}
                                    customerInfo={{
                                        firstName: servicePartnerDetail.secondaryFirstName ? servicePartnerDetail.secondaryFirstName : '',
                                        lastName: servicePartnerDetail.secondaryLastName ? servicePartnerDetail.secondaryLastName : '',
                                        phone: servicePartnerDetail.secondaryPhone,
                                        countryCode: servicePartnerDetail.secondaryCountryCode,
                                        countryCode2: servicePartnerDetail.secondaryCountryCode2,
                                        phone2: servicePartnerDetail.secondaryPhone2 ? servicePartnerDetail.secondaryPhone2 : '',
                                        Email: servicePartnerDetail.secondaryEmail ? servicePartnerDetail.secondaryEmail : '',
                                        ContactType: servicePartnerDetail.secondaryContactType,
                                        Comments: servicePartnerDetail.secondaryContactComments ? servicePartnerDetail.secondaryContactComments : ''
                                    }} />
                            </Box>}
                        </Box>
                    </Box>
                </Paper>
            </div>
        </div>
    );
};
