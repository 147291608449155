export type AuthenticationState = {
    isAuthenticating: boolean;
    login: {
        loading: boolean;
        errorCode: number;
        errorMessage: string;
    };
    forgotPassword: {
        loading: boolean;
        success: boolean;
        errorMessage: string;
    };
    setPassword: {
        requested: boolean;
        pending: boolean;
        success: boolean;
        failure: boolean;
        errorCode: string;
    };
    verifyResetCode: {
        code: string;
        pending: boolean;
        success: boolean;
    };
    preloadData: {
        loading: boolean;
        success: boolean;
    };
};

export const initialAuthenticationState: AuthenticationState = {
    isAuthenticating: false,
    login: {
        errorCode: 0,
        errorMessage: '',
        loading: false,
    },
    setPassword: {
        requested: false,
        pending: false,
        success: false,
        failure: false,
        errorCode: ''
    },
    forgotPassword: {
        loading: false,
        errorMessage: '',
        success: false,
    },
    verifyResetCode: {
        code: '',
        pending: false,
        success: false,
    },
    preloadData: {
        loading: false,
        success: false,
    },
};
