// eslint-disable-next-line
import React, { useCallback, useEffect } from 'react';
import * as PXBColors from '@brightlayer-ui/colors';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { SELECTED_ORGANIZATION, ALL_ORGANIZATION_ID, ADD_ORGANIZATION_ID } from '../../constants';
import { CircularProgress, InputLabel } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import Checkbox from "@mui/material/Checkbox";
import { Done, Add } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { EmptyState } from '@brightlayer-ui/react-components';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useHistory } from 'react-router-dom';
import { pushAppRoute } from '../../data/domain/route-manager';
import { rem_16 } from '../../constants/dimentions';
import { getControlledText } from '../../app/enum/GenericFormat';

type Organization = { id: string; name: string };
type OrgList = {
    organizationList: Organization[];
    selectedOrganization: string;
    updateSelectedOrganization: (organizationID: string) => void;
};
const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export const OrganizationDropdownToolbar: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ TestOrgList?: OrgList }>>> = (props) => {
    const { t } = useLanguageLocale();
    const history = useHistory();
    const DRAWER_WIDTH = 360;
    const useOrganizationDataList = useOrganizationList();
    //eslint-disable-next-line
    const [organizationList, setOrganizationList] = React.useState<OrgList>(useOrganizationDataList);

    const [organizationListData, setOrganizationListData] = React.useState<Organization[]>([])
    const [selectedOrganization, setSelectedOrganization] = React.useState<string>('');
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [displayCheck, setDisplayCheck] = React.useState(false);

    const createOrganizationList = () => {
        const allOrganization: Organization[] = [{ id: ALL_ORGANIZATION_ID, name: t('DASHBOARD.ALL_ORGANIZATION') }];
        const addOrganization: Organization[] = [{ id: ADD_ORGANIZATION_ID, name: t('DASHBOARD.ADD_ORGANIZATION') }];
        const list = allOrganization.concat(useOrganizationDataList.organizationList).concat(addOrganization);
        setOrganizationListData(list);
    };

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            formControl: {
                color: PXBColors.white[400],
                margin: theme.spacing(1),
                marginLeft: 0,
                width: 270,
                background: 'none',
            },
            dropdown: {
                color: PXBColors.black[400],
            },
            subheaderClass: {
                color: PXBColors.white[400],
                display: 'flex',
                alignItems: 'center',
                marginLeft: '15px',
            },
            PaperStyle: {
                // left: '0px !important',
                borderRadius: 0,
            },
            select: {
                '&:focus': { background: 'none' },
            },
        })
    );
    const classes = useStyles();

    useEffect(() => {
        setOrganizationList(useOrganizationDataList);
        setup();
    }, [useOrganizationDataList, useOrganizationDataList.organizationList]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.TestOrgList) {
            setOrganizationList(props.TestOrgList);
            setup();
        }
    }, [props.TestOrgList]); //eslint-disable-line react-hooks/exhaustive-deps

    const setup = (): void => {
        createOrganizationList();
        if (selectedOrganization === '') {
            sessionStorage.setItem(SELECTED_ORGANIZATION, ALL_ORGANIZATION_ID);
            setSelectedOrganization(ALL_ORGANIZATION_ID);
            organizationList.updateSelectedOrganization(ALL_ORGANIZATION_ID);
        }
    }

    const onDropdownCloseHandler = useCallback((): void => {
        setIsDropdownOpen(false);
        setDisplayCheck(false);
    }, []);

    const onDropdownOpenHandler = useCallback((): void => {
        setIsDropdownOpen(true);
        setDisplayCheck(true);
    }, []);

    const onSelectedOrganizationChangeHandler = (event: SelectChangeEvent) => {
        if(useOrganizationDataList)
            useOrganizationDataList.setTabClicked(false);
        setSelectedOrganization(event.target.value as string);
        if (event.target.value !== '0') {
            if (event.target.value === ADD_ORGANIZATION_ID) {
                const orgId = sessionStorage.getItem(SELECTED_ORGANIZATION);
                if (orgId) {
                    setSelectedOrganization(orgId);
                }
                addNewOrganization();
            } else {
                const orgId = event.target.value as string
                sessionStorage.setItem(SELECTED_ORGANIZATION, orgId);
                setSelectedOrganization(orgId);
                organizationList.updateSelectedOrganization(orgId);
            }
        }
    };

    const addNewOrganization = (): void => {
        pushAppRoute(history,
            { type: 'CreateOrganization' },
            { backRoute: 'Organizations' });
    }

    const isDividerDisplay = (id: string): boolean => {
        return id === ALL_ORGANIZATION_ID || id === organizationListData[organizationListData.length - 2].id
    }

    if (!useOrganizationDataList?.orgListLoaded) return <EmptyState style={{ flex: 1, marginTop: 10, backgroundColor: PXBColors.white[50] }}
        icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />
    else
        return (
            <div data-testid="organization-dd-container"
                style={{ color: PXBColors.white[100] }}>
                <FormControl variant={'filled'} style={{ display: 'flex', minWidth: 50 }}>
                    <InputLabel id="organization-name-label"> <Typography variant="overline">{t('DASHBOARD.ORGANIZATION_TITLE')}</Typography></InputLabel>
                    <Select
                        disableUnderline
                        variant="filled"
                        label={t('DASHBOARD.ORGANIZATION_TITLE')}
                        labelId="organization-dd-control-label"
                        id="organization-dd-control"
                        data-testid="organization-dd-control"
                        open={isDropdownOpen}
                        onClose={onDropdownCloseHandler}
                        onOpen={onDropdownOpenHandler}
                        value={selectedOrganization}
                        onChange={onSelectedOrganizationChangeHandler}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            MenuListProps: {
                                disablePadding: true,
                            },
                            PaperProps: {
                                style: { width: DRAWER_WIDTH },
                            },
                            classes: { paper: classes.PaperStyle },

                        }}
                        className={classes.dropdown}
                    >
                        {organizationListData?.map((item) => (
                            <StyledMenuItem
                                data-testid="organization-dropdown-item"
                                key={item.id}
                                value={item.id}
                                divider={isDividerDisplay(item.id)}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'left', fontSize: rem_16,
                                    lineHeight: 1.5, fontWeight: 400,
                                    overflow: 'hidden',
                                    boxSizing: 'border-box',
                                    whiteSpace: 'nowrap',
                                    minHeight: 48,
                                    paddingTop: 6,
                                    paddingBottom: 6,
                                    width: 'auto',
                                    paddingLeft: 16,
                                    paddingRight: 16
                                }}
                            >
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    {displayCheck && <Checkbox
                                        icon={item.id === ADD_ORGANIZATION_ID ? <Add style={{ width: 20, height: 20, color: PXBColors.gray[500] }} /> : <Done style={{ color: '#00000000', width: 20, height: 20 }} />} checkedIcon={<Done style={{ width: 20, height: 20, color: PXBColors.gray[500] }} />} checked={(item.id === ADD_ORGANIZATION_ID) ? false : (selectedOrganization.indexOf(item.id) > -1)}
                                    />}

                                    <Typography variant="subtitle1" style={{ fontWeight: isDropdownOpen ? 400 : 600, fontSize: rem_16, color: item.id === ADD_ORGANIZATION_ID ? PXBColors.gray[500] : PXBColors.black[500] }}>
                                        {getControlledText(item.name, 35)}
                                    </Typography>
                                </div>
                            </StyledMenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        );
};

