import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { SIGNALR } from './url-constants';

type SignalRConnectionEndpointSpecifications = ApiEndpointSpecification;
export const SignalRConnectionEndpointSpecification: SignalRConnectionEndpointSpecifications = {
    url: SIGNALR,
    method: HttpMethod.GET,
    requestParamsSchemaName: 'SignalrConnectionRequestParams',
    requestBodySchemaName: 'SignalrConnectionRequestBody',
    okResponseSchemaName: 'SignalrConnectionOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeSignalRConnection = Helpers.makeApiRequestFn<
    Types.SignalRConnectionRequestParams,
    Types.SignalRConnectionRequestBody,
    Types.SignalRConnectionOkResponse
>(SignalRConnectionEndpointSpecification);
