import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_BUILDING_LIST } from './url-constants';

type ProjectBuildingListRequestEndpointSpecifications = ApiEndpointSpecification;
export const ProjectBuildingListRequestEndpointSpecification: ProjectBuildingListRequestEndpointSpecifications = {
    url: GET_BUILDING_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'ProjectBuildingListRequestParams',
    requestBodySchemaName: 'ProjectBuildingListRequestBody',
    okResponseSchemaName: 'ProjectBuildingListRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeProjectBuildingListRequest = Helpers.makeApiRequestFn<
    Types.ProjectBuildingListRequestParams,
    Types.ProjectBuildingListRequestBody,
    Types.ProjectBuildingListRequestOkResponse
>(ProjectBuildingListRequestEndpointSpecification);
