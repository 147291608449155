// eslint-disable-next-line
import React, { useEffect } from 'react';
import { Autocomplete, Button, CircularProgress, DialogContent, DialogTitle, Divider, IconButton, TextField, Typography } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { ItemData, ItemList } from '../../types/logbook-type';
import { ActionType } from '../../constants';
import { vw_600 } from '../../constants/dimentions';
import { Close } from '@mui/icons-material';
import { Selectors } from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { slaCustomerList } from '../../types/sla-customer-details';
import { Thunks } from '../../actions';
import Box from '@mui/material/Box';
import { EmptyState } from '@brightlayer-ui/react-components';

type Props = {
    closeClick: Function;
    openDialogFlag: boolean;
    deviceId: string;
    projectId: string;
    customerNameUpdate: Function;
    customerId: string;
};
export const LinkCustomerDialog: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const history = useHistory();
    const asset = useSelector(Selectors.Dashboard.asset);
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const [customers, setCustomers] = React.useState<ItemList>([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState<ItemData>();
    const [isDataAvailable, setDataAvailable] = React.useState(false);

    useEffect(() => {
        dispatch(Thunks.Asset.getSlaCustomerList(''));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.customerId) {
            setSelectedCustomer(customers.filter((customer) => customer.id === props.customerId)[0]);
         }
    }, [props.customerId]); // eslint-disable-line react-hooks/exhaustive-deps

    const customerListData = (list: slaCustomerList): ItemList => {
        return list?.map((data, customer) => {
            return {
                id: data.id,
                name: data.companyName,
                number:data.customerNumber!
            };
        });
    };

    useEffect(() => {
        if (!asset.slaCustomerListLoading && asset.slaCustomerListSuccess) {
            setCustomers(customerListData(asset.slaCustomerList));
            setDataAvailable(true);
            if (props.customerId) {
                setSelectedCustomer(customerListData(asset.slaCustomerList).filter((customer) => customer.id === props.customerId)[0]);
                if (props.customerNameUpdate) { props.customerNameUpdate(customerListData(asset.slaCustomerList).filter((customer) => customer.id === props.customerId)[0]?.number) }
              }
        } else if (!asset.slaCustomerListLoading && asset.slaCustomerListFail) {
            setCustomers([]);
            setDataAvailable(false);
        }
    }, [asset.slaCustomerListLoading, asset.slaCustomerListSuccess, asset.slaCustomerListFail, props.customerId]); // eslint-disable-line react-hooks/exhaustive-deps

    function closeSelectOrgDialog(): void {
        props.closeClick({});
    }
    const onCancel = () => {
        closeSelectOrgDialog();
    }
    const goToCreateCustomer = (): void => {
        pushAppRoute(history,
            { type: 'AddCustomerDetails', actionType: ActionType.ADD },
            { backRoute: 'Assets', deviceId: props.deviceId, projectId: props.projectId, }
        );
    };

    return (
        <div data-testid="link-customer-dialog-1"
            style={{ width: vw_600 }}
        >

            {!isDataAvailable && <EmptyState style={{ flex: 1, height: '100%', backgroundColor: PXBColors.white[50] }}
                icon={<CircularProgress id="progress-spinner" />} title={t('MESSAGES.LOADING')} placeholder={undefined} />}
            {isDataAvailable && <DialogContent >
                <>
                    <IconButton style={{
                        textAlign: 'right',
                        float: 'right',
                    }}
                        data-testid="close-link-customer-button"
                        onClick={(): void => onCancel()}>
                        <Close style={{ width: 24, height: 24, }} fontSize="small" />
                    </IconButton>

                    <div>
                        <DialogTitle data-testid='dialogHeaderLinkCustomer' style={{fontSize: 16, fontWeight: 600, color: PXBColors.black[500], paddingLeft: 0 }}>{t('ASSETS_DETAILS.LINK_TO_CUSTOMER_DIALOG_HEADER')}
                        </DialogTitle>
                    </div>
                    <Divider></Divider>

                    <Typography data-testid='helperText' variant='subtitle2' style={{ paddingTop: 30, paddingBottom: 12 }}>{t('ASSETS_DETAILS.LINK_CUSTOMER_DIALOG_SEARCH')}</Typography>
                    <Autocomplete
                        options={customers}
                        id="auto-complete"
                        autoComplete
                        includeInputInList
                        value={selectedCustomer}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }} component="li"  {...props}>

                                <Typography>{option.name} </Typography>
                                <Typography style={{ marginRight: 50 }}> {option.number}</Typography>
                            </Box>
                        )}
                        onChange={(event, newValue, reason) => {
                            if (
                                event.type === 'keydown' &&
                                (event as React.KeyboardEvent).key === 'Backspace' &&
                                reason === 'removeOption'
                            ) { return; }

                            else {
                                setSelectedCustomer(newValue as ItemData);
                                if (props.customerNameUpdate) { props.customerNameUpdate(newValue?.number) }
                            }
                        }}

                        renderInput={(params) => (
                            <TextField
                                variant='filled'
                                {...params}
                            />
                        )}
                    />

                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 80, paddingBottom: 50 }}>
                        <Button
                            style={{
                                borderColor: PXBColors.blue[500],
                                backgroundColor: PXBColors.white[50],
                                color: PXBColors.blue[500],
                                flexDirection: 'row',
                                textTransform: 'none',
                            }}
                            id="create-customer"
                            data-testid="create-customer"
                            variant="outlined"
                            color="primary"
                            onClick={(): void => goToCreateCustomer()}>
                            {t('ASSETS_DETAILS.CREATE_NEW_CUSTOMER_BUTTON')}
                        </Button>
                    </div>
                </>
            </DialogContent>}
        </div >
    );
};

