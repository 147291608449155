// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { OrgBuildingData } from '../../types/organization';
import BuildingPin from '../../assets/images/map/Building_Pin.svg';
import { GeoCodingBuildingAddress } from '../../types/building';
import { MAP_DEFAULT_ZOOM_COUNTRY, MAP_DEFAULT_ZOOM_MARKER } from '../../constants';

type Props = {
    buildingId: string;
    buildingDetails?: OrgBuildingData;
    geocodePosition?: Function;
    modifiedAddress?: Function;
    address?: string;
    city?: string;
    country?: string;
    latitude?: number;
    longitude?: number;
    postalCode?: string;
    isMarkerDraggable?: boolean;
};

const defaultCenter = {
    lat: 51.57558000, // -3.745
    lng: 8.10619000   // -38.523
};

const MapGeneric = (props: Props) => {

    const [center, setCenter] = React.useState<google.maps.LatLngLiteral>(defaultCenter);
    const [buildingAddress, setBuildingAddress] = React.useState<GeoCodingBuildingAddress>();
    const [zoom, setZoom] = useState(MAP_DEFAULT_ZOOM_COUNTRY);
    const [buildingLoaded, setBuildingLoaded] = React.useState(false);
    let address = props.buildingDetails?.address;
    let city = props.buildingDetails?.city;
    let country = props.buildingDetails?.country;
    let postalCode = props.buildingDetails?.postalCode;
    const userAddress = address + ' ' + city + ' ' + postalCode + ' ' + country;

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    useEffect(() => {
        if (props.address || props.city || props.country || props.postalCode) {
            let currentAddress = props.country + ' ' + props.city + ' ' + props.postalCode + ' ' + props.address;
            codeAddress(currentAddress);
        }
    }, [props.address, props.city, props.country, props.postalCode]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!props.latitude) {
            setBuildingLoaded(true);
        }
    }, [props.buildingDetails]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (buildingLoaded) {
            if (props.country) {
                let currentAddress = props.country + ' ' + props.city + ' ' + props.postalCode + ' ' + props.address;
                codeAddress(currentAddress);
            }
            else {
                codeAddress(userAddress);
            }
        }
    }, [buildingLoaded]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.latitude && props.longitude) {
            let buildinglocation = {
                lat: props.latitude,
                lng: props.longitude
            }
            setCenter(buildinglocation);
            if (props.geocodePosition)
                props.geocodePosition(buildinglocation);
        }
    }, [props.latitude, props.longitude]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (buildingAddress) {
            if (props.modifiedAddress)
                props.modifiedAddress(buildingAddress);
        }
    }, [buildingAddress]); // eslint-disable-line react-hooks/exhaustive-deps

    let geocoder = new google.maps.Geocoder();

    const codeAddress = (address: string) => {
        geocoder.geocode({ address: address }, function (results, status) {
            if (status === 'OK') {
                if (results) {
                    const position: google.maps.LatLng = results[0].geometry.location;
                    var lat = position.lat();
                    var lng = position.lng();
                    const buildingposition = {
                        lat: lat,
                        lng: lng
                    }
                    setCenter(buildingposition);
                    if (props.geocodePosition)
                        props.geocodePosition(buildingposition);
                    setBuildingLoaded(false);
                }
            } else {
                //Geocode unsuccessful + status
            }
        });
    }

    const reverseGeocodeAddressbyLatLng = (newPosition: any) => geocoder.geocode({ location: newPosition }, function (results, status) {
        if (status === 'OK') {
            if (results) {
                // here we can dispatch action to search by city name and     autofill the autocomplete
                let establishment, streetNumber, route, city, premise, postalTown, political, country, postalCode;
                for (let i = 0; i < results[0].address_components.length; i++) {
                    for (let j = 0; j < results[0].address_components[i].types.length; j++) {
                        switch (results[0].address_components[i].types[j]) {
                            case "establishment":
                                establishment = results[0].address_components[i].long_name;
                                break;
                            case "premise":
                                premise = results[0].address_components[i].long_name;
                                break;
                            case "political":
                                political = results[0].address_components[i].long_name;
                                break;
                            case "street_number":
                                streetNumber = results[0].address_components[i].long_name;
                                break;
                            case "postal_town":
                                postalTown = results[0].address_components[i].long_name;
                                break;

                            case "route":
                                route = results[0].address_components[i].long_name;
                                break;
                            case "locality":
                                city = results[0].address_components[i].long_name;
                                break;
                            case "country":
                                country = results[0].address_components[i].long_name;
                                break;
                            case "postal_code":
                                postalCode = results[0].address_components[i].long_name;
                        }
                    }
                }
                let reverseGeocodeAddress = {
                    establishment: establishment,
                    streetNumber: streetNumber,
                    route: route,
                    city: city,
                    premise: premise,
                    political: political,
                    country: country,
                    postalCode: postalCode,
                    postalTown: postalTown
                }
                if (reverseGeocodeAddress.country)
                    setBuildingAddress(reverseGeocodeAddress);
            } else {
                console.log("address not found");
            }
        }
    })

    //TODO
    const handleActiveMarker = (marker: any, position: any) => {
        setCenter(center);
        setZoom(MAP_DEFAULT_ZOOM_MARKER);
    };

    const onMarkerDragEnd = (position: any) => {
        if (position) {
            let newLocation = new google.maps.LatLng(position.latLng);
            reverseGeocodeAddressbyLatLng(newLocation);
            if (props.geocodePosition)
                props.geocodePosition(newLocation);
        }
    }

    return (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoom}
                options={{ streetViewControl: false }}
            >
                {props.buildingId != '' && (<Marker
                    key={props.buildingId}
                    position={center}
                    icon={BuildingPin}
                    draggable={props.isMarkerDraggable}
                    onDragEnd={onMarkerDragEnd}
                    onClick={() => handleActiveMarker(props.buildingId, center)}
                ></Marker>)}
            </GoogleMap>
        </>
    )
}

export default MapGeneric;