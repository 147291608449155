import { AppReducer } from './app-reducer';
import { ChangePasswordDialogProgress } from '../types';
import { InitialState } from '../state';

export const userSettingsReducer: AppReducer<'User'> = (state = InitialState.User, action): typeof state => {
    switch (action.type) {
        case 'User/userDetail/Started':
            return {
                ...state,
                getUserDetailLoading: true,
                getUserDetailSuccess: false,
                getUserDetailFail: false,
                getUserDetailErrorMessage: '',
            };
        case 'User/userDetail/Succeeded':
            return {
                ...state,
                getUserDetailLoading: false,
                getUserDetailSuccess: true,
                getUserDetailFail: false,
                getUserDetailErrorMessage: '',
                userDetail: action.payload.userDetail,
            };
        case 'User/userDetail/Failed':
            return {
                ...state,
                getUserDetailLoading: false,
                getUserDetailSuccess: false,
                getUserDetailFail: true,
                getUserDetailErrorMessage: action.payload.errorCode,
            };
        case 'User/userDetail/Unmounted':
            return {
                ...state,
                getUserDetailLoading: false,
                getUserDetailSuccess: false,
                getUserDetailFail: false,
                getUserDetailErrorMessage: '',
            };

        case 'User/updateUserDetail/Started':
            return {
                ...state,
                updateUserDetailLoading: true,
                updateUserDetailSuccess: false,
                updateUserDetailFail: false,
                updateUserDetailErrorMessage: '',
            };
        case 'User/updateUserDetail/Succeeded':
            return {
                ...state,
                updateUserDetailLoading: false,
                updateUserDetailSuccess: true,
                updateUserDetailFail: false,
                updateUserDetailErrorMessage: '',
            };
        case 'User/updateUserDetail/Failed':
            return {
                ...state,
                updateUserDetailLoading: false,
                updateUserDetailSuccess: false,
                updateUserDetailFail: true,
                updateUserDetailErrorMessage: action.payload.errorCode,
            };
        case 'User/updateUserDetail/Unmounted':
            return {
                ...state,
                updateUserDetailLoading: false,
                updateUserDetailSuccess: false,
                updateUserDetailFail: false,
                updateUserDetailErrorMessage: '',
            };
                    case 'UserSettings/ChangePassword/Succeeded':
            return {
                ...state,
                changePassword: {
                    loading: false,
                    progress: ChangePasswordDialogProgress.Succeeded,
                },
            };
        case 'UserSettings/ChangePassword/Failed':
            return {
                ...state,
                changePassword: {
                    loading: false,
                    progress: ChangePasswordDialogProgress.Failed,
                },
            };
        case 'UserSettings/ChangePassword/Started':
        case 'UserSettings/ChangePassword/Reset':
            return {
                ...state,
                changePassword: {
                    loading: false,
                    progress: ChangePasswordDialogProgress.Initial,
                },
            };
        case 'UserSettings/UserRole/Update/Started':
            return {
                ...state,
                updateRoleLoading: true,
                updateRoleSuccess: false,
            };
        case 'UserSettings/UserRole/Update/Succeeded':
            return {
                ...state,
                updateRoleLoading: false,
                updateRoleSuccess: true,
                updatedRolesSites: action.payload.updatedRoleSite,
            };
        case 'UserSettings/UserRole/Update/Failed':
            return {
                ...state,
                updateRoleLoading: false,
                updateRoleSuccess: false,
            };
        case 'UserSettings/UserRole/Update/Unmounted':
            return {
                ...state,
                updateRoleLoading: false,
                updateRoleSuccess: false,
                updatedRolesSites: [],
            };
            case 'UserSettings/deleteUser/Started':
            return {
                ...state,
                deleteUserLoading: true,
                deleteUserSuccess: false,
                deleteUserErrorCode: '',
            };

        case 'UserSettings/deleteUser/Succeeded':
            return {
                ...state,
                deleteUserLoading: false,
                deleteUserSuccess: true,
                deleteUserErrorCode: '',
            };

        case 'UserSettings/deleteUser/Failed':
            return {
                ...state,
                deleteUserLoading: false,
                deleteUserSuccess: false,
                deleteUserErrorCode: action.payload.errorCode,
            };

        case 'UserSettings/deleteUser/Unmounted':
            return {
                ...state,
                deleteUserLoading: false,
                deleteUserSuccess: false,
                deleteUserErrorCode: '',
            };


        default:
            return state;
    }
};
