// eslint-disable-next-line
import React from 'react';
import { AlarmStatus } from '../../lib/alarm-helpers';
import { AcknowledgedStatusLabel } from '../AcknowledgedStatusLabel';
import { OpenStatusLabel } from '../OpenStatusLabel';
import { ClosedStatusLabel } from '../ClosedStatusLabel';
import { UnAcknowledgedStatusLabel } from '../UnAcknowledgedStatusLabel';
import { OpenStatusLabelStatus } from '../OpenStatusLabelStatus';

export const AlarmBadge: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ alarmStatus: string }>>> = (props) => (
    <>
        {props.alarmStatus === AlarmStatus.Acknowledged && <AcknowledgedStatusLabel />}
        {props.alarmStatus === AlarmStatus.Open && <OpenStatusLabel />}
        {props.alarmStatus === AlarmStatus.Closed && <ClosedStatusLabel />}
        {props.alarmStatus === AlarmStatus.Cleared && <UnAcknowledgedStatusLabel />}
    </>
);

export const AlarmBadgeStatus: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ alarmStatus: string, statusName?: string, statusColor: string, foregroundColor: string }>>> = (props) => (
    <OpenStatusLabelStatus statusName={props.statusName} statusColor={props.statusColor} foregroundColor={props.foregroundColor}/>
);
