// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Search, Close } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { EmptyState } from '@brightlayer-ui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { Thunks } from '../../actions';
import { DashboardThunks } from '../../actions/thunks/dashboard-thunks';
import { DropDownListComponent } from '../../components/DropDownList/DropDownListComponent';
import { ReportsListItem } from '../../components/ReportsList/ReportsListItem';
import { ORGANIZATTION_NAME } from '../../constants';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Selectors } from '../../selectors';
import { BuildingDataList, BuildingList, BuildingListItems } from '../../types/building';
import { ItemList } from '../../types/logbook-type';

type Props = {
    onClick: Function;
    orgList: ItemList;
};

export const ReportsBuildings: React.FC <React.PropsWithChildren<React.PropsWithChildren<Props>>> =(props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const [emptyBuildingList, setEmptyBuildingList] = React.useState(false);
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const [selectedBuilding, setSelectedBuilding] = React.useState('0');
    const [buildingList, setBuildingList] = useState<BuildingDataList>([]);
    const [projects] = useState<ItemList>(props.orgList);
    const [filterProject, setFilteredProject] = React.useState('');
    const [query, setQuery] = useState('');
    const [buildingDataLoaded, setBuildingDataLoaded] = React.useState(false);


    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setQuery(e.target.value);
    };

    const resetSearch = (): void => {
        setQuery('');
    };

    const getBuildingDataList = (list: BuildingList): BuildingDataList => {
        return list.map((data, building) => {
            return (
                {
                    buildingId: data.buildingId,
                    name: data.name,
                    parentProjectId: data.parentProjectId,
                    projectName: data.projectName,
                    city: data.city,
                    elStatus: data.elStatus,
                    elStatusPriority: data.elStatus,
                    gatewayCount: data.gatewayCount,
                    systemCount: data.systemCount,
                    isFavourite: data.isFavourite ? 1 : 0,
                }
            );
        })
    };

    useEffect(() => {
        dispatch(Thunks.Dashboard.getBuildingList(filterProject));
    }, [filterProject]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (buildingDataLoaded) {
            /* Debouncing to override user typing */
            const delayDebounceFn = setTimeout(() => {
                dispatch(DashboardThunks.getBuildingList(filterProject, '', query));
            }, 3000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            setEmptyBuildingList(dashboard.buildingList.detail.length <= 0)
            const buildingList = getBuildingDataList(dashboard.buildingList.detail);
            setBuildingList(buildingList);
            setBuildingDataLoaded(true);
        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            setEmptyBuildingList(true);
            setBuildingList([]);
        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    // Navigation for selected building to reports
    const handleDisplay = (
        selectedName: string,
        buildingId: string,
    ): void => {
        setSelectedBuilding(buildingId);
        props.onClick(selectedName, buildingId);
    };


    return (
        <div id="report-building-container">
            <div data-testid="report-buildings-search-panel" style={{ display: 'flex', marginBottom: 20 }}>

                <DropDownListComponent data-testid="buildings-project-filter" list={projects} value={t('SEARCH.ALL_ORGANIZATIONS')} handleSelection={setFilteredProject} selected={''} defaultSelection={''} ></DropDownListComponent>
                <TextField
                    inputProps={{ 'data-testid': 'search', maxLength: 100 }}
                    value={query}
                    style={{
                        width: 'auto',
                        textAlign: 'right',
                        float: 'right',
                        border: '0px solid #727E84',
                        borderRadius: 5,
                        backgroundColor: 'white',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        overflow: 'hidden',
                        marginTop: 28,
                        fontFamily: 'Open sans',
                        fontSize: 14,
                        color: '#727E84',
                        fontStyle: 'normal',
                        fontWeight: 400,
                    }}
                    placeholder={t('ORGANIZATION_MANAGE.SEARCH_BUILDINGS')}
                    onChange={(e): void => onChangeSearchText(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search data-testid="search-icon" style={{ color: PXBColors.gray[500] }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {query !== '' && (
                                    <IconButton onClick={resetSearch}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>

            {!dashboard.buildingListLoading && !emptyBuildingList && (buildingList).map((row: BuildingListItems, index: number) => (
                <ReportsListItem
                    key={index}
                    selectedItem={selectedBuilding}
                    displayClick={handleDisplay} buildingItem={row}
                >
                </ReportsListItem>
            ))}
            {dashboard.buildingListLoading && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
            icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
            {!dashboard.buildingListLoading && emptyBuildingList && <EmptyState style={{ flex: 1, height: 200 }}
            title={t('ORGANIZATION_MANAGE.ERROR_NO_BUILDING_FOUND')} icon={undefined} placeholder={undefined} />}
        </div>
    );
};


