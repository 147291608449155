import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_SERVICE_PARTNER_CUSTOMER_DETAIL_LIST } from './url-constants';

type getServicePartnerCustomersDetailListEndpointSpecifications = ApiEndpointSpecification;
export const getServicePartnerCustomersDetailListEndpointSpecification: getServicePartnerCustomersDetailListEndpointSpecifications = {
    url: GET_SERVICE_PARTNER_CUSTOMER_DETAIL_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'GetServicePartnerCustomerDetailRequestParams',
    requestBodySchemaName: 'GetServicePartnerCustomerDetailListRequestBody',
    okResponseSchemaName: 'GetServicePartnerCustomerDetailListOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeServicePartnerCustomerDetailListRequest = Helpers.makeApiRequestFn<
    Types.GetServicePartnerCustomerDetailListRequestParams,
    Types.GetServicePartnerCustomerDetailListRequestBody,
    Types.GetServicePartnerCustomerDetailListRequestOkResponse
>(getServicePartnerCustomersDetailListEndpointSpecification);
