import { InitialState } from '../state';
import { AppReducer } from './app-reducer';


export const reportReducer: AppReducer<'Report'> = (state = InitialState.Report, action): typeof state => {
    switch (action.type) {
        case 'Report/HealthReportList/Started':
            return {
                ...state,
                healthReportListLoading: true,
                healthReportListSuccess: false,
                healthReportListFail: false,
                healthReportErrorCode: '',
            };
        case 'Report/HealthReportList/Succeeded':
            return {
                ...state,
                healthReportListLoading: false,
                healthReportListSuccess: true,
                healthReportListFail: false,
                healthReportErrorCode: '',
                healthReportList: action.payload.healthReportList,
            };
        case 'Report/HealthReportList/Failed':
            return {
                ...state,
                healthReportListLoading: false,
                healthReportListSuccess: false,
                healthReportListFail: true,
                healthReportErrorCode: action.payload.errorCode,
            };
        case 'Report/HealthReportList/Unmounted':
            return {
                ...state,
                healthReportListLoading: false,
                healthReportListSuccess: false,
                healthReportListFail: false,
                healthReportErrorCode: '',
            };

        case 'Report/DownloadHealthReport/Started':
            return {
                ...state,
                downloadHealthReportLoading: true,
                downloadHealthReportSuccess: false,
                downloadHealthReportFail: false,
                downloadHealthReportErrorCode: '',
            };
        case 'Report/DownloadHealthReport/Succeeded':
            return {
                ...state,
                downloadHealthReportLoading: false,
                downloadHealthReportSuccess: true,
                downloadHealthReportFail: false,
                downloadHealthReportErrorCode: '',
                downloadHealthReport: '',
            };
        case 'Report/DownloadHealthReport/Failed':
            return {
                ...state,
                downloadHealthReportLoading: false,
                downloadHealthReportSuccess: false,
                downloadHealthReportFail: true,
                downloadHealthReportErrorCode: action.payload.errorCode,
            };
        case 'Report/DownloadHealthReport/Unmounted':
            return {
                ...state,
                downloadHealthReportLoading: false,
                downloadHealthReportSuccess: false,
                downloadHealthReportFail: false,
                downloadHealthReportErrorCode: '',
            };

        default:
            return state;
    }
};
