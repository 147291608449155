// eslint-disable-next-line
import * as React from 'react';
import * as MuiCore from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import * as ReactRouter from 'react-router';
import { AppRoute } from '../../routes';
import { pushAppRoute } from '../../data/domain/route-manager';

const ActionRow: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ label: string; appRoute: AppRoute }>>> = (props) => {
    const history = ReactRouter.useHistory();

    const onClick = (): void => {
        pushAppRoute(history, { type: 'Logbook' });
    };

    return (
        <MuiCore.List dense>
            <MuiCore.ListItem style={{ backgroundColor: 'transparent' }}>
                <MuiCore.ListItemText
                    sx={{
                            cursor: 'pointer',
                    }}
                    onClick={onClick}
                    primary={props.label}
                    primaryTypographyProps={{
                        color: 'primary',
                        style: { fontWeight: 600, fontSize: 14 },
                    }}
                />
                <MuiCore.ListItemSecondaryAction
                    sx={{
                            cursor: 'pointer',
                    }}
                >
                    <MuiCore.IconButton onClick={onClick}>
                        <MuiIcons.ChevronRight />
                    </MuiCore.IconButton>
                </MuiCore.ListItemSecondaryAction>
            </MuiCore.ListItem>
        </MuiCore.List>
    );
};

export { ActionRow };
