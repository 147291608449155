// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import {
    Typography,
    Card,
    IconButton,
} from '@material-ui/core';
import ListItem from '@mui/material/ListItem';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Close } from '@mui/icons-material';
import { ACTION_TYPE_COPY, ACTION_TYPE_NONE } from '../../constants';
import * as PXBColors from '@brightlayer-ui/colors';
import { AssetListItem } from '../../components/AssetListItem/AssetListItem';
import { OrgBuildingData } from '../../types/organization';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { ENTITY_TYPE } from '../enum/ENUM';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { buildingDetailsDefault } from '../overview/OverviewListComponent';
import { Thunks } from '../../actions';

type Props = {
    closeClick: Function;
    buildingID: string;
};

export const BuildingContactDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const history = useHistory();
    const organization = useSelector(Selectors.Organization.organization);
    const [buildingDetails, setBuildingDetails] = useState<OrgBuildingData>(buildingDetailsDefault);

    useEffect(() => {
        if (!organization.buildingListLoading && organization.buildingListSuccess) {
            setBuildingDetails(organization.buildingList[0]);

        } else if (!organization.buildingListLoading && organization.buildingListFail) {
            setBuildingDetails(buildingDetailsDefault);
        }
    }, [organization.buildingListLoading, organization.buildingListSuccess, organization.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps


    const primaryContact = buildingDetails.primaryFirstName + " " + buildingDetails.primaryLastName;
    const secondaryContact = ((buildingDetails.secondaryFirstName ? buildingDetails.secondaryFirstName : '') + " " + (buildingDetails.secondaryLastName ? buildingDetails.secondaryLastName : ''));

    function copyToClipboard(valueToCopy: string) {
        const tempInput = document.createElement('input')
        tempInput.value = valueToCopy
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        document.body.removeChild(tempInput)
    }

    function closeBuildingDetail(): void {
        props.closeClick({});
    }
    const editBuilding = (): void => {
        pushAppRoute(history,
            { type: 'EditBuilding', orgId: buildingDetails.parentProjectId, buildingId: buildingDetails.buildingId },
            { backRoute: 'OrgBuildingDetails', assetId: buildingDetails.buildingId, assetType: ENTITY_TYPE.BUILDING, title: '', orgId: buildingDetails.parentProjectId, buildingId: buildingDetails.buildingId });
    };

    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="building-details-container">
                <ListItem style={{
                    width: '100%',
                }}
                    secondaryAction={
                        <IconButton id="building-detail-close" data-testid="building-detail-close" style={{ top: -10, right: -10, marginLeft: "auto", justifyContent: 'start' }} onClick={(): void => closeBuildingDetail()}>
                            <Close />
                        </IconButton>
                    }>
                    <div>
                        <div>
                            <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600, fontStyle: 'normal', color: PXBColors.black[500], top: 24, left: 16 }} id="building-name" data-testid="buildingName" >{buildingDetails.name}</Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Typography variant="body1" style={{ fontSize: 16, fontWeight: 400, fontStyle: 'normal', color: PXBColors.black[500] }} id="buildingContactTextLabel" data-testid="buildingContactStaticTestId" >{t('DASHBOARD.BUILDING_CONTACT_DETAILS_LABEL')}</Typography>
                        </div>
                    </div>
                </ListItem>

                <AssetListItem
                    data-testid="primary-buildingContact-name"
                    key={'primary-buildingContact-name'}
                    subtitle={primaryContact}
                    title={t('DASHBOARD.MAIN_CONTACT_LABEL')}
                    imageType={ACTION_TYPE_NONE}
                    divider={false}
                    titleBold={false}
                ></AssetListItem>

                <AssetListItem
                    data-testid="building-detail-email"
                    key={'building-detail-email'}
                    subtitle={buildingDetails.email}
                    title={t('DASHBOARD.BUILDING_DETAIL_EMAIL')}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void =>
                        copyToClipboard(buildingDetails.email)
                    }
                    divider={false}
                    titleBold={false}
                ></AssetListItem>

                <AssetListItem
                    data-testid="building-detail-phone"
                    key={'building-detail-phone'}
                    subtitle={'+' + buildingDetails.countryCode + ' ' + buildingDetails.phone}
                    title={t('DASHBOARD.BUILDING_DETAIL_PHONE')}
                    imageType={ACTION_TYPE_NONE}
                    divider={true}
                    titleBold={false}
                ></AssetListItem>

                <AssetListItem
                    data-testid="building-detail-contactLable"
                    key={'building-detail-contactLable'}
                    subtitle={secondaryContact}
                    title={t('DASHBOARD.SECONDARY_CONTACT_LABEL')}
                    imageType={ACTION_TYPE_NONE}
                    divider={false}
                    titleBold={false}
                ></AssetListItem>

                <AssetListItem
                    data-testid="building-detail-secondaryEmail"
                    key={'building-detail-secondaryEmail'}
                    subtitle={buildingDetails.secondaryEmail ? buildingDetails.secondaryEmail : ''}
                    title={t('DASHBOARD.BUILDING_DETAIL_EMAIL')}
                    imageType={ACTION_TYPE_COPY}
                    OnItemSelected={(): void =>
                        copyToClipboard(buildingDetails.secondaryEmail ? buildingDetails.secondaryEmail : '')
                    }
                    divider={false}
                    titleBold={false}
                ></AssetListItem>

                <AssetListItem
                    data-testid="building-detail-secondaryPhone"
                    key={'building-detail-secondaryPhone'}
                    subtitle={buildingDetails.secondaryPhone && buildingDetails.secondaryCountryCode ? '+' + buildingDetails.secondaryCountryCode + ' ' + buildingDetails.secondaryPhone : ''}
                    title={t('DASHBOARD.BUILDING_DETAIL_PHONE')}
                    imageType={ACTION_TYPE_NONE}

                    divider={true}
                    titleBold={false}
                ></AssetListItem>


                <Button
                    style={{
                        alignItems: 'center', borderColor: PXBColors.blue[500], backgroundColor: PXBColors.white[50], color: PXBColors.blue[500], border: '1px solid rgba(0, 123, 193, 1)', borderRadius: '4px',
                        textTransform: 'capitalize', fontWeight: 600, display: 'flex', top: '8%', left: '34%', opacity: buildingDetails.isAdmin ? 1 : 0.5, width: 'auto', height: 36,
                    }}
                    id="editBuildingDetailButton"
                    data-testid="editBuildingDetailButton"
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    disabled={!buildingDetails.isAdmin}
                    onClick={(): void => editBuilding()}
                >
                    {t('DASHBOARD.EDIT_CONTACT_BUTTON')}
                </Button>
            </Card >
        </>
    );
};
