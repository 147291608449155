
export type Config = {
    envName: string;
    apiUrl: string;
    version: string;
};

export const config: Config = {
    envName: process.env.REACT_APP_ENV || '',
    apiUrl: process.env.REACT_APP_API_URL || '',
    version: process.env.REACT_APP_VERSION || '1.0.0',
};

