import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_GET_INVITATION } from './url-constants';

type GetUserInvitationEndpointSpecifications = ApiEndpointSpecification;
export const GetUserInvitationEndpointSpecification: GetUserInvitationEndpointSpecifications = {
    url: USER_GET_INVITATION,
    method: HttpMethod.GET,
    requestParamsSchemaName: 'UserInvitationRequestParams',
    requestBodySchemaName: 'UserInvitationRequestBody',
    okResponseSchemaName: 'UserInvitationOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeGetUserInvitationDetails = Helpers.makeApiRequestFn<
    Types.UserInvitationRequestParams,
    Types.UserInvitationRequestBody,
    Types.UserInvitationOkResponse
>(GetUserInvitationEndpointSpecification);
