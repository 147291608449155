import { AppReducer } from './app-reducer';
import { InitialState } from '../state';

export const signalrConnectionReducer: AppReducer<'SignalRConnection'> = (
    state = InitialState.SignalRConnection,
    action
): typeof state => {
    switch (action.type) {
        case 'SignalRConnection/Load/Started':
            return {
                ...state,
                loading: true,
                success: false,
                data: { url: '', accessToken: '' },
            };
        case 'SignalRConnection/Load/Succeeded':
            return {
                ...state,
                loading: false,
                success: true,
                data: { url: action.payload.url, accessToken: action.payload.accessToken },
            };
        case 'SignalRConnection/Load/Failed':
            return {
                ...state,
                loading: false,
                success: false,
                data: { url: '', accessToken: '' },
            };
        default:
            return state;
    }
};
