// eslint-disable-next-line
import * as React from 'react';
import {
    TreeItemProps,
    useTreeItem,
    TreeItemContentProps,
    TreeItem,
} from '@mui/x-tree-view/TreeItem';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { COLOR_GRAY, COLOR_GRAY_ICON } from '../../constants/color-codes';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useEffect } from 'react';
import { USER_ROLE_TYPE } from '../../app/enum/ENUM';

type CustomTreeItemProps = TreeItemContentProps & {
    bgColor?: string;
    bgColorForDarkMode?: string;
    color?: string;
    colorForDarkMode?: string;
    labelInfo?: string;
};

const CustomTreeContent = React.forwardRef(function CustomTreeContent(
    props: CustomTreeItemProps,
    ref,
) {
    const { t } = useLanguageLocale();
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
    } = props;

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);
    const [nodeName, setNodeName] = React.useState('');
    const [nodeSubTitle, setNodeSubTitle] = React.useState('');
    const [accessRole, setAccessRole] = React.useState(0);


    const icon = iconProp || expansionIcon || displayIcon;

    useEffect(() => {
        if (label) {
            let title = label.toString().split('#');
            setNodeName(title[0]);
            setNodeSubTitle(title[1]);
        }
        else {
            setNodeName('');
            setNodeSubTitle('');
        }
    }, [label]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (nodeId) {
            let title = nodeId.toString().split('#');
            setAccessRole(parseInt(title[1]));
        }
        else {
            setAccessRole(0);
        }
    }, [nodeId]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        preventSelection(event);
    };

    const handleExpansionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        handleExpansion(event);
    };

    const handleSelectionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        handleSelection(event);
    };

    const [role, setRole] = React.useState('web');

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newRole: string,
    ) => {
        setRole(newRole);
    };
    return (
        <div
            style={{ marginBottom: 8 }}
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}

            ref={ref as React.Ref<HTMLDivElement>}
        >
            <div data-testid="expand-icon" onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography
                onClick={handleSelectionClick}
                component="div"
                className={classes.label}
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography
                        id="tree-item-name"
                        data-testid="tree-item-name"
                        style={{ color: COLOR_GRAY, fontWeight: 600, fontSize: 16, paddingLeft: 8, }} variant="subtitle1">
                        {nodeName}
                    </Typography>
                    <Typography
                        id="tree-item-name-sub"
                        data-testid="tree-item-name-sub"
                        style={{ alignSelf: 'left', color: COLOR_GRAY, fontWeight: 400, fontSize: 14, paddingLeft: 8, paddingRight: 24 }} variant="body2">
                        {' (' + nodeSubTitle + ')'}
                    </Typography>
                </div>
            </Typography >

            <ToggleButtonGroup
                data-testid="toggle-group"
                color="info"
                sx={{ height: 24 }}
                value={role}
                exclusive
                onChange={handleChange}
                aria-label="small outlined"
            >
                <ToggleButton data-testid="toggle-limited" style={{ color: COLOR_GRAY_ICON, width: 80, textTransform: 'none' }} selected={accessRole === USER_ROLE_TYPE.LIMITED ? true : false}  disabled={label?.toString().includes("Luminaire") ? true : false} value="limited">{label?.toString().includes("Luminaire") ? <LockOutlinedIcon style={{ color: 'gray', width: 16, height: 16 }} /> : t('MANAGE_USERS.USER_ROLE_LIMITED')}  </ToggleButton>
                <ToggleButton data-testid="toggle-viewer" style={{ color: COLOR_GRAY_ICON, width: 80, textTransform: 'none' }} selected={(accessRole === USER_ROLE_TYPE.EL_ORG_VIEWER || accessRole === USER_ROLE_TYPE.EL_BLDG_VIEWER || accessRole === USER_ROLE_TYPE.EF_SYSTEM_VIEWER) ? true : false} value="viewer">{t('MANAGE_USERS.USER_ROLE_VIEWER')}</ToggleButton>
                <ToggleButton data-testid="toggle-admin" style={{ color: COLOR_GRAY_ICON, width: 80, textTransform: 'none' }} selected={(accessRole === USER_ROLE_TYPE.EF_BLDG_ADMIN_ORG_VIEWER ||  accessRole === USER_ROLE_TYPE.EL_ORG_ADMIN || accessRole === USER_ROLE_TYPE.EF_SYSTEM_ADMIN_GATEWAY_VIEWER) ? true : false} value="admin">{t('MANAGE_USERS.USER_ROLE_ADMIN')}</ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
});

export default function CustomTreeItem(props: TreeItemProps,) {
    return <TreeItem ContentComponent={CustomTreeContent} {...props} >
    </TreeItem>;
};
