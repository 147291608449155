// eslint-disable-next-line
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { PageContainer } from '../components/PageContainer';
import Legal from './legal/Legal';
import { AppRoutes } from '../routes';
import { ToastProvider } from '../data/DataProviders/ToastProvider';
import { AssetsManagement } from './assets/AssetsManagement';
import { OverviewManagement } from './overview/OverviewManagement';
import { LogbookManagement } from './logbook/LogbookManagement';
import { OrganizationManagement } from './organization-management/OrganizationManagement';
import { UsersManagement } from './manage-user/UsersManagement';
import { ReportsManagement } from './reports/ReportsManagement';
import { userDetailsManagement } from './settings/userDetailsManagement';
//import { UserManagement } from './user-management/UserManagement';

export const Content: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    // ToDo - for future reference
    //    const authState = useAuthState();
    //    const userId = getUserId(authState) || '';

    return (
        <ToastProvider>
            <PageContainer>
                <Switch>
                    <Route path={['/dashboard', '/logbook', '/devices', '/assets', '/reports','/users']}>
                        <Switch>
                            <Route path={AppRoutes.Dashboard} component={OverviewManagement} />
                            <Route path={AppRoutes.Logbook} component={LogbookManagement} />
                            <Route path={AppRoutes.Assets} component={AssetsManagement} />
                            <Route path={AppRoutes.Reports} component={ReportsManagement} />
                            <Route path={AppRoutes.ManageUsers} component={UsersManagement} />
                        </Switch>
                    </Route>
                    <Route path={['/settings', '/organizations']}>
                        <Switch>
                            <Route path={AppRoutes.Settings} component={userDetailsManagement} />
                            <Route path={AppRoutes.Organizations} component={OrganizationManagement} />
                        </Switch>
                    </Route>

                    <Route path="/legal">
                        <Legal />
                    </Route>

                    <Redirect to="/dashboard" />
                </Switch>
            </PageContainer>
        </ToastProvider>
    );
};
