// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { BuildingList } from '../../types/building';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { ALL_ORGANIZATION_API_ID, SEPERATOR_COMMA } from '../../constants';
import { OverviewList, OverviewSelectedData } from '../../types/overview-type';
import { OverviewListComponent } from './OverviewListComponent';
import { DEVICE_STATUS, ENTITY_TYPE } from '../enum/ENUM';

type Props = {
    userId?: string;
    projectId: string;
    projectName?: string;
    projectLocation?: string;
    OnBuildingSelected?: Function;
    OnUserSelected?: Function;
    OnEditSelected?: Function;
    OnSearchTextChanged?: Function;
    customerId?: string;
};

export const OverviewBuildingList: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    const { t } = useLanguageLocale();
    const [projectId, setProjectId] = React.useState(props.projectId);
    const [projectName, setProjectName] = React.useState(props.projectName);
    const [projectLocation, setProjectLocation] = React.useState(props.projectLocation);
    const [displayAllProject, setDisplayAllProject] = useState((projectId !== ALL_ORGANIZATION_API_ID));
    const [users, setUsers] = React.useState(t('MESSAGES.LOADING'));
    const { OnBuildingSelected, OnUserSelected, OnSearchTextChanged, OnEditSelected } = props;

    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const usersSelector = useSelector(Selectors.Users.users);

    const [buildingList, setBuildingList] = useState<OverviewList>([]);

    const [buildingsReady, setBuildingsReady] = useState<OverviewList>([]);
    const [buildingsAnomaly, setBuildingsAnomaly] = useState<OverviewList>([]);
    const [buildingsBlock, setBuildingsBlock] = useState<OverviewList>([]);
    const [buildingsEmpty, setBuildingsEmpty] = useState<OverviewList>([]);

    const setBuildingId = (building: OverviewSelectedData): void => {
        if (OnBuildingSelected) OnBuildingSelected(building);
    };

    const OnUserSelectedAction = (): void => {
        if (OnUserSelected) OnUserSelected();
    };

    const OnEditSelectedAction = (): void => {
        if (OnEditSelected) OnEditSelected();
    };

    const getBuildingListByStatus = (status: number, list: BuildingList): BuildingList => {
        return list?.filter((data, building) => {
            if ((status === DEVICE_STATUS.STATUS_EMPTY && data.systemCount === 0) || (data.systemCount !== 0 && data.elStatus === status)) return (
                {
                    id: data.buildingId,
                    name: data.name,
                    status: data.elStatus,
                    location: data.address + SEPERATOR_COMMA + data.city,
                    projectName: data.projectName,
                }
            );
            else {
                return null;
            }
        });
    };

    const getBuildingList = (list: BuildingList): OverviewList => {
        return list?.map((data, building) => {
            return (
                {
                    id: data.buildingId,
                    name: data.name,
                    elStatus: data.elStatus,
                    city: data.address + SEPERATOR_COMMA + data.city,
                    latitude: data.latitude,
                    longitude: data.longitude,
                    projectId: data.parentProjectId,
                    projectName: data.projectName,
                    primaryFirstName: data.primaryFirstName,
                    primaryLastName: data.primaryLastName,
                    systemCount: data.systemCount
                }
            );
        })
    };

    const updateListData = (list: BuildingList): void => {
        setBuildingList(getBuildingList(list));

        setBuildingsReady(getBuildingList(getBuildingListByStatus(DEVICE_STATUS.STATUS_READY, list)));

        setBuildingsBlock(getBuildingList(getBuildingListByStatus(DEVICE_STATUS.STATUS_BLOCK, list)));

        setBuildingsEmpty(getBuildingList(getBuildingListByStatus(DEVICE_STATUS.STATUS_EMPTY, list)));

        const buildingOffline = getBuildingList(getBuildingListByStatus(DEVICE_STATUS.STATUS_OFFLINE, list));
        const buildingOfflineDisable = getBuildingList(getBuildingListByStatus(DEVICE_STATUS.STATUS_OFFLINE_DISABLE, list));
        const buildingError = getBuildingList(getBuildingListByStatus(DEVICE_STATUS.STATUS_ERROR, list));
        const buildingWarning = getBuildingList(getBuildingListByStatus(DEVICE_STATUS.STATUS_WARNING, list));

        setBuildingsAnomaly(buildingOffline.concat(buildingOfflineDisable).concat(buildingError).concat(buildingWarning));
    };


    const updateUserListData = (adminCount: number, viewerCount: number): void => {
        const adminsText = adminCount > 0 ? (adminCount > 1 ? adminCount + ' ' + t('DASHBOARD.USERS_ADMIN_S') : adminCount + ' ' + t('DASHBOARD.USERS_ADMIN')) : '';
        const seperatorText = viewerCount > 0 ? SEPERATOR_COMMA : '';
        const viewersText = viewerCount > 0 ? (viewerCount > 1 ? viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER_S') : viewerCount + ' ' + t('DASHBOARD.USERS_VIEWER')) : '';
        setUsers(adminsText + seperatorText + viewersText)
    }

    useEffect(() => {
        setDisplayAllProject(props.projectId !== ALL_ORGANIZATION_API_ID);
        setProjectId(props.projectId);
        setProjectName(props.projectName);
        setProjectLocation(props.projectLocation);

    }, [props.projectId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            updateListData(dashboard.buildingList.detail);
        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            updateListData([]);
        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!usersSelector.userListLoading && usersSelector.userListSuccess) {
            updateUserListData(usersSelector.userList.AdminCount, usersSelector.userList.viewersCount);
        } else if (!usersSelector.userListLoading && usersSelector.userListFail) {
            updateUserListData(0, 0);
        }
    }, [usersSelector.userListLoading, usersSelector.userListSuccess, usersSelector.userListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <OverviewListComponent
            isDeviceList={false}
            data-testid="overview-building-list-container"
            userId={props.userId}
            customerId={props.customerId}
            selectId={projectId}
            selectName={projectName?projectName:''}
            selectLocation={projectLocation?projectLocation:''}
            list={buildingList}
            listReady={buildingsReady}
            listAnomaly={buildingsAnomaly}
            listBlock={buildingsBlock}
            listEmpty={buildingsEmpty}
            users={users}
            displayAllList={displayAllProject}
            listType={ENTITY_TYPE.BUILDING}
            OnItemSelected={(building: OverviewSelectedData): void => setBuildingId(building)}
            OnUserSelected={(): void => OnUserSelectedAction()}
            OnEditSelected={(): void => OnEditSelectedAction()}
            OnSearchTextChanged={(query: string): void => {
                if (OnSearchTextChanged)
                    OnSearchTextChanged(query);
            }}
            isDataLoading={dashboard.buildingListLoading} buildingID={''}>
        </OverviewListComponent>
    );
};
