import { AuthenticationState, initialAuthenticationState } from './authentication-state';
import { DashboardState, initialDashboardState } from './dashboard-state';
import { LegalState, initialLegalState } from './legal-state';
import { LocationState } from 'history';
import { NavigationState, initialNavigationState } from './navigation-state';
import { RegistrationState, initialRegistrationState } from './registration-state';
import { UserState, initialUserState } from './user-state';
import { SiganlRConnectionState, initialSiganlRConnection } from './siganlr-connection.state';
import { initialLogbookState, LogbookState } from './logbook-state';
import { AssetState, initialAssetState } from './assets-state';
import { initialOrganizationState, OrganizationState } from './organization-state';
import { initialUsersState, UsersState } from './users-state';
import { initialReportState, ReportState } from './report-state';
import { CustomerState, initialCustomerState } from './customer-state';
import { UsermanagementState, initialUsermanagementState } from './usermanagement-state';

export type State = {
    Authentication: AuthenticationState;
    Dashboard: DashboardState;
    Organization: OrganizationState;
    Legal: LegalState;
    Navigation: NavigationState;
    Registration: RegistrationState;
    User: UserState;
    Users: UsersState;
    Logbook: LogbookState;
    Asset: AssetState;
    SignalRConnection: SiganlRConnectionState;
    router: LocationState;
    Report: ReportState;
    Customer: CustomerState;
    Usermanagement: UsermanagementState

};

export const InitialState: State = {
    Authentication: initialAuthenticationState,
    Dashboard: initialDashboardState,
    Organization: initialOrganizationState,
    Legal: initialLegalState,
    Navigation: initialNavigationState,
    Registration: initialRegistrationState,
    User: initialUserState,
    Users: initialUsersState,
    Logbook: initialLogbookState,
    Asset: initialAssetState,
    Report: initialReportState,
    SignalRConnection: initialSiganlRConnection,
    Customer: initialCustomerState,
    Usermanagement: initialUsermanagementState,
    router: undefined,
};
