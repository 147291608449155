// eslint-disable-next-line
import React, { createContext, useContext } from 'react';
import { getUserId, useAuthHelper, useAuthState } from '../../components/AuthContextProvider';
import { Data, DataState } from '../../types/data-providers';
import { Role } from '../../types';
import * as UserManager from '../domain/user-manager';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ContextState = Data<Role>;

const Context = createContext<ContextState | null>(null);

function useUserRoleOperationData(): ContextState {
    const contextState = useContext(Context);
    if (contextState === null) {
        throw new Error('useUserRoleOperationData must be used within a UserRoleOperationProvider');
    }
    return contextState;
}

const UserRoleOperationProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = (props) => {
    const [state, setState] = React.useState<ContextState>({ state: DataState.Loading });
    const authState = useAuthState();
    const userId = getUserId(authState);
    const authHelper = useAuthHelper();
    React.useEffect(() => {
        if (!userId) {
            return;
        }
        const controller = new AbortController();
        (async (): Promise<void> => {
            setState({
                state: DataState.Loading,
            });
            const result = await UserManager.loadUserRoleWithOperation(authHelper, userId);

            if (result.ok) {
                setState({
                    value: result.value,
                    state: DataState.Loaded,
                });
            } else {
                setState({
                    state: DataState.Error,
                    errorMessage: result.message || 'Unable to load application data',
                });
            }
        })();
        return (): void => {
            setState({
                state: DataState.Loading,
            });
            controller.abort();
        };
    }, [userId, setState, authHelper]);

    return <Context.Provider value={state}>{props.children}</Context.Provider>;
};

const TestUserRoleDataProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ value: ContextState }>>> = (props) => (
    <Context.Provider value={props.value}>{props.children}</Context.Provider>
);

export { UserRoleOperationProvider, TestUserRoleDataProvider, useUserRoleOperationData };
