

import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_INVITE_ACCESS_DETAIL } from './url-constants';

type UserAdvanceAccessEndpointSpecification = ApiEndpointSpecification;
export const UserAdvanceAccessEndpointSpecifications: UserAdvanceAccessEndpointSpecification = {
    url: GET_INVITE_ACCESS_DETAIL,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'UserAdvanceAccessRequestParams',
    requestBodySchemaName: 'UserAdvanceAccessRequestBody',
    okResponseSchemaName: 'UserAdvanceAccessOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeUserAdvanceAccess = Helpers.makeApiRequestFn<
    Types.UserAdvanceAccessRequestParams,
    Types.UserAdvanceAccessRequestBody,
    Types.UserAdvanceAccessOkResponse
>(UserAdvanceAccessEndpointSpecifications);
