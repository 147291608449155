// eslint-disable-next-line
import React, { useReducer } from 'react';
import { ConnectionStatus } from '../../types/signalr-types';
import { useAuthHelper } from '../../components/AuthContextProvider';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useSelector, useDispatch } from 'react-redux';
import { Selectors } from '../../selectors';
import { Thunks, Actions } from '../../actions';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const reducer = (state: ConnectionStatus, partialState: Partial<ConnectionStatus>) => ({ ...state, ...partialState });

const initialState = {
    connection: null,
    error: null,
};
const Context = React.createContext<ConnectionStatus>({
    connection: null,
    error: null,
});

function useSignalRConnection(): ConnectionStatus {
    const contextState = React.useContext(Context);
    if (contextState === null) {
        throw new Error('useSignalRConnection must be used within a SignalRConnectionProvider');
    }
    return contextState;
}

const SignalRConnectionProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = (props) => {
    const [state, setState] = useReducer(reducer, initialState);
    const authHelper = useAuthHelper();
    const dispatch = useDispatch();

    //ToDo - Handle SignalR - Notification API call
    React.useEffect(() => {
        dispatch(Thunks.SignalRConnection.getSignalRConnection(authHelper));

        return (): void => {
            dispatch(Actions.SignalRConnection.SignalRConnectionFailed());
        };
    }, [authHelper, dispatch]);

    const ConnectionData = useSelector(Selectors.SignalRConnection.signalrConnectionData);

    React.useEffect(() => {
        if (ConnectionData.url !== '' && process.env.NODE_ENV !== 'test') {
            const connection = new HubConnectionBuilder()
                .withUrl(ConnectionData.url, { accessTokenFactory: () => ConnectionData.accessToken })
                .configureLogging(LogLevel.Error)
                .build();

            connection
                .start()
                .then((): void => setState({ connection }))
                .catch((error) => setState({ error }));
            return (): void => {
                connection.stop();
            };
        }
    }, [ConnectionData.url, setState, ConnectionData.accessToken]);
    return <Context.Provider value={state}>{props.children}</Context.Provider>;
};

export { SignalRConnectionProvider, useSignalRConnection };
