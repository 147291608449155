import { State } from '../state';

export const DashboardSelectors = {
    isAutoRedirectBannerShown: (state: State): boolean => state.Dashboard.autoRedirectBannerShown,
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    trendLinesEnabled: (state: State) => state.Dashboard.trendLinesEnabled,
    trendCardDays: (state: State): number => state.Dashboard.trendCardDays,
    hoveredTrendline: (state: State): { channelId: number; deviceId: string } | undefined =>
        state.Dashboard.hoveredTrendline,
    inActiveLogOut: (state: State): boolean => state.Dashboard.inActiveLogOut,
    isRefresh: (state: State): boolean => state.Dashboard.isLocationRefresh,
    
    dashboard: (state: State) => state.Dashboard,
    asset: (state: State) => state.Asset,
    logbook: (state: State) => state.Logbook,
    users: (state: State) => state.Users,
    customer: (state:State) => state.Customer,
};
