import { OutgoingHttpHeaders } from 'http';
import { v4 as uuidv4 } from 'uuid';
import { config } from '../app-config';
import { SessionStorage } from '../components/AuthContextProvider/session-storage';
import { makeWaitForTurn } from '../lib';

const ConcurrentInFlightRequests = 5;
const InFlightRequestCheckInterval = 100;

export const inFlightRequests: { [k: string]: Promise<unknown> } = {};
const waitForTurn = makeWaitForTurn(inFlightRequests, ConcurrentInFlightRequests, InFlightRequestCheckInterval);

export enum HttpMethod {
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    GET = 'GET',
    DELETE = 'DELETE',
}

export type BaseHttpResponse<T> = {
    status: number;
    response: T;
};

type HttpRequestParams<TBody = unknown> =
    | {
        method: 'GET';
    }
    | {
        method: 'DELETE';
    }
    | {
        method: 'POST';
        body: TBody;
    }
    | {
        method: 'PUT';
        body: TBody;
    };

type HttpRequestBase = {
    resource: string;
};

type HttpRequestOptions = {
    responseType?: 'text' | 'json';
    headers?: OutgoingHttpHeaders;
    prependApiUrl?: boolean;
    version?: number;
};

export type HttpResponse<TResponse = unknown> = {
    status: number;
    response: TResponse;
};

// eslint-disable-next-line
const request = async <TBody = unknown, TResponse = unknown>(
    args: HttpRequestParams & HttpRequestBase & HttpRequestOptions
): Promise<{ status: number; response: TResponse; }> => {
    const requestUuid = uuidv4().toString();

    // For most usages of HttpRequest we'll want to prepend the correct API hostname and
    // base path. But to allow for pulling content from other locations (not the API), it's
    // possible to disable the prepend by passing in `prependApiUrl` as false.

    const resource = args.prependApiUrl === false ? args.resource : `${config.apiUrl}${args.resource}`;
    const value = args?.headers || {};
    const header: string = JSON.stringify(value);

    const req = fetch(resource, {
        method: args.method,
        credentials: 'include',
        headers: {
            ...('body' in args ? { 'Content-Type': 'application/json', 'Authorization': SessionStorage.getAuthToken() } : { 'Authorization': SessionStorage.getAuthToken() }),
            ...{ version: args.version !== undefined ? args.version.toFixed(1) : '2.0' },
            header
        },
        ...('body' in args
            ? {
                body: JSON.stringify(args.body),
            }
            : {}),
    });

    await waitForTurn();

    // eslint-disable-next-line require-atomic-updates
    inFlightRequests[requestUuid] = req;

    try {
        const response = await req;

        //Handle Download scenario for pdf, csv, xls
        var contentType = response.headers.get("Content-Type");
        if (contentType?.includes("pdf") || contentType?.includes("csv") || contentType?.includes("xlsx")) {
            const blob = new Blob([await response.blob()], { type: contentType ? contentType : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const filename = (contentType.split(';')[1]).trim();
            const anchorLinkTag = document.createElement('a');
            anchorLinkTag.download = filename;
            anchorLinkTag.href = window.URL.createObjectURL(blob)
            const clickEvt = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
            })
            anchorLinkTag.dispatchEvent(clickEvt);
            anchorLinkTag.remove();
        }

        return {
            status: response.status,
            response: args.responseType === 'text' ? await response.text() : await response.json(),
        };


    } catch (error) {
        if (process.env.NODE_ENV !== 'test') {
            console.error('HttpRequest: request', { resource, req, error });
        }

        return {
            status: 0,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            response: (error as Error).message as any,
        };
    } finally {
        delete inFlightRequests[requestUuid];
    }
};

export const HttpRequest = {
    request,

    post: <TBody = {}>(
        args: {
            resource: string;
            body: TBody;
            version?: number;
        } & HttpRequestOptions
    ): Promise<HttpResponse> => request<TBody>({ method: 'POST', ...args }),

    put: <TBody = {}>(
        args: { resource: string; body: TBody; version?: number } & HttpRequestOptions
    ): Promise<HttpResponse> => request<TBody>({ method: 'PUT', ...args }),

    get: <TResponse>(
        args: { resource: string; version?: number } & HttpRequestOptions
    ): Promise<HttpResponse<TResponse>> => request<never, TResponse>({ method: 'GET', ...args }),

    delete: <TBody = {}>(
        args: { resource: string; body: TBody; version?: number } & HttpRequestOptions
    ): Promise<HttpResponse> => request({ method: 'DELETE', ...args }),
};
