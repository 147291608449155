import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { LOG_ACKNOWLEDGE } from './url-constants';

type AcknowledgeLogEndpointSpecification = ApiEndpointSpecification;
export const AcknowledgeLogEndpointSpecifications: AcknowledgeLogEndpointSpecification = {
    url: LOG_ACKNOWLEDGE,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'LogAcknowledgeRequestParams',
    requestBodySchemaName: 'LogAcknowledgeRequestBody',
    okResponseSchemaName: 'LogAcknowledgeRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeLogAcknowledgeRequest = Helpers.makeApiRequestFn<
    Types.LogAcknowledgeRequestParams,
    Types.LogAcknowledgeRequestBody,
    Types.LogAcknowledgeRequestOkResponse
>(AcknowledgeLogEndpointSpecifications);
