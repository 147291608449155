// eslint-disable-next-line
import React from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { AccountCircle, ArrowDropDown, ArrowDropUp, ExitToApp, VpnKey } from '@material-ui/icons';
import { Chip } from './Chip';
import * as PXBColors from '@brightlayer-ui/colors';
import { Thunks } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId, useAuthHelper, useAuthState } from '../AuthContextProvider';
import { useHistory } from 'react-router';
import { Typography, useMediaQuery } from '@mui/material';
import { Selectors } from '../../selectors';
import { pushAppRoute } from '../../data/domain/route-manager';
import { AccountSettings } from '@brightlayer-ui/icons-mui';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { USER_THEME } from '../../constants';
import { getStyleColors, ToolbarColor } from '../GenericToolbar/GenericToolbar';
import { getControlledText } from '../../app/enum/GenericFormat';



const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

type materialColor = 'inherit' | 'primary' | 'secondary' | 'default';

const getUserTheme = (
  color: string
): { themeColor: materialColor; foregroundColor: string; backgroundColor: string } => {
  switch (color) {
    case 'error':
      return getStyleColors(ToolbarColor.Error);
    case 'primary':
      return getStyleColors(ToolbarColor.Primary);
    case 'default':
      return getStyleColors(ToolbarColor.Default);
    default: return getStyleColors(ToolbarColor.Primary);
  }
};

export default function CustomizedMenus() {
  const dispatch = useDispatch();
  const { t } = useLanguageLocale();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const authHelper = useAuthHelper();
  const history = useHistory();
  const authState = useAuthState();
  const userId = getUserId(authState);
  const userData = useSelector(Selectors.User.user);
  const user = userData;
  const logoutSession = React.useCallback(() => {
    dispatch(Thunks.Authentication.logout(userId, history, authHelper.onUserNotAuthenticated, authHelper));
  }, [dispatch, history, userId, authHelper.onUserNotAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const userTheme = sessionStorage.getItem(USER_THEME);
  const userSelectedTheme = getUserTheme(userTheme === null ? 'Primary' : userTheme);
  const isDefaultTheme: boolean = userSelectedTheme.themeColor === 'default';

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setChipToggled(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setChipToggled(false);
  };

  const handleProfileClick = () => {
    pushAppRoute(history, { type: 'Settings', changeId: '0' });
  }

  const handleProfileChangePasswordClick = () => {
    pushAppRoute(history, { type: 'Settings', changeId: '1' });
  }

  const [chipToggled, setChipToggled] = React.useState(false);


  return (
    <div >
      <Chip
        data-testid="user-menu-chip"
        style={{ backgroundColor: isDefaultTheme ? PXBColors.gray[50] : 'transparent', borderRadius: 15 }}
        variant={isDefaultTheme ? 'filled' : 'outlined'}

        icon={<AccountCircle style={{ color: userSelectedTheme.foregroundColor, marginLeft: 5 }} />}
        onClick={handleClick}
        rightIcon={chipToggled ? (
          <ArrowDropUp fontSize={"small"} style={{ marginLeft: md ? 10 : 1, marginTop: 5, color: isDefaultTheme ? PXBColors.gray[500] : PXBColors.white[500] }} />
        ) : (
          <ArrowDropDown fontSize={"small"} style={{ marginLeft: md ? 10 : 1, marginTop: 5, color: isDefaultTheme ? PXBColors.gray[500] : PXBColors.white[500] }} />
        )}
        highlight={chipToggled}
        label={md ? (getControlledText(user.userDetail.firstname, 20) + ' ' + getControlledText(user.userDetail.lastname, 20)) : ''}
        foregroundColor={userSelectedTheme.foregroundColor}
        backgroundColor={userSelectedTheme.backgroundColor} />
      <StyledMenu
        id="demo-customized-menu"
        data-testid="sytled-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div style={{ marginTop: 10, marginBottom: 10, marginLeft: 20, marginRight: 70 }}>
          <Typography style={{ fontWeight: 600, fontSize: 14 }}>{user.userDetail.firstname + ' ' + user.userDetail.lastname}</Typography>
          <Typography style={{ fontSize: 14 }}>{user.userDetail.email_id}</Typography>
        </div>
        <Divider style={{ color: '#727E84' }} />
        <MenuItem
          data-testid="change-password-menu-option"
          style={{ minHeight: 48, fontSize: 16, display: 'block', padding: 0, paddingTop: 10 }}
          onClick={handleProfileChangePasswordClick} disableRipple>
          <VpnKey style={{ marginLeft: 15, marginRight: 25, color: '#727E84', verticalAlign: 'middle' }} />
          {t('CHANGE_PASSWORD.PASSWORD')}
        </MenuItem>
        <MenuItem
          data-testid="account-setting-menu-option"
          style={{ minHeight: 48, fontSize: 16, display: 'block', padding: 0, paddingTop: 10 }}
          onClick={handleProfileClick} disableRipple>
          <AccountSettings style={{ marginLeft: 15, marginRight: 25, color: '#727E84', verticalAlign: 'middle' }} />
          {t('USER_MENU.ACCOUNT_SETTING')}
        </MenuItem>
        <MenuItem
          data-testid="logout-menu-option"
          style={{ minHeight: 48, fontSize: 16, display: 'block', padding: 0, paddingTop: 10 }}
          onClick={logoutSession}>
          <ExitToApp style={{ marginLeft: 15, marginRight: 25, color: '#727E84', verticalAlign: 'middle' }} />
          {t('USER_MENU.LOG_OUT')}
        </MenuItem>
      </StyledMenu>

    </div>


  );
}
