// eslint-disable-next-line
import React from 'react';
import {
    DialogActions,
    Button,
    DialogTitle,
    DialogContent,
    Typography,
    Dialog,
} from '@mui/material';

import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { UserInvitationData } from '../../types/manage-users';
import * as PXBColors from '@brightlayer-ui/colors';
import { AcceptInviteCard } from './AcceptInviteCard';

type DialogProps = {
    show: boolean;
    inviteType: number;
    title: string;
    description1: string;
    description2: string;
    actionText: string;
    closeDialog: Function;
    actionClick: Function;
    inviteData?: UserInvitationData;
};

export const InviteConfirmationDialog: React.FC<React.PropsWithChildren<React.PropsWithChildren<DialogProps>>> = (props) => {
    const { t } = useLanguageLocale();

    const actionClick = (): void => {
        props.closeDialog();
        props.actionClick({ inviteType: props.inviteType });
    };

    const actionCancel = (): void => {
        props.closeDialog();
    };

    return (
        <>
            <Dialog
                disableEscapeKeyDown={true}
                open={props.show}
                onClose={actionCancel}
                aria-labelledby="invite-history-dialog"
                maxWidth="md"
                data-testid="invite-history-dialog"
            >
                <DialogTitle id="invite-history-dialog-title" data-testid="invite-history-dialog-title" >{props.title}</DialogTitle>
                <DialogContent>
                    <>
                        <div data-testid='AcceptInviteCardId'>
                            <Typography variant="body2" component="p" style={{ fontSize: 16 }}>
                                {props.description1}
                            </Typography>
                            <Typography variant="body2" component="p" style={{ marginBottom: 10, marginTop: 20, fontSize: 16 }}>
                                {props.description2}
                            </Typography>

                            {props.inviteData !== undefined && <AcceptInviteCard userInvitationData={props.inviteData} onCheckboxClick={() => { }} isSelectedAll={false} hideCheckBox={true} />}
                        </div>
                    </>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'float', marginBottom: 5, marginRight: 20 }}>
                    <Button
                        style={{
                            borderColor: PXBColors.red[500],
                            backgroundColor: PXBColors.white[50],
                            color: PXBColors.red[500],
                        }}
                        id="btnCancel"
                        data-testid="decline"
                        variant="outlined"
                        color="primary"
                        onClick={actionCancel}>
                        {t('ACTIONS.CANCEL')}
                    </Button>
                    <Button
                        id="btnClick"
                        data-testid="join"
                        variant="contained"
                        color="primary"
                        onClick={actionClick}>
                        {props.actionText}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
