// eslint-disable-next-line
import React from 'react';
import { Data } from '../../types/data-providers';
import { DataErrorIndicator } from '../DataErrorIndicator';
import { DataLoadingIndicator } from '../DataLoadingIndicator';
import { DataEmptyIndicator } from '../DataEmptyIndicator';
import { Icon } from '../../lib/icons';

export const DataIndicators: React.FC<React.PropsWithChildren<React.PropsWithChildren<{
    errorMessage: string;
    emptyIcon: Icon;
    emptyMessage: string;
    isEmpty: boolean;
    data: Data;
    inline?: boolean;
    loadingComponent?: React.ReactElement;
    isDetailedInfo?: boolean;
    withAction?: boolean;
    action?: JSX.Element;
    description?: string;
}>>> = (props) => (
    <>
        <DataErrorIndicator
            message={props.errorMessage}
            data={props.data}
            inline={props.inline}
            isDetailedInfo={props.isDetailedInfo}
            withAction={props.withAction}
            action={props.action}
            icon={props.emptyIcon}
            description={props.description}
        />
        <DataLoadingIndicator data={props.data} inline={props.inline} loadingComponent={props.loadingComponent} />
        <DataEmptyIndicator
            icon={props.emptyIcon}
            message={props.emptyMessage}
            data={props.data}
            isEmpty={props.isEmpty}
            inline={props.inline}
            isDetailedInfo={props.isDetailedInfo}
            withAction={props.withAction}
            action={props.action}
            description={props.description}
        />
    </>
);
