// eslint-disable-next-line
import React from 'react';
import {  DialogActions, Button, DialogTitle, DialogContent, Divider, Typography, Dialog } from '@mui/material';

type DialogProps = {
    icon?: JSX.Element;
    title?: string;
    titleColor?:
        | 'inherit'
        | 'initial'
        | 'primary'
        | 'secondary'
        | 'textPrimary'
        | 'textSecondary'
        | 'error'
        | undefined;
    body: JSX.Element | string;
    okButtonText?: string;
    okButtonShow?: boolean;
    cancelButtonsShow?: boolean;
    cancelButtonText?: string;
    show: boolean;
    width: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
    closeDialog?: Function;
    handleOk?: Function;
    backgroundColor?: string;
};
const setOkButtonVisiblity = (okButtonShow: boolean | undefined): boolean => (okButtonShow ? true : false);
const setCancelButtonVisiblity = (cancelButtonShow: boolean | undefined): boolean => (cancelButtonShow ? true : false);

export const ConfirmationPopUp: React.FC<React.PropsWithChildren<React.PropsWithChildren<DialogProps>>> = (props): JSX.Element => {
    const handleClose = (): void => {
        if (props.closeDialog) props.closeDialog();
    };
    const handleOk = (): void => {
        if (props.handleOk) props.handleOk(true);
    };
    return (
        <Dialog
            disableEscapeKeyDown={true}
            open={props.show}
            onClose={handleClose}
            data-testid="confirmation-popup"
            maxWidth={props.width}
            fullWidth={true}
        >
            <DialogTitle id="dialog-title">
                <div style={{ textAlign: 'center' }}>{props.icon}</div>
                <div>
                    <Typography variant="h6" color={props.titleColor !== undefined ? props.titleColor : undefined}>
                        {props.title}
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent>{props.body}</DialogContent>
            <Divider />
            <DialogActions
                style={{ justifyContent: setCancelButtonVisiblity(props.cancelButtonsShow) ? 'space-between' : '' }}
            >
                {setCancelButtonVisiblity(props.cancelButtonsShow) && (
                    <Button
                        autoFocus
                        color="primary"
                        variant="outlined"
                        onClick={handleClose}
                        data-testid="cancel-action"
                    >
                        {props.cancelButtonText}
                    </Button>
                )}
                {setOkButtonVisiblity(props.okButtonShow) && (
                    <Button
                        autoFocus
                        color="primary"
                        style={{ backgroundColor: props.backgroundColor }}
                        variant="contained"
                        onClick={handleOk}
                        data-testid="ok-action"
                    >
                        {props.okButtonText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
