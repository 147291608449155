import * as ApiRequests from '../../api/requests';
import { AuthHelper } from '../../types';
import { AppThunk } from '../actions';
import { CustomerActions } from '../actions/customer-actions';

export const CustomerThunks = {
    getDashboardCustomerList: (): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(CustomerActions.dashboardCustomerListDataStarted());

        const payload = await ApiRequests.getCustomerList('', { authHelper: undefined });

        if (payload.ok) {
            dispatch(
                CustomerActions.dashboardCustomerListDataSucceeded(payload.response.data)
            );
        } else {
            dispatch(CustomerActions.dashboardCustomerListDataFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },
    linkCustomer: (
        customerNumber: string,
        systemId: string,
        SLANumber: string,
        expiryDate: string,
        luminariesNotPartOfSLA: boolean,
        authHelper: AuthHelper
    ): AppThunk => async (
        dispatch
    ): Promise<void> => {
            dispatch(CustomerActions.linkCustomerStarted());

            const payload = await ApiRequests.linkCustomer(
                {
                    customerNumber: customerNumber,
                    systemId: systemId,
                    SLANumber: SLANumber,
                    expiryDate: expiryDate,
                    luminariesNotPartOfSLA: luminariesNotPartOfSLA
                },
                { authHelper: authHelper }
            );

            if (payload.ok) {
                dispatch(
                    CustomerActions.linkCustomerSucceeded(payload.response.data.customerNumber)
                );
            } else {
                dispatch(CustomerActions.linkCustomerFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
            }
        },
    getCustomerListV2: (projectId: string,buildingId?: string,offset?: string,limit?: string,searchText?: string): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(CustomerActions.customerListDataStarted());

        const payload = await ApiRequests.getCustomerListV2({ projectId: projectId, buildingId: buildingId,offset: offset,limit: limit,searchText: searchText }, { authHelper: undefined });
        if (payload.ok) {
            dispatch(
                CustomerActions.customerListDataSucceeded(payload.response.data)
            );
        } else {
            dispatch(CustomerActions.customerListDataFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },
};
