// eslint-disable-next-line
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppRoutes } from '../../routes';
import { ManageUser } from '../user-management/ManageUser';
import { ManageAssetUsers } from './ManageAssetUsers';
import { CreateServicePartner } from '../user-management/CreateServicePartner';
import { ServicePartnerDetails } from './ServicePartnerDetails';
import { PassDetails } from './PassDetails';
//import { ManageUsers } from './ManageUsers';

export const UsersManagement: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => (
    <>
        <Switch>
            {/* ToDo - Remove duplicate unused file - waiting for developer to confirm references
        <Route exact path={AppRoutes.ManageUsers} component={ManageUsers} /> */}
            <Route exact path={AppRoutes.ManageUsers} component={ManageUser} />
            <Route exact path={AppRoutes.ManageAssetUsers} component={ManageAssetUsers} />
            <Route exact path={AppRoutes.ServicePartnerDetails} component={ServicePartnerDetails} />
            <Route exact path ={AppRoutes.PassDetails} component={PassDetails} />
            <Route exact path={AppRoutes.CreateServicePartner} component={CreateServicePartner} />
        </Switch>
    </>
);
