import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_HEALTH_REPORT } from './url-constants';

type HealthReportEndpointSpecifications = ApiEndpointSpecification;
export const HealthReportEndpointSpecification: HealthReportEndpointSpecifications = {
    url: GET_HEALTH_REPORT,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'HealthReportParams',
    requestBodySchemaName: 'HealthReportRequestBody',
    okResponseSchemaName: 'HealthReportRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeHealthReport = Helpers.makeApiRequestFn<
    Types.HealthReportRequestParams,
    Types.HealthReportRequestBody,
    Types.HealthReportRequestOkResponse
>(HealthReportEndpointSpecification);
