import { State } from '../state';

export const UserSelectors = {
    email: (state: State): string =>
        (state.User && state.User.userDetail.email_id && state.User.userDetail && state.User.userDetail.email_id) ||
        '',

    name: (state: State): string =>
        state.User && state.User.loggedInUser.user
            ? `${state.User.loggedInUser.user.firstname} ${state.User.loggedInUser.user.lastname}`
            : '',

    initials: (state: State): string =>
        state.User &&
            state.User.loggedInUser.user &&
            state.User.loggedInUser.user.firstname &&
            state.User.loggedInUser.user.lastname
            ? `${state.User.loggedInUser.user.firstname.charAt(0)}${state.User.loggedInUser.user.lastname.charAt(0)}`
            : '',

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    changePassword: (state: State) => state.User.changePassword,

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    user: (state: State) => state.User,

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    session: (state: State): string =>
    state.User&&
    state.User.userDetail&&
    state.User.userDetail.isAutologoutEnabled

};
