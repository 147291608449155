//Supported browsers : chrome, firefox , IE EDGE and safari
export function isSupportedBrowser(): boolean {
    const hasOperaAndEdge = navigator.userAgent.indexOf('OPR') === -1 && navigator.userAgent.indexOf('Edg') === -1; //eslint-disable-line  @typescript-eslint/prefer-includes
    const isChrome = navigator.userAgent.indexOf('Chrome') !== -1 && hasOperaAndEdge; //eslint-disable-line  @typescript-eslint/prefer-includes
    const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && hasOperaAndEdge; //eslint-disable-line  @typescript-eslint/prefer-includes
    const value =
        navigator.userAgent.indexOf('Firefox') !== -1 || //eslint-disable-line  @typescript-eslint/prefer-includes
        navigator.userAgent.indexOf('Edg') !== -1 || //eslint-disable-line  @typescript-eslint/prefer-includes
        isChrome ||
        isSafari ||
        navigator.userAgent.indexOf('jsdom') !== -1; //eslint-disable-line  @typescript-eslint/prefer-includes

    return value;
}
