// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import PinReadyActive from '../../assets/images/map/Pin_Ready_Active.svg';
import { BuildingListMarker } from '../../types/building';
import PinWarningActive from '../../assets/images/map/Pin_Warning_Active.svg';
import PinOfflineActive from '../../assets/images/map/Pin_Offline_Active.svg';
import PinOfflineInactive from '../../assets/images/map/Pin_Offline_Inactive.svg';
import PinErrorActive from '../../assets/images/map/Pin_Error_Active.svg';
import PinBlockedActive from '../../assets/images/map/Pin_Blocked_Active.svg';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { CircularProgress, Typography } from '@mui/material';
import { StatusTag } from '../StatusTag/StatusTag';
import { Status } from '../../types';
import { EmptyState } from '@brightlayer-ui/react-components';
import { DEVICE_STATUS } from '../../app/enum/ENUM';

type Props = {
    buildings: BuildingListMarker;
    buildingId: string;
    OnBuidlingMarkerClicked: Function;
};

const MapContainer = (props: Props) => {
    const { t } = useLanguageLocale();
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [center, setCenter] = React.useState<google.maps.LatLngLiteral>();
    const [zoom, setZoom] = useState(9);
    const [activeMarker, setActiveMarker] = useState(null);
    const [markersList, setMarkersList] = useState<BuildingListMarker>(props.buildings);
    const [isLoadState, setIsLoad] = useState(false);

    const handleActiveMarker = (marker: any, position: any) => {
        setCenter(position);
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const handleActiveMarkerClicked = (id: string, parentProjectId: string) => {
        props.OnBuidlingMarkerClicked(id, parentProjectId);
    }

    useEffect(() => {
        setMarkersList(props.buildings);
        if (props.buildings.length > 0)
            setIsDataLoaded(true);
    }, [props.buildings]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isDataLoaded) {
            if (props.buildingId !== '' && markersList.length > 0) {
                setCenter(
                    markersList.filter(function (item) {
                        return item.id === props.buildingId;
                    })[0].position
                );
                setZoom(9);
            }
            setIsLoad(true);
        }
    }, [isDataLoaded, props.buildings]); // eslint-disable-line react-hooks/exhaustive-deps

    // Set Marker icon according to status of building

    const getMarkerIcon = (status: number) => {
        switch (status) {
            case DEVICE_STATUS.STATUS_READY:
                return PinReadyActive;
            case DEVICE_STATUS.STATUS_WARNING:
                return PinWarningActive;
            case DEVICE_STATUS.STATUS_BLOCK:
                return PinBlockedActive;
            case DEVICE_STATUS.STATUS_OFFLINE:
                return PinOfflineActive;
            case DEVICE_STATUS.STATUS_OFFLINE_DISABLE:
                return PinOfflineInactive;
            case DEVICE_STATUS.STATUS_ERROR:
                return PinErrorActive;
            default:
                return PinOfflineInactive;
        }
    };

    const getStatus = (status: number) => {
        switch (status) {
            case DEVICE_STATUS.STATUS_READY:
                return Status.Ready;
            case DEVICE_STATUS.STATUS_WARNING:
                return Status.Warning;
            case DEVICE_STATUS.STATUS_BLOCK:
                return Status.Block;
            case DEVICE_STATUS.STATUS_OFFLINE:
                return Status.Offline;
            case DEVICE_STATUS.STATUS_OFFLINE_DISABLE:
                return Status.Offline;
            case DEVICE_STATUS.STATUS_ERROR:
                return Status.Error;
            default:
                return Status.InActive;
        }
    };

    // // Iterate myPlaces to size, center, and zoom map to contain all markers
    const fitBounds = (map: { fitBounds: (arg0: google.maps.LatLngBounds) => void }) => {
        const bounds = new window.google.maps.LatLngBounds();
        markersList.map((item) => {
            bounds.extend(item.position);
            return item.id;
        });
        map.fitBounds(bounds);
    };

    const loadHandler = (map: any) => {
        // Fit map bounds to contain all markers
        fitBounds(map);
        var listener = google.maps.event.addListener(map, "idle", function () {
            if (map.getZoom() > 12) map.setZoom(12);
            google.maps.event.removeListener(listener);
        });
    };

    return (
        <>
            {!isDataLoaded && (
                <div style={{ height: '100vh', width: '100%' }}>
                    <EmptyState icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />
                </div>
            )}

            {isDataLoaded && isLoadState && (
                <GoogleMap
                    onLoad={loadHandler}
                    center={center}
                    zoom={zoom}
                    onClick={() => setActiveMarker(null)}
                    mapContainerStyle={{ height: '100vh', width: '100%' }}
                    options={{ streetViewControl: false, zoomControlOptions: { position: 9 } }}
                    data-testid="google-map-container"
                >
                    {markersList.map(({ id, name, position, elStatus, city, address, systemCount, parentProjectId }) => (
                        <Marker
                            key={id}
                            position={position}
                            onClick={() => handleActiveMarker(id, position)}
                            icon={getMarkerIcon(elStatus)}
                            data-testid="marker-selected"
                        >
                            {activeMarker === id ? (
                                <InfoWindow onCloseClick={() => setActiveMarker(null)} data-testid="marker-details">
                                    <div>
                                        <Typography variant="body2" style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => handleActiveMarkerClicked(id, parentProjectId)}>
                                            <b>{name}</b>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            style={{ fontSize: 14 }}
                                            id="system-count"
                                            data-testid="project-name"
                                        >
                                            {systemCount} {systemCount > 1 ? t('MAP.SYSTEMS') : t('MAP.SYSTEM')}
                                        </Typography>
                                        <Typography variant="body2" style={{ fontSize: 14 }} id="marker-location">
                                            {address}, {city}
                                        </Typography>
                                        <StatusTag
                                            status={getStatus(elStatus).toString() || Status.InActive}
                                            style={{ marginRight: 5 }}
                                        />
                                    </div>
                                </InfoWindow>
                            ) : null}
                        </Marker>
                    ))}
                </GoogleMap>
            )}
        </>
    );
};

export default MapContainer;
