export const selectStyle: any = {
    dropdown:
    {
        display: 'flex',
        justifyContent: 'left', fontSize: 14,
        lineHeight: 1.5, fontWeight: 400,
        overflow: 'hidden',
        boxSizing: 'border-box',
        whiteSpace: 'nowrap',
        minHeight: 48,
        paddingTop: 6,
        paddingBottom: 6,
        width: 'auto',
        paddingLeft: 16,
        paddingRight: 16
    }
}
