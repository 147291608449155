// eslint-disable-next-line
import React, { useState } from 'react';
import { CardContent, Typography, Divider } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { SetPasswordControl } from '../../components/SetPasswordControl/SetPasswordControl';

type Props = {
    password?: string;
    errorMessage: string;
    changePassword?: Function;
    changeNextEnabled?: Function;
    changeNextLabel?: Function;
};

export const SetPassword: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();

    const { password, errorMessage, changePassword, changeNextEnabled, changeNextLabel } = props;
    const [passwordValid, setPasswordsValid] = useState(false);

    if (changeNextEnabled) {
        changeNextEnabled(passwordValid);
    }
    if (changeNextLabel) {
        changeNextLabel(t('ACTIONS.NEXT'));
    }

    return (
        <>
            <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }}>
                <Typography variant="body1" component="p" style={{ marginBottom: 50 }}>
                    {t('CHANGE_PASSWORD.PASSWORD_INFO')}
                </Typography>
                <Divider style={{ margin: '30px 0px' }} />
                <SetPasswordControl
                    password={password}
                    passwordValidate={(value: boolean): void => setPasswordsValid(value)}
                    onPasswordChange={(newPassword: string): void => {
                        if (changePassword) {
                            changePassword(newPassword);
                        }
                    }}
                />
                <Typography variant="body1" component="p" style={{ marginBottom: 50, marginTop: 30, color: 'red' }}>
                    {errorMessage}
                </Typography>
            </CardContent>
        </>
    );
};
