// eslint-disable-next-line
import React from 'react';
import { Data, DataState } from '../../types/data-providers';
import { CircularProgress } from '@mui/material';
import { EmptyState } from '@brightlayer-ui/react-components';
import { CenteredContent } from '../CenteredContent';

export const DataLoadingIndicator: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ data: Data; inline?: boolean; loadingComponent?: React.ReactElement }>>> = (
    props
) => {
    if (props.data.state !== DataState.Loading) {
        return null;
    }

    if (props.loadingComponent) {
        return <>{props.loadingComponent}</>;
    }

    const component = <EmptyState icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />;

    if (props.inline) {
        return (
            <div data-testid="loading-indicator" style={{ marginTop: '30px', marginBottom: '20px' }}>
                {component}
            </div>
        );
    }

    return <CenteredContent data-testid="loading-indicator">{component}</CenteredContent>;
};
