import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';

type DeviceMasterDataEndpointSpecification = ApiEndpointSpecification;
export const DeviceMasterDataEndpointSpecifications: DeviceMasterDataEndpointSpecification = {
    url: '/api/device/masterdata',
    method: HttpMethod.POST,
    requestParamsSchemaName: 'DeviceMasterDataRequestParams',
    requestBodySchemaName: 'DeviceMasterDataRequestBody',
    okResponseSchemaName: 'DeviceMasterDataOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeDeviceMaster = Helpers.makeApiRequestFn<
    Types.DeviceMasterDataRequestParams,
    Types.DeviceMasterDataRequestBody,
    Types.DeviceMasterDataOkResponse
>(DeviceMasterDataEndpointSpecifications);
