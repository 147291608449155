import { createStore, applyMiddleware } from 'redux';
import Reducer from '../reducers';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { State, InitialState } from '../state';

export const history = createBrowserHistory();
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const configureStore = (state?: Partial<State>) => {
    const initialState: any = { ...InitialState, ...state };

    return createStore(Reducer(history), initialState, applyMiddleware(thunk));
};
