// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { GenericToolbar } from '../../components/GenericToolbar';
import { pushAppRoute } from '../../data/domain/route-assets';
import { useHistory } from 'react-router-dom';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { OrgInfo } from './OrgInfo';
import { OrgBuildings } from './OrgBuildings';
import { ENTITY_TYPE } from '../enum/ENUM';
import { Button, Snackbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { OrganizationActions } from '../../actions/actions/organization-actions';
import * as PXBColors from '@brightlayer-ui/colors';

type Props = {
    location: any;
};

export const OrganizationDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const history = useHistory();
    const { t } = useLanguageLocale();
    const [orgName, setOrgName] = useState('');
    const theme = useTheme();
    const dispatch = useDispatch();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const organization = useSelector(Selectors.Organization.organization);
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const [alertSuccessState, setAlertSuccessState] = useState(false);
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');

    const { backRoute, orgId } = props.location.state;

    const goToOrgBuildingDetails = (value: { buildingId: string, buildingName: string }): void => {
        pushAppRoute(history,
            { type: 'OrgBuildingDetails', buildingId: value.buildingId },
            { backRoute: 'OrganizationDetails', orgId: orgId, buildingId: value.buildingId });
    };

    const goToManageUsers = (): void => {
        pushAppRoute(history,
            { type: 'ManageAssetUsers', assetId: orgId },
            { backRoute: 'OrganizationDetails', assetId: orgId, assetType: ENTITY_TYPE.ORGANIZATION, title: orgName });
    };
    useEffect(() => {
        setOrgName(orgName)
    }, [orgName])

    useEffect(() => {
        if (organization.updateOrgDetailSuccess && !organization.updateOrgDetailLoading) {
            setAlertSuccessMessage(t('TOAST_MSG.TOAST_SUCCESS_ORG_UPDATE'));
            setAlertSuccessState(true)
            dispatch(OrganizationActions.updateOrgUnmounted());
        }
    }, [organization.updateOrgDetailSuccess])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.mergeBuildingAssetLoading && organization.mergeBuildingAssetSuccess) {
            setAlertSuccessMessage(t('MERGE_ASSETS.SUCCESS'));
            setAlertSuccessState(true);
        }
        dispatch(OrganizationActions.mergeBuildingAssetUnmounted());
    }, [organization.mergeBuildingAssetLoading, organization.mergeBuildingAssetSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.upsertBuildingDetailLoading && organization.upsertBuildingDetailSuccess) {
            setAlertSuccessMessage(t('TOAST_MSG.TOAST_SUCCESS_BUILDING_CREATE'));
            setAlertSuccessState(true);
        }
        dispatch(OrganizationActions.upsertBuildingUnmounted());
    }, [organization.upsertBuildingDetailLoading, organization.upsertBuildingDetailSuccess])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div data-testid="organizations-details-container">
            <GenericToolbar
                title={orgName}
                subtitle={t('ORGANIZATION_DETAILS.ORGANIZATION_DETAILS')}
                showBackButton={!organization.orgListLoading && organization.orgListSuccess && !dashboard.buildingListLoading && dashboard.buildingListSuccess}
                backButtonOnClick={(): void => {
                    pushAppRoute(history, {
                        type: backRoute,
                    });
                }}
                color={ToolbarColor.Default}
            />

            <div data-testid="container-org-details" style={{ display: 'flex', flexWrap: 'wrap', marginTop: 40 }}>

                <div style={{ minHeight: 700, width: md ? '40%' : '80%', marginLeft: 30 }}>
                    <OrgInfo
                        orgId={orgId}
                        orgNameUpdate={(orgName: string): void => setOrgName(orgName)}
                        userOnClick={goToManageUsers}
                    />
                </div>

                <div style={{ display: 'flex', minHeight: 700, width: md ? '45%' : '80%', marginLeft: 30 }}>
                    <OrgBuildings
                        orgId={orgId}
                        onClick={goToOrgBuildingDetails}
                    />
                </div>
                {
                    alertSuccessState &&
                    <Snackbar
                        open={true}
                        onClick={() => setAlertSuccessState(false)}
                        autoHideDuration={20}
                        title={''}
                        message={alertSuccessMessage}
                        action={
                            <Button color="inherit" size="small" onClick={() => setAlertSuccessState(false)}>
                                {t('ACTIONS.OKAY')}
                            </Button>
                        }
                        ContentProps={{ sx: { background: PXBColors.black[900] } }}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        sx={{ bottom: { xs: 90, sm: 0 } }}
                        style={{ height: 200, width: 200, flexGrow: 0 }}
                    />
                }
            </div>

        </div >
    );
};