// eslint-disable-next-line
import React from 'react';
import { IconButton, Divider } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { COLOR_GRAY } from '../../constants/color-codes';

type Props = {
    title1: string;
    title2: string | null | undefined;
    title3: string;
    subtitle: string | null | undefined;
    subtitle2?: string;
    icon: any;
    iconAlignment: string;
    displayAction: boolean;
    OnItemSelected?: Function;
    dividerDisable?: boolean;
};

export const OrgDetailListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    const goToUserDetails = (): void => {
        if (props.OnItemSelected != null) props.OnItemSelected();
    };

    return (
        <>
            <ListItem style={{ cursor: props.displayAction ? 'pointer' : 'cursor', display: 'flex', justifyContent: 'flex-end' }}
                onClick={(): void => goToUserDetails()}
                secondaryAction={
                    <div>
                        {props.displayAction &&
                            <IconButton edge="end" id="list-item-next-icon" data-testid="list-item-next-icon" style={{ marginLeft: "auto" }} >
                                <ChevronRight style={{ color: COLOR_GRAY }} />
                            </IconButton>
                        }
                    </div>
                }
            >
                <div data-testid="list-item-icon-type" style={{ minWidth: 50, height: 50, display: 'flex', alignItems: props.iconAlignment }}>
                    {props.icon}
                </div>
                <ListItemText data-testid="list-item-text-item"
                    primary={
                        <div>
                            {props.title1 !== '' && <Typography role="title" data-testid="list-item-text-title1" variant="subtitle1" style={{ color: COLOR_GRAY, wordWrap: 'break-word' }}>
                                {props.title1}
                            </Typography>
                            }
                            {props.title2 !== '' && <Typography data-testid="list-item-text-title2" variant="subtitle1" style={{ color: COLOR_GRAY, wordWrap: 'break-word' }}>
                                {props.title2}
                            </Typography>
                            }
                            {props.title3 !== '' && <Typography data-testid="list-item-text-title3" variant="subtitle1" style={{ color: COLOR_GRAY, wordWrap: 'break-word' }}>
                                {props.title3}
                            </Typography>
                            }
                        </div>
                    }
                    secondary={
                        <div>
                            {
                                props.subtitle !== '' && <Typography
                                    id="list-item-text-subtitle" data-testid="list-item-text-subtitle" variant="body2" display="flex" style={{ color: COLOR_GRAY, wordBreak:'break-word' }}>
                                    {props.subtitle}
                                </Typography>
                            }
                            {props.subtitle2 !== '' && <Typography
                                id="list-item-text-subtitle2" data-testid="list-item-text-subtitle2" variant="body1" display="flex" style={{ color: COLOR_GRAY, overflow: 'auto' }}>
                                {props.subtitle2}
                            </Typography>
                            }
                        </div>
                    }
                />
            </ListItem>
            {!props?.dividerDisable && <Divider style={{ marginLeft: 60 }} />}
        </>
    );
};
