// eslint-disable-next-line
import React, { } from 'react';
import {
    Typography,
    Card,
    ListSubheader,
    Divider,
} from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { servicePartnerDetail } from '../../types/add-ServicePartnerCustomer';
import { FormatDateInText } from '../enum/DateTimeFormat';

type Props = {
    servicePartnerInfo: servicePartnerDetail;
};

export const ServicePartnerSlaDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();

    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }} id="servicePartner-sla-details" data-testid="servicePartner-sla-details-container">
                <ListSubheader
                    component="div"
                    data-testid="sla-details-header"
                    id="sla-details-header"
                    style={{
                        display: 'flex',
                        fontWeight: 600,
                        fontSize: 14,
                        alignItems: 'center',
                        overflow: 'auto',
                        whiteSpace: 'nowrap',
                        marginLeft: 10,
                        height: 60,
                    }}

                >

                    <Typography
                        variant="subtitle1"
                        style={{
                            alignItems: 'left',
                            color: PXBColors.blue[600],
                            width: '92.3%',
                            paddingLeft: '10',
                        }}
                        id="sla-details-title"
                        data-testid="sla-details-title"
                    >
                        {t('CUSTOMER_DETAILS.SLA_DETAILS_HEADER')}

                    </Typography>
                </ListSubheader>
                <Divider />
                <div style={{ paddingLeft: 24, paddingTop: 8, paddingBottom: 8 }}>
                    <Typography variant='h6'>{props.servicePartnerInfo.sLANumber}</Typography>
                    <Typography data-testid='expiryDateHelpertext' variant='body1'>{t('CUSTOMER_DETAILS.EXPIRES') + (props.servicePartnerInfo.expiryDate === null ? '--' : FormatDateInText(props.servicePartnerInfo.expiryDate))}</Typography>
                </div>
            </Card >
        </>
    );
};
