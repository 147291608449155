// eslint-disable-next-line
import React, { useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import { UserSettingsDataProvider } from '../data/DataProviders/UserSettingsDataProvider';
import { AppDataProvider } from '../components/AppDataProvider';
import { ForgotPassword } from './forgot-password/ForgotPassword';
import { Registration } from './registration/Register';
import { ResetPassword } from './reset-password/ResetPassword';
import { Login } from './login/Login';

import { IdleTimerContainer } from '../components/IdleTimerContainer';
import UnsupportedBrowser from './unsupported-browser/UnsupportedBrowser';
import { isSupportedBrowser } from '../lib/browser-check';

import { Content } from './content';
import { OrganizationListProvider } from '../data/DataProviders/OrganizationListProvider';
import { DataProviderCache } from '../data/DataProviders/DataProviderCache';
import { OrganizationDataProvider } from '../data/DataProviders/OrganizationDataProvider';
import { SignalRConnectionProvider } from '../data/DataProviders/SignalRConnectionProvider';
import { UserInvitation } from './user-management/UserInvitation';
import { CustomerListProvider } from '../data/DataProviders/CustomerListProvider';
import { CustomerDataProvider } from '../data/DataProviders/CustomerDataProvider';
import { useTranslation } from 'react-i18next';
import { Selectors } from '../selectors';
import { useSelector } from 'react-redux';
import { languageList } from '../components/UserDetails/AccountPreferencesData';
import { SELECTED_LANGUAGE } from '../constants';
import { SessionStorage } from '../components/AuthContextProvider/session-storage';

const App: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, -100);
        }, 500);
    }, []);

    const { i18n } = useTranslation();
    const userData = useSelector(Selectors.User.user);
    const lan = SessionStorage.readLanguage()

    const setLang = (value: number) => {
        // eslint-disable-next-line
        let userLang = languageList.filter((lang) => {
            if (lang.key === value)
                return lang.code;
        });
        if (userLang.length > 0 && userLang[0].code)
            return userLang[0].code;
    };

    useEffect(() => {
        if (!userData.getUserDetailLoading && userData.getUserDetailSuccess) {
            const lang = userData.userDetail.language;
            i18n.changeLanguage(setLang(lang));
        }
        else {
            i18n.changeLanguage(lan);
        }

    }, [userData.getUserDetailLoading, userData.getUserDetailSuccess, userData.getUserDetailFail]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="full">
            <Switch>
                <Route path={['/login', '/forgot-password', '/user-invitation', '/register', '/reset-password', '/unsupported-browser']}>
                    <Switch>
                        <Route exact path="/unsupported-browser" component={UnsupportedBrowser} />
                    </Switch>

                    {isSupportedBrowser() ? (
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/forgot-password" component={ForgotPassword} />
                            <Route exact path="/register/:inviteUserType/:inviteUserEmail" component={Registration} />
                            <Route exact path="/user-invitation/:invitationId" component={UserInvitation} />
                            <Route path="/reset-password/:code" component={ResetPassword} />
                            <Redirect to="/login" />
                        </Switch>
                    ) : (
                        <Redirect to="/unsupported-browser"></Redirect>
                    )}
                </Route>

                <PrivateRoute path="*">
                    <AppDataProvider>
                        <IdleTimerContainer />
                        <UserSettingsDataProvider />
                        <DataProviderCache for="User Role">
                                <OrganizationListProvider>
                                    <CustomerListProvider>
                                        <SignalRConnectionProvider>
                                                <OrganizationDataProvider>
                                                    <CustomerDataProvider>
                                                        <Content />
                                                    </CustomerDataProvider>
                                                </OrganizationDataProvider>
                                        </SignalRConnectionProvider>
                                    </CustomerListProvider>
                                </OrganizationListProvider>
                        </DataProviderCache>
                    </AppDataProvider>
                </PrivateRoute>
            </Switch>
        </div>
    );
};

export default App;
