import { InitialState } from '../state';
import { LOCATION_CHANGE } from 'connected-react-router';
import { AppReducer } from './app-reducer';

export const authReducer: AppReducer<'Authentication', { type: typeof LOCATION_CHANGE }> = (
    state = InitialState.Authentication,
    action
): typeof state => {
    switch (action.type) {
        case 'Authentication/Login/Started':
            return {
                ...state,
                isAuthenticating: true,
                login: {
                    errorCode: 0,
                    errorMessage: '',
                    loading: true,
                },
            };
        case 'Authentication/Login/Succeeded':
            return {
                ...state,
                isAuthenticating: false,
                login: {
                    errorCode: 0,
                    errorMessage: '',
                    loading: false,
                },
            };
        case 'Authentication/Login/Failed':
            return {
                ...state,
                isAuthenticating: false,
                login: {
                    errorCode: action.payload.errorCode,
                    errorMessage: action.payload.errorMessage,
                    loading: false,
                },
            };
        case 'Authentication/Login/Unmount':
            return {
                ...state,
                isAuthenticating: false,
                login: {
                    errorCode: 0,
                    errorMessage: '',
                    loading: false,
                },
            };
        case 'Authentication/VerifyResetCode/Started':
            return {
                ...state,
                verifyResetCode: {
                    code: action.payload.code,
                    pending: true,
                    success: false,
                },
            };
        case 'Authentication/VerifyResetCode/Succeeded':
            return {
                ...state,
                verifyResetCode: {
                    code: action.payload.code,
                    pending: false,
                    success: true,
                },
            };
        case 'Authentication/VerifyResetCode/Failed':
            return {
                ...state,
                verifyResetCode: {
                    code: action.payload.code,
                    pending: false,
                    success: false,
                },
            };
        case 'Authentication/SetPassword/Started':
            return {
                ...state,
                setPassword: {
                    pending: true,
                    requested: true,
                    success: false,
                    failure: false,
                    errorCode: '',
                },
            };
        case 'Authentication/SetPassword/Succeeded':
            return {
                ...state,
                setPassword: {
                    pending: false,
                    requested: true,
                    success: true,
                    failure: false,
                    errorCode: '',
                },
            };
        case 'Authentication/SetPassword/Failed':
            return {
                ...state,
                setPassword: {
                    pending: false,
                    requested: true,
                    success: false,
                    failure: true,
                    errorCode: action.payload.errorCode
                },
            };
        case 'Authentication/ResetPassword/Started':
            return {
                ...state,
                forgotPassword: {
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case 'Authentication/ResetPassword/Succeeded':
            return {
                ...state,
                forgotPassword: {
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case 'Authentication/ResetPassword/Failed':
            return {
                ...state,
                forgotPassword: {
                    loading: false,
                    success: false,
                    errorMessage: action.payload.errorMessage,
                },
            };
        case 'Authentication/ResetPassword/Reset':
            return {
                ...state,
                forgotPassword: {
                    loading: false,
                    success: false,
                    errorMessage: '',
                },
            };
        case LOCATION_CHANGE: {
            return {
                ...state,
                forgotPassword: {
                    loading: false,
                    success: false,
                    errorMessage: '',
                },
            };
        }

        default:
            return state;
    }
};
