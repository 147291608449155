// eslint-disable-next-line
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppRoutes } from '../../routes';
import { LogBook } from './LogBook';
import { LogbookDetails } from './LogbookDetails';

export const LogbookManagement: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => (
    <Switch>
        <Route exact path={AppRoutes.Logbook} component={LogBook} />
        <Route exact path={AppRoutes.LogbookDetails} component={LogbookDetails} />
    </Switch>
);
