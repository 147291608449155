// eslint-disable-next-line
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppRoutes } from '../../routes';
import { Overview } from './Overview';
import { OverviewBuildingDetails } from './OverviewBuildingDetails';

export const OverviewManagement: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => (
    <Switch>
        <Route exact path={AppRoutes.Dashboard} component={Overview} />
        <Route exact path={AppRoutes.OverviewBuildingDetails} component={OverviewBuildingDetails} />
    </Switch>
);
