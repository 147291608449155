import { InitialState } from '../state';
import { AppReducer } from './app-reducer';
import { isTrendlineEnabled } from '../state/dashboard-state';
import { getTrendlineIdentifier } from '../lib/trends-helpers';

export const dashboardReducer: AppReducer<'Dashboard'> = (state = InitialState.Dashboard, action): typeof state => {
    switch (action.type) {
        case 'Dashboard/AutoRedirectBannerShown':
            return {
                ...state,
                autoRedirectBannerShown: true,
            };
        case 'Dashboard/AutoRedirectBannerDismissed':
            return {
                ...state,
                autoRedirectBannerShown: false,
            };
        case 'Dashboard/TrendCardDaysChanged':
            return {
                ...state,
                trendCardDays: action.payload.days,
            };
        case 'Dashboard/TrendListItemCheckboxClicked': {
            return {
                ...state,
                trendLinesEnabled: {
                    ...state.trendLinesEnabled,
                    [getTrendlineIdentifier(action.payload.channelId, action.payload.deviceId)]: !isTrendlineEnabled(
                        state.trendLinesEnabled,
                        action.payload.channelId,
                        action.payload.deviceId
                    ),
                },
            };
        }
        case 'Dashboard/TrendListItemEntered':
            return {
                ...state,
                hoveredTrendline: {
                    channelId: action.payload.channelId,
                    deviceId: action.payload.deviceId,
                },
            };
        case 'Dashboard/TrendListItemLeft': {
            return {
                ...state,
                hoveredTrendline: undefined,
            };
        }
        case 'Dashboard/inActiveLogOut': {
            return {
                ...state,
                inActiveLogOut: action.payload.inActiveLogOut,
            };
        }
        case 'Dashboard/LocationRefresh':
            return {
                ...state,
                isLocationRefresh: action.payload.isRefresh,
            };

        case 'Dashboard/buildingList/Started':
            return {
                ...state,
                buildingListLoading: true,
                buildingListSuccess: false,
                buildingListFail: false,
                errorCode: '',
            };
        case 'Dashboard/buildingList/Succeeded':
            return {
                ...state,
                buildingListLoading: false,
                buildingListSuccess: true,
                buildingListFail: false,
                errorCode: '',
                buildingList: action.payload.buildingList,
            };
        case 'Dashboard/buildingList/Failed':
            return {
                ...state,
                buildingListLoading: false,
                buildingListSuccess: false,
                buildingListFail: true,
                errorCode: action.payload.errorCode,
            };

        case 'Dashboard/buildingList/Unmounted':
            return {
                ...state,
                buildingListLoading: false,
                buildingListSuccess: false,
                buildingListFail: false,
                errorCode: '',
            };

        case 'Dashboard/statisticSummaryDashboard/Started':
            return {
                ...state,
                statisticSummaryDashboardLoading: true,
                statisticSummaryDashboardSuccess: false,
                statisticSummaryDashboardFail: false,
                statisticSummaryDashboardErrorCode: '',
            };
        case 'Dashboard/statisticSummaryDashboard/Succeeded':
            return {
                ...state,
                statisticSummaryDashboardLoading: false,
                statisticSummaryDashboardSuccess: true,
                statisticSummaryDashboardFail: false,
                statisticSummaryDashboardErrorCode: '',
                statisticSummaryDashboard: action.payload.statisticSummaryDashboard,
            };
        case 'Dashboard/statisticSummaryDashboard/Failed':
            return {
                ...state,
                statisticSummaryDashboardLoading: false,
                statisticSummaryDashboardSuccess: false,
                statisticSummaryDashboardFail: true,
                statisticSummaryDashboardErrorCode: action.payload.errorCode,
            };
        case 'Dashboard/statisticSummaryDashboard/Unmounted':
            return {
                ...state,
                statisticSummaryDashboardLoading: false,
                statisticSummaryDashboardSuccess: false,
                statisticSummaryDashboardFail: false,
                statisticSummaryDashboardErrorCode: '',
                statisticSummaryDashboard:{}
            };
        case 'Dashboard/selectedDashboard/dashboardChange':
            return {
                ...state,
                dashboardTabSelection: action.payload.dashboardTabSelection,

            };

        default:
            return state;
    }
};
