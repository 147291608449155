// eslint-disable-next-line
import React from 'react';
import { AuthHelper } from '../../types/auth-helper-types';
import { SessionStorage } from './session-storage';
import { reducer } from './reducer';
import { isUnauthorized } from './helpers';
import { useRefreshToken } from './hooks';
import { RefreshTokenFn, AuthContextState } from './types';

type AuthContextDispatch = AuthHelper;
const StateContext = React.createContext<AuthContextState | null>(null);
const DispatchContext = React.createContext<AuthContextDispatch | null>(null);

function useAuthState(): AuthContextState {
    const context = React.useContext(StateContext);
    if (context === null) {
        throw new Error('useAuthState must be used within an AuthContextProvider');
    }
    return context;
}

function useAuthHelper(): AuthHelper {
    const context = React.useContext(DispatchContext);
    if (context === null) {
        throw new Error('useAuthHelper must be used within an AuthContextProvider');
    }
    return context;
}

type HttpResponse<T extends unknown> = {
    status: number;
    response: T;
};

type Props = {
    children: React.ReactNode;
    numberOfSecondsBeforeExpirationToRefreshToken?: number;
    checkTokenExpirationFrequencyMs?: number;
    refreshTokenFn: RefreshTokenFn;
};

const AuthContextProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    // The presence of an email address indicates a previously successful authentication.
    // This will allow a user to get to a "private route". If the token has expired they'll
    // end up getting redirected to the login anyway, after an API call fails.
    const persistedAuthData = SessionStorage.readAuthData();
    const initialContextState =
        persistedAuthData.userId && persistedAuthData.email
            ? {
                userId: persistedAuthData.userId,
                email: persistedAuthData.email,
                rememberMeDetails: {
                    email: persistedAuthData.rememberMeData.user,
                    rememberMe: persistedAuthData.rememberMeData.rememberMe,
                },
                roleTypeId: persistedAuthData.roleTypeId
            }
            : {
                userId: undefined,
                email: undefined,
                rememberMeDetails: {
                    email: persistedAuthData.rememberMeData.user,
                    rememberMe: persistedAuthData.rememberMeData.rememberMe,
                },
            };

    const [state, dispatch] = React.useReducer(reducer, initialContextState);
    const [tokenExpirationDate, setTokenExpirationDate] = React.useState<Date | null>(
        persistedAuthData.initialExpirationDate
    );

    const onUserNotAuthenticated = React.useCallback((): void => {
        SessionStorage.clearAuthCredentials();
        SessionStorage.clearSelectedOrganization();
        SessionStorage.clearSelectedServiceManagerOptions();
        setTokenExpirationDate(null);

        if (!persistedAuthData.rememberMeData.rememberMe) {
            SessionStorage.clearRememberMeData();
            dispatch({
                type: 'userNotAuthenticated',
            });
        } else {
            dispatch({
                type: 'userNotAuthenticatedWithRememberMe',
                payload: {
                    email: persistedAuthData.rememberMeData.user,
                    rememberMe: persistedAuthData.rememberMeData.rememberMe,
                },
            });
        }
    }, [dispatch, persistedAuthData.rememberMeData]);

    const refreshTokenFn = props.refreshTokenFn;

    const memoizedAuthHelper = React.useMemo(() => {
        const authHelper: AuthHelper = {
            async send<T>(request: Promise<HttpResponse<T>>): Promise<HttpResponse<T>> {
                const initialResponse = await request;
                if (!isUnauthorized(initialResponse)) {
                    return initialResponse;
                }

                // Not authorized, so try to refresh the token
                const refreshResult = await refreshTokenFn({ user: state.email || '' }, { authHelper: undefined });

                if (refreshResult.ok) {
                    SessionStorage.saveAuthToken(refreshResult.response.data.token);
                    setTokenExpirationDate(new Date(refreshResult.response.data.expiration_date));

                    // Able to refresh, so try again!
                    return request;
                }

                // Still not authorized, logout
                onUserNotAuthenticated();

                return initialResponse;
            },
            onUserAuthenticated(onUserAuthenticatedArgs: {
                email: string;
                userId: string;
                expirationDate: Date;
                rememberMe: boolean;
                roleTypeId?: number;
            }) {
                SessionStorage.saveAuthCredentials(onUserAuthenticatedArgs.email, onUserAuthenticatedArgs.userId, onUserAuthenticatedArgs.roleTypeId);
                SessionStorage.saveRememberMeData(onUserAuthenticatedArgs.email, onUserAuthenticatedArgs.rememberMe);
                setTokenExpirationDate(onUserAuthenticatedArgs.expirationDate);
                dispatch({
                    type: 'userAuthenticated',
                    payload: onUserAuthenticatedArgs,
                });
            },
            onUserNotAuthenticated,
        };
        return authHelper;
    }, [dispatch, state.email, onUserNotAuthenticated, refreshTokenFn, state.roleTypeId]); // eslint-disable-line react-hooks/exhaustive-deps


    // This handles auto-refreshing the token if the page is open
    useRefreshToken({
        email: state.email,
        tokenExpirationDate,
        onUserNotAuthenticated,
        setTokenExpirationDate,
        refreshTokenFn: props.refreshTokenFn,
        numberOfSecondsBeforeExpirationToRefreshToken: props.numberOfSecondsBeforeExpirationToRefreshToken,
        checkTokenExpirationFrequencyMs: props.checkTokenExpirationFrequencyMs,
    });

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={memoizedAuthHelper}>{props.children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
};

const TestAuthContextProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ value?: AuthContextState }>>> = (props) => {
    const memoizedAuthHelper: AuthHelper = React.useMemo(
        () => ({
            send<T>(request: Promise<HttpResponse<T>>): Promise<HttpResponse<T>> {
                return request;
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function, no-empty-function
            onUserAuthenticated: (args: { email: string; userId: string; expirationDate: Date }): void => { },
            // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
            onUserNotAuthenticated: (): void => { },
        }),
        []
    );

    return (
        <StateContext.Provider value={props.value || { userId: undefined, email: undefined, rememberMeDetails: {} }}>
            <DispatchContext.Provider value={memoizedAuthHelper}>{props.children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
};

const isAuthenticated = (state: AuthContextState): boolean => Boolean(state.email && state.userId);
const getUserId = (state: AuthContextState): string | undefined => state.userId;

const getUserRoleType = (state: AuthContextState): number | undefined => state.roleTypeId;
const getRememberMe = (state: AuthContextState): boolean => Boolean(state.rememberMeDetails.rememberMe);
const getUserEmail = (state: AuthContextState): string | undefined => state.rememberMeDetails.email;
export {
    AuthContextProvider,
    TestAuthContextProvider,
    useAuthState,
    useAuthHelper,
    isAuthenticated,
    getUserId,
    getUserRoleType,
    getRememberMe,
    getUserEmail
};
