import moment, { now } from 'moment';
export const INVALID_DATE = 'invalid date';

export function getFilterDate(daysToSubtract: number): string {
    const date = moment(now()).subtract(daysToSubtract, 'days').format('YYYY-MM-DD');
    return date;
}

export function getShortDate(date: string): string {
    return moment(date).format('MM/DD/YYYY');
}
export function getDate(date: string | string[] | undefined): string {
    if( date === undefined)
        {
            return INVALID_DATE
        }

    return moment(date).format('MM/DD/YYYY');
}

export function getDaysFromDate(dateYear: string): number {
    const differenceInDays = (new Date().getTime() - (new Date(dateYear).getTime())) / (1000 * 3600 * 24);
    return differenceInDays;
}

export function getNextYearDate(): Date {
    var result = new Date();
    result.setFullYear(result.getFullYear() + 1);
    return result;
}

export function getDaysDifferenceFromDates(startDate: Date, endDate: Date): number {
    const differenceInDaysFromDates = (endDate.getTime() - (startDate.getTime())) / (1000 * 3600 * 24);
    return differenceInDaysFromDates;
}

export function subtractDaysFromDate(date: string, days: number): Date {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
}

export function getDateFromDays(days: number): Date {
    var result = new Date();
    result.setDate(result.getDate() - days);
    return result;
}

export function getFormattedDate(date: string): string {
    return moment(date).format('YYYY-DD-MM');
}

export function addDaysToDate(date: Date, days: number): string {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return moment(result).format('YYYY-MM-DD');
}

//YYYY-MM-DD format for date
export const formatToSimpleDate = (date: Date): string => {
    return (date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString() + '-' + date.getDate().toString());
}

//DD/MM/YYY format for date
// We are not considering UTC timezone here.
export const formatToDate = (date: string): string => {
    var result = new Date(date);
    const hours = result.getHours();
    const mins = result.getMinutes();
    if (hours === 0 && mins === 0)
        return ((result.getMonth() + 1).toString() + '/' + (result.getDate() - 1).toString() + '/' + result.getFullYear().toString());
    else
        return ((result.getMonth() + 1).toString() + '/' + result.getDate().toString() + '/' + result.getFullYear().toString());
}

//YYYY-MM-DD format for report date "Month 01 - Month 01,2023"
export const monthFormatDate = (fromdate: string, todate: string): string => {
    return( moment(fromdate).utc().format('MMMM DD') +" - "+ moment(todate).utc().format('MMMM DD, YYYY'));
}
//YYYY-MM-DD format  to October 09, 2050
export const FormatDateInText = (date: string): string => {
    return(moment(date?.slice(0, 10)).local().format('MMMM DD, YYYY'));
}
