// eslint-disable-next-line
import React, { useState } from 'react';
import {
    DialogActions,
    Button,
    DialogTitle,
    DialogContent,
    Typography,
    Divider,
    FormControl,
    InputLabel,
    FilledInput,
    InputAdornment,
    IconButton,
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as PXBColors from '@brightlayer-ui/colors';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { ChangePasswordDialogProgress } from '../../types';
import ErrorIcon from '@mui/icons-material/Error';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuthHelper, useAuthState, getUserId } from '../AuthContextProvider';
import { useHistory } from 'react-router';
import { Thunks, Actions } from '../../actions';
import { UserSelectors } from '../../selectors/user-selectors';
import { Selectors } from '../../selectors';
import { SetPasswordControl } from '../SetPasswordControl/SetPasswordControl';
import Dialog from '@mui/material/Dialog';

type DialogProps = {
    show: boolean;
    closeDialog: Function;
};

export const ChangePasswordDialog: React.FC<React.PropsWithChildren<React.PropsWithChildren<DialogProps>>> = (props) => {
    // const classes = useStyles();
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const authHelper = useAuthHelper();
    const authState = useAuthState();
    const history = useHistory();
    const changePassword = useSelector(Selectors.User.changePassword);
    const [showPassword, setShowPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const [passwordValid, setPasswordsValid] = useState(false);
    const userEmail = useSelector(UserSelectors.email);
    const userId = getUserId(authState);

    const Done = (): void => {
        dispatch(Thunks.UserSettings.userChangePassword(oldPassword, newPassword, authHelper));
    };

    const handleClose = (): void => {
        props.closeDialog();
        setOldPassword('');
    };

    // Reset the dialog state (before it renders), but only when the component is first mounted
    React.useLayoutEffect(() => {
        dispatch(Actions.UserSettings.resetChangePasswordSettings());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return changePassword.progress === ChangePasswordDialogProgress.Succeeded ? (
        <Dialog
            disableEscapeKeyDown={true}
            open={props.show}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="xs"
        >
            <DialogTitle id="dialog-success" data-testid="dialog-success" style={{ display: 'flex', justifyContent: 'center' }}>
                <AccountCircleIcon
                    sx={{
                        width: 60,
                        height: 60,
                        color: PXBColors.gray[500]
                    }}
                    color={'primary'}></AccountCircleIcon>
            </DialogTitle>
            <DialogContent style={{ marginBottom: 20 }}>
                <Typography variant="h5" style={{ marginBottom: 20 }}>
                    {t('CHANGE_PASSWORD.PASSWORD_CHANGED')}
                </Typography>
                <Typography variant="body2" style={{ marginBottom: 20 }} id="success-Title">
                    {t('CHANGE_PASSWORD.SUCCESS_MESSAGE')}
                </Typography>
                <Typography variant="body2" style={{ marginBottom: 150 }}>
                    {t('CHANGE_PASSWORD.EMAIL_CONFIRM_MESSAGE', { replace: { email: userEmail } })}
                </Typography>
            </DialogContent>
            <Divider style={{ marginBottom: 50 }}></Divider>
            <DialogActions>
                <Button
                    id="btnLogin"
                    data-testid="btnLogin"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={(): void => {
                        dispatch(Thunks.Authentication.logout(userId, history, authHelper.onUserNotAuthenticated, authHelper));
                    }}
                >
                    Login
                </Button>
            </DialogActions>
        </Dialog>
    ) : (
        <>
            <Dialog
                disableEscapeKeyDown={true}
                open={props.show}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="xs"
            >
                <DialogTitle id="form-dialog-title" data-testid='changePwdId'>{t('CHANGE_PASSWORD.PASSWORD')}</DialogTitle>
                <DialogContent>
                    <>
                        <div>
                            <Typography variant="body2" component="p" data-testid='pwdHintId' style={{ marginBottom: 50 }}>
                                {t('CHANGE_PASSWORD.PASSWORD_INFO')}
                            </Typography>
                            <Divider style={{ margin: '30px 0px' }} />

                            {changePassword.progress === ChangePasswordDialogProgress.Failed && (
                                <div style={{ marginBottom: 10 }} data-testid="fail-body">
                                    <div style={{ float: 'left', marginRight: 5 }}>
                                        <ErrorIcon color="error" />
                                    </div>
                                    <Typography variant="body2" color="error" display="inline">
                                        <b> {t('CHANGE_PASSWORD.PROBLEM_OCCURRED')}</b>{' '}
                                    </Typography>
                                    <Typography variant="caption" display="inline">
                                        {t('CHANGE_PASSWORD.ERROR_MESSAGR')}
                                    </Typography>
                                </div>
                            )}

                            <FormControl variant={'filled'} fullWidth style={{ marginBottom: 20 }}>
                                <InputLabel id={'new-label'} htmlFor={'old-password'} data-testid='oldPwdId'>
                                    {t('CHANGE_PASSWORD.OLD_PASSWORD')}
                                </InputLabel>
                                <FilledInput
                                    id={'old-password'}
                                    type={showPassword ? 'text' : 'password'}
                                    value={oldPassword}
                                    inputProps={{ maxLength: 16, 'data-testid': 'old-password' }}
                                    fullWidth
                                    onChange={(e): void => {
                                        setOldPassword(e.target.value);
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={t('FORMS.TOGGLE_PASSWORD_VISIBILITY')}
                                                onClick={(): void => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <SetPasswordControl
                                passwordLabel={t('LABELS.NEW_PASSWORD')}
                                passwordValidate={(value: boolean): void => setPasswordsValid(value)}
                                onPasswordChange={(newPass: string): void => setNewPassword(newPass)}
                            />
                        </div>
                    </>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button style={{ textTransform: 'none' }} id="btnBack" data-testid="back" variant="outlined" color="primary" onClick={handleClose}>
                        {t('ACTIONS.BACK')}
                    </Button>
                    <Button
                        style={{
                            textTransform: 'none', backgroundColor: passwordValid ? PXBColors.blue[500] : PXBColors.blue[50],
                            color: passwordValid ? PXBColors.white[50] : PXBColors.blue[200],
                        }}
                        id="btnDone"
                        disabled={!passwordValid}
                        data-testid="done"
                        variant="contained"
                        color="primary"
                        onClick={Done}
                    >
                        {t('ACTIONS.DONE')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
