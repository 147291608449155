// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import { pushAppRoute } from '../../data/domain/route-assets';
import { useHistory } from 'react-router-dom';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, TextField, Typography } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { Country, CountryList } from '../../state/registration-state';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { ALPHA_NUMBERIC, COMPANY_ADDRESS, COMPANY_ADDRESS_MAX_LENGTH, COMPANY_CITY, COMPANY_CITY_MAX_LENGTH, COMPANY_POSTALCODE_MAX_LENGTH, COUNTRY_CODE_MAX_LENGTH, EMPTY_STRING, FIRST_NAME_MAX_LENGTH, LAST_NAME_MAX_LENGTH, NAME_VALIDATION, ORGANIZATTION_NAME, ORG_CHARACTER_LIMIT, ORG_COMMENTS_LIMIT, ORG_EMAIL_MAX_LENGTH, PHONE_MAX_LENGTH, PHONE_MIN_LENGTH, PHONE_NUMBER } from '../../constants';
import { Thunks } from '../../actions';
import { OrganizationThunks } from '../../actions/thunks/organization-thunks';
import { useAuthHelper } from '../../components/AuthContextProvider';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { GenericToolbar } from '../../components/GenericToolbar';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { OrganizationData } from '../../types/organization';
import { validEmail, validPhoneNumberLength, validPostalCode } from '../../lib/form-validator';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { vh_10, vh_26 } from '../../constants/dimentions';
import { OrganizationActions } from '../../actions/actions/organization-actions';

const orgDetailsDefault: OrganizationData = {
    projectId: "",
    name: "",
    description: "",
    country: "",
    address: "",
    postalCode: "",
    city: "",
    countryCode: "",
    phone: "",
    email: "",
    systemCount: 11,
    buildingCount: 22,
    gatewayCount: 0,
    adminCount: 2,
    viewerCount: 14,
    isAdmin: false,
    isFavourite: false,
    address2: '',
    primaryFirstName: null,
    primaryLastName: null,
    secondaryFirstName: null,
    secondaryLastName: null,
    secondaryEmail: null,
    secondaryPhone: null,
    secondaryCountryCode: null
};

type Props = {
    location: any;
};

export const CreateOrganization: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const history = useHistory();
    const { t } = useLanguageLocale();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const authHelper = useAuthHelper();
    const { addToast } = useToasts();
    const { backRoute, orgId, buildingId, assetId } = props.location.state;
    const dispatch = useDispatch();
    const registration = useSelector(Selectors.Registration.registration);
    const countryListLoading = useSelector(Selectors.Registration.countryListLoading);
    const countryListSuccess = useSelector(Selectors.Registration.countryListSuccess);
    const [isFormEdit, setFormEdit] = useState(false);
    const [isAPICallDone, setIsAPICallDone] = useState(false);
    const [comments, setComments] = React.useState('');
    const [organizationId, setOrganizationId] = useState('');
    const [email, setEmail] = useState('');
    const [secondaryEmail, setSecondaryEmail] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [countryName, setCountryName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [primaryFirstName, setPrimaryFirstName] = useState('');
    const [primaryLastName, setPrimaryLastName] = useState('');
    const [secondaryFirstName, setSecondaryFirstName] = useState('');
    const [secondaryLastName, setSecondaryLastName] = useState('');
    const [companyAddress2, setCompanyAddress2] = useState('');
    const [companyPostalCode, setCompanyPostalCode] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [countryList, setCountryList] = useState<CountryList>([]);
    const [selectedCountry, setSelectedCountry] = useState<Country>({ name: '', code: '' });
    const [countryCode, setCountryCode] = useState('');
    const [secondaryCountryCode, setSecondaryCountryCode] = useState('');
    const [appBarColor, setAppBarColor] = useState(ToolbarColor.Default);
    const [header, setHeader] = React.useState(t('DASHBOARD.ADD_ORGANIZATION'));
    const organization = useSelector(Selectors.Organization.organization);
    const [organizationDetails, setOrganizationDetails] = useState<OrganizationData>(orgDetailsDefault);
    const [organizationEditDetails, setOrganizationEditDetails] = useState<OrganizationData>(orgDetailsDefault);
    const [openQuitDialog, setOpenQuitDialog] = React.useState(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [alertSuccessMessage, setAlertSuccessMessage] = useState<string>('');
    const [savebuttonState, setSavebuttonState] = React.useState(false);

    const handleToolBar = (flag: boolean) => {
        setSavebuttonState(flag);
        setAppBarColor(flag ? ToolbarColor.Other : ToolbarColor.Default);
        setFormEdit(flag);
    };

    const handleLinkCustomerResponse = (): void => {
        if (!organization.updateOrgDetailLoading && organization.updateOrgDetailFail) {
            if (organization.updateOrgDetailErrorMessage === '9121')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9121'));
            else if (organization.updateOrgDetailErrorMessage === '9068')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9068'));
            else if (organization.updateOrgDetailErrorMessage === '9069')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9069'));
            else if (organization.updateOrgDetailErrorMessage === '9070')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9070'));
            else if (organization.updateOrgDetailErrorMessage === '9071')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9071'));
            else if (organization.updateOrgDetailErrorMessage === '9072')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9072'));
            else if (organization.updateOrgDetailErrorMessage === '9043')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9043'));
            else if (organization.updateOrgDetailErrorMessage === '9073')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9073'));
            else if (organization.updateOrgDetailErrorMessage === '9074')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9074'));
            else if (organization.updateOrgDetailErrorMessage === '9079')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9079'));
            else if (organization.updateOrgDetailErrorMessage === '9077')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9077'));
            else if (organization.updateOrgDetailErrorMessage === '9075')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9075'));
            else if (organization.updateOrgDetailErrorMessage === '9076')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9076'));
            else if (organization.updateOrgDetailErrorMessage === '9078')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9078'));
            else if (organization.updateOrgDetailErrorMessage === '9001')
                setAlertErrorMessage(t('TOAST_ERROR_LINK_CUSTOMER_ERROR_INVAILD_TOKEN'));
            else if (organization.updateOrgDetailErrorMessage === '9100')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9100'));
            else if (organization.updateOrgDetailErrorMessage === '9082')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9082'));
            else if (organization.updateOrgDetailErrorMessage === '9083')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9083'));
            else if (organization.updateOrgDetailErrorMessage === '9150')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9150'));
            else if (organization.updateOrgDetailErrorMessage === '9151')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9151'));
            else if (organization.updateOrgDetailErrorMessage === '9152')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9152'));
            else if (organization.updateOrgDetailErrorMessage === '9153')
                setAlertErrorMessage(t('TOAST_MSG.TOAST_MSG_ERROR_CODE_9153'));
            else
                setAlertErrorMessage(t('TOAST_MSG.TOAST_ERROR_ORG_UPDATE'));

        }
    }
    useEffect(() => {
        dispatch(Thunks.Registration.countryCodeRequest());
    }, [backRoute]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.updateOrgDetailLoading && organization.updateOrgDetailFail) {
            handleLinkCustomerResponse();
            setDisplayErrorMessage(true);
            dispatch(OrganizationActions.updateOrgUnmounted());

        }
    }, [organization.updateOrgDetailLoading, organization.updateOrgDetailFail])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!countryListLoading && countryListSuccess) {
            setCountryList(registration.countryList);
            if (organization.orgListSuccess && organizationId.length > 0) {
                const country: Country = registration.countryList.filter((country) => country.name.trim() === organization.orgList.detail[0].country.trim())[0];
                setSelectedCountry(country);
                setCountryName(country.name);
            }
        }
    }, [countryListLoading, countryListSuccess, organization.orgListSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (orgId)
            dispatch(Thunks.Organization.getOrganizationDetailsList(orgId));
    }, [orgId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (orgId) {
            handleToolBar(false);
            if (!organization.orgListLoading && organization.orgListSuccess) {
                setOrganizationDetails(organization.orgList.detail[0]);
                setOrganizationEditDetails(organization.orgList.detail[0]);
                setOrganizationName(organization.orgList.detail[0].name);
            } else if (!organization.orgListLoading && organization.orgListFail) {
                setOrganizationDetails(orgDetailsDefault);
                setOrganizationEditDetails(orgDetailsDefault);
            }
        }
    }, [organization.orgListLoading, organization.orgListSuccess, organization.orgListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (organization.updateOrgDetailSuccess && isAPICallDone) {
            onQuitClick();
            setAppBarColor(ToolbarColor.Default);
            setFormEdit(false);
            setHeader(organizationName);
        }
        else if (organization.updateOrgDetailFail) {
            setIsAPICallDone(false);
        }
        else {
            //todo false state
        }
    }, [organization.updateOrgDetailSuccess, organization.updateOrgDetailFail, organization.updateOrgDetailLoading, isAPICallDone]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.orgListLoading && organization.orgListSuccess) {
            handleToolBar(false);
            if (email == organizationEditDetails.email &&
                secondaryEmail == organizationEditDetails.secondaryEmail &&
                phoneNumber == organizationEditDetails.phone &&
                secondaryPhoneNumber == organizationEditDetails.secondaryPhone &&
                organizationName == organizationEditDetails.name &&
                comments == organizationEditDetails.description &&
                countryName == organizationEditDetails.country &&
                companyAddress == organizationEditDetails.address &&
                companyAddress2 == organizationEditDetails.address2 &&
                primaryFirstName == organizationEditDetails.primaryFirstName &&
                primaryLastName == organizationEditDetails.primaryLastName &&
                secondaryFirstName == organizationEditDetails.secondaryFirstName &&
                secondaryLastName == organizationEditDetails.secondaryLastName &&
                secondaryCountryCode == organizationDetails.secondaryCountryCode &&
                countryCode == organizationEditDetails.countryCode &&
                companyCity == organizationEditDetails.city &&
                companyPostalCode == organizationEditDetails.postalCode) {
                handleToolBar(false);
                setFormEdit(false);
            }
            else {
                if (
                    fieldsComplete([organizationName, countryName]) &&
                    (!companyPostalCode || (companyPostalCode && validPostalCode(companyPostalCode))) &&
                    (!email || (email && validEmail(email))) &&
                    (!phoneNumber || (phoneNumber && validPhoneNumberLength(phoneNumber))) &&
                    (!secondaryEmail || (secondaryEmail && validEmail(secondaryEmail))) &&
                    (!secondaryPhoneNumber || (secondaryPhoneNumber && validPhoneNumberLength(secondaryPhoneNumber)))
                ) {
                    setSavebuttonState(true);
                    setAppBarColor(ToolbarColor.Other);
                    if (companyPostalCode.length > 0) {
                        if (validPostalCode(companyPostalCode)) {
                            handleToolBar(true);
                        }
                        else if (!validPostalCode(companyPostalCode)) {
                            setSavebuttonState(false);
                        }
                    }
                    if (email.length > 0) {
                        if (validEmail(email)) {
                            handleToolBar(true);

                        }
                        else if (!validEmail(email)) {
                            handleToolBar(false);
                        }
                    }
                    if (phoneNumber.length > 0) {
                        if (validPhoneNumberLength(phoneNumber)) {
                            handleToolBar(true);
                        }
                        else if (!validPhoneNumberLength(phoneNumber)) {
                            handleToolBar(false);
                        }
                    }
                    if (secondaryEmail.length > 0) {
                        if (validEmail(secondaryEmail)) {
                            handleToolBar(true);
                        }
                        else if (!validEmail(secondaryEmail)) {
                            handleToolBar(false);
                        }
                    }
                    if (secondaryPhoneNumber.length > 0) {
                        if (validPhoneNumberLength(secondaryPhoneNumber)) {
                            handleToolBar(true);
                        }
                        else if (!validPhoneNumberLength(secondaryPhoneNumber)) {
                            handleToolBar(false);
                        }
                    }
                    if (companyPostalCode.length > 0 && email.length > 0 && phoneNumber.length > 0 && secondaryEmail.length > 0 && secondaryPhoneNumber.length > 0) {
                        if (validPostalCode(companyPostalCode) && validEmail(email) && validPhoneNumberLength(phoneNumber) && validEmail(secondaryEmail) && validPhoneNumberLength(secondaryPhoneNumber)) {
                            setSavebuttonState(true);
                            setAppBarColor(ToolbarColor.Other);
                        }
                        else {
                            setSavebuttonState(false);
                            setAppBarColor(ToolbarColor.Default);
                            setFormEdit(false)
                        }
                    }
                }
                else {
                    handleToolBar(false);
                }
            }
        }
        else {
            if (fieldsComplete([organizationName, countryName])) {
                handleToolBar(true);
                setAppBarColor(ToolbarColor.Other);
            }
            else {
                handleToolBar(false);
                setAppBarColor(ToolbarColor.Default);
            }
        }

    }, [email, countryName, phoneNumber, companyAddress, companyPostalCode, companyCity, countryCode, organizationName, companyAddress2, comments, primaryFirstName, primaryLastName, secondaryFirstName, secondaryLastName, secondaryEmail, secondaryPhoneNumber, secondaryCountryCode]) // eslint-disable-line react-hooks/exhaustive-deps

    const onCompanyAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_ADDRESS.test(e.target.value)) {
            setCompanyAddress(e.target.value);
        }
    };

    const onPrimaryFirstNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setPrimaryFirstName(e.target.value);
        }
    };
    const onPrimaryLastNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setPrimaryLastName(e.target.value);
        }
    };
    const onSecondaryFirstNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setSecondaryFirstName(e.target.value);
        }
    };
    const onSecondaryLastNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || NAME_VALIDATION.test(e.target.value)) {
            setSecondaryLastName(e.target.value);
        }
    };

    const onCompanyAddressChange2 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_ADDRESS.test(e.target.value)) {
            setCompanyAddress2(e.target.value);
        }
    };

    const onCompanyPostalCodeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ALPHA_NUMBERIC.test(e.target.value)) {
            setCompanyPostalCode(e.target.value as string);
        }
    };

    const onCompanyCityChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || COMPANY_CITY.test(e.target.value)) {
            setCompanyCity(e.target.value as string);
        }
    };

    const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setPhoneNumber(e.target.value);
        }
    };
    const onChangeSecondaryPhoneNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || PHONE_NUMBER.test(e.target.value)) {
            setSecondaryPhoneNumber(e.target.value);
        }
    };

    const onChangeCountryCode = (event: SelectChangeEvent) => {
        setCountryCode(event.target.value as string);
    };
    const onChangeSecondaryCountryCode = (event: SelectChangeEvent) => {
        setSecondaryCountryCode(event.target.value as string);
    };

    const onChangeCountryName = (event: SelectChangeEvent) => {
        const countryNameData = event.target.value as string
        setCountryName(countryNameData);
        // eslint-disable-next-line
        const countryCodeData = countryList.filter(function (countryData) {
            if (countryData.name === countryNameData)
                return countryData
        });
        setCountryCode(countryCodeData?.[0].code);
    };

    const onOrganizationNameChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        if (event.target.value === '' || ORGANIZATTION_NAME.test(event.target.value as string)) {
            setOrganizationName(event.target.value as string);
        }
    };

    const handleQuitDialog = () => {
        setOpenQuitDialog(true);
    };

    const handleCancel = () => {
        setOpenQuitDialog(false);
    }

    const onQuitClick = () => {
        if (backRoute === 'OrganizationDetails') {
            pushAppRoute(history,
                { type: 'OrganizationDetails', orgId: orgId },
                { backRoute: 'Organizations', orgId: orgId });
        } else if (backRoute === 'OrgBuildingDetails') {
            pushAppRoute(history,
                { type: 'OrgBuildingDetails', buildingId: assetId },
                { backRoute: 'OrganizationDetails', orgId: orgId, buildingId: buildingId });
        } else {
            pushAppRoute(history, {
                type: backRoute,
            });
        }
        setIsAPICallDone(false);
    }

    const fieldsComplete = (fields: string[]): boolean => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].length < 1) {
                return false;
            }
        }
        return true;
    };

    const setOrgDetails = (organizationDetails: OrganizationData) => {
        setOrganizationId(organizationDetails.projectId);
        setOrganizationName(organizationDetails.name);
        setComments(organizationDetails.description ? organizationDetails.description : '');
        setCompanyAddress(organizationDetails.address ? organizationDetails.address : '');
        setPrimaryFirstName(organizationDetails.primaryFirstName ? organizationDetails.primaryFirstName : '')
        setPrimaryLastName(organizationDetails.primaryLastName ? organizationDetails.primaryLastName : '')
        setSecondaryFirstName(organizationDetails.secondaryFirstName ? organizationDetails.secondaryFirstName : '')
        setSecondaryLastName(organizationDetails.secondaryLastName ? organizationDetails.secondaryLastName : '')
        setCompanyAddress2(organizationDetails.address2 ? organizationDetails.address2 : '');
        setCountryCode(organizationDetails.countryCode);
        setSecondaryCountryCode(organizationDetails.secondaryCountryCode ? organizationDetails.secondaryCountryCode : '');
        setCompanyCity(organizationDetails.city);
        setCompanyPostalCode(organizationDetails.postalCode ? organizationDetails.postalCode : '');
        setCountryName(organizationDetails.country);
        setEmail(organizationDetails.email ? organizationDetails.email : '');
        setSecondaryEmail(organizationDetails.secondaryEmail ? organizationDetails.secondaryEmail : '');
        setPhoneNumber(organizationDetails.phone ? organizationDetails.phone : '');
        setSecondaryPhoneNumber(organizationDetails.secondaryPhone ? organizationDetails.secondaryPhone : '')
    };
    useEffect(() => {
        if (backRoute === 'OrganizationDetails') {
            setHeader(t('ACTIONS.EDIT') + " \"" + organizationName + "\"");
            setOrganizationName(organizationDetails.name);
            setCountryName(organizationDetails.country);
            setOrgDetails(organizationDetails);
        }
        else {
            setHeader(t('DASHBOARD.ADD_ORGANIZATION'));
            setOrgDetails(orgDetailsDefault);
        }
    }, [organizationDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    const saveData = (): void => {
        if (fieldsComplete([organizationName, countryName])) {
            setIsAPICallDone(true);
            dispatch(OrganizationThunks.updateOrganization(
                organizationId,
                addToast,
                authHelper,
                organizationName,
                countryName,
                email,
                countryCode,
                phoneNumber,
                companyAddress,
                companyPostalCode,
                companyCity,
                comments,
                companyAddress2,
                primaryFirstName,
                primaryLastName,
                secondaryFirstName,
                secondaryLastName,
                secondaryEmail,
                secondaryPhoneNumber,
                secondaryCountryCode
            ));
        }
        else {
            addToast("Please fill all the details properly before submission.");
        }
    }

    const onCancel = () => {
        history.goBack();
    }

    return (
        <div data-testid="organizations-details-container">

            <GenericToolbar
                title={header}
                showBackButton={!isFormEdit}
                showCloseButton={isFormEdit}
                actionUIState={savebuttonState}

                isButtonClicked={isAPICallDone}
                backButtonOnClick={(): void => {
                    if (!isFormEdit) {
                        onQuitClick()
                    }
                    else {
                        handleQuitDialog()
                    }
                }}
                saveOnClick={(): void => {
                    saveData()
                }}
                cancelOnClick={(): void => {
                    onCancel()
                }}
                color={appBarColor}
            />

            <div data-testid="container-create-org" style={{ backgroundColor: PXBColors.white[50] }}>

                <div style={{ height: md ? 1250 : 1600, width: '100%', padding: '10%', paddingTop: '3%', display: md ? 'flex' : '' }}>

                    <div style={{ float: 'left', width: md ? '49%' : '', paddingRight: '5%' }}>
                        <div style={{ display: 'flex', gap: 15 }}>
                            <InfoIcon style={{ color: PXBColors.blue[500] }} />
                            <Typography variant='body1' style={{ fontWeight: 400, fontSize: 16 }}>{t('FORMS.HELPING_TEXT')}</Typography>
                        </div>
                        <Typography variant='body1' style={{ marginTop: vh_10, fontWeight: 400, fontSize: 16, color: PXBColors.black[500], fontStyle: 'normal' }}>{t('FORMS.ORGANIZATION_DETAILS_HEADER')}</Typography>
                        <TextField
                            style={{ marginTop: 24, width: '100%', fontSize: '16px', color: PXBColors.black[500] }}
                            id="orgname"
                            data-testid='orgnameLabelId'
                            variant={'filled'}
                            label={t('USER_SETTINGS.ORGANIZATION_NAME')}
                            fullWidth
                            required
                            value={organizationName}
                            onChange={(e): void => onOrganizationNameChange(e)}
                            inputProps={{ 'data-testid': 'orgnameLabelId2', maxLength: ORG_CHARACTER_LIMIT }}
                        />
                        <div data-testid='helperTextId'
                            style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                            <small className="form-text" style={{ width: '85%', float: 'left', paddingLeft: '2%' }}>
                                {t('ORGANIZATION_MANAGE.ORGANIZATION_NAME_HELPER')}</small>
                            <Typography data-testid='charLimitId' style={{ width: 'auto', float: 'right', fontSize: '12px', color: PXBColors.gray[500] }}>
                                {organizationName.length}/{ORG_CHARACTER_LIMIT}
                            </Typography>
                        </div>

                        <TextField
                            style={{ marginTop: 24, width: '100%', float: 'left', wordWrap: 'break-word', color: PXBColors.black[500] }}
                            id="companycomments"
                            data-testid='companycommentsId'
                            variant={'filled'}
                            label={t('LOGBOOK_DETAILS.COMMENTS')}
                            rows={2}
                            multiline
                            fullWidth
                            onChange={(evt: ChangeEvent<HTMLInputElement>): void => setComments(evt.target.value)}
                            value={comments}
                            inputProps={{ 'data-testid': 'company-comments', maxLength: ORG_COMMENTS_LIMIT }}
                        />
                        <div
                            style={{ height: 17, width: '100%', color: PXBColors.gray[500] }}>
                            <small data-testid='helperTextIdTechnician' className="form-text" style={{ width: '86%', float: 'left', paddingLeft: '2%' }}>
                                {t('ORGANIZATION_MANAGE.COMMENTS_HELPER')}</small>
                            <Typography data-testid='orgCommentsLimit' style={{ width: 'auto', float: 'right', fontSize: '12px', color: PXBColors.gray[500] }}>
                                {comments.length}/{ORG_COMMENTS_LIMIT}
                            </Typography>
                        </div>

                    </div>

                    <div style={{ float: 'right', width: md ? '49%' : '', paddingTop: vh_26 }}>
                        <Typography data-testid='orgAddressId' variant='body1' style={{ fontWeight: 400, fontSize: 16, color: PXBColors.black[500], fontStyle: 'normal' }}>{t('FORMS.ORGANIZATION_ADDRESS_HEADER')}</Typography>
                        <TextField
                            data-testid='companyAddressId'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="companyaddress"
                            variant={'filled'}
                            label={t('FORMS.COMPANY_ADDRESS')}
                            fullWidth
                            inputProps={{ 'data-testid': 'company-address', maxLength: COMPANY_ADDRESS_MAX_LENGTH }}
                            value={companyAddress}
                            onChange={(e): void => onCompanyAddressChange(e)}
                        />
                        <TextField
                            data-testid='companyAddressId2'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="companyaddress2"
                            variant={'filled'}
                            label={t('FORMS.ADDRESS_LINE_2') + t('FORMS.OPTIONAL')}
                            fullWidth
                            inputProps={{ 'data-testid': 'company-address2', maxLength: COMPANY_ADDRESS_MAX_LENGTH }}
                            value={companyAddress2}
                            onChange={(e): void => onCompanyAddressChange2(e)}

                        />
                        <TextField
                            style={{ marginTop: 24, width: '35%', marginRight: '2%', textAlign: 'center' }}
                            id="companypostalcode"
                            data-testid='company-postal-code'
                            variant={'filled'}
                            label={t('FORMS.POSTAL_CODE')}
                            error={companyPostalCode.length > 0 && !validPostalCode(companyPostalCode)}
                            helperText={companyPostalCode.length > 0 && !validPostalCode(companyPostalCode) ? (t('FORMS.INVALID_POSTAL_CODE')) : ''}
                            inputProps={{ 'data-testid': 'companyPostalCode', maxLength: COMPANY_POSTALCODE_MAX_LENGTH }}
                            fullWidth
                            value={companyPostalCode}
                            onChange={(e): void => onCompanyPostalCodeChange(e)}
                        />
                        <TextField
                            data-testid='companyCityTestId'
                            style={{ marginTop: 24, width: '63%', textAlign: 'center' }}
                            id="companycity"
                            variant={'filled'}
                            label={t('FORMS.CITY')}
                            inputProps={{ 'data-testid': 'company-city', maxLength: COMPANY_CITY_MAX_LENGTH }}
                            fullWidth
                            value={companyCity}
                            onChange={(e): void => onCompanyCityChange(e)}
                        />
                        <FormControl variant={'filled'} style={{ display: 'flex', marginTop: 24 }}>
                            <InputLabel data-testid='countryNameLabelId' id="country-name-label">{t('FORMS.COUNTRY')}</InputLabel>
                            <Select
                                labelId="country-name-label"
                                id="country-name-label"
                                data-testid='select'
                                fullWidth
                                defaultValue={selectedCountry.name !== '' ? selectedCountry.name : ''}
                                value={countryName}
                                inputProps={{ 'data-testid': 'country-name' }}
                                onChange={onChangeCountryName}
                            >
                                {countryList?.map((e, code) => {
                                    return (
                                        <MenuItem id="country-name-list" key={code} value={e.name}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left', fontSize: 14,
                                                lineHeight: 1.5, fontWeight: 400,
                                                overflow: 'hidden',
                                                boxSizing: 'border-box',
                                                whiteSpace: 'nowrap',
                                                minHeight: 48,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                width: 'auto',
                                                paddingLeft: 16,
                                                paddingRight: 16
                                            }}>
                                            {e.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <Typography variant='body1' style={{ fontWeight: 400, fontSize: 16, color: PXBColors.black[500], fontStyle: 'normal', marginTop: 32, marginBottom: 32 }}>{t('FORMS.LOCAL_PRIMARY_CONTACT_INFO')} </Typography>
                        <TextField
                            data-testid='companyEmailTestId'
                            id="companyemail"
                            variant={'filled'}
                            label={t('FORMS.LOCAL_PRIMARY_EMAIL')}
                            error={email.length > 0 && !validEmail(email)}
                            helperText={email.length > 0 && !validEmail(email) ? (t('FORMS.INVALID_EMAIL_ERROR')) : ''}
                            inputProps={{ 'data-testid': 'company-email', maxLength: ORG_EMAIL_MAX_LENGTH }}
                            fullWidth
                            value={email}
                            onChange={(e): void => setEmail(e.target.value)}
                        />
                        <TextField
                            data-testid='companyFirstName'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="companyFirstNameId"
                            variant={'filled'}
                            label={t('FORMS.FIRST_NAME')}
                            fullWidth
                            inputProps={{ 'data-testid': 'companyFirstNameLabel', maxLength: FIRST_NAME_MAX_LENGTH }}
                            value={primaryFirstName}
                            onChange={(e): void => onPrimaryFirstNameChange(e)}
                        />
                        <TextField
                            data-testid='companyLastName'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="companyLastNameId"
                            variant={'filled'}
                            label={t('FORMS.LAST_NAME')}
                            fullWidth
                            inputProps={{ 'data-testid': 'companyLastNameLabel', maxLength: LAST_NAME_MAX_LENGTH }}
                            value={primaryLastName}
                            onChange={(e): void => onPrimaryLastNameChange(e)}

                        />
                        <div>
                            <Select
                                style={{ marginTop: 24, width: md ? '23%' : '33%', marginRight: '2%', textAlign: 'center' }}
                                variant={'filled'}
                                labelId="countrycode-label"
                                inputProps={{ 'data-testid': 'country-code', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                                id="countrycode-testid"
                                defaultValue={countryCode}
                                value={countryCode}
                                onChange={onChangeCountryCode}
                            >
                                {countryList?.map((e, code) => {
                                    return (
                                        <MenuItem id="country-code-list" key={code} value={e.code}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left', fontSize: 14,
                                                lineHeight: 1.5, fontWeight: 400,
                                                overflow: 'hidden',
                                                boxSizing: 'border-box',
                                                whiteSpace: 'nowrap',
                                                minHeight: 48,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                width: 'auto',
                                                paddingLeft: 16,
                                                paddingRight: 16
                                            }}>
                                            {'+' + e.code}
                                        </MenuItem>
                                    );
                                })}
                            </Select>

                            <TextField
                                data-testid='companyPhoneNumberTestId'
                                style={{ marginTop: 24, width: md ? '75%' : '65%' }}
                                variant={'filled'}
                                label={t('FORMS.PHONE_NUMBER')}
                                error={phoneNumber.length > 0 && !validPhoneNumberLength(phoneNumber)}
                                helperText={phoneNumber.length > 0 && !validPhoneNumberLength(phoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                                inputProps={{ 'data-testid': 'phone-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                                fullWidth
                                value={phoneNumber}
                                onChange={(e): void => onChangePhoneNumber(e)}
                            />
                        </div>
                        <Typography variant='body1' style={{ fontWeight: 400, fontSize: 16, color: PXBColors.black[500], fontStyle: 'normal', marginTop: 32, marginBottom: 32 }}>{t('FORMS.LOCAL_SECONDARY_CONTACT_INFO')}
                        </Typography>
                        <TextField
                            data-testid='companySecondaryEmailTestId'
                            id="companySecondaryemail"
                            variant={'filled'}
                            label={t('FORMS.LOCAL_SECONDARY_EMAIL')}
                            error={secondaryEmail.length > 0 && !validEmail(secondaryEmail)}
                            helperText={secondaryEmail.length > 0 && !validEmail(secondaryEmail) ? (t('FORMS.INVALID_EMAIL_ERROR')) : ''}
                            inputProps={{ 'data-testid': 'companySecondaryemailTestId', maxLength: ORG_EMAIL_MAX_LENGTH }}
                            fullWidth
                            value={secondaryEmail}
                            onChange={(e): void => setSecondaryEmail(e.target.value)}
                        />
                        <TextField
                            data-testid='secondaryFirstName'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="secondaryFirstNameId"
                            variant={'filled'}
                            label={t('FORMS.FIRST_NAME')}
                            fullWidth
                            inputProps={{ 'data-testid': 'secondaryFirstNameTestId', maxLength: COMPANY_ADDRESS_MAX_LENGTH }}
                            value={secondaryFirstName}
                            onChange={(e): void => onSecondaryFirstNameChange(e)}
                        />
                        <TextField
                            data-testid='secondaryLastName'
                            style={{ marginTop: 24, float: 'right', width: '100%' }}
                            id="secondaryLastNameId"
                            variant={'filled'}
                            label={t('FORMS.LAST_NAME')}
                            fullWidth
                            inputProps={{ 'data-testid': 'secondaryLastNameTestId', maxLength: COMPANY_ADDRESS_MAX_LENGTH }}
                            value={secondaryLastName}
                            onChange={(e): void => onSecondaryLastNameChange(e)}

                        />

                        <div>
                            <Select
                                style={{ marginTop: 24, width: md ? '23%' : '33%', marginRight: '2%', textAlign: 'center' }}
                                variant={'filled'}
                                labelId="secondaryCountryCode"
                                inputProps={{ 'data-testid': 'secondaryCountryCodeTestId', maxLength: COUNTRY_CODE_MAX_LENGTH }}
                                id="countrycodetestid"
                                defaultValue={secondaryCountryCode}
                                value={secondaryCountryCode}
                                onChange={(e) => onChangeSecondaryCountryCode(e)}
                            >
                                {countryList?.map((e, code) => {
                                    return (
                                        <MenuItem id="country-code-list" key={code} value={e.code}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'left', fontSize: 14,
                                                lineHeight: 1.5, fontWeight: 400,
                                                overflow: 'hidden',
                                                boxSizing: 'border-box',
                                                whiteSpace: 'nowrap',
                                                minHeight: 48,
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                width: 'auto',
                                                paddingLeft: 16,
                                                paddingRight: 16
                                            }}>
                                            {'+' + e.code}
                                        </MenuItem>
                                    );
                                })}
                            </Select>

                            <TextField
                                data-testid='companySecondaryPhoneNumberTestId'
                                style={{ marginTop: 24, marginBottom: 24, width: md ? '75%' : '65%' }}
                                variant={'filled'}
                                error={secondaryPhoneNumber.length > 0 && !validPhoneNumberLength(secondaryPhoneNumber)}
                                helperText={secondaryPhoneNumber.length > 0 && !validPhoneNumberLength(secondaryPhoneNumber) ? (t('FORMS.INVALID_PHONE_NUMBER')) : ''}
                                label={t('FORMS.PHONE_NUMBER')}
                                inputProps={{ 'data-testid': 'secondary-phone-number', maxLength: PHONE_MAX_LENGTH, minLength: PHONE_MIN_LENGTH }}
                                fullWidth
                                value={secondaryPhoneNumber}
                                onChange={(e): void => onChangeSecondaryPhoneNumber(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Dialog id="transfer-building-dialog" maxWidth="md" open={openQuitDialog} onClose={handleQuitDialog}>
                <DialogTitle id="dialog-header">{t('ORGANIZATION_MANAGE.QUIT_TITLE', { replace: { orgName: organizationDetails.name } })}</DialogTitle>
                <DialogContent>
                    <DialogContentText data-testid="dialog-header-text">
                        {t('ORGANIZATION_MANAGE.QUIT_MESSAGE')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{
                            marginBottom: 10,
                            width: 77,
                            height: 36,
                            flexDirection: 'row',
                            marginRight: 10,
                            backgroundColor: PXBColors.white[50],
                        }}
                        id="cancelButton"
                        color="primary"
                        data-testid="cancelButton"
                        onClick={handleCancel}
                    >
                        {t('ACTIONS.CANCEL')}
                    </Button>
                    <Button
                        style={{
                            marginBottom: 10,
                            width: 64,
                            height: 36,
                            flexDirection: 'row',
                            backgroundColor: PXBColors.white[50],
                            color: PXBColors.red[500],
                        }}
                        id="saveButton"
                        variant="text"
                        data-testid="quitButton"
                        onClick={onQuitClick}
                    >
                        {t('ACTIONS.QUIT')}
                    </Button>
                </DialogActions>
            </Dialog>
            {
                displaySuccessMessage &&
                <Snackbar
                    open={true}
                    onClick={() => setDisplaySuccessMessage(false)}
                    autoHideDuration={20}
                    title={''}
                    message={alertSuccessMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplaySuccessMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.black[900] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}
                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }
            {
                displayErrorMessage &&
                <Snackbar
                    open={true}
                    onClick={() => setDisplayErrorMessage(false)}
                    autoHideDuration={20}
                    title={''}
                    message={alertErrorMessage}
                    action={
                        <Button color="inherit" size="small" onClick={() => setDisplayErrorMessage(false)}>
                            {t('ACTIONS.OKAY')}
                        </Button>
                    }
                    ContentProps={{ sx: { background: PXBColors.red[500] } }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ bottom: { xs: 90, sm: 0 } }}

                    style={{ height: 200, width: 200, flexGrow: 0 }}
                />
            }
        </div >
    );
};
