import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_REGISTRATION_VALIDATION } from './url-constants';

type UserRegistrationRequestValidationEndpointSpecifications = ApiEndpointSpecification;
export const UserRegistrationRequestValidationEndpointSpecification: UserRegistrationRequestValidationEndpointSpecifications = {
    url: USER_REGISTRATION_VALIDATION,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'UserRegistrationRequestValidationRequestParams',
    requestBodySchemaName: 'UserRegistrationRequestValidationRequestBody',
    okResponseSchemaName: 'UserRegistrationRequestValidationOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeUserRegistrationRequestValidation = Helpers.makeApiRequestFn<
    Types.UserRegistrationRequestValidationRequestParams,
    Types.UserRegistrationRequestValidationRequestBody,
    Types.UserRegistrationRequestValidationOkResponse
>(UserRegistrationRequestValidationEndpointSpecification);
