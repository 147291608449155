// eslint-disable-next-line
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppRoutes } from '../../routes';
import { AssetDetails } from './AssetDetails';
import { Assets } from './Assets';
import { AddCustomerDetails } from './AddCustomerDetails';
import { CustomerDetails } from './CustomerDetails';

export const AssetsManagement: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => (
    <>
        <Switch>
            <Route exact path={AppRoutes.Assets} component={Assets} />
            <Route exact path={AppRoutes.AssetDetails} component={AssetDetails} />
            <Route exact path={AppRoutes.CustomerDetails} component={CustomerDetails} />
            <Route exact path={AppRoutes.AddCustomerDetails} component={AddCustomerDetails} />
        </Switch>
    </>
);
