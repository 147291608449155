// eslint-disable-next-line
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Divider, IconButton, List, ListItemSecondaryAction, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import { useHistory, useParams } from 'react-router';
import { GenericToolbar } from '../../components/GenericToolbar';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { SystemLogData } from '../../types/system-log';
import { Thunks } from '../../actions';
import * as PXBColors from '@brightlayer-ui/colors';
import * as MuiIcons from '@material-ui/icons';
import { DeviceState, DEVICE_STATUS, ENTITY_TYPE, getDeviceType, getEntityType, getLogbookAppBarState, getLogbookSeverityStatus, LOGBOOK_SEVERITY_STATUS, USER_ROLE_TYPE } from '../enum/ENUM';
import { TooltipItem } from '../../components/TooltipItem/TooltipItem';
import { AlarmBadgeStatus } from '../../components/AlarmBadge/AlarmBadge';
import { AlarmStatus } from '../../lib/alarm-helpers';
import { AcknowledgedStatusLabel } from '../../components/AcknowledgedStatusLabel';
import moment from 'moment';
import { EmptyState, ListItemTag } from '@brightlayer-ui/react-components';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { LogBookThunks } from '../../actions/thunks/logbook-thunks';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { DEFAULT_PAGE_ONE, EMPTY_STRING, HISTORY_LIMIT, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { pushAppRoute } from '../../data/domain/route-assets';
import { vh_18, vh_65, vw_300 } from '../../constants/dimentions';
import { LogbookLuminaryDetails } from './LogbookLuminaryDetails';
import { LogbookActions } from '../../actions/actions/logbook-actions';
import { getControlledText } from '../enum/GenericFormat';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider';


type Props = {
    location: any;
};

const defaultLogData: SystemLogData = {
    id: '',
    logSourceId: '',
    name: 'Event',
    description: '',
    title: '',
    datetimeutc: '0001-01-01T00:00:00',
    elstatus: -1,
    projectId: '',
    projectName: '',
    buildingName: '',
    buildingId: '',
    systemName: '',
    sublocation: '',
    isactive: false,
    acknowledgedTime: '',
    isAcknowledged: false,
    acknowledgedBy: '',
    partitionKey: '',
    logSourceName: '',
    systemPartNumber: '',
    systemType: 0,
    logSourcePartNumber: '',
    logSourceSerialNumber: '',
    logSourceDeviceType: 0,
    comment: '',
    logStartDate: '0001-01-01T00:00:00',
    logEndDate: '0001-01-01T00:00:00',
    systemId: '',
    subDevice: null,
}

export const LogbookDetails: React.FC<Props> = (props: Props) => {
    const { t } = useLanguageLocale();
    // @ts-ignore
    const { logId } = useParams();
    const { backRoute } = props.location.state || 'Dashboard';
    const [previousNavigationCount, setPreviousNavigationCount] = useState(0);
    const [systemEarlierLog, setSystemEarlierLog] = useState<SystemLogData>(defaultLogData);
    const dispatch = useDispatch();
    const [systemLatestLog, setSystemLatestLog] = useState<SystemLogData>(defaultLogData);
    const history = useHistory();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const { addToast } = useToasts();
    const COMMENTS_LIMIT = 250;
    const logbook = useSelector(Selectors.Dashboard.logbook);
    const [isActive, setIsActive] = useState(false);
    const [openCommentDialog, setOpenCommentDialog] = React.useState(false);
    const [comments, setComments] = React.useState('');
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';

    const serialNumber = t('LOGBOOK_DETAILS.SERIAL_NUMBER');
    const partNumber = t('LOGBOOK_DETAILS.PART_NUMBER');
    const deviceState: DeviceState = getLogbookSeverityStatus(systemLatestLog?.elstatus, systemLatestLog?.isactive);
    const foregroundColor = systemLatestLog?.elstatus === DEVICE_STATUS.STATUS_WARNING ? '#424E54' : PXBColors.white[500];
    const systemStatus = systemLatestLog?.elstatus;
    const systemState: DeviceState = getLogbookSeverityStatus(systemStatus);
    const previousSystemState: DeviceState = getLogbookSeverityStatus(systemEarlierLog.elstatus);
    const appBarTitle = systemStatus == -1 ? t(deviceState.statusTitle) : (t(deviceState.statusTitle) + ":".concat(" ", systemLatestLog?.title));
    const [isLuminaryDetailDrawerOpen, setIsLuminaryDetailDrawerOpen] = React.useState(false);
    const [luminaryId, setLuminaryId] = useState('');
    const [systemId, setSystemId] = useState('');

    const handleOpenCommentDialog = () => {
        setOpenCommentDialog(true);
    };
    const luminaryDetailDrawerClose = (): void => {
        setIsLuminaryDetailDrawerOpen(false);
    };
    const onLuminaryDetailSelect = (): void => {
        setSystemId(systemLatestLog.systemId);
        setLuminaryId(systemLatestLog.logSourceId);
        setIsLuminaryDetailDrawerOpen(true);
    };

    const handleCloseCommentDialog = () => {
        setOpenCommentDialog(false);
        onCancel();
    };

    const onCommentsSave = (): void => {
        dispatch(LogBookThunks.upsertLogComments(
            logId,
            comments,
            addToast,
        ));
    }

    useEffect(() => {
        if (systemLatestLog?.isactive)
            setIsActive(true);
        else
            setIsActive(false);
    }, [systemLatestLog])

    useEffect(() => {
        if (!logbook.latestLogListLoading && logbook.latestLogListSuccess) {
            if (logbook.latestLogList.detail.length > 0) {
                setSystemLatestLog(logbook.latestLogList.detail[0]);
            }
            dispatch(Thunks.Logbook.getPreviousLogs(logbook.latestLogList.detail[0]?.logSourceId, logbook.latestLogList.detail[0]?.datetimeutc));
        }
    }, [logbook.latestLogListLoading, logbook.latestLogListSuccess, logbook.latestLogListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!logbook.previousLogLoading && logbook.previousLogSuccess) {
            setSystemEarlierLog(logbook.previousLogData)
        } else if (!logbook.previousLogLoading && logbook.previousLogFail) {
            console.log('Previous log list loading failed');
        }
    }, [logbook.previousLogLoading, logbook.previousLogSuccess, logbook.previousLogFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(Thunks.Logbook.getLatestLogs('', '', RECORD_PER_PAGE_LIMIT.toString(), DEFAULT_PAGE_ONE.toString(), logId, '', '', '', [], false, '', usertype === USER_ROLE_TYPE.SERVICE_MANAGER));
    }, [logId]);// eslint-disable-line react-hooks/exhaustive-deps


    function OnAcknowledgeClick(): void {
        dispatch(LogBookThunks.acknowledgeLog(
            logId,
            true,
            addToast,
        ));
    }

    const editComments = (text: string) => {
        setOpenCommentDialog(true)
        setComments(text);
    };

    const onCancel = () => {
        setComments(EMPTY_STRING);
    };


    const goToAssetDetails = (): void => {
        pushAppRoute(
            history,
            { type: 'AssetDetails', deviceId: systemLatestLog.systemId, gatewayId: systemLatestLog.systemId, projectId: systemLatestLog.projectId },
            { backRoute: 'LogbookDetails', logId: systemLatestLog.id, deviceId: systemLatestLog.systemId, gatewayId: systemLatestLog.systemId }
        );
    };

    const goToPreviouslog = (): void => {
        setPreviousNavigationCount(previousNavigationCount + 1);
        pushAppRoute(
            history,
            { type: 'LogbookDetails', logId: systemEarlierLog.id },
            { backRoute: 'LogbookDetails', logId: systemEarlierLog.id, systemId: systemEarlierLog.systemId }
        );
    };


    useEffect(() => {
        if (!logbook.upsertLogCommentsLoading && logbook.upsertLogCommentsSuccess) {
            addToast(t('TOAST_MSG.UPDATE_LOG_COMMENTS_SUCCESS'), 'success');
            dispatch(LogbookActions.upsertLogCommentsUnmounted());
            handleCloseCommentDialog();
            dispatch(Thunks.Logbook.getLatestLogs('', '', RECORD_PER_PAGE_LIMIT.toString(), DEFAULT_PAGE_ONE.toString(), logId, '', '', '', [], false, '', usertype === USER_ROLE_TYPE.SERVICE_MANAGER));
        }
        else if (!logbook.upsertLogCommentsLoading && logbook.upsertLogCommentsFail) {
            addToast(t('TOAST_MSG.UPDATE_LOG_COMMENTS_API_FAILURE'), 'error');
        }
    }, [logbook.upsertLogCommentsLoading, logbook.upsertLogCommentsSuccess, logbook.upsertLogCommentsFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!logbook.acknowledgeLogLoading && logbook.acknowledgeLogSuccess) {
            addToast(t('TOAST_MSG.ACKNOWLEDGE_LOG_SUCCESS'), 'success');
            dispatch(LogbookActions.acknowledgeLogUnmounted());
            dispatch(Thunks.Logbook.getLatestLogs('', '', RECORD_PER_PAGE_LIMIT.toString(), DEFAULT_PAGE_ONE.toString(), logId, '', '', '', [], false, '', usertype === USER_ROLE_TYPE.SERVICE_MANAGER));
        }
        else if (!logbook.acknowledgeLogLoading && logbook.acknowledgeLogFail) {
            addToast(t('TOAST_MSG.ACKNOWLEDGE_LOG_API_FAILURE'), 'error');
        }
    }, [logbook.acknowledgeLogLoading, logbook.acknowledgeLogSuccess, logbook.acknowledgeLogFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const textSystemLocation = systemLatestLog?.projectName + " > " + systemLatestLog?.buildingName + " > " + systemLatestLog?.sublocation;

    //Handling log date time in UTC format
    const eventDate = moment(systemLatestLog?.datetimeutc).format('DD MMM YYYY') + ', ' + moment(systemLatestLog?.datetimeutc).format('hh:mm A') + ' (' +
        moment(systemLatestLog?.datetimeutc).fromNow(true) + t('LOGBOOK_DETAILS.LABEL_EVENT_AGO') + ')';

    //const eventDate = systemLatestLog.logStartDate;
    //ToDo - confirm format of date time and display hours difference

    const eventStartDate = t('LOGBOOK_DETAILS.LABEL_EVENT_BEGAN') + moment(systemLatestLog?.logStartDate).format('DD MMM YYYY') + ', ' + moment(systemLatestLog?.logStartDate).format('hh:mm A');

    const eventCloseDate = moment(systemLatestLog?.logEndDate).format('DD MMM YYYY') + ', ' + moment(systemLatestLog?.logEndDate).format('hh:mm A');
    const subTitle = (systemLatestLog?.isactive && systemLatestLog?.isAcknowledged) ? t('LOGBOOK_DETAILS.ACTIVE_ACKNOWLEDGED') : (systemLatestLog?.isactive ? t('LOGBOOK_DETAILS.ACTIVE') : '')

    return (
        <>
            <GenericToolbar
                title={appBarTitle}
                subtitle={subTitle}
                color={isActive ? getLogbookAppBarState(systemLatestLog.elstatus) : ToolbarColor.Default}
                showBackButton={true}//backButtonState
                backButtonOnClick={(): void => {
                    if (backRoute === 'LogbookDetails' && previousNavigationCount > 0) {
                        history.go(-previousNavigationCount);
                    }
                    else if (backRoute === 'Logbook') {
                        pushAppRoute(
                            history,
                            { type: 'Logbook' },
                            { backRoute: 'Logbook', logId: systemLatestLog.id }
                        );
                    }
                    else if (history.length > HISTORY_LIMIT)
                        history.goBack();
                    else
                        window.location.href = '/Dashboard';
                }}
            />
            {logbook.latestLogListLoading && <EmptyState style={{ flex: 1, minHeight: 400 }}
                icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
            {!logbook.latestLogListLoading && !logbook.previousLogLoading && <div style={{ width: md ? '75%' : '90%', margin: 'auto', paddingTop: '5%' }} data-testid="logbook-details">

                {usertype !== USER_ROLE_TYPE.SERVICE_MANAGER && !systemLatestLog?.isAcknowledged && systemLatestLog?.elstatus !== LOGBOOK_SEVERITY_STATUS.STATUS_EVENT && <div style={{ display: 'flex' }}>
                    <Button
                        style={{
                            backgroundColor: PXBColors.blue[500],
                            color: PXBColors.white[500],
                            wordBreak: 'break-all'
                        }}
                        id="acknowledge-button"
                        variant="text"
                        color="primary"
                        data-testid="acknowledge-button"
                        onClick={(): void => OnAcknowledgeClick()}
                    >
                        {t('LOGBOOK_DETAILS.ACKNOWLEDGE')}
                    </Button>
                </div>}

                {systemLatestLog?.isAcknowledged && systemLatestLog?.elstatus !== LOGBOOK_SEVERITY_STATUS.STATUS_EVENT && <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        style={{

                            backgroundColor: PXBColors.blue[50],
                            color: PXBColors.blue[200],
                            wordBreak: 'break-all'
                        }}
                        id="acknowledged-button"
                        variant="text"
                        color="primary"
                        data-testid="acknowledged-button"
                    >
                        {t('LOGBOOK_DETAILS.ACKNOWLEDGED')}
                    </Button>
                    <Typography style={{
                        fontSize: 14,
                        paddingLeft: 16
                    }}>{t('LOGBOOK_DETAILS.ACKNOWLEDGED_BY')} {systemLatestLog?.acknowledgedBy} {t('LOGBOOK_DETAILS.AT')} {moment(systemLatestLog?.acknowledgedTime).format('DD MMM YYYY') + ', ' + moment(systemLatestLog?.datetimeutc).format('hh:mm A')}</Typography>
                </div>}

                {(systemLatestLog?.comment) && <div data-testid='commentBox' style={{ display: 'flex', padding: '20px 0' }}>
                    <ListItem style={{ display: 'flex', background: 'white', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', gap: 24, borderRadius: 4, boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)' }}>
                        <div style={{ wordWrap: 'break-word' }}>
                            <Typography style={{ fontSize: 12, lineHeight: 1.7 }}>{t('LOGBOOK_DETAILS.COMMENTS')}</Typography>
                            <Typography style={{ fontWeight: 600, fontSize: 14 }}>{systemLatestLog?.comment ? systemLatestLog.comment : ''}</Typography>
                        </div>
                        <Button
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 8,
                                width: 58,
                                height: 36,
                                position: 'relative',
                                right: 15,
                                backgroundColor: PXBColors.white[50],
                            }}
                            id="editButton"
                            variant="outlined"
                            color="primary"
                            data-testid="edit-button"
                            onClick={(): void => editComments(systemLatestLog?.comment ? systemLatestLog?.comment : '')}
                        >
                            {t('LOGBOOK_DETAILS.EDIT')}
                        </Button>
                    </ListItem>
                </div>}

                {usertype !== USER_ROLE_TYPE.SERVICE_MANAGER && !systemLatestLog?.comment && <div data-testid='nonActiveCommentBox' style={{ display: 'flex', padding: '20px 0' }}>
                    <ListItem style={{ display: 'flex', flexDirection: 'row', background: 'white', gap: 24, height: 60, width: '100%', borderRadius: 4, boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)' }}>
                        <Button variant="text" style={{ color: PXBColors.blue[500], fontSize: 14, fontWeight: 400, }} onClick={handleOpenCommentDialog}>
                            {t('LOGBOOK_DETAILS.ADD_COMMENTS')}
                        </Button>
                    </ListItem>
                </div>}

                <div data-testid='listComponent' style={{ display: 'flex', width: '100%', }}>
                    <List style={{ display: 'flex', flexDirection: 'column', width: '100%', background: '#FFFFFF', borderRadius: 4 }}>
                        {systemLatestLog?.elstatus !== LOGBOOK_SEVERITY_STATUS.STATUS_EVENT &&
                            <><div data-testid="status-id">
                                <ListItem style={{ display: 'flex', justifyContent: 'flex-end', height: 55 }}
                                    secondaryAction={
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {systemLatestLog?.isactive && (
                                                <AlarmBadgeStatus
                                                    alarmStatus={AlarmStatus.Open}
                                                    statusColor={deviceState.statusColor}
                                                    foregroundColor={foregroundColor}
                                                />
                                            )}
                                            {!systemLatestLog?.isactive && !systemLatestLog?.isAcknowledged && (
                                                <ListItemTag style={{ backgroundColor: 'white', color: 'black', fontWeight: 700, border: '1px solid #424E54', marginRight: 10, height: 18 }} label={t('LOGBOOK_DETAILS.LABEL_CLOSED')} translate={undefined} placeholder={undefined} />
                                            )}
                                            {systemLatestLog?.isAcknowledged === true && <AcknowledgedStatusLabel></AcknowledgedStatusLabel>}
                                        </div>
                                    }
                                >
                                    <ListItemText data-testid="list-item-status">
                                        <TooltipItem
                                            title={'TITLE'}
                                            isTopPlacement={true}
                                            lenghtLimit={30}
                                            component={
                                                <Typography
                                                    display="inline" id="list-item-title"
                                                    style={{ fontSize: 14, fontWeight: 600 }}>
                                                    {t('LOGBOOK_DETAILS.STATUS')}
                                                </Typography>
                                            }>
                                        </TooltipItem>
                                    </ListItemText>
                                </ListItem>
                            </div>

                                <Divider style={{ color: 'black' }}></Divider>
                            </>
                        }
                        <div data-testid="timestamp-log-id" style={{ height: 'auto' }}>
                            <ListItem style={{ display: 'flex', justifyContent: 'flex-end', height: 'auto', width: md ? '100%' : '105%' }}
                                secondaryAction={

                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        {!systemLatestLog?.isactive && systemLatestLog?.elstatus !== LOGBOOK_SEVERITY_STATUS.STATUS_EVENT && <div style={{ display: 'flex' }}>
                                            <Typography style={{ fontWeight: 600, fontSize: 14, paddingRight: 5 }}>{t('LOGBOOK_DETAILS.LABEL_EVENT_CLOSE')} </Typography>
                                            <Typography style={{ fontWeight: 400, fontSize: 14 }}>{eventCloseDate} </Typography>
                                        </div>}

                                        <Typography style={{ fontWeight: 400, paddingRight: md ? 16 : 0, fontSize: systemLatestLog?.isactive ? 14 : 12 }}>{systemLatestLog?.isactive ? eventDate : eventStartDate}</Typography>
                                    </div>
                                }
                            >
                                <ListItemText sx={{ width: '60%', justifyContent: 'flex-end' }}>
                                    <TooltipItem
                                        title={'TITLE'}
                                        isTopPlacement={true}
                                        lenghtLimit={30}
                                        component={
                                            <Typography
                                                display="inline" id="list-item-title"
                                                style={{ fontSize: 14, fontWeight: 600 }}>
                                                {t('LOGBOOK_DETAILS.TIMESTAMP')}
                                            </Typography>
                                        }>
                                    </TooltipItem>
                                </ListItemText>
                            </ListItem>
                        </div>
                        <Divider style={{ color: 'black' }}></Divider>

                        <ListItem data-testid="system-list-item" key={'system'} sx={{ padding: '25px 0', paddingLeft: 2, display: 'flex', width: '40%', flexWrap: 'wrap' }}>
                            <ListItemText>
                                <Typography
                                    style={{ fontSize: 14, fontWeight: 600 }}>
                                    {t('LOGBOOK_DETAILS.SYSTEM')}
                                </Typography>
                            </ListItemText>
                            <ListItemSecondaryAction sx={{ width: '60%', right: 0 }}>
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
                                >
                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
                                    >
                                        <Typography style={{ fontWeight: 600, fontSize: 14 }}>{systemLatestLog?.systemName} </Typography>
                                        <Typography style={{ fontSize: 14, fontWeight: 400 }}>{getDeviceType(systemLatestLog?.systemType)}{', '} {systemLatestLog?.systemPartNumber}</Typography>
                                        <TooltipItem
                                            title={textSystemLocation}
                                            isTopPlacement={false}
                                            lenghtLimit={30}
                                            component={
                                                <Typography style={{ fontSize: 12, fontWeight: 400, color: PXBColors.black[500] }}>{getControlledText(textSystemLocation, 50)}</Typography>
                                            }></TooltipItem>
                                    </div>

                                    <IconButton onClick={goToAssetDetails} style={{ position: 'sticky' }} >
                                        <MuiIcons.ChevronRight id="system-log-list-item-next-icon" />
                                    </IconButton>
                                </div>
                            </ListItemSecondaryAction>

                        </ListItem>

                        <Divider style={{ color: 'black' }}></Divider>

                        <ListItem key={'device'} sx={{ padding: '25px 0', paddingLeft: 2 }}>
                            <Typography
                                style={{ fontSize: 14, fontWeight: 600 }}>
                                {t('LOGBOOK_DETAILS.DEVICE')}
                            </Typography>
                            <ListItemSecondaryAction sx={{ width: '50%', right: 0 }}>
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
                                >
                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: systemLatestLog?.logSourceDeviceType === ENTITY_TYPE.LUMINARY ? '' : 40 }}
                                    >
                                        <Typography style={{ fontWeight: 600, fontSize: 14 }}>{systemLatestLog?.logSourceName} </Typography>
                                        <Typography style={{ fontSize: 12, fontWeight: 400 }}>{t('LOGBOOK_DETAILS.SYSTEM_TYPE')}{t(getEntityType(systemLatestLog?.logSourceDeviceType)) + ',' + partNumber + (systemLatestLog?.logSourcePartNumber !== null ? systemLatestLog?.logSourcePartNumber : '--') + ',' + serialNumber + (systemLatestLog?.logSourceSerialNumber !== null ? systemLatestLog?.logSourceSerialNumber : '--')}</Typography>
                                    </div>

                                    {systemLatestLog?.logSourceDeviceType === ENTITY_TYPE.LUMINARY && <IconButton style={{ position: 'sticky' }} onClick={(): void => onLuminaryDetailSelect()} >
                                        <MuiIcons.ChevronRight id="system-log-list-item-next-icon" />
                                    </IconButton>}

                                </div>

                            </ListItemSecondaryAction>
                        </ListItem>

                        <Divider style={{ color: 'black' }}></Divider>
                        <div data-testid="status-Image" style={{ height: 'auto' }} >
                            <ListItem style={{ display: 'flex', height: md ? "auto" : 80, width: md ? '100%' : '40%' }}>
                                <ListItemText data-testid="description" sx={{ width: '40%' }} >
                                    <TooltipItem
                                        title={'TITLE'}
                                        isTopPlacement={true}
                                        lenghtLimit={30}
                                        component={
                                            <Typography
                                                display="inline" id="list-item-title"
                                                style={{ fontSize: 14, fontWeight: 600 }}>
                                                {t('LOGBOOK_DETAILS.DESCRIPTION')}
                                            </Typography>
                                        }>
                                    </TooltipItem>
                                </ListItemText>
                                <ListItemSecondaryAction sx={{ width: '60%', right: md ? 16 : 0 }} >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        <Button variant="outlined"
                                            id="device-status-button"
                                            style={{ borderRadius: 30, borderColor: systemState.statusColor, fontSize: 14, color: '#424E54', fontWeight: 600, textTransform: 'none', height: 30, marginRight: 15 }}
                                            startIcon={<img
                                                src={systemState.statusImage}
                                                alt="logo"
                                                style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', padding: 0 }}
                                                id="list-item-status-icon" />}>
                                            {t(systemState.statusTitle)}
                                        </Button>
                                        <Typography style={{ fontSize: 14 }}>
                                            {systemLatestLog?.title}
                                        </Typography>
                                    </div>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </div>

                        <Divider style={{ color: 'black' }}></Divider>

                        <ListItem key={'previous-log'} sx={{ padding: '25px 0', paddingLeft: 2, display: 'flex', width: '40%', flexWrap: 'wrap', height: md ? "auto" : 125 }}>
                            <Typography
                                style={{ fontSize: 14, fontWeight: 600 }}>
                                {t('LOGBOOK_DETAILS.PREVIOUS_LOG_ITEM')}
                            </Typography>
                            {!logbook.previousLogLoading && logbook.previousLogSuccess && <ListItemSecondaryAction sx={{ width: '60%', right: 0 }}>
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
                                >
                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: 30 }}
                                    >

                                        <div style={{ display: 'flex' }}>
                                            <Typography style={{ fontWeight: 600, fontSize: 14 }}>{t(previousSystemState.statusTitle) + ':'} </Typography>
                                            <Typography style={{ fontSize: 14, fontWeight: 400 }}>{getControlledText(systemEarlierLog?.description, 25)}</Typography>
                                        </div>
                                        <Typography style={{ fontSize: 12, fontWeight: 400 }}>{
                                            //systemEarlierLog.logStartDate
                                            //systemEarlierLog.logSourceName
                                            moment(systemEarlierLog.datetimeutc).format('DD MMM YYYY') + ", " + moment(systemEarlierLog.logStartDate).format('hh:mm A')
                                        }</Typography>
                                        <Typography style={{ fontSize: 12, fontWeight: 400 }}> {systemEarlierLog.logSourceName + ", " + systemEarlierLog?.logSourcePartNumber}</Typography>
                                    </div>
                                    <IconButton onClick={goToPreviouslog} style={{ position: 'sticky' }}  >
                                        <MuiIcons.ChevronRight id="system-log-list-item-next-icon" />
                                    </IconButton>
                                </div>
                            </ListItemSecondaryAction>}
                            {logbook.previousLogFail && !logbook.previousLogLoading && <ListItemSecondaryAction sx={{ width: '60%', right: 0 }}>
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
                                >
                                    <div
                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: 30 }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <Typography style={{ fontWeight: 600, fontSize: 14 }}> {t('LOGBOOK_DETAILS.NO_PREVIOUS_LOG')} </Typography>
                                        </div>
                                    </div>
                                </div>
                            </ListItemSecondaryAction>
                            }

                        </ListItem>

                    </List>
                </div>
                <Box
                    display="flex"
                    style={{
                        top: vh_65,
                        justifyContent: 'flex-end',
                        right: vh_18,
                        width: md ? vw_300 : '',
                        zIndex: 100,
                        minHeight: '90%',
                        position: 'absolute',
                        transitionDelay: '0.2s',
                        visibility: isLuminaryDetailDrawerOpen ? 'visible' : 'hidden',
                    }}
                    data-testid="luminary-details"
                >
                    <LogbookLuminaryDetails
                        systemId={systemId}
                        luminaryId={luminaryId}
                        closeClick={luminaryDetailDrawerClose} />
                </Box>
            </div >

            }

            <Dialog id='commentDialogBox' fullWidth open={openCommentDialog} onClose={handleCloseCommentDialog} style={{ borderRadius: 4, boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)' }} >
                <DialogContent id='commentBoxContent' style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, paddingBottom: 5 }}>
                    <TextField
                        style={{ width: '100%', float: 'left', wordWrap: 'break-word', color: PXBColors.black[500] }}
                        id="comments"
                        data-testid='comments'
                        variant={'filled'}
                        label={t('LOGBOOK_DETAILS.COMMENTS')}
                        rows={2}
                        multiline
                        fullWidth
                        value={comments}
                        onChange={(evt: ChangeEvent<HTMLInputElement>): void => setComments(evt.target.value)}
                        inputProps={{ 'data-testid': 'company-comments', maxLength: COMMENTS_LIMIT }}
                    />
                    <Typography style={{ width: 'auto', float: 'right', fontSize: 12, color: PXBColors.gray[500] }}>
                        {comments.length}/{COMMENTS_LIMIT}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button
                        style={{
                            marginBottom: 10,
                            width: 'auto',
                            height: 36,
                            flexDirection: 'row',
                            marginRight: 10,
                            backgroundColor: PXBColors.white[50],
                        }}
                        id="cancelButton"
                        variant="outlined"
                        color="primary"
                        data-testid="cancelButton"
                        onClick={handleCloseCommentDialog}
                    >
                        {t('ACTIONS.CANCEL')}
                    </Button>
                    <Button
                        style={{
                            marginBottom: 10,
                            width: 'auto',
                            height: 36,
                            flexDirection: 'row',
                            backgroundColor: PXBColors.blue[500],
                            color: PXBColors.white[50],
                        }}
                        id="saveButton"
                        data-testid="saveButton"
                        onClick={onCommentsSave}
                    >
                        {t('ACTIONS.SAVE')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
