import { State } from '../state';

export const RegistrationSelectors = {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    registration: (state: State) => state.Registration,
    verifyCode: (state: State): string => state.Registration.verificationCode ? state.Registration.verificationCode : '',
    validateEmailLoading: (state: State): boolean => state.Registration.validateEmailLoading,
    validateEmailFail: (state: State): boolean => state.Registration.validateEmailFail,
    validateEmailSuccess: (state: State): boolean => state.Registration.validateEmailSuccess,

    verificationCodeLoading: (state: State): boolean => state.Registration.verificationCodeLoading,
    verificationCodeFail: (state: State): boolean => state.Registration.verificationCodeFail,
    verificationCodeSuccess: (state: State): boolean => state.Registration.verificationCodeSuccess,

    verifyCodeLoading: (state: State): boolean => state.Registration.verifyCodeLoading,
    verifyCodeFail: (state: State): boolean => state.Registration.verifyCodeFail,
    verifyCodeSuccess: (state: State): boolean => state.Registration.verifyCodeSuccess,

    countryListLoading: (state: State): boolean => state.Registration.countryListLoading,
    countryListFail: (state: State): boolean => state.Registration.countryListFail,
    countryListSuccess: (state: State): boolean => state.Registration.countryListSuccess,

    registrationLoading: (state: State): boolean => state.Registration.registrationLoading,
    registrationFail: (state: State): boolean => state.Registration.registrationFail,
    registrationSuccess: (state: State): boolean => state.Registration.registrationSuccess,

    userProfileAccessLoading: (state: State): boolean => state.Registration.upsertUserAccessProfileLoading,
    userProfileAccessFail: (state: State): boolean => state.Registration.upsertUserAccessProfileFail,
    userProfileAccessSuccess: (state: State): boolean => state.Registration.upsertUserAccessProfileSuccess,
};
