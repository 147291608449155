// eslint-disable-next-line
import React, { useEffect } from 'react';
import { IconButton, Paper, List, ListItem, ListItemText, Card, Typography, CardContent } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronRight } from '@mui/icons-material';
import { AppRoute } from '../../routes';
import { GenericToolbar } from '../../components/GenericToolbar';
import { ContentContainer } from '../../components/ContentContainer';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import _ from 'lodash';
import { DataIndicators } from '../../components/DataIndicators';
import { isDataLoaded } from '../../types/data-providers';
import { useLegalContentData, LegalContentDataProvider } from '../../data/DataProviders/LegalContentDataProvider';
import { Actions } from '../../actions';
import { Selectors } from '../../selectors';
import { useCurrentAppRoute } from '../../hooks/app-route-hooks';
import { PDFRender } from '../../components/PDFRender/PDFRender';
import { EULA_FILE_PATH } from '../../constants';
import MediaQuery from 'react-responsive';

export const LegalContent: React.FC<React.PropsWithChildren<React.PropsWithChildren<{
    title: string;
    backRoute: AppRoute;
    contentTitle?: string;
}>>> = (props) => {
    const history = useHistory();
    const legalContentData = useLegalContentData();

    const isEmpty = isDataLoaded(legalContentData) && _.isEmpty(legalContentData.value.content);
    return (
        <>
            <GenericToolbar
                title={props.title}
                showBackButton
                backButtonOnClick={(): void => pushAppRoute(history, props.backRoute)}
            />
            <ContentContainer data-testid={'container'}>
                <DataIndicators
                    errorMessage={`Error Loading ${props.contentTitle}`}
                    emptyMessage={`No ${props.contentTitle} found`}
                    data={legalContentData}
                    emptyIcon="Devices"
                    isEmpty={isEmpty}
                />
                {isDataLoaded(legalContentData) && !isEmpty && (
                    <LegalContentDetails content={legalContentData.value.content || ''} title={props.contentTitle} />
                )}
            </ContentContainer>
        </>
    );
};

export const OpenSourceList: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ backRoute: AppRoute }>>> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useLanguageLocale();
    const legal = useSelector(Selectors.Legal.legal);

    useEffect(() => {
        dispatch(Actions.Legal.loadOpenSourceSucceeded());
    }, [dispatch]);

    const items = legal && legal.openSourceList ? legal.openSourceList : [];

    return (
        <>
            <GenericToolbar
                title={t('LEGAL.OPENSOURCELICENSES')}
                showBackButton
                backButtonOnClick={(): void => pushAppRoute(history, props.backRoute)}
            />
            <ContentContainer>
                <Paper style={{ textAlign: 'justify' }}>
                    <List className="list" disablePadding data-testid={'open-source-menu'}>
                        {items.map((
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            value: any
                        ) => (
                            <ListItem
                                className="selected"
                                divider={true}
                                style={{ height: 72, cursor: 'pointer' }}
                                key={value.id}
                                onClick={(): void => {
                                    pushAppRoute(history, { type: 'OpenSourceContent', name: value.name });
                                }}
                            >
                                <ListItemText
                                    primary={value.name}
                                    primaryTypographyProps={{
                                        variant: 'body1',
                                        style: { fontWeight: 600 },
                                    }}
                                ></ListItemText>
                                <IconButton>
                                    <ChevronRight />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </ContentContainer>
        </>
    );
};

export const OpenSourceContent: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ backRoute: AppRoute }>>> = (props) => {
    const currentAppRoute = useCurrentAppRoute();

    if ('name' in currentAppRoute) {
        return (
            <LegalContentDataProvider filename={`${currentAppRoute.name}.txt`}>
                <LegalContent title={currentAppRoute.name} backRoute={props.backRoute} />
            </LegalContentDataProvider>
        );
    }

    return null;
};

const LegalContentDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ content: string; title?: string }>>> = (props) => {
    const { t } = useLanguageLocale();
    return (
        <>
            <MediaQuery  maxWidth={450}>
                {props.title === t('LEGAL.EULA') && process.env.NODE_ENV !== 'test' && (
                    <PDFRender file={EULA_FILE_PATH} pageCount={14} width={380} />
                )}
            </MediaQuery>
            <MediaQuery minWidth={450} maxWidth={800}>
                {props.title === t('LEGAL.EULA') && process.env.NODE_ENV !== 'test' && (
                    <PDFRender file={EULA_FILE_PATH} pageCount={14} width={600} />
                )}
            </MediaQuery>
            <MediaQuery minWidth={801} >
                {props.title === t('LEGAL.EULA') && process.env.NODE_ENV !== 'test' && (
                    <PDFRender file={EULA_FILE_PATH} pageCount={14} width={900} />
                )}
            </MediaQuery>
            {(props.title !== t('LEGAL.EULA') || process.env.NODE_ENV === 'test') && (
                <Paper style={{ textAlign: 'justify', maxWidth: 1200, marginLeft: '5%' , marginRight: '5%' }} data-testid={'eula-content'}>
                    <Card>
                        {props.title && (
                            <Typography variant={'h4'} style={{ fontWeight: 600, textAlign: 'center', padding: 28 }}>
                                {props.title}
                            </Typography>
                        )}
                        <CardContent>
                            <Typography component="pre" style={{ whiteSpace: 'pre-wrap' }}>
                                {props.content}
                            </Typography>
                        </CardContent>
                    </Card>
                </Paper>
            )}
        </>
    );
};
