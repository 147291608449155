import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_ROLES } from './url-constants';

type UserRoleEndpointSpecifications = ApiEndpointSpecification;
export const UserRoleEndpointSpecification: UserRoleEndpointSpecifications = {
    url: USER_ROLES,
    method: HttpMethod.GET,
    requestParamsSchemaName: 'UserRoleRequestParams',
    requestBodySchemaName: 'UserRoleRequestBody',
    okResponseSchemaName: 'UserRoleOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeUserRole = Helpers.makeApiRequestFn<
    Types.UserRoleRequestParams,
    Types.UserRoleRequestBody,
    Types.UserRoleOkResponse
>(UserRoleEndpointSpecification);
