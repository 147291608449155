// eslint-disable-next-line
import React from 'react';
import {
    ListItemText,
    Typography,
} from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';

export const UserDetailsListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<{
    title: string;
    value: string;
    testid?: string;
    size?: number;
}>>> = (props) => {
    return (
        <ListItemText>
            <Typography data-testid={props.testid} style={{ paddingLeft: 16, fontWeight: 600, fontSize: props.size ? props.size : 16, lineHeight: 2, fontStyle: 'normal', color: PXBColors.black[500] }}  >
                {props.value}
            </Typography>
            <Typography style={{ paddingLeft: 16, fontWeight: 400, fontSize: 14, lineHeight: 1, color: PXBColors.gray[500] }}>{props.title}</Typography>
        </ListItemText>
    );
};
