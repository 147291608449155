import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { ADD_SERVICEPARTNER_CUSTOMER_DETAIL } from './url-constants';

type AddServicePartnerCustomerEndpointSpecifications = ApiEndpointSpecification;
export const AddServicePartnerCustomerEndpointSpecification: AddServicePartnerCustomerEndpointSpecifications = {
    url: ADD_SERVICEPARTNER_CUSTOMER_DETAIL,
    method: HttpMethod.PUT,
    requestParamsSchemaName: 'AddServicePartnerCustomerRequestParams',
    requestBodySchemaName: 'AddServicePartnerCustomerRequestBody',
    okResponseSchemaName: 'AddServicePartnerCustomerRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeAddServicePartnerCustomerManagerRequest = Helpers.makeApiRequestFn<
    Types.AddServicePartnerCustomerRequestParams,
    Types.AddServicePartnerCustomerRequestBody,
    Types.AddServicePartnerCustomerRequestOkResponse
>(AddServicePartnerCustomerEndpointSpecification);
