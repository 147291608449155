// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { BuildingList } from '../../types/building';
import MapContainer from '../../components/MapContainer/MapContainer';
import MapGeneric from '../../components/MapContainer/MapGeneric';
import { EmptyState } from '@brightlayer-ui/react-components';
import { CircularProgress } from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';

const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";

type Props = {
    userId?: string;
    projectId?: string;
    buildingId: string;
    onBuildingNameClicked: Function;
};

export const OverviewMap: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const [selectedBuildingId, setBuildingId] = useState(props.buildingId);
    const [buildingList, setBuildingList] = useState<BuildingList>([]); //all buildings

    const getBuildingList = (list: BuildingList): BuildingList => {
        return list.map((data) => {
            return {
                buildingId: data.buildingId,
                name: data.name,
                elStatus: data.elStatus,
                city: data.city,
                latitude: data.latitude,
                longitude: data.longitude,
                elStatusName: data.elStatusName,
                parentProjectId: data.parentProjectId,
                projectName: data.projectName,
                address: data.address,
                gatewayCount: data.gatewayCount,
                systemCount: data.systemCount,
                isFavourite: data.isFavourite,
                secondaryEmail: data.secondaryEmail,
                secondaryCountryCode: data.secondaryCountryCode,
                secondaryPhone: data.secondaryPhone,
                primaryFirstName: data.primaryFirstName,
                primaryLastName: data.primaryLastName

            };
        });
    };

    useEffect(() => {
        if (dashboard.buildingListLoading && !dashboard.buildingListSuccess && !dashboard.buildingListFail) {
            setBuildingList([]);
        }//ToDo - validate imapct on refresh
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            setBuildingList(getBuildingList(dashboard.buildingList.detail));
        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            // addToast('Building list loading failed');
            setBuildingList([]);
        }
        setBuildingId(props.buildingId);
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    //filter selected building from building list
    const buildItem = buildingList.filter(function (buildItem) {
        return buildItem.buildingId === props.buildingId;
    });
    //selected building details object for creating marker
    const selectedBuildingMarkerProps = buildItem.map((element) => ({
        id: element.buildingId,
        name: element.name,
        position: { lat: getFloatToNumber(element.latitude), lng: getFloatToNumber(element.longitude) },
        elStatus: element.elStatus,
        city: element.city,
        address: element.address,
        systemCount: element.systemCount,
        parentProjectId: element.parentProjectId
    }));

    // All buildings marker list
    const buildingMarkerProps = buildingList.map((element) => ({
        id: element.buildingId,
        name: element.name,
        position: { lat: getFloatToNumber(element.latitude), lng: getFloatToNumber(element.longitude) },
        elStatus: element.elStatus,
        city: element.city,
        address: element.address,
        systemCount: element.systemCount,
        parentProjectId: element.parentProjectId
    }));

    const OnBuildingNameMarkerClick = (buildingId: string, projectId: string): void => {
        props.onBuildingNameClicked(buildingId, projectId);
    };

    function getFloatToNumber(value?: string): number {
        return parseFloat(value ? value : '0');
    };

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: googleApiKey, // Add your API key
    });


    return (dashboard.buildingListLoading && !dashboard.buildingListSuccess) ?
        <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
            icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />

        :
        buildingList.length > 0 && isLoaded ? (
            <MapContainer buildings={buildItem.length > 0 ? selectedBuildingMarkerProps : buildingMarkerProps} buildingId={selectedBuildingId} OnBuidlingMarkerClicked={(buildingId: string, projectId: string) => OnBuildingNameMarkerClick(buildingId, projectId)} />
        ) :
            (isLoaded ? (<MapGeneric buildingId={''} />) : null);

};

export default OverviewMap;
