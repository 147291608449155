import { TechnicianListData } from '../types/technicians';
import { ServiceManagerCustomerDetailData, ServiceManagerListData } from '../types/serviceManagers';
import { ServicePartnerCustomerDataList, ServicePartnerCustomerDetailData, ServicePartnerCustomerDetailDataList } from '../types/servicePartnerCustomerList';
import { PlatformDataList, PlatformListData, PlatformSystemInfo, PlatformSystemInfoList, platformDetail } from '../types/platform';
import { servicePartnerDetail } from '../types/add-ServicePartnerCustomer';

const getTechniciansData: TechnicianListData = {
    detail: [{
        userName: '',
        id: '0',
        email: '',
        company_name: '',
        company_country: '',
        userStatus: 0
    }],
    totalRecordCount: 0,
    totalPage: 0
}

const getServiceMangersData: ServiceManagerListData = {
    detail: [{
        userName: '',
        id: '0',
        email: '',
        company_name: '',
        company_country: '',
        userStatus: 0
    }],
    totalRecordCount: 0,
    totalPage: 0
}

const getServicePartnerCustomerListData: ServicePartnerCustomerDataList =
    [{
        companyName: '',
        id: '',
        customerNumber: '',
        serviceManagerEmail: '',
        serviceManagerFirstName: '',
        serviceManagerLastName: '',
        serviceManagerDisplayName: ''
    }]

const getServicePartnerCustomerDetailListData: ServicePartnerCustomerDetailData = {
    detail: [{
        companyName: '',
        id: '',
        customerNumber: '',
        country: '',
        city: '',
        serviceManagerEmail: '',
        serviceManagerFirstName: '',
        serviceManagerLastName: '',
        serviceManagerDisplayName: '',
        imageBase64: '',
        imageType: ''
    }],
    totalRecordCount: 0,
    totalPage: 0
}
const getServiceManagerCustomerDetailListData: ServiceManagerCustomerDetailData = {
    detail: [{
        companyName: '',
        id: '',
        customerNumber: '',
        country: '',
        city: '',
        imageBase64: '',
        imageType: '',
        status: 0
    }],
    totalRecordCount: 0,
    totalPage: 0
}


const getPlatformListData: PlatformListData = {
    detail: [{
        id: '',
        name: '',
        country: '',
        customerId: '',
        customerName: '',
        mainServiceManagerId: '',
        mainServiceManagerName: '',
        activeStatus: '',
        imageBase64: '',
        imageType: '',
        isFavorite: '',
    }],
    totalRecordCount: 0,
    totalPage: 0

}
export const defaultPlatformDetail: platformDetail = {
    id: "",
    name: "",
    country: "",
    customerId: "",
    customerName: "",
    customerNumber: "",
    customerAddress1: "",
    CustomerAddress2: "",
    customerPostalCode: "",
    CustomerCity: "",
    customerCountry: "",
    customerCountryCode: "",
    customerPhone: "",
    customerComments: "",
    slaNumber: "",
    slaExpiryDate: "",
    isInternalTeam: false,
    primaryContactType: 0,
    primaryEmail: "",
    primaryFirstName: "",
    primaryLastName: "",
    primaryCountryCode: "",
    primaryPhone: "",
    primaryContactComments: "",
    secondaryContactType: 0,
    secondaryEmail: "",
    secondaryFirstName: "",
    secondaryLastName: "",
    secondaryCountryCode: "",
    secondaryPhone: "",
    secondaryContactComments: "",
    serviceManagerEmail: "",
    serviceManagerFirstName: "",
    serviceManagerLastName: "",
    serviceManagerPrimaryCountryCode: "",
    serviceManagerPrimaryPhone: "",
    serviceManagerComments: "",
    imageBase64: "",
    imageType: "",
    primaryCountryCode2: "",
    primaryPhone2: "",
    secondaryCountryCode2: "",
    secondaryPhone2: "",
    serviceManagerPhone2: "",
    serviceManagerCountryCode2: "",


};


export type UsermanagementState = {
    technicianListLoading: boolean;
    technicianListSuccess: boolean;
    technicianListFail: boolean;
    technicianList: TechnicianListData,
    technicianTotalPages: number,
    technicianListErrorCode: string;
    addPlatformLoading: boolean,
    addPlatformSuccess: boolean,
    addPlatformFail: boolean,
    addPlatformErrorCode: string,
    addServiceManagerLoading: boolean,
    addServiceManagerSuccess: boolean,
    addServiceManagerFail: boolean,
    addServiceManagerErrorCode: string,
    serviceManagerListLoading: boolean;
    serviceManagerListSuccess: boolean;
    serviceManagerListFail: boolean;
    serviceManagerList: ServiceManagerListData,
    serviceManagerTotalPages: number,
    serviceManagerListErrorCode: string;
    addServicePartnerCustomerLoading: boolean;
    addServicePartnerCustomerSuccess: boolean;
    addServicePartnerCustomerFail: boolean;
    addServicePartnerCustomerErrorCode: string;
    servicePartnerCustomersListLoading: boolean;
    servicePartnerCustomersListSuccess: boolean;
    servicePartnerCustomersListFail: boolean;
    servicePartnerCustomerList: ServicePartnerCustomerDataList,
    servicePartnerCustomersListErrorCode: string;
    servicePartnerCustomersDetailListLoading: boolean;
    servicePartnerCustomersDetailListSuccess: boolean;
    servicePartnerCustomersDetailListFail: boolean;
    servicePartnerCustomerDetailList: ServicePartnerCustomerDetailData,
    servicePartnerCustomersDetailListErrorCode: string;
    platformListLoading: boolean;
    platformListSuccess: boolean;
    platformListFail: boolean;
    platformList: PlatformListData,
    platformListErrorCode: string;
    serviceManagerCustomersDetailListLoading: boolean;
    serviceManagerCustomersDetailListSuccess: boolean;
    serviceManagerCustomersDetailListFail: boolean;
    serviceManagerCustomerDetailList: ServiceManagerCustomerDetailData,
    serviceManagerCustomersDetailListErrorCode: string;
    servicePartnerDetailLoading: boolean;
    servicePartnerDetailSuccess: boolean;
    servicePartnerDetailFail: boolean;
    servicePartnerDetail: servicePartnerDetail,
    servicePartnerDetailErrorCode: string;
    platformSystemInfoLoading: boolean;
    platformSystemInfoSuccess: boolean;
    platformSystemInfoFail: boolean;
    platformSystemInfo: PlatformSystemInfoList,
    platformSystemInfoErrorCode: string;
    platformDetailLoading: boolean,
    platformDetailSuccess: boolean,
    platformDetailFail: boolean,
    platformDetail: platformDetail,
    platformDetailErrorCode: string,
    removeServiceUserLoading: boolean;
    removeServiceUserSuccess: boolean;
    removeServiceUserErrorCode: string;
    removeServiceUserFail: boolean;
};

export const defaultServicePartnerDetail: servicePartnerDetail = {
    id: "",
    companyName: "",
    customerNumber: "",
    comments: "",
    companyAddress1: "",
    companyAddress2: "",
    postalCode: "",
    city: "",
    country: "",
    companyCountryCode: "",
    companyPhone: "",
    isInternalTeam: false,
    serviceManagerEmail: "",
    serviceManagerFirstName: "",
    serviceManagerLastName: "",
    serviceManagerPrimaryCountryCode: "",
    serviceManagerPrimaryPhone: "",
    serviceManagerCountryCode2: "",
    serviceManagerPhone2: "",
    serviceManagerComments: "",
    primaryContactType: 0,
    primaryEmail: "",
    primaryFirstName: "",
    primaryLastName: "",
    primaryCountryCode: "",
    primaryPhone: "",
    primaryCountryCode2: "",
    primaryPhone2: "",
    primaryContactComments: "",
    secondaryContactType: 0,
    secondaryEmail: "",
    secondaryFirstName: "",
    secondaryLastName: "",
    secondaryCountryCode: "",
    secondaryPhone: "",
    secondaryCountryCode2: "",
    secondaryPhone2: "",
    secondaryContactComments: "",
    imageBase64: "",
    imageType: "",
    sLANumber: "",
    expiryDate: ""
}
export const defaultPlatformSystemInfo: PlatformSystemInfo = {
    DeviceType: 0,
    Description: "",
    SystemCount: 0,
    LuminaryCount: 0,
};

export const initialUsermanagementState: UsermanagementState = {
    technicianListLoading: false,
    technicianListSuccess: false,
    technicianListFail: false,
    technicianList: getTechniciansData,
    technicianTotalPages: 0,
    technicianListErrorCode: '',
    addPlatformLoading: false,
    addPlatformSuccess: false,
    addPlatformFail: false,
    addPlatformErrorCode: '',
    addServiceManagerLoading: false,
    addServiceManagerSuccess: false,
    addServiceManagerFail: false,
    addServiceManagerErrorCode: '',
    serviceManagerListLoading: false,
    serviceManagerListSuccess: false,
    serviceManagerListFail: false,
    serviceManagerList: getServiceMangersData,
    serviceManagerTotalPages: 0,
    serviceManagerListErrorCode: '',
    addServicePartnerCustomerLoading: false,
    addServicePartnerCustomerSuccess: false,
    addServicePartnerCustomerFail: false,
    addServicePartnerCustomerErrorCode: '',
    servicePartnerCustomersListLoading: false,
    servicePartnerCustomersListSuccess: false,
    servicePartnerCustomersListFail: false,
    servicePartnerCustomerList: getServicePartnerCustomerListData,
    servicePartnerCustomersListErrorCode: '',
    servicePartnerCustomersDetailListLoading: false,
    servicePartnerCustomersDetailListSuccess: false,
    servicePartnerCustomersDetailListFail: false,
    servicePartnerCustomerDetailList: getServicePartnerCustomerDetailListData,
    servicePartnerCustomersDetailListErrorCode: '',
    serviceManagerCustomersDetailListLoading: false,
    serviceManagerCustomersDetailListSuccess: false,
    serviceManagerCustomersDetailListFail: false,
    serviceManagerCustomerDetailList: getServiceManagerCustomerDetailListData,
    serviceManagerCustomersDetailListErrorCode: '',
    platformListLoading: false,
    platformListSuccess: false,
    platformListFail: false,
    platformList: getPlatformListData,
    platformListErrorCode: '',
    servicePartnerDetailLoading: false,
    servicePartnerDetailSuccess: false,
    servicePartnerDetailFail: false,
    servicePartnerDetail: defaultServicePartnerDetail,
    servicePartnerDetailErrorCode: '',
    platformSystemInfoLoading: false,
    platformSystemInfoSuccess: false,
    platformSystemInfoFail: false,
    platformSystemInfo: [],
    platformSystemInfoErrorCode: '',
    platformDetailLoading: false,
    platformDetailSuccess: false,
    platformDetailFail: false,
    platformDetail: defaultPlatformDetail,
    platformDetailErrorCode: '',
    removeServiceUserLoading: false,
    removeServiceUserSuccess: false,
    removeServiceUserErrorCode: '',
    removeServiceUserFail: false,
};
