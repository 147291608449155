import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_ROLES_OPERATIONS } from './url-constants';

type UserRoleOperationEndpointSpecifications = ApiEndpointSpecification;
export const UserRoleOperationEndpointSpecification: UserRoleOperationEndpointSpecifications = {
    url: USER_ROLES_OPERATIONS,
    method: HttpMethod.GET,
    requestParamsSchemaName: 'UserRoleOperationRequestParams',
    requestBodySchemaName: 'UserRoleOperationRequestBody',
    okResponseSchemaName: 'UserRoleOperationOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeUserRoleOperation = Helpers.makeApiRequestFn<
    Types.UserRoleOperationRequestParams,
    Types.UserRoleOperationRequestBody,
    Types.UserRoleOperationOkResponse
>(UserRoleOperationEndpointSpecification);
