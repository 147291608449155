import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { PROJECT_LIST } from './url-constants';

type ProjectListEndpointSpecifications = ApiEndpointSpecification;
export const ProjectListEndpointSpecification: ProjectListEndpointSpecifications = {
    url: PROJECT_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'ProjectListRequestParams',
    requestBodySchemaName: 'ProjectListRequestBody',
    okResponseSchemaName: 'ProjectListOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeProjectList = Helpers.makeApiRequestFn<
    Types.ProjectListRequestParams,
    Types.ProjectListRequestBody,
    Types.ProjectListOkResponse
>(ProjectListEndpointSpecification);
