import { ActionType } from '../../types/general';

export const NavigationActions = {
    drawerToggled: (open: boolean, variant: 'persistent' | 'temporary' | 'permanent') =>
        ({
            type: 'Navigation/DrawerToggled',
            payload: { open, variant },
        } as const),
} as const;
// eslint-disable-next-line
export type NavigationAction = ActionType<typeof NavigationActions>;
