import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { BUILDING_DETAILS } from './url-constants';

type BuildingDetailRequestEndpointSpecifications = ApiEndpointSpecification;
export const BuildingDetailRequestEndpointSpecification: BuildingDetailRequestEndpointSpecifications = {
    url: BUILDING_DETAILS,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'BuildingDetailRequestParams',
    requestBodySchemaName: 'BuildingDetailRequestBody',
    okResponseSchemaName: 'BuildingDetailRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeBuildingDetailRequest = Helpers.makeApiRequestFn<
    Types.BuildingDetailRequestParams,
    Types.BuildingDetailRequestBody,
    Types.BuildingDetailRequestOkResponse
>(BuildingDetailRequestEndpointSpecification);
