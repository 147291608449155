import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import {GET_GATEWAY_DETAILS_LIST } from './url-constants';

type GatewayDetailsListRequestEndpointSpecifications = ApiEndpointSpecification;
export const GatewayDetailsListRequestEndpointSpecification: GatewayDetailsListRequestEndpointSpecifications = {
    url: GET_GATEWAY_DETAILS_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'GatewayDetailsListRequestParams',
    requestBodySchemaName: 'GatewayDetailsListRequestBody',
    okResponseSchemaName: 'GatewayDetailsListRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeGatewayDetailsListRequest = Helpers.makeApiRequestFn<
    Types.GatewayDetailsListRequestParams,
    Types.GatewayDetailsListRequestBody,
    Types.GatewayDetailsListRequestOkResponse
>(GatewayDetailsListRequestEndpointSpecification);
