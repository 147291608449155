import * as ApiRequests from '../../api/requests';
import { AuthHelper } from '../../types/auth-helper-types';
import { UserSettingsActions } from '../actions/user-settings-actions';
import { AppThunk } from '../actions';
import { SiteRole } from '../../types';

export const UserSettingsThunks = {
    getUserData: (userId: string, authHelper: AuthHelper): AppThunk => async (
        dispatch
    ): Promise<void> => {
        dispatch(UserSettingsActions.getUserDetailStarted());

        const payload = await ApiRequests.getUserSettings({ userId }, { authHelper });

        if (payload.ok) {
            dispatch(
                UserSettingsActions.getUserDetailSucceeded(payload.response.data)
            );
        } else {
            dispatch(UserSettingsActions.getUserDetailFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        }
    },

    updateUserData: (
        notify: boolean,
        sms: boolean,
        session: boolean,
        userId: string,
        authHelper: AuthHelper,
        isEmail: boolean
    ): AppThunk => async (dispatch): Promise<void> => {
        if (isEmail) dispatch(UserSettingsActions.updateEmailUserDataStarted());
        else dispatch(UserSettingsActions.updateSmsUserDataStarted());

        const payload = await ApiRequests.setUserSettings(
            {
                userId,
                notification: { emailenabled: notify, smsenabled: sms },
                session: { sessionEnabled: session },
                language: { id: 1 },
            },
            { authHelper }
        );

        if (payload.ok) {
            dispatch(UserSettingsActions.updateUserDataSucceeded(notify, sms, session));
            return;
        }
        dispatch(UserSettingsActions.updateUserDataFailed());
    },

    updateUserSessionData: (
        notify: boolean,
        sms: boolean,
        session: boolean,
        userId: string,
        authHelper: AuthHelper
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UserSettingsActions.updateSessionUserDataStarted());

        const payload = await ApiRequests.setUserSettings(
            {
                userId,
                notification: { emailenabled: notify, smsenabled: sms },
                session: { sessionEnabled: session },
                language: { id: 1 },
            },
            { authHelper }
        );

        if (payload.ok) {
            dispatch(UserSettingsActions.updateUserDataSucceeded(notify, sms, session));
            return;
        }
        dispatch(UserSettingsActions.updateUserDataFailed());
    },

    userChangePassword: (oldPassword: string, newPassword: string, authHelper: AuthHelper) => async (
        dispatch: Function
    ): Promise<void> => {
        dispatch(UserSettingsActions.changePasswordStarted());
        const payload = await ApiRequests.changePassword(
            {
                currentpassword: oldPassword,
                newpassword: newPassword,
            },
            { authHelper }
        );

        if (payload.ok) {
            dispatch(UserSettingsActions.changePasswordSucceeded());
        } else {
            dispatch(UserSettingsActions.changePasswordFailed());
        }
    },

    updateUserRole: (
        userId: string,
        updatedRoles: SiteRole[],
        authHelper: AuthHelper,
        addToast: Function
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UserSettingsActions.updateUserRoleStarted());

        const payload = await ApiRequests.updateUserRole(
            {
                userId,
                role: updatedRoles,
            },
            { authHelper }
        );

        if (payload.ok) {
            dispatch(UserSettingsActions.updateUserRoleSucceeded(updatedRoles));
            return;
        }
        addToast(`${ApiRequests.extractResponseNotOkMessage(payload.response)}`, 'error');
        dispatch(UserSettingsActions.updateUserRoleFailed());
    },

    updateUserNotification: (
        authHelper: AuthHelper,
        addToast: Function,
        firstname?: string,
        lastname?: string,
        phone?: string,
        countryCode?: string,
        company_name?: string,
        company_phoneNumber?: string,
        company_countrycode?: string,
        company_address?: string,
        company_city?: string,
        company_postalcode?: string,
        company_country?: string,
        language?: string,
        timeZone?: string,
        dateFormat?: string,
        unitSystem?: string,
        appTheme?: string,
        isAutologoutEnabled?: string,
        reportTypePreference?: string,
        reportFrequencyNotificationType?: string,
        productUpdateNotificationType?: string,
        alarmNotificationType?: string,
        statusUpdateNotificationType?: string,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UserSettingsActions.updateUserDetailStarted());

        const payload = await ApiRequests.updateUserNotification(
            {
                firstname: firstname,
                lastname: lastname,
                phone: phone,
                countryCode: countryCode,
                company_name: company_name,
                company_phoneNumner: company_phoneNumber,
                company_countrycode: company_countrycode,
                company_address: company_address,
                company_city: company_city,
                company_postalcode: company_postalcode,
                company_country: company_country,
                language: language,
                timeZone: timeZone,
                dateFormat: dateFormat,
                unitSystem: unitSystem,
                appTheme: appTheme,
                isAutologoutEnabled: isAutologoutEnabled,
                reportTypePreference: reportTypePreference,
                reportFrequencyNotificationType: reportFrequencyNotificationType,
                productUpdateNotificationType: productUpdateNotificationType,
                alarmNotificationType: alarmNotificationType,
                statusUpdateNotificationType: statusUpdateNotificationType,
            },
            { authHelper }
        );
        if (payload.ok) {
            dispatch(UserSettingsActions.updateUserDetailSucceeded(payload.response.message));
            return;
        }
        addToast(`${ApiRequests.extractResponseNotOkMessage(payload.response)}`, 'error');
        dispatch(UserSettingsActions.updateUserDetailFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));

    },

    deleteUser: (
        IsConfirmed: boolean,
        authHelper: AuthHelper
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(UserSettingsActions.deleteUserStarted());

        const payload = await ApiRequests.deleteUser(
            {
                IsConfirmed: IsConfirmed
            },
            { authHelper }
        );

        if (payload.ok) {
            dispatch(UserSettingsActions.deleteUserSucceeded());

            return;
        }
        dispatch(
            UserSettingsActions.deleteUserFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`)
        );
    },

};
