// eslint-disable-next-line
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteProps, useLocation } from 'react-router';
import { useAuthState, isAuthenticated } from '../AuthContextProvider';

type PrivateRouteProps = RouteProps;

const PrivateRoute = ({ children, ...rest }: PrivateRouteProps): JSX.Element => {
    const authState = useAuthState();
    const location = useLocation();

    if (!isAuthenticated(authState)) {
        return (
            <Route {...rest}>
                <Redirect to={{ pathname: '/login', state: { from: location } }} />
            </Route>
        );
    }

    return <Route {...rest}>{children}</Route>;
};

export default PrivateRoute;
