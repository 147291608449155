// eslint-disable-next-line
import React from 'react';
import { Data, DataState } from '../../types/data-providers';
import { EmptyState } from '@brightlayer-ui/react-components';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CenteredContent } from '../CenteredContent';
import { Typography, Link, Box, Paper } from '@mui/material';
import { CONTACT_EMAIL } from '../../constants';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { FORBIDDEN } from 'http-status-codes';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Icon, getIcon } from '../../lib/icons';

export const DataErrorIndicator: React.FC<React.PropsWithChildren<React.PropsWithChildren<{
    icon?: Icon;
    data: Data;
    message: string;
    inline?: boolean;
    divider?: boolean;
    description?: string;
    isDetailedInfo?: boolean;
    withAction?: boolean;
    action?: JSX.Element;
}>>> = (props) => {
    const { t } = useLanguageLocale();
    const TheIcon = getIcon(props.icon);
    if (props.data.state !== DataState.Error) {
        return null;
    }

    const errorMessage =
        props.data.errorMessage === undefined || props.data.errorMessage === ''
            ? []
            : props.data.errorMessage.split('/');
    /** Below error message to show Access Denied Response */
    if (
        errorMessage.length > 0 &&
        (Number(errorMessage[0]) === FORBIDDEN ||
        Number(errorMessage[0]) === 9017 || // custom code for access denied recevied from API
            errorMessage[0] === 'Access denied')
    ) {
        return (
            <>
                {props.inline && (
                    <div style={{ margin: '20px' }}>
                        <EmptyState
                            icon={<div style={{ fontSize: 30 }}>
                                <ReportProblemOutlinedIcon id="fail-icon" fontSize="inherit" />
                            </div>}
                            title="Access Denied" placeholder={undefined}                        />
                    </div>
                )}
                {!props.inline && (
                    <CenteredContent>
                        <EmptyState
                            icon={<ReportProblemOutlinedIcon
                                style={{ width: 60, height: 60 }}
                                id="fail-icon"
                                color="action"
                                fontSize="large" />}
                            title="Access Denied" placeholder={undefined}                        />
                    </CenteredContent>
                )}
            </>
        );
    }
    if (props.inline) {
        return (
            <>
                <div style={{ padding: 16 }}>
                    <EmptyState
                        icon={<div style={{ fontSize: 30 }}>
                            <ErrorOutlineIcon id="fail-icon" fontSize="inherit" />
                        </div>}
                        title={props.message} placeholder={undefined}                    />
                </div>
            </>
        );
    }

    console.error(props.data.errorMessage);

    if (props.isDetailedInfo) {
        return (
            <>
                <CenteredContent style={{ height: '30vh' }}>
                    <EmptyState
                        icon={<ErrorOutlineIcon id="fail-icon" style={{ fontSize: '64px' }} />}
                        title={t('DASHBOARD.NO_FOUND', { replace: { entity: props.message } })}
                        actions={<>
                            <Typography variant="subtitle1" color="primary" style={{ textAlign: 'center' }}>
                                {t('DASHBOARD.NOT_ACCESS', { replace: { entity: props.message } })}
                            </Typography>
                            <Typography variant="subtitle1" color="primary" style={{ textAlign: 'center' }}>
                                {t('DASHBOARD.CONTACT_MANAGER')}{' '}
                                <Link underline="always" href={`mailto:${CONTACT_EMAIL}`}>
                                    {CONTACT_EMAIL}
                                </Link>{' '}
                                {t('DASHBOARD.NEW_SITE', { replace: { entity: props.message } })}
                            </Typography>
                        </>} placeholder={undefined}                    />
                </CenteredContent>
            </>
        );
    }
    if (props.withAction) {
        return (
            <Paper style={{ padding: 16 }}>
                <EmptyState
                    icon={<div style={{ fontSize: 64 }}>{TheIcon && <TheIcon id="fail-icon" fontSize="inherit" />}</div>}
                    title={props.message}
                    description={<Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography style={{ width: '50%' }} variant="subtitle2">
                            {props.description}
                        </Typography>
                    </Box>}
                    actions={props.action} placeholder={undefined}                />
            </Paper>
        );
    }
    return (
        <>
            <CenteredContent>
                <EmptyState
                    icon={<div style={{ fontSize: 64 }}>
                        <ErrorOutlineIcon id="fail-icon" fontSize="inherit" />
                    </div>}
                    title={props.message} placeholder={undefined}                />
            </CenteredContent>
        </>
    );
};
