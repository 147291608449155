import { Data, isDataLoaded } from '../types/data-providers';

export function getAlarmTitleAndSubtitle(
    alarmData: Data<{ trigger: string; severity: string; channelName: string }>,
    siteName: string
): { title: string; subtitle: string } {
    if (!isDataLoaded(alarmData)) {
        return { title: '', subtitle: '' };
    }

    return {
        title: `${alarmData.value.trigger} ${alarmData.value.channelName}`,
        subtitle: siteName,
    };
}
export enum AlarmStatus {
    Open = 'Open',
    Acknowledged = 'Acknowledged',
    Cleared = 'Cleared',
    Closed = 'Closed',
}
