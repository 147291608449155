import { ActionType } from '../../types';

export const AuthenticationActions = {
    loginStarted: () =>
    ({
        type: 'Authentication/Login/Started',
    } as const),

    loginSucceeded: () =>
    ({
        type: 'Authentication/Login/Succeeded',
    } as const),

    loginFailed: (errorCode: number, errorMessage: string) =>
    ({
        type: 'Authentication/Login/Failed',
        payload: { errorCode, errorMessage },
    } as const),

    loginUnmount: () =>
    ({
        type: 'Authentication/Login/Unmount'
    } as const),

    setPasswordStarted: () =>
    ({
        type: 'Authentication/SetPassword/Started',
    } as const),

    setPasswordSucceeded: () =>
    ({
        type: 'Authentication/SetPassword/Succeeded',
    } as const),

    setPasswordFailed: (errorCode: string) =>
    ({
        type: 'Authentication/SetPassword/Failed',
        payload: { errorCode }
    } as const),

    setPasswordUnmount: () =>
    ({
        type: 'Authentication/SetPassword/Unmount'
    } as const),

    resetPasswordStarted: () =>
    ({
        type: 'Authentication/ResetPassword/Started',
    } as const),

    resetPasswordSucceeded: () =>
    ({
        type: 'Authentication/ResetPassword/Succeeded',
    } as const),

    resetPasswordFailed: (errorMessage: string) =>
    ({
        type: 'Authentication/ResetPassword/Failed',
        payload: { errorMessage },
    } as const),
    resetPasswordReset: () =>
    ({
        type: 'Authentication/ResetPassword/Reset',
    } as const),

    verifyResetCodeStarted: (code: string) =>
    ({
        type: 'Authentication/VerifyResetCode/Started',
        payload: { code },
    } as const),

    verifyResetCodeSucceeded: (code: string) =>
    ({
        type: 'Authentication/VerifyResetCode/Succeeded',
        payload: { code },
    } as const),

    verifyResetCodeFailed: (code: string) =>
    ({
        type: 'Authentication/VerifyResetCode/Failed',
        payload: { code },
    } as const),
};

export type AuthenticationAction = ActionType<typeof AuthenticationActions>;
