// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Button, Card, CircularProgress, IconButton, InputAdornment, Table, TableBody, TablePagination, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { Close, GetApp, Search } from '@mui/icons-material';
import { HeadCell, Order } from '../../types/table';
import { EnhancedTableHead } from '../../components/Table/EnhancedTableHead';
import { ArrowDropDown } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DropDownListComponent } from '../../components/DropDownList/DropDownListComponent';
import { ActionType, CURRENT_PAGE_SESSION, ORGANIZATTION_NAME, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { deviceTypeEnum } from '../enum/ENUM';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import * as MuiIcons from '@mui/icons-material';
import { vw_15 } from '../../constants/dimentions';
import { slaCustomerList } from '../../types/sla-customer-details';
import { useHistory } from 'react-router';
import { AssetAddSLADialog } from '../assets/AssetAddSLADialog';
import { CustomersListItem } from '../../components/UserManagementListItem/CustomersListItem';
import { Selectors } from '../../selectors';
import { Thunks } from '../../actions';
import { getComparator, stableSort } from '../../lib/table-helpers';
import { EmptyState } from '@brightlayer-ui/react-components';
import { ServiceManagerCustomerDetailDataList, ServiceManagerCustomerDetailListItems } from '../../types/serviceManagers';
import { ItemList } from '../../types/logbook-type';
import { CountryList } from '../../state/registration-state';
import { pushAppRoute } from '../../data/domain/route-manager';
import { TAB_CUSTOMERS_KEY, updateUserTabSession } from './ManageUser';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';

type Props = {
    defaultProject: string;
};

export const Customers: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const DEFAULT_ID: string = '';
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('companyName');
    const [countryId, setCountryId] = React.useState(DEFAULT_ID);
    const [filterProject, setFilteredProject] = React.useState(props.defaultProject !== undefined ? props.defaultProject : '');
    const [filterCountry, setFilteredCountry] = React.useState(DEFAULT_ID);
    const [searchText, setSearchText] = useState(DEFAULT_ID);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [offset, setOffset] = React.useState(1);
    const history = useHistory();
    const [openOrgSelectDialog, setOpenOrgSelectDialog] = React.useState(false);
    const [emptyCustomerList, setEmptyCustomerList] = React.useState(false);
    const [customerList, setCustomerList] = useState<slaCustomerList>([]);
    const [customerDataLoaded, setCustomerDataLoaded] = React.useState(false);
    const [selectedCustomer, setSelectedCustomer] = React.useState(DEFAULT_ID);
    const userManagement = useSelector(Selectors.Usermanagement.usermanagement);
    const countryListLoading = useSelector(Selectors.Registration.countryListLoading);
    const countryListSuccess = useSelector(Selectors.Registration.countryListSuccess);
    const countryListFail = useSelector(Selectors.Registration.countryListFail);
    const [country, setCountry] = useState<ItemList>([]);
    const registration = useSelector(Selectors.Registration.registration);
    const useOrganizationDataList = useOrganizationList();
    const [organizationListData, setOrganizationListData] = React.useState<ItemList>([]);

    const headCells: HeadCell[] = [
        { id: 'companyName', label: t('USERS.CUSTOMER'), hideSorting: false },
        { id: 'country', label: t('USERLIST.COL_LOCATION'), hideSorting: false },
        { id: 'city', label: t('USERS.CITY'), hideSorting: false },
        { id: 'customerNumber', label: t('USERS.CUSTOMER_ID'), hideSorting: false },
        { id: 'userStatus', label: t('USERS.STATUS'), hideSorting: false }
    ];

    useEffect(() => {
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        dispatch(Thunks.Registration.countryCodeRequest());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setOrganizationListData(useOrganizationDataList.organizationList);

    }, [useOrganizationDataList, useOrganizationDataList.organizationList]); //eslint-disable-line react-hooks/exhaustive-deps


    const getServiceManagerDetailList = (list: ServiceManagerCustomerDetailDataList): ServiceManagerCustomerDetailDataList => {
        return list.map((data, index) => {
            return (
                {
                    companyName: data.companyName,
                    id: data.id,
                    country: data.country,
                    city: data.city,
                    imageBase64: data.imageBase64,
                    imageType: data.imageType,
                    status: data.status,
                    customerNumber: data.customerNumber
                }
            );
        })
    };

    const countryListData = (list: CountryList): ItemList => {
        return list?.map((data, country) => {
            return {
                id: data.name,
                name: data.name,
            };
        });
    };

    useEffect(() => {
        if (!countryListLoading && countryListSuccess) {
            setCountry(countryListData(registration.countryList));
        } else if (!countryListLoading && countryListFail) {
            setCountry([]);
            setCountryId(DEFAULT_ID);
        }
    }, [countryListLoading, countryListSuccess, countryListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (filterCountry !== '' || filterProject !== '') {
            dispatch(Thunks.UserManagement.getServiceManagerCustomersDetailList('', filterCountry, filterProject, searchText, '1', rowsPerPage.toString()));
            setCurrentPage(0);
        } else {
            dispatch(Thunks.UserManagement.getServiceManagerCustomersDetailList('', '', '', searchText, offset.toString(), rowsPerPage.toString()));
            const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
            setCurrentPage(parseInt(currentPageAfterSearchClear));
        }
        // eslint-disable-next-line
    }, [filterCountry, rowsPerPage, filterProject]);

    useEffect(() => {
        if (filterCountry !== '' || filterProject !== '') {
            dispatch(Thunks.UserManagement.getServiceManagerCustomersDetailList('', filterCountry, filterProject, searchText, '1', rowsPerPage.toString()));
        } else {
            dispatch(Thunks.UserManagement.getServiceManagerCustomersDetailList('', '', '', searchText, offset.toString(), rowsPerPage.toString()));
            const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
            setCurrentPage(parseInt(currentPageAfterSearchClear));
        }
        // eslint-disable-next-line
    }, [offset]);

    useEffect(() => {
        if (!userManagement.serviceManagerCustomersDetailListLoading && userManagement.serviceManagerCustomersDetailListSuccess) {
            setEmptyCustomerList(userManagement.serviceManagerCustomerDetailList.detail.length <= 0)
            if (userManagement.serviceManagerCustomerDetailList.detail.length > 0) {
                const serviceManagersList = getServiceManagerDetailList(userManagement.serviceManagerCustomerDetailList.detail);
                setCustomerList(serviceManagersList);
                setTotalRecords(userManagement.serviceManagerCustomerDetailList.totalRecordCount)
            } else {
                setCustomerList([]);
            }
            setCustomerDataLoaded(true);
        }
    }, [userManagement.serviceManagerCustomersDetailListLoading, userManagement.serviceManagerCustomersDetailListSuccess, userManagement.serviceManagerCustomersDetailListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const customerListData = (list: slaCustomerList): ItemList => {
        return list?.map((data, customer) => {
            return {
                id: data.id,
                name: data.companyName,
            };
        });
    };

    useEffect(() => {
        if (customerDataLoaded) {
            /* Debouncing to override user typing */
            const delayDebounceFn = setTimeout(() => {
                if (searchText !== '') {
                    dispatch(Thunks.UserManagement.getServiceManagerCustomersDetailList('', filterCountry, filterProject, searchText, '1', rowsPerPage.toString()));
                    setCurrentPage(0);
                } else {
                    dispatch(Thunks.UserManagement.getServiceManagerCustomersDetailList('', filterCountry, '', searchText, offset.toString(), rowsPerPage.toString()));
                    const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
                    setCurrentPage(parseInt(currentPageAfterSearchClear));
                }
            }, 3000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

    const goToCreateCustomer = (): void => {
        pushAppRoute(history,
            { type: 'AddCustomerDetails', actionType: ActionType.ADD },
            { backRoute: 'Users' }
        );
        updateUserTabSession(TAB_CUSTOMERS_KEY);
    };

    //**** Search text ***//
    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setSearchText(e.target.value);
    };

    const resetSearch = (): void => {
        setSearchText(DEFAULT_ID);
        setSelectedCustomer(DEFAULT_ID);
    };

    //*** Sorting ***//
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const sortedValue = stableSort(customerList, getComparator(order, orderBy));
        setCustomerList(sortedValue);
    };

    const handleDisplay = (value: {
        customerData: ServiceManagerCustomerDetailListItems;
    }): void => {
        setSelectedCustomer(value.customerData.id);
    };

    //*** Pagination ***//
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, newPage.toString());
        setOffset(newPage + 1);
        setSelectedCustomer(DEFAULT_ID);
    };


    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        setCurrentPage(0);
        setOffset(1);
        setSelectedCustomer(DEFAULT_ID);
    };

    return (
        <div id="users-customers-container" data-testid="users-customers-container">
            <div data-testid="users-customers-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    id="users-customers-add-user"
                    data-testid="users-customers-add-user"
                    style={{
                        borderColor: PXBColors.blue[700],
                        flexDirection: 'row',
                        backgroundColor: PXBColors.white[50],
                        textTransform: 'none'
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={(): void => goToCreateCustomer()}
                >
                    {md ? t('USERS.ADD_CUSTOMER') : <MuiIcons.Add></MuiIcons.Add>}
                </Button>
                <Button
                    id="users-customers-add-sla"
                    data-testid="users-customers-add-sla"
                    style={{
                        flexDirection: 'row',
                        backgroundColor: PXBColors.white[50],
                        textTransform: 'none',
                        marginLeft: vw_15
                    }}
                    variant="outlined"
                    color="primary"
                    onClick={() => { setOpenOrgSelectDialog(true) }}
                >
                    {md ? t('USERS.ADD_SLA') : <MuiIcons.Add></MuiIcons.Add>}
                </Button>
                <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto' }}>
                    <Typography
                        id="users-customers-favorite-filter-checkbox-lable"
                        data-testid="users-customers-favorite-filter-checkbox-lable"
                        style={{ alignSelf: 'center' }} variant="body2">
                        {t('USERS.SHOW_FAVORITE_CUSTOMERS')}
                    </Typography>
                    <Checkbox id="users-customers-favorite-filter-checkbox"
                    />
                </div>
            </div>

            <div data-testid="users-customers-search" style={{ display: 'flex', }}>
                <DropDownListComponent disabled={false} data-testid="users-customers-company-filter" list={organizationListData} value={t('SEARCH.ALL_ORGANIZATIONS')} handleSelection={setFilteredProject} selected={''} defaultSelection={props.defaultProject}></DropDownListComponent>
                <DropDownListComponent disabled={false} data-testid="users-customers-country-filter" list={country} value={t('SEARCH.ALL_COUNTRY')} handleSelection={setFilteredCountry} selected={countryId} defaultSelection={''}></DropDownListComponent>
                <TextField
                    inputProps={{ 'data-testid': 'search', maxLength: 100, fontSize: 14 }}
                    value={searchText}
                    style={{
                        width: 'auto',
                        textAlign: 'right',
                        float: 'right',
                        border: '0px solid #727E84',
                        borderRadius: 5,
                        backgroundColor: 'white',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        overflow: 'hidden',
                        marginTop: 28,
                        fontFamily: 'Open sans',
                        fontSize: 14,
                        color: '#727E84',
                        fontStyle: 'normal',
                        fontWeight: 400,
                    }}
                    placeholder={t('ORGANIZATION_MANAGE.SEARCH_BUILDINGS')}
                    onChange={(e): void => onChangeSearchText(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search data-testid="search-icon" style={{ color: PXBColors.gray[500] }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchText !== '' && (
                                    <IconButton onClick={resetSearch}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>
            <Card style={{ flex: '1 1 0px', overflow: 'auto', marginTop: '2%' }} id="users-service-partner-list-container" data-testid="users-service-partner-list-container">
                <Table stickyHeader size="small"
                    id="users-service-partner-list-table"
                    data-testid="users-service-partner-list-table" style={{ width: '100%' }}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />
                    {!emptyCustomerList && !userManagement.serviceManagerCustomersDetailListLoading && <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable'}
                        id="users-service-partner-list-table-body"
                        data-testid="users-service-partner-list-table-body">
                        {stableSort(customerList, getComparator(order, orderBy)).map((row: ServiceManagerCustomerDetailListItems, index: number) => (
                            <CustomersListItem
                                key={row.id}
                                row={row}
                                selectedCustomer={selectedCustomer}
                                displayClick={handleDisplay}
                            ></CustomersListItem>
                        ))}
                    </TableBody>}
                </Table>

                {userManagement.serviceManagerCustomersDetailListLoading && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!userManagement.serviceManagerCustomersDetailListLoading && emptyCustomerList && <EmptyState style={{ flex: 1, height: 200 }}
                    title={t('USERS.ERROR_NO_CUSTOMER_FOUND')} icon={undefined} placeholder={undefined} />}
            </Card >

            <AssetAddSLADialog openDialogFlag={openOrgSelectDialog} closeClick={() => setOpenOrgSelectDialog(false)} ></AssetAddSLADialog>
            <div style={{ flex: '1 1 0px', backgroundColor: PXBColors.white[50] }}>
                {!emptyCustomerList && <TablePagination
                    component="div"
                    data-testid="pagination-users-service-partner"
                    count={totalRecords}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('LABELS.ITEMS_PER_PAGE')}
                    showFirstButton showLastButton
                />}
            </div>
        </div>
    );
};
