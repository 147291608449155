// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Button, Card, CircularProgress, IconButton, InputAdornment, MenuItem, Select, SelectChangeEvent, Table, TableBody, TablePagination, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { Close, GetApp, Search } from '@mui/icons-material';
import { HeadCell, Order } from '../../types/table';
import { Selectors } from '../../selectors';
import { useSelector } from 'react-redux';
import { EnhancedTableHead } from '../../components/Table/EnhancedTableHead';
import { getComparator, stableSort } from '../../lib/table-helpers';
import { DeviceAssetData, DeviceAssetList, DeviceList } from '../../types/device';
import { AssetSystemListItem } from '../../components/AssetListItem/AssetSystemListItem';
import { ArrowDropDown } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { Thunks } from '../../actions';
import { useDispatch } from 'react-redux';
import { DropDownListComponent } from '../../components/DropDownList/DropDownListComponent';
import { ItemList } from '../../types/logbook-type';
import { BuildingList } from '../../types/building';
import { GatewayDetailsList } from '../../types/gateway';
import { EmptyState } from '@brightlayer-ui/react-components';
import { CURRENT_PAGE_SESSION, DEFAULT_PAGE_ONE, ORGANIZATTION_NAME, RECORD_PER_PAGE_LIMIT } from '../../constants';
import { USER_ROLE_TYPE, deviceStatusEnum, deviceTypeEnum } from '../enum/ENUM';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import * as MuiIcons from '@mui/icons-material';
import { vw_15 } from '../../constants/dimentions';
import { useOrganizationList } from '../../data/DataProviders/OrganizationListProvider';
import { OrganizationActions } from '../../actions/actions/organization-actions';
import { getUserRoleType, useAuthState } from '../../components/AuthContextProvider';
import { selectStyle } from './selectStyle';
import { customerListV2Data } from '../../types/customer-detail';
import { CustomerActions } from '../../actions/actions/customer-actions';

type Props = {
    onClick: Function;
    orgList: ItemList;
    defaultProject: string;
    defaultBuilding: string;
    gatewayId: string;
    elStatus: string;
    customerId?: string;
};

export const AssetsSystems: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const authState = useAuthState();
    const usertype = getUserRoleType(authState) || '';
    const DEFAULT_ID: string = '';
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const defaultSelect = props.elStatus !== undefined ? props.elStatus : '';
    const defaultProjectSelect = props.defaultProject !== undefined ? props.defaultProject : ''
    const defaultBuildingSelect = props.defaultBuilding !== undefined ? props.defaultBuilding : ''
    const defaultCustomerSelect = props.customerId !== undefined ? props.customerId : ''
    const dashboard = useSelector(Selectors.Dashboard.dashboard);
    const [emptyDeviceList, setEmptyDeviceList] = React.useState(false)
    const organization = useSelector(Selectors.Organization.organization);
    const useOrganizationDataList = useOrganizationList();
    const [searchText, setSearchText] = useState('');
    const [filterCustomer, setFilteredCustomer] = React.useState(defaultCustomerSelect);
    const [focusedCustomer, setFocusedCustomer] = React.useState(defaultCustomerSelect ? true : false);

    const [filterType, setFilteredType] = React.useState('');
    const [filterStatus, setFilteredStatus] = React.useState(props.elStatus);

    const [isGatewayVisible, setGatewayVisible] = React.useState(false);
    const [buildingId, setBuildingId] = React.useState(DEFAULT_ID);
    const [gatewayId, setGatewayId] = React.useState(DEFAULT_ID);

    const [gateways, setGateways] = useState<ItemList>([]);
    const [filterGateway, setFilteredGateway] = React.useState(props.gatewayId);

    const [buildings, setBuildings] = useState<ItemList>([]);
    const [filterBuilding, setFilteredBuilding] = React.useState(props.defaultBuilding);

    const [projects] = useState<ItemList>(props.orgList);
    const [filterProject, setFilteredProject] = React.useState(props.defaultProject || '');

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('systemName');
    const [selectedSystem, setSelectedSystem] = React.useState('');

    const asset = useSelector(Selectors.Dashboard.asset);
    const customer = useSelector(Selectors.Customer.customer);

    const [deviceList, setDeviceList] = useState<DeviceAssetList>([]);
    const [deviceAssetList, setDeviceAssetList] = useState<DeviceAssetList>([]);
    const [isFavourite, setFavourite] = useState(false);
    const [deviceDataLoaded, setDeviceDataLoaded] = React.useState(false);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(RECORD_PER_PAGE_LIMIT);
    const [offset, setOffset] = React.useState(1);
    const [customerList, setCustomerList] = React.useState<ItemList>([]);
    const [firstCall, setFirstCall] = React.useState(true);
    const [isFav, setIsFav] = React.useState(false);

    const headCells: HeadCell[] = [
        { id: 'systemName', label: t('DEVICE_DETAILS.NAME'), hideSorting: false },
        { id: 'projectName', label: t('DEVICE_DETAILS.ORGANIZATION'), hideSorting: false },
        { id: 'buildingName', label: t('DEVICE_DETAILS.BUILDING'), hideSorting: false },
        { id: 'luminaryCount', label: t('DEVICE_DETAILS.LUMINAIRES'), hideSorting: false },
        { id: 'type', label: t('DEVICE_DETAILS.TYPE'), hideSorting: false },
        { id: 'systemStatus', label: t('DEVICE_DETAILS.STATUS'), hideSorting: false },
    ];

    const getDeviceAssetList = (list: DeviceList): DeviceAssetList => {
        return list.map((data, device) => {
            return (
                {
                    systemId: data.systemId,
                    systemName: data.systemName,
                    type: data.type,
                    firmwareVersion: data.firmwareVersion,
                    systemStatus: data.systemStatus,
                    gateWayId: data.gateWayId,
                    gateWayName: data.gateWayName,
                    projectId: data.projectId,
                    projectName: data.projectName,
                    buildingName: data.buildingName,
                    buildingId: data.buildingId,
                    luminaryCount: data.luminaryCount,
                    isFavourite: data.isFavourite ? 1 : 0
                }
            );
        })
    };

    useEffect(() => {
        if (firstCall) {
            dispatch(Thunks.Dashboard.getBuildingList(filterProject, DEFAULT_ID));
            dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, filterBuilding, DEFAULT_ID));
            dispatch(Thunks.Asset.getDeviceList(filterProject, filterBuilding, DEFAULT_ID, searchText, filterType, filterStatus, filterGateway, filterCustomer, offset.toString(), rowsPerPage.toString(), isFav));
            dispatch(CustomerActions.customerListDataUnmounted());
            usertype === USER_ROLE_TYPE.SERVICE_MANAGER ? dispatch(Thunks.Customer.getCustomerListV2(filterProject, filterBuilding)) : '';
            setFirstCall(false);
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            if (filterProject === '' && filterBuilding !== '')
                setGatewayVisible(true);
            dispatch(Thunks.Dashboard.getBuildingList(filterProject, DEFAULT_ID));
            dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, DEFAULT_ID, DEFAULT_ID));
            dispatch(Thunks.Asset.getDeviceList(filterProject, '', DEFAULT_ID, searchText, filterType, filterStatus, filterGateway, filterCustomer, offset.toString(), rowsPerPage.toString(), isFav));
            dispatch(CustomerActions.customerListDataUnmounted());
            usertype === USER_ROLE_TYPE.SERVICE_MANAGER ? dispatch(Thunks.Customer.getCustomerListV2(filterProject)) : '';
            setFilteredBuilding(DEFAULT_ID);
            setGatewayId(filterProject);
            setBuildingId(filterProject);
            setGatewayVisible(false);
            setFilteredCustomer(DEFAULT_ID);
            setFocusedCustomer(false);
        }
    }, [filterProject]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            setGatewayId(filterBuilding);
            dispatch(Thunks.Asset.getGatewayDetailsList(filterProject, filterBuilding, DEFAULT_ID));
            dispatch(Thunks.Asset.getDeviceList(filterProject, filterBuilding, DEFAULT_ID, searchText, filterType, filterStatus, filterGateway, filterCustomer, offset.toString(), rowsPerPage.toString(), isFav));
            dispatch(CustomerActions.customerListDataUnmounted());
            usertype === USER_ROLE_TYPE.SERVICE_MANAGER ? dispatch(Thunks.Customer.getCustomerListV2(filterProject, filterBuilding)) : '';
            setGatewayVisible(true);
            setFilteredCustomer(DEFAULT_ID);
            setFocusedCustomer(false);
        }
    }, [filterBuilding]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            dispatch(Thunks.Asset.getDeviceList(filterProject, filterBuilding, DEFAULT_ID, searchText, filterType, filterStatus, filterGateway, filterCustomer, offset.toString(), rowsPerPage.toString(), isFav));
            setGatewayVisible(true);
        }
    }, [filterGateway]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            dispatch(Thunks.Asset.getDeviceList(filterProject, filterBuilding, DEFAULT_ID, searchText, filterType, filterStatus, filterGateway, filterCustomer, offset.toString(), rowsPerPage.toString(), isFav));
        }
    }, [filterType, filterStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            dispatch(Thunks.Asset.getDeviceList(filterProject, filterBuilding, DEFAULT_ID, searchText, filterType, filterStatus, filterGateway, filterCustomer, offset.toString(), rowsPerPage.toString(), isFav));
            const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
            setCurrentPage(parseInt(currentPageAfterSearchClear));
        }
    }, [rowsPerPage, offset, filterCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!dashboard.buildingListLoading && dashboard.buildingListSuccess) {
            setBuildings(buildingListData(dashboard.buildingList.detail));
        } else if (!dashboard.buildingListLoading && dashboard.buildingListFail) {
            // addToast('Building list loading failed');
            setBuildings([]);
            setBuildingId(DEFAULT_ID);
        }
    }, [dashboard.buildingListLoading, dashboard.buildingListSuccess, dashboard.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!asset.gatewayDetailsListLoading && asset.gatewayDetailsListSuccess) {
            setGateways(gatewayListData(asset.gatewayDetailsList.detail));
        } else if (!asset.gatewayDetailsListLoading && asset.gatewayDetailsListFail) {
            // addToast('device list loading failed');
            setGateways([]);
        }
    }, [asset.gatewayDetailsListLoading, asset.gatewayDetailsListSuccess, asset.gatewayDetailsListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!customer.customerListLoading && customer.customerListSuccess) {
            setCustomerList(customerListData(customer.customerList.customerDetailList));
        }
        else if (!customer.customerListLoading && customer.customerListFail) {
            setCustomerList([]);
        }
    }, [customer.customerListLoading, customer.customerListSuccess, customer.customerListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const customerListData = (list: customerListV2Data): ItemList => {
        return list?.map((data, customer) => {
            return {
                id: data.id,
                name: data.companyName,
                number: data.customerNumber
            };
        });
    };


    useEffect(() => {
        if (!asset.deviceListLoading && asset.deviceListSuccess) {
            setEmptyDeviceList(asset.deviceList.detail.length <= 0)
            const deviceDataList = getDeviceAssetList(asset.deviceList.detail);
            setDeviceList(deviceDataList);
            setTotalRecords(asset.deviceList.totalRecordCount);
            //handle faviourite
            if (isFavourite) {
                setDeviceAssetList(deviceDataList.filter((data, device) => { return (data.isFavourite === 1) }));
            } else {
                setDeviceAssetList(deviceDataList);
            }
            setDeviceDataLoaded(true);
        } else if (!asset.deviceListLoading && asset.deviceListFail) {
            // addToast('device list loading failed');
            setDeviceList([])
            setDeviceAssetList([]);
            setEmptyDeviceList(true);
        }
        dispatch(OrganizationActions.markasFavUnmounted());
    }, [asset.deviceListLoading, asset.deviceListSuccess, asset.deviceListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const buildingListData = (list: BuildingList): ItemList => {
        return list?.map((data, building) => {
            return {
                id: data.buildingId,
                name: data.name,
            };
        });
    };
    const gatewayListData = (list: GatewayDetailsList): ItemList => {
        return list?.map((data, building) => {
            return {
                id: data.gateWayId,
                name: data.gateWayName,
            };
        });
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const sortedValue = stableSort(deviceAssetList, getComparator(order, orderBy));
        setDeviceAssetList(sortedValue);
    };

    const handleDisplay = (value: {
        deviceData: DeviceAssetData;
    }): void => {
        setSelectedSystem(value.deviceData.systemId)
        props.onClick({ deviceData: value.deviceData });
    };

    /* //ToDo - Text search for systems
    const setSearchText = (searchText: string): void => {};
    */

    function downloadSystemReport(): void {
        //ToDo - Download System report
    }
    function addNewSystem(): void {
        //ToDo - Add new system
    }

    const handleFavoriteChange = (
        event: React.ChangeEvent<HTMLElement>,
        checked: boolean,
    ) => {
        if (checked) {
            setIsFav(true);
            dispatch(Thunks.Asset.getDeviceList(filterProject, filterBuilding, DEFAULT_ID, searchText, filterType, filterStatus, filterGateway, filterCustomer, DEFAULT_PAGE_ONE.toString(), rowsPerPage.toString(), true));
            setCurrentPage(0);
        } else {
            setIsFav(false);
            dispatch(Thunks.Asset.getDeviceList(filterProject, filterBuilding, DEFAULT_ID, searchText, filterType, filterStatus, filterGateway, filterCustomer, offset.toString(), rowsPerPage.toString(), false));
        }
    };

    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setSearchText(e.target.value);
    };

    const resetSearch = (): void => {
        setSearchText('');
    };

    useEffect(() => {
        if (deviceDataLoaded) {
            /* Debouncing to override user typing */
            const delayDebounceFn = setTimeout(() => {
                if (searchText !== '') {
                    dispatch(Thunks.Asset.getDeviceList(filterProject, filterBuilding, DEFAULT_ID, searchText, filterType, filterStatus, filterGateway, filterCustomer, DEFAULT_PAGE_ONE.toString(), rowsPerPage.toString(), isFav));
                    setCurrentPage(0);
                } else {
                    dispatch(Thunks.Asset.getDeviceList(filterProject, '', '', searchText, '', filterStatus, filterGateway, filterCustomer, offset.toString(), rowsPerPage.toString(), isFav));
                    const currentPageAfterSearchClear: string = window.sessionStorage.getItem(CURRENT_PAGE_SESSION) || '0';
                    setCurrentPage(parseInt(currentPageAfterSearchClear));
                }
            }, 3000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.markasFavLoading && organization.markasFavSuccess) {
            addToast(t('TOAST_MSG.MARK_AS_FAV_API_SUCCESS'));
            dispatch(Thunks.Asset.getDeviceList(filterProject, filterBuilding, DEFAULT_ID, searchText, filterType, filterStatus, filterGateway, filterCustomer, offset.toString(), rowsPerPage.toString(), isFav));
        }
        else if (!organization.markasFavLoading && organization.markasFavFail) {
            if (organization.errorCode === '9183') {
                addToast(t('ORGANIZATION_MANAGE.MARK_AS_FAV_API_FAILURE_MESSAGE'));
            }
            else if (organization.errorCode === '9001') {
                addToast(t('TOAST_MSG.REMOVE_ACCESS_ERROR_CODE_9001'));
            }
            else {
                addToast(t('TOAST_MSG.MARK_AS_FAV_API_FAILED'));
            }
            dispatch(OrganizationActions.markasFavUnmounted());
        }
    }, [organization.markasFavLoading, organization.markasFavSuccess, organization.markasFavFail]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!firstCall) {
            if (!organization.removeMarkAsFavLoading && organization.removeMarkAsFavSuccess) {
                addToast(t('TOAST_MSG.REMOVE_MARK_AS_FAV_API_SUCCESS'));
                dispatch(Thunks.Asset.getDeviceList(filterProject, filterBuilding, DEFAULT_ID, searchText, filterType, filterStatus, filterGateway, filterCustomer, offset.toString(), rowsPerPage.toString(), isFav));
            }
            else if (!organization.removeMarkAsFavLoading && organization.removeMarkAsFavFail) {
                if (organization.errorCode === '9001')
                    addToast(t('TOAST_MSG.REMOVE_ACCESS_ERROR_CODE_9001'));
                else
                    addToast(t('TOAST_MSG.REMOVE_MARK_AS_FAV_API_FAILED'));
                dispatch(OrganizationActions.removeMarkAsFavUnmounted());
            }
        }
    }, [organization.removeMarkAsFavLoading, organization.removeMarkAsFavSuccess, organization.removeMarkAsFavFail]);// eslint-disable-line react-hooks/exhaustive-deps


    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setCurrentPage(newPage);
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, newPage.toString());
        setOffset(newPage + 1);
    };


    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        window.sessionStorage.setItem(CURRENT_PAGE_SESSION, '0');
        setCurrentPage(0);
        setOffset(1);
    };
    const customerOnChange = (event: SelectChangeEvent) => {
        setFilteredCustomer(event.target.value);
        setFocusedCustomer(event.target.value !== '');

    };

    return (
        <div id="asset-system-container">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    disabled={true}
                    id="system-download-as-button"
                    data-testid="system-download-as-button"
                    style={{
                        opacity: 0.5,
                        flexDirection: 'row',
                        backgroundColor: PXBColors.blue[700],
                        color: PXBColors.white[50],
                        marginLeft: vw_15,
                        marginRight: vw_15,
                        textTransform: 'none'
                    }}
                    onClick={(): void => downloadSystemReport()}
                >
                    <GetApp style={{ padding: 3 }} />
                    {md ? t('ASSETS_DETAILS.DOWNLOAD_AS') : ''}
                    <ArrowDropDown style={{ padding: 3 }} />
                </Button>
                <div style={{ display: 'flex', marginLeft: 'auto', marginTop: 'auto' }}>
                    <Typography
                        id="system-favorite-filter-checkbox-lable"
                        data-testid="system-favorite-filter-checkbox-lable"
                        style={{ alignSelf: 'center' }} variant="body2">
                        {t('ASSETS_DETAILS.SHOW_FAVORITE_SYSTEMS')}
                    </Typography>
                    <Checkbox id="system-favorite-filter-checkbox"
                        onChange={handleFavoriteChange} />
                </div>
            </div>

            <div data-testid="asset-buildings-search-panel" style={{ display: 'flex', }}>

                <DropDownListComponent disabled={!useOrganizationDataList.orgListLoaded || asset.deviceListLoading} data-testid="asset-buildings-project-filter" list={projects} value={t('SEARCH.ALL_ORGANIZATIONS')} handleSelection={setFilteredProject} selected={''} defaultSelection={defaultProjectSelect}></DropDownListComponent>
                <DropDownListComponent disabled={dashboard.buildingListLoading || asset.deviceListLoading} data-testid="asset-buildings-project-filter" list={buildings} value={t('SEARCH.ALL_BUILDINGS')} handleSelection={setFilteredBuilding} selected={buildingId} defaultSelection={defaultBuildingSelect}></DropDownListComponent>
                {isGatewayVisible && filterBuilding && <DropDownListComponent disabled={asset.gatewayDetailsListLoading} data-testid="asset-buildings-project-filter" list={gateways} value={t('SEARCH.ALL_GATEWAYS')} handleSelection={setFilteredGateway} selected={gatewayId} defaultSelection={props.gatewayId}></DropDownListComponent>}
                {usertype === USER_ROLE_TYPE.SERVICE_MANAGER && <Select
                    disabled={customer.customerListLoading}
                    id="customer-list-dropdown"
                    data-testid="asset-system-customer-filter"
                    style={{
                        backgroundColor: focusedCustomer ? PXBColors.lightBlue[50] : PXBColors.white[50],
                        marginRight: '1%',
                        width: 'auto',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        borderRadius: 32,
                        overflow: 'hidden',
                        fontSize: 14,
                        color: focusedCustomer ? PXBColors.blue[500] : PXBColors.black[500],
                        fontStyle: 'normal',
                        fontWeight: 400,
                        marginTop: 28,
                    }}
                    value={filterCustomer}
                    onChange={customerOnChange}
                    displayEmpty
                >
                    <MenuItem value=""
                        style={
                            selectStyle.dropdown
                        }>{t('SEARCH.ALL_CUSTOMER')}</MenuItem>
                    {customerList.map((data, val) => {
                        return (
                            <MenuItem id="system-list" key={data.id} value={data.id}
                                style={selectStyle.dropdown}>
                                {data.name}
                            </MenuItem>
                        );
                    })}
                </Select>}
                <DropDownListComponent disabled={asset.deviceListLoading} data-testid="asset-buildings-status-filter" list={deviceStatusEnum} value={t('SEARCH.ALL_STATUSES')} handleSelection={setFilteredStatus} selected={''} defaultSelection={defaultSelect}></DropDownListComponent>
                <DropDownListComponent disabled={asset.deviceListLoading} data-testid="asset-buildings-project-filter" list={deviceTypeEnum} value={t('SEARCH.ALL_TYPES')} handleSelection={setFilteredType} selected={''} defaultSelection={''}></DropDownListComponent>

                <TextField
                    disabled={asset.deviceListLoading}
                    inputProps={{ 'data-testid': 'search', maxLength: 100 }}
                    value={searchText}
                    style={{
                        width: 'auto',
                        textAlign: 'right',
                        float: 'right',
                        border: '0px solid #727E84',
                        borderRadius: 5,
                        backgroundColor: 'white',
                        height: 32,
                        borderBlock: 0,
                        outline: 'none',
                        overflow: 'hidden',
                        marginTop: 28,
                        fontFamily: 'Open sans',
                        fontSize: 14,
                        color: '#727E84',
                        fontStyle: 'normal',
                        fontWeight: 400,
                    }}
                    placeholder={'Search'}
                    onChange={(e): void => onChangeSearchText(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search data-testid="search-icon" style={{ color: PXBColors.gray[500] }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchText !== '' && (
                                    <IconButton onClick={resetSearch}>
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>

            <Card style={{ flex: '1 1 0px', overflow: 'auto', marginTop: '2%' }} id="system-list-container" data-testid="system-list-container">

                <Table stickyHeader size="small"
                    id="system-list-table"
                    data-testid="system-list-table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />

                    {!emptyDeviceList && !asset.deviceListLoading && useOrganizationDataList.orgListLoaded && <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable'}
                        id="system-list-table-body"
                        data-testid="system-list-table-body">
                        {stableSort(deviceAssetList, getComparator(order, orderBy)).map((row: DeviceAssetData, index: number) => (
                            <AssetSystemListItem
                                key={row.systemId}
                                row={row}
                                selectedSystem={selectedSystem}
                                displayClick={handleDisplay}
                            ></AssetSystemListItem>
                        ))}
                    </TableBody>}
                </Table>
                {asset.deviceListLoading && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!asset.deviceListLoading && asset.deviceListSuccess && !useOrganizationDataList.orgListLoaded && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                    icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}
                {!asset.deviceListLoading && emptyDeviceList && <EmptyState style={{ flex: 1, height: 200 }}
                    title={t('ORGANIZATION_MANAGE.ERROR_NO_SYSTEM_FOUND')} icon={undefined} placeholder={undefined} />}

            </Card >
            <div style={{ flex: '1 1 0px', backgroundColor: PXBColors.white[50] }}>
                {!emptyDeviceList && <TablePagination
                    component="div"
                    data-testid="pagination-users-service-partner"
                    count={totalRecords}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('LABELS.ITEMS_PER_PAGE')}
                    showFirstButton showLastButton
                />}
            </div>

        </div>
    );
};
