// eslint-disable-next-line
import React from 'react';
import { IconButton, Paper, List, ListItem, ListItemText } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useHistory, Switch, Route } from 'react-router-dom';
import { GenericToolbar } from '../../components/GenericToolbar';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { ContentContainer } from '../../components/ContentContainer';
import { pushAppRoute } from '../../data/domain/route-manager';
import { AppRoutes, AppRoute } from '../../routes';
import { LegalContent, OpenSourceList, OpenSourceContent } from './LegalContent';
import { LegalContentDataProvider } from '../../data/DataProviders/LegalContentDataProvider';

const LegalMenu: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const history = useHistory();
    const { t } = useLanguageLocale();
    return (
        <>
            <GenericToolbar title={t('LEGAL.TITLE')} />

            <ContentContainer>
                <Paper>
                    <div>
                        <List className="list" disablePadding data-testid="legal-menu">
                            {/* //TODO: comment as per MVP scope , may be needed post MVP */}
                            {/* <ListItem
                                data-testid="terms-and-conditions"
                                className="selected"
                                divider={true}
                                style={{ height: 72, cursor: 'pointer' }}
                                onClick={(): void => pushAppRoute(history, { type: 'TermsAndConditions' })}
                            >
                                <ListItemText
                                    primary={t('LEGAL.TERMSANDCONDITIONS')}
                                    primaryTypographyProps={{
                                        variant: 'body1',
                                        style: { fontWeight: 600 },
                                    }}
                                ></ListItemText>
                                <IconButton>
                                    <ChevronRight />
                                </IconButton>
                            </ListItem> */}
                            <ListItem
                                data-testid="eula"
                                className="selected"
                                divider={true}
                                style={{ height: 72, cursor: 'pointer' }}
                                onClick={(): void => pushAppRoute(history, { type: 'EULA' })}
                            >
                                <ListItemText
                                    primary={t('LEGAL.EULA')}
                                    primaryTypographyProps={{
                                        variant: 'body1',
                                        style: { fontWeight: 600 },
                                    }}
                                ></ListItemText>
                                <IconButton>
                                    <ChevronRight />
                                </IconButton>
                            </ListItem>
                            <ListItem
                                data-testid="open-source-licenses"
                                className="selected"
                                divider={true}
                                style={{ height: 72, cursor: 'pointer' }}
                                onClick={(): void => pushAppRoute(history, { type: 'OpenSource' })}
                            >
                                <ListItemText
                                    primary={t('LEGAL.OPENSOURCELICENSES')}
                                    primaryTypographyProps={{
                                        variant: 'body1',
                                        style: { fontWeight: 600 },
                                    }}
                                ></ListItemText>
                                <IconButton>
                                    <ChevronRight />
                                </IconButton>
                            </ListItem>
                        </List>
                    </div>
                </Paper>
            </ContentContainer>
        </>
    );
};

const LEGAL_ROUTE: AppRoute = { type: 'Legal' };
const OPEN_SOURCE_ROUTE: AppRoute = { type: 'OpenSource' };

const Legal = (): JSX.Element => {
    const { t } = useLanguageLocale();
    return (
        <>
            <Switch>
                <Route exact path={AppRoutes.TermsAndConditions}>
                    <LegalContentDataProvider filename="EULA.txt">
                        <LegalContent
                            title={t('LEGAL.TERMSANDCONDITIONS')}
                            contentTitle={t('LEGAL.TERMSANDCONDITIONS')}
                            backRoute={LEGAL_ROUTE}
                        />
                    </LegalContentDataProvider>
                </Route>
                <Route exact path={AppRoutes.EULA}>
                    <LegalContentDataProvider filename="EULA.txt">
                        <LegalContent title={t('LEGAL.EULA')} contentTitle={t('LEGAL.EULA')} backRoute={LEGAL_ROUTE} />
                    </LegalContentDataProvider>
                </Route>
                <Route exact path={AppRoutes.OpenSourceContent}>
                    <OpenSourceContent backRoute={OPEN_SOURCE_ROUTE} />
                </Route>
                <Route exact path={AppRoutes.OpenSource}>
                    <OpenSourceList backRoute={LEGAL_ROUTE} />
                </Route>

                <Route component={LegalMenu} />
            </Switch>
        </>
    );
};

export default Legal;