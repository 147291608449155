export enum LegalType {
    None,
    EULA,
    TermsandCondition,
    OpenSource,
    ReactJs,
}

export type EulaPayload = {
    status: number;
    data: string;
};
