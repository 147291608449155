// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import {
    Typography,
    Card,
    IconButton,
} from '@material-ui/core';

import ListItem from '@mui/material/ListItem';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Close } from '@mui/icons-material';
import * as PXBColors from '@brightlayer-ui/colors';
import { AssetListItem } from '../../components/AssetListItem/AssetListItem';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import { PlatformSystemInfoList } from '../../types/platform';
import { getDeviceType } from '../enum/ENUM';

type Props = {
    closeClick: Function;
    PlatformId: string;
};

export const PlatformSystemInfo: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {

    const { t } = useLanguageLocale();
    const user = useSelector(Selectors.Usermanagement.usermanagement);
    const [platformSystemInfo, setPlatformSystemInfo] = useState<PlatformSystemInfoList>([]);
    const [systemCount, setSystemCount] = useState<number>();

    useEffect(() => {
        if (!user.platformSystemInfoLoading && user.platformSystemInfoSuccess) {
            setPlatformSystemInfo(user.platformSystemInfo);
            setSystemCount(user.platformSystemInfo[0].SystemCount + user.platformSystemInfo[1].SystemCount + user.platformSystemInfo[2].SystemCount + user.platformSystemInfo[3].SystemCount + user.platformSystemInfo[4].SystemCount + user.platformSystemInfo[5].SystemCount + user.platformSystemInfo[6].SystemCount);
        } else if (!user.platformSystemInfoLoading && user.platformSystemInfoFail) {
            setPlatformSystemInfo([]);
        }
    }, [user.platformSystemInfoLoading, user.platformSystemInfoSuccess, user.platformSystemInfoFail]); // eslint-disable-line react-hooks/exhaustive-deps

    function closeBuildingDetail(): void {
        props.closeClick({});
    }

    return (
        <>
            <Card style={{ flex: '1 1 0px', overflow: 'auto' }} data-testid="building-details-container">
                <ListItem style={{
                    width: '100%',
                }}
                    secondaryAction={
                        <IconButton id="building-detail-close" data-testid="building-detail-close" style={{ right: -10, marginLeft: "auto", justifyContent: 'start' }} onClick={(): void => closeBuildingDetail()}>
                            <Close />
                        </IconButton>
                    }>
                    <div>
                        <div>
                            <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600, fontStyle: 'normal', color: PXBColors.black[500], top: 24, left: 16 }} id="building-name" data-testid="buildingName" >{t('SEARCH.ALL_SYSTEMS') + ' ' + '(' + systemCount + ')'}</Typography>
                        </div>
                    </div>
                </ListItem>

                <Typography >
                    {platformSystemInfo.map((item, index) => (
                        <AssetListItem
                            data-testid="primary-buildingContact-name"
                            key={'primary-buildingContact-name' + index}
                            title={getDeviceType(item.DeviceType)}
                            subtitle={item.SystemCount + ' ' + t('ASSETS_DETAILS.SYSTEMS')}
                            imageType={''} divider={true} titleBold={true}
                            subtitle2={item.LuminaryCount + ' ' + t('DEVICE_DETAILS.LUMINARIES_COUNTS')}
                        ></AssetListItem>
                    ))}
                </Typography>

            </Card >
        </>
    );
};
