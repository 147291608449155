// eslint-disable-next-line
import React, { useEffect,useState } from 'react';
import { CardContent, TextField } from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Typography, Divider } from '@mui/material';
import { validEmail } from '../../lib/form-validator';
import { ORG_EMAIL_MAX_LENGTH } from '../../constants';

type Props = {
    email?: string;
    errorMessage: string;
    onEmailChanged?: Function;
    changeNextLabel?: Function;
    changeNextEnabled?: Function;
};

export const EnterEmail: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();

    const {
        email,
        errorMessage,
        onEmailChanged,
        changeNextLabel,
        changeNextEnabled,
    } = props;
    const [emailAddress, setEmailAddress] = useState(email || '');
    const [localErrorMessage, setLocalErrorMessage] = useState(errorMessage);

    useEffect(() => {
        setLocalErrorMessage(errorMessage);
    }, [errorMessage]);

    if (changeNextLabel) {
        changeNextLabel(t('ACTIONS.NEXT'));
    }
    if (changeNextEnabled) {
        changeNextEnabled(validEmail(emailAddress));
    }

    return (
        <>
            <CardContent style={{ flex: '1 1 0px', overflow: 'auto' }}>
                <Typography data-testid='header-1' variant="body1">{t('REGISTRATION.STEPS_MESSAGE.CREATE_ACCOUNT_ENTER_EMAIL')}</Typography>

                <Divider style={{ margin: '32px 0' }} />

                <TextField
                    label={t('LABELS.EMAIL')}
                    fullWidth
                    id="email"
                    value={emailAddress}
                    onChange={(e): void => {
                        setEmailAddress(e.target.value);
                        if (onEmailChanged) onEmailChanged(e.target.value);
                        if (e.target.value === '') {
                            setLocalErrorMessage('');
                        }
                    }}
                    variant="filled"
                    error={emailAddress.length > 0 && !validEmail(emailAddress) || Boolean(localErrorMessage)}
                    helperText={(emailAddress.length > 0 && !validEmail(emailAddress) ? t('FORMS.INVALID_EMAIL_ERROR') : '') || t(localErrorMessage)}
                    inputProps={{
                        'data-testid': 'email-input',
                        maxLength: ORG_EMAIL_MAX_LENGTH
                    }}
                />
            </CardContent>
        </>
    );
};
