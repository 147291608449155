import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { REMOVE_SERVICE_USER } from './url-constants';

type RemoveServiceUserEndpointSpecifications = ApiEndpointSpecification;
export const RemoveServiceUserEndpointSpecification: RemoveServiceUserEndpointSpecifications = {
    url: REMOVE_SERVICE_USER,
    method: HttpMethod.DELETE,
    requestParamsSchemaName: 'RemoveServiceUserRequestParams',
    requestBodySchemaName: 'RemoveServiceUserRequestBody',
    okResponseSchemaName: 'RemoveServiceUserRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeRemoveServiceUser = Helpers.makeApiRequestFn<
    Types.RemoveServiceUserRequestParams,
    Types.RemoveServiceUserRequestBody,
    Types.RemoveServiceUserOkResponse
>(RemoveServiceUserEndpointSpecification);
