// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { GenericToolbar } from '../../components/GenericToolbar';
import { pushAppRoute } from '../../data/domain/route-assets';
import { useHistory } from 'react-router-dom';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { ToolbarColor } from '../../components/GenericToolbar/GenericToolbar';
import { EnhancedTableHead } from '../../components/Table/EnhancedTableHead';
import { getComparator, stableSort } from '../../lib/table-helpers';
import { HeadCell, Order } from '../../types/table';
import { Selectors } from '../../selectors';
import { useSelector, useDispatch } from 'react-redux';
import { EmptyState } from '@brightlayer-ui/react-components';
import * as PXBColors from '@brightlayer-ui/colors';
import { AcceptInvitationRequest, InviteData, InviteEntities, UserInvitationData, UserInvitationList, UserInviteList } from '../../types/manage-users';
import { InviteListItem } from '../../components/InvitationList/InviteListItem';
import { CustomizedTableHead } from '../../components/Table/CustomizedTableHead';
import { ENTITY_TYPE, getEntityType, INVITE_STATUS } from '../enum/ENUM';
import { UsersThunks } from '../../actions/thunks/users-thunks';
import { useToasts } from '../../data/DataProviders/ToastProvider';
import { InviteConfirmationDialog } from '../../components/AcceptInvite/InviteConfirmationDialog';
import { UsersActions } from '../../actions/actions/users-actions';
import { Card, CircularProgress, Divider, IconButton, ListItemButton, Table, TableBody, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

type Props = {
    location: any;
};

export const InvitationHistory: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const history = useHistory();
    const { t } = useLanguageLocale();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const { backRoute } = props.location.state;

    const [order, setOrder] = React.useState<Order>('asc');
    const [order2, setOrder2] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('entityName');
    const [orderBy2, setOrderBy2] = React.useState('entityName');
    const [isTableCollapsed, setIsTableCollapsed] = useState(false);

    const users = useSelector(Selectors.Users.users);

    const [userInviteList, setUserInviteList] = useState<UserInvitationList>([]);
    const [requestPendingList, setRequestPendingList] = useState<UserInviteList>([]);
    const [deniedList, setDeniedList] = useState<UserInviteList>([]);
    const [expiredList, setExpiredList] = useState<UserInviteList>([]);
    const [acceptedList, setAcceptedList] = useState<UserInviteList>([]);


    const [accpetedInvitationCount, setAcceptedInvitationCount] = useState(0);
    const [selectedInvitation, setSelectedInvitation] = useState<UserInvitationData>();
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [displayAcceptConfirmPopup, setDisplayAcceptConfirmPopup] = useState(false);
    const [displayRequetConfirmPopup, setDisplayRequestConfirmPopup] = useState(false);
    const [tableHeaderDisplayUIState, setTableHeaderDisplayUIState] = useState(false);

    const headCells: HeadCell[] = [
        { id: 'entityName', label: t('USER_INVITATION_MANAGEMENT.TABLE_HEADER_NAME'), hideSorting: false, },
        { id: 'invitationDate', label: t('USER_INVITATION_MANAGEMENT.TABLE_HEADER_INVITE_DATE'), hideSorting: false },
        { id: 'invitedByUser', label: t('USER_INVITATION_MANAGEMENT.TABLE_HEADER_INVITE_BY'), hideSorting: false },
        { id: 'invitationexpirationDateexpiryutc', label: t('USER_INVITATION_MANAGEMENT.TABLE_HEADER_EXPIRED_AT'), hideSorting: false },
        { id: 'entityId', label: '', hideSorting: true }
    ];

    const headCells2: HeadCell[] = [
        { id: 'entityName', label: t('USER_INVITATION_MANAGEMENT.TABLE_HEADER_NAME'), hideSorting: false, },
        { id: 'invitationDate', label: t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPTED_BUTTON'), hideSorting: false },
        { id: 'invitedByUser', label: t('USER_INVITATION_MANAGEMENT.TABLE_HEADER_INVITE_BY'), hideSorting: false },
        { id: 'invitationexpirationDateexpiryutc', label: t('USER_INVITATION_MANAGEMENT.TABLE_HEADER_EXPIRED_AT'), hideSorting: true },
        { id: 'entityId', label: '', hideSorting: true }
    ];

    const headCellsDenied: HeadCell[] = [
        { id: 'entityName', label: t('USER_INVITATION_MANAGEMENT.TABLE_HEADER_DENIED_TITLE', { replace: { count: deniedList.length } }), hideSorting: true }
    ];

    const headCellsDeniedNoDataFound: HeadCell[] = [
        { id: 'entityName', label: t('USER_INVITATION_MANAGEMENT.TABLE_HEADER_DENIED_NO_DATA_FOUND'), hideSorting: true }
    ];

    const headCellsExpired: HeadCell[] = [
        { id: 'entityName', label: t('USER_INVITATION_MANAGEMENT.TABLE_HEADER_EXPIRED_TITLE', { replace: { count: expiredList.length } }), hideSorting: true }
    ];

    const headCellsExpiredNoDataFound: HeadCell[] = [
        { id: 'entityName', label: t('USER_INVITATION_MANAGEMENT.TABLE_HEADER_EXPIRED_NO_DATA_FOUND'), hideSorting: true }
    ];

    const headCellsTotalAccepted: HeadCell[] = [
        { id: 'entityName', label: t('USER_INVITATION_MANAGEMENT.TABLE_HEADER_TOTAL_ACCEPTED_COUNT', { replace: { count: acceptedList.length } }), hideSorting: true }
    ];

    const headCellsTotalAcceptedNoDataFound: HeadCell[] = [
        { id: 'entityName', label: t('USER_INVITATION_MANAGEMENT.TABLE_HEADER_TOTAL_ACCEPTED_NO_DATA_FOUND'), hideSorting: true }
    ];

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        stableSort(requestPendingList, getComparator(order, orderBy));
        stableSort(deniedList, getComparator(order, orderBy));
        stableSort(expiredList, getComparator(order, orderBy));
    };
    const handleRequestSortForAcceptedInvitation = (event: React.MouseEvent<unknown>, property: any): void => {
        const isAsc = orderBy2 === property && order2 === 'asc';
        setOrder2(isAsc ? 'desc' : 'asc');
        setOrderBy2(property);
        stableSort(acceptedList, getComparator(order2, orderBy2));
    };
    const getEntityId = (userData: UserInvitationData) => {
        if (userData.invitationType === ENTITY_TYPE.ORGANIZATION)
            return userData.organiztionId;
        else if (userData.invitationType === ENTITY_TYPE.BUILDING)
            return userData.buidlingId;
        else if (userData.invitationType === ENTITY_TYPE.SYSTEM)
            return userData.systemId;
        else if (userData.invitationType === ENTITY_TYPE.PLATFORM)
            return userData.platformId;
        else
            return userData.organiztionId;
    };
    const getEntityName = (userData: UserInvitationData) => {
        if (userData.invitationType === ENTITY_TYPE.ORGANIZATION)
            return userData.organizationName;
        else if (userData.invitationType === ENTITY_TYPE.BUILDING)
            return userData.buldingName;
        else if (userData.invitationType === ENTITY_TYPE.SYSTEM)
            return userData.systemName;
        else if (userData.invitationType === ENTITY_TYPE.PLATFORM)
            return userData.platformName;
        else
            return userData.organizationName;
    };

    const getInvitationTypeTitle = (type: number) => {
        return ' (' + t(getEntityType(type)) + ')';
    };

    const getInviteList = (list: UserInvitationList): UserInviteList => {
        return list.map((data, invite) => {
            return (
                {
                    invitationId: data.invitationId,
                    entityId: getEntityId(data),
                    entityName: getEntityName(data),
                    entityTypeTitle: getInvitationTypeTitle(data.invitationType),
                    entityType: data.invitationType,
                    invitedByUser: data.invitedByUser,
                    invitationDate: data.invitedDateTimeUTC,
                    expirationDate: data.invitationExpiryUTC,
                    inviteType: data.invitationStatus,
                }
            );
        })
    };

    useEffect(() => {
        dispatch(UsersThunks.userInvitationHistory([INVITE_STATUS.REQUESTED, INVITE_STATUS.DENIED, INVITE_STATUS.EXPIRED, INVITE_STATUS.ACCEPTED]));
    }, [backRoute]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setTableHeaderDisplayUIState(!(requestPendingList.length === 0 && deniedList.length === 0 && expiredList.length === 0 && acceptedList.length === 0));
    }, [requestPendingList, deniedList, expiredList, acceptedList]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!users.inviteUserHistoryLoading && users.inviteUserHistorySuccess) {
            setUserInviteList(users.inviteUserHistory.requested.concat(users.inviteUserHistory.denied).concat(users.inviteUserHistory.expired));
            setAcceptedInvitationCount(users.inviteUserHistory.acceptedInvitationsCount);
            setRequestPendingList(getInviteList(users.inviteUserHistory.requested));
            setDeniedList(getInviteList(users.inviteUserHistory.denied));
            setExpiredList(getInviteList(users.inviteUserHistory.expired));
            setAcceptedList(getInviteList(users.inviteUserHistory.accepted));
        } else if (!users.inviteUserHistoryLoading && users.inviteUserHistoryFail) {
            setUserInviteList([]);
            setAcceptedInvitationCount(0);
            setRequestPendingList([]);
            setDeniedList([]);
            setExpiredList([]);
            setAcceptedList([]);

            dispatch(UsersActions.userInviteHistoryStarted());
        }
    }, [users.inviteUserHistoryLoading, users.inviteUserHistorySuccess, users.inviteUserHistoryFail]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!users.inviteAccessRequestLoading && users.inviteAccessRequestSuccess) {
            setUserInviteList([]);
            setAcceptedInvitationCount(0);
            setRequestPendingList([]);
            setDeniedList([]);
            setExpiredList([]);
            setAcceptedList([]);

            dispatch(UsersActions.userInviteHistoryUnmounted());
            dispatch(UsersThunks.userInvitationHistory([INVITE_STATUS.REQUESTED, INVITE_STATUS.DENIED, INVITE_STATUS.EXPIRED, INVITE_STATUS.ACCEPTED]));

        } else if (!users.inviteAccessRequestLoading && users.inviteAccessRequestFail) {

        }
    }, [users.inviteAccessRequestLoading, users.inviteAccessRequestSuccess, users.inviteAccessRequestFail]); // eslint-disable-line react-hooks/exhaustive-deps


    function getAcceptInvitationRequest(invitationStatus: number, userInvite: UserInvitationData): AcceptInvitationRequest {
        const inviteEntities: InviteEntities[] = [{
            entityId: getEntityId(userInvite),
            entityType: userInvite.invitationType.toString(),
        }];
        return {
            invitationStatus: invitationStatus,
            inviteEntities: inviteEntities
        }
    }

    const actionClick = (value: {
        inviteType: number;
    }): void => {
        switch (value.inviteType) {
            case INVITE_STATUS.DENIED://Handle Accept
                if (selectedInvitation !== undefined) {
                    const inviteAccept = getAcceptInvitationRequest(INVITE_STATUS.ACCEPTED, selectedInvitation);
                    dispatch(UsersThunks.updateUserInvitation(INVITE_STATUS.ACCEPTED, inviteAccept.inviteEntities, addToast));
                }
                break;

            case INVITE_STATUS.EXPIRED://Handle Request
                if (selectedInvitation !== undefined) {
                    dispatch(UsersThunks.userSendInvitationAccessRequest(getEntityId(selectedInvitation), addToast));
                }
                break;

            default:
                break;
        }
    };

    const actionClickPopup = (value: {
        inviteData: InviteData;
        inviteType: number
    }): void => {
        switch (value.inviteType) {
            case INVITE_STATUS.DENIED://Handle Accept
                const inviteAccept: UserInvitationList = userInviteList.filter((invite) => invite.invitationId === value.inviteData.invitationId && value.inviteData.entityId === getEntityId(invite));
                if (inviteAccept.length > 0) {
                    setSelectedInvitation(inviteAccept[0]);
                    setDisplayAcceptConfirmPopup(true);
                }
                break;

            case INVITE_STATUS.EXPIRED://Handle Request
                const inviteRequest: UserInvitationList = userInviteList.filter((invite) => invite.invitationId === value.inviteData.invitationId && value.inviteData.entityId === getEntityId(invite));
                if (inviteRequest.length > 0) {
                    setSelectedInvitation(inviteRequest[0]);
                    setDisplayRequestConfirmPopup(true);
                }
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        if (!users.updateUserInvitationLoading && users.updateUserInvitationSuccess) {
            dispatch(UsersThunks.userInvitationHistory([INVITE_STATUS.REQUESTED, INVITE_STATUS.DENIED, INVITE_STATUS.EXPIRED]));
        } else if (!users.updateUserInvitationLoading && users.updateUserInvitationFail) {
            // TO do error handle
        }
    }, [users.updateUserInvitationLoading, users.updateUserInvitationSuccess, users.updateUserInvitationFail]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div data-testid="invitation-history-container">
            <GenericToolbar
                title={t('USER_INVITATION_MANAGEMENT.INVITE_HISTORY')}
                showBackButton={true}
                backButtonOnClick={(): void => {
                    pushAppRoute(history, {
                        type: backRoute,
                    });
                }}
                color={ToolbarColor.Default}
            />

            <div data-testid="invitation-history-details" style={{ display: 'table', marginTop: 40, marginLeft: 'auto', marginRight: 'auto', width: '80%' }}>

                <Card style={{ flex: '1 1 0px', overflow: 'auto', marginTop: '2%', marginBottom: '2%' }} id="invitation-history-card" data-testid="invitation-history-card">

                    <Table stickyHeader size="small"
                        id="invitation-history-table"
                        data-testid="invitation-history-table">
                        {tableHeaderDisplayUIState && <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />}
                        {!users.inviteUserHistoryLoading && <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable-pending'}
                            id="invitation-history-table-pending"
                            data-testid="invitation-history-table-pending">
                            {stableSort(requestPendingList, getComparator(order, orderBy)).map((row: InviteData, index: number) => (
                                <InviteListItem
                                    key={row.invitationId}
                                    row={row}
                                    actionClick={actionClickPopup}
                                    inviteType={INVITE_STATUS.REQUESTED}
                                ></InviteListItem>
                            ))}
                        </TableBody>}


                        <CustomizedTableHead
                            headCells={headCellsDenied}
                            headColor={PXBColors.blue[500]}
                            headFontSize={16}
                        />

                        {users.inviteUserHistory.denied.length === 0 && <CustomizedTableHead
                            headCells={headCellsDeniedNoDataFound}
                            headColor={PXBColors.gray[500]}
                            headFontSize={16}
                            headFontWeight='400'
                        />}

                        {!users.inviteUserHistoryLoading && <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable-denied'}
                            id="invitation-history-table-deny"
                            data-testid="invitation-history-table-deny">
                            {stableSort(deniedList, getComparator(order, orderBy)).map((row: InviteData, index: number) => (
                                <InviteListItem
                                    key={row.invitationId}
                                    row={row}
                                    actionClick={actionClickPopup}
                                    inviteType={INVITE_STATUS.DENIED}
                                ></InviteListItem>
                            ))}
                        </TableBody>}

                        <CustomizedTableHead
                            headCells={headCellsExpired}
                            headColor={PXBColors.red[500]}
                            headFontSize={16}
                        />

                        {users.inviteUserHistory.expired.length === 0 && <CustomizedTableHead
                            headCells={headCellsExpiredNoDataFound}
                            headColor={PXBColors.gray[500]}
                            headFontSize={16}
                            headFontWeight='400'
                        />}

                        {!users.inviteUserHistoryLoading && <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable-expired'}
                            id="invitation-history-table-expired"
                            data-testid="invitation-history-table-expired">
                            {stableSort(expiredList, getComparator(order, orderBy)).map((row: InviteData, index: number) => (
                                <InviteListItem
                                    key={row.invitationId}
                                    row={row}
                                    actionClick={actionClickPopup}
                                    inviteType={INVITE_STATUS.EXPIRED}
                                ></InviteListItem>
                            ))}
                        </TableBody>}

                    </Table>

                    <InviteConfirmationDialog
                        data-testid='confirmationDialog'
                        show={displayAcceptConfirmPopup}
                        inviteType={INVITE_STATUS.DENIED}
                        title={t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPT_INVITATION') + '?'}
                        description1={t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPT_INVITATION_DESCRIPTION_1')}
                        description2={''}
                        actionText={t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPT_INVITATION')}
                        closeDialog={() => setDisplayAcceptConfirmPopup(false)}
                        actionClick={actionClick}

                        inviteData={selectedInvitation} />

                    <InviteConfirmationDialog
                        show={displayRequetConfirmPopup}
                        inviteType={INVITE_STATUS.EXPIRED}
                        title={t('USER_INVITATION_MANAGEMENT.INVITE_REQUEST_ACCESS') + '?'}
                        description1={t('USER_INVITATION_MANAGEMENT.INVITE_REQUEST_ACCESS_DESCRIPTION_1')}
                        description2={t('USER_INVITATION_MANAGEMENT.INVITE_REQUEST_ACCESS_DESCRIPTION_2')}
                        actionText={t('USER_INVITATION_MANAGEMENT.INVITE_REQUEST_ACCESS')}
                        closeDialog={() => setDisplayRequestConfirmPopup(false)}
                        actionClick={actionClick}
                        inviteData={undefined} />

                    {(users.inviteUserHistoryLoading || users.updateUserInvitationLoading) && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                        icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

                </Card >
                <Card style={{ flex: '1 1 0px', overflow: 'auto', marginTop: '2%', marginBottom: '2%' }} id="invitation-history-card" data-testid="invitation-history-card">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <CustomizedTableHead
                            headCells={headCellsTotalAccepted}
                            headColor={PXBColors.black[500]}
                            headFontSize={16}
                        />
                        <IconButton
                            onClick={() => setIsTableCollapsed(!isTableCollapsed)}
                            id="collapse-button"
                            data-testid='collapse-button'
                            style={{
                                width: '7.41%',
                            }}
                        >
                            {!isTableCollapsed ? (
                                <KeyboardArrowUp data-testid="arrow-up" id="list-header-up-icon" />
                            ) : (
                                <KeyboardArrowDown data-testid="arrow-down" id="list-header-down-icon" />
                            )}
                        </IconButton>
                    </div>
                    <Divider></Divider>
                    {!isTableCollapsed && (<Table stickyHeader size="small"
                        id="invitation-history-accepted-table"
                        data-testid="invitation-history-accepted-table">
                        {tableHeaderDisplayUIState && <EnhancedTableHead
                            order={order2}
                            orderBy={orderBy2}
                            onRequestSort={handleRequestSortForAcceptedInvitation}
                            headCells={headCells2}
                        />}
                        {users.inviteUserHistory.accepted.length === 0 && <CustomizedTableHead
                            headCells={headCellsTotalAcceptedNoDataFound}
                            headColor={PXBColors.gray[500]}
                            headFontSize={16}
                            headFontWeight='400'
                        />}

                        {!users.inviteUserHistoryLoading && <TableBody className="tableHover" style={{ minHeight: 100 }} key={'mainTable-accepted'}
                            id="invitation-history-table-accepted"
                            data-testid="invitation-history-table-accepted">
                            {stableSort(acceptedList, getComparator(order2, orderBy2)).map((row: InviteData, index: number) => (
                                <InviteListItem
                                    key={row.invitationId}
                                    row={row}
                                    actionClick={actionClickPopup}
                                    inviteType={INVITE_STATUS.ACCEPTED}
                                ></InviteListItem>
                            ))}
                        </TableBody>}
                    </Table>)}
                    <InviteConfirmationDialog
                        data-testid='confirmationDialog'
                        show={displayAcceptConfirmPopup}
                        inviteType={INVITE_STATUS.DENIED}
                        title={t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPT_INVITATION') + '?'}
                        description1={t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPT_INVITATION_DESCRIPTION_1')}
                        description2={''}
                        actionText={t('USER_INVITATION_MANAGEMENT.INVITE_ACCEPT_INVITATION')}
                        closeDialog={() => setDisplayAcceptConfirmPopup(false)}
                        actionClick={actionClick}

                        inviteData={selectedInvitation} />

                    <InviteConfirmationDialog
                        show={displayRequetConfirmPopup}
                        inviteType={INVITE_STATUS.EXPIRED}
                        title={t('USER_INVITATION_MANAGEMENT.INVITE_REQUEST_ACCESS') + '?'}
                        description1={t('USER_INVITATION_MANAGEMENT.INVITE_REQUEST_ACCESS_DESCRIPTION_1')}
                        description2={t('USER_INVITATION_MANAGEMENT.INVITE_REQUEST_ACCESS_DESCRIPTION_2')}
                        actionText={t('USER_INVITATION_MANAGEMENT.INVITE_REQUEST_ACCESS')}
                        closeDialog={() => setDisplayRequestConfirmPopup(false)}
                        actionClick={actionClick}
                        inviteData={undefined} />

                    {(users.inviteUserHistoryLoading || users.updateUserInvitationLoading) && <EmptyState style={{ flex: 1, minHeight: 400, backgroundColor: PXBColors.white[50] }}
                        icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

                </Card >

            </div>

        </div>
    );
};
