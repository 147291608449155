import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { SEND_INVITATION_ACCESS } from './url-constants';

type GetUserInvitationAccessRequestEndpointSpecifications = ApiEndpointSpecification;
export const GetUserInvitationAccessRequestEndpointSpecification: GetUserInvitationAccessRequestEndpointSpecifications = {
    url: SEND_INVITATION_ACCESS,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'UserInvitationAccessRequestParams',
    requestBodySchemaName: 'UserInvitationAccessRequestBody',
    okResponseSchemaName: 'UserInvitationAccessRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeSendInvitationAccessRequest = Helpers.makeApiRequestFn<
    Types.UserInvitationAccessRequestParams,
    Types.UserInvitationAccessRequestBody,
    Types.UserInvitationAccessRequestOkResponse
>(GetUserInvitationAccessRequestEndpointSpecification);
