import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { GET_SERVICE_MANAGER_CUSTOMER_DETAIL_LIST } from './url-constants';

type getServiceManagerCustomersDetailListEndpointSpecifications = ApiEndpointSpecification;
export const getServiceManagerCustomersDetailListEndpointSpecification: getServiceManagerCustomersDetailListEndpointSpecifications = {
    url: GET_SERVICE_MANAGER_CUSTOMER_DETAIL_LIST,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'GetServiceManagerCustomerDetailListRequestParams',
    requestBodySchemaName: 'GetservicemanagerCustomerDetailListRequestBody',
    okResponseSchemaName: 'GetserviceManagerCustomerDetailListOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeServiceManagerCustomerDetailListRequest = Helpers.makeApiRequestFn<
    Types.GetServiceManagerCustomerDetailListRequestParams,
    Types.GetServiceManagerCustomerDetailListRequestBody,
    Types.GetServiceManagerCustomerDetailListRequestOkResponse
>(getServiceManagerCustomersDetailListEndpointSpecification);
