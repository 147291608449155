// eslint-disable-next-line
import React, { useState, createContext, useCallback, useContext } from 'react';
import Toaster from '../../components/Toaster';

export type ToastContextData = {
    open: boolean;
    popupTitle?: string;
    message?: string;
    appearance?: string;
    addToast: (msg: string, severity?: string, title?: string) => void;
    removeToast: () => void;
};

const ToastContext = createContext<ToastContextData | undefined>(undefined);

function useToastsValue(): ToastContextData {
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [popupTitle, setPopupTitle] = useState<string | undefined>('Error');
    const [appearance, setAppearance] = useState<string | undefined>('success');

    const addToast = useCallback((msg: string, severity?: string, title?: string) => {
        setOpen(true);
        setPopupTitle(title ? title : 'Error');
        setMessage(msg);
        setAppearance(severity ? severity : 'success');
    }, []);

    const removeToast = useCallback(() => {
        setOpen(false);
    }, []);

    return {
        open,
        popupTitle,
        message,
        appearance,
        addToast,
        removeToast,
    };
}

function useToasts(): ToastContextData {
    const toastContext = useContext(ToastContext);
    if (!toastContext) {
        throw new Error('useToasts must be used within the ToastContext.Provider');
    }
    return toastContext;
}

const ToastProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = (props) => {
    const toastValue = useToastsValue();
    return (
        <ToastContext.Provider value={toastValue}>
            <>
                <Toaster />
                {props.children}
            </>
        </ToastContext.Provider>
    );
};

const TestToastProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ value: ToastContextData }>>> = (props) => (
    <ToastContext.Provider value={props.value}></ToastContext.Provider>
);

export { ToastProvider, TestToastProvider, useToasts, useToastsValue };
