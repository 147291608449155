// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Close, Search } from '@mui/icons-material';
import { TreeView } from '@mui/x-tree-view';
import { DialogContent, IconButton, DialogTitle, Typography, TextField, InputAdornment, Box, useMediaQuery, useTheme, CircularProgress } from '@mui/material';
import CustomTreeItem from '../../components/CustomTree/CustomTreeContent';
import { COLOR_GRAY } from '../../constants/color-codes';
import { getDeviceType } from '../enum/ENUM';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { UserAdvanceAccessDetail } from '../../types/manage-users';
import { ORGANIZATTION_NAME } from '../../constants';
import * as PXBColors from '@brightlayer-ui/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { EmptyState } from '@brightlayer-ui/react-components';


type Props = {
    isLoggedInUser: boolean;
    fullname: string;
    firstName: string;
    userAccessDetailData: UserAdvanceAccessDetail;
    onCancelAdvance: Function;
    isAdvanceTreeDataAvailable: boolean;
};

export const ViewAccessDetailsDialog: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();

    const [isDataAvailable, setDataAvailable] = useState(false);
    const [query, setQuery] = useState('');
    const [orgDetails, setOrgDetails] = useState<UserAdvanceAccessDetail>({});
    const [userData, setUserData] = React.useState<UserAdvanceAccessDetail>();

    useEffect(() => {
        if ((props.userAccessDetailData.inviteByUserEntityAccessDetail && props.userAccessDetailData.inviteByUserEntityAccessDetail.length > 0) ||
            (props.userAccessDetailData.inviteUserEntityAccessDetail && props.userAccessDetailData.inviteUserEntityAccessDetail.length > 0)) {
            /*** This is to show only common orgs between logged in user and other user ***/
            if (!props.isLoggedInUser) {
                const cloneInviteUserEntityAccessDetailObj = props.userAccessDetailData.inviteUserEntityAccessDetail?.map((data) => data);
                props.userAccessDetailData.inviteUserEntityAccessDetail = [];
                props.userAccessDetailData.inviteByUserEntityAccessDetail!.forEach(element => {
                    const commonOrg = cloneInviteUserEntityAccessDetailObj?.find((data) => data.organizationId === element.organizationId);
                    if (commonOrg !== undefined) {
                        props.userAccessDetailData?.inviteUserEntityAccessDetail?.push(commonOrg);
                    }
                });
                setUserData(props.userAccessDetailData);
            }

            setDataAvailable(true);
        } else {
            setDataAvailable(false);
        }
        // eslint-disable-next-line
    }, [props.userAccessDetailData])

    const onCancelAdvanceInviteCall = () => {
        props.onCancelAdvance({});
    }

    const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (e.target.value === '' || ORGANIZATTION_NAME.test(e.target.value as string))
            setQuery(e.target.value);
    };

    const resetSearch = (): void => {
        setQuery('');
    };


    return (
        <div data-testid="advance-page-container"
            style={{ overflow: 'scroll' }}
        >
            <DialogContent>
                <div>
                    <IconButton style={{
                        textAlign: 'right',
                        float: 'right',
                        margin: '8px 16px'
                    }}
                        data-testid="close-icon"
                        onClick={() => onCancelAdvanceInviteCall()}>
                        <Close style={{ width: 24, height: 24, }} fontSize="small" />
                    </IconButton>
                    <DialogTitle data-testid="invite-user-dialog-header-2" id="invite-user-dialog-header-2" sx={{ padding: '32px 24px 0px 24px', mb: 2, alignSelf: "stretch" }}>{props.fullname}</DialogTitle>
                    <Typography
                        id="invite-user-dialog-header-subtitle-2"
                        data-testid="invite-user-dialog-header-subtitle-2"
                        style={{ alignSelf: 'left', color: COLOR_GRAY, fontSize: 16, paddingLeft: 24, paddingBottom: 8 }} variant="body2">
                        {t('MANAGE_USERS.ADVANCED_INVITE_PLEASE_ASSIGN_ROLES', { replace: { email: props.firstName } })}
                    </Typography>
                </div>
                <div style={{ padding: 24, paddingTop: 8, paddingBottom: 8, }}>
                    <Box data-testid="organization-hierarchy-container" id="organization-hierarchy-container"
                        sx={{
                            maxHeight: '60%', flexGrow: 1, maxWidth: '100%', overflowY: 'auto',
                            padding: 2, backgroundColor: PXBColors.white[200],
                        }}
                        px={2}>
                        {!props.isAdvanceTreeDataAvailable && isDataAvailable && <Typography
                            id="no-data"
                            data-testid="no-data"
                            style={{ fontSize: 16, alignContent: 'center' }} variant="body2">
                            {t('DASHBOARD.NO_DATA')}
                        </Typography>}
                        {!isDataAvailable && <EmptyState style={{ flex: 1, height: '100%', backgroundColor: PXBColors.white[50] }}
                            icon={<CircularProgress id="progress-spinner" />} title={t('MESSAGES.LOADING')} placeholder={undefined} />}
                        {props.isAdvanceTreeDataAvailable && <TreeView
                            data-testid="advance-tree-view"
                            aria-label="icon expansion"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            sx={{ minHeight: 200, flexGrow: 1, maxWidth: '100%', position: 'relative' }}
                        >
                            {!props.isLoggedInUser && userData!?.inviteUserEntityAccessDetail!?.length > 0 ? userData!?.inviteUserEntityAccessDetail?.map((itemsListOrg) => (
                                <CustomTreeItem data-testid="advance-tree-item-org"
                                    nodeId={`${itemsListOrg.organizationId} # ${itemsListOrg.organizatoinRoleType}`} label={itemsListOrg.organizationName + '#' + itemsListOrg.buildingCount + ' ' + ((itemsListOrg.buildingCount > 1) ? t('ASSETS_DETAILS.BUILDINGS') : t('ENTITY_TYPE.BUILDING'))}
                                >
                                    {itemsListOrg.buildings ? itemsListOrg.buildings.map((itemsListBuilding) => (
                                        <CustomTreeItem data-testid="advance-tree-item-building"
                                            nodeId={`${itemsListBuilding.buildingId} # ${itemsListBuilding.buildingRoleType}`} label={itemsListBuilding.buildingName + '#' + itemsListBuilding.systemCount + ' ' + ((itemsListBuilding.systemCount > 1) ? t('DEVICE_DETAILS.SYSTEMS') : t('ENTITY_TYPE.SYSTEM'))}>
                                            {itemsListBuilding.systems ? itemsListBuilding.systems.map((objsystem) => (
                                                <CustomTreeItem data-testid="advance-tree-item-system"
                                                    nodeId={`${objsystem.systemId} # ${objsystem.systemRoleType}`}
                                                    label={"\"" + objsystem.systemName + "\", " + getDeviceType(objsystem.systemType) + '#' + objsystem.luminaryCount + ' ' + ((objsystem.luminaryCount > 1) ? t('DEVICE_DETAILS.LUMINAIRES') : t('DEVICE_DETAILS.LUMINAIRE'))} >
                                                </CustomTreeItem>
                                            )) : ''}
                                        </CustomTreeItem>
                                    )) : ''}
                                </CustomTreeItem>
                            )) :
                                props.userAccessDetailData?.inviteByUserEntityAccessDetail!?.length > 0 && props.userAccessDetailData?.inviteByUserEntityAccessDetail?.map((itemsListOrg) => (
                                    <CustomTreeItem data-testid="advance-tree-item-org"
                                        nodeId={`${itemsListOrg.organizationId} # ${itemsListOrg.organizatoinRoleType}`} label={itemsListOrg.organizationName + '#' + itemsListOrg.buildingCount + ' ' + ((itemsListOrg.buildingCount > 1) ? t('ASSETS_DETAILS.BUILDINGS') : t('ENTITY_TYPE.BUILDING'))}
                                    >
                                        {itemsListOrg.buildings ? itemsListOrg.buildings.map((itemsListBuilding) => (
                                            <CustomTreeItem data-testid="advance-tree-item-building"
                                                nodeId={`${itemsListBuilding.buildingId} # ${itemsListBuilding.buildingRoleType}`} label={itemsListBuilding.buildingName + '#' + itemsListBuilding.systemCount + ' ' + ((itemsListBuilding.systemCount > 1) ? t('DEVICE_DETAILS.SYSTEMS') : t('ENTITY_TYPE.SYSTEM'))}>
                                                {itemsListBuilding.systems ? itemsListBuilding.systems.map((objsystem) => (
                                                    <CustomTreeItem data-testid="advance-tree-item-system"
                                                        nodeId={`${objsystem.systemId} # ${objsystem.systemRoleType}`}
                                                        label={"\"" + objsystem.systemName + "\", " + getDeviceType(objsystem.systemType) + '#' + objsystem.luminaryCount + ' ' + ((objsystem.luminaryCount > 1) ? t('DEVICE_DETAILS.LUMINAIRES') : t('DEVICE_DETAILS.LUMINAIRE'))} >
                                                    </CustomTreeItem>
                                                )) : ''}
                                            </CustomTreeItem>
                                        )) : ''}
                                    </CustomTreeItem>
                                ))
                            }
                        </TreeView>}
                    </Box>
                </div>
            </DialogContent>
        </div >
    )
}
