// eslint-disable-next-line
import React from 'react';
import {
    Divider,
    Card,
} from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import * as PXBColors from '@brightlayer-ui/colors';
import { Button } from '@mui/material';
import { AccountCircle, Call, Delete, Edit, Fingerprint, Notes, Person, Room } from '@mui/icons-material';
import { OrgDetailListItem } from '../../components/OverviewList/OrgDetailListItem';
import { COLOR_GRAY_ICON } from '../../constants/color-codes';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { servicePartnerDetail } from '../../types/add-ServicePartnerCustomer';
import { pushAppRoute } from '../../data/domain/route-manager';
import { useHistory } from 'react-router';
import { ActionType } from '../../constants';

type Props = {
    servicePartnerInfo: servicePartnerDetail;
};

export const ServicePartnerInfoCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const history = useHistory();
    const goToEditServicePartnerCustomer = (): void => {
        pushAppRoute(history,
            { type: 'CreateServicePartner' ,actionType: ActionType.EDIT},
            { backRoute: 'ManageUsers' , customerId :  props.servicePartnerInfo.id});
    };

    return (
        <Card style={{ height: 'fit-content', flex: '1 1 0px', overflow: 'auto' }}
            id="servicePartner-detail-info-container"
            data-testid="servicePartner-detail-info-container">
            <div style={{ display: 'flex', justifyContent: 'center', padding: 16 }} data-testid="servicePartner-logo">
                {props.servicePartnerInfo.imageBase64 ? <img style={{ display: 'flex', width: 100, height: 100 }} src={"data:image/jpeg;base64," + props.servicePartnerInfo.imageBase64} alt="" /> : <AccountCircle style={{ display: 'flex', width: 96, height: 96, justifyContent: 'center', alignItems: 'center', flexShrink: 0, color: COLOR_GRAY_ICON }} />}
            </div>
            <Divider />
            <InfoListItem data-testid='servicePartner-name' title={props.servicePartnerInfo.companyName}
                icon={<Person style={{ color: COLOR_GRAY_ICON }} />}
                iconAlign={'center'}
                divider={'full'} placeholder={undefined}
            />
            <InfoListItem data-testid='servicePartner-number' title={props.servicePartnerInfo.customerNumber} subtitle={t('CUSTOMER_DETAILS.CUSTOMER_NUMBER')}
                icon={<Fingerprint style={{ color: COLOR_GRAY_ICON }} />}
                iconAlign={'center'}
                divider={'full'} placeholder={undefined}
            />
            <OrgDetailListItem
                title1={props.servicePartnerInfo.companyAddress1}
                title2={props.servicePartnerInfo.companyAddress2 + ' ' + props.servicePartnerInfo.city}
                title3={props.servicePartnerInfo.country}
                subtitle={t('ORGANIZATION_DETAILS.ADDRESS')}
                icon={<Room style={{ color: COLOR_GRAY_ICON }} />}
                iconAlignment={'center'}
                displayAction={false}
                dividerDisable={true}
            />
            <Divider />
            <OrgDetailListItem
                title1={'+' + (props.servicePartnerInfo.companyCountryCode ? props.servicePartnerInfo.companyCountryCode : '') + ' ' + (props.servicePartnerInfo.companyPhone ? props.servicePartnerInfo.companyPhone : '')}
                title2={''}
                title3={''}
                subtitle={t('FORMS.PHONE_NUMBER')}
                icon={<Call style={{ color: COLOR_GRAY_ICON }} />}
                iconAlignment={'center'}
                displayAction={false}
                dividerDisable={true}
            />
            <Divider />
            <OrgDetailListItem
                title1={''}
                title2={''}
                title3={''}
                subtitle={props.servicePartnerInfo.comments}
                subtitle2={''}
                icon={<Notes style={{ color: COLOR_GRAY_ICON }} />}
                iconAlignment={'center'}
                displayAction={false}
                dividerDisable={true}
            />
            <Divider />
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15, marginRight: 15, marginBottom: 15 }} >

                <Button
                    style={{
                        backgroundColor: PXBColors.blue[700],
                        color: PXBColors.white[50],
                        marginTop: 15,
                        textTransform: 'none',
                    
                    }}
                    id="servicePartnerInfo-edit-button"
                    data-testid="servicePartnerInfo-edit-button"
                    variant="contained"
                    onClick={(): void => goToEditServicePartnerCustomer()}
                >
                    <Edit style={{ padding: 3 }} />
                    {t('CUSTOMER_DETAILS.EDIT_CUSTOMER_INFO')}
                </Button>


                <Button
                    disabled={true}
                    style={{
                        backgroundColor: PXBColors.white[50],
                        color: PXBColors.red[500],
                        borderColor: PXBColors.red[500],
                        marginTop: 15,
                        textTransform: 'none',
                        opacity: 0.5
                    }}
                    id="servicePartner-delete-button"
                    data-testid="servicePartner-delete-button"
                    variant="outlined"
                    onClick={(): void => { }}
                >
                    <Delete style={{ padding: 3 }} />
                    {t('CUSTOMER_DETAILS.DELETE_CUSTOMER')}
                </Button>

            </div>
        </Card>
    );
};
