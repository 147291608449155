import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { ORGANIZATION_LIST } from './url-constants';

type OrganizationListEndpointSpecifications = ApiEndpointSpecification;
export const OrganizationListEndpointSpecification: OrganizationListEndpointSpecifications = {
    url: ORGANIZATION_LIST,
    method: HttpMethod.GET,
    requestParamsSchemaName: 'OrganizationListRequestParams',
    requestBodySchemaName: 'OrganizationListRequestBody',
    okResponseSchemaName: 'OrganizationListOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeOrganizationList = Helpers.makeApiRequestFn<
    Types.OrganizationListRequestParams,
    Types.OrganizationListRequestBody,
    Types.OrganizationListOkResponse
>(OrganizationListEndpointSpecification);
