// eslint-disable-next-line
import React, { useState } from 'react';
import {
    TableRow,
    IconButton,
    Typography,
    Link,
    TableCell,

} from '@mui/material';
import * as PXBColors from '@brightlayer-ui/colors';
import { ChevronRight } from '@mui/icons-material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { Star } from '@mui/icons-material';
import { StarBorder } from '@mui/icons-material';
import { GatewayAssetData, GatewayAssetList } from '../../types/gateway';
import { DeviceState, ENTITY_TYPE, getDeviceStatusState, getGatewayConnectionType } from '../../app/enum/ENUM';
import { TooltipItem } from '../TooltipItem/TooltipItem';
import { getControlledText } from '../../app/enum/GenericFormat';
import { useDispatch } from 'react-redux';
import { OrganizationThunks } from '../../actions/thunks/organization-thunks';

type Props = {
    row: GatewayAssetData;
    selectedGateway: string;
    displayClick: Function;
    systemCountClick: Function;
};

export const AssetGatewayListItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const row: GatewayAssetData = props.row;
    const dispatch = useDispatch();
    const [gatewayList, setGatewayList] = useState<GatewayAssetList>([]);
    const [isFavourite, setFavourite] = useState(false);

    const handleDisplay = (): void => {
        props.displayClick({ gatewayData: row });
    };

    const handleSystemCount = (): void => {
        props.systemCountClick({ buildingId: row.buildingId, projectId: row.projectId, gatewayId: row.gateWayId });
    };

    const handleMarkAsFav = (): void => {
        dispatch(OrganizationThunks.markAsFav(
            row.gateWayId,
            ENTITY_TYPE.GATEWAY
        ));
    }

    const handleRemoveMarkAsFav = (): void => {
        dispatch(OrganizationThunks.removeMarkAsFav(
            row.gateWayId,
            ENTITY_TYPE.GATEWAY
        ));
    }



    const systemState: DeviceState = getDeviceStatusState(row.gatewayStatus);

    return (
        <TableRow
            id={`row${row.gateWayId}`}
            data-testid={`row${row.gateWayId}`}
            style={{ cursor: 'pointer', width: '100%', backgroundColor: props.selectedGateway === row.gateWayId ? PXBColors.blue[50] : PXBColors.white[100] }}
            //onClick={(): void => handleDisplay()}
        >
            <TableCell style={{ width: '0%', paddingLeft: 16 }}
                id="gateway-list-item-favorite"
                data-testid="gateway-list-item-favorite">
                {row.isFavourite ? <Star data-testid='removeMarkAsFaVGateway' onClick={handleRemoveMarkAsFav} style={{ color: '#007BC1' }}  /> : <StarBorder onClick={handleMarkAsFav} style={{ color: '#727E84' }} />}
            </TableCell>
            <TooltipItem title={row.gateWayName} isTopPlacement={true} lenghtLimit={15} component={<TableCell
                id="gateway-list-item-name"
                data-testid="gateway-list-item-name"
                align="left"
                padding="checkbox"
                style={{ width: '14%', wordWrap: 'break-word', wordBreak: 'break-all' }}
            >
                {getControlledText(row.gateWayName, 15)}
            </TableCell>}>

            </TooltipItem>
            <TooltipItem title={row.buildingName} isTopPlacement={true} lenghtLimit={15} component={<TableCell align="left" padding="checkbox" style={{ width: '14%', padding: 0, paddingLeft: 16 }}
                id="gateway-list-item-building-name"
                data-testid="gateway-list-item-building-name">
                {getControlledText(row.buildingName, 15)}
            </TableCell>}>

            </TooltipItem>
            <TableCell align="left" padding="checkbox" style={{ width: '5%', padding: 0, paddingLeft: 16, color: PXBColors.blue[500] }}
                id="gateway-list-item-sys-count"
                data-testid="gateway-list-item-sys-count"
                onClick={(): void => handleSystemCount()}>
                <Link href="#" underline="hover">{row.systemCount}</Link>
            </TableCell>

            <TableCell align="left" padding="checkbox" style={{ width: '13%', padding: 0, paddingLeft: 16 }}
                id="gateway-list-item-type"
                data-testid="gateway-list-item-type">
                {t(getGatewayConnectionType(row.connectionType))}
            </TableCell>

            <TableCell align="left" style={{ width: '10%', paddingLeft: 16 }}
                id="gateway-list-item-status"
                data-testid="gateway-list-item-status">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'left',
                    }}
                >
                    <div style={{ minWidth: 25 }}>
                        <img
                            src={systemState.statusImage}
                            alt="logo"
                            style={{ display: 'flex', alignItems: 'right' }}
                            id="gateway-status-icon"
                            data-testid="gateway-status-icon"
                        /></div>
                    <Typography
                        id="gateway-status-button"
                        data-testid="gateway-status-button"

                        style={{ color: systemState.statusColor, textTransform: 'none', marginLeft: 10, fontWeight: 600 }} variant="body2">
                        {t(systemState.statusTitle)}
                    </Typography>
                </div>
            </TableCell>

            <TableCell style={{ width: '3%' }}
                id="gateway-list-item-next"
                data-testid="gateway-list-item-next">
                <IconButton edge="end" id="list-item-next-icon" style={{ marginLeft: "auto" }} >
                    <ChevronRight onClick={(): void => handleDisplay()}  />
                </IconButton>
            </TableCell>

            <TableCell align="left" padding="checkbox" style={{ width: '0%', padding: 0 }}
                id="gateway-list-item-empty"
                data-testid="gateway-list-item-empty">

            </TableCell>

        </TableRow>
    );
};
