import { SignalRConnectionData } from '../types/signalr-types';

export type SiganlRConnectionState = {
    loading: boolean;
    success: boolean;
    data: SignalRConnectionData;
};

export const initialSiganlRConnection: SiganlRConnectionState = {
    loading: false,
    success: false,
    data: { url: '', accessToken: '' },
};
