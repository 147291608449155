import { ActionType } from '../../types';

export const SignalRConnectionActions = {
    SignalRConnectionStarted: () =>
        ({
            type: 'SignalRConnection/Load/Started',
        } as const),

    SignalRConnectionSucceeded: (status: number, url: string, accessToken: string) =>
        ({
            type: 'SignalRConnection/Load/Succeeded',
            payload: { url, accessToken },
        } as const),

    SignalRConnectionFailed: () =>
        ({
            type: 'SignalRConnection/Load/Failed',
        } as const),
};
export type SignalRConnectionAction = ActionType<typeof SignalRConnectionActions>;
