import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { ORGANIZATION_DETAILS } from './url-constants';

type OrgDetailRequestEndpointSpecifications = ApiEndpointSpecification;
export const OrgDetailRequestEndpointSpecification: OrgDetailRequestEndpointSpecifications = {
    url: ORGANIZATION_DETAILS,
    method: HttpMethod.POST,
    requestParamsSchemaName: 'OrgDetailRequestParams',
    requestBodySchemaName: 'OrgDetailRequestBody',
    okResponseSchemaName: 'OrgDetailRequestOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: false,
};

export const invokeOrgDetailRequest = Helpers.makeApiRequestFn<
    Types.OrgDetailRequestParams,
    Types.OrgDetailRequestBody,
    Types.OrgDetailRequestOkResponse
>(OrgDetailRequestEndpointSpecification);
