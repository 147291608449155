/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Data, isDataLoaded, makeLoadedData } from '../../types/data-providers';
import { useAppData } from '.';
import { AppDataLoader } from '../../lib/app-data-loader';
import { OrganizationData } from '../../types';
import { SLADetailsData, SMCustomerData } from '../../types/customer-detail';
import { SMOrganization } from '../../types/organization';

export const useAppOrganizationData = (organizationId: string): Data<OrganizationData> => {
    const appData = useAppData();

    return React.useMemo(() => {
        const organizationData = AppDataLoader.getOrganization(appData, organizationId ? organizationId : undefined);
        if (!isDataLoaded(organizationData)) {
            return organizationData;
        }

        const result: Data<{
            id: string;
            name: string;
            address: string;
        }> = makeLoadedData({
            id: organizationData.value.id,
            name: organizationData.value.name,
            address: organizationData.value.address,
        });

        return result;
    }, [appData, organizationId]);
};

export const useAppCustomerData = (customerId: string): Data<SMCustomerData> => {
    const appData = useAppData();

    return React.useMemo(() => {
        const customerData = AppDataLoader.getCustomer(appData, customerId ? customerId : undefined);
        if (!isDataLoaded(customerData)) {
            return customerData;
        }

        const result: Data<
            SMCustomerData
        > = makeLoadedData({
            id: customerData.value.id,
            name: customerData.value.name,
            address: customerData.value.address,
            primaryEmail: customerData.value.primaryEmail,
            primaryName: customerData.value.primaryName,
            primaryPhone: customerData.value.primaryPhone,
            adminCount: customerData.value.adminCount,
            viewerCount: customerData.value.viewerCount,
            customerNumber: customerData.value.customerNumber,
        });

        return result;
    }, [appData, customerId]);
};



