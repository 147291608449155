// eslint-disable-next-line
import React from 'react';
import { StatusLabel } from '../StatusLabel';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';

export const OpenStatusLabelStatus: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ statusName?: string, statusColor: string, foregroundColor: string }>>> = (props) => {
    const { t } = useLanguageLocale();

    return (
        <StatusLabel
            status={props.statusName ? props.statusName : t('DASHBOARD.TIMELINE_DETAILS.OPEN')}
            foregroundColor={props.foregroundColor}
            backgroundColor={props.statusColor}
            testId="open-status-label"
        />
    );
};
