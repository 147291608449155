import { InitialState } from '../state';
import { AppReducer } from './app-reducer';

export const logbookReducer: AppReducer<'Logbook'> = (state = InitialState.Logbook, action): typeof state => {
    switch (action.type) {
        case 'Logbook/LatestLogList/Started':
            return {
                ...state,
                latestLogListLoading: true,
                latestLogListSuccess: false,
                latestLogListFail: false,
                latestLogErrorCode: '',
            };
        case 'Logbook/LatestLogList/Succeeded':
            return {
                ...state,
                latestLogListLoading: false,
                latestLogListSuccess: true,
                latestLogListFail: false,
                latestLogErrorCode: '',
                latestLogList: action.payload.systemLogListData,
            };
        case 'Logbook/LatestLogList/Failed':
            return {
                ...state,
                latestLogListLoading: false,
                latestLogListSuccess: false,
                latestLogListFail: true,
                latestLogErrorCode: action.payload.errorCode,
            };
        case 'Logbook/LatestLogList/Unmounted':
            return {
                ...state,
                latestLogListLoading: false,
                latestLogListSuccess: false,
                latestLogListFail: false,
                latestLogErrorCode: '',
            };

        case 'Logbook/EarlierLogList/Started':
            return {
                ...state,
                earlierLogListLoading: true,
                earlierLogListSuccess: false,
                earlierLogListFail: false,
                earlierLogListErrorCode: '',
            };

        case 'Logbook/EarlierLogList/Succeeded':
            return {
                ...state,
                earlierLogListLoading: false,
                earlierLogListSuccess: true,
                earlierLogListFail: false,
                earlierLogListErrorCode: '',
                earlierLogList: action.payload.earlierLogList,
            };
        case 'Logbook/EarlierLogList/Failed':
            return {
                ...state,
                earlierLogListLoading: false,
                earlierLogListSuccess: false,
                earlierLogListFail: true,
                earlierLogListErrorCode: action.payload.errorCode,
            };
        case 'Logbook/EarlierLogList/Unmounted':
            return {
                ...state,
                earlierLogListLoading: false,
                earlierLogListSuccess: false,
                earlierLogListFail: false,
                earlierLogListErrorCode: '',
            };

        case 'Logbook/upsertLogComments/Started':
            return {
                ...state,
                upsertLogCommentsLoading: true,
                upsertLogCommentsSuccess: false,
                upsertLogCommentsFail: false,
                upsertLogCommentsErrorMessage: '',
            };
        case 'Logbook/upsertLogComments/Succeeded':
            return {
                ...state,
                upsertLogCommentsLoading: false,
                upsertLogCommentsSuccess: true,
                upsertLogCommentsFail: false,
                upsertLogCommentsErrorMessage: '',
            };
        case 'Logbook/upsertLogComments/Failed':
            return {
                ...state,
                upsertLogCommentsLoading: false,
                upsertLogCommentsSuccess: false,
                upsertLogCommentsFail: true,
                upsertLogCommentsErrorMessage: action.payload.errorCode,
            };
        case 'Logbook/upsertLogComments/Unmounted':
            return {
                ...state,
                upsertLogCommentsLoading: false,
                upsertLogCommentsSuccess: false,
                upsertLogCommentsFail: false,
                upsertLogCommentsErrorMessage: '',
            };

        case 'Logbook/acknowledgeLog/Started':
            return {
                ...state,
                acknowledgeLogLoading: true,
                acknowledgeLogSuccess: false,
                acknowledgeLogFail: false,
                acknowledgeLogErrorMessage: '',
            };
        case 'Logbook/acknowledgeLog/Succeeded':
            return {
                ...state,
                acknowledgeLogLoading: false,
                acknowledgeLogSuccess: true,
                acknowledgeLogFail: false,
                acknowledgeLogErrorMessage: '',
            };
        case 'Logbook/acknowledgeLog/Failed':
            return {
                ...state,
                acknowledgeLogLoading: false,
                acknowledgeLogSuccess: false,
                acknowledgeLogFail: true,
                acknowledgeLogErrorMessage: action.payload.errorCode,
            };
        case 'Logbook/acknowledgeLog/Unmounted':
            return {
                ...state,
                acknowledgeLogLoading: false,
                acknowledgeLogSuccess: false,
                acknowledgeLogFail: false,
                acknowledgeLogErrorMessage: '',
            };

        case 'Logbook/PreviousLogList/Started':
            return {
                ...state,
                previousLogLoading: true,
                previousLogSuccess: false,
                previousLogFail: false,
                previousLogErrorCode: '',
            };
        case 'Logbook/PreviousLogData/Succeeded':
            return {
                ...state,
                previousLogLoading: false,
                previousLogSuccess: true,
                previousLogFail: false,
                previousLogErrorCode: '',
                previousLogData: action.payload.previousLogData,
            };
        case 'Logbook/PreviousLogList/Failed':
            return {
                ...state,
                previousLogLoading: false,
                previousLogSuccess: false,
                previousLogFail: true,
                previousLogErrorCode: action.payload.errorCode,
            };

        case 'Logbook/PreviousLogList/Unmounted':
            return {
                ...state,
                previousLogLoading: false,
                previousLogSuccess: false,
                previousLogFail: false,
                previousLogErrorCode: '',
            };

        case 'Logbook/downloadLogbookReport/Started':
            return {
                ...state,
                downloadLogbookReportLoading: true,
                downloadLogbookReportSuccess: false,
                downloadLogbookReportFail: false,
                downloadLogbookReportErrorMessage: '',
            };
        case 'Logbook/downloadLogbookReport/Succeeded':
            return {
                ...state,
                downloadLogbookReportLoading: false,
                downloadLogbookReportSuccess: true,
                downloadLogbookReportFail: false,
                downloadLogbookReportErrorMessage: '',
                downloadLogbookReportData: action.payload.data,
            };
        case 'Logbook/downloadLogbookReport/Failed':
            return {
                ...state,
                downloadLogbookReportLoading: false,
                downloadLogbookReportSuccess: false,
                downloadLogbookReportFail: true,
                downloadLogbookReportErrorMessage: action.payload.errorCode,
            };
        case 'Logbook/downloadLogbookReport/Unmounted':
            return {
                ...state,
                downloadLogbookReportLoading: false,
                downloadLogbookReportSuccess: false,
                downloadLogbookReportFail: false,
                downloadLogbookReportErrorMessage: '',
            };

        default:
            return state;
    }
};
