// eslint-disable-next-line
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppRoutes } from '../../routes';
import { UpsertBuilding } from './UpsertBuilding';
import { CreateOrganization } from './CreateOrganization';
import { OrganizationDetails } from './OrganizationDetails';
import { Organizations } from './Organizations';
import { OrgBuildingDetails } from './OrgBuildingDetails';
import { InvitationHistory } from './InvitationHistory';

export const OrganizationManagement: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => (
    <Switch>
        <Route exact path={AppRoutes.Organizations} component={Organizations} />
        <Route exact path={AppRoutes.CreateOrganization} component={CreateOrganization} />
        <Route exact path={AppRoutes.OrganizationDetails} component={OrganizationDetails} />
        <Route exact path={AppRoutes.OrgBuildingDetails} component={OrgBuildingDetails} />
        <Route exact path={AppRoutes.EditOrganization} component={CreateOrganization} />
        <Route exact path={AppRoutes.EditBuilding} component={UpsertBuilding}/>
        <Route exact path={AppRoutes.UpsertBuilding} component={UpsertBuilding} />
        <Route exact path={AppRoutes.InvitationHistory} component={InvitationHistory} />
    </Switch>
);
