// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import {
    DialogActions,
    Button,
    DialogTitle,
    DialogContent,
    Typography,
    Dialog,
} from '@mui/material';

import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { AcceptInvitationRequest, InviteEntities, UserInvitationData, UserInvitationList } from '../../types/manage-users';
import { Thunks } from '../../actions';
import { Selectors } from '../../selectors';
import SelectAllEmpty from '../../assets/images/selectall_blank.svg';
import SelectAllSelected from '../../assets/images/selectall_selected.svg';
import { AcceptInviteList } from './AcceptInviteList';
import { INVITE_STATUS, ENTITY_TYPE, ACTION_TYPE } from '../../app/enum/ENUM';
import * as PXBColors from '@brightlayer-ui/colors';
import { Close, Add } from '@mui/icons-material';
import { useToasts } from '../../data/DataProviders/ToastProvider';

type DialogProps = {
    show: boolean;
    closeDialog: Function;
    actionType: Function;
};

export const ActiveInviteDialog: React.FC<React.PropsWithChildren<React.PropsWithChildren<DialogProps>>> = (props) => {
    const { t } = useLanguageLocale();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const usersSelector = useSelector(Selectors.Users.users);
    const [isDataAvailable, setDataAvailable] = useState(false);
    const [invitationCount, setInvitationCount] = useState(0); // eslint-disable-line @typescript-eslint/no-unused-vars
    const [isSelect, setIsSelect] = useState(false);
    const [isSelectAll, setSelectALL] = useState(false);
    const userDetails = useSelector(Selectors.User.user);
    const userName = userDetails.userDetail.firstname + " " + userDetails.userDetail.lastname;
    const [userInviteList, setUserInviteList] = useState<UserInvitationList>([]);
    const [updateInviteList, setUpdateInviteList] = useState<UserInvitationList>([]);


    const getEntityId = (userData: UserInvitationData) => {
        if (userData.invitationType === ENTITY_TYPE.ORGANIZATION)
            return userData.organiztionId;
        else if (userData.invitationType === ENTITY_TYPE.BUILDING)
            return userData.buidlingId;
        else if (userData.invitationType === ENTITY_TYPE.SYSTEM)
            return userData.systemId;
        else if (userData.invitationType === ENTITY_TYPE.PLATFORM)
            return userData.platformId;
        else
            return userData.organiztionId;
    }

    function getAcceptInvitationRequest(invitationStatus: number, userInviteList: UserInvitationList): AcceptInvitationRequest {
        const inviteEntities: InviteEntities[] = userInviteList.filter((item) => item.isSelected === true).map((user) => {
            return {
                entityId: getEntityId(user),
                entityType: user.invitationType.toString(),
            }
        });
        return {
            invitationStatus: invitationStatus,
            inviteEntities: inviteEntities
        }
    }

    const handleJoin = (): void => {
        var query = undefined;
        query = getAcceptInvitationRequest(INVITE_STATUS.ACCEPTED, userInviteList);
        dispatch(Thunks.Users.updateUserInvitation(INVITE_STATUS.ACCEPTED, query.inviteEntities, addToast));
        props.closeDialog();
        props.actionType(ACTION_TYPE.ACCEPT)
    };

    const handleDeny = (): void => {
        var query = undefined;
        query = getAcceptInvitationRequest(INVITE_STATUS.DENIED, userInviteList);
        dispatch(Thunks.Users.updateUserInvitation(INVITE_STATUS.DENIED, query.inviteEntities, addToast));
        props.closeDialog();
        props.actionType(ACTION_TYPE.DECLINE);
    };

    const handleSelectAll = () => {
        if (isSelectAll) {
            const updateInviteList = userInviteList.map(obj => ({ ...obj, isSelected: false }))
            setSelectALL(false);
            setIsSelect(false);
            setUpdateInviteList(updateInviteList);
            setDataAvailable(false);
            setUserInviteList(updateInviteList);
        }
        else {
            const updateInviteList = userInviteList.map(obj => ({ ...obj, isSelected: true }))
            setSelectALL(true);
            setUpdateInviteList(updateInviteList);
            setDataAvailable(true);
            setUserInviteList(updateInviteList);
            setIsSelect(true);
        }
    }

    //This updates the invitation list with flag for checkbox
    const handleSelectedData = (userSelectedData: UserInvitationData) => {
        userInviteList.map((item) => {
            if (item.organiztionId === userSelectedData.organiztionId && item.invitationType === userSelectedData.invitationType) {
                return { ...item, userSelectedData }
            }
            else {
                return item;
            }
        })
        if (userInviteList.filter((item) => item.isSelected === true).length > 0) {
            setDataAvailable(true);
            setInvitationCount(userInviteList.filter((item) => item.isSelected === true).length);
        }
        else {
            setDataAvailable(false);
            setInvitationCount(0);
        }
    }

    useEffect(() => {
        setUserInviteList(usersSelector.inviteUserHistory.invited);
    }, [props.show]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Dialog
                disableEscapeKeyDown={true}
                open={props.show}
                onClose={handleDeny}
                aria-labelledby="invite-user-dialog"
                maxWidth="md"
                data-testid="invite-user-dialog"
            >
                <DialogTitle id="invite-user-dialog-title" data-testid="invite-user-dialog-title" >{t('USER_INVITATION_MANAGEMENT.TITLE', { replace: { username: userName } })}</DialogTitle>
                <DialogContent>
                    <>
                        <div>
                            <Typography variant="subtitle1" component="p" >
                                {t('USER_INVITATION_MANAGEMENT.SUBTITLE')}
                            </Typography>
                            <Typography variant="body2" component="p" style={{ marginBottom: 10 }}>
                                {t('USER_INVITATION_MANAGEMENT.INSTRUCTIONS')}
                            </Typography>
                            <div style={{ display: 'flex', padding: 10 }}>
                                <div className="invite-dialog-checkbox" style={{ display: 'left', margin: 20, alignContent: 'center' }}>
                                    <div className="invite-dialog-checkbox-image">
                                        <img
                                            src={SelectAllSelected}
                                            alt="logo"
                                            hidden={!isSelectAll}
                                            style={{ float: 'left', paddingRight: 12 }}
                                            id="select-icon"
                                            onClick={handleSelectAll}
                                        />
                                        <img
                                            src={SelectAllEmpty}
                                            alt="logo"
                                            hidden={isSelectAll}
                                            style={{ float: 'left', paddingRight: 12 }}
                                            id="unselect-icon"
                                            onClick={handleSelectAll}
                                        />
                                    </div>
                                </div>
                                <Typography
                                    id="invite-accept-checkbox-all-label"
                                    data-testid="invite-accept-checkbox-all-label"
                                    style={{ alignSelf: 'center' }} variant="body2">
                                    {t('MANAGE_USERS.INVITE_USER_ORGANIZATION_SELECT_ALL')}
                                </Typography>
                            </div>
                            <div >
                                <AcceptInviteList datalist={isSelectAll ? updateInviteList : userInviteList} OnItemSelected={handleSelectedData} isSelectedAll={isSelectAll}></AcceptInviteList>
                            </div>
                        </div>
                    </>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'float', marginBottom: 5, marginRight: 20 }}>
                    <Button
                        id="btnJoin"
                        disabled={!isDataAvailable}
                        data-testid="join"
                        variant="contained"
                        color="primary"
                        onClick={handleJoin}>
                        <Add style={{ paddingRight: 5 }} />
                        {isSelect ? t('USER_INVITATION_MANAGEMENT.JOINALL') : t('USER_INVITATION_MANAGEMENT.JOIN')}
                    </Button>
                    <Button
                        style={{
                            borderColor: isDataAvailable ? PXBColors.red[500] : PXBColors.gray[500],
                            opacity: isDataAvailable ? 1 : 0.5,
                            backgroundColor: PXBColors.white[50],
                            color: isDataAvailable ? PXBColors.red[500] : PXBColors.gray[500],
                        }}
                        id="btnDecline"
                        data-testid="decline"
                        variant="outlined"
                        disabled={!isDataAvailable}
                        color="primary"
                        onClick={handleDeny}>
                        <Close style={{ color: isDataAvailable ? PXBColors.red[500] : PXBColors.gray[500], paddingRight: 5 }} />
                        {t('USER_INVITATION_MANAGEMENT.DECLINE_INVITATION')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
