import { AppThunk } from '../actions';
import * as ApiRequests from '../../api/requests';
import { OrganizationActions } from '../actions/organization-actions';
import { AuthHelper } from '../../types';

export const OrganizationThunks = {

    getOrganizationDetailsList: (projectId: string, searchtext?: string, limit?: string, offSet?: string): AppThunk => async (dispatch): Promise<void> => {
        dispatch(OrganizationActions.orgListStarted());

        const payload = await ApiRequests.getOrgDetails({ projectId: projectId, searchtext: searchtext, limit: limit, offSet: offSet }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(OrganizationActions.orgListSucceeded(payload.response.data));
            return;
        }

        dispatch(OrganizationActions.orgListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    getBuildingDetailsList: (projectId: string, buildingId: string): AppThunk => async (dispatch): Promise<void> => {
        dispatch(OrganizationActions.buildingListStarted());

        const payload = await ApiRequests.getBuildingDetails({ projectId: projectId, buildingId: buildingId }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(OrganizationActions.buildingListSucceeded(payload.response.data));
            return;
        }

        dispatch(OrganizationActions.buildingListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    transferBuilding: (
        destOrgId: string,
        srcOrgId: string,
        password: string,
        addToast: Function,
        authHelper: AuthHelper,
        buildingId?: string,

    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(OrganizationActions.transferBuildingStarted());
        const payload = await ApiRequests.transferBuilding(
            {
                destintationOrgId: destOrgId,
                sourceOrgId: srcOrgId,
                password: password,
                buildingId: buildingId,
            },
            { authHelper }
        );

        if (payload.ok) {
            dispatch(OrganizationActions.transferBuildingSucceeded(payload.response.message));
            return;
        }
        dispatch(OrganizationActions.transferBuildingFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
        addToast(`${ApiRequests.extractResponseNotOkMessage(payload.response)}`, 'error');
    },

    updateOrganization: (
        id: string,
        addToast: Function,
        authHelper: AuthHelper,
        name?: string,
        country?: string,
        email?: string,
        countrycode?: string,
        phone?: string,
        address?: string,
        postalcode?: string,
        city?: string,
        description?: string,
        address2?: string,
        primaryFirstName?: string,
        primaryLastName?: string,
        secondaryFirstName?: string,
        secondaryLastName?: string,
        secondaryEmail?: string,
        secondaryPhone?: string,
        secondaryCountryCode?: string,
        latitude?: string,
        longitude?: string,

    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(OrganizationActions.updateOrgStarted());

        const payload = await ApiRequests.updateOrg(
            {
                id: id,
                name: name,
                country: country,
                email: email,
                countryCode: countrycode,
                phone: phone,
                address: address,
                address2: address2,
                postalcode: postalcode,
                city: city,
                secondaryEmail: secondaryEmail,
                secondaryCountryCode: secondaryCountryCode,
                secondaryPhone: secondaryPhone,
                description: description,
                latitude: latitude,
                longitude: longitude,
                primaryFirstName: primaryFirstName,
                primaryLastName: primaryLastName,
                secondaryFirstName: secondaryFirstName,
                secondaryLastName: secondaryLastName
            },
            { authHelper }
        );

        if (payload.ok) {
            dispatch(OrganizationActions.updateOrgSucceeded(payload.response.message));
            return;
        }
        dispatch(OrganizationActions.updateOrgFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },
    upsertBuilding: (
        addToast: Function,
        authHelper: AuthHelper,
        parentSiteId: string,
        id?: string,
        name?: string,
        email?: string,
        countrycode?: string,
        phone?: string,
        address?: string,
        postalcode?: string,
        city?: string,
        description?: string,
        address2?: string,
        country?: string,
        latitude?: string,
        longitude?: string,
        primaryFirstName?: string,
        primaryLastName?: string,
        secondaryFirstName?: string,
        secondaryLastName?: string,
        secondaryEmail?: string,
        secondaryCountryCode?: string,
        secondaryPhone?: string,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(OrganizationActions.upsertBuildingStarted());

        const payload = await ApiRequests.upsertBuilding(
            {
                parentSiteId: parentSiteId,
                id: id,
                name: name,
                email: email,
                countryCode: countrycode,
                phone: phone,
                address: address,
                address2: address2,
                city: city,
                country: country,
                postalCode: postalcode,
                secondaryEmail: secondaryEmail,
                secondaryCountryCode: secondaryCountryCode,
                secondaryPhone: secondaryPhone,
                description: description,
                latitude: latitude,
                longitude: longitude,
                primaryFirstName: primaryFirstName,
                primaryLastName: primaryLastName,
                secondaryFirstName: secondaryFirstName,
                secondaryLastName: secondaryLastName
            },
            { authHelper }
        );

        if (payload.ok) {
            dispatch(OrganizationActions.upsertBuildingSucceeded(payload.response.message));
            return;
        }
        dispatch(OrganizationActions.upsertBuildingFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    markAsFav: (
        entityId: string,
        entityType: number
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(OrganizationActions.markasFavStarted());
        const payload = await ApiRequests.updateMarkAsFav(
            {
                entityId: entityId,
                entityType: entityType,
            },
            { authHelper: undefined }
        );
        if (payload.ok) {
            dispatch(OrganizationActions.markasFavSucceeded(payload.ok));
            return;
        }
        dispatch(OrganizationActions.markasFavFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    removeMarkAsFav: (
        entityId: string,
        entityType: number
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(OrganizationActions.removeMarkAsFavStarted());
        const payload = await ApiRequests.removeMarkAsFav(
            {
                entityId: entityId,
                entityType: entityType,
            },
            { authHelper: undefined }
        );
        if (payload.ok) {
            dispatch(OrganizationActions.removeMarkAsFavSucceeded(payload.ok));
            return;
        }
        dispatch(OrganizationActions.removeMarkAsFavFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },

    mergeAssetBuilding: (
        sourceBuildingId: string,
        destintationBuildingId: string,
        password: string,
        authHelper: AuthHelper,

    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(OrganizationActions.mergeBuildingAssetStarted());
        const payload = await ApiRequests.mergeAssetBuilding(
            {
                sourceBuildingId: sourceBuildingId,
                destintationBuildingId: destintationBuildingId,
                password: password
            },
            { authHelper }
        );

        if (payload.ok) {
            dispatch(OrganizationActions.mergeBuildingAssetSucceeded(payload.response.message));
            return;
        }
        dispatch(OrganizationActions.mergeBuildingAssetFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },


    getServiceManagerOrganizationList: (
        projectId: string,
    ): AppThunk => async (dispatch): Promise<void> => {
        dispatch(OrganizationActions.serviceManagerOrgListStarted());

        const payload = await ApiRequests.getServiceOrganizations({ projectId: projectId }, { authHelper: undefined });

        if (payload.ok) {
            dispatch(OrganizationActions.serviceManagerOrgListSucceeded(payload.response.data));
            return;
        }

        dispatch(OrganizationActions.serviceManagerOrgListFailed(`${ApiRequests.extractResponseNotOkCode(payload.response)}`));
    },
};
