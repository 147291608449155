import { DeviceCustomerDetail } from './../../types/customer-detail';
import { ActionType } from '../../types';
import { GatewayListData } from '../../types/gateway';
import { DeviceListData } from '../../types/device';
import { LuminaryListData } from '../../types/luminary';
import { SLADataList, SLAListData } from '../../types/sla';
import { slaCustomerList } from '../../types/sla-customer-details';

export const AssetActions = {
    deviceListStarted: () =>
    ({
        type: 'Asset/deviceList/Started',
    } as const),

    deviceListSucceeded: (deviceList: DeviceListData) =>
    ({
        type: 'Asset/deviceList/Succeeded',
        payload: { deviceList }
    } as const),

    deviceListFailed: (errorCode: string) =>
    ({
        type: 'Asset/deviceList/Failed',
        payload: { errorCode },
    } as const),

    deviceListUnmounted: () =>
    ({
        type: 'Asset/deviceList/Unmounted',
    } as const),

    deviceCustomerDataStarted: () =>
    ({
        type: 'Customer/deviceCustomerData/Started',
    } as const),

    deviceCustomerDataSucceeded: (customerData: DeviceCustomerDetail) =>
    ({
        type: 'Customer/deviceCustomerData/Succeeded',
        payload: { customerData }
    } as const),

    deviceCustomerDataFailed: (errorCode: string) =>
    ({
        type: 'Customer/deviceCustomerData/Failed',
        payload: { errorCode },
    } as const),

    deviceCustomerDataUnmounted: () =>
    ({
        type: 'Customer/deviceCustomerData/Unmounted',
    } as const),

    updateCustomerStarted: () =>
    ({
        type: 'Customer/updateCustomerDetail/Started',
    } as const),

    updateCustomerSucceeded: (id: string) =>
    ({
        type: 'Customer/updateCustomerDetail/Succeeded',
        payload: { id },
    } as const),

    updateCustomerFailed: (errorCode: string) =>
    ({
        type: 'Customer/updateCustomerDetail/Failed',
        payload: { errorCode },
    } as const),

    updateCustomerUnmounted: () =>
    ({
        type: 'Customer/updateCustomerDetail/Unmounted',
    } as const),

    luminaryListStarted: () =>
    ({
        type: 'Asset/luminaryList/Started',
    } as const),

    luminaryListSucceeded: (luminaryListData: LuminaryListData) =>
    ({
        type: 'Asset/luminaryList/Succeeded',
        payload: { luminaryListData }
    } as const),

    luminaryListFailed: (errorCode: string) =>
    ({
        type: 'Asset/luminaryList/Failed',
        payload: { errorCode },
    } as const),

    luminaryListUnmounted: () =>
    ({
        type: 'Asset/luminaryList/Unmounted',
    } as const),

    gatewayDetailsListStarted: () =>
    ({
        type: 'Asset/gatewayDetailsList/Started',
    } as const),

    gatewayDetailsListSucceeded: (gatewayDetailsList: GatewayListData) =>
    ({
        type: 'Asset/gatewayDetailsList/Succeeded',
        payload: { gatewayDetailsList }
    } as const),

    gatewayDetailsListFailed: (errorCode: string) =>
    ({
        type: 'Asset/gatewayDetailsList/Failed',
        payload: { errorCode },
    } as const),

    gatewayDetailsListUnmounted: () =>
    ({
        type: 'Asset/gatewayDetailsList/Unmounted',
    } as const),

    slaListStarted: () =>
    ({
        type: 'Asset/slaList/Started',
    } as const),

    slaListSucceeded: (SLADetailsList: SLAListData) =>
    ({
        type: 'Asset/slaList/Succeeded',
        payload: { SLADetailsList }
    } as const),

    slaListFailed: (errorCode: string) =>
    ({
        type: 'Asset/slaList/Failed',
        payload: { errorCode },
    } as const),

    slaListUnmounted: () =>
    ({
        type: 'Asset/slaList/Unmounted',
    } as const),

    slaDetailsListStarted: () =>
    ({
        type: 'Asset/slaDetailsList/Started',
    } as const),

    slaDetailsListSucceeded: (slaDetailsList: SLADataList) =>
    ({
        type: 'Asset/slaDetailsList/Succeeded',
        payload: { slaDetailsList }
    } as const),

    slaDetailsListFailed: (errorCode: string) =>
    ({
        type: 'Asset/slaDetailsList/Failed',
        payload: { errorCode },
    } as const),

    slaDetailsListUnmounted: () =>
    ({
        type: 'Asset/slaDetailsList/Unmounted',
    } as const),

    slaCustomerListStarted: () =>
    ({
        type: 'Asset/slaCustomerDetails/Started',
    } as const),

    slaCustomerListSucceeded: (customerDetailsList: slaCustomerList) =>
    ({
        type: 'Asset/slaCustomerDetails/Succeeded',
        payload: { customerDetailsList }
    } as const),

    slaCustomerListFailed: (errorCode: string) =>
    ({
        type: 'Asset/slaCustomerDetails/Failed',
        payload: { errorCode },
    } as const),

    slaCustomerListUnmounted: () =>
    ({
        type: 'Asset/slaCustomerDetails/Unmounted',
    } as const),
};

export type AssetAction = ActionType<typeof AssetActions>;
