// eslint-disable-next-line
import React, { useState } from 'react';
import { Card, } from '@mui/material';
import * as MuiCore from '@mui/material';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import { OverviewCustomerStaticalSummary } from './OverviewCustomerStaticalSummary';
import { OverviewBuildingList } from './OverviewBuildingList';
import { OverviewSelectedData } from '../../types/overview-type';
import { SM_BUILDING_TAB, TAB_BUILDING_LIST, TAB_BUILDING_SUMMARY } from '../../constants';


type Props = {
    userId?: string;
    projectId: string;
    buildingId?: string;
    elStatusClick?: Function;
    buildingStatusClick?: Function;
    viewAllClicked?: Function;
    onBuildingSelected?: Function;
    OnSearchTextChanged?: Function;
    customerId?: string;
};

export const OverviewSMBuildingCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const { t } = useLanguageLocale();
    const [activeTab, setActiveTab] = useState(TAB_BUILDING_SUMMARY);
    const { OnSearchTextChanged } = props;

    const handleActiveTabChange = (
        event: React.MouseEvent<HTMLElement>,
        selection: string | null,
    ) => {
        setActiveTab(selection || TAB_BUILDING_SUMMARY);
        window.sessionStorage.setItem(SM_BUILDING_TAB, selection || TAB_BUILDING_SUMMARY);
    };

    const handleDisplayELStatus = (value: {
        elstatus: any
    }): void => {
        if (props.elStatusClick)
            props.elStatusClick({ elstatus: value.elstatus });
    };

    const handleDisplayBuildingStatus = (value: {
        elstatus: any
    }): void => {
        if (props.buildingStatusClick)
            props.buildingStatusClick({ elstatus: value.elstatus });
    };

    const handleBuildingSelection = (building: OverviewSelectedData): void => {
        if (props.onBuildingSelected)
            props.onBuildingSelected(building);
    };

    const onViewAllClick = (tab: string) => {
        if (props.viewAllClicked)
            props.viewAllClicked(tab);
    };

    return (
        <>
            <Card
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: 10,

                }}
                data-testid="sm-building-card-tab"
            >
                <div data-testid="service-manager-selection-filter">
                    <MuiCore.ToggleButtonGroup
                        data-testid="toggle-group"
                        color="info"
                        sx={{ marginButtom: 20, width: '100%' }}
                        value={activeTab}
                        exclusive
                        onChange={handleActiveTabChange}
                        aria-label="medium outlined"
                    >
                        <MuiCore.ToggleButton data-testid="toggle-org" style={{ width: '50%', textTransform: 'none' }} value={TAB_BUILDING_SUMMARY}>{t('TABS.SUMMARY_VIEW').toUpperCase()}</MuiCore.ToggleButton>
                        <MuiCore.ToggleButton data-testid="toggle-cus" style={{ width: '50%', textTransform: 'none' }} value={TAB_BUILDING_LIST}>{t('TABS.LIST_VIEW').toUpperCase()}</MuiCore.ToggleButton>
                    </MuiCore.ToggleButtonGroup>
                </div>
            </Card>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    zIndex: 3,
                    marginTop: 'vh_20'
                }}
                data-testid="status-list"
            >
                {activeTab === TAB_BUILDING_SUMMARY && <OverviewCustomerStaticalSummary projectId={props.projectId} buildingId={props.buildingId} elStatusClick={handleDisplayELStatus} buildingStatusClick={handleDisplayBuildingStatus} viewAllClicked={onViewAllClick}></OverviewCustomerStaticalSummary>
                }
                {activeTab === TAB_BUILDING_LIST && <OverviewBuildingList
                    userId={''}
                    projectId={props.projectId}
                    customerId={props.customerId}
                    OnBuildingSelected={handleBuildingSelection}
                    OnSearchTextChanged={(query: string): void => {
                        if (OnSearchTextChanged)
                            OnSearchTextChanged(query);
                    }}
                />
                }
            </div>

        </>
    );
};
