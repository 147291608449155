
import { UserListData, UpdateInvitationList, UserInvitationTypeList, InvitationRequestList, UserAdvanceAccessDetail,RemoveAccess, SentInviteResultList } from "../types/manage-users";
import { UserDetailsData } from "../types/user-details-data";
import { userDetail } from "./user-state";

export type UsersState = {
    userListLoading: boolean;
    userListSuccess: boolean;
    userListFail: boolean;
    userList: UserListData,

    userListerrorCode: string;
    inviteUserLoading: boolean;
    inviteUserSuccess: boolean;
    inviteUserFail: boolean;
    inviteUsererrorCode: string;
    inviteUseData: SentInviteResultList;

    inviteUserHistoryLoading: boolean;
    inviteUserHistorySuccess: boolean;
    inviteUserHistory: UserInvitationTypeList;
    inviteUserHistoryFail: boolean;
    inviteUserHistoryerrorCode: string;

    inviteAccessRequestLoading: boolean;
    inviteAccessRequestSuccess: boolean;
    inviteAccessRequestFail: boolean;
    inviteAccessRequesterrorCode: string;

    updateUserInvitationLoading: boolean;
    updateUserInvitationSuccess: boolean;
    updateUserInvitationFail: boolean;
    updateUserInvitationerrorCode: string;
    updateUserInvitationResult: UpdateInvitationList
    errorCode: string;

    adminApporvalInviteHistoryLoading: boolean;
    adminApporvalInviteHistorySuccess: boolean;
    adminApporvalInviteHistory: InvitationRequestList;
    adminApporvalInviteHistoryFail: boolean;
    adminApporvalInviteHistoryerrorCode: string;

    updateInvitationRequestLoading: boolean;
    updateInvitationRequestSuccess: boolean;
    updateInvitationRequestFail: boolean;
    updateInvitationRequesterrorCode: string;
    updateInvitationRequestResult: UpdateInvitationList;

    getEntityUserDetailLoading: boolean;
    getEntityUserDetailSuccess: boolean;
    getEntityUserDetailFail: boolean;
    entityUserDetail: UserDetailsData,
    getEntityUserDetailErrorMessage: string;

    getuserAdvanceAccessLoading: boolean;
    getuserAdvanceAccessSuccess: boolean;
    getuserAdvanceAccessFail: boolean;
    getuserAdvanceAccess: UserAdvanceAccessDetail;
    getuserAdvanceAccessErrorMessage: string;

    removeAccessLoading: boolean;
    removeAccessSuccess: boolean;
    removeAccessFail: boolean;
    removeAccess: RemoveAccess;
    removeAccessErrorMessage: string;
};

const userListData: UserListData = {
    AdminCount: 0,
    viewersCount: 0,
    usersList: [],
};

const getuserAdvanceAccess: UserAdvanceAccessDetail = {
    inviteByUserEntityAccessDetail: [],
    inviteUserEntityAccessDetail: []
};

const removeAccess: RemoveAccess = {
    userId: '',
    entityId: '',
    entityType: 0,
};

const updateInvitationList: UpdateInvitationList = {
    successDetail: [],
    failureDetail: []
};

const sentInviteResultList: SentInviteResultList = {
    successDetail: [],
    failureDetail: []
};

export const initialUsersState: UsersState = {
    userListLoading: false,
    userListSuccess: false,
    userListFail: false,
    userList: userListData,
    userListerrorCode: '',

    inviteUserLoading: false,
    inviteUserSuccess: false,
    inviteUserFail: false,
    inviteUsererrorCode: '',
    inviteUseData: sentInviteResultList,

    inviteUserHistoryLoading: false,
    inviteUserHistorySuccess: false,
    inviteUserHistory: {
        invited: [],
        accepted: [],
        requested: [],
        expired: [],
        denied: [],
        acceptedInvitationsCount: 0
    },
    inviteUserHistoryFail: false,
    inviteUserHistoryerrorCode: '',

    inviteAccessRequestLoading: false,
    inviteAccessRequestSuccess: false,
    inviteAccessRequestFail: false,
    inviteAccessRequesterrorCode: '',

    updateUserInvitationLoading: false,
    updateUserInvitationSuccess: false,
    updateUserInvitationResult: updateInvitationList,
    updateUserInvitationFail: false,
    updateUserInvitationerrorCode: '',
    errorCode: '',

    adminApporvalInviteHistoryLoading: false,
    adminApporvalInviteHistorySuccess: false,
    adminApporvalInviteHistory: [],
    adminApporvalInviteHistoryFail: false,
    adminApporvalInviteHistoryerrorCode: '',

    updateInvitationRequestLoading: false,
    updateInvitationRequestSuccess: false,
    updateInvitationRequestFail: false,
    updateInvitationRequesterrorCode: '',
    updateInvitationRequestResult: updateInvitationList,

    getEntityUserDetailLoading: false,
    getEntityUserDetailSuccess: false,
    getEntityUserDetailFail: false,
    getEntityUserDetailErrorMessage: '',
    entityUserDetail: userDetail,

    getuserAdvanceAccessLoading: false,
    getuserAdvanceAccessSuccess: false,
    getuserAdvanceAccessFail: false,
    getuserAdvanceAccess: getuserAdvanceAccess,
    getuserAdvanceAccessErrorMessage: "",

    removeAccessLoading: false,
    removeAccessSuccess: false,
    removeAccessFail: false,
    removeAccess: removeAccess,
    removeAccessErrorMessage: "",
};
