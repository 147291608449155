// eslint-disable-next-line
import React, { ReactText, useState, useRef, useEffect,  } from 'react';

export const TimerLeft: React.FC<React.PropsWithChildren<React.PropsWithChildren<{ minutes: number; timeout: Function }>>> = (props) => {
    //let [time, setTime] = useState(props.minutes);
    const timeState = useState(props.minutes);
    let time = timeState[0];
    const setTime = timeState[1];
    const secondsPassed = useRef('');

    useEffect(() => {
        const timeout = setInterval(() => {
            if (time <= 0) {
                props.timeout();
                setTime(5 * 60);
            }
            let minutes: ReactText = parseInt((parseInt(time.toString(), 10) / 60).toString());
            let seconds: ReactText = parseInt(time.toString(), 10) % 60;

            minutes = minutes < 10 ? `0${minutes.toString()}` : minutes;
            seconds = seconds < 10 ? `0${seconds.toString()}` : seconds;

            secondsPassed.current = `${minutes}:${seconds}`;
            setTime(--time);
        }, 1000);
        return (): void => {
            clearInterval(timeout);
        };
    }, [props.minutes]); // eslint-disable-line react-hooks/exhaustive-deps

    return <>{secondsPassed.current}</>;
};
