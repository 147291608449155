// eslint-disable-next-line
import React from 'react';

export const ContentContainer: React.FC<React.PropsWithChildren<React.PropsWithChildren<{
    justifyContent?: 'start' | 'center' | 'space-between' | 'end' | 'simple-center';
    testId?: string;
}>>> = (props) => {
    const style: React.CSSProperties = {
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: 16,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: props.justifyContent || 'center',
        alignItems: 'stretch',
        marginLeft: 'auto',
        marginRight: 'auto',
        minHeight: '90vh',
        position: 'relative',
        overflowX: 'hidden',
    };

    if (!props.justifyContent || props.justifyContent === 'simple-center') {
        return (
            <div data-testid={props.testId} style={{ maxWidth: '1024px', ...style }}>
                <div style={{ flex: '1 1 100%', zIndex: 0 }}>{props.children}</div>
            </div>
        );
    }

    return (
        <div data-testid={props.testId} style={style}>
            {props.children}
        </div>
    );
};
