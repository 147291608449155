// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { EmptyState } from '@brightlayer-ui/react-components';
import { OrgBuildingData } from '../../types/organization';
import { useSelector } from 'react-redux';
import { Selectors } from '../../selectors';
import * as PXBColors from '@brightlayer-ui/colors';
import { useLanguageLocale } from '../../hooks/language-locale-hooks';
import MapUserLocation from '../../assets/images/map/User_Location.svg';
import MapGeneric from '../../components/MapContainer/MapGeneric';
import { useLoadScript } from '@react-google-maps/api';
import { GeoCodingBuildingAddress } from '../../types/building';

type Props = {
    buildingId: string;
    isUpsertBuilding: boolean;
    geocodePosition?: Function;
    modifiedAddress?: Function;
    address?: string;
    city?: string;
    postalCode?:string;
    country?: string;
    latitude?: number;
    longitude?: number;
};

const buildingDetailsDefault: OrgBuildingData = {
    buildingId: "",
    name: "",
    parentProjectId: "",
    projectName: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    contact: '',
    email: "",
    countryCode: "",
    phone: "",
    description: "",
    adopterId: '',
    latitude: "",
    longitude: "",
    gatewayCount: 0,
    systemCount: 0,
    adminCount: 0,
    viewerCount: 0,
    isAdmin: false,
    isFavourite: false,
    address2: '',
    secondaryEmail: '',
    secondaryCountryCode: '',
    secondaryPhone: '',
    primaryFirstName: '',
    primaryLastName: '',
    secondaryFirstName: null,
    secondaryLastName: null
};

const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";

export const BuildingMap: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const [buildingDetails, setBuildingDetails] = useState<OrgBuildingData>(buildingDetailsDefault);
    const organization = useSelector(Selectors.Organization.organization);
    const { t } = useLanguageLocale();
    const [selectedBuildingId, setBuildingId] = useState(props.buildingId);
    const [latitude, setlatitude] = useState(0.0);
    const [longitude, setlongitude] = useState(0.0);
    const [isLocationAvailable, setLocationAvailable] = useState(false);

    useEffect(() => {
        if (!props.isUpsertBuilding) {
            if ((props.latitude && props.latitude > 0) || (props.longitude && props.longitude > 0))
                setLocationAvailable(true);
            else
                setLocationAvailable(false);
        }
        else
            setLocationAvailable(true);
    }, [props.latitude, props.longitude, props.isUpsertBuilding]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!organization.buildingListLoading && organization.buildingListSuccess) {
            setBuildingDetails(organization.buildingList[0]);
            setBuildingId(props.buildingId);
        } else if (!organization.buildingListLoading && organization.buildingListFail) {
            setBuildingDetails(buildingDetailsDefault);
        }
    }, [organization.buildingListLoading, organization.buildingListSuccess, organization.buildingListFail]); // eslint-disable-line react-hooks/exhaustive-deps

    const updatePosition = (value: { lat: number, lng: number }): void => {
        if (props.geocodePosition)
            props.geocodePosition(value.lat, value.lng);
        setlatitude(value.lat);
        setlongitude(value.lng)
    }

    //Reverse Geocode Address acccording to map location from update
    const updatedAddress = (value: GeoCodingBuildingAddress) => {
        if (props.modifiedAddress)
            props.modifiedAddress(value);
    }

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleApiKey, // Add your API key
    });

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLocationAvailable && isLoaded ? (
        <div style={{ flex: '1 1 0px', overflow: 'auto' }}
            id="building-detail-map-container"
            data-testid="building-detail-map-container">

            {organization.buildingListLoading && <EmptyState style={{ flex: 1, minHeight: 400 }}
                icon={<CircularProgress id="progress-spinner" />} title="" placeholder={undefined} />}

            {!organization.buildingListLoading && <div id="building-map">
                <div style={{ maxHeight: 500, border: '1px solid #ced4da', borderRadius: 4, overflow: 'hidden' }}>
                    {props.isUpsertBuilding && <div>
                        <Typography
                            id="building-map-marker"
                            data-testid="building-map-marker"
                            style={{ fontSize: '16', fontWeight: 400, alignSelf: 'center', color: PXBColors.black[500], fontFamily: 'Open sans', marginBottom: 15, height: 22, marginLeft: 16 }}>
                            {t('MAP.MAP_DRAG_MARKER')}
                        </Typography>
                    </div>}
                    <div style={{ height: 375 }}>
                        {!props.isUpsertBuilding && <MapGeneric buildingId={selectedBuildingId} geocodePosition={updatePosition} isMarkerDraggable={false} latitude={props.latitude} longitude={props.longitude} country={props.country} address={props.address} city={props.city} />}
                        {props.isUpsertBuilding && <MapGeneric buildingId={selectedBuildingId} buildingDetails={buildingDetails} geocodePosition={updatePosition} modifiedAddress={updatedAddress} isMarkerDraggable={props.isUpsertBuilding}
                            address={props.address} country={props.country} city={props.city} postalCode={props.postalCode}/>}
                    </div>
                    <div style={{ height: 52, display: 'flex', }}>
                        <div style={{ float: 'left', width: '10%', paddingLeft: 17, paddingTop: 15 }}>
                            <img
                                src={MapUserLocation}
                                alt={'map-' + buildingDetails.latitude + buildingDetails.longitude}
                            />
                        </div>
                        <div style={{ float: 'right', width: '90%', paddingLeft: '10%', height: 52 }}>

                            <Typography
                                id="building-map-cordinates"
                                data-testid="building-map-cordinates"
                                style={{ fontSize: '16', fontWeight: 600, alignSelf: 'center', color: PXBColors.black[500], fontFamily: 'Open sans' }}>
                                {t('MAP.MAP_COORDINATES', { replace: { latitude: latitude, longitude: longitude } })}
                            </Typography>
                            <Typography
                                id="building-map-cordinates-lable"
                                data-testid="building-map-cordinates-lable"
                                style={{ fontSize: 'smaller', fontWeight: 400, alignSelf: 'center', color: PXBColors.black[500], fontFamily: 'Open sans' }}>
                                {t('MAP.MAP_COORDINATES_LABEL')}
                            </Typography>
                        </div>
                    </div>
                </div>

            </div>}

        </div>
    ) : null;
};
