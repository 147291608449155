import { ApiEndpointSpecification } from './types';
import { HttpMethod } from '../http-request';
import * as Helpers from '../api-request-helpers';
import * as Types from '../types';
import { USER_LIST } from './url-constants';

type UserListEndpointSpecifications = ApiEndpointSpecification;
export const UserListEndpointSpecification: UserListEndpointSpecifications = {
    url: USER_LIST,
    method: HttpMethod.GET,
    requestParamsSchemaName: 'UserListRequestParams',
    requestBodySchemaName: 'UserListRequestBody',
    okResponseSchemaName: 'UserListOkResponse',
    notOkResponseSchemaName: 'GenericNotOkResponse',
    authenticated: true,
};

export const invokeUserList = Helpers.makeApiRequestFn<
    Types.UserListRequestParams,
    Types.UserListRequestBody,
    Types.UserListOkResponse
>(UserListEndpointSpecification);
